import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">




<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M675.000000,1025.000000 
	C450.000000,1025.000000 225.500000,1025.000000 1.000000,1025.000000 
	C1.000000,683.666687 1.000000,342.333344 1.000000,1.000000 
	C342.333344,1.000000 683.666687,1.000000 1025.000000,1.000000 
	C1025.000000,342.333344 1025.000000,683.666687 1025.000000,1025.000000 
	C908.500000,1025.000000 792.000000,1025.000000 675.000000,1025.000000 
M256.534576,718.230530 
	C256.372009,718.523071 256.209473,718.815613 255.795456,719.611755 
	C255.453354,720.058960 255.111252,720.506165 254.144028,721.097656 
	C253.808762,721.815857 253.473495,722.534058 253.093796,723.628296 
	C253.093796,723.628296 252.963455,723.983765 252.374268,724.052063 
	C252.276154,724.714661 252.178024,725.377258 252.074219,726.779785 
	C252.007248,727.515991 251.940277,728.252136 251.579971,729.157410 
	C251.519241,729.250977 251.387329,729.372192 251.410889,729.433105 
	C251.487274,729.630005 251.620743,729.804688 251.753250,730.681946 
	C251.736374,731.144531 251.719482,731.607178 251.288422,732.611206 
	C251.873489,734.060669 252.458542,735.510193 253.003464,736.988708 
	C253.003464,736.988708 253.041809,736.968384 253.021271,737.675415 
	C253.658035,738.461487 254.294800,739.247620 255.466644,740.280029 
	C255.972290,740.863098 256.477966,741.446228 257.138245,742.773193 
	C262.286102,746.459778 267.822815,744.431519 273.330383,744.093933 
	C277.672028,743.827820 279.394562,742.020569 279.150726,737.356079 
	C279.448792,736.905640 279.746857,736.455200 280.550568,735.642334 
	C280.683868,734.421753 280.817169,733.201111 280.928741,731.327942 
	C280.939331,730.892822 280.949921,730.457764 281.432983,729.732666 
	C281.276642,729.149048 281.120300,728.565491 280.945587,727.351807 
	C280.947754,726.931580 280.949921,726.511414 280.994080,725.289124 
	C280.350891,723.839355 279.707703,722.389526 279.007111,720.190430 
	C277.603668,719.169312 276.200226,718.148193 274.391357,717.098816 
	C274.391357,717.098816 274.002747,716.979736 273.891907,716.203369 
	C270.287994,712.614624 261.335999,713.109863 257.994904,717.050781 
	C257.994904,717.050781 258.064331,717.045898 257.588104,717.203979 
	C257.390167,717.465454 257.192200,717.726929 257.000000,718.000000 
	C257.000000,718.000000 256.988525,717.994507 256.534576,718.230530 
M730.653137,547.654419 
	C730.531311,547.319580 730.293457,547.131226 729.774658,546.641113 
	C729.774658,546.641113 729.575439,546.206909 729.795227,545.481934 
	C728.889832,543.300842 727.984436,541.119690 727.079102,538.281311 
	C726.964233,537.861938 726.849426,537.442566 726.819885,536.304077 
	C725.662720,534.963989 724.505493,533.623840 723.348328,532.283752 
	C722.801453,533.254700 722.254578,534.225647 721.360779,535.735413 
	C721.229431,536.141785 721.098083,536.548096 720.319946,537.150757 
	C719.298950,538.820251 718.156494,540.428467 717.278748,542.170166 
	C714.099426,548.478699 709.960144,552.693298 701.961243,551.398193 
	C697.716614,550.710876 693.269226,551.276367 688.913330,551.276367 
	C688.716675,551.918701 688.520020,552.561096 688.323364,553.203430 
	C689.588257,554.453125 690.853149,555.702820 692.299622,557.619873 
	C695.207336,560.607422 698.115112,563.594910 701.210510,567.303589 
	C701.830322,568.882690 702.450195,570.461731 702.634888,572.545593 
	C702.348816,574.020508 702.062744,575.495422 701.282532,577.586304 
	C699.759521,582.508484 698.236511,587.430664 696.713440,592.352844 
	C697.142151,592.639465 697.570801,592.926147 697.999512,593.212830 
	C703.154419,591.030762 708.545959,589.269714 713.395020,586.543640 
	C717.894897,584.013855 720.953735,585.257446 724.529724,588.134216 
	C728.829285,591.592896 733.568909,594.504456 739.352356,598.507141 
	C738.393677,589.325317 737.594421,581.670471 737.381165,573.636902 
	C740.258850,570.773743 743.136475,567.910583 746.518494,565.274170 
	C749.404053,563.744873 752.289551,562.215637 755.175110,560.686401 
	C755.023987,559.978577 754.872864,559.270752 754.721741,558.562927 
	C747.164124,556.687073 739.606567,554.811218 731.930481,552.269409 
	C731.624756,551.181702 731.319031,550.093994 731.280212,548.837341 
	C731.280212,548.837341 731.203979,548.431274 731.203979,548.431274 
	C731.203979,548.431274 730.775574,548.420227 730.724976,548.256775 
	C730.724976,548.256775 730.611389,548.128906 730.653137,547.654419 
M233.997375,729.989197 
	C233.997375,729.989197 234.058945,729.981384 234.124634,730.612610 
	C234.673935,731.232422 235.223236,731.852234 235.700287,732.723206 
	C235.700287,732.723206 235.767059,732.975830 235.834396,733.783203 
	C236.178238,735.343811 236.522079,736.904419 236.919205,738.706909 
	C235.075516,738.933167 233.030090,738.576050 232.279495,739.399292 
	C230.566254,741.278442 228.243866,743.902588 228.513290,745.909363 
	C228.763657,747.774170 232.070160,750.547546 233.999344,750.521973 
	C236.228348,750.492432 239.645508,748.298828 240.418854,746.244019 
	C243.654434,737.647278 246.092377,728.750427 249.050369,719.634338 
	C249.050369,719.634338 249.140137,719.244202 249.713806,718.986694 
	C250.185577,718.349976 250.657349,717.713196 251.567001,716.590210 
	C249.650070,712.488403 246.616989,714.453918 243.814850,715.164124 
	C244.185181,715.797668 244.555527,716.431274 245.199387,717.405029 
	C245.199387,717.405029 245.568176,717.631897 245.568176,717.631897 
	C245.568176,717.631897 246.001068,717.627258 246.181168,717.888611 
	C246.181168,717.888611 246.418762,718.099060 246.248108,718.890564 
	C245.268646,723.291870 245.414703,728.320007 239.772766,729.504028 
	C239.335526,728.641052 238.898285,727.778076 238.525223,726.578186 
	C238.411316,726.338013 238.221939,726.232483 237.767029,725.656250 
	C237.426590,725.121765 237.086151,724.587341 236.831497,723.239441 
	C236.511703,721.817078 235.882004,720.384338 235.929092,718.974243 
	C236.081985,714.395996 233.653748,713.712769 229.954544,714.687439 
	C228.768661,714.999939 227.602921,715.388794 225.691086,715.964661 
	C227.459335,717.631348 228.725479,718.824829 230.159485,720.571594 
	C230.508682,721.034668 230.857880,721.497803 231.150421,722.685303 
	C231.786011,724.080994 232.421600,725.476624 233.142609,727.651611 
	C233.300232,728.147949 233.457840,728.644348 233.536072,729.472412 
	C233.626907,729.703552 233.790588,729.857666 233.997375,729.989197 
M226.352615,538.531677 
	C226.576126,539.004211 226.799637,539.476685 227.116714,540.651917 
	C228.022156,542.086182 228.927612,543.520508 230.676651,546.291077 
	C231.647186,543.111328 232.093552,541.360107 232.714462,539.673157 
	C234.113693,535.871582 235.599319,532.101868 237.753159,528.588257 
	C241.487793,527.540344 245.222412,526.492371 250.172348,525.103394 
	C247.953461,523.149597 246.918823,522.238586 245.450607,520.812927 
	C233.927673,516.511658 237.575058,506.041809 236.653381,496.325439 
	C232.790146,499.619415 229.887421,501.987457 227.111374,504.495728 
	C224.958344,506.441101 223.140884,506.707733 220.546524,504.890045 
	C217.802856,502.967651 214.517380,501.818542 211.468552,500.331696 
	C211.052567,500.621918 210.636581,500.912170 210.220612,501.202393 
	C211.863739,506.738953 213.506882,512.275513 214.469940,518.325439 
	C213.299454,520.521179 212.128967,522.716919 210.320694,525.156250 
	C209.114990,526.793945 207.909286,528.431702 206.333969,530.571533 
	C208.691422,530.725220 210.400833,530.535828 211.914276,530.993896 
	C215.848404,532.184692 220.751266,530.023315 223.918625,534.735718 
	C223.918625,534.735718 224.216965,534.995667 224.264008,535.585510 
	C224.882889,536.286926 225.501785,536.988281 226.180969,537.894775 
	C226.180969,537.894775 226.373260,537.988098 226.352615,538.531677 
M502.783447,262.213776 
	C502.463715,262.074188 502.177734,262.125061 501.125336,262.213898 
	C498.749664,262.427155 496.374023,262.640411 493.491669,262.330200 
	C492.292358,262.388458 491.093079,262.446716 489.032043,262.316528 
	C486.390289,262.377563 483.748535,262.438599 480.307434,262.157593 
	C479.171295,262.260376 478.035156,262.363159 476.150940,262.305542 
	C475.150940,262.418793 474.150970,262.532013 472.320770,262.274811 
	C471.484161,262.488617 470.647583,262.702423 469.288269,262.664673 
	C468.907532,262.674072 468.526825,262.683441 467.439270,262.203552 
	C466.605438,262.267975 465.771637,262.332367 464.024994,262.257477 
	C459.411743,262.410126 454.798523,262.562775 449.566193,262.360931 
	C449.334778,262.415710 449.103394,262.470459 448.095093,262.330658 
	C446.774780,262.418610 445.454498,262.506561 443.421936,262.249878 
	C442.911804,262.332825 442.401642,262.415771 441.052155,262.316650 
	C439.328186,262.339874 437.604248,262.363098 435.670959,261.948120 
	C435.156616,262.098419 434.642242,262.248718 433.289551,262.279816 
	C431.901031,262.389984 430.512512,262.500153 428.396545,262.286896 
	C427.888855,262.394714 427.381195,262.502502 426.149841,262.342346 
	C425.142120,262.489655 424.134369,262.636932 422.421600,262.402466 
	C421.910980,262.507507 421.400391,262.612549 420.033844,262.421478 
	C416.024597,262.515106 412.015350,262.608765 407.387787,262.225922 
	C405.225739,262.309052 403.063721,262.392181 399.999817,262.315948 
	C395.714722,262.412567 391.429626,262.509186 386.331665,262.223145 
	C384.856720,262.328827 383.381805,262.434509 381.027161,262.262756 
	C376.380646,262.355835 371.734131,262.448914 366.363739,262.290771 
	C365.873688,262.397278 365.383636,262.503754 364.029785,262.351349 
	C360.698700,262.335876 357.367645,262.320374 353.251343,262.087311 
	C352.456909,262.241089 351.662506,262.394836 350.040619,262.307281 
	C348.042206,262.391510 346.043823,262.475739 343.125092,262.292389 
	C341.251648,262.487671 339.378204,262.682983 336.855804,262.462280 
	C335.279724,262.502228 333.703613,262.542145 331.614532,262.301758 
	C330.876709,262.440277 330.138885,262.578796 328.689545,262.361725 
	C326.429108,262.535309 324.168640,262.708923 321.501892,262.740173 
	C321.501892,262.740173 321.104034,262.904541 320.414825,262.761993 
	C319.509521,263.353607 318.604248,263.945190 317.117584,264.657288 
	C316.820648,265.099487 316.523712,265.541687 315.997772,266.773132 
	C316.016052,267.864655 316.034332,268.956146 315.855194,270.487610 
	C315.855194,270.487610 316.022339,270.939941 315.883575,271.645050 
	C315.933777,272.120178 315.983948,272.595337 315.813110,273.914459 
	C315.931274,275.642487 316.049438,277.370544 315.545135,279.586639 
	C315.718750,282.722870 315.892365,285.859131 315.837799,289.653595 
	C315.944305,290.096161 316.050781,290.538757 316.056366,291.758423 
	C316.038696,292.544250 316.021027,293.330048 315.724823,295.004150 
	C315.802704,304.642334 315.880615,314.280487 315.864777,324.629059 
	C315.931458,325.100159 315.998138,325.571289 315.808441,326.875793 
	C315.901123,328.893951 315.993774,330.912079 315.978668,333.753723 
	C315.988403,334.859344 315.998108,335.964935 315.778198,337.674042 
	C315.846893,338.092499 315.915558,338.510956 315.864471,339.704803 
	C315.930145,340.483398 315.995819,341.262024 315.801575,342.750641 
	C315.893463,343.488464 315.985352,344.226257 315.936646,345.806549 
	C315.948059,347.228027 315.959503,348.649506 315.739136,350.766205 
	C315.808044,351.487305 315.876923,352.208405 315.849579,353.857605 
	C315.889832,357.591125 315.930115,361.324677 315.818481,365.515717 
	C315.818481,365.515717 316.019714,365.953796 315.920135,366.725067 
	C315.983917,367.496765 316.047729,368.268433 315.932617,369.497528 
	C315.932617,369.497528 316.112457,369.954559 315.954956,370.831818 
	C315.978119,372.907349 316.001312,374.982880 315.858704,377.510406 
	C315.858704,377.510406 316.060059,377.947723 315.949219,378.831207 
	C315.975555,380.906464 316.001923,382.981689 315.845520,385.490601 
	C315.845520,385.490601 315.999451,385.935303 315.876678,386.818634 
	C315.930237,388.894714 315.983795,390.970825 315.800507,393.813873 
	C315.917206,394.868408 316.033905,395.922974 315.948608,397.744202 
	C315.961700,398.531769 315.974792,399.319366 315.752838,400.943756 
	C315.831177,403.259735 315.909515,405.575714 315.848969,408.805420 
	C315.917603,411.566528 315.986267,414.327667 315.776459,417.969818 
	C315.846283,423.289917 315.916107,428.610046 315.866943,434.741333 
	C315.914185,435.821014 315.961426,436.900696 315.847900,438.843475 
	C315.893585,440.591187 315.939240,442.338928 315.795013,444.877441 
	C315.883057,446.230499 315.971130,447.583557 315.878326,449.741211 
	C315.986542,450.819489 316.094788,451.897797 316.029510,453.716614 
	C316.056030,454.473236 316.082550,455.229828 315.871277,456.873596 
	C315.887787,459.624268 315.904297,462.374939 315.639038,465.214142 
	C315.496918,465.557922 315.591095,465.781006 315.831818,466.773712 
	C315.919708,468.851776 316.007599,470.929810 315.935944,473.737152 
	C315.984650,474.478058 316.033386,475.218994 315.849854,476.882690 
	C315.969391,484.916260 316.088898,492.949860 315.999603,501.856171 
	C316.062378,504.243225 316.125153,506.630249 315.884369,509.785706 
	C315.935150,510.881836 315.985931,511.977966 315.833954,513.836975 
	C315.922089,514.873840 316.010254,515.910645 315.870392,517.868530 
	C315.950500,527.936646 316.030609,538.004700 315.886230,548.831055 
	C315.963837,549.599426 316.041443,550.367798 316.093567,551.531921 
	C316.093567,551.531921 316.274902,551.884521 315.927032,552.732727 
	C315.873260,555.720093 315.819519,558.707520 315.445496,562.067017 
	C315.263184,562.396484 315.297180,562.701843 315.333954,563.744934 
	C315.349365,565.054810 315.364777,566.364685 315.367126,567.973145 
	C315.359558,568.199524 315.450104,568.375977 315.177887,569.092834 
	C315.139435,570.070007 315.100983,571.047180 314.632782,572.656616 
	C315.082031,577.851196 315.531311,583.045837 315.756378,588.905579 
	C315.883759,590.261047 316.011139,591.616455 315.982788,593.648804 
	C316.027191,594.106506 316.071594,594.564270 315.807159,595.455017 
	C315.903748,596.297485 316.000336,597.140015 315.941467,598.769714 
	C315.944702,599.571289 315.947937,600.372925 315.595490,601.581299 
	C315.687561,602.090942 315.779633,602.600647 315.725067,604.007080 
	C315.717499,607.797363 315.633667,611.589355 315.720001,615.377502 
	C315.855682,621.331055 318.590179,624.014160 324.491608,624.309753 
	C326.929962,624.431885 329.358307,624.754211 331.256073,625.773376 
	C331.049194,628.250793 330.667450,630.728088 330.665100,633.205688 
	C330.653839,645.147156 330.929688,657.091675 330.742584,669.029419 
	C330.585052,679.079346 334.710693,684.612183 344.554504,686.555725 
	C357.238556,689.060059 367.579163,682.492554 367.984955,671.528625 
	C368.187317,666.061279 368.172058,660.576782 368.011749,655.107178 
	C367.738708,645.791077 362.603241,641.078064 352.354126,640.922607 
	C351.893585,640.982300 351.433014,641.041992 350.612946,640.900818 
	C350.101288,641.020874 349.589630,641.140930 348.327698,641.214233 
	C347.890564,641.440125 347.453400,641.666016 346.194489,641.810364 
	C345.115295,641.183411 343.170776,640.637634 343.103058,639.915771 
	C342.766266,636.324402 342.952881,632.686218 342.898163,629.065369 
	C342.846436,625.640381 344.396484,625.276550 347.333954,626.267029 
	C350.880829,627.463074 354.534882,628.649536 358.226440,629.033630 
	C363.311127,629.562622 368.489410,629.146851 373.594635,629.551636 
	C375.090698,629.670227 376.474792,631.200867 377.582703,632.931885 
	C377.747681,633.815430 377.912659,634.698975 377.774414,635.689697 
	C377.774414,635.689697 377.660553,635.388000 377.660553,635.388000 
	C377.660553,635.388000 378.081543,635.429382 377.915924,636.300354 
	C377.892273,637.156311 377.868591,638.012207 377.742889,639.686951 
	C377.773560,648.169312 377.804230,656.651733 377.607452,665.943298 
	C377.756409,666.480774 377.905334,667.018250 377.771637,667.663086 
	C377.771637,667.663086 377.658356,667.386658 377.658356,667.386658 
	C377.658356,667.386658 378.057587,667.416931 377.872375,668.252319 
	C377.872772,668.814575 377.873169,669.376831 377.695099,670.628723 
	C378.257233,679.177185 381.065002,683.977661 388.309174,685.470459 
	C393.474121,686.534790 399.172485,686.380371 404.394226,685.442078 
	C410.511627,684.342834 414.186127,679.532593 414.700653,673.456665 
	C415.285583,666.549133 415.228577,659.520020 414.686188,652.603638 
	C414.165863,645.968994 410.763611,642.966125 403.129547,641.145874 
	C399.401459,641.481750 395.673401,641.817627 391.612427,641.677124 
	C391.068909,641.522095 390.525421,641.367004 389.902710,640.437256 
	C389.827637,639.665100 389.752563,638.892883 389.983826,637.498596 
	C389.988190,637.267395 389.992523,637.036194 390.369354,636.592346 
	C390.245270,636.125793 390.121155,635.659302 390.025818,634.720581 
	C390.125000,634.356201 390.005768,634.089294 389.783356,633.550842 
	C389.783356,633.550842 389.595154,633.213013 389.847260,632.443970 
	C389.770874,629.989624 389.694489,627.535278 390.125916,624.924316 
	C390.253571,624.610413 390.381226,624.296448 391.076202,623.847717 
	C391.223663,623.721069 391.371094,623.594360 391.803345,623.989014 
	C392.566650,624.064270 393.329987,624.139465 394.595337,624.624878 
	C396.034973,624.462891 397.474640,624.300842 399.828094,624.138733 
	C402.563354,624.121521 405.298584,624.104309 408.172943,624.415466 
	C408.444489,624.303650 408.716003,624.191833 409.126678,623.846008 
	C409.126678,623.846008 408.921326,623.667358 409.754822,623.907715 
	C411.168762,623.753723 412.582733,623.599731 413.870361,623.763855 
	C413.870361,623.763855 413.969849,624.091370 413.938843,624.896790 
	C413.978302,630.557007 417.397522,630.253113 421.456360,629.211121 
	C421.663330,627.479126 421.856506,625.862732 422.783112,624.230408 
	C423.519470,624.272583 424.255798,624.314819 425.240875,624.970215 
	C428.615936,628.262939 432.417969,628.051819 436.761414,627.085144 
	C441.969971,625.925964 447.374176,625.642944 452.698517,625.022034 
	C452.872253,625.001831 453.099030,625.436340 453.384033,625.750244 
	C452.759125,626.840881 452.087219,628.013489 451.062134,629.802490 
	C454.887146,629.802490 458.023804,629.546570 461.102020,629.861633 
	C465.884369,630.351135 469.021729,628.406494 471.557800,624.180603 
	C472.069641,623.972229 472.581451,623.763916 473.740204,623.721252 
	C474.850098,623.811279 475.959991,623.901245 477.310913,624.432373 
	C477.859558,624.286560 478.408203,624.140747 479.046844,623.806885 
	C479.046844,623.806885 478.876373,623.686768 479.637756,624.047485 
	C480.445221,623.908691 481.252655,623.769897 482.889771,623.896790 
	C484.910645,623.837341 486.931488,623.777893 489.758118,624.025024 
	C491.171661,623.926270 492.585205,623.827515 494.889160,623.880432 
	C497.596039,623.872253 500.302917,623.864075 503.188446,624.355530 
	C503.775635,624.271973 504.362854,624.188477 505.723511,624.162354 
	C506.496429,624.126831 507.269318,624.091309 508.177582,624.372498 
	C508.442993,624.282776 508.708405,624.193054 509.782074,624.161926 
	C510.863373,624.135559 511.944641,624.109192 513.564758,624.571716 
	C515.750610,624.230164 517.936462,623.888611 520.889587,623.322266 
	C521.916260,623.085449 522.942932,622.848694 524.784607,622.888062 
	C526.210815,622.826965 527.636963,622.765808 529.253479,623.159729 
	C529.875366,622.918274 530.497314,622.676880 531.914551,622.354370 
	C532.972595,622.223633 534.030640,622.092896 535.481384,622.420471 
	C536.711365,622.110779 537.941406,621.801086 539.289673,621.144653 
	C539.289673,621.144653 538.987549,620.937256 539.692566,621.145813 
	C540.182129,621.125000 540.671692,621.104248 541.600952,621.385803 
	C542.451477,621.074097 543.301941,620.762451 544.613037,620.234314 
	C544.738647,620.120178 544.864197,620.006104 545.734131,620.101501 
	C546.205994,619.931885 546.677917,619.762268 547.656494,619.702454 
	C547.919189,619.493347 548.181885,619.284241 549.013000,619.235718 
	C549.335083,619.036865 549.657166,618.838013 550.711243,618.815186 
	C553.154724,618.522217 555.598145,618.229309 558.432861,618.416931 
	C560.309082,617.738647 562.185303,617.060303 564.561890,616.281738 
	C564.715210,616.208008 564.868469,616.134277 565.617737,616.216309 
	C566.116211,615.953186 566.614746,615.690063 567.752686,615.254700 
	C568.174927,615.139465 568.597168,615.024231 569.404541,615.516907 
	C571.976501,614.778137 574.548462,614.039429 577.725708,613.107483 
	C578.507751,612.817993 579.289795,612.528503 580.524841,612.601440 
	C582.051697,611.856567 583.578491,611.111694 585.602600,610.149170 
	C585.771545,610.084412 585.940491,610.019714 586.735352,610.034302 
	C587.421082,609.630127 588.106812,609.225952 589.516663,608.652649 
	C590.447632,608.292847 591.416321,608.008057 592.304077,607.562256 
	C613.611328,596.864380 631.359924,581.805725 646.236755,563.291199 
	C661.262207,544.591736 670.489014,523.197571 674.251770,499.632080 
	C678.352966,473.947052 679.123352,448.075287 677.749390,422.057312 
	C676.634888,400.952271 673.135254,380.448822 666.241821,360.493958 
	C657.085571,333.988800 641.135437,312.250641 619.623779,294.535248 
	C602.400879,280.351746 582.655212,271.115417 560.945679,266.182343 
	C555.677673,264.985291 550.197937,264.719818 544.477173,263.402588 
	C541.617188,263.179260 538.757141,262.955933 535.139160,262.427887 
	C532.077026,262.550537 529.014893,262.673218 525.414307,262.692444 
	C525.243042,262.756226 525.071777,262.820007 524.532227,262.724091 
	C524.532227,262.724091 524.153137,262.856110 523.458984,262.438049 
	C520.683960,262.509888 517.908936,262.581726 514.411072,262.290192 
	C513.896362,262.399872 513.381653,262.509552 512.198608,262.397491 
	C511.514374,262.505463 510.830139,262.613403 509.465027,262.311523 
	C508.943604,262.351196 508.422211,262.390869 507.319611,262.165009 
	C506.521820,262.307739 505.724060,262.450439 504.331116,262.533478 
	C503.933136,262.523895 503.535187,262.514343 502.783447,262.213776 
M342.062592,742.541077 
	C342.707214,742.700500 343.351807,742.859924 344.687134,743.084595 
	C345.138794,743.184387 345.590454,743.284180 346.474731,743.921387 
	C348.666138,743.712402 350.857513,743.503357 353.623993,743.085144 
	C354.025635,743.023132 354.427277,742.961121 355.565277,742.903625 
	C357.703644,741.272095 359.841980,739.640564 362.266632,738.505676 
	C362.589081,738.979858 362.911499,739.454041 363.315887,740.362732 
	C363.489105,740.651672 363.741882,740.768005 364.732147,741.035706 
	C369.801239,744.940063 376.114838,745.308716 381.236481,741.957703 
	C383.594910,740.414673 385.633942,738.686829 384.276855,735.230835 
	C374.902222,742.416626 371.104889,742.783325 367.000732,736.364319 
	C367.971893,734.225403 368.943054,732.086487 369.965424,729.956604 
	C369.965424,729.956604 369.968872,729.904785 370.562408,729.887695 
	C371.068909,729.616882 371.575409,729.346069 372.999359,729.070190 
	C373.830322,729.066162 374.668549,729.131958 375.491028,729.046265 
	C379.871613,728.590088 386.195770,731.514526 386.061066,723.013611 
	C386.055817,722.682922 387.429626,721.939880 387.976776,722.084656 
	C388.752228,722.289734 389.967224,723.172424 389.940704,723.713867 
	C389.618347,730.290527 392.052948,737.220215 387.073059,743.997131 
	C391.720856,743.997131 395.816223,743.997131 399.911591,743.997131 
	C395.353729,742.145447 395.581268,738.493408 395.812897,734.940491 
	C396.005798,731.981812 396.208710,728.996521 396.782806,726.097351 
	C397.404175,722.959412 398.063507,719.330139 402.769745,722.419861 
	C403.244659,722.731628 404.479004,722.429504 405.029846,721.993835 
	C408.391571,719.334473 409.132507,721.806458 410.171356,724.340698 
	C412.053009,728.931152 414.038300,733.486267 416.193848,737.953125 
	C416.887299,739.390137 418.252228,740.503052 419.309967,741.764221 
	C420.610413,740.239685 421.910828,738.715149 423.840851,736.918274 
	C426.369781,730.911316 428.898712,724.904358 431.538635,718.633728 
	C433.504456,720.055603 434.819946,721.007080 436.069733,722.900085 
	C436.066132,726.979858 435.766296,731.088074 436.153595,735.130371 
	C436.458344,738.311279 436.572601,742.020203 441.192383,743.438904 
	C441.749329,743.284485 442.306274,743.130066 443.043915,742.556396 
	C443.043915,742.556396 443.089081,742.102112 443.797272,741.913696 
	C445.896515,740.928894 447.995758,739.944092 450.161652,739.659729 
	C453.458832,742.927979 457.265015,744.810913 462.845001,744.078247 
	C469.534088,743.378845 472.603210,740.077209 471.247986,736.460938 
	C467.140991,738.038940 463.056854,739.608154 458.486938,741.059814 
	C458.486938,741.059814 457.989777,741.008240 457.801727,740.553284 
	C457.601044,740.278748 457.339447,740.087463 456.825226,739.518921 
	C456.624054,739.229675 456.350220,739.053406 455.822388,738.397095 
	C455.191589,738.004089 454.560791,737.611145 453.901184,736.657532 
	C453.835022,736.482544 453.768829,736.307495 453.766693,735.287964 
	C453.641998,731.186340 453.517273,727.084778 453.878540,722.521912 
	C454.223755,721.959290 454.568939,721.396667 455.678558,720.799866 
	C465.504852,714.463196 466.895386,722.703247 469.126221,730.425415 
	C473.385162,724.108521 471.811127,719.250977 470.502441,715.063049 
	C466.310455,715.063049 462.641235,714.646423 459.114746,715.177368 
	C455.402679,715.736206 450.924988,715.739563 449.387390,721.089783 
	C449.146362,721.303589 448.988495,721.567139 448.539307,722.182983 
	C448.288940,722.424072 448.187042,722.716858 448.029541,723.786072 
	C447.983002,724.532166 447.936462,725.278198 447.778412,726.724854 
	C447.540070,728.992798 447.504517,731.307129 446.999023,733.513855 
	C446.596588,735.270752 445.620300,736.896240 444.898773,738.580017 
	C443.626709,738.008362 442.354645,737.436707 441.099030,736.234253 
	C441.036560,735.818298 440.974091,735.402344 441.407532,734.510925 
	C441.238129,733.017517 441.068756,731.524048 440.969513,729.114563 
	C440.852661,725.797119 440.735840,722.479614 440.798981,718.429688 
	C440.674164,717.418945 440.549377,716.408203 440.523254,716.196777 
	C437.850464,716.497681 434.436462,717.712097 433.748199,716.821106 
	C430.037994,712.018005 426.378387,714.283936 421.642212,715.949341 
	C423.837799,717.429382 425.360321,718.455688 426.951904,719.528564 
	C425.849182,722.002991 424.994781,724.330566 423.805206,726.471924 
	C422.665192,728.524170 421.177216,730.383118 419.401917,731.723877 
	C417.828217,727.219971 415.700012,722.816589 414.859924,718.179749 
	C414.212280,714.605164 412.696564,714.117859 409.870605,714.774658 
	C406.108856,715.648987 402.405914,716.819031 398.612427,717.496704 
	C396.668335,717.843933 394.195465,716.646362 392.634186,717.451355 
	C388.247559,719.713196 384.303772,721.260010 380.270447,716.942627 
	C379.536011,716.156494 378.257996,715.797485 377.165863,715.439819 
	C371.270386,713.509216 361.441284,717.334900 361.647430,724.115662 
	C361.647430,724.115662 361.284698,724.206238 360.584503,724.040833 
	C359.434296,723.016541 358.284088,721.992249 357.010712,720.159546 
	C356.689026,718.342651 356.367371,716.525696 355.838531,714.025391 
	C351.777710,714.403015 347.666656,714.513245 343.675720,715.262939 
	C341.431671,715.684387 338.698486,716.333679 338.964539,719.891174 
	C339.231873,723.465210 338.756500,727.253357 343.071686,728.923035 
	C343.071686,728.923035 343.073364,728.901550 343.121460,729.623901 
	C345.538483,730.680725 347.955475,731.737610 350.933777,732.806946 
	C351.290955,732.914429 351.648163,733.021912 352.209198,733.524109 
	C352.397247,733.767761 352.638977,733.932800 353.360016,734.104370 
	C353.360016,734.104370 353.791321,734.153442 353.908112,734.857178 
	C353.920532,735.565979 353.932953,736.274841 353.215942,737.075134 
	C349.884430,739.966919 346.606750,739.048950 343.026337,736.285645 
	C342.165558,735.160339 341.304810,734.035034 340.444031,732.909729 
	C340.012360,733.114441 339.580658,733.319153 339.148987,733.523865 
	C339.443146,735.343018 339.737335,737.162231 339.490570,739.405090 
	C340.342590,740.254211 341.194611,741.103333 341.998779,741.985657 
	C341.998779,741.985657 342.045929,741.943115 342.062592,742.541077 
M166.526581,741.202820 
	C166.264755,741.402283 166.088104,741.660095 165.532608,742.407227 
	C168.202194,745.702759 168.999100,745.789734 173.540558,743.281311 
	C170.748352,737.103271 172.105438,735.125427 179.142624,735.043823 
	C181.798279,735.013000 184.452881,734.892151 187.569778,734.909485 
	C187.569778,734.909485 188.035797,734.983887 188.100967,735.699158 
	C189.074905,737.124878 190.048828,738.550598 191.515106,740.426880 
	C192.931610,741.385620 194.348114,742.344299 196.125565,743.993469 
	C199.746414,743.990540 203.367264,743.987549 207.404984,744.518372 
	C208.180252,744.524170 209.119720,744.819031 209.703369,744.487549 
	C212.811966,742.721558 216.610886,733.510193 215.691193,730.009644 
	C215.573532,729.561768 214.686890,729.315918 213.913010,728.367920 
	C212.918503,726.957581 211.924011,725.547241 210.979431,723.334045 
	C212.139267,721.882629 213.363800,720.476746 214.431580,718.960510 
	C214.964035,718.204407 215.209579,717.246277 215.584915,716.379517 
	C214.713257,716.286194 213.841614,716.192871 212.178589,716.096558 
	C211.123520,716.080688 210.068436,716.064758 208.704376,715.564514 
	C208.118912,715.781433 207.533432,715.998291 206.303482,716.240723 
	C205.873856,716.226013 205.444229,716.211365 205.009048,715.413086 
	C204.639221,714.265930 204.269394,713.118774 203.903534,711.229370 
	C203.919022,710.487549 203.934509,709.745667 204.513504,708.568909 
	C203.940002,707.420288 203.366501,706.271606 202.418884,705.335083 
	C202.191376,705.514587 201.999512,705.726379 201.187637,706.064941 
	C199.337433,710.026245 198.230240,714.485413 193.515060,717.189087 
	C193.376770,717.735840 193.238480,718.282654 193.051422,719.596741 
	C193.810883,724.503906 197.034821,719.860229 198.806808,721.928406 
	C198.724899,723.933044 198.642975,725.937744 197.949356,728.171448 
	C198.922668,731.255981 199.830673,734.363403 200.896881,737.415466 
	C201.428345,738.936829 202.249435,740.356995 202.301178,741.894775 
	C201.874130,741.865173 201.447098,741.835571 200.773300,741.434326 
	C200.562210,741.207764 200.304855,741.063782 199.526443,740.936584 
	C199.526443,740.936584 199.058304,740.833679 198.937180,740.177368 
	C197.282379,738.456299 195.627579,736.735229 193.496445,734.766052 
	C193.053391,734.466125 192.610321,734.166199 192.280212,733.411194 
	C192.280212,733.411194 192.426163,732.965637 192.618149,732.783752 
	C192.757843,732.525146 192.718369,732.289917 192.243637,731.310425 
	C189.815430,724.563904 187.387238,717.817322 184.924500,710.347656 
	C182.762177,706.753967 181.155609,707.226990 179.596542,711.511292 
	C179.351410,712.329773 179.106293,713.148254 178.195541,714.097595 
	C177.801117,715.073059 177.406677,716.048523 176.992554,717.804443 
	C176.852600,718.833618 176.712646,719.862732 176.204483,720.885986 
	C176.204483,720.885986 175.834564,720.944519 175.174744,721.110046 
	C174.808365,722.104431 174.441986,723.098877 174.039566,724.540039 
	C174.039566,724.540039 173.969971,724.982788 173.216232,725.088379 
	C172.471436,727.383179 171.726654,729.677979 170.909485,732.477356 
	C170.882172,732.645081 170.854874,732.812805 170.449173,733.231201 
	C170.214767,733.443054 170.068420,733.704285 169.994354,734.684875 
	C169.671631,735.461365 169.348907,736.237854 168.308594,737.126465 
	C167.858887,738.396484 167.409180,739.666443 166.990082,740.970581 
	C166.990082,740.970581 166.944855,740.977356 166.526581,741.202820 
M813.000000,720.000000 
	C813.000000,720.000000 812.991211,719.991516 813.671814,719.938965 
	C814.750061,719.638367 815.828308,719.337769 816.971497,719.916321 
	C817.006714,722.268250 817.041931,724.620117 816.588501,727.272949 
	C816.777893,727.863953 816.967285,728.454895 817.143555,729.693970 
	C817.150146,730.125793 817.156677,730.557678 816.601440,731.386353 
	C816.065796,733.467346 818.384644,736.354797 814.717224,737.738770 
	C814.403870,737.856934 814.929016,741.357544 815.761353,742.914246 
	C816.256653,743.840454 818.545776,744.808472 819.268616,744.403015 
	C820.759216,743.566895 822.953613,741.521057 822.701782,740.510071 
	C821.977112,737.600830 821.236145,735.156250 822.750488,732.121460 
	C823.305237,731.009827 821.514526,728.727783 820.898499,726.243774 
	C820.967896,725.504517 821.037292,724.765198 821.798462,723.859863 
	C823.687927,721.937744 823.844238,720.255066 821.043457,718.220398 
	C820.989990,717.467468 820.936523,716.714600 821.479675,715.519836 
	C821.145813,713.795166 820.811951,712.070496 820.478088,710.345886 
	C819.013489,710.915039 817.548889,711.484192 815.258484,712.110718 
	C813.209412,712.410828 811.108032,712.525635 809.131592,713.083313 
	C808.018860,713.397278 807.112305,714.441772 806.131897,715.711548 
	C806.416443,716.157593 806.700989,716.603638 807.004395,717.753174 
	C807.687317,718.505249 808.370239,719.257263 808.994202,720.742737 
	C808.933228,721.475952 808.872314,722.209106 808.159058,723.115662 
	C807.798096,724.108154 807.437134,725.100586 807.048706,726.545166 
	C807.048706,726.545166 806.991943,726.994568 806.125366,727.001892 
	C804.958130,727.071167 803.790894,727.140442 802.076782,726.733826 
	C801.594421,725.724670 801.073730,724.731812 800.637085,723.703247 
	C799.408081,720.808899 798.213562,717.899841 796.928162,714.400940 
	C796.518555,714.084839 796.109009,713.768738 795.699402,713.452637 
	C795.462219,713.967896 795.224976,714.483154 794.202087,715.065430 
	C793.154541,715.109680 792.106995,715.153870 790.680969,714.624146 
	C789.916931,714.693054 789.090942,714.596741 788.398621,714.856689 
	C782.469421,717.083374 781.519958,716.469421 780.917603,710.410095 
	C780.798767,709.214661 779.725830,708.114197 779.024841,706.961304 
	C779.024841,706.961304 779.042969,706.895630 778.914490,706.222839 
	C777.761902,706.266357 776.609253,706.309875 775.456665,706.353455 
	C775.997498,707.228516 776.538269,708.103516 777.134277,709.656921 
	C777.106689,710.109253 777.079163,710.561584 776.360291,711.002625 
	C775.594116,711.002625 774.828003,711.002625 774.009583,711.002625 
	C774.423157,712.549438 774.762634,713.819214 775.109314,715.089966 
	C775.109314,715.089966 775.122192,715.106873 774.294312,715.048584 
	C771.849487,715.378601 769.404602,715.708618 767.000000,716.003235 
	C767.000000,716.003235 766.961548,716.044373 767.005310,715.298340 
	C768.275146,712.796448 769.544983,710.294556 771.100159,707.230347 
	C767.696350,707.230347 763.849609,706.176514 762.641785,707.511047 
	C761.290710,709.003906 762.331665,712.661621 762.331665,716.294373 
	C759.069824,715.856201 755.517395,716.028564 752.504883,714.836548 
	C746.562500,712.485046 745.054382,712.812561 741.622681,719.127441 
	C741.622681,719.127441 741.220581,719.137634 740.435486,719.035583 
	C739.568298,718.275208 738.701172,717.514771 737.874634,716.367493 
	C737.874634,716.367493 738.011047,716.003296 738.700317,715.937805 
	C742.239380,714.437195 740.658142,712.742371 738.656128,711.519165 
	C735.520325,709.603271 733.374695,711.179932 731.228210,716.099060 
	C730.480103,716.010315 729.731995,715.921570 728.585754,715.220032 
	C725.913696,713.786743 723.254395,712.509705 720.343201,715.999878 
	C719.916016,716.064941 719.488892,716.130005 718.680237,715.623718 
	C716.096558,715.793274 713.089783,715.363647 713.260071,719.975891 
	C713.162537,720.314697 713.064941,720.653442 712.132812,721.000000 
	C711.010803,721.041626 709.888733,721.083252 708.254944,720.674622 
	C702.698792,713.773315 696.217041,713.433411 687.280334,718.039490 
	C685.517761,718.030151 683.755188,718.020752 681.489563,718.020569 
	C681.322510,718.006042 681.155396,717.991516 680.439209,717.403137 
	C677.963928,717.265198 675.488586,717.127319 673.005249,716.091980 
	C673.005249,713.238892 673.005249,710.385864 673.005249,707.532776 
	C672.544434,707.482910 672.083618,707.433044 671.622803,707.383118 
	C670.058960,710.906738 668.495178,714.430298 666.190369,717.972656 
	C665.449402,717.989624 664.708374,718.006653 663.817139,717.711060 
	C663.604004,717.839600 663.390869,717.968079 662.986816,718.449158 
	C662.986816,718.449158 662.856750,718.828491 662.075256,719.009338 
	C660.541443,718.661194 659.020264,718.116333 657.470459,718.016907 
	C655.715210,717.904419 653.934448,718.191345 651.388123,718.175903 
	C650.631165,718.300171 649.874207,718.424377 648.341675,718.364990 
	C645.981567,718.927063 643.214600,715.166992 640.671387,719.343384 
	C640.119629,719.502380 639.567871,719.661438 638.572266,719.461731 
	C638.098694,719.899353 637.625061,720.336975 637.151489,720.774658 
	C637.784180,721.070557 638.416809,721.366455 639.571899,721.841003 
	C639.748962,721.891418 639.925964,721.941833 640.116760,722.754089 
	C640.391602,724.177429 640.666443,725.600830 640.819214,727.765381 
	C640.879517,728.510254 640.939758,729.255127 641.000061,730.503052 
	C641.006653,730.670593 641.013245,730.838196 640.909363,731.499451 
	C640.938782,731.665527 640.968201,731.831543 641.011047,732.521606 
	C641.048035,732.692383 641.085022,732.863159 640.966492,733.895569 
	C640.999878,735.933716 641.033264,737.971802 640.970520,740.481873 
	C640.970520,740.481873 640.960266,740.963318 640.970764,740.979431 
	C640.970764,740.979431 640.951904,740.962585 640.086548,741.033203 
	C637.349731,741.128418 633.957031,742.134277 633.974365,737.610352 
	C634.006592,729.182190 634.230103,720.754822 635.076172,712.242737 
	C635.124817,711.543091 635.173462,710.843384 635.222107,710.143738 
	C632.302917,710.115234 629.382263,710.044495 626.467590,710.145874 
	C626.255554,710.153259 626.086975,711.410278 626.490417,712.627258 
	C627.044800,714.052551 628.016479,715.460083 628.077881,716.906250 
	C628.288940,721.876648 628.405579,726.873291 628.123779,731.835266 
	C627.901978,735.741394 627.039246,739.611084 626.385864,744.003418 
	C633.075256,744.003418 640.740845,744.003418 649.188660,744.003418 
	C647.574890,740.999451 646.464233,738.931946 645.247498,736.152344 
	C645.187744,735.434814 645.128052,734.717224 645.168823,733.518799 
	C645.168823,733.518799 645.100159,733.032288 645.549561,732.447266 
	C645.867371,729.952942 646.185181,727.458557 646.739929,724.291809 
	C646.724609,723.819458 646.709290,723.347107 647.360046,722.495178 
	C652.842651,719.270325 654.973328,720.337830 655.115356,726.413513 
	C655.158142,728.242126 655.054016,730.073975 655.087341,731.903076 
	C655.248108,740.737732 660.110413,745.247070 669.742554,744.903137 
	C672.439636,744.211487 675.239502,743.760010 677.768982,742.676941 
	C678.495361,742.365906 678.441040,740.231628 679.230469,738.437317 
	C680.816833,737.297424 682.403259,736.157532 684.245483,735.531677 
	C684.652893,735.936523 685.060303,736.341370 685.268799,737.432373 
	C686.499329,738.634888 687.729858,739.837463 689.168152,741.723145 
	C696.022827,747.764099 707.652161,743.129333 710.188721,734.826782 
	C708.316162,735.088318 705.937683,735.473022 705.194763,736.784302 
	C703.241150,740.232727 700.365967,740.449646 697.164001,740.085083 
	C693.326538,739.648071 692.356384,737.248108 694.279907,733.957703 
	C695.146667,732.474854 695.450439,730.662842 696.677307,729.013123 
	C697.122314,729.005249 697.567322,728.997437 698.264038,729.364380 
	C698.476440,729.604065 698.742737,729.725708 699.903564,729.915894 
	C702.872864,729.575439 705.842163,729.235046 709.639709,728.966797 
	C710.737610,728.985046 711.835571,729.003296 713.274109,729.614746 
	C715.142517,731.738098 717.010986,733.861450 719.245850,736.401184 
	C717.731873,737.028198 716.449341,737.559265 714.404297,738.085754 
	C710.143555,740.874268 709.874023,742.272888 713.149780,745.560425 
	C713.150879,745.946106 713.151978,746.331848 713.126709,747.456604 
	C715.779846,748.547302 718.432983,749.637939 721.984131,750.806030 
	C728.114746,751.084229 734.415039,751.823730 738.770264,745.001648 
	C743.054749,745.301758 747.337708,745.750122 751.624878,745.795410 
	C752.669495,745.806335 753.728027,744.492981 754.780151,743.788513 
	C754.157288,743.211121 753.534485,742.633728 752.470642,741.953491 
	C752.470642,741.953491 752.033203,741.836487 751.933472,741.191589 
	C750.962280,740.458374 749.991089,739.725159 748.563782,738.947388 
	C748.563782,738.947388 748.106873,738.982727 748.291687,738.113953 
	C748.275513,735.760010 748.259399,733.406067 748.658691,731.089783 
	C748.658691,731.089783 749.071289,731.028381 749.748230,730.885132 
	C749.767395,729.571838 749.786560,728.258606 749.859009,726.295715 
	C749.901062,725.863281 749.943176,725.430908 750.811279,724.998352 
	C754.645081,726.180481 759.833191,723.582031 762.016052,729.923950 
	C762.051086,732.263306 762.086121,734.602722 761.562500,737.347717 
	C761.448669,737.968933 761.045959,738.726074 761.264587,739.190979 
	C764.371643,745.798401 779.064636,748.691589 784.408691,743.749756 
	C784.809814,743.378784 784.769043,742.529785 784.961182,741.449097 
	C784.961182,741.449097 784.992737,740.995422 785.633301,740.912842 
	C786.091309,740.279053 786.549377,739.645325 787.338928,738.391479 
	C788.282043,735.944763 789.225159,733.498108 790.907654,731.008301 
	C793.258911,731.009644 795.610107,731.011047 798.298645,731.581482 
	C798.683411,732.373535 799.068176,733.165588 799.280823,734.564758 
	C799.885071,735.291870 800.489258,736.019043 801.143677,737.470703 
	C801.176025,737.953796 801.208374,738.436890 800.387878,738.965271 
	C798.590454,739.302002 796.793091,739.638733 794.517944,740.000916 
	C794.517944,740.000916 794.039856,740.019409 793.264099,740.094849 
	C790.129944,743.023804 790.042480,746.358521 793.007751,748.982605 
	C793.007751,748.982605 793.022583,748.969910 793.089966,749.643921 
	C793.784180,750.060608 794.478455,750.477295 795.976807,750.940674 
	C797.318542,750.950867 798.660278,750.960999 800.109497,751.659912 
	C800.866882,751.943542 801.624268,752.227173 802.381653,752.510803 
	C803.207703,749.993347 804.033813,747.475830 804.915527,744.198975 
	C805.324280,742.480225 805.732971,740.761536 806.848022,738.923889 
	C811.747986,735.026306 810.987305,729.218323 811.961243,723.229858 
	C812.056152,722.489807 812.151062,721.749817 812.592590,720.752075 
	C812.803589,720.540100 812.937134,720.287170 813.000000,720.000000 
M577.671204,742.987305 
	C578.450867,742.335144 579.230530,741.682922 580.504272,740.578857 
	C581.979614,738.059021 583.454956,735.539185 585.406555,732.737427 
	C585.262512,732.156006 585.118469,731.574646 584.985229,730.326782 
	C584.990417,729.882507 584.995667,729.438232 584.865479,728.596863 
	C584.735718,728.341431 584.544922,728.145447 584.208496,727.266113 
	C584.132202,726.522766 584.055847,725.779419 584.004517,724.220337 
	C585.148376,715.649780 583.959961,714.633667 575.480469,716.928284 
	C575.321411,716.971252 575.151855,716.975342 574.841736,716.108032 
	C574.846741,713.972595 575.083496,711.801880 574.765991,709.715515 
	C574.588013,708.546631 573.491943,707.517456 572.809509,706.425293 
	C572.468445,707.514404 571.985413,708.581665 571.813843,709.696838 
	C571.360962,712.640259 572.044312,716.306641 566.294800,716.041931 
	C561.568115,715.677246 556.841370,715.312500 551.579956,714.916931 
	C551.402222,714.900879 551.224426,714.884766 550.941040,714.309265 
	C550.274353,714.471558 549.607605,714.633850 548.197388,714.860046 
	C547.275391,715.053467 546.353455,715.246826 545.431519,715.440186 
	C545.896790,715.619385 546.362061,715.798523 546.827393,715.977661 
	C546.250244,719.552979 545.656616,723.125732 545.100647,726.704346 
	C544.569946,730.119934 542.769775,730.725098 539.893677,728.364502 
	C539.347900,724.144409 538.802124,719.924377 538.194519,715.226257 
	C535.901001,715.226257 532.548523,715.226257 527.939880,715.226257 
	C533.821472,721.612671 528.602600,726.118896 527.534546,731.365173 
	C524.937439,727.508545 523.128601,723.485352 521.693359,719.333008 
	C519.829956,713.941528 517.914124,713.174561 513.229492,715.860596 
	C516.284668,722.928772 519.311890,729.932434 522.208008,737.674255 
	C523.422607,739.326416 524.637268,740.978577 525.851929,742.630737 
	C527.040161,740.722778 528.228394,738.814819 529.929688,736.315674 
	C530.910339,733.709534 531.890930,731.103394 532.871521,728.497192 
	C533.352417,728.622437 533.833313,728.747681 534.314270,728.872925 
	C535.197510,730.919739 536.080750,732.966553 537.000000,735.660828 
	C537.353638,736.446350 537.707336,737.231812 537.681824,738.367126 
	C538.091003,738.966370 538.500183,739.565613 539.007202,740.526062 
	C539.007202,740.526062 539.162598,740.866516 539.151184,741.541809 
	C540.084412,741.346497 541.017700,741.151123 542.389526,740.743835 
	C542.664368,740.555115 542.872498,740.309753 543.719727,739.864136 
	C544.083313,738.546814 544.446838,737.229553 544.880188,735.461365 
	C544.880188,735.461365 544.848816,735.006165 545.438416,734.625854 
	C547.348572,729.771423 549.258667,724.916931 551.930786,720.025269 
	C552.956055,720.015198 553.981384,720.005188 555.492554,720.603943 
	C556.015686,724.791260 556.196655,729.061218 557.180481,733.137390 
	C557.853577,735.926575 559.645203,738.445923 561.152100,741.524719 
	C561.357544,741.803650 561.632019,741.965698 562.452454,742.460754 
	C563.299500,742.629333 564.146606,742.797913 565.612366,743.333374 
	C566.760071,743.645264 567.907715,743.957092 569.656311,744.731506 
	C572.153503,744.388672 575.330750,746.852295 576.994995,743.002441 
	C576.994995,743.002441 576.958313,742.964905 577.671204,742.987305 
M289.074341,743.912231 
	C289.074341,743.912231 289.098328,743.890015 289.040680,744.603760 
	C289.788086,745.115417 290.679413,746.177856 291.253876,746.027771 
	C292.581421,745.680908 293.755371,744.746338 295.387299,743.919617 
	C295.387299,743.919617 295.801971,743.878784 296.665466,743.966064 
	C298.092590,743.980347 299.519714,743.994629 301.479858,744.061523 
	C301.656738,744.085754 301.833618,744.110046 302.205872,744.427002 
	C302.470825,744.310852 302.735748,744.194641 303.659515,743.996948 
	C304.101868,743.986816 304.544189,743.976624 305.360718,744.489990 
	C306.255646,744.279480 307.150543,744.068970 308.696503,743.870972 
	C309.126831,743.926331 309.557159,743.981750 310.152557,744.779968 
	C312.648285,746.509766 315.037567,747.229919 317.674347,743.981079 
	C318.115082,743.996216 318.555847,744.011414 319.178223,744.362732 
	C319.451904,744.238647 319.725586,744.114563 320.439087,743.662476 
	C320.557343,743.138611 320.675598,742.614685 321.577881,741.915405 
	C322.499176,739.223450 323.420471,736.531494 324.628784,733.000854 
	C321.535004,733.000854 319.764099,733.000854 317.992920,732.214783 
	C317.938385,728.544312 319.858429,726.371765 323.261047,725.404297 
	C325.150635,724.867004 327.077362,724.460327 329.705292,723.918091 
	C334.062592,722.311340 330.431366,719.058105 331.069183,716.609314 
	C333.054199,708.988464 330.541870,706.874329 322.993896,708.586609 
	C322.993896,710.824646 322.993896,712.920898 322.184143,715.013672 
	C320.043610,715.391907 317.903076,715.770142 315.477417,716.181763 
	C315.477417,716.181763 315.193665,716.138489 314.390564,716.052063 
	C312.598358,716.371033 310.806152,716.690063 308.508270,716.595154 
	C309.158630,713.167847 311.475189,708.901917 305.519409,708.113892 
	C300.981964,707.513611 296.500641,707.328003 292.683655,704.267944 
	C292.247040,703.917847 290.323242,705.071167 289.402496,705.882996 
	C289.112671,706.138550 289.926208,707.541199 290.096680,708.444946 
	C290.445282,710.292969 290.700317,712.158691 290.299652,714.006531 
	C289.836670,714.027649 289.373688,714.048767 288.516235,714.094055 
	C288.516235,714.094055 288.121033,714.091919 287.298981,714.028198 
	C285.012360,714.124084 282.216888,713.366821 282.011688,717.084717 
	C282.011688,717.084717 282.118713,717.076355 281.589417,717.312927 
	C281.857147,717.813049 282.124878,718.313171 282.731628,718.757996 
	C282.731628,718.757996 283.062836,718.849060 283.074066,719.485657 
	C283.620941,720.009094 284.167786,720.532532 284.789429,721.910522 
	C284.734772,723.616394 284.680145,725.322266 284.420013,727.228271 
	C284.284943,727.519226 284.351135,727.766113 284.764679,728.865417 
	C284.734650,731.584106 284.704620,734.302734 284.451904,737.232056 
	C284.316772,737.550598 284.406494,737.803040 284.872620,738.635010 
	C285.000336,739.058044 285.128052,739.481140 284.674927,740.255676 
	C284.403381,743.575806 286.513641,744.018799 289.074341,743.912231 
M247.239487,448.382843 
	C246.624695,446.972076 246.009888,445.561279 245.549316,443.394348 
	C245.521744,442.631348 245.494156,441.868378 245.683105,440.500610 
	C245.610153,440.299316 245.537186,440.098022 245.606873,439.284058 
	C245.474854,438.894165 245.342834,438.504303 245.480713,437.460297 
	C245.045227,436.054352 244.609741,434.648407 243.812546,432.074677 
	C240.303711,437.574646 237.005936,441.632904 234.951065,446.244904 
	C232.361038,452.058044 228.248550,452.156189 223.147873,451.149872 
	C218.719742,450.276215 214.231964,449.704712 209.770554,448.999664 
	C209.512695,449.438232 209.254837,449.876801 208.996994,450.315369 
	C212.856201,456.540741 216.715408,462.766113 220.480026,469.366699 
	C220.591415,469.656006 220.799835,469.772827 221.119995,469.838867 
	C221.119995,469.838867 221.235657,469.879364 221.120972,470.667084 
	C220.574631,472.561005 219.799530,474.426056 219.555420,476.358154 
	C219.365356,477.862366 219.836411,479.450043 219.390930,481.052460 
	C218.965164,481.799774 218.539413,482.547119 217.430252,483.405365 
	C216.193161,486.245148 214.956070,489.084900 213.718979,491.924683 
	C214.086990,492.315918 214.454987,492.707184 214.822983,493.098450 
	C217.317810,492.575470 219.802216,491.994873 222.309631,491.541626 
	C227.294678,490.640472 232.292389,489.809418 237.521927,489.559479 
	C241.828522,494.627258 246.135117,499.695038 250.441711,504.762817 
	C251.042648,504.534607 251.643570,504.306396 252.244507,504.078186 
	C252.244507,500.733948 252.721695,497.306274 252.154144,494.061310 
	C250.518372,484.708923 252.760941,477.338593 262.398712,472.823425 
	C263.502655,472.155029 264.682343,471.584534 265.696198,470.799744 
	C268.602631,468.550049 269.248779,466.492950 264.518982,464.901947 
	C261.125702,463.217316 257.732422,461.532654 254.420929,459.810883 
	C254.420929,459.810883 254.421783,459.840668 253.565674,459.529938 
	C252.060822,459.336395 250.555969,459.142853 249.099319,458.211395 
	C248.730942,456.795929 248.362564,455.380493 247.966476,453.472565 
	C247.966476,453.472565 247.772156,453.018219 248.223602,452.617432 
	C247.855087,451.432007 247.486572,450.246613 247.239487,448.382843 
M757.382935,549.457153 
	C757.926636,549.951172 758.470337,550.445190 758.996643,550.996094 
	C758.996643,550.996094 759.055420,550.987305 759.149597,551.674133 
	C760.281128,552.785767 761.412659,553.897339 762.544189,555.008972 
	C762.949646,554.792542 763.355042,554.576050 763.760498,554.359619 
	C763.576904,552.878296 763.393372,551.397034 763.472595,549.254822 
	C763.477905,548.783875 763.483215,548.312866 764.100342,547.544678 
	C764.405090,546.702209 764.709839,545.859741 765.537292,544.513428 
	C766.689880,542.032593 767.842407,539.551758 769.483093,536.957947 
	C769.622864,536.866577 769.762634,536.775269 770.612549,536.816650 
	C774.075989,535.604675 777.539429,534.392700 781.002869,533.180725 
	C780.981140,532.468323 780.959351,531.755859 780.937622,531.043457 
	C776.938171,529.380676 772.938782,527.717957 768.258484,525.770935 
	C767.537292,524.342041 766.362427,522.967773 766.182495,521.473694 
	C765.704468,517.502502 765.655457,513.479553 765.435730,509.477234 
	C764.988892,509.237244 764.542114,508.997253 764.095337,508.757294 
	C762.460327,510.122040 760.891785,511.577728 759.175720,512.831665 
	C756.142273,515.048218 753.018982,517.141968 749.471191,518.691650 
	C746.639160,518.147217 743.807068,517.602722 740.321411,516.908875 
	C739.876648,516.920715 739.431885,516.932495 738.650330,516.491516 
	C738.519714,517.640442 738.389160,518.789429 738.371704,520.627686 
	C740.016541,524.460449 741.661377,528.293152 742.979126,532.373108 
	C742.979126,532.373108 742.790588,532.736877 742.143799,532.949707 
	C741.246948,535.015869 740.350159,537.082092 739.167297,539.303955 
	C739.167297,539.303955 739.024902,539.596863 738.722961,539.464722 
	C738.722961,539.464722 738.499023,539.703247 738.499023,539.703247 
	C738.499023,539.703247 738.661804,539.987915 738.019043,540.314453 
	C737.733704,540.689697 737.448303,541.064941 736.645325,541.705383 
	C736.363098,542.601624 736.080811,543.497864 735.798584,544.394104 
	C736.557434,544.410034 737.316284,544.425964 738.801147,544.619812 
	C739.515198,544.453918 740.229187,544.288025 741.654480,544.465576 
	C744.820862,543.632935 747.987305,542.800293 751.679016,542.114685 
	C752.175171,542.328247 752.671326,542.541870 753.149902,543.466858 
	C754.494263,545.138000 755.838562,546.809082 757.242432,548.742676 
	C757.242432,548.742676 757.479553,548.869812 757.382935,549.457153 
M775.809998,465.597931 
	C775.568237,465.384338 775.326477,465.170746 775.063599,464.951141 
	C775.063599,464.951141 775.069214,464.923096 775.126038,464.225067 
	C775.852417,462.581024 776.578796,460.936981 777.891663,459.004242 
	C778.260864,456.828583 778.260864,456.828583 773.278503,456.825256 
	C770.628906,454.837402 767.979248,452.849548 765.225098,450.075012 
	C766.487610,449.294556 767.750183,448.514069 769.187439,447.625580 
	C768.200195,446.509094 767.251465,445.436035 766.595581,443.554535 
	C766.028503,442.370178 765.661255,440.347961 764.857422,440.156219 
	C762.293518,439.544678 759.567322,439.613281 757.281311,439.446686 
	C756.445801,442.114227 755.624878,444.735168 754.591919,448.032959 
	C755.927856,448.246368 757.550232,448.505493 759.207153,448.839233 
	C759.207153,448.839233 759.249573,448.909637 759.153076,449.501770 
	C759.472595,449.956757 759.792175,450.411743 760.334839,451.402222 
	C761.071594,451.652832 761.859253,452.199951 762.533020,452.084198 
	C763.530334,451.912872 764.446777,451.270966 765.190186,451.597168 
	C764.119751,456.314240 762.518921,460.633057 756.366577,461.534698 
	C756.553650,461.976624 756.740662,462.418549 756.927673,462.860474 
	C758.965271,463.661896 761.002808,464.463318 763.287048,465.351685 
	C763.287048,465.351685 763.515442,465.221802 763.496216,465.908112 
	C763.535339,466.821777 763.574402,467.735443 763.267761,469.255493 
	C763.267761,470.546570 763.267761,471.837677 763.267761,473.128754 
	C763.693665,473.250397 764.119629,473.372040 764.545532,473.493683 
	C766.171265,471.759857 767.796997,470.026062 769.795959,468.391602 
	C769.795959,468.391602 770.181946,468.412109 770.181946,468.412109 
	C770.181946,468.412109 770.559143,468.327881 771.001770,468.848907 
	C772.729065,469.450745 774.456360,470.052582 777.210327,471.012177 
	C776.542053,468.679474 776.172607,467.389984 775.809998,465.597931 
M594.423218,706.654297 
	C594.205566,706.172363 593.987915,705.690491 593.770264,705.208557 
	C593.193115,705.816101 592.615967,706.423645 591.584595,707.148132 
	C591.584595,707.148132 591.137329,707.289673 590.335632,707.123901 
	C588.937988,707.298950 587.540283,707.473938 584.968262,707.795959 
	C587.119507,709.811401 588.243347,710.864380 589.267273,712.735779 
	C589.428589,713.532715 589.589966,714.329651 589.417419,715.837585 
	C589.552734,718.518616 589.688049,721.199585 589.512451,724.126465 
	C589.461792,724.247925 589.339172,724.398621 589.374329,724.485168 
	C589.468689,724.717651 589.630615,724.922546 589.795471,725.956726 
	C589.765503,727.593323 589.735596,729.229919 589.389099,731.268433 
	C589.517334,731.880798 589.645630,732.493164 589.747498,733.746948 
	C589.834106,734.165283 589.920654,734.583679 589.486145,735.466431 
	C588.324829,738.230469 587.163574,740.994446 585.615540,744.679138 
	C589.894104,744.443604 593.738586,744.231934 598.176270,743.987671 
	C596.283936,740.189392 594.992615,737.597351 593.993530,734.414124 
	C594.066589,733.981995 594.139587,733.549927 594.495117,732.855530 
	C594.381042,732.536804 594.267029,732.218079 594.185303,731.299866 
	C594.186401,730.899902 594.187500,730.499878 594.612854,729.564697 
	C594.695984,727.714294 594.779114,725.863892 594.976562,723.375977 
	C595.018127,722.946289 595.059692,722.516541 595.737915,721.926636 
	C596.196960,720.995361 596.656006,720.064026 597.965027,719.008606 
	C602.832397,717.736511 604.233521,720.274475 604.182800,724.566711 
	C604.127991,729.212219 603.920715,733.878113 604.257019,738.500427 
	C604.690491,744.458984 608.622070,746.288391 613.755249,742.633057 
	C614.806458,740.329285 618.044189,737.874878 612.759094,735.401245 
	C609.061646,733.957031 609.778931,730.757080 609.768555,727.856018 
	C609.723694,715.324280 607.697021,714.782288 596.387512,716.087952 
	C595.611511,715.053955 594.835449,714.020020 594.171692,712.285583 
	C594.169861,711.576965 594.167969,710.868286 594.459412,709.879883 
	C594.324219,709.562561 594.188965,709.245300 594.049744,708.286621 
	C594.017334,707.860352 593.984924,707.434021 594.423218,706.654297 
M496.001221,742.000000 
	C496.001221,742.000000 496.015411,742.011414 496.717102,741.934265 
	C498.076996,740.566467 499.542267,739.281555 500.726013,737.775391 
	C501.039642,737.376343 500.727661,736.020752 500.259674,735.612305 
	C499.849548,735.254333 498.598938,735.320068 498.098236,735.698975 
	C495.946991,737.326965 493.935486,739.139587 491.228149,740.945496 
	C490.801208,741.014282 490.374298,741.083069 489.528564,740.588623 
	C484.560211,739.548706 483.088745,736.686340 485.793762,731.922058 
	C487.533051,730.955505 489.272339,729.989014 491.803101,728.993713 
	C492.857971,729.023987 493.912842,729.054260 495.134155,729.786255 
	C497.075470,731.389771 498.869629,732.156921 500.183563,728.444275 
	C500.434143,727.972229 500.684723,727.500122 501.505035,726.562073 
	C501.156616,717.248108 499.333221,715.063354 491.030212,715.051331 
	C480.467316,714.990417 476.167755,717.980713 475.087311,726.578003 
	C475.087311,726.578003 475.069611,727.061768 474.522278,727.571167 
	C474.944885,729.770081 475.367493,731.968994 475.872772,734.820862 
	C476.216492,735.581543 476.560211,736.342224 476.996399,737.791077 
	C478.177551,738.921265 479.358704,740.051453 480.873566,741.893433 
	C485.368103,745.260437 490.134338,744.380310 495.422363,742.736694 
	C495.730042,742.583984 495.926483,742.341187 496.001221,742.000000 
M248.778259,597.957336 
	C251.527222,598.633240 254.276184,599.309082 257.297363,600.663940 
	C256.785248,601.944153 256.273132,603.224365 255.652740,604.775269 
	C257.029144,605.565918 258.412811,606.360718 259.990601,607.824036 
	C260.535736,609.096619 261.080872,610.369202 261.740692,611.909546 
	C263.373657,610.729553 264.430878,609.536072 265.736908,609.115723 
	C268.099365,608.355408 270.993195,608.488647 269.086670,604.547852 
	C268.107758,602.524475 267.645355,600.251221 266.281647,597.988831 
	C265.841187,598.060303 265.400696,598.131775 264.567108,597.658508 
	C263.704987,597.458374 262.842865,597.258240 261.612885,596.568542 
	C260.767517,596.806580 259.745300,596.826904 259.119537,597.335266 
	C258.250031,598.041687 257.677765,599.114014 256.994781,599.207825 
	C256.345428,596.477722 255.696106,593.747681 255.634186,590.723816 
	C256.501343,589.478088 258.100067,588.175476 258.008972,587.004272 
	C257.933289,586.031372 255.916214,585.140259 254.673630,584.346863 
	C253.444199,583.561707 252.107620,582.944336 250.803070,582.173157 
	C250.803070,582.173157 250.749283,582.111877 250.854126,581.400818 
	C250.062378,580.018799 249.270645,578.636841 248.478912,577.254822 
	C246.865784,582.471436 244.364609,585.774658 237.654129,586.532532 
	C240.278824,588.600403 241.699356,589.719604 243.438309,591.062500 
	C243.438309,591.062500 243.791824,591.225281 243.880280,591.984924 
	C243.880280,594.752625 243.880280,597.520325 243.880280,600.288025 
	C244.351639,600.314575 244.822998,600.341064 245.294357,600.367554 
	C245.646301,599.679932 245.998245,598.992249 246.886124,598.215271 
	C247.248688,598.156189 247.611267,598.097107 248.778259,597.957336 
M531.714417,654.810669 
	C531.939514,655.246033 532.164612,655.681396 532.312134,656.994812 
	C532.431458,659.354797 532.550842,661.714722 532.359558,664.945862 
	C532.596802,670.946228 532.834106,676.946655 533.061951,683.698792 
	C534.184998,687.182068 537.201843,687.675232 539.794739,686.743713 
	C541.578491,686.102844 542.704834,683.632141 544.368408,681.492432 
	C544.282349,681.332764 544.196228,681.173096 544.320190,680.094543 
	C544.230408,672.393555 544.140686,664.692505 544.251465,656.219788 
	C544.059937,655.200378 543.868469,654.181030 543.735840,652.460693 
	C543.255737,651.199341 542.775696,649.937927 542.092712,648.026611 
	C536.806274,640.841736 529.047363,639.016174 519.989502,642.475037 
	C518.258972,643.135925 515.991699,642.987915 514.123657,642.556519 
	C511.100647,641.858521 508.549347,641.797485 506.281342,644.995239 
	C506.276794,646.166077 506.272278,647.336914 506.568665,649.120911 
	C506.973755,649.661438 507.378815,650.201904 507.790710,651.715210 
	C507.796173,661.303345 507.699707,670.893127 507.859222,680.478699 
	C507.919678,684.112915 509.493896,686.948975 513.737854,687.024536 
	C517.765320,687.096252 520.416748,685.600281 520.148193,680.076782 
	C520.121155,671.712524 520.094177,663.348267 520.446350,654.470764 
	C521.061768,653.371460 521.677124,652.272095 522.980713,651.198792 
	C523.658020,651.081177 524.335266,650.963562 525.659302,651.127686 
	C527.668884,652.187805 529.678406,653.247925 531.714417,654.810669 
M591.433105,686.588440 
	C592.576721,684.749695 593.720398,682.910950 595.080994,680.148926 
	C595.034119,674.417236 594.987305,668.685547 595.302368,662.534180 
	C595.503235,662.016174 595.704041,661.498169 596.850159,660.892700 
	C602.261475,660.892700 607.672791,660.892700 614.032166,660.892700 
	C614.032166,668.254395 614.032166,675.115845 613.964478,682.717896 
	C615.147766,685.986206 617.621277,687.856506 620.842773,686.728455 
	C622.818542,686.036682 624.530029,683.848999 625.726074,681.936462 
	C626.504211,680.692261 626.265076,678.735168 626.270386,677.096741 
	C626.308960,665.182800 626.384338,653.267578 626.249512,641.355103 
	C626.198364,636.837219 625.623779,632.325195 625.109436,627.096008 
	C622.686035,623.540527 619.639160,623.005127 615.445801,625.192993 
	C614.924805,626.449036 614.403870,627.705078 613.696106,629.886108 
	C613.600586,636.253479 613.505066,642.620911 612.437805,649.194092 
	C606.695984,649.112122 600.954224,649.030151 595.183716,648.278687 
	C595.121277,647.837158 595.058899,647.395569 595.344238,646.667786 
	C595.223572,646.129456 595.102966,645.591064 595.091736,644.128296 
	C595.058167,640.399170 595.024658,636.670044 595.374817,632.368896 
	C594.557617,629.998230 593.740356,627.627625 592.292847,624.821289 
	C589.886047,624.964722 587.479309,625.108154 584.470886,625.402588 
	C583.971069,627.248840 583.471191,629.095154 582.834656,631.876953 
	C582.901794,637.921082 582.968933,643.965149 582.691040,650.501648 
	C582.778687,651.674866 582.866333,652.848145 582.797180,654.944946 
	C582.887146,663.647156 582.977173,672.349426 582.740723,681.496277 
	C584.220093,683.148438 585.699463,684.800537 587.876648,686.720337 
	C588.851746,686.631348 589.826904,686.542297 591.433105,686.588440 
M238.448624,576.043823 
	C238.716980,576.330078 238.985336,576.616394 239.268906,577.575623 
	C240.165726,578.296875 241.062531,579.018188 241.959335,579.739441 
	C242.333679,579.555847 242.708023,579.372314 243.082367,579.188721 
	C242.784332,576.725891 242.486282,574.263123 242.314240,571.314941 
	C242.317444,570.981873 242.320648,570.648865 242.694809,569.738220 
	C245.155594,567.031616 247.616394,564.325073 250.272049,561.404175 
	C249.489182,561.156677 248.746429,560.921814 247.701401,559.988403 
	C240.645264,557.907288 238.363159,552.144958 237.532227,544.310730 
	C233.581619,549.791565 232.161530,556.200867 224.307724,554.313782 
	C223.923187,554.364441 223.538635,554.415161 222.504745,554.258240 
	C219.616943,557.719849 223.201675,558.523682 224.932999,560.768982 
	C225.337692,561.488464 225.742401,562.207947 226.126587,563.445923 
	C226.208023,563.598572 226.289444,563.751160 226.304779,564.726624 
	C225.730515,566.907776 225.015457,569.064148 224.625824,571.277771 
	C224.304840,573.101379 223.665176,575.620483 227.781418,574.228394 
	C228.156723,574.187317 228.532013,574.146240 229.672348,574.003479 
	C231.136276,573.596008 232.600220,573.188538 234.464630,573.265625 
	C235.349258,573.826904 236.233887,574.388123 237.263229,575.363037 
	C237.263229,575.363037 237.599533,575.639893 237.599533,575.639893 
	C237.599533,575.639893 238.034866,575.625122 238.448624,576.043823 
M461.165405,657.589050 
	C461.200165,660.039001 461.234894,662.489014 461.193665,665.820068 
	C461.303894,668.159119 461.414093,670.498108 461.459564,673.578186 
	C462.641785,676.045898 463.823975,678.513611 465.311798,681.577393 
	C469.089325,685.218323 473.478394,687.114075 479.630920,686.694580 
	C485.537292,686.885925 491.082062,686.093384 495.672089,680.659119 
	C500.957092,673.344238 498.700104,664.985962 499.293732,656.457153 
	C498.408356,653.950134 497.523010,651.443115 496.480835,648.213074 
	C490.472412,641.016602 481.898132,639.081604 470.517639,642.438232 
	C468.393127,644.300354 466.268616,646.162537 463.625732,648.528076 
	C462.896240,651.284973 462.166748,654.041931 461.165405,657.589050 
M671.001892,643.999146 
	C671.001892,643.999146 671.002747,643.985901 670.921326,643.299866 
	C663.994263,639.972412 659.911743,642.357910 659.863525,649.805786 
	C659.819580,656.595154 659.519836,663.404968 659.946045,670.167175 
	C660.207520,674.316223 658.598145,676.062134 654.863708,676.360962 
	C651.009094,676.669434 648.933044,674.710876 648.664124,670.888672 
	C648.524475,668.903442 648.505920,666.908875 648.460693,664.917908 
	C648.321777,658.793030 648.538269,652.628906 647.848206,646.567566 
	C647.652405,644.847107 644.665710,642.013672 643.248169,642.178467 
	C640.750488,642.468689 636.225586,641.817261 636.063782,645.706238 
	C635.687317,654.756165 636.124878,663.839905 636.312073,673.579102 
	C636.426636,674.011780 636.541138,674.444458 636.408142,675.548462 
	C638.558411,678.432800 640.708740,681.317139 643.366211,684.698425 
	C649.375610,687.699890 655.339600,687.540649 661.449646,684.818787 
	C662.816650,684.209778 664.932678,684.173218 666.259644,684.805481 
	C669.405029,686.303955 672.177063,686.222717 674.192078,683.449158 
	C676.229004,680.645508 675.086609,678.201904 672.303284,676.386292 
	C672.012024,676.196167 672.067139,675.475220 672.374451,674.481750 
	C672.227722,672.974243 672.081055,671.466797 672.161133,669.196716 
	C672.110168,668.146484 672.059204,667.096191 672.314331,665.426697 
	C672.264343,663.607483 672.214355,661.788208 672.303955,659.063049 
	C672.249451,655.683533 672.194946,652.304077 672.553894,648.434143 
	C672.040405,646.955688 671.526855,645.477234 671.001892,643.999146 
M279.019348,664.420410 
	C279.403442,658.518311 280.394592,652.572388 279.972321,646.728577 
	C279.656036,642.352295 277.519257,638.107666 276.019714,633.390808 
	C276.019714,633.390808 275.654083,633.131226 275.449890,632.524841 
	C271.960175,628.034668 267.438538,624.884583 261.775696,624.629517 
	C254.551468,624.304321 247.289902,624.808289 239.435013,625.093262 
	C238.919937,625.713440 238.404861,626.333618 237.415131,626.964783 
	C237.253723,627.627441 237.092300,628.290161 236.711090,629.873779 
	C236.776794,635.910461 236.842484,641.947083 236.517273,648.636536 
	C236.588013,651.423035 236.658737,654.209534 236.621552,657.928345 
	C236.675308,664.954712 236.729065,671.981018 236.418060,679.513794 
	C237.291138,683.771423 239.413437,686.379578 245.112549,685.608704 
	C247.919540,685.678711 250.726501,685.806763 253.533508,685.807617 
	C261.346191,685.809875 268.650208,684.363647 274.877167,678.019653 
	C276.117493,675.303711 277.357819,672.587769 278.950684,669.131592 
	C278.904510,667.787781 278.858337,666.443909 279.019348,664.420410 
M683.845093,627.740112 
	C683.783569,631.883057 683.626343,636.027161 683.678894,640.168640 
	C683.821655,651.419983 684.053284,662.670227 684.244873,674.669678 
	C685.953247,685.220215 699.097595,688.944763 709.721985,685.914307 
	C716.967346,683.847717 721.085022,678.475952 721.193665,670.940186 
	C721.260498,666.304321 721.288635,661.666565 721.241638,657.030640 
	C721.103882,643.436523 710.817383,637.009094 697.509949,642.347473 
	C696.917480,641.573242 696.325012,640.799011 696.094604,639.410828 
	C696.055725,638.943420 696.016846,638.476013 696.314087,637.308167 
	C696.061584,633.732483 695.809143,630.156799 695.506470,625.881226 
	C690.967773,621.413879 687.030212,621.848999 683.845093,627.740112 
M776.150146,484.999939 
	C774.214294,481.745331 772.278442,478.490723 769.446228,473.729126 
	C767.225403,483.129883 760.908569,485.357605 752.181335,485.337952 
	C755.584106,489.157257 758.995361,491.385315 759.794800,494.323944 
	C760.630066,497.394165 758.952026,501.148102 758.372620,504.603180 
	C758.531006,505.117676 758.689331,505.632202 758.847717,506.146729 
	C765.869934,500.225586 772.452454,500.702881 778.240295,507.097992 
	C779.000183,505.658478 779.500244,505.158295 779.407959,504.819458 
	C777.821167,498.993774 777.029663,493.636658 783.656860,489.913147 
	C785.539978,488.855133 785.164429,486.625122 781.607056,486.308014 
	C780.018188,485.936920 778.429382,485.565796 776.150146,484.999939 
M300.433350,673.669495 
	C300.362274,673.400574 300.240967,673.156616 300.212830,672.304993 
	C300.138397,671.882385 300.063965,671.459839 300.369141,670.279114 
	C301.930450,669.797913 303.466156,669.195679 305.058014,668.858643 
	C309.453918,667.927734 313.873322,667.107605 318.879028,665.926453 
	C323.734497,662.429260 324.373566,657.527832 323.379486,652.145386 
	C322.410034,646.896240 319.166351,643.626831 314.177124,641.989624 
	C297.966003,636.669739 286.749054,645.729370 288.090637,663.789246 
	C288.181030,666.906555 288.271423,670.023804 288.287720,673.859680 
	C289.447083,680.253784 293.255219,684.646667 299.491089,685.729065 
	C304.436523,686.587524 309.765350,686.577209 314.716064,685.733337 
	C321.233551,684.622253 323.854340,681.249329 322.617432,677.588867 
	C321.486267,674.241394 317.543884,673.705994 311.650391,675.985229 
	C307.610687,677.547607 303.722839,677.843750 300.433350,673.669495 
M426.028259,670.673523 
	C424.510406,672.636169 422.847168,674.504761 421.505188,676.581055 
	C418.772308,680.809509 420.664703,685.443237 425.624969,685.669678 
	C434.082306,686.055847 442.588837,686.047607 451.031708,685.518433 
	C452.823883,685.406189 454.431458,682.348328 456.124054,680.647583 
	C454.506927,679.075928 453.108063,676.576416 451.225433,676.133484 
	C447.739777,675.313538 443.951263,675.872009 440.333344,675.462952 
	C439.354797,675.352295 438.535797,673.829956 438.326813,672.772339 
	C441.161163,668.825989 443.995514,664.879639 447.370483,660.528442 
	C449.389679,657.934143 451.469025,655.384155 453.416016,652.736755 
	C458.034607,646.456665 456.619080,642.990051 448.756317,642.318848 
	C442.469940,641.782104 436.120300,641.938232 429.797943,641.889038 
	C425.984985,641.859436 421.278351,641.338379 421.213745,646.855896 
	C421.150787,652.231140 425.632660,652.166199 429.593170,652.135925 
	C432.601135,652.112915 435.609436,652.131348 439.934174,652.131348 
	C436.595306,656.609985 434.255493,659.748535 431.323669,663.167908 
	C429.744843,665.531250 428.165985,667.894653 426.028259,670.673523 
M729.645264,676.979248 
	C727.743774,681.378906 729.324890,684.548279 733.451599,686.291870 
	C736.879089,687.740051 740.099609,686.774719 742.291260,683.701294 
	C744.475464,680.638245 743.874634,677.489075 741.122742,675.227661 
	C737.726318,672.436584 734.084351,672.673035 730.765259,675.708069 
	C730.520203,675.932190 730.304504,676.188354 729.645264,676.979248 
M750.645752,420.062805 
	C749.053650,421.200439 747.461548,422.338074 744.646484,424.349548 
	C748.233032,426.082214 750.500000,427.177368 753.004761,428.387421 
	C752.543457,429.202026 751.935120,430.276306 751.208191,431.559998 
	C753.790222,433.308044 754.203247,438.964172 758.762268,436.523407 
	C760.979431,435.336426 761.874512,431.679993 763.636230,428.685760 
	C759.638977,427.361206 756.882263,426.447723 754.125549,425.534241 
	C754.456055,425.105591 754.786621,424.676941 755.117188,424.248322 
	C753.882202,422.855316 752.647278,421.462311 750.645752,420.062805 
M271.070831,609.718994 
	C267.212128,612.688965 269.421173,615.626831 270.919250,618.704163 
	C274.492584,617.951538 278.196899,615.750732 280.372498,620.765076 
	C280.528931,621.125671 282.860565,620.542542 284.180847,620.398193 
	C283.822815,618.425354 283.464813,616.452454 283.032562,614.070618 
	C280.349060,615.418457 278.815613,616.188599 276.465881,617.368774 
	C276.818115,614.834778 277.052185,613.150757 277.352783,610.988220 
	C275.422699,610.393982 273.401123,609.771606 271.070831,609.718994 
M557.035156,710.514099 
	C558.287842,711.496704 559.540588,712.479309 560.793335,713.461853 
	C561.311035,712.983765 561.828796,712.505676 562.346497,712.027527 
	C561.642639,710.450500 560.938721,708.873413 560.234863,707.296326 
	C559.175598,708.096802 558.116272,708.897278 557.035156,710.514099 
M755.705872,710.025635 
	C756.241516,710.649902 756.777100,711.274231 757.312744,711.898499 
	C757.804016,711.397278 758.295288,710.896057 758.786560,710.394836 
	C757.929443,710.108459 757.072266,709.822083 755.705872,710.025635 
M745.045105,709.351868 
	C745.556519,709.213257 746.067932,709.074707 746.579346,708.936096 
	C746.059509,708.419922 745.539734,707.903687 745.019897,707.387512 
	C744.884338,707.900085 744.748718,708.412598 745.045105,709.351868 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M544.815552,264.025970 
	C550.197937,264.719818 555.677673,264.985291 560.945679,266.182343 
	C582.655212,271.115417 602.400879,280.351746 619.623779,294.535248 
	C641.135437,312.250641 657.085571,333.988800 666.241821,360.493958 
	C673.135254,380.448822 676.634888,400.952271 677.749390,422.057312 
	C679.123352,448.075287 678.352966,473.947052 674.251770,499.632080 
	C670.489014,523.197571 661.262207,544.591736 646.236755,563.291199 
	C631.359924,581.805725 613.611328,596.864380 592.304077,607.562256 
	C591.416321,608.008057 590.447632,608.292847 588.838745,608.710327 
	C587.476990,609.163635 586.793213,609.559265 586.109436,609.954956 
	C585.940491,610.019714 585.771545,610.084412 585.003479,610.190186 
	C582.960205,610.900513 581.516052,611.569824 580.071838,612.239136 
	C579.289795,612.528503 578.507751,612.817993 577.068604,613.103394 
	C575.927673,613.007690 575.443970,612.916138 575.118896,612.563232 
	C575.527283,611.538757 575.776978,610.775635 576.313904,609.997437 
	C577.061951,609.691528 577.522705,609.400818 578.393066,609.095703 
	C583.942017,607.117493 589.219421,605.445740 594.179626,603.102417 
	C598.611267,601.008667 605.078308,601.218933 605.768005,594.119446 
	C605.804504,593.743958 606.784729,593.242798 607.371216,593.170471 
	C616.906189,591.994812 622.230164,584.521912 628.679077,578.804871 
	C629.607605,577.981750 629.526367,576.019592 629.912415,574.584473 
	C629.616882,574.161621 629.321289,573.738831 629.025757,573.315979 
	C623.981140,581.299072 621.258667,573.276306 616.070679,573.520874 
	C618.541565,571.748901 619.823425,570.971985 620.868469,569.952332 
	C621.062622,569.762939 620.303101,568.596252 619.993896,567.565491 
	C621.346497,565.195190 619.312439,561.043457 623.250061,560.817810 
	C627.277954,560.586975 628.898743,558.458191 630.191345,555.072144 
	C631.529785,555.154907 632.479431,555.254211 634.123657,555.426025 
	C633.091858,558.808411 632.181946,561.791077 631.272034,564.773743 
	C630.783630,564.658691 630.295288,564.543701 629.806885,564.428650 
	C630.761536,565.322021 631.716248,566.215393 632.820251,567.248535 
	C631.428040,567.792908 630.489624,568.159729 628.968628,568.754395 
	C631.020447,570.061768 632.456970,570.977112 633.612244,571.954346 
	C633.166199,572.550476 633.001343,573.084595 632.836548,573.618713 
	C633.268127,573.120911 633.699707,572.623108 634.473450,572.077637 
	C639.156006,567.148010 643.855164,562.531311 647.723511,557.300232 
	C650.828796,553.101074 652.270020,547.598511 655.615173,543.665649 
	C661.670288,536.546692 664.416809,528.035706 667.614319,519.640015 
	C667.939941,518.785034 667.838196,517.767151 667.882812,517.332214 
	C666.096924,516.411011 664.724304,515.703003 663.351685,514.994995 
	C664.513123,514.334900 665.730408,513.754639 666.820801,512.992798 
	C667.973755,512.187134 669.675354,511.387848 669.973572,510.274139 
	C671.192505,505.722382 673.651794,501.174255 671.790588,497.266571 
	C669.286194,496.180298 667.686951,495.486603 666.087708,494.792938 
	C666.232117,494.377747 666.376465,493.962555 666.520813,493.547394 
	C671.901550,493.984253 672.679443,489.217133 673.423340,486.153931 
	C673.882935,484.261414 670.712463,481.487335 668.443176,477.984619 
	C668.157959,480.374634 668.063293,481.167786 667.645630,481.917328 
	C667.882751,479.243713 668.442871,476.613739 669.357178,473.984253 
	C670.936768,475.102692 672.162109,476.220581 673.621948,477.552368 
	C673.953979,477.041656 674.749146,476.336426 674.881470,475.523285 
	C675.378296,472.470581 675.910461,469.385529 675.945923,466.307037 
	C675.978088,463.520569 677.449585,459.446350 672.006470,459.538208 
	C672.001953,458.872009 671.999817,458.491150 672.326477,457.955688 
	C673.997070,457.317810 675.338806,456.834534 677.395081,456.093933 
	C675.492493,452.988800 673.817749,450.255615 672.141357,447.519684 
	C675.085815,446.001282 677.237610,444.239380 674.710938,440.635132 
	C674.327393,440.088013 674.173645,438.716064 674.512390,438.426910 
	C678.828186,434.742493 675.462708,430.815338 675.006226,426.679749 
	C675.026550,425.945251 675.040649,425.526123 675.334778,424.836792 
	C675.420288,422.347992 675.225769,420.129333 675.029175,417.600891 
	C675.023132,416.877991 675.019043,416.464874 675.253662,415.852661 
	C674.676453,414.706024 673.860413,413.758545 673.050903,412.640076 
	C673.057373,412.469086 673.051086,412.126953 673.189697,411.824585 
	C673.934753,410.397278 674.541138,409.272369 675.320374,407.826874 
	C673.384888,408.322632 672.225525,408.619598 671.051331,408.530701 
	C669.360596,404.434021 667.684631,400.723236 666.360352,396.958618 
	C667.476379,395.618713 668.240784,394.332703 669.046509,392.683899 
	C672.608704,385.926910 671.317749,379.468719 666.057800,376.594604 
	C668.761475,371.149872 665.930359,362.733398 660.604187,359.902222 
	C663.064880,355.035217 658.560120,344.045135 649.962769,333.670898 
	C648.305908,331.560516 646.678223,329.791473 644.978027,327.658264 
	C643.615601,326.823334 642.325623,326.352539 641.030640,325.583282 
	C641.023804,324.886749 641.022095,324.488708 641.326904,324.034302 
	C641.935730,323.534210 642.237915,323.090454 642.540100,322.646729 
	C641.982910,322.447327 641.425781,322.247925 640.552612,322.030823 
	C639.603577,322.004486 638.970642,321.995850 638.468689,321.696136 
	C639.069763,319.974152 639.539734,318.543213 640.016113,317.093048 
	C637.864563,316.990570 635.856079,317.178070 635.766785,316.847412 
	C634.741333,313.049438 632.266357,314.393982 629.721436,314.769897 
	C629.474609,312.192474 629.474609,309.841278 629.474609,307.490051 
	C628.791260,307.243744 628.107910,306.997406 627.424622,306.751099 
	C627.136963,307.806305 626.849365,308.861481 626.524414,310.053589 
	C622.202637,305.900024 623.546631,298.817230 616.803101,297.644226 
	C614.366089,296.922302 612.108887,296.517059 610.039612,296.145569 
	C608.830688,294.033020 608.048401,290.875793 606.044617,289.507721 
	C600.535217,285.746216 594.513611,282.735016 588.709106,279.435150 
	C588.109192,280.281616 587.605408,280.992371 586.738403,282.215607 
	C585.394470,276.458008 581.235107,275.142029 576.634033,273.849884 
	C570.518677,272.137024 564.714050,270.572601 558.588257,269.068481 
	C556.433350,271.314667 554.873230,269.957031 552.892395,269.108673 
	C550.796204,268.210907 548.286926,268.277679 545.701172,267.722412 
	C543.349060,264.718445 533.927856,265.129944 531.490601,268.020935 
	C530.433411,266.618591 529.703857,265.312195 528.621704,264.001831 
	C527.497009,263.597198 526.724854,263.196533 525.952759,262.795868 
	C529.014893,262.673218 532.077026,262.550537 535.660522,262.911255 
	C539.059814,263.605072 541.937683,263.815521 544.815552,264.025970 
M627.025452,562.233215 
	C626.176208,562.391907 625.326965,562.550598 624.477722,562.709290 
	C624.655518,563.173462 624.833313,563.637573 625.011108,564.101746 
	C625.839905,563.626892 626.668701,563.152100 627.025452,562.233215 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M377.910187,632.084351 
	C376.474792,631.200867 375.090698,629.670227 373.594635,629.551636 
	C368.489410,629.146851 363.311127,629.562622 358.226440,629.033630 
	C354.534882,628.649536 350.880829,627.463074 347.333954,626.267029 
	C344.396484,625.276550 342.846436,625.640381 342.898163,629.065369 
	C342.952881,632.686218 342.766266,636.324402 343.103058,639.915771 
	C343.170776,640.637634 345.115295,641.183411 346.456909,642.122437 
	C345.366730,642.896545 344.014099,643.358704 342.357574,643.924622 
	C342.063324,641.886780 341.836761,640.317566 341.572845,638.489563 
	C338.452484,638.738770 335.531677,638.971985 332.052765,639.249756 
	C332.052765,647.315979 331.980194,655.102112 332.171509,662.881714 
	C332.189484,663.613281 333.814941,664.809692 334.788971,664.915405 
	C337.355438,665.193787 339.974213,664.990112 342.679260,665.416992 
	C342.824768,667.668640 342.862488,669.481628 342.901733,671.294617 
	C342.991302,675.437744 345.285950,677.210815 349.247498,677.262390 
	C353.236267,677.314270 355.478516,675.467590 355.790894,671.421387 
	C355.931061,669.605164 356.094299,667.790710 356.642761,665.952026 
	C359.573151,665.449219 362.107880,664.969849 364.573578,664.503540 
	C364.573578,660.512634 364.612701,656.826172 364.554810,653.141235 
	C364.530884,651.615967 364.481689,650.050232 364.129883,648.578064 
	C362.734100,642.737366 357.076813,643.093506 353.032074,641.103516 
	C362.603241,641.078064 367.738708,645.791077 368.011749,655.107178 
	C368.172058,660.576782 368.187317,666.061279 367.984955,671.528625 
	C367.579163,682.492554 357.238556,689.060059 344.554504,686.555725 
	C334.710693,684.612183 330.585052,679.079346 330.742584,669.029419 
	C330.929688,657.091675 330.653839,645.147156 330.665100,633.205688 
	C330.667450,630.728088 331.049194,628.250793 331.672729,625.371338 
	C332.341339,624.777832 332.495087,624.528748 332.914368,624.121033 
	C336.463135,623.954834 339.648254,623.889893 343.142212,623.910034 
	C344.659760,623.324402 345.868439,622.653625 347.423187,622.011414 
	C348.824921,622.502380 349.880646,622.964783 351.012817,623.710754 
	C352.068054,624.098083 353.046814,624.201843 354.349182,624.295105 
	C355.082397,624.408203 355.491974,624.531738 355.991211,624.891479 
	C356.729706,625.112122 357.378601,625.096558 358.489624,625.050293 
	C364.264801,624.610779 369.577789,624.201965 375.078766,624.090088 
	C376.151276,624.631653 377.035828,624.876160 377.946594,625.496704 
	C378.329285,627.602478 378.685760,629.332214 378.993835,631.365967 
	C378.885712,631.866882 378.825958,632.063721 378.558990,632.183472 
	C378.351776,632.106506 377.910187,632.084351 377.910187,632.084351 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M316.061462,342.040619 
	C315.995819,341.262024 315.930145,340.483398 316.138702,339.190735 
	C316.277893,338.141266 316.142853,337.605927 316.007812,337.070557 
	C315.998108,335.964935 315.988403,334.859344 316.286865,333.102692 
	C315.851990,330.362091 320.132904,327.856873 316.064819,326.042389 
	C315.998138,325.571289 315.931458,325.100159 316.174286,324.050110 
	C317.313568,321.806915 318.143341,320.142700 318.973145,318.478485 
	C319.506073,318.944641 320.039032,319.410797 320.571991,319.876984 
	C319.305115,319.343506 318.038208,318.809998 316.771332,318.276520 
	C317.257843,317.677185 317.744354,317.077881 318.230865,316.478546 
	C319.471619,316.261841 320.712341,316.045135 322.614563,315.712891 
	C317.151184,309.725586 316.890656,302.940948 317.348816,296.097656 
	C318.794708,297.417603 319.889679,298.703613 320.960754,300.339050 
	C320.656464,301.837036 320.376160,302.985596 320.095825,304.134125 
	C320.435333,304.368103 320.774872,304.602081 321.114380,304.836060 
	C322.413208,303.551361 323.712036,302.266693 325.018860,301.343201 
	C325.714111,302.476135 326.401367,303.247864 327.071198,304.420532 
	C327.775665,307.142700 328.497589,309.463928 329.235504,311.836639 
	C327.518738,312.877380 325.620544,314.028107 323.722351,315.178833 
	C323.994568,315.633514 324.266785,316.088165 324.539032,316.542816 
	C325.462952,316.187256 326.386902,315.831696 327.554016,315.382538 
	C327.338989,317.141327 327.164825,318.565887 327.001282,320.300018 
	C327.349457,321.083710 327.687073,321.557831 328.285004,322.225525 
	C328.664093,323.256622 328.782898,324.094177 328.643494,325.195007 
	C327.941315,327.948730 327.497345,330.439270 327.040375,333.356323 
	C327.014587,337.517914 327.001801,341.253021 326.628418,344.956787 
	C324.599670,344.136536 322.931519,343.347595 321.139191,342.499939 
	C320.611267,343.790253 319.869415,345.603363 319.223206,347.182709 
	C322.059937,347.182709 324.205200,347.263153 326.329041,347.086426 
	C326.611969,347.062927 326.786346,345.734894 327.358459,345.061768 
	C328.148376,346.084595 328.590851,347.056610 328.816071,348.307617 
	C327.097107,351.426300 325.499115,354.063080 322.458893,355.727905 
	C321.635834,356.178558 321.820801,358.470062 321.543091,359.916595 
	C321.896362,359.975098 322.249603,360.033569 322.602875,360.092041 
	C322.883301,359.375977 323.163696,358.659912 323.315491,358.272308 
	C324.646393,360.685425 326.049927,363.230286 327.767914,366.345306 
	C325.099792,369.953888 319.999664,373.952454 325.788818,379.877716 
	C327.226105,378.984253 328.683472,378.078308 330.140869,377.172333 
	C330.539642,377.441010 330.938416,377.709686 331.337189,377.978333 
	C329.913116,380.649536 328.489044,383.320740 326.774231,385.882721 
	C325.830292,385.353149 325.177063,384.932770 324.352386,384.402039 
	C324.841431,390.587189 322.679291,393.400726 317.642273,393.075470 
	C316.893250,393.058685 316.465271,393.052795 316.037323,393.046936 
	C315.983795,390.970825 315.930237,388.894714 316.099304,386.312805 
	C316.377716,385.706573 316.505219,385.553619 316.476837,385.514862 
	C316.351868,385.344238 316.181946,385.206543 316.028259,385.056946 
	C316.001923,382.981689 315.975555,380.906464 316.167267,378.317871 
	C316.265045,377.555847 316.144775,377.307129 316.024475,377.058411 
	C316.001312,374.982880 315.978119,372.907349 316.192932,370.320404 
	C316.324493,369.552704 316.218018,369.296417 316.111542,369.040131 
	C316.047729,368.268433 315.983917,367.496765 316.134521,366.266968 
	C316.222717,365.558655 316.096527,365.308441 315.970398,365.058197 
	C315.930115,361.324677 315.889832,357.591125 316.134064,353.221985 
	C316.269348,351.747894 316.120148,350.909454 315.970947,350.070984 
	C315.959503,348.649506 315.948059,347.228027 316.329651,345.346497 
	C316.502258,343.937836 316.281860,342.989227 316.061462,342.040619 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M594.940430,662.953857 
	C594.987305,668.685547 595.034119,674.417236 594.687622,680.770691 
	C590.512573,682.576172 586.716125,684.242676 583.067200,681.051636 
	C582.977173,672.349426 582.887146,663.647156 583.303284,654.441650 
	C586.868774,653.721313 589.928223,653.504333 593.117065,653.647095 
	C593.958252,654.322571 594.653442,654.865112 595.384216,654.918091 
	C600.939514,655.321167 606.500122,655.844238 612.062622,655.889099 
	C615.720520,655.918640 619.384338,655.215393 623.407166,654.901855 
	C624.178223,662.458801 624.725098,669.951904 624.844482,677.451843 
	C624.861816,678.541504 623.018005,680.213928 621.711914,680.651855 
	C619.277588,681.468079 616.605347,681.575073 614.032166,681.977295 
	C614.032166,675.115845 614.032166,668.254395 614.032166,660.892700 
	C607.672791,660.892700 602.261475,660.892700 596.090698,660.900269 
	C595.200928,661.589783 595.070679,662.271851 594.940430,662.953857 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M420.889771,262.717621 
	C421.400391,262.612549 421.910980,262.507507 422.754272,262.936646 
	C422.589355,264.958130 421.807648,266.424438 421.695496,267.940308 
	C421.622589,268.926025 422.524811,269.983917 422.977905,271.342712 
	C423.009766,272.486725 423.053925,273.297546 423.098053,274.108337 
	C423.554688,273.955078 424.011322,273.801819 424.467987,273.648560 
	C423.981750,272.762726 423.495544,271.876923 423.078918,270.649841 
	C424.913513,270.566345 427.544220,270.174652 428.303497,271.186859 
	C433.376892,277.950195 433.364441,269.512909 435.825134,268.739746 
	C436.320038,268.584259 436.411652,267.145264 436.505798,266.857941 
	C438.984070,266.857941 440.863770,266.857941 443.746399,266.857941 
	C444.277496,269.460022 444.992157,272.961517 445.660248,276.234772 
	C448.729706,273.207489 451.726471,270.251892 454.648987,267.369537 
	C454.851624,267.825989 455.215485,268.645599 455.567261,269.438019 
	C457.199097,268.888580 458.603790,268.415619 460.148254,268.253662 
	C461.773560,270.491974 466.107391,271.749268 461.417603,275.046814 
	C460.448822,276.806702 459.872803,278.556458 459.192749,280.607971 
	C457.719879,281.599060 456.351013,282.288391 454.659882,282.842224 
	C452.764496,281.030945 451.191376,279.355164 449.042145,277.065674 
	C448.941711,278.744598 448.860107,280.108307 448.737793,282.153198 
	C446.285217,280.763092 444.182922,279.571503 441.814697,278.229218 
	C440.996948,279.606140 439.992371,281.297699 438.604370,282.987122 
	C435.942261,282.467224 433.139374,280.848053 431.482422,281.636627 
	C426.364105,284.072479 421.782074,283.375183 416.741272,281.070435 
	C415.617737,281.636566 414.813599,282.212799 413.708923,282.775238 
	C411.642456,282.556793 409.874939,282.184723 408.111145,282.201324 
	C406.737579,282.214233 405.368591,282.710968 403.751648,282.843750 
	C399.878052,283.337372 397.363251,281.267822 394.906616,278.760956 
	C394.625977,276.904907 394.306793,275.438416 394.146790,273.609894 
	C394.691284,270.839172 395.076660,268.430450 395.559082,265.415009 
	C396.577484,269.266693 394.573975,273.769623 399.963074,275.954163 
	C399.748627,273.113312 399.577209,270.842651 399.405792,268.571991 
	C399.631531,268.524811 399.857239,268.477661 400.082977,268.430511 
	C400.714783,269.544647 401.346619,270.658752 402.052460,271.903442 
	C403.555389,270.120972 404.793915,268.652039 406.032471,267.183075 
	C406.564026,267.441620 407.095612,267.700134 407.627197,267.958649 
	C407.008484,269.497742 406.389771,271.036804 405.720337,272.702026 
	C410.120117,273.567017 410.168854,269.423096 411.735138,266.824585 
	C411.951508,268.961914 412.143005,270.853668 412.370544,273.101471 
	C414.835144,272.483429 416.658081,272.026306 419.023376,271.433167 
	C417.485687,270.359497 416.462097,269.644806 414.896637,268.551758 
	C418.643219,267.703552 421.594025,266.833344 420.889771,262.717621 
M441.115448,271.921234 
	C441.694946,268.023438 439.457306,269.148468 437.360504,269.552399 
	C438.524017,273.663727 438.524017,273.663727 441.115448,271.921234 
M438.847473,277.802002 
	C438.793854,277.193268 438.740234,276.584534 438.686615,275.975800 
	C438.482605,276.005554 438.278625,276.035278 438.074615,276.065002 
	C438.203644,276.755890 438.332672,277.446747 438.847473,277.802002 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M698.031616,642.495483 
	C710.817383,637.009094 721.103882,643.436523 721.241638,657.030640 
	C721.288635,661.666565 721.260498,666.304321 721.193665,670.940186 
	C721.085022,678.475952 716.967346,683.847717 709.721985,685.914307 
	C699.097595,688.944763 685.953247,685.220215 684.501709,674.056885 
	C688.439514,672.050354 692.120483,670.656677 695.902344,669.642944 
	C695.915344,674.522400 697.664124,677.288513 702.604004,677.278076 
	C707.639648,677.267456 709.100586,674.263123 709.128418,669.874390 
	C709.138733,668.242004 709.321838,666.610718 709.762512,665.025391 
	C712.946045,665.071899 715.793274,665.071899 719.394104,665.071899 
	C718.854858,660.375305 718.407227,656.673340 718.011292,652.965881 
	C717.685547,649.915649 716.065796,648.570740 713.003906,649.005493 
	C713.000000,649.000000 712.989014,648.992249 713.001099,648.586670 
	C711.847168,641.585266 708.141418,646.294312 705.711304,645.828186 
	C703.151428,644.717285 700.591492,643.606384 698.031616,642.495483 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M288.307617,662.877686 
	C286.749054,645.729370 297.966003,636.669739 314.177124,641.989624 
	C319.166351,643.626831 322.410034,646.896240 323.379486,652.145386 
	C324.373566,657.527832 323.734497,662.429260 318.180420,666.016968 
	C315.531219,666.157227 313.547211,666.461975 311.635834,666.211914 
	C303.847687,665.192932 296.081726,664.004028 288.307617,662.877686 
M308.840149,650.532715 
	C301.471954,649.996521 299.284058,652.377075 301.056183,660.534058 
	C304.369843,659.645081 307.852264,659.094910 310.839813,657.573181 
	C311.411713,657.281921 310.034760,653.164490 308.840149,650.532715 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.765747,561.694885 
	C315.819519,558.707520 315.873260,555.720093 316.245239,552.241394 
	C316.563446,551.750061 316.549133,551.312012 316.549133,551.312012 
	C316.549133,551.312012 316.119049,551.136169 316.119049,551.136169 
	C316.041443,550.367798 315.963837,549.599426 316.257385,548.226685 
	C317.394073,543.074585 318.159607,538.526794 318.956421,533.735168 
	C318.987701,533.491333 319.004364,533.000000 319.008240,533.006165 
	C319.012085,533.012329 319.027863,533.014404 319.235291,532.898804 
	C319.702789,532.589050 319.898407,532.343506 320.355774,532.040161 
	C321.116852,532.028198 321.551727,532.022705 322.028778,532.326416 
	C322.668701,533.117737 323.266479,533.599792 324.000366,534.297974 
	C324.423065,534.683594 324.709686,534.853027 324.986572,535.257690 
	C324.976837,535.492859 324.966156,535.963501 324.770935,536.209839 
	C323.969391,537.667603 323.363037,538.878967 322.756653,540.090332 
	C323.245209,540.320862 323.733734,540.551331 324.222290,540.781860 
	C325.154510,539.191528 326.086700,537.601196 327.363495,535.967529 
	C329.664368,533.813171 335.211578,533.356140 331.150787,528.402710 
	C330.941589,528.147583 331.699188,526.956421 332.166443,526.317932 
	C333.425110,524.597839 334.770294,522.941101 336.301392,521.085938 
	C336.677155,520.419434 336.832672,519.926025 337.347717,519.339050 
	C339.438965,518.466614 341.170593,517.687744 343.130737,517.215393 
	C342.898346,518.576355 342.437439,519.630859 341.568665,521.618469 
	C347.682617,517.996704 353.102051,514.786377 358.778229,511.525665 
	C359.359192,511.347504 359.683380,511.219696 360.300659,511.077820 
	C360.899902,510.830719 361.206085,510.597687 361.870453,510.343384 
	C364.488464,509.548004 366.748291,508.773895 369.299988,508.022797 
	C370.084534,507.798218 370.577179,507.550598 371.125610,507.269653 
	C371.181366,507.236298 371.293915,507.171356 371.680725,507.152130 
	C376.117950,506.357880 380.173279,505.607513 384.217285,504.800323 
	C387.557892,504.133545 390.885040,503.399567 394.670654,502.715912 
	C408.623047,504.269073 422.122894,505.801697 435.865387,507.605194 
	C440.692444,509.268402 445.276855,510.660736 450.071594,512.270508 
	C450.419983,512.865601 450.558014,513.243286 450.482239,513.974731 
	C450.204529,515.137634 450.140625,515.946838 449.719055,516.714478 
	C429.841187,508.322754 409.651306,503.495728 388.370911,506.963623 
	C380.176910,508.298889 372.269043,511.389984 363.814392,513.663086 
	C354.374542,516.556458 346.737274,521.703735 339.657196,527.914185 
	C329.997803,536.387146 324.258240,547.254211 319.639771,558.879028 
	C318.944092,558.742188 318.138062,558.645813 318.089447,558.795471 
	C317.133667,561.738037 316.265900,564.709229 315.380188,567.674561 
	C315.364777,566.364685 315.349365,565.054810 315.567871,563.173401 
	C315.920044,562.294434 315.908020,561.992065 315.765747,561.694885 
M335.491364,526.776489 
	C335.768860,526.429810 336.046356,526.083130 336.323853,525.736511 
	C336.114075,525.626282 335.904327,525.516052 335.694611,525.405762 
	C335.459686,525.934998 335.224762,526.464172 334.588989,527.246582 
	C334.598572,527.595032 334.608154,527.943420 334.617798,528.291870 
	C334.749176,527.865417 334.880554,527.438904 335.491364,526.776489 
M339.884796,523.361145 
	C339.577057,523.052490 339.269348,522.743774 338.961609,522.435120 
	C338.780334,522.800537 338.599060,523.165894 338.417786,523.531311 
	C338.891876,523.654114 339.365997,523.776917 339.884796,523.361145 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M423.211243,737.190674 
	C421.910828,738.715149 420.610413,740.239685 419.309998,741.764221 
	C418.252228,740.503052 416.887299,739.390137 416.193848,737.953125 
	C414.038300,733.486267 412.053009,728.931152 410.171356,724.340698 
	C409.132507,721.806458 408.391571,719.334473 405.029846,721.993835 
	C404.479004,722.429504 403.244659,722.731628 402.769745,722.419861 
	C398.063507,719.330139 397.404175,722.959412 396.782806,726.097351 
	C396.208710,728.996521 396.005798,731.981812 395.812897,734.940491 
	C395.581268,738.493408 395.353729,742.145447 399.911591,743.997131 
	C395.816223,743.997131 391.720856,743.997131 387.073059,743.997131 
	C392.052948,737.220215 389.618347,730.290527 389.940704,723.713867 
	C389.967224,723.172424 388.752228,722.289734 387.976776,722.084656 
	C387.429626,721.939880 386.055817,722.682922 386.061066,723.013611 
	C386.195770,731.514526 379.871613,728.590088 375.491028,729.046265 
	C374.668549,729.131958 373.830322,729.066162 372.232605,729.051025 
	C370.966827,729.322815 370.467865,729.613770 369.968872,729.904785 
	C369.968872,729.904785 369.965424,729.956604 369.568909,729.975464 
	C368.426605,729.997253 367.680786,730.000061 366.982635,729.627686 
	C371.213928,727.742493 375.397583,726.232483 379.637451,724.702209 
	C378.239716,717.417114 373.445435,715.437439 367.714600,720.204041 
	C366.551636,721.171387 365.881561,722.731323 364.612061,724.017639 
	C363.495239,724.014954 362.750641,724.012695 362.006073,724.010437 
	C361.441284,717.334900 371.270386,713.509216 377.165863,715.439819 
	C378.257996,715.797485 379.536011,716.156494 380.270447,716.942627 
	C384.303772,721.260010 388.247559,719.713196 392.634186,717.451355 
	C394.195465,716.646362 396.668335,717.843933 398.612427,717.496704 
	C402.405914,716.819031 406.108856,715.648987 409.870605,714.774658 
	C412.696564,714.117859 414.212280,714.605164 414.859924,718.179749 
	C415.700012,722.816589 417.828217,727.219971 419.644104,732.370972 
	C420.994598,734.408875 422.102936,735.799805 423.211243,737.190674 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M389.677490,638.120728 
	C389.752563,638.892883 389.827637,639.665100 389.764771,641.033264 
	C389.581238,641.807556 389.535675,641.985779 389.344971,642.384888 
	C390.159485,642.666260 391.119232,642.726746 392.105408,642.616150 
	C392.131836,642.445068 391.945312,642.153503 391.945312,642.153503 
	C395.673401,641.817627 399.401459,641.481750 403.638489,641.642151 
	C411.790222,645.844727 414.082977,650.264954 411.206421,655.748718 
	C416.089600,658.124878 413.508118,662.028503 413.571625,664.855713 
	C409.847870,665.261902 406.578705,665.618591 403.156128,665.541504 
	C403.055542,661.743042 403.108368,658.378418 403.524567,655.013489 
	C404.861847,654.415222 405.835785,653.817200 406.809723,653.219177 
	C406.651642,652.919922 406.493561,652.620728 406.335510,652.321533 
	C405.095856,652.321533 403.856232,652.321533 402.776917,652.219116 
	C402.937286,652.116699 402.641663,652.356323 402.715637,651.969788 
	C402.014771,651.130310 401.239868,650.677368 400.058014,650.260254 
	C390.992493,649.490784 389.886780,650.479492 389.844910,659.003113 
	C389.833649,661.294739 389.764587,663.586060 389.289215,665.948120 
	C385.510498,665.769470 382.164581,665.520264 378.882507,664.789185 
	C378.910889,655.789429 378.875488,647.271606 379.115997,638.430420 
	C382.820465,638.111633 386.248962,638.116150 389.677490,638.120728 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M403.309540,665.975220 
	C406.578705,665.618591 409.847870,665.261902 413.571625,664.855713 
	C413.508118,662.028503 416.089600,658.124878 411.206421,655.748718 
	C414.082977,650.264954 411.790222,645.844727 404.050385,641.822876 
	C410.763611,642.966125 414.165863,645.968994 414.686188,652.603638 
	C415.228577,659.520020 415.285583,666.549133 414.700653,673.456665 
	C414.186127,679.532593 410.511627,684.342834 404.394226,685.442078 
	C399.172485,686.380371 393.474121,686.534790 388.309174,685.470459 
	C381.065002,683.977661 378.257233,679.177185 378.248596,670.233521 
	C382.453857,669.912964 386.105591,669.987549 389.858459,670.450317 
	C389.882751,675.186646 392.130798,677.148560 396.365570,677.223572 
	C400.719788,677.300720 402.666016,675.047913 402.858887,670.886292 
	C402.934937,669.245789 403.155029,667.612061 403.309540,665.975220 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M671.934326,669.959351 
	C672.081055,671.466797 672.227722,672.974243 671.946045,674.483643 
	C669.441223,675.160828 667.398804,675.978638 665.281860,676.484619 
	C658.969666,677.993164 652.590149,679.238220 646.328247,680.925110 
	C644.975830,681.289490 644.005371,683.072144 642.859009,684.201477 
	C640.708740,681.317139 638.558411,678.432800 636.830872,675.364014 
	C641.211426,679.702332 644.270264,675.082031 647.560120,674.597107 
	C647.408752,671.675964 647.195923,669.193237 647.167480,666.708313 
	C647.088074,659.780762 644.499634,657.910828 637.223450,659.698303 
	C636.894470,664.084717 636.563538,668.497375 636.232605,672.909973 
	C636.124878,663.839905 635.687317,654.756165 636.063782,645.706238 
	C636.225586,641.817261 640.750488,642.468689 643.248169,642.178467 
	C644.665710,642.013672 647.652405,644.847107 647.848206,646.567566 
	C648.538269,652.628906 648.321777,658.793030 648.460693,664.917908 
	C648.505920,666.908875 648.524475,668.903442 648.664124,670.888672 
	C648.933044,674.710876 651.009094,676.669434 654.863708,676.360962 
	C658.598145,676.062134 660.207520,674.316223 659.946045,670.167175 
	C659.519836,663.404968 659.819580,656.595154 659.863525,649.805786 
	C659.911743,642.357910 663.994263,639.972412 670.528748,643.661377 
	C661.820251,643.680908 661.020264,644.440369 661.024292,652.685059 
	C661.024841,653.843750 660.741577,655.117859 661.131775,656.129456 
	C661.540405,657.188965 662.466797,658.588257 663.404602,658.803589 
	C666.263062,659.459900 669.235962,659.617371 672.164307,659.968994 
	C672.214355,661.788208 672.264343,663.607483 671.746948,665.724915 
	C667.988708,665.427917 664.797913,664.832703 661.421021,664.202759 
	C661.421021,666.923340 661.421021,669.255737 661.421021,671.737366 
	C665.216675,671.095398 668.575500,670.527344 671.934326,669.959351 
M639.580505,650.841858 
	C639.427612,651.679504 639.089172,653.236145 639.148560,653.251526 
	C641.770020,653.928101 644.426575,654.468750 647.761475,655.169067 
	C647.028931,652.275513 646.620667,650.662537 646.123474,648.698669 
	C643.914001,649.357422 642.070801,649.907043 639.580505,650.841858 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M342.572144,664.978394 
	C339.974213,664.990112 337.355438,665.193787 334.788971,664.915405 
	C333.814941,664.809692 332.189484,663.613281 332.171509,662.881714 
	C331.980194,655.102112 332.052765,647.315979 332.052765,639.249756 
	C335.531677,638.971985 338.452484,638.738770 341.572845,638.489563 
	C341.836761,640.317566 342.063324,641.886780 342.357574,643.924622 
	C344.014099,643.358704 345.366730,642.896545 346.867798,642.163208 
	C347.453400,641.666016 347.890564,641.440125 348.850952,641.433472 
	C349.906982,641.468994 350.439728,641.285339 350.972473,641.101624 
	C351.433014,641.041992 351.893585,640.982300 352.693115,641.013062 
	C357.076813,643.093506 362.734100,642.737366 364.129883,648.578064 
	C364.481689,650.050232 364.530884,651.615967 364.554810,653.141235 
	C364.612701,656.826172 364.573578,660.512634 364.573578,664.503540 
	C362.107880,664.969849 359.573151,665.449219 356.497070,665.508301 
	C355.936005,661.778564 355.989716,658.466553 355.876221,655.160156 
	C355.760101,651.777527 353.897095,650.021240 350.506104,650.016602 
	C348.862732,650.014343 347.219147,650.166077 345.201660,650.184692 
	C344.219574,650.748291 343.611481,651.375244 342.965424,652.464417 
	C342.809021,656.943909 342.690582,660.961182 342.572144,664.978394 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M431.915680,662.887085 
	C434.255493,659.748535 436.595306,656.609985 439.934174,652.131348 
	C435.609436,652.131348 432.601135,652.112915 429.593170,652.135925 
	C425.632660,652.166199 421.150787,652.231140 421.213745,646.855896 
	C421.278351,641.338379 425.984985,641.859436 429.797943,641.889038 
	C436.120300,641.938232 442.469940,641.782104 448.756317,642.318848 
	C456.619080,642.990051 458.034607,646.456665 453.416016,652.736755 
	C451.469025,655.384155 449.389679,657.934143 446.708710,660.729736 
	C441.696899,662.055664 437.352692,663.205261 432.980713,664.237061 
	C432.745056,664.292664 432.276062,663.359802 431.915680,662.887085 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M697.770752,642.421509 
	C700.591492,643.606384 703.151428,644.717285 705.711304,645.828186 
	C708.141418,646.294312 711.847168,641.585266 712.880005,648.647705 
	C712.833862,649.079956 712.920837,649.045532 713.007874,649.011047 
	C716.065796,648.570740 717.685547,649.915649 718.011292,652.965881 
	C718.407227,656.673340 718.854858,660.375305 719.394104,665.071899 
	C715.793274,665.071899 712.946045,665.071899 709.651794,664.588684 
	C709.274597,661.415894 709.344482,658.726318 709.699707,655.976074 
	C710.054321,653.954895 712.614868,650.928162 707.595459,650.846069 
	C707.347717,650.449829 707.394226,650.226929 707.723145,650.011536 
	C708.304016,649.969055 708.605652,649.931519 708.897278,649.855530 
	C708.940002,649.844482 708.934998,649.650391 708.952087,649.540894 
	C708.446167,649.696106 707.940247,649.851257 707.385437,650.232178 
	C707.336487,650.457886 706.875000,650.441223 706.896545,650.119385 
	C706.590271,649.344727 706.262451,648.891968 705.934631,648.439148 
	C705.618347,648.914978 705.302124,649.390869 704.570190,649.924927 
	C696.710022,650.250488 695.996399,650.975769 695.985901,658.117920 
	C695.982056,660.722595 695.893860,663.327087 695.439453,665.957520 
	C693.745178,665.808411 692.460205,665.536438 691.165344,665.476013 
	C686.240417,665.246094 684.346436,662.945374 684.803223,657.860229 
	C685.304382,652.281189 684.976685,646.627686 685.436890,640.992310 
	C689.153259,640.660278 692.442871,640.342529 695.732544,640.024780 
	C696.325012,640.799011 696.917480,641.573242 697.770752,642.421509 
M696.562195,649.515442 
	C696.562195,649.515442 696.480835,649.559570 696.562195,649.515442 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M422.990143,271.009460 
	C422.524811,269.983917 421.622589,268.926025 421.695496,267.940308 
	C421.807648,266.424438 422.589355,264.958130 423.106812,263.127502 
	C424.134369,262.636932 425.142120,262.489655 426.537109,262.763062 
	C427.379089,263.664490 427.833771,264.145233 428.288452,264.625977 
	C428.566956,263.954071 428.845459,263.282196 429.123962,262.610321 
	C430.512512,262.500153 431.901031,262.389984 433.836731,262.513733 
	C434.882721,262.627228 435.381500,262.506775 435.880280,262.386353 
	C437.604248,262.363098 439.328186,262.339874 441.509583,262.678253 
	C442.689423,262.891418 443.411804,262.742981 444.134186,262.594543 
	C445.454498,262.506561 446.774780,262.418610 448.496826,262.653839 
	C449.078796,263.202515 449.259033,263.428009 449.439270,263.653503 
	C449.687958,263.340820 449.936615,263.028137 450.185303,262.715454 
	C454.798523,262.562775 459.411743,262.410126 464.524963,262.629578 
	C466.065308,262.898743 467.105713,262.795776 468.146088,262.692810 
	C468.526825,262.683441 468.907532,262.674072 469.665100,263.042206 
	C471.078247,263.161591 472.114624,262.903442 473.150970,262.645264 
	C474.150970,262.532013 475.150940,262.418793 476.601562,262.700378 
	C478.403687,262.896667 479.755249,262.698151 481.106781,262.499634 
	C483.748535,262.438599 486.390289,262.377563 489.540344,262.739685 
	C491.365234,263.059784 492.681793,262.956726 493.998352,262.853668 
	C496.374023,262.640411 498.749664,262.427155 501.516052,262.554932 
	C501.977631,263.241821 502.048492,263.587677 502.105164,264.284058 
	C502.130646,265.099854 502.170319,265.565125 502.174011,266.362274 
	C502.149078,267.138733 502.160156,267.583313 502.114807,268.352844 
	C502.037689,269.117950 502.017029,269.558105 501.657410,269.945312 
	C500.198822,268.452484 499.079224,267.012573 497.670654,265.201080 
	C497.139313,266.666473 496.785431,267.642487 496.654907,268.002441 
	C494.162476,268.002441 492.082458,268.002441 490.001221,268.001221 
	C490.000000,268.000000 489.997559,267.997559 489.991943,267.650665 
	C489.623596,263.847290 488.182343,265.593811 486.860413,266.645996 
	C485.909088,267.403229 484.930145,268.125793 482.995087,269.603271 
	C483.615662,267.375885 483.897308,266.365051 484.189880,265.314880 
	C479.321411,263.609711 479.555908,269.270447 476.531769,270.681793 
	C476.531769,268.786438 476.531769,267.149628 476.531769,265.972504 
	C474.616211,266.450073 473.003723,266.852051 471.111755,267.323730 
	C471.068573,268.235962 471.006439,269.548645 470.933319,270.885071 
	C470.922333,270.908875 470.880798,270.940796 470.534668,270.949158 
	C467.589966,270.650879 465.316406,270.431030 464.015686,267.257721 
	C462.915253,264.572998 461.067657,265.865845 460.008484,267.942627 
	C458.603790,268.415619 457.199097,268.888580 455.567261,269.438019 
	C455.215485,268.645599 454.851624,267.825989 454.648987,267.369537 
	C451.726471,270.251892 448.729706,273.207489 445.660248,276.234772 
	C444.992157,272.961517 444.277496,269.460022 443.746399,266.857941 
	C440.863770,266.857941 438.984070,266.857941 436.505798,266.857941 
	C436.411652,267.145264 436.320038,268.584259 435.825134,268.739746 
	C433.364441,269.512909 433.376892,277.950195 428.303497,271.186859 
	C427.544220,270.174652 424.913513,270.566345 423.074219,270.654297 
	C422.999908,271.000031 422.990143,271.009460 422.990143,271.009460 
M451.393616,264.460358 
	C451.393616,264.460358 451.526428,264.366150 451.393616,264.460358 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M728.983887,715.832825 
	C729.731995,715.921570 730.480103,716.010315 731.816650,716.311951 
	C733.920410,715.432800 735.435791,714.340759 737.353516,712.958740 
	C737.632019,714.248352 737.821533,715.125793 738.011047,716.003296 
	C738.011047,716.003296 737.874634,716.367493 737.578735,716.780029 
	C737.446289,719.760559 737.609802,722.328552 737.726196,725.240234 
	C737.378418,726.694580 737.077698,727.805298 736.544922,729.078857 
	C736.079285,729.753357 735.845764,730.264954 735.451355,730.810669 
	C735.290466,730.844849 735.008728,731.014771 734.691528,731.155518 
	C731.544189,732.812683 728.713989,734.329102 725.459595,736.072754 
	C727.221191,736.389587 728.873291,736.893860 730.540527,736.950256 
	C737.321106,737.179504 737.976746,737.834778 737.912842,744.945801 
	C734.415039,751.823730 728.114746,751.084229 721.310059,750.524109 
	C718.141724,749.067322 715.647400,747.892395 713.153076,746.717529 
	C713.151978,746.331848 713.150879,745.946106 713.118347,744.892700 
	C713.780212,742.180115 714.473572,740.135254 715.166870,738.090454 
	C716.449341,737.559265 717.731873,737.028198 719.245850,736.401184 
	C717.010986,733.861450 715.142517,731.738098 713.139893,728.938049 
	C712.992981,725.838318 712.980164,723.415283 712.967407,720.992249 
	C713.064941,720.653442 713.162537,720.314697 713.580933,719.584473 
	C715.621765,718.193726 717.341736,717.194458 719.061707,716.195190 
	C719.488892,716.130005 719.916016,716.064941 721.101074,715.984863 
	C724.233948,715.924194 726.608887,715.878479 728.983887,715.832825 
M727.565247,732.061096 
	C731.866089,729.180115 734.058716,724.208984 731.282959,721.466125 
	C729.178650,719.386780 725.092346,717.971619 722.174805,718.368530 
	C717.705872,718.976379 717.083191,723.524536 718.090210,727.088379 
	C719.197754,731.007751 722.442566,733.034668 727.565247,732.061096 
M717.533936,745.053406 
	C727.429382,748.057983 731.893982,747.415588 734.671509,742.456726 
	C730.360779,741.335632 726.126099,740.017151 721.798828,739.173767 
	C717.963806,738.426392 715.863892,740.967407 717.533936,745.053406 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M275.654083,633.131226 
	C275.654083,633.131226 276.019714,633.390808 276.052246,633.975891 
	C276.788239,636.268616 277.731140,637.874023 274.737762,638.538513 
	C272.024933,639.140747 269.423676,640.245605 266.478058,640.960144 
	C262.195557,633.643616 255.546158,635.111389 248.738815,634.990234 
	C248.738815,638.661743 248.738815,641.779297 248.369476,644.985168 
	C244.302826,646.043518 240.605499,647.013611 236.908188,647.983765 
	C236.842484,641.947083 236.776794,635.910461 237.081558,629.255920 
	C237.597946,628.076599 237.743866,627.515198 237.889786,626.953735 
	C238.404861,626.333618 238.919937,625.713440 240.171478,625.053101 
	C242.706543,625.063721 244.591675,624.780457 246.289459,625.220337 
	C256.102173,627.762756 265.870636,630.475891 275.654083,633.131226 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M549.979309,618.639160 
	C549.657166,618.838013 549.335083,619.036865 548.495605,619.204224 
	C547.669739,619.248901 547.393555,619.388855 547.149780,619.592590 
	C546.677917,619.762268 546.205994,619.931885 545.346558,619.652344 
	C544.178833,617.446594 543.398682,615.690002 541.985901,612.509216 
	C540.654724,616.251160 539.821167,618.594177 538.987549,620.937256 
	C538.987549,620.937256 539.289673,621.144653 538.895325,621.142700 
	C537.363525,621.414551 536.226135,621.688354 535.088684,621.962158 
	C534.030640,622.092896 532.972595,622.223633 531.263489,622.220581 
	C530.096008,622.292725 529.579590,622.498718 529.063171,622.704712 
	C527.636963,622.765808 526.210815,622.826965 524.364075,622.415710 
	C523.150024,620.737915 522.356567,619.532471 522.090576,619.128418 
	C522.090576,616.627319 522.090576,614.772766 522.444214,612.897766 
	C523.865417,609.570312 524.932983,606.263245 526.331238,602.946899 
	C527.101624,602.966736 527.541321,602.995972 527.980347,603.379395 
	C527.459229,605.197571 526.938660,606.661682 526.415100,608.134155 
	C528.138306,608.259399 529.706238,608.373291 530.545166,608.434265 
	C531.783936,606.488464 532.812988,604.878357 533.835266,603.263977 
	C534.532410,602.163025 535.219849,601.056091 535.966553,599.645386 
	C537.692444,600.225647 539.363342,601.112183 540.648560,602.006714 
	C539.356384,602.711060 538.449829,603.407410 537.543274,604.103760 
	C538.569580,605.153076 539.595886,606.202393 540.622192,607.251709 
	C541.029175,606.907654 541.436218,606.563599 541.843201,606.219604 
	C541.561829,604.801697 541.280518,603.383789 541.323669,601.953735 
	C542.780884,601.944153 543.913635,601.946655 544.874634,602.208496 
	C544.844360,603.674011 544.828918,605.873596 545.152771,605.924744 
	C547.182007,606.245178 549.286865,606.086670 551.794800,606.086670 
	C552.264221,606.706848 553.132141,607.853455 553.989258,609.433350 
	C553.978577,612.045898 553.978577,614.225159 553.978577,616.404358 
	C553.574890,616.448975 553.171204,616.493591 552.767517,616.538147 
	C552.271362,615.492065 551.775208,614.445923 551.215637,613.266113 
	C549.182251,613.727905 547.251282,614.166504 544.961426,614.686523 
	C546.979614,616.276306 548.479431,617.457703 549.979309,618.639160 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M437.643555,672.955566 
	C438.535797,673.829956 439.354797,675.352295 440.333344,675.462952 
	C443.951263,675.872009 447.739777,675.313538 451.225433,676.133484 
	C453.108063,676.576416 454.506927,679.075928 456.124084,680.647583 
	C454.431458,682.348328 452.823883,685.406189 451.031708,685.518433 
	C442.588837,686.047607 434.082306,686.055847 425.624969,685.669678 
	C420.664703,685.443237 418.772308,680.809509 421.505188,676.581055 
	C422.847168,674.504761 424.510406,672.636169 426.662109,670.470337 
	C430.745148,671.163269 434.194366,672.059387 437.643555,672.955566 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M278.913513,737.840271 
	C279.394562,742.020569 277.672028,743.827820 273.330383,744.093933 
	C267.822815,744.431519 262.286102,746.459778 257.478088,742.409058 
	C261.134430,742.441406 264.450958,742.837830 268.358185,743.304932 
	C264.384430,740.864929 261.228882,738.927307 258.071930,736.737549 
	C258.053680,736.318115 258.036835,736.150879 258.026917,735.642456 
	C257.669800,734.530029 257.305756,733.758667 256.969055,732.536865 
	C256.901093,727.030823 255.959824,721.654724 262.300079,718.799438 
	C265.082031,718.565674 267.548615,718.449951 270.273132,718.523743 
	C277.011993,724.674194 277.177094,728.628967 272.098907,742.302307 
	C274.370453,740.815002 276.641998,739.327637 278.913513,737.840271 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M220.574631,468.991455 
	C216.715408,462.766113 212.856201,456.540741 208.996994,450.315369 
	C209.254837,449.876801 209.512695,449.438232 209.770554,448.999664 
	C214.231964,449.704712 218.719742,450.276215 223.147873,451.149872 
	C228.248550,452.156189 232.361038,452.058044 234.951065,446.244904 
	C237.005936,441.632904 240.303711,437.574646 243.812546,432.074677 
	C244.609741,434.648407 245.045227,436.054352 245.018005,437.753235 
	C238.699173,439.380280 239.149673,445.051758 236.777191,449.189026 
	C234.760757,455.481567 229.869476,455.246429 224.662933,455.065765 
	C223.343475,455.105255 222.398666,455.209412 221.548264,455.303162 
	C221.671692,456.977386 221.978973,458.428101 221.823120,459.827240 
	C221.678986,461.121002 221.038742,462.359497 220.616943,463.622314 
	C222.264465,463.823822 224.048798,464.516907 225.525864,464.105896 
	C227.496902,463.557404 229.229263,462.151215 231.358917,461.125214 
	C232.046707,461.152679 232.439713,461.168274 232.968811,461.550720 
	C240.271271,464.895294 245.150223,462.473328 247.117661,454.778717 
	C247.475266,454.353180 247.734726,454.159119 247.994186,453.965027 
	C248.362564,455.380493 248.730942,456.795929 248.784027,458.613617 
	C248.317062,459.071014 248.144669,459.097931 248.019653,459.189514 
	C247.899948,459.277252 247.731415,459.522980 247.751358,459.544464 
	C248.760330,460.632965 249.787262,461.704803 251.443726,463.440002 
	C248.619125,464.813904 246.283585,465.949890 243.579803,467.073486 
	C242.474716,467.060394 241.737900,467.059692 240.783051,466.755920 
	C238.241089,466.083344 235.716202,464.961121 233.638885,465.515625 
	C230.271973,466.414368 227.198700,468.413086 223.654755,469.960754 
	C222.617249,469.942474 221.926453,469.910919 221.235657,469.879395 
	C221.235657,469.879364 221.119995,469.838867 221.057098,469.635132 
	C220.893127,469.247772 220.753265,469.101105 220.574631,468.991455 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M316.082092,475.959900 
	C316.033386,475.218994 315.984650,474.478058 316.290527,473.080383 
	C316.731964,466.935913 319.468201,461.361816 316.109070,455.986450 
	C316.082550,455.229828 316.056030,454.473236 316.353149,453.094391 
	C316.808167,451.327026 316.939575,450.181854 317.296936,448.812317 
	C317.326080,447.116302 317.129303,445.644653 316.960938,443.811737 
	C317.352844,442.616974 317.716339,441.783508 318.471863,440.960693 
	C321.411835,440.971313 323.959808,440.971313 326.052734,440.971313 
	C326.713165,446.011963 327.405334,451.294708 328.164490,457.088928 
	C326.567108,458.031342 326.567108,458.031342 326.759094,465.102539 
	C326.569519,465.594482 326.587891,465.960144 326.606262,466.325836 
	C326.748627,465.894897 326.891022,465.463928 327.271851,464.871155 
	C328.006195,464.706573 328.502106,464.703796 328.998047,464.700989 
	C328.659760,466.117950 328.321503,467.534912 327.709991,469.187988 
	C326.992279,471.835541 326.547791,474.246887 326.299286,475.595154 
	C324.956879,475.753937 323.970154,475.870667 322.691803,476.105957 
	C322.093201,476.886230 321.786194,477.547913 321.479187,478.209625 
	C321.829620,478.335724 322.180023,478.461823 322.530426,478.587921 
	C322.691833,477.730164 322.853241,476.872406 323.301758,476.255768 
	C324.045044,479.443085 324.501282,482.389252 324.990509,485.548706 
	C325.649872,484.822418 326.369263,484.030029 327.088684,483.237610 
	C327.514526,483.473114 327.940399,483.708649 328.366241,483.944153 
	C327.595367,485.617157 326.824463,487.290161 325.861511,489.210876 
	C323.230865,489.908295 321.930359,485.273163 318.993530,487.620789 
	C318.483093,483.681519 317.994232,480.080963 317.401154,476.497681 
	C317.360565,476.252380 316.539948,476.136139 316.082092,475.959900 
M323.063324,452.598877 
	C323.478821,452.919800 323.894318,453.240692 324.309814,453.561584 
	C324.402557,453.289642 324.622437,452.811432 324.568207,452.778015 
	C324.073853,452.473358 323.531799,452.246155 323.017334,451.367401 
	C322.864044,450.795929 322.710754,450.224457 322.557465,449.652954 
	C322.196716,449.888306 321.835968,450.123627 321.475250,450.358978 
	C321.982666,450.906860 322.490051,451.454742 323.063324,452.598877 
M320.051392,464.456940 
	C319.434357,465.730499 318.817291,467.004089 318.200256,468.277679 
	C318.514954,468.441040 318.829651,468.604401 319.144348,468.767792 
	C321.093842,467.430542 323.043304,466.093292 325.255615,464.575775 
	C323.749969,463.244812 322.541992,462.176971 321.317200,461.094269 
	C321.030701,462.026978 320.744629,462.958313 320.051392,464.456940 
M323.015015,457.823395 
	C323.264038,458.389557 323.513062,458.955688 323.762085,459.521851 
	C324.132324,459.112396 324.502533,458.702942 324.872742,458.293488 
	C324.280792,457.925995 323.688812,457.558502 323.015015,457.823395 
M324.515808,446.542114 
	C324.454498,446.791077 324.393219,447.040039 324.331909,447.289001 
	C324.485962,447.128235 324.640045,446.967499 324.515808,446.542114 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M299.989502,671.037231 
	C300.063965,671.459839 300.138397,671.882385 300.032410,672.862183 
	C300.096924,673.629517 300.341919,673.839661 300.586884,674.049805 
	C303.722839,677.843750 307.610687,677.547607 311.650391,675.985229 
	C317.543884,673.705994 321.486267,674.241394 322.617432,677.588867 
	C323.854340,681.249329 321.233551,684.622253 314.716064,685.733337 
	C309.765350,686.577209 304.436523,686.587524 299.491089,685.729065 
	C293.255219,684.646667 289.447083,680.253784 288.725616,673.464294 
	C292.772186,672.391724 296.380859,671.714478 299.989502,671.037231 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M736.795166,574.015564 
	C737.594421,581.670471 738.393677,589.325317 739.352356,598.507141 
	C733.568909,594.504456 728.829285,591.592896 724.529724,588.134216 
	C720.953735,585.257446 717.894897,584.013855 713.395020,586.543640 
	C708.545959,589.269714 703.154419,591.030762 697.999512,593.212830 
	C697.570801,592.926147 697.142151,592.639465 696.713440,592.352844 
	C698.236511,587.430664 699.759521,582.508484 701.731384,577.277893 
	C702.180237,576.969482 702.587341,576.985352 702.587341,576.985352 
	C702.587341,576.985352 702.994690,576.979370 703.282959,576.993774 
	C703.763306,576.999023 703.955383,576.989868 704.102051,577.359009 
	C704.047607,578.498047 704.038513,579.258850 703.989990,580.404663 
	C706.035034,585.545898 709.608704,583.875488 713.418823,582.868408 
	C717.466492,582.980957 721.118469,583.116882 724.865845,583.579224 
	C726.268799,584.642517 727.576416,585.379395 728.952881,586.459961 
	C729.455872,587.472168 729.741760,588.382568 730.355225,588.758545 
	C731.594971,589.518250 733.012451,589.987854 734.356628,590.577087 
	C734.156860,589.307495 733.859497,588.043579 733.785461,586.766663 
	C733.715027,585.550964 733.877747,584.321838 733.984619,583.062256 
	C734.031250,583.026001 734.100037,582.929871 734.467529,582.874756 
	C736.951599,580.065613 736.892761,577.564087 733.248657,576.611145 
	C729.908630,575.737671 726.369690,575.624939 722.694458,575.227661 
	C722.047424,575.455505 721.624268,575.632751 721.118286,575.484253 
	C719.660400,573.137451 718.285339,571.116577 717.294983,569.100586 
	C723.894714,571.352783 731.139771,569.589905 736.795166,574.015564 
M702.820496,584.755432 
	C702.998657,584.622253 703.176758,584.489075 703.354919,584.355835 
	C703.107971,584.385071 702.861023,584.414307 702.820496,584.755432 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M394.093292,624.214600 
	C393.329987,624.139465 392.566650,624.064270 391.516418,623.377747 
	C391.220154,622.266785 391.210846,621.767151 391.537231,621.199768 
	C393.152832,620.338135 394.432739,619.544250 395.630890,618.801086 
	C394.261627,615.792114 393.167419,613.387573 392.062408,610.968933 
	C392.051605,610.954834 392.031403,610.925598 392.038635,610.583862 
	C391.388092,609.460632 390.730347,608.679077 389.801819,607.580933 
	C388.796997,606.878479 388.062988,606.492554 387.212952,605.884705 
	C386.886383,604.794800 386.675842,603.926758 386.892609,603.001343 
	C387.591797,602.860352 387.863708,602.776794 388.509277,602.734863 
	C390.255676,602.517761 391.628448,602.258911 393.340759,602.034790 
	C395.111816,602.739746 396.543243,603.409851 398.133728,604.350342 
	C399.630920,604.779358 400.969055,604.937927 402.538086,605.123840 
	C402.538086,605.953247 402.538086,607.234924 402.538086,609.725159 
	C404.892242,606.566101 406.532837,604.364502 408.498840,602.195435 
	C409.523163,603.359497 410.222137,604.491150 410.936310,605.647339 
	C411.897583,604.864990 412.688019,604.221680 414.319885,602.893494 
	C414.197723,604.831299 414.430206,605.962708 414.012512,606.748718 
	C413.182678,608.310486 413.541107,612.346008 414.970947,614.297119 
	C414.974426,615.087219 414.965057,615.538574 414.755341,616.003845 
	C414.555023,616.017761 414.153839,616.035767 413.844910,616.116089 
	C412.992493,616.818115 412.449036,617.439819 411.530762,618.035522 
	C408.522675,617.279724 405.792084,616.239990 404.436432,620.527893 
	C407.552612,619.440857 409.494598,620.039795 408.921326,623.667358 
	C408.921326,623.667358 409.126678,623.846008 408.818359,623.869751 
	C408.351318,623.958008 408.192566,624.022583 408.033844,624.087097 
	C405.298584,624.104309 402.563354,624.121521 399.217468,623.921509 
	C397.102325,623.874390 395.597809,624.044495 394.093292,624.214600 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M613.998291,682.347534 
	C616.605347,681.575073 619.277588,681.468079 621.711914,680.651855 
	C623.018005,680.213928 624.861816,678.541504 624.844482,677.451843 
	C624.725098,669.951904 624.178223,662.458801 623.107422,654.649414 
	C612.626526,653.985962 602.807068,653.636658 592.987610,653.287354 
	C589.928223,653.504333 586.868774,653.721313 583.381714,653.979858 
	C582.866333,652.848145 582.778687,651.674866 583.317871,650.271423 
	C587.700623,649.676880 591.456482,649.312500 595.212402,648.948181 
	C600.954224,649.030151 606.695984,649.112122 613.290894,649.146606 
	C617.601746,649.099182 621.059387,649.099182 625.287842,649.099182 
	C625.287842,641.589661 625.287842,634.700073 625.287842,627.810547 
	C625.623779,632.325195 626.198364,636.837219 626.249512,641.355103 
	C626.384338,653.267578 626.308960,665.182800 626.270386,677.096741 
	C626.265076,678.735168 626.504211,680.692261 625.726074,681.936462 
	C624.530029,683.848999 622.818542,686.036682 620.842773,686.728455 
	C617.621277,687.856506 615.147766,685.986206 613.998291,682.347534 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M782.104858,486.794861 
	C785.164429,486.625122 785.539978,488.855133 783.656860,489.913147 
	C777.029663,493.636658 777.821167,498.993774 779.407959,504.819458 
	C779.500244,505.158295 779.000183,505.658478 778.240295,507.097992 
	C772.452454,500.702881 765.869934,500.225586 758.847717,506.146729 
	C758.689331,505.632202 758.531006,505.117676 758.372620,504.603180 
	C758.952026,501.148102 760.630066,497.394165 759.794800,494.323944 
	C758.995361,491.385315 755.584106,489.157257 752.181335,485.337952 
	C760.908569,485.357605 767.225403,483.129883 769.446228,473.729126 
	C772.278442,478.490723 774.214294,481.745331 776.447083,485.411682 
	C775.791687,485.970093 774.365295,486.592743 773.960449,486.189575 
	C769.971985,482.217651 766.921143,485.238190 762.161072,487.764435 
	C767.406677,489.043518 771.264954,490.356445 775.219177,490.786407 
	C777.104492,490.991425 779.172424,489.518036 781.157593,488.804901 
	C779.187195,487.057526 780.909241,487.050232 782.104858,486.794861 
M771.301514,500.151337 
	C772.617126,500.733429 773.932739,501.315552 775.263855,501.904510 
	C776.912781,497.531036 776.625793,495.933960 773.993469,495.191406 
	C770.560242,494.222900 767.066711,493.467987 763.342896,492.558563 
	C762.530457,495.385559 761.680420,498.343353 760.833496,501.290314 
	C764.687256,500.722931 767.653015,500.286316 771.301514,500.151337 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M536.963989,735.013367 
	C536.080750,732.966553 535.197510,730.919739 534.314270,728.872925 
	C533.833313,728.747681 533.352417,728.622437 532.871521,728.497192 
	C531.890930,731.103394 530.910339,733.709534 529.297852,736.647339 
	C526.557007,736.964722 524.448059,736.950378 522.339111,736.936035 
	C519.311890,729.932434 516.284668,722.928772 513.229492,715.860596 
	C517.914124,713.174561 519.829956,713.941528 521.693359,719.333008 
	C523.128601,723.485352 524.937439,727.508545 527.534546,731.365173 
	C528.602600,726.118896 533.821472,721.612671 527.939880,715.226257 
	C532.548523,715.226257 535.901001,715.226257 538.194519,715.226257 
	C538.802124,719.924377 539.347900,724.144409 539.934814,729.106934 
	C540.333252,731.551514 540.690552,733.253723 540.639038,734.955078 
	C539.141418,734.973999 538.052734,734.993652 536.963989,735.013367 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M522.090576,612.918213 
	C522.090576,614.772766 522.090576,616.627319 522.090576,619.128418 
	C522.356567,619.532471 523.150024,620.737915 523.956543,622.277649 
	C522.942932,622.848694 521.916260,623.085449 520.179077,623.261963 
	C518.976929,623.142029 518.485168,623.082397 517.913086,622.850098 
	C517.556519,622.782654 517.280273,622.888000 516.612793,623.023315 
	C515.175415,623.056396 514.129395,623.059570 512.761719,622.903870 
	C511.298279,622.473450 510.156342,622.201782 508.839417,621.714294 
	C508.100067,621.957581 507.535706,622.416809 506.733337,622.890015 
	C506.495270,622.904053 506.018341,622.898926 506.006256,622.562988 
	C505.607727,621.606995 505.221283,620.986938 504.834839,620.366821 
	C504.296082,621.081177 503.757355,621.795593 503.157776,622.735596 
	C502.898743,623.242920 502.869720,623.541199 503.009796,623.855896 
	C500.302917,623.864075 497.596039,623.872253 494.356964,623.565002 
	C494.036682,622.432068 494.248566,621.614563 494.820160,619.409424 
	C492.285370,621.270813 490.618866,622.494629 488.952362,623.718445 
	C486.931488,623.777893 484.910645,623.837341 482.431152,623.448181 
	C480.940460,623.228638 479.908417,623.457703 478.876373,623.686768 
	C478.876373,623.686768 479.046844,623.806885 478.698395,623.760071 
	C477.923248,623.805908 477.496582,623.898621 477.069885,623.991211 
	C475.959991,623.901245 474.850098,623.811279 473.327148,623.262573 
	C472.556702,622.445435 472.199310,622.087036 471.883484,621.382141 
	C472.303284,619.683594 472.681580,618.331604 473.417236,616.938721 
	C474.818298,615.913635 475.772552,614.803833 476.929932,613.979065 
	C478.170288,613.095093 479.601776,612.479370 480.949829,611.746521 
	C481.040955,612.040405 481.132080,612.334290 481.223175,612.628174 
	C480.021484,614.310547 478.819794,615.992859 477.611359,617.684692 
	C481.162964,616.842468 483.860901,616.202637 486.558838,615.562866 
	C486.170959,617.102600 485.783051,618.642395 485.347107,620.372925 
	C490.347198,622.084167 494.044891,612.417419 498.948212,618.433350 
	C501.012665,617.588745 503.406799,615.924622 504.066498,616.447754 
	C509.287079,620.587830 513.338440,616.575623 517.682434,614.872070 
	C518.263855,614.644043 518.492310,613.515930 518.592773,613.333496 
	C516.882446,612.847595 515.452637,612.441345 514.283691,611.765442 
	C514.869263,609.812378 515.193970,608.129028 515.626648,605.885681 
	C517.469482,607.365112 517.598022,612.605774 520.885254,607.684692 
	C521.355652,609.727173 521.723145,611.322693 522.090576,612.918213 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M309.987488,744.037109 
	C309.557159,743.981750 309.126831,743.926331 308.171570,743.636841 
	C306.759949,743.590637 305.873260,743.778564 304.986542,743.966431 
	C304.544189,743.976624 304.101868,743.986816 303.080231,744.010010 
	C302.337433,744.060181 302.173950,744.097290 302.010498,744.134338 
	C301.833618,744.110046 301.656738,744.085754 301.194244,743.676270 
	C300.389160,741.755554 299.869690,740.219971 299.190826,738.213196 
	C299.415924,732.692627 299.815369,726.431763 299.792969,720.172302 
	C299.788940,719.051941 298.040558,717.937866 297.263062,716.583618 
	C298.284027,715.892334 299.139557,715.438232 300.394257,715.028076 
	C301.863281,715.045166 302.933105,715.018250 304.082275,715.338562 
	C304.269287,716.148254 304.376984,716.610718 304.417297,717.273560 
	C304.456512,717.768677 304.663757,717.946350 304.983093,718.010010 
	C304.994537,718.013000 304.981110,718.033142 304.950317,718.353516 
	C305.186890,720.409363 305.454254,722.144897 305.585510,724.240845 
	C305.288635,726.395020 305.127869,728.188843 304.695160,730.147461 
	C303.785950,731.362732 303.148682,732.413025 302.293335,733.822754 
	C303.469482,733.909058 304.233124,733.965027 304.974854,734.408813 
	C304.950287,735.832214 304.947662,736.867798 304.756409,738.005676 
	C304.706390,738.411804 304.845062,738.715698 304.991852,739.009766 
	C305.000000,739.000000 304.978058,739.015076 305.010437,739.380615 
	C306.742493,740.429626 308.442200,741.113037 310.141907,741.796509 
	C310.564789,740.506165 310.987671,739.215881 311.426453,737.673462 
	C311.448242,737.253113 311.454163,737.084839 311.543091,736.713989 
	C311.546692,736.202881 311.360168,735.986816 311.081055,735.515259 
	C311.121246,734.474182 311.146942,733.780823 311.412048,732.762329 
	C311.882812,728.109131 312.954315,723.610596 309.340149,720.269714 
	C311.257721,718.916321 313.225677,717.527405 315.193665,716.138489 
	C315.193665,716.138489 315.477417,716.181763 315.654663,716.520203 
	C316.681488,717.371948 317.570465,718.363220 318.372253,718.297119 
	C319.942688,718.167480 321.464447,717.448364 323.343811,717.014648 
	C324.785034,717.704895 325.888885,718.353760 326.718079,719.223389 
	C325.086609,720.139709 323.740814,720.858093 322.369873,721.524719 
	C320.845184,722.266052 318.226135,722.684387 317.957794,723.726135 
	C317.098785,727.060791 317.106995,730.618896 316.801758,734.377808 
	C316.852264,735.048096 316.881500,735.431274 316.729065,735.953125 
	C316.300140,736.346619 316.267120,736.626648 316.446320,737.183289 
	C316.456970,737.601990 316.469696,737.769226 316.348816,738.287598 
	C317.477936,739.757446 318.740692,740.876221 320.199280,742.033569 
	C320.395111,742.072144 320.793884,742.090820 320.793884,742.090820 
	C320.675598,742.614685 320.557343,743.138611 319.967346,743.803711 
	C319.329254,743.972168 319.162903,743.999390 318.996582,744.026550 
	C318.555847,744.011414 318.115082,743.996216 316.908020,743.966431 
	C314.090302,743.980164 312.038879,744.008667 309.987488,744.037109 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M326.990662,319.990448 
	C327.164825,318.565887 327.338989,317.141327 327.554016,315.382538 
	C326.386902,315.831696 325.462952,316.187256 324.539032,316.542816 
	C324.266785,316.088165 323.994568,315.633514 323.722351,315.178833 
	C325.620544,314.028107 327.518738,312.877380 329.235504,311.836639 
	C328.497589,309.463928 327.775665,307.142700 327.276855,304.425659 
	C327.499939,304.029877 327.913422,304.029602 328.179474,303.936890 
	C328.640137,303.532867 328.834747,303.221558 329.032898,302.697052 
	C329.036407,302.483856 329.014984,302.057953 329.277771,301.848328 
	C329.701508,300.770538 329.862427,299.902374 330.015015,299.022797 
	C330.006653,299.011383 330.034454,299.008270 330.313812,298.846069 
	C330.731506,296.790863 330.869812,294.897888 331.227356,292.861115 
	C327.736176,289.322815 328.987396,285.087708 329.859192,281.951935 
	C327.190613,280.776276 324.684753,280.306793 323.121674,278.835632 
	C320.750671,276.604034 320.907684,276.341583 318.707397,280.035278 
	C318.032104,280.063324 317.647675,280.098877 317.136322,279.903015 
	C316.728790,279.480560 316.448212,279.289581 316.167603,279.098572 
	C316.049438,277.370544 315.931274,275.642487 316.180084,273.648834 
	C318.761749,272.896423 320.976440,272.409637 323.536041,271.937317 
	C325.138550,271.951782 326.396179,271.951782 327.687592,271.951782 
	C327.221771,273.183105 326.794891,274.311432 326.337402,275.520752 
	C334.721497,276.707825 336.542877,281.872162 331.187378,289.546204 
	C333.437927,290.416199 335.554077,291.234253 337.739014,292.078888 
	C337.253418,293.033875 336.695435,294.131226 336.102509,295.297302 
	C337.339020,295.615387 338.220215,295.842041 339.083038,296.394318 
	C339.051178,297.155548 339.037659,297.591187 338.772247,297.929504 
	C338.014008,298.221863 337.507599,298.611542 336.996674,299.250793 
	C336.992126,299.500366 337.000427,299.999481 336.999939,300.249542 
	C336.999786,300.666321 337.000092,300.833008 336.976196,301.328278 
	C336.645905,303.453033 336.339874,305.249237 336.029968,307.407562 
	C336.034668,308.492126 336.043243,309.214600 335.896423,310.278717 
	C335.494843,312.079742 335.248657,313.539124 335.001221,314.999237 
	C335.000000,315.000000 334.997559,315.002441 334.648499,315.055786 
	C332.867950,317.717865 331.436432,320.326599 330.002686,323.262207 
	C330.024536,324.022308 330.048645,324.455566 329.780151,324.903168 
	C329.292236,324.922272 329.096985,324.927032 328.901703,324.931763 
	C328.782898,324.094177 328.664093,323.256622 328.259949,321.920837 
	C327.646637,320.945221 327.318634,320.467834 326.990662,319.990448 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M367.087616,262.542023 
	C371.734131,262.448914 376.380646,262.355835 381.492493,262.709625 
	C382.177460,263.971283 382.397034,264.786072 382.450592,264.984894 
	C383.920441,264.613617 384.971558,264.348114 386.008545,264.465088 
	C386.148285,266.107269 386.302094,267.366974 386.559906,269.478394 
	C388.044800,267.665588 388.786957,266.759521 389.476807,265.917297 
	C390.048431,267.773163 390.552429,269.409515 391.036377,271.290466 
	C391.016357,271.535034 391.004883,272.025726 391.001373,272.011292 
	C390.997864,271.996887 391.026367,272.005829 390.770386,271.998169 
	C390.343719,271.996094 390.173065,272.001648 389.642822,272.067047 
	C388.888580,273.429169 388.493958,274.731445 388.080139,276.047150 
	C388.060944,276.060577 388.039337,276.102173 387.668976,276.014526 
	C386.653717,274.218872 386.008820,272.510834 384.891418,269.551331 
	C384.120514,273.748444 383.548431,276.863037 382.611694,279.956238 
	C378.831696,278.261444 375.416351,276.587982 371.992371,274.676636 
	C371.983704,274.438751 371.943207,273.962494 372.089294,273.644653 
	C373.258606,271.482178 374.281799,269.637604 375.304993,267.793030 
	C375.135132,267.598724 374.965240,267.404419 374.795380,267.210114 
	C372.869019,268.796631 370.942688,270.383148 369.007843,271.984833 
	C368.999359,272.000000 369.028687,271.981415 369.016602,271.612915 
	C368.373199,270.580170 367.741852,269.915955 367.110504,269.251740 
	C366.735931,270.167419 366.361328,271.083099 365.695435,272.028076 
	C365.211792,272.090820 365.019470,272.124268 364.521820,272.139404 
	C363.806213,272.088654 363.395935,272.056213 362.985718,271.715759 
	C362.884155,270.883087 362.782532,270.358490 362.680939,269.833862 
	C362.490570,269.847656 362.300232,269.861420 362.109863,269.875214 
	C362.109863,270.612305 362.109863,271.349426 362.101257,272.096649 
	C362.092621,272.106781 362.115234,272.137268 361.736664,272.102234 
	C358.913910,270.706085 356.469757,269.344971 354.217590,267.730164 
	C355.185059,267.043976 355.960510,266.611542 356.999786,266.032013 
	C355.720764,265.163788 354.824951,264.555695 353.955078,263.673828 
	C353.999512,263.034973 354.018036,262.669952 354.036560,262.304871 
	C357.367645,262.320374 360.698700,262.335876 364.540771,262.704071 
	C365.730408,262.885193 366.408997,262.713623 367.087616,262.542023 
M376.620972,271.776947 
	C376.243805,271.722717 375.866669,271.668488 375.489502,271.614227 
	C375.487427,271.887390 375.485382,272.160553 375.483307,272.433716 
	C375.863190,272.392670 376.243042,272.351624 376.620972,271.776947 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M215.150009,517.812073 
	C213.506882,512.275513 211.863739,506.738953 210.220612,501.202393 
	C210.636581,500.912170 211.052567,500.621918 211.468552,500.331696 
	C214.517380,501.818542 217.802856,502.967651 220.546524,504.890045 
	C223.140884,506.707733 224.958344,506.441101 227.111374,504.495728 
	C229.887421,501.987457 232.790146,499.619415 236.653381,496.325439 
	C237.575058,506.041809 233.927673,516.511658 245.586121,521.412415 
	C244.549240,522.198181 243.335754,522.677490 242.211578,522.519836 
	C237.378555,521.842224 232.592163,520.547302 227.748962,520.317688 
	C223.917404,520.136108 220.027817,521.178955 216.561325,521.626709 
	C215.996521,520.100098 215.573257,518.956116 215.150009,517.812073 
M234.275864,501.023926 
	C233.149429,502.209625 232.037476,503.409454 230.894028,504.578491 
	C226.124329,509.455170 223.069641,509.720062 217.397842,505.863800 
	C215.938339,504.871429 214.363525,504.048645 212.872330,503.166107 
	C213.732773,506.024933 214.051666,508.508301 215.210190,510.504303 
	C216.223495,512.250000 217.956314,514.121887 219.774612,514.727905 
	C222.431900,515.613586 225.500626,515.167969 228.302536,515.738708 
	C232.777908,516.650330 234.990036,515.146973 235.103104,510.514618 
	C235.180130,507.359253 235.074799,504.199432 235.341873,500.915253 
	C235.213058,500.925720 235.084229,500.936188 234.275864,501.023926 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M506.772644,644.789917 
	C508.549347,641.797485 511.100647,641.858521 514.123657,642.556519 
	C515.991699,642.987915 518.258972,643.135925 519.989502,642.475037 
	C529.047363,639.016174 536.806274,640.841736 542.070862,648.766357 
	C540.419617,649.959778 538.804688,650.729187 537.158081,650.804260 
	C533.117371,650.988586 529.062012,650.852905 525.012573,650.845947 
	C524.335266,650.963562 523.658020,651.081177 522.165039,651.055786 
	C516.827576,650.855957 512.305725,650.799194 507.783905,650.742432 
	C507.378815,650.201904 506.973755,649.661438 506.627502,648.382812 
	C506.715088,646.693176 506.743866,645.741516 506.772644,644.789917 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M498.948975,656.974304 
	C498.700104,664.985962 500.957092,673.344238 494.950867,680.797302 
	C489.850372,679.450500 485.471100,677.965576 481.462769,676.371582 
	C485.663208,675.655884 487.039276,673.188049 487.045288,669.562439 
	C487.052673,665.077454 487.161743,660.592529 487.615540,656.081177 
	C489.753662,655.635742 491.568390,655.389404 493.237579,654.758240 
	C495.912048,653.746887 497.892273,654.044861 498.948975,656.974304 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M520.067200,654.984009 
	C520.094177,663.348267 520.121155,671.712524 519.756531,680.769897 
	C516.053406,681.750977 512.741882,682.039001 509.257324,682.342041 
	C509.257324,672.332642 509.257324,663.305115 509.257324,653.275757 
	C513.157532,653.892090 516.612366,654.437988 520.067200,654.984009 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M339.101379,296.068695 
	C338.220215,295.842041 337.339020,295.615387 336.102509,295.297302 
	C336.695435,294.131226 337.253418,293.033875 337.739014,292.078888 
	C335.554077,291.234253 333.437927,290.416199 331.187378,289.546204 
	C336.542877,281.872162 334.721497,276.707825 326.337402,275.520752 
	C326.794891,274.311432 327.221771,273.183105 327.687592,271.951782 
	C326.396179,271.951782 325.138550,271.951782 323.545532,271.573059 
	C323.686157,269.560974 324.162170,267.927673 324.717407,266.022491 
	C325.985962,267.553040 326.907440,268.664856 328.507019,270.594818 
	C328.892548,267.197906 329.146790,264.957611 329.401062,262.717285 
	C330.138885,262.578796 330.876709,262.440277 331.827576,262.773041 
	C331.698425,264.625519 331.356262,266.006714 331.014069,267.387909 
	C331.321564,267.496399 331.629059,267.604889 331.936523,267.713348 
	C333.288971,266.423859 334.641418,265.134338 336.236145,263.901550 
	C336.478424,263.958252 336.972992,264.013580 336.949982,264.364655 
	C336.904816,265.418976 336.882660,266.122253 336.546082,266.917084 
	C336.048553,268.033569 335.865479,269.058472 335.682404,270.083374 
	C336.126953,269.749603 336.571472,269.415863 337.376862,269.069824 
	C338.753326,269.957764 339.768951,270.858002 340.728607,271.708618 
	C340.921234,271.273315 341.226227,270.584137 342.023346,268.782928 
	C342.023346,271.030151 342.023346,272.005981 342.014679,273.359497 
	C342.676544,274.818604 343.347046,275.900055 344.031799,277.230774 
	C344.154633,277.771942 344.263245,278.063843 344.371857,278.355713 
	C344.500549,278.265656 344.629211,278.175598 344.757904,278.085541 
	C344.499664,277.727417 344.241425,277.369293 344.005676,276.661072 
	C344.275208,275.658966 344.522278,275.006897 345.190613,273.242981 
	C346.017792,276.276825 346.522522,278.128143 346.656677,279.995544 
	C345.502258,280.976898 344.718445,281.942169 343.537231,282.942291 
	C341.094147,282.184265 339.048492,281.391388 336.483459,280.397186 
	C336.988464,281.916840 337.267548,283.628937 338.091278,285.018036 
	C339.160492,286.821045 340.663147,288.366943 341.975525,290.326172 
	C341.954407,291.031952 341.937073,291.435120 341.601898,291.879791 
	C340.556519,293.303741 339.828949,294.686218 339.101379,296.068695 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M595.198059,648.613403 
	C591.456482,649.312500 587.700623,649.676880 583.490356,650.025269 
	C582.968933,643.965149 582.901794,637.921082 583.170044,631.201050 
	C584.027771,628.767273 584.550110,627.009399 585.072510,625.251526 
	C587.479309,625.108154 589.886047,624.964722 592.621765,625.414978 
	C593.630798,628.319397 594.310974,630.630188 594.991089,632.940918 
	C595.024658,636.670044 595.058167,640.399170 594.815918,644.714294 
	C594.692261,645.851562 594.844360,646.402771 594.996460,646.954041 
	C595.058899,647.395569 595.121277,647.837158 595.198059,648.613403 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M391.056427,271.045868 
	C390.552429,269.409515 390.048431,267.773163 389.476807,265.917297 
	C388.786957,266.759521 388.044800,267.665588 386.559906,269.478394 
	C386.302094,267.366974 386.148285,266.107269 386.239441,264.365723 
	C386.704468,263.457886 386.924500,263.031860 387.144531,262.605835 
	C391.429626,262.509186 395.714722,262.412567 400.559052,262.747742 
	C403.414215,263.020447 405.710175,262.861420 408.006104,262.702393 
	C412.015350,262.608765 416.024597,262.515106 420.461792,262.569550 
	C421.594025,266.833344 418.643219,267.703552 414.896637,268.551758 
	C416.462097,269.644806 417.485687,270.359497 419.023376,271.433167 
	C416.658081,272.026306 414.835144,272.483429 412.370544,273.101471 
	C412.143005,270.853668 411.951508,268.961914 411.735138,266.824585 
	C410.168854,269.423096 410.120117,273.567017 405.720337,272.702026 
	C406.389771,271.036804 407.008484,269.497742 407.627197,267.958649 
	C407.095612,267.700134 406.564026,267.441620 406.032471,267.183075 
	C404.793915,268.652039 403.555389,270.120972 402.052460,271.903442 
	C401.346619,270.658752 400.714783,269.544647 400.082977,268.430511 
	C399.857239,268.477661 399.631531,268.524811 399.405792,268.571991 
	C399.577209,270.842651 399.748627,273.113312 399.963074,275.954163 
	C394.573975,273.769623 396.577484,269.266693 395.559082,265.415009 
	C395.076660,268.430450 394.691284,270.839172 393.754120,273.609863 
	C392.155762,273.926697 391.109222,273.881531 390.078766,273.683472 
	C390.094849,273.530548 390.071716,273.223877 390.307434,273.085999 
	C390.704224,272.634033 390.865295,272.319946 391.026367,272.005829 
	C391.026367,272.005829 390.997864,271.996887 391.192383,271.950623 
	C391.456604,271.792267 391.610382,271.616211 391.581879,271.578735 
	C391.432129,271.381805 391.236267,271.219910 391.056427,271.045868 
M417.625458,264.499634 
	C417.625458,264.499634 417.425171,264.380096 417.425171,264.380096 
	C417.425171,264.380096 417.451569,264.624054 417.625458,264.499634 
M415.636749,265.520569 
	C415.636749,265.520569 415.495850,265.608917 415.636749,265.520569 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M737.088196,573.826233 
	C731.139771,569.589905 723.894714,571.352783 716.992310,569.059692 
	C715.900085,568.960449 715.495117,568.906982 715.028931,568.497314 
	C712.781799,567.061584 710.669922,565.760803 708.379028,564.995789 
	C707.232544,564.612854 705.759644,565.207581 704.434326,565.360107 
	C705.323120,566.582214 706.211853,567.804382 707.069824,569.357239 
	C706.670715,570.440186 706.302429,571.192383 705.575562,571.933105 
	C704.501343,571.961304 703.785706,572.001038 703.070007,572.040771 
	C702.450195,570.461731 701.830322,568.882690 701.071045,566.600342 
	C699.943481,563.607239 698.955261,561.317322 698.287842,558.982422 
	C702.440796,558.936340 706.272949,558.935303 710.411743,558.975952 
	C711.457581,559.374451 712.196838,559.731384 712.990906,560.441589 
	C714.727844,562.189026 716.245850,564.464600 718.122437,564.813904 
	C724.044250,565.916077 730.091675,566.626831 736.109131,566.786133 
	C738.376709,566.846252 740.695129,564.982422 743.335999,563.960083 
	C744.780518,563.577820 745.879395,563.231079 746.892822,563.168823 
	C746.542969,563.984619 746.278564,564.516052 746.014160,565.047424 
	C743.136475,567.910583 740.258850,570.773743 737.088196,573.826233 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M634.374084,712.327087 
	C634.230103,720.754822 634.006592,729.182190 633.974365,737.610352 
	C633.957031,742.134277 637.349731,741.128418 640.585266,741.326782 
	C641.958618,741.926147 642.833191,742.231995 643.707825,742.537842 
	C643.821777,741.370422 643.935730,740.202942 644.284546,738.854736 
	C644.669617,738.114807 644.819702,737.555664 645.061890,736.952271 
	C645.153931,736.908020 645.353577,736.864502 645.353577,736.864502 
	C646.464233,738.931946 647.574890,740.999451 649.188660,744.003418 
	C640.740845,744.003418 633.075256,744.003418 626.385864,744.003418 
	C627.039246,739.611084 627.901978,735.741394 628.123779,731.835266 
	C628.405579,726.873291 628.288940,721.876648 628.077881,716.906250 
	C628.016479,715.460083 627.044800,714.052551 626.659302,712.330139 
	C629.343506,712.131042 631.858826,712.229065 634.374084,712.327087 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M643.112610,684.449951 
	C644.005371,683.072144 644.975830,681.289490 646.328247,680.925110 
	C652.590149,679.238220 658.969666,677.993164 665.281860,676.484619 
	C667.398804,675.978638 669.441223,675.160828 671.739624,674.743042 
	C672.067139,675.475220 672.012024,676.196167 672.303284,676.386292 
	C675.086609,678.201904 676.229004,680.645508 674.192078,683.449158 
	C672.177063,686.222717 669.405029,686.303955 666.259644,684.805481 
	C664.932678,684.173218 662.816650,684.209778 661.449646,684.818787 
	C655.339600,687.540649 649.375610,687.699890 643.112610,684.449951 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M625.198608,627.453247 
	C625.287842,634.700073 625.287842,641.589661 625.287842,649.099182 
	C621.059387,649.099182 617.601746,649.099182 613.776794,649.043701 
	C613.505066,642.620911 613.600586,636.253479 614.073120,629.278137 
	C614.974854,627.520203 615.499573,626.370239 616.024353,625.220276 
	C619.639160,623.005127 622.686035,623.540527 625.198608,627.453247 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M236.782806,679.007385 
	C236.729065,671.981018 236.675308,664.954712 237.016479,657.321411 
	C241.143127,657.177490 244.874878,657.640442 248.714142,658.553833 
	C248.821671,664.238770 248.821671,669.473145 248.821671,674.663269 
	C250.693115,674.798096 251.846405,674.881165 252.993073,675.363525 
	C247.585236,676.844421 242.184021,677.925903 236.782806,679.007385 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M221.178314,470.273254 
	C221.926453,469.910919 222.617249,469.942474 223.657379,470.360901 
	C224.130310,472.002716 224.253876,473.257690 224.450043,475.249756 
	C230.322708,472.343506 235.661896,469.701263 241.001068,467.058990 
	C241.737900,467.059692 242.474716,467.060394 243.655853,467.409302 
	C246.189468,470.085541 248.382843,469.959778 251.001480,468.463226 
	C255.379089,465.961395 259.917572,463.910797 264.598907,469.212616 
	C264.776459,467.305939 264.879211,466.202576 264.981995,465.099213 
	C269.248779,466.492950 268.602631,468.550049 265.696198,470.799744 
	C264.682343,471.584534 263.502655,472.155029 261.800476,472.620422 
	C259.873657,472.354401 258.431305,471.931519 257.241577,472.307648 
	C255.145676,472.970245 253.203995,474.120667 250.977692,474.797974 
	C249.533890,473.973480 248.310120,473.417847 246.992279,472.512115 
	C245.307480,472.084259 243.520569,471.493408 242.158676,472.014160 
	C234.719315,474.858795 227.384430,477.976654 220.013657,481.000641 
	C219.836411,479.450043 219.365356,477.862366 219.555420,476.358154 
	C219.799530,474.426056 220.574631,472.561005 221.178314,470.273254 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M236.600433,679.260620 
	C242.184021,677.925903 247.585236,676.844421 253.370560,675.223145 
	C256.785431,674.042114 259.816162,673.400757 263.051636,673.061768 
	C268.370300,672.200073 273.484222,671.036011 278.598145,669.871826 
	C277.357819,672.587769 276.117493,675.303711 274.339111,677.812439 
	C264.655884,679.637268 255.503784,681.639587 246.377533,683.753357 
	C245.575256,683.939087 244.941879,684.854126 244.230042,685.430420 
	C239.413437,686.379578 237.291138,683.771423 236.600433,679.260620 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M357.133881,720.967957 
	C358.284088,721.992249 359.434296,723.016541 360.734924,724.466919 
	C360.297119,728.125916 358.263947,731.502808 361.858337,734.662964 
	C361.962555,735.693726 362.033844,736.383362 362.086029,737.308655 
	C362.066925,737.544250 361.980347,738.009033 361.980347,738.009033 
	C359.841980,739.640564 357.703644,741.272095 355.302979,742.554382 
	C355.804657,738.683228 356.568665,735.161255 357.177795,731.332153 
	C355.438904,729.915344 353.962860,728.116943 352.246979,727.847839 
	C349.235382,727.375671 345.685577,724.854187 343.108887,728.896484 
	C338.756500,727.253357 339.231873,723.465210 338.964539,719.891174 
	C338.698486,716.333679 341.431671,715.684387 343.675720,715.262939 
	C347.666656,714.513245 351.777710,714.403015 355.543701,714.502319 
	C353.053009,715.294189 350.812958,715.437622 348.677490,715.987183 
	C347.358490,716.326660 346.198669,717.284668 344.626709,717.941223 
	C343.300476,718.394592 342.314941,718.871704 341.329407,719.348755 
	C341.695648,719.832458 342.061890,720.316162 342.428131,720.799866 
	C343.295410,719.876526 344.162689,718.953186 345.466431,718.031067 
	C352.157104,716.587830 354.967316,719.846191 355.380981,726.377136 
	C355.786377,726.099243 356.191803,725.821289 356.597198,725.543396 
	C356.776093,724.018250 356.954987,722.493103 357.133881,720.967957 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M685.010132,641.006592 
	C684.976685,646.627686 685.304382,652.281189 684.803223,657.860229 
	C684.346436,662.945374 686.240417,665.246094 691.165344,665.476013 
	C692.460205,665.536438 693.745178,665.808411 695.555176,666.222534 
	C695.984192,667.395569 695.892822,668.329224 695.801453,669.262939 
	C692.120483,670.656677 688.439514,672.050354 684.504089,673.682495 
	C684.053284,662.670227 683.821655,651.419983 683.678894,640.168640 
	C683.626343,636.027161 683.783569,631.883057 684.313599,627.709839 
	C685.252686,632.829651 687.110779,633.984802 694.741089,633.702454 
	C695.009888,631.355347 695.283264,628.968262 695.556641,626.581116 
	C695.809143,630.156799 696.061584,633.732483 695.733398,637.619263 
	C691.771790,638.955750 688.390930,639.981140 685.010132,641.006592 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M251.197723,475.066833 
	C253.203995,474.120667 255.145676,472.970245 257.241577,472.307648 
	C258.431305,471.931519 259.873657,472.354401 261.433105,472.619629 
	C252.760941,477.338593 250.518372,484.708923 252.154144,494.061310 
	C252.721695,497.306274 252.244507,500.733948 252.244507,504.078186 
	C251.643570,504.306396 251.042648,504.534607 250.441711,504.762817 
	C246.135117,499.695038 241.828522,494.627258 237.697678,489.270569 
	C238.267715,488.966522 238.661987,488.951385 239.436493,488.959930 
	C246.307831,489.881714 250.882645,485.416748 249.006531,479.641113 
	C249.722122,477.881958 250.459915,476.474396 251.197723,475.066833 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M768.939331,526.055237 
	C772.938782,527.717957 776.938171,529.380676 780.937622,531.043457 
	C780.959351,531.755859 780.981140,532.468323 781.002869,533.180725 
	C777.539429,534.392700 774.075989,535.604675 770.347412,536.533508 
	C769.725769,535.857849 769.369263,535.465393 768.951599,534.708008 
	C763.595703,532.555969 758.300964,530.768799 752.977600,528.765991 
	C752.949036,528.550232 752.920532,528.115906 752.912292,527.780640 
	C752.268555,526.955444 751.633057,526.465515 750.980225,525.761047 
	C750.962891,525.546509 750.937866,525.116821 750.960815,524.711548 
	C750.997620,523.497559 751.011414,522.688782 751.388306,521.850220 
	C754.808228,520.709229 757.864990,519.598022 761.164551,518.398560 
	C762.192566,520.567078 763.221008,522.448914 763.990906,524.431152 
	C765.025818,527.095642 766.589661,527.645508 768.939331,526.055237 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M585.000916,728.993958 
	C584.995667,729.438232 584.990417,729.882507 584.767944,730.808472 
	C584.677185,731.866516 584.803711,732.442932 584.930237,733.019348 
	C583.454956,735.539185 581.979614,738.059021 579.876404,740.801025 
	C578.487061,741.009155 577.725586,740.995178 576.982300,740.980469 
	C577.000549,740.979797 577.006592,741.015808 577.030457,740.679077 
	C577.699890,739.225891 578.345520,738.109375 579.145874,736.955566 
	C579.202698,736.948425 579.104736,736.978577 578.632202,736.942749 
	C567.028564,736.225220 569.131042,727.522888 568.858887,720.292236 
	C566.966003,720.161865 565.516235,720.062012 564.094482,719.623779 
	C565.164856,718.243469 566.207275,717.201599 567.249695,716.159790 
	C572.044312,716.306641 571.360962,712.640259 571.813843,709.696838 
	C571.985413,708.581665 572.468445,707.514404 572.809509,706.425293 
	C573.491943,707.517456 574.588013,708.546631 574.765991,709.715515 
	C575.083496,711.801880 574.846741,713.972595 574.905762,716.974426 
	C574.657349,722.113892 574.169922,726.386414 574.154968,730.660522 
	C574.151184,731.737366 575.932068,733.847900 576.424927,733.717285 
	C578.326111,733.213440 580.405334,732.318237 581.656494,730.897949 
	C582.233765,730.242615 581.359314,727.843384 580.563782,726.607849 
	C580.036255,725.788635 578.481140,725.630920 577.386047,725.177063 
	C577.501709,724.714661 577.617371,724.252258 577.733032,723.789856 
	C579.815186,724.205261 581.897339,724.620667 583.979492,725.036072 
	C584.055847,725.779419 584.132202,726.522766 584.242676,727.864502 
	C584.446045,728.738159 584.687439,728.915161 585.000916,728.993958 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M248.822586,719.963501 
	C246.092377,728.750427 243.654434,737.647278 240.418854,746.244019 
	C239.645508,748.298828 236.228348,750.492432 233.999344,750.521973 
	C232.070160,750.547546 228.763657,747.774170 228.513290,745.909363 
	C228.243866,743.902588 230.566254,741.278442 232.279495,739.399292 
	C233.030090,738.576050 235.075516,738.933167 236.919205,738.706909 
	C236.522079,736.904419 236.178238,735.343811 236.120483,733.572327 
	C237.666931,733.947998 238.927307,734.534607 241.185501,735.585571 
	C240.570343,732.934631 240.255142,731.576294 239.939941,730.217957 
	C245.414703,728.320007 245.268646,723.291870 246.510010,718.684326 
	C247.455460,718.973206 248.139023,719.468323 248.822586,719.963501 
M233.659302,746.637756 
	C237.089264,746.425598 238.394043,744.783813 237.943604,741.961426 
	C237.457977,741.603821 236.972351,741.246277 236.486710,740.888733 
	C235.364136,742.632202 234.241562,744.375732 233.659302,746.637756 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M299.995056,714.984131 
	C299.139557,715.438232 298.284027,715.892334 297.228455,717.007935 
	C297.354736,720.112122 297.681030,722.554871 297.661957,724.961548 
	C295.227264,723.118225 293.000854,721.824158 290.067993,722.672852 
	C290.032928,721.900696 290.044128,721.457825 290.348572,720.972534 
	C290.428986,720.285095 290.216125,719.640076 289.965088,718.626953 
	C289.906128,717.518738 289.885345,716.778809 290.135132,715.884033 
	C290.601807,715.158508 290.797852,714.587830 290.993896,714.017090 
	C290.700317,712.158691 290.445282,710.292969 290.096680,708.444946 
	C289.926208,707.541199 289.112671,706.138550 289.402496,705.882996 
	C290.323242,705.071167 292.247040,703.917847 292.683655,704.267944 
	C296.500641,707.328003 300.981964,707.513611 305.519409,708.113892 
	C311.475189,708.901917 309.158630,713.167847 308.525665,717.074341 
	C307.355743,717.713440 306.168427,717.873291 304.981110,718.033142 
	C304.981110,718.033142 304.994537,718.013000 304.948914,717.830444 
	C304.818726,717.416260 304.679199,717.224731 304.484711,717.073242 
	C304.376984,716.610718 304.269287,716.148254 303.900513,715.074646 
	C302.424652,714.637085 301.209869,714.810608 299.995056,714.984131 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M315.373657,567.823853 
	C316.265900,564.709229 317.133667,561.738037 318.089447,558.795471 
	C318.138062,558.645813 318.944092,558.742188 319.645508,559.286560 
	C319.385712,563.262817 318.877838,566.676514 318.216553,570.361694 
	C318.164429,572.058411 318.265717,573.483521 318.457977,575.348633 
	C319.722260,581.124023 320.895538,586.459595 322.113281,592.044373 
	C322.376038,592.543030 322.594330,592.792480 322.873596,593.016479 
	C322.934570,592.991150 322.979950,593.115173 323.125519,593.316406 
	C324.125214,595.001892 324.979340,596.486206 325.874634,598.337036 
	C328.280426,601.812439 330.645020,604.921265 333.008362,608.011230 
	C333.007111,607.992310 332.969269,607.989380 333.120758,608.273315 
	C333.854370,608.711731 334.436462,608.866272 335.015106,609.007446 
	C335.011658,608.994080 334.985229,608.994263 334.895660,609.298340 
	C335.229095,610.546265 335.652100,611.490173 336.051422,612.882324 
	C336.030884,613.928711 336.034088,614.526794 336.157623,615.272949 
	C336.277985,615.421021 336.204376,615.795532 335.961182,615.509705 
	C333.611053,613.640747 331.504089,612.057678 329.289093,610.184937 
	C328.201080,609.161499 327.221100,608.427612 326.222534,607.337036 
	C323.161041,601.290039 320.118164,595.599731 317.053314,589.689209 
	C316.681091,589.059387 316.330841,588.649902 315.980591,588.240417 
	C315.531311,583.045837 315.082031,577.851196 315.137665,572.439270 
	C315.641266,570.982117 315.639954,569.742310 315.638672,568.502502 
	C315.450104,568.375977 315.359558,568.199524 315.373657,567.823853 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M544.050964,656.991577 
	C544.140686,664.692505 544.230408,672.393555 543.894775,680.578735 
	C543.003113,681.449646 542.536926,681.836365 541.701782,682.212036 
	C540.839172,682.208008 540.345459,682.215088 539.876221,681.821655 
	C533.409668,674.659241 536.999512,665.946716 535.840393,658.865601 
	C534.892639,660.422913 533.781433,662.248840 532.670227,664.074707 
	C532.550842,661.714722 532.431458,659.354797 532.772339,656.507080 
	C536.838745,656.343384 540.444824,656.667480 544.050964,656.991577 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M481.091797,676.480591 
	C485.471100,677.965576 489.850372,679.450500 494.642700,680.980652 
	C491.082062,686.093384 485.537292,686.885925 479.323547,686.195923 
	C478.507233,684.542358 478.261139,682.653137 477.443573,682.360535 
	C474.046234,681.144470 470.492981,680.332275 466.952301,679.582703 
	C466.425842,679.471313 465.660797,680.487061 465.006165,680.981323 
	C463.823975,678.513611 462.641785,676.045898 461.853394,673.270020 
	C466.232574,673.562134 470.217926,674.162476 474.596649,674.784058 
	C477.023926,675.363708 479.057861,675.922180 481.091797,676.480591 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M641.166138,719.040405 
	C643.214600,715.166992 645.981567,718.927063 648.830688,718.769409 
	C652.095825,720.010742 654.871948,720.847656 657.648010,721.684631 
	C657.802795,721.409302 657.957581,721.133972 658.112305,720.858704 
	C656.129822,720.007263 654.147278,719.155884 652.164734,718.304504 
	C653.934448,718.191345 655.715210,717.904419 657.470459,718.016907 
	C659.020264,718.116333 660.541443,718.661194 662.619629,719.177002 
	C663.787903,719.818665 664.411743,720.292725 664.604431,720.844604 
	C663.165710,721.280701 661.622253,721.345947 661.239746,722.045898 
	C659.151733,725.865967 661.629333,737.949463 665.213013,741.431396 
	C666.630676,742.700012 667.784424,743.738892 668.938110,744.777832 
	C660.110413,745.247070 655.248108,740.737732 655.087341,731.903076 
	C655.054016,730.073975 655.158142,728.242126 655.115356,726.413513 
	C654.973328,720.337830 652.842651,719.270325 646.755249,722.532471 
	C644.489014,721.393311 642.827576,720.216858 641.166138,719.040405 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M499.121338,656.715698 
	C497.892273,654.044861 495.912048,653.746887 493.237579,654.758240 
	C491.568390,655.389404 489.753662,655.635742 487.406250,655.775146 
	C484.580353,650.033752 480.055542,651.572937 475.912415,651.552002 
	C471.984161,650.128479 468.064117,649.076538 464.144073,648.024658 
	C466.268616,646.162537 468.393127,644.300354 470.979431,642.781311 
	C479.839996,645.061646 488.238800,646.998901 496.637634,648.936157 
	C497.523010,651.443115 498.408356,653.950134 499.121338,656.715698 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M597.057617,716.284912 
	C607.697021,714.782288 609.723694,715.324280 609.768555,727.856018 
	C609.778931,730.757080 609.061646,733.957031 612.772461,735.991699 
	C611.539368,737.562439 610.292908,738.542725 609.046387,739.523071 
	C610.421875,740.664978 611.797424,741.807007 613.172913,742.948975 
	C608.622070,746.288391 604.690491,744.458984 604.257019,738.500427 
	C603.920715,733.878113 604.127991,729.212219 604.182800,724.566711 
	C604.233521,720.274475 602.832397,717.736511 597.305725,718.900513 
	C596.783447,717.956604 596.920532,717.120728 597.057617,716.284912 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M779.092896,706.969910 
	C779.725830,708.114197 780.798767,709.214661 780.917603,710.410095 
	C781.519958,716.469421 782.469421,717.083374 788.398621,714.856689 
	C789.090942,714.596741 789.916931,714.693054 790.882812,715.259949 
	C793.376953,720.934631 795.669128,725.973511 797.961304,731.012329 
	C795.610107,731.011047 793.258911,731.009644 790.495483,730.626465 
	C790.479187,725.894226 787.749512,723.567444 784.377136,721.859619 
	C783.658264,721.495483 782.413330,722.170166 781.410645,722.366211 
	C781.672241,723.387085 781.668701,724.624756 782.273926,725.364502 
	C782.820129,726.031982 784.308350,725.912781 784.887024,726.573364 
	C785.792053,727.606506 786.938660,729.114258 786.755188,730.216553 
	C786.580811,731.264160 784.958923,732.371765 783.736816,732.856873 
	C782.586487,733.313538 781.127319,732.992432 779.767944,732.604065 
	C779.546021,728.812012 779.360168,725.429382 779.371887,721.859253 
	C779.740173,721.153870 779.910889,720.636047 780.524048,720.091858 
	C783.831848,720.065552 786.697327,720.065552 789.562744,720.065552 
	C789.570496,719.435242 789.578247,718.804871 789.585999,718.174561 
	C786.034424,717.851379 782.482849,717.528198 778.919922,717.148926 
	C778.908508,717.092773 778.808411,717.036926 778.887329,716.586060 
	C779.008423,713.080078 779.050659,710.025024 779.092896,706.969910 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M692.118042,556.952515 
	C690.853149,555.702820 689.588257,554.453125 688.323364,553.203430 
	C688.520020,552.561096 688.716675,551.918701 688.913330,551.276367 
	C693.269226,551.276367 697.716614,550.710876 701.961243,551.398193 
	C709.960144,552.693298 714.099426,548.478699 717.278748,542.170166 
	C718.156494,540.428467 719.298950,538.820251 720.648132,537.438477 
	C718.593750,546.384277 718.900818,546.834412 726.009644,545.374390 
	C726.353027,546.817139 726.647583,547.918396 726.486084,549.008179 
	C722.519226,548.996826 719.008423,548.996826 715.271667,548.996826 
	C715.146301,551.094604 715.060791,552.524475 714.735413,554.227844 
	C713.036682,554.694824 711.577881,554.888184 709.646118,555.049866 
	C707.218872,555.014038 705.217957,554.742126 703.321594,555.071716 
	C700.796204,555.510559 698.352051,556.417480 695.612549,556.973633 
	C694.274109,556.866760 693.196045,556.909668 692.118042,556.952515 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M266.773224,641.128845 
	C269.423676,640.245605 272.024933,639.140747 274.737762,638.538513 
	C277.731140,637.874023 276.788239,636.268616 276.138916,634.182678 
	C277.519257,638.107666 279.656036,642.352295 279.972321,646.728577 
	C280.394592,652.572388 279.403442,658.518311 278.511688,664.745605 
	C274.386414,664.420105 270.768799,663.769470 267.108765,662.785889 
	C267.302490,660.616943 267.538666,658.781006 268.136200,657.103882 
	C271.800262,658.158447 275.102936,659.054199 278.619873,660.008179 
	C278.619873,654.394897 278.619873,649.373657 278.619873,643.498718 
	C274.562378,644.821960 271.166290,645.929504 267.577942,646.720276 
	C267.181519,644.645264 266.977386,642.887085 266.773224,641.128845 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M470.977448,624.189758 
	C469.021729,628.406494 465.884369,630.351135 461.102020,629.861633 
	C458.023804,629.546570 454.887146,629.802490 451.062134,629.802490 
	C452.087219,628.013489 452.759125,626.840881 453.384033,625.750244 
	C453.099030,625.436340 452.872253,625.001831 452.698517,625.022034 
	C447.374176,625.642944 441.969971,625.925964 436.761414,627.085144 
	C432.417969,628.051819 428.615936,628.262939 425.360352,624.519043 
	C425.650909,623.985840 425.821991,623.903687 426.408142,623.895630 
	C430.797607,625.140503 434.728302,625.719849 438.851990,623.230469 
	C446.096619,623.396057 452.912231,623.442261 459.929810,623.719238 
	C460.720490,623.999573 461.309174,624.049194 462.320801,624.171997 
	C465.508240,623.728516 468.272766,623.211914 471.041626,623.069702 
	C471.023132,623.692627 471.000305,623.941162 470.977448,624.189758 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M729.860229,676.704468 
	C730.304504,676.188354 730.520203,675.932190 730.765259,675.708069 
	C734.084351,672.673035 737.726318,672.436584 741.122742,675.227661 
	C743.874634,677.489075 744.475464,680.638245 742.291260,683.701294 
	C740.099609,686.774719 736.879089,687.740051 733.451599,686.291870 
	C729.324890,684.548279 727.743774,681.378906 729.860229,676.704468 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M525.683533,262.744141 
	C526.724854,263.196533 527.497009,263.597198 528.492249,264.325165 
	C528.305054,267.065948 527.899170,269.480194 527.481750,271.892456 
	C527.362427,272.582123 527.209900,273.266052 527.072632,273.952637 
	C526.285095,273.464539 525.548950,272.841125 524.698608,272.518646 
	C523.251587,271.969910 521.749878,271.347015 520.239075,271.248779 
	C518.844788,271.158112 517.408325,271.715607 515.995239,271.993774 
	C516.000000,271.999908 516.012146,272.009064 515.979858,271.711853 
	C513.271179,270.165924 510.855164,269.663605 510.297089,273.859222 
	C509.100494,271.983917 508.167572,270.521912 507.415466,269.343231 
	C508.013123,267.378967 508.523621,265.701141 509.236633,263.923157 
	C509.674744,263.455780 509.910339,263.088562 510.145935,262.721344 
	C510.830139,262.613403 511.514374,262.505463 512.564087,262.792175 
	C513.378418,263.668793 513.827271,264.150726 514.276123,264.632660 
	C514.562073,263.972961 514.847961,263.313263 515.133911,262.653564 
	C517.908936,262.581726 520.683960,262.509888 523.803589,262.881714 
	C524.399048,263.178192 524.649780,263.030975 524.900574,262.883789 
	C525.071777,262.820007 525.243042,262.756226 525.683533,262.744141 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M708.811462,728.894592 
	C705.842163,729.235046 702.872864,729.575439 699.358398,729.637878 
	C698.596069,729.128662 698.329102,729.005188 698.012329,728.989502 
	C697.567322,728.997437 697.122314,729.005249 695.980103,729.039734 
	C693.469299,730.805481 692.590210,734.070862 689.029053,732.568909 
	C689.379517,731.125366 689.703186,730.024963 690.370117,728.794189 
	C693.663208,727.836548 696.616882,727.022583 699.561218,726.176392 
	C701.033020,725.753235 702.487793,725.271118 703.950317,724.815735 
	C703.269226,723.038635 703.019348,720.194824 701.816467,719.708496 
	C699.383667,718.725159 696.314026,718.447327 693.738586,718.985962 
	C691.956360,719.358643 690.561951,721.585571 688.813477,722.744324 
	C687.876953,722.357666 687.121338,722.210571 686.411987,721.751709 
	C687.028015,720.325500 687.597717,719.210999 688.167480,718.096558 
	C696.217041,713.433411 702.698792,713.773315 708.562500,721.327576 
	C708.850586,724.285217 708.830994,726.589905 708.811462,728.894592 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M636.272339,673.244507 
	C636.563538,668.497375 636.894470,664.084717 637.223450,659.698303 
	C644.499634,657.910828 647.088074,659.780762 647.167480,666.708313 
	C647.195923,669.193237 647.408752,671.675964 647.560120,674.597107 
	C644.270264,675.082031 641.211426,679.702332 636.954651,675.028320 
	C636.541138,674.444458 636.426636,674.011780 636.272339,673.244507 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M201.843307,705.970398 
	C201.999512,705.726379 202.191376,705.514587 202.702515,705.552490 
	C203.307434,706.847839 203.628723,707.925842 203.950012,709.003845 
	C203.934509,709.745667 203.919022,710.487549 203.669662,711.820801 
	C203.962067,713.673767 204.488342,714.935181 205.014603,716.196655 
	C205.444229,716.211365 205.873856,716.226013 206.802948,716.407959 
	C207.872726,716.399780 208.443054,716.224365 209.013367,716.048828 
	C210.068436,716.064758 211.123520,716.080688 212.687469,716.404907 
	C210.902374,717.163086 208.459061,717.271851 206.368927,718.187195 
	C205.057297,718.761475 204.276215,720.547363 203.257111,721.789673 
	C204.332413,722.491943 205.324860,723.482544 206.504532,723.821655 
	C207.894852,724.221252 209.447327,724.056763 210.929504,724.136902 
	C211.924011,725.547241 212.918503,726.957581 214.004578,729.085083 
	C213.727539,732.200867 213.358932,734.599487 212.581543,737.006714 
	C210.816727,737.009521 209.460678,737.003601 208.206451,736.662964 
	C210.496918,734.815247 213.380814,733.011658 211.623947,730.186890 
	C210.220825,727.931030 207.194855,726.684448 205.314407,725.302307 
	C203.268951,727.962769 202.068359,729.524353 200.514069,731.546021 
	C199.620148,729.896545 199.090607,728.919495 198.561066,727.942383 
	C198.642975,725.937744 198.724899,723.933044 198.727463,721.137207 
	C196.798813,719.840454 194.949509,719.334900 193.100189,718.829407 
	C193.238480,718.282654 193.376770,717.735840 194.152557,717.046509 
	C196.799255,715.875793 199.660294,715.337891 200.604828,713.696899 
	C201.821075,711.583862 201.501419,708.586731 201.843307,705.970398 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M779.804016,733.013428 
	C781.127319,732.992432 782.586487,733.313538 783.736816,732.856873 
	C784.958923,732.371765 786.580811,731.264160 786.755188,730.216553 
	C786.938660,729.114258 785.792053,727.606506 784.887024,726.573364 
	C784.308350,725.912781 782.820129,726.031982 782.273926,725.364502 
	C781.668701,724.624756 781.672241,723.387085 781.410645,722.366211 
	C782.413330,722.170166 783.658264,721.495483 784.377136,721.859619 
	C787.749512,723.567444 790.479187,725.894226 790.125793,730.648071 
	C789.225159,733.498108 788.282043,735.944763 786.845825,738.733826 
	C785.899414,739.715942 785.446106,740.355713 784.992798,740.995483 
	C784.992737,740.995422 784.961182,741.449097 784.570801,741.722473 
	C779.875305,742.641418 775.584045,743.496582 771.255188,743.782288 
	C770.199951,743.851929 769.021301,742.052307 768.201050,740.934692 
	C768.812134,740.411438 769.121643,740.065735 769.846069,739.802979 
	C774.780457,739.690552 779.572693,741.381958 784.093384,738.482239 
	C781.395325,736.693359 779.266663,735.282043 777.422974,733.843994 
	C778.406616,733.549255 779.105286,733.281372 779.804016,733.013428 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M520.256775,654.727356 
	C516.612366,654.437988 513.157532,653.892090 509.257324,653.275757 
	C509.257324,663.305115 509.257324,672.332642 509.257324,682.342041 
	C512.741882,682.039001 516.053406,681.750977 519.726440,681.238586 
	C520.416748,685.600281 517.765320,687.096252 513.737854,687.024536 
	C509.493896,686.948975 507.919678,684.112915 507.859222,680.478699 
	C507.699707,670.893127 507.796173,661.303345 507.787292,651.228821 
	C512.305725,650.799194 516.827576,650.855957 521.820923,651.042725 
	C521.677124,652.272095 521.061768,653.371460 520.256775,654.727356 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M300.179321,670.658203 
	C296.380859,671.714478 292.772186,672.391724 288.762695,673.104980 
	C288.271423,670.023804 288.181030,666.906555 288.199127,663.333496 
	C296.081726,664.004028 303.847687,665.192932 311.635834,666.211914 
	C313.547211,666.461975 315.531219,666.157227 317.882629,666.175598 
	C313.873322,667.107605 309.453918,667.927734 305.058014,668.858643 
	C303.466156,669.195679 301.930450,669.797913 300.179321,670.658203 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M232.832703,461.183899 
	C232.439713,461.168274 232.046707,461.152679 231.101532,460.820007 
	C227.590271,459.863007 223.712646,460.165466 225.037598,455.130463 
	C229.869476,455.246429 234.760757,455.481567 237.173492,449.213928 
	C240.170319,448.699219 242.502838,448.382538 244.885071,448.298218 
	C244.934799,448.530548 245.044846,448.992920 245.037750,449.317749 
	C245.944489,450.767792 246.858322,451.893005 247.772156,453.018219 
	C247.772156,453.018219 247.966476,453.472565 247.980347,453.718811 
	C247.734726,454.159119 247.475266,454.353180 246.749237,454.812012 
	C241.799347,457.112518 237.316025,459.148193 232.832703,461.183899 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M289.864532,716.038818 
	C289.885345,716.778809 289.906128,717.518738 289.923950,718.963745 
	C289.965759,720.117493 290.010559,720.566223 290.055359,721.015015 
	C290.044128,721.457825 290.032928,721.900696 289.986938,723.109924 
	C289.943451,726.240784 289.934692,728.605225 289.673767,731.221558 
	C289.911896,734.186401 290.199554,736.962769 291.017609,739.572998 
	C291.258759,740.342529 292.999664,740.642029 294.377869,741.125305 
	C295.122223,740.982056 295.541962,740.870544 296.277222,740.797607 
	C297.197815,741.018799 297.802887,741.201416 298.792328,741.500000 
	C297.470184,742.551758 296.636078,743.215271 295.802002,743.878784 
	C295.801971,743.878784 295.387299,743.919617 294.766998,743.976562 
	C292.463898,743.985718 290.781128,743.937866 289.098328,743.890015 
	C289.098328,743.890015 289.074341,743.912231 288.960358,743.617981 
	C287.649506,742.183838 286.452637,741.044006 285.255798,739.904175 
	C285.128052,739.481140 285.000336,739.058044 284.860016,738.066650 
	C284.789795,737.339294 284.732178,737.180359 284.674561,737.021423 
	C284.704620,734.302734 284.734650,731.584106 284.770844,728.182617 
	C284.777008,727.499756 284.625519,727.028137 284.625519,727.028137 
	C284.680145,725.322266 284.734772,723.616394 284.818359,721.159058 
	C284.252502,719.888123 283.657654,719.368591 283.062805,718.849060 
	C283.062836,718.849060 282.731628,718.757996 282.492615,718.500366 
	C282.208618,717.854004 282.163666,717.465149 282.118713,717.076355 
	C282.118713,717.076355 282.011688,717.084717 282.426086,717.113525 
	C284.600677,716.125549 286.360870,715.108765 288.121033,714.091919 
	C288.121033,714.091919 288.516235,714.094055 288.747528,714.366089 
	C289.274048,715.104980 289.569275,715.571899 289.864532,716.038818 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M672.234131,659.516052 
	C669.235962,659.617371 666.263062,659.459900 663.404602,658.803589 
	C662.466797,658.588257 661.540405,657.188965 661.131775,656.129456 
	C660.741577,655.117859 661.024841,653.843750 661.024292,652.685059 
	C661.020264,644.440369 661.820251,643.680908 670.569458,644.004395 
	C671.002747,643.985901 671.001892,643.999146 670.966370,644.383301 
	C671.334045,646.153137 671.737244,647.538879 672.140442,648.924561 
	C672.194946,652.304077 672.249451,655.683533 672.234131,659.516052 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M474.203308,674.762817 
	C470.217926,674.162476 466.232574,673.562134 461.885742,672.899475 
	C461.414093,670.498108 461.303894,668.159119 461.481445,665.126831 
	C464.276062,659.840271 469.192841,661.415833 473.044678,660.510620 
	C473.484344,665.561829 473.843842,670.162292 474.203308,674.762817 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M214.809967,518.068726 
	C215.573257,518.956116 215.996521,520.100098 216.561325,521.626709 
	C220.027817,521.178955 223.917404,520.136108 227.748962,520.317688 
	C232.592163,520.547302 237.378555,521.842224 242.211578,522.519836 
	C243.335754,522.677490 244.549240,522.198181 245.802917,521.669739 
	C246.918823,522.238586 247.953461,523.149597 250.172348,525.103394 
	C245.222412,526.492371 241.487793,527.540344 237.577789,528.271606 
	C237.677719,527.456848 237.953033,526.958740 238.228333,526.460632 
	C237.201859,526.342407 236.175369,526.224182 234.919220,525.801636 
	C232.362350,524.677307 230.008652,523.105347 227.713226,523.186157 
	C222.116104,523.383484 216.545944,524.346558 210.963776,524.979126 
	C210.962616,524.957031 210.958466,524.912659 210.958466,524.912659 
	C212.128967,522.716919 213.299454,520.521179 214.809967,518.068726 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M188.035797,734.983887 
	C188.035797,734.983887 187.569778,734.909485 187.309250,734.538086 
	C186.990097,733.735535 186.931473,733.304443 186.878448,732.502808 
	C185.157364,727.355469 183.430649,722.578796 181.360184,716.851135 
	C179.660019,719.074280 178.879395,720.095032 177.716156,721.067383 
	C177.079910,720.976685 176.826309,720.934326 176.572693,720.891907 
	C176.712646,719.862732 176.852600,718.833618 177.272858,717.248779 
	C177.989182,715.784302 178.425171,714.875549 178.861176,713.966736 
	C179.106293,713.148254 179.351410,712.329773 180.213593,711.235474 
	C182.206757,710.996704 183.582901,711.033752 184.959030,711.070801 
	C187.387238,717.817322 189.815430,724.563904 192.283813,731.910278 
	C192.324005,732.510071 192.426163,732.965637 192.426163,732.965637 
	C192.426163,732.965637 192.280212,733.411194 191.968597,733.914551 
	C190.449921,734.606628 189.242859,734.795227 188.035797,734.983887 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M687.723877,718.067993 
	C687.597717,719.210999 687.028015,720.325500 686.253723,722.075195 
	C685.633240,724.061951 685.217407,725.413391 684.457275,726.839722 
	C682.924744,726.726562 681.736450,726.538574 681.815735,726.551147 
	C680.352539,729.828247 679.159180,732.501099 677.554932,735.119263 
	C670.522339,734.801147 673.178101,729.675354 672.997437,726.498230 
	C672.632446,720.078613 672.899902,720.104797 679.253418,720.817200 
	C681.177979,721.032959 683.817993,721.608215 681.992615,718.011353 
	C683.755188,718.020752 685.517761,718.030151 687.723877,718.067993 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M423.526062,737.054443 
	C422.102936,735.799805 420.994598,734.408875 419.864227,732.672485 
	C421.177216,730.383118 422.665192,728.524170 423.805206,726.471924 
	C424.994781,724.330566 425.849182,722.002991 426.951904,719.528564 
	C425.360321,718.455688 423.837799,717.429382 421.642212,715.949341 
	C426.378387,714.283936 430.037994,712.018005 433.748199,716.821106 
	C434.436462,717.712097 437.850464,716.497681 440.523254,716.196777 
	C440.549377,716.408203 440.674164,717.418945 440.387695,718.763367 
	C437.290802,718.164673 436.833679,720.223511 436.135437,721.958618 
	C434.819946,721.007080 433.504456,720.055603 431.538635,718.633728 
	C428.898712,724.904358 426.369781,730.911316 423.526062,737.054443 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M275.552002,632.828003 
	C265.870636,630.475891 256.102173,627.762756 246.289459,625.220337 
	C244.591675,624.780457 242.706543,625.063721 240.476181,624.983887 
	C247.289902,624.808289 254.551468,624.304321 261.775696,624.629517 
	C267.438538,624.884583 271.960175,628.034668 275.552002,632.828003 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M751.029053,420.066040 
	C752.647278,421.462311 753.882202,422.855316 755.117188,424.248322 
	C754.786621,424.676941 754.456055,425.105591 754.125549,425.534241 
	C756.882263,426.447723 759.638977,427.361206 763.636230,428.685760 
	C761.874512,431.679993 760.979431,435.336426 758.762268,436.523407 
	C754.203247,438.964172 753.790222,433.308044 751.208191,431.559998 
	C751.935120,430.276306 752.543457,429.202026 753.004761,428.387421 
	C750.500000,427.177368 748.233032,426.082214 744.646484,424.349548 
	C747.461548,422.338074 749.053650,421.200439 751.029053,420.066040 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M583.992004,724.628174 
	C581.897339,724.620667 579.815186,724.205261 577.733032,723.789856 
	C577.617371,724.252258 577.501709,724.714661 577.386047,725.177063 
	C578.481140,725.630920 580.036255,725.788635 580.563782,726.607849 
	C581.359314,727.843384 582.233765,730.242615 581.656494,730.897949 
	C580.405334,732.318237 578.326111,733.213440 576.424927,733.717285 
	C575.932068,733.847900 574.151184,731.737366 574.154968,730.660522 
	C574.169922,726.386414 574.657349,722.113892 574.978455,717.419189 
	C575.151855,716.975342 575.321411,716.971252 575.480469,716.928284 
	C583.959961,714.633667 585.148376,715.649780 583.992004,724.628174 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M750.937866,525.116821 
	C750.937866,525.116821 750.962891,525.546509 751.010986,526.083984 
	C751.679565,527.119629 752.300049,527.617737 752.920532,528.115906 
	C752.920532,528.115906 752.949036,528.550232 753.019043,529.138306 
	C754.354980,530.534058 755.581726,531.413025 756.894958,532.134705 
	C759.885132,533.778015 762.917725,535.344116 765.673218,537.167847 
	C765.201172,538.287598 764.988953,539.181152 764.494568,539.883301 
	C760.843262,538.117126 757.474121,536.542480 753.814575,534.765747 
	C751.128418,529.516785 747.138428,531.151855 743.306213,532.125916 
	C741.661377,528.293152 740.016541,524.460449 738.531494,520.052734 
	C738.789917,518.633301 738.888489,517.788757 738.987061,516.944275 
	C739.431885,516.932495 739.876648,516.920715 740.650269,517.369446 
	C742.094482,523.820984 743.412720,524.785583 750.937866,525.116821 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M798.130005,731.296875 
	C795.669128,725.973511 793.376953,720.934631 791.072083,715.546936 
	C792.106995,715.153870 793.154541,715.109680 794.930237,715.076782 
	C796.107422,715.057678 796.556396,715.027161 797.005432,714.996643 
	C798.213562,717.899841 799.408081,720.808899 800.637085,723.703247 
	C801.073730,724.731812 801.594421,725.724670 802.420166,727.318542 
	C803.140625,728.990906 803.517761,730.078613 803.958374,731.488892 
	C804.720398,732.218201 805.418945,732.625000 806.343384,733.029541 
	C806.569275,733.027344 807.020508,733.004089 807.019226,733.335815 
	C807.003723,734.109375 806.989441,734.551208 806.681702,735.143555 
	C804.971191,736.508606 803.554077,737.723145 801.913208,738.919617 
	C801.689392,738.901550 801.240662,738.919983 801.240662,738.919983 
	C801.208374,738.436890 801.176025,737.953796 801.089844,736.808472 
	C800.508362,735.416687 799.980652,734.687134 799.452942,733.957642 
	C799.068176,733.165588 798.683411,732.373535 798.130005,731.296875 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M198.255219,728.056885 
	C199.090607,728.919495 199.620148,729.896545 200.514069,731.546021 
	C202.068359,729.524353 203.268951,727.962769 205.314407,725.302307 
	C207.194855,726.684448 210.220825,727.931030 211.623947,730.186890 
	C213.380814,733.011658 210.496918,734.815247 207.899109,736.702271 
	C207.489975,738.004883 207.489975,738.933472 207.489975,740.372559 
	C206.337021,740.740051 204.638260,741.281494 202.939499,741.822937 
	C202.249435,740.356995 201.428345,738.936829 200.896881,737.415466 
	C199.830673,734.363403 198.922668,731.255981 198.255219,728.056885 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M248.606613,658.103394 
	C244.874878,657.640442 241.143127,657.177490 237.070435,656.855286 
	C236.658737,654.209534 236.588013,651.423035 236.712738,648.310181 
	C240.605499,647.013611 244.302826,646.043518 248.479034,645.446167 
	C248.840836,649.913757 248.723724,654.008545 248.606613,658.103394 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M437.985168,672.863953 
	C434.194366,672.059387 430.745148,671.163269 426.941528,670.262573 
	C428.165985,667.894653 429.744843,665.531250 431.619690,663.027466 
	C432.276062,663.359802 432.745056,664.292664 432.980713,664.237061 
	C437.352692,663.205261 441.696899,662.055664 446.438416,660.932129 
	C443.995514,664.879639 441.161163,668.825989 437.985168,672.863953 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M769.431152,739.719971 
	C769.121643,740.065735 768.812134,740.411438 767.876465,740.864197 
	C766.196289,739.904053 765.142395,738.836914 763.959351,737.582642 
	C763.610046,737.168762 763.343750,737.030823 762.803894,736.971191 
	C762.576355,736.960632 762.121155,736.942078 762.121216,736.942078 
	C762.086121,734.602722 762.051086,732.263306 762.377441,729.328979 
	C763.998413,725.305298 762.119385,720.959167 758.615234,720.023682 
	C757.169800,720.379944 756.068359,720.704590 754.972168,721.020996 
	C754.977539,721.012756 754.970032,721.035706 754.976501,720.684265 
	C754.973511,719.863831 754.964111,719.394897 755.227295,718.625122 
	C759.320374,717.564331 763.140991,716.804321 766.961548,716.044373 
	C766.961548,716.044373 767.000000,716.003235 767.022583,716.403320 
	C769.703796,716.875977 772.362488,716.948669 775.076416,717.078491 
	C775.131592,717.135681 775.242493,717.249573 775.155029,717.587280 
	C775.041077,718.625183 775.014465,719.325439 774.716370,720.043457 
	C774.265686,720.089600 774.086487,720.117859 773.474731,720.117310 
	C771.270630,720.088623 769.499207,720.088623 767.566711,720.088623 
	C765.119324,727.052124 766.001160,733.497681 769.431152,739.719971 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M244.671295,685.519592 
	C244.941879,684.854126 245.575256,683.939087 246.377533,683.753357 
	C255.503784,681.639587 264.655884,679.637268 274.026611,677.936584 
	C268.650208,684.363647 261.346191,685.809875 253.533508,685.807617 
	C250.726501,685.806763 247.919540,685.678711 244.671295,685.519592 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M449.766907,720.822021 
	C450.924988,715.739563 455.402679,715.736206 459.114746,715.177368 
	C462.641235,714.646423 466.310455,715.063049 470.502441,715.063049 
	C471.811127,719.250977 473.385162,724.108521 469.126221,730.425415 
	C466.895386,722.703247 465.504852,714.463196 455.311157,720.488892 
	C454.149292,719.769104 453.257385,718.910522 452.583130,719.056580 
	C451.564697,719.277161 450.698059,720.198486 449.766907,720.822021 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M768.598877,525.913086 
	C766.589661,527.645508 765.025818,527.095642 763.990906,524.431152 
	C763.221008,522.448914 762.192566,520.567078 761.164551,518.398560 
	C757.864990,519.598022 754.808228,520.709229 751.214355,521.641418 
	C749.902588,520.917419 747.306885,520.995239 749.933289,519.287109 
	C753.018982,517.141968 756.142273,515.048218 759.175720,512.831665 
	C760.891785,511.577728 762.460327,510.122040 764.095337,508.757294 
	C764.542114,508.997253 764.988892,509.237244 765.435730,509.477234 
	C765.655457,513.479553 765.704468,517.502502 766.182495,521.473694 
	C766.362427,522.967773 767.537292,524.342041 768.598877,525.913086 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M239.056274,488.936279 
	C238.661987,488.951385 238.267715,488.966522 237.579163,488.965515 
	C232.292389,489.809418 227.294678,490.640472 222.309631,491.541626 
	C219.802216,491.994873 217.317810,492.575470 214.822983,493.098450 
	C214.454987,492.707184 214.086990,492.315918 213.718979,491.924683 
	C214.956070,489.084900 216.193161,486.245148 217.947800,483.551361 
	C218.992920,483.793152 219.520508,483.888977 220.002167,484.339294 
	C219.687180,485.885803 219.418091,487.077789 219.382309,487.236328 
	C225.639664,486.512177 231.810226,485.798096 238.127029,485.388824 
	C238.534256,486.774536 238.795273,487.855377 239.056274,488.936279 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M226.370880,563.903748 
	C226.289444,563.751160 226.208023,563.598572 226.520081,563.158813 
	C230.802689,561.953369 234.691803,561.035156 239.367264,559.931274 
	C238.631287,556.591370 237.874130,553.155212 237.026794,549.309998 
	C230.465942,557.097351 229.554428,557.461853 224.855194,554.527527 
	C232.161530,556.200867 233.581619,549.791565 237.532227,544.310730 
	C238.363159,552.144958 240.645264,557.907288 247.394592,560.428345 
	C245.032898,561.632019 242.978043,562.395691 240.541412,563.134521 
	C239.140717,563.135620 238.121780,563.161621 236.711349,563.133789 
	C233.003540,563.354553 229.687210,563.629150 226.370880,563.903748 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M766.983398,715.671387 
	C763.140991,716.804321 759.320374,717.564331 754.878418,718.645569 
	C751.828247,718.328064 749.399414,717.689331 746.416870,716.905029 
	C746.251404,717.032166 745.560730,717.721924 744.734985,718.152832 
	C743.899353,718.588745 742.930054,718.768555 742.019287,719.060608 
	C745.054382,712.812561 746.562500,712.485046 752.504883,714.836548 
	C755.517395,716.028564 759.069824,715.856201 762.331665,716.294373 
	C762.331665,712.661621 761.290710,709.003906 762.641785,707.511047 
	C763.849609,706.176514 767.696350,707.230347 771.100159,707.230347 
	C769.544983,710.294556 768.275146,712.796448 766.983398,715.671387 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M681.741089,718.015991 
	C683.817993,721.608215 681.177979,721.032959 679.253418,720.817200 
	C672.899902,720.104797 672.632446,720.078613 672.997437,726.498230 
	C673.178101,729.675354 670.522339,734.801147 677.564819,735.496216 
	C677.988464,736.946228 677.991394,737.964539 677.825867,739.144775 
	C676.825623,738.853149 675.993958,738.399597 674.917480,737.702881 
	C673.799622,737.184753 672.926392,736.909790 671.992065,736.340332 
	C671.027527,735.350586 670.124146,734.655212 669.030518,733.718140 
	C668.588013,732.924072 668.335754,732.371704 668.117432,731.447693 
	C668.061646,728.702515 667.971924,726.329102 667.838745,723.679932 
	C667.825867,723.034363 667.856445,722.664490 667.823486,722.002502 
	C667.263550,721.442810 666.767029,721.175232 665.959290,720.894531 
	C665.443848,720.843262 665.239746,720.805054 665.035583,720.766785 
	C664.411743,720.292725 663.787903,719.818665 663.010376,719.086548 
	C662.856750,718.828491 662.986816,718.449158 663.279785,718.262512 
	C663.572754,718.075867 663.967407,718.023621 663.967407,718.023621 
	C664.708374,718.006653 665.449402,717.989624 666.917358,717.988037 
	C669.379700,718.033142 671.114990,718.062866 673.166748,718.328979 
	C675.984985,718.369263 678.486633,718.173157 680.988342,717.977051 
	C681.155396,717.991516 681.322510,718.006042 681.741089,718.015991 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M689.002197,732.912048 
	C692.590210,734.070862 693.469299,730.805481 695.646301,729.033203 
	C695.450439,730.662842 695.146667,732.474854 694.279907,733.957703 
	C692.356384,737.248108 693.326538,739.648071 697.164001,740.085083 
	C700.365967,740.449646 703.241150,740.232727 705.194763,736.784302 
	C705.937683,735.473022 708.316162,735.088318 710.188721,734.826782 
	C707.652161,743.129333 696.022827,747.764099 689.114624,741.329590 
	C689.061035,740.936096 689.160828,740.832581 689.486938,740.874146 
	C690.603699,740.876709 691.394470,740.837708 692.825134,740.767212 
	C691.423035,738.796204 690.345825,737.281921 689.221680,735.412720 
	C689.117249,734.342651 689.059753,733.627319 689.002197,732.912048 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M741.820984,719.093994 
	C742.930054,718.768555 743.899353,718.588745 744.734985,718.152832 
	C745.560730,717.721924 746.251404,717.032166 746.416870,716.905029 
	C749.399414,717.689331 751.828247,718.328064 754.605896,718.946350 
	C754.964111,719.394897 754.973511,719.863831 754.595398,720.665283 
	C751.216675,720.659912 750.186279,722.391479 749.985229,724.998474 
	C749.943176,725.430908 749.901062,725.863281 749.596924,726.886353 
	C749.246948,728.660828 749.159119,729.844604 749.071289,731.028381 
	C749.071289,731.028381 748.658691,731.089783 748.101562,730.953979 
	C746.696228,731.306946 745.342163,731.619629 745.098877,732.318848 
	C744.474548,734.113098 744.343445,736.079041 743.777466,737.961304 
	C743.539856,737.946289 743.068298,737.881226 743.082275,737.428223 
	C743.072327,733.314514 743.048340,729.653809 743.083984,725.669067 
	C742.502625,723.275879 741.861633,721.206726 741.220581,719.137634 
	C741.220581,719.137634 741.622681,719.127441 741.820984,719.093994 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M279.032104,737.598145 
	C276.641998,739.327637 274.370453,740.815002 272.098907,742.302307 
	C277.177094,728.628967 277.011993,724.674194 270.272766,718.121216 
	C270.013550,717.262451 270.012695,716.995728 270.411499,716.748291 
	C271.875031,716.838257 272.938873,716.908997 274.002747,716.979736 
	C274.002747,716.979736 274.391357,717.098816 274.766724,717.371094 
	C275.748169,718.461243 276.354218,719.279175 276.999695,720.396118 
	C277.619171,721.144714 278.199219,721.594360 278.832397,722.382996 
	C277.946075,724.843811 278.307831,726.167480 280.952057,726.091187 
	C280.949921,726.511414 280.947754,726.931580 280.735962,727.819580 
	C280.671082,728.865723 280.815796,729.444214 280.960510,730.022644 
	C280.949921,730.457764 280.939331,730.892822 280.689514,731.905823 
	C280.315186,733.657410 280.180054,734.831055 280.044891,736.004761 
	C279.746857,736.455200 279.448792,736.905640 279.032104,737.598145 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M560.936829,741.085876 
	C559.645203,738.445923 557.853577,735.926575 557.180481,733.137390 
	C556.196655,729.061218 556.015686,724.791260 555.299561,719.921082 
	C557.883911,715.637268 559.871887,719.695923 562.147339,720.436890 
	C562.058777,724.650024 561.687683,728.455566 562.214539,732.132446 
	C562.595581,734.791321 564.168884,737.279358 564.819824,739.880737 
	C563.265137,740.307129 562.100952,740.696533 560.936829,741.085876 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M271.225189,609.434082 
	C273.401123,609.771606 275.422699,610.393982 277.352783,610.988220 
	C277.052185,613.150757 276.818115,614.834778 276.465881,617.368774 
	C278.815613,616.188599 280.349060,615.418457 283.032562,614.070618 
	C283.464813,616.452454 283.822815,618.425354 284.180847,620.398193 
	C282.860565,620.542542 280.528931,621.125671 280.372498,620.765076 
	C278.196899,615.750732 274.492584,617.951538 270.919250,618.704163 
	C269.421173,615.626831 267.212128,612.688965 271.225189,609.434082 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M264.750488,465.000580 
	C264.879211,466.202576 264.776459,467.305939 264.598907,469.212616 
	C259.917572,463.910797 255.379089,465.961395 251.001480,468.463226 
	C248.382843,469.959778 246.189468,470.085541 244.024109,467.421753 
	C246.283585,465.949890 248.619125,464.813904 251.443726,463.440002 
	C249.787262,461.704803 248.760330,460.632965 247.751358,459.544464 
	C247.731415,459.522980 247.899948,459.277252 248.019653,459.189514 
	C248.144669,459.097931 248.317062,459.071014 248.759933,458.982544 
	C250.555969,459.142853 252.060822,459.336395 253.975220,459.666718 
	C254.384781,459.803497 254.339127,459.848022 254.339127,459.848022 
	C257.732422,461.532654 261.125702,463.217316 264.750488,465.000580 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M496.559235,648.574585 
	C488.238800,646.998901 479.839996,645.061646 471.313904,642.814575 
	C481.898132,639.081604 490.472412,641.016602 496.559235,648.574585 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M746.266357,565.160767 
	C746.278564,564.516052 746.542969,563.984619 746.856445,562.812134 
	C745.475525,561.231262 744.160461,559.944580 742.591370,559.425171 
	C739.480835,558.395386 736.230164,557.789001 733.016724,556.652222 
	C732.680298,555.176392 732.364624,554.055847 732.048950,552.935364 
	C739.606567,554.811218 747.164124,556.687073 754.721741,558.562927 
	C754.872864,559.270752 755.023987,559.978577 755.175110,560.686401 
	C752.289551,562.215637 749.404053,563.744873 746.266357,565.160767 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M317.075256,589.909424 
	C320.118164,595.599731 323.161041,601.290039 326.167145,607.412720 
	C326.130371,607.845032 326.009033,607.987793 325.719788,608.110046 
	C324.332214,607.979675 323.233856,607.726990 323.587219,607.808289 
	C321.953064,608.479492 320.693909,608.996643 319.480072,609.495239 
	C320.950470,610.557678 322.142273,611.418884 324.008087,612.767029 
	C321.167847,612.916382 319.627075,612.997437 317.811310,612.847046 
	C317.735199,610.752747 318.216339,608.862976 318.042084,607.035645 
	C317.907013,605.619690 316.952576,604.281860 316.308472,602.614197 
	C316.153442,601.937561 316.052307,601.556030 315.951141,601.174500 
	C315.947937,600.372925 315.944702,599.571289 316.253906,598.192993 
	C316.416199,596.751465 316.266113,595.886719 316.116028,595.021973 
	C316.071594,594.564270 316.027191,594.106506 316.304749,593.085754 
	C316.776245,591.651611 316.925751,590.780518 317.075256,589.909424 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M441.082581,736.865051 
	C442.354645,737.436707 443.626709,738.008362 444.898743,738.580017 
	C445.620300,736.896240 446.596588,735.270752 446.999023,733.513855 
	C447.504517,731.307129 447.540070,728.992798 448.221313,726.371582 
	C450.240326,725.006531 451.816437,723.994873 453.392578,722.983154 
	C453.517273,727.084778 453.641998,731.186340 453.478790,735.837036 
	C452.158936,737.243835 451.126953,738.101562 450.095001,738.959290 
	C447.995758,739.944092 445.896515,740.928894 443.232422,741.737671 
	C439.410645,740.916931 440.592438,738.774292 441.082581,736.865051 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M387.329010,606.106628 
	C388.062988,606.492554 388.796997,606.878479 389.787720,607.943970 
	C390.706757,609.390869 391.369080,610.158203 392.031403,610.925598 
	C392.031403,610.925598 392.051605,610.954834 392.043579,611.390869 
	C391.757538,614.973755 391.479523,618.120605 391.201508,621.267456 
	C391.210846,621.767151 391.220154,622.266785 391.373993,623.117065 
	C391.371094,623.594360 391.223663,623.721069 390.498291,623.646973 
	C389.398682,623.383423 388.876984,623.320618 387.988373,623.188965 
	C384.387756,623.786987 381.154053,624.453796 377.920380,625.120605 
	C377.035828,624.876160 376.151276,624.631653 375.359863,623.895630 
	C380.655823,620.964661 385.637756,618.304260 387.178772,611.710388 
	C387.450439,610.585754 387.460907,609.691895 387.470947,608.350098 
	C387.423340,607.303589 387.376190,606.705078 387.329010,606.106628 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M550.345276,618.727173 
	C548.479431,617.457703 546.979614,616.276306 544.961426,614.686523 
	C547.251282,614.166504 549.182251,613.727905 551.215637,613.266113 
	C551.775208,614.445923 552.271362,615.492065 552.767517,616.538147 
	C553.171204,616.493591 553.574890,616.448975 553.978577,616.404358 
	C553.978577,614.225159 553.978577,612.045898 553.989258,609.433350 
	C554.000000,609.000000 553.998108,608.996948 554.367188,609.067383 
	C555.449402,610.964111 556.162659,612.790344 557.100647,615.192139 
	C559.006409,612.481201 560.613831,610.194702 562.259216,607.854309 
	C564.784607,609.747131 566.957520,611.375793 569.097473,613.265015 
	C569.058655,613.700500 569.052795,613.875549 568.983643,614.263306 
	C568.920288,614.476135 569.019409,614.908936 569.019409,614.908936 
	C568.597168,615.024231 568.174927,615.139465 567.309998,615.054932 
	C566.252075,615.256897 565.636841,615.658691 565.021667,616.060547 
	C564.868469,616.134277 564.715210,616.208008 564.012573,616.164612 
	C561.656128,616.677124 559.848877,617.306763 558.041626,617.936401 
	C555.598145,618.229309 553.154724,618.522217 550.345276,618.727173 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M224.581451,554.420654 
	C229.554428,557.461853 230.465942,557.097351 237.026794,549.309998 
	C237.874130,553.155212 238.631287,556.591370 239.367264,559.931274 
	C234.691803,561.035156 230.802689,561.953369 226.530334,562.899536 
	C225.742401,562.207947 225.337692,561.488464 225.164062,560.234985 
	C224.648117,557.955872 223.901108,556.210876 223.154099,554.465820 
	C223.538635,554.415161 223.923187,554.364441 224.581451,554.420654 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M441.090820,736.549683 
	C440.592438,738.774292 439.410645,740.916931 442.878357,741.831848 
	C443.089081,742.102112 443.043915,742.556396 442.603760,742.777954 
	C441.696442,743.050415 441.229279,743.101379 440.762085,743.152344 
	C436.572601,742.020203 436.458344,738.311279 436.153595,735.130371 
	C435.766296,731.088074 436.066132,726.979858 436.102570,722.429321 
	C436.833679,720.223511 437.290802,718.164673 440.297699,719.129639 
	C440.735840,722.479614 440.852661,725.797119 440.700745,729.762695 
	C440.274353,731.969299 437.069641,733.847168 440.911591,734.986389 
	C440.974091,735.402344 441.036560,735.818298 441.090820,736.549683 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M754.105042,534.967773 
	C757.474121,536.542480 760.843262,538.117126 764.524658,540.275269 
	C764.798157,541.907776 764.759399,542.956726 764.527344,544.205200 
	C764.052185,545.550476 763.770386,546.696167 763.488525,547.841919 
	C763.483215,548.312866 763.477905,548.783875 763.060059,549.699463 
	C761.450195,550.425110 760.252808,550.706238 759.055420,550.987305 
	C759.055420,550.987305 758.996643,550.996094 758.987061,550.682129 
	C758.478149,549.868713 757.978882,549.369263 757.479553,548.869812 
	C757.479553,548.869812 757.242432,548.742676 757.167175,548.293884 
	C756.384094,546.264954 755.676270,544.684875 755.328186,543.096375 
	C756.121887,542.191284 757.135376,541.045410 756.877686,540.446045 
	C756.190125,538.846863 754.955994,537.482605 753.980591,535.760620 
	C754.049622,535.320129 754.077332,535.143982 754.105042,534.967773 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M695.531555,626.231201 
	C695.283264,628.968262 695.009888,631.355347 694.741089,633.702454 
	C687.110779,633.984802 685.252686,632.829651 684.562744,627.397156 
	C687.030212,621.848999 690.967773,621.413879 695.531555,626.231201 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M267.151184,663.118896 
	C270.768799,663.769470 274.386414,664.420105 278.408112,665.085449 
	C278.858337,666.443909 278.904510,667.787781 278.774414,669.501709 
	C273.484222,671.036011 268.370300,672.200073 263.215088,672.761597 
	C264.499634,669.145569 265.825409,666.132202 267.151184,663.118896 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M738.341553,744.973755 
	C737.976746,737.834778 737.321106,737.179504 730.540527,736.950256 
	C728.873291,736.893860 727.221191,736.389587 725.459595,736.072754 
	C728.713989,734.329102 731.544189,732.812683 734.698669,731.512695 
	C736.188538,732.623352 737.354065,733.517578 737.983582,734.000549 
	C739.137573,736.688538 740.129089,738.998169 741.215576,741.614258 
	C744.447205,742.609924 747.565857,743.422852 750.735779,743.882507 
	C751.359680,743.972961 752.181274,742.700317 752.911682,742.056335 
	C753.534485,742.633728 754.157288,743.211121 754.780151,743.788513 
	C753.728027,744.492981 752.669495,745.806335 751.624878,745.795410 
	C747.337708,745.750122 743.054749,745.301758 738.341553,744.973755 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M425.993103,623.821533 
	C425.821991,623.903687 425.650909,623.985840 425.235992,624.212524 
	C424.255798,624.314819 423.519470,624.272583 421.981720,624.135132 
	C418.776825,624.057068 416.373352,624.074219 413.969849,624.091370 
	C413.969849,624.091370 413.870361,623.763855 413.898621,623.597290 
	C413.926849,623.430664 413.938416,623.358887 413.941223,622.886230 
	C414.013977,620.287659 414.083893,618.161682 414.153839,616.035767 
	C414.153839,616.035767 414.555023,616.017761 415.100067,615.961609 
	C419.213593,614.142761 420.852661,608.985840 426.218384,610.358887 
	C426.584473,610.922607 426.740112,611.141968 426.692993,611.377625 
	C426.490234,611.393860 426.190918,611.138184 425.923279,611.202026 
	C425.474731,611.570618 425.293823,611.875244 425.094849,612.096558 
	C425.076752,612.013306 425.151245,612.166626 424.746765,612.117676 
	C423.619019,612.918274 422.895782,613.767883 422.246704,614.911072 
	C422.225128,615.800415 422.129425,616.396179 421.789856,617.197998 
	C422.230255,617.887512 422.914551,618.370850 423.812469,618.794067 
	C424.362244,618.974365 424.698364,619.214783 425.045837,619.834229 
	C425.369141,621.416016 425.681122,622.618774 425.993103,623.821533 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M329.045288,262.539490 
	C329.146790,264.957611 328.892548,267.197906 328.507019,270.594818 
	C326.907440,268.664856 325.985962,267.553040 324.717407,266.022491 
	C324.162170,267.927673 323.686157,269.560974 323.200623,271.558594 
	C320.976440,272.409637 318.761749,272.896423 316.290588,273.226837 
	C315.983948,272.595337 315.933777,272.120178 316.119873,271.230316 
	C316.414734,270.713196 316.547302,270.555267 316.519257,270.517914 
	C316.387939,270.342987 316.212372,270.201263 316.052612,270.047668 
	C316.034332,268.956146 316.016052,267.864655 316.409821,266.341888 
	C317.114227,265.452698 317.406586,264.994751 317.698944,264.536804 
	C318.604248,263.945190 319.509521,263.353607 320.722656,263.146088 
	C321.133331,264.072845 321.236145,264.615479 321.338959,265.158142 
	C321.528687,265.145355 321.718445,265.132599 321.908203,265.119812 
	C321.908203,264.374054 321.908203,263.628296 321.908203,262.882538 
	C324.168640,262.708923 326.429108,262.535309 329.045288,262.539490 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M541.047852,734.955872 
	C540.690552,733.253723 540.333252,731.551514 539.948486,729.478638 
	C542.769775,730.725098 544.569946,730.119934 545.100647,726.704346 
	C545.656616,723.125732 546.250244,719.552979 546.827393,715.977661 
	C546.362061,715.798523 545.896790,715.619385 545.431519,715.440186 
	C546.353455,715.246826 547.275391,715.053467 548.917358,714.892273 
	C550.107056,714.905884 550.576843,714.887268 551.046570,714.868652 
	C551.224426,714.884766 551.402222,714.900879 551.817505,715.310913 
	C551.759644,717.157471 551.464233,718.609985 551.168823,720.062500 
	C549.258667,724.916931 547.348572,729.771423 544.764526,734.822937 
	C543.076355,734.998657 542.062073,734.977234 541.047852,734.955872 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M235.148895,526.105896 
	C236.175369,526.224182 237.201859,526.342407 238.228333,526.460632 
	C237.953033,526.958740 237.677719,527.456848 237.226105,528.137085 
	C235.599319,532.101868 234.113693,535.871582 232.714462,539.673157 
	C232.093552,541.360107 231.647186,543.111328 230.676651,546.291077 
	C228.927612,543.520508 228.022156,542.086182 227.069397,540.030518 
	C226.805786,538.935486 226.589523,538.461792 226.373260,537.988098 
	C226.373260,537.988098 226.180969,537.894775 226.111053,537.482178 
	C225.433090,536.378296 224.825027,535.687012 224.216965,534.995667 
	C224.216965,534.995667 223.918625,534.735718 223.898239,534.122070 
	C223.926529,532.645996 223.975220,531.783630 224.332123,531.092773 
	C229.397644,535.490356 230.679489,535.128479 232.375839,528.944763 
	C233.457108,527.863647 234.302994,526.984741 235.148895,526.105896 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M326.992737,719.002686 
	C325.888885,718.353760 324.785034,717.704895 323.342224,716.688721 
	C323.000092,715.886658 322.997009,715.451843 322.993896,715.017090 
	C322.993896,712.920898 322.993896,710.824646 322.993896,708.586609 
	C330.541870,706.874329 333.054199,708.988464 331.069183,716.609314 
	C330.431366,719.058105 334.062592,722.311340 329.347595,723.561279 
	C328.324188,721.803894 327.658447,720.403259 326.992737,719.002686 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M362.123474,738.257324 
	C361.980347,738.009033 362.066925,737.544250 362.478516,737.277954 
	C364.204315,737.036987 365.518463,737.062256 366.832642,737.087524 
	C371.104889,742.783325 374.902222,742.416626 384.276855,735.230835 
	C385.633942,738.686829 383.594910,740.414673 381.236481,741.957703 
	C376.114838,745.308716 369.801239,744.940063 364.299194,740.709351 
	C363.694397,740.177307 363.483643,740.025696 363.233948,739.928223 
	C362.911499,739.454041 362.589081,738.979858 362.123474,738.257324 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M316.362366,602.909302 
	C316.952576,604.281860 317.907013,605.619690 318.042084,607.035645 
	C318.216339,608.862976 317.735199,610.752747 317.789917,613.236572 
	C319.343719,616.229187 320.643890,618.600769 322.024658,621.355713 
	C325.256958,622.071411 328.408722,622.403687 332.029724,622.785522 
	C331.993164,622.684692 332.271942,623.453430 332.550690,624.222229 
	C332.495087,624.528748 332.341339,624.777832 331.940308,624.977417 
	C329.358307,624.754211 326.929962,624.431885 324.491608,624.309753 
	C318.590179,624.014160 315.855682,621.331055 315.720001,615.377502 
	C315.633667,611.589355 315.717499,607.797363 315.927673,603.525696 
	C316.130310,603.044312 316.362366,602.909302 316.362366,602.909302 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M377.946594,625.496704 
	C381.154053,624.453796 384.387756,623.786987 387.953003,623.448364 
	C388.729065,624.211426 389.173584,624.646179 389.618103,625.080933 
	C389.694489,627.535278 389.770874,629.989624 389.314392,632.675171 
	C386.903076,632.644043 385.013855,632.440857 383.149811,632.099854 
	C381.763672,631.846375 380.410217,631.414368 379.042206,631.061890 
	C378.685760,629.332214 378.329285,627.602478 377.946594,625.496704 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M816.084229,712.053345 
	C817.548889,711.484192 819.013489,710.915039 820.478088,710.345825 
	C820.811951,712.070496 821.145813,713.795166 820.916992,715.616089 
	C818.376404,713.870239 817.276489,714.334351 816.644409,716.978271 
	C815.183289,717.977661 814.087280,718.984558 812.991211,719.991516 
	C812.991211,719.991516 813.000000,720.000000 812.791138,720.099243 
	C812.313965,720.404114 812.201904,720.674561 812.246033,721.009766 
	C812.151062,721.749817 812.056152,722.489807 811.662598,723.819458 
	C810.535706,726.620117 809.707458,728.831299 808.531372,731.024536 
	C807.487305,731.019897 806.790894,731.033020 806.110107,730.697021 
	C806.414490,729.230042 806.703186,728.112305 806.991943,726.994568 
	C806.991943,726.994568 807.048706,726.545166 807.343506,726.155762 
	C808.029236,724.825012 808.420288,723.883667 808.811279,722.942322 
	C808.872314,722.209106 808.933228,721.475952 809.264160,720.184570 
	C808.684509,718.767517 807.835022,717.908569 806.985474,717.049683 
	C806.700989,716.603638 806.416443,716.157593 806.562378,715.388489 
	C809.095703,715.036865 811.275818,715.330322 813.271057,714.852295 
	C814.369263,714.589172 815.156677,713.028748 816.084229,712.053345 
M818.509155,712.402466 
	C818.509155,712.402466 818.591675,712.533508 818.509155,712.402466 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M250.818054,582.253418 
	C252.107620,582.944336 253.444199,583.561707 254.673630,584.346863 
	C255.916214,585.140259 257.933289,586.031372 258.008972,587.004272 
	C258.100067,588.175476 256.501343,589.478088 255.086884,590.859192 
	C254.370483,590.995544 254.201385,590.996521 253.811752,590.737549 
	C248.892654,590.903687 246.427963,593.567810 246.350189,598.304565 
	C245.998245,598.992249 245.646301,599.679932 245.294357,600.367554 
	C244.822998,600.341064 244.351639,600.314575 243.880280,600.288025 
	C243.880280,597.520325 243.880280,594.752625 244.190948,591.476074 
	C246.889191,589.632202 249.276779,588.297302 251.853241,586.856689 
	C251.532959,585.432495 251.175507,583.842957 250.818054,582.253418 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M820.793701,726.979675 
	C821.514526,728.727783 823.305237,731.009827 822.750488,732.121460 
	C821.236145,735.156250 821.977112,737.600830 822.701782,740.510071 
	C822.953613,741.521057 820.759216,743.566895 819.268616,744.403015 
	C818.545776,744.808472 816.256653,743.840454 815.761353,742.914246 
	C814.929016,741.357544 814.403870,737.856934 814.717224,737.738770 
	C818.384644,736.354797 816.065796,733.467346 817.117310,731.401733 
	C818.036194,732.184265 818.439209,732.951294 819.513489,734.996155 
	C820.086914,731.405396 820.440308,729.192566 820.793701,726.979675 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M463.884888,648.276367 
	C468.064117,649.076538 471.984161,650.128479 475.628662,651.664185 
	C474.593597,653.093994 473.833984,654.040039 472.658325,654.944336 
	C468.640594,655.534607 465.038940,656.166748 461.437256,656.798828 
	C462.166748,654.041931 462.896240,651.284973 463.884888,648.276367 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M186.872833,732.873352 
	C186.931473,733.304443 186.990097,733.735535 187.078339,734.489502 
	C184.452881,734.892151 181.798279,735.013000 179.142624,735.043823 
	C172.105438,735.125427 170.748352,737.103271 173.540558,743.281311 
	C168.999100,745.789734 168.202194,745.702759 165.990692,742.100342 
	C166.726852,741.589966 166.892227,741.317932 166.944855,740.977356 
	C166.944855,740.977356 166.990082,740.970581 167.335205,740.903564 
	C168.128952,739.562500 168.577560,738.288391 169.026184,737.014343 
	C169.348907,736.237854 169.671631,735.461365 170.212219,734.243896 
	C170.690460,733.590576 170.822968,733.316406 170.827591,732.980469 
	C170.854874,732.812805 170.882172,732.645081 171.229950,732.095703 
	C172.369553,730.811951 173.188705,729.909790 174.003815,729.003906 
	C173.999786,729.000183 173.993103,728.995544 174.001923,729.400146 
	C175.346954,730.851501 176.575912,732.567932 178.042130,732.802734 
	C180.915802,733.262878 183.921692,732.897217 186.872833,732.873352 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M590.007263,735.002014 
	C589.920654,734.583679 589.834106,734.165283 589.880371,733.056702 
	C589.910767,731.866455 589.808228,731.366516 589.705688,730.866516 
	C589.735596,729.229919 589.765503,727.593323 589.859741,725.235596 
	C589.890503,724.303223 589.856934,724.091919 589.823303,723.880615 
	C589.688049,721.199585 589.552734,718.518616 589.899353,715.447388 
	C591.266541,715.030945 592.151794,715.004761 593.280762,715.209167 
	C594.050110,717.655457 594.575684,719.871155 595.101318,722.086853 
	C595.059692,722.516541 595.018127,722.946289 594.653320,723.973999 
	C594.283020,726.414673 594.235840,728.257324 594.188721,730.099915 
	C594.187500,730.499878 594.186401,730.899902 594.120789,731.907593 
	C594.108398,732.716187 594.160522,732.916992 594.212646,733.117798 
	C594.139587,733.549927 594.066589,733.981995 593.608582,734.831055 
	C592.151489,735.165955 591.079407,735.083984 590.007263,735.002014 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M336.860504,266.825500 
	C336.882660,266.122253 336.904816,265.418976 337.327637,264.368134 
	C339.799957,264.672943 341.871674,265.325348 344.222107,266.065552 
	C344.152191,264.678558 344.098816,263.619263 344.045410,262.559937 
	C346.043823,262.475739 348.042206,262.391510 350.467285,262.660095 
	C350.986572,263.311523 351.057037,263.615295 351.201904,264.256714 
	C351.491211,265.688019 351.684021,266.786865 351.559387,267.918091 
	C350.483917,268.620178 349.725891,269.289856 348.697083,269.761108 
	C347.357697,268.648682 346.289124,267.734650 344.186493,265.936127 
	C344.595856,268.986633 344.799774,270.506287 344.908752,271.318481 
	C343.645996,272.046448 342.834686,272.514130 342.023346,272.981842 
	C342.023346,272.005981 342.023346,271.030151 342.023346,268.782928 
	C341.226227,270.584137 340.921234,271.273315 340.728607,271.708618 
	C339.768951,270.858002 338.753326,269.957764 337.368347,268.692535 
	C336.952820,267.826843 336.906647,267.326172 336.860504,266.825500 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M316.054932,417.088776 
	C315.986267,414.327667 315.917603,411.566528 316.201294,408.100586 
	C316.365021,404.966156 316.176483,402.536560 315.987915,400.106934 
	C315.974792,399.319366 315.961700,398.531769 316.407837,397.387878 
	C317.585815,397.038940 318.304596,397.046387 319.382141,397.097504 
	C319.587982,398.142853 319.486969,399.155884 319.271515,400.143951 
	C318.777313,402.409973 318.227325,404.663849 317.699951,406.922638 
	C318.104858,407.048187 318.509766,407.173706 318.914673,407.299255 
	C319.460114,406.513855 320.005554,405.728424 320.988586,404.312836 
	C320.988586,409.302216 321.153351,413.508728 320.852112,417.681580 
	C320.790527,418.535004 318.981201,419.262207 317.727631,419.863831 
	C317.003479,418.816284 316.529205,417.952545 316.054932,417.088776 
M318.462677,416.616638 
	C318.462677,416.616638 318.559082,416.442841 318.559082,416.442841 
	C318.559082,416.442841 318.365997,416.469452 318.462677,416.616638 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M316.187927,509.017303 
	C316.125153,506.630249 316.062378,504.243225 316.318542,501.265564 
	C317.068604,500.138397 317.499756,499.601807 317.960938,499.429565 
	C318.991486,501.195831 319.895782,502.680298 321.012726,503.982391 
	C323.298767,506.647491 325.699097,509.214600 328.719330,512.561035 
	C325.813965,512.347290 324.081360,512.044922 322.371460,512.142212 
	C320.590942,512.243530 318.832336,512.730530 317.048340,513.028442 
	C317.032562,513.007874 317.005676,512.963623 317.011292,512.610535 
	C316.740601,511.177399 316.464294,510.097351 316.187927,509.017303 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M753.939148,536.024963 
	C754.955994,537.482605 756.190125,538.846863 756.877686,540.446045 
	C757.135376,541.045410 756.121887,542.191284 755.037598,542.994141 
	C753.980713,542.852051 753.574097,542.803711 753.167480,542.755371 
	C752.671326,542.541870 752.175171,542.328247 751.161255,541.744873 
	C749.102356,541.279175 747.561157,541.183350 745.935791,540.718689 
	C744.493286,539.869934 743.134949,539.390015 741.821045,538.636230 
	C742.240173,537.241150 742.614807,536.119873 743.376221,534.960205 
	C747.155029,535.289490 750.547119,535.657227 753.939148,536.024963 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M532.514893,664.510254 
	C533.781433,662.248840 534.892639,660.422913 535.840393,658.865601 
	C536.999512,665.946716 533.409668,674.659241 539.498413,681.852356 
	C537.087891,682.504700 535.079651,682.725891 533.071411,682.947021 
	C532.834106,676.946655 532.596802,670.946228 532.514893,664.510254 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M261.984711,718.917419 
	C255.959824,721.654724 256.901093,727.030823 256.733704,732.710876 
	C256.987335,734.218079 257.503662,735.100830 258.019989,735.983582 
	C258.036835,736.150879 258.053680,736.318115 257.696838,736.730225 
	C255.863327,735.998718 254.403519,735.022400 252.720779,733.851196 
	C252.232773,733.127441 251.967682,732.598572 251.702606,732.069763 
	C251.719482,731.607178 251.736374,731.144531 251.838684,730.093567 
	C251.907181,729.332886 251.890244,729.160645 251.873322,728.988342 
	C251.940277,728.252136 252.007248,727.515991 252.320862,726.235962 
	C252.699463,725.122742 252.831451,724.553223 252.963440,723.983765 
	C252.963455,723.983765 253.093796,723.628296 253.422409,723.336609 
	C254.090408,722.347778 254.429794,721.650574 254.769165,720.953369 
	C255.111252,720.506165 255.453354,720.058960 256.149689,719.244385 
	C256.665466,718.582886 256.826996,718.288696 256.988525,717.994507 
	C256.988525,717.994507 257.000000,718.000000 257.231201,717.906738 
	C257.777130,717.647156 257.977783,717.391235 258.064331,717.045898 
	C258.064331,717.045898 257.994904,717.050781 258.383179,717.053223 
	C259.483643,717.000793 260.195892,716.945923 261.158478,717.066895 
	C261.600800,717.800964 261.792755,718.359192 261.984711,718.917419 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M589.746704,735.234253 
	C591.079407,735.083984 592.151489,735.165955 593.462463,735.126648 
	C594.992615,737.597351 596.283936,740.189392 598.176270,743.987671 
	C593.738586,744.231934 589.894104,744.443604 585.615540,744.679138 
	C587.163574,740.994446 588.324829,738.230469 589.746704,735.234253 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M765.329590,450.861694 
	C767.979248,452.849548 770.628906,454.837402 773.330811,457.223328 
	C770.770386,457.852509 768.157715,458.083649 765.042603,458.359253 
	C765.418945,460.018188 765.732056,461.398407 765.664795,462.834106 
	C764.536438,463.681305 763.788452,464.473022 763.040405,465.264771 
	C761.002808,464.463318 758.965271,463.661896 756.927673,462.860474 
	C756.740662,462.418549 756.553650,461.976624 756.366577,461.534698 
	C762.518921,460.633057 764.119751,456.314240 765.289062,451.246155 
	C765.387878,450.895111 765.329590,450.861725 765.329590,450.861694 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M357.332642,731.639343 
	C356.568665,735.161255 355.804657,738.683228 354.934814,742.552124 
	C354.427277,742.961121 354.025635,743.023132 352.897064,743.145020 
	C350.127472,743.264648 348.084778,743.324280 346.042114,743.384033 
	C345.590454,743.284180 345.138794,743.184387 344.176453,742.808716 
	C343.125824,742.336243 342.585876,742.139648 342.045929,741.943115 
	C342.045929,741.943115 341.998779,741.985657 341.910828,741.684204 
	C341.225739,740.582336 340.628601,739.781860 340.031494,738.981384 
	C339.737335,737.162231 339.443146,735.343018 339.148987,733.523865 
	C339.580658,733.319153 340.012360,733.114441 340.444031,732.909729 
	C341.304810,734.035034 342.165558,735.160339 343.130676,736.977051 
	C344.160736,741.092773 346.980988,741.534790 349.567627,740.797485 
	C351.252350,740.317139 352.501251,738.307922 353.945435,736.983643 
	C353.932953,736.274841 353.920532,735.565979 354.254883,734.443481 
	C355.511963,733.232971 356.422302,732.436157 357.332642,731.639343 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M781.855957,486.551453 
	C780.909241,487.050232 779.187195,487.057526 781.157593,488.804901 
	C779.172424,489.518036 777.104492,490.991425 775.219177,490.786407 
	C771.264954,490.356445 767.406677,489.043518 762.161072,487.764435 
	C766.921143,485.238190 769.971985,482.217651 773.960449,486.189575 
	C774.365295,486.592743 775.791687,485.970093 776.792236,485.509094 
	C778.429382,485.565796 780.018188,485.936920 781.855957,486.551453 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M256.978271,600.029724 
	C257.677765,599.114014 258.250031,598.041687 259.119537,597.335266 
	C259.745300,596.826904 260.767517,596.806580 261.920715,597.082764 
	C263.139099,597.799011 264.049683,598.001099 264.960236,598.203247 
	C265.400696,598.131775 265.841187,598.060303 266.577789,598.384033 
	C264.357666,601.438538 264.515533,606.357361 259.796448,607.155518 
	C258.412811,606.360718 257.029144,605.565918 255.652740,604.775269 
	C256.273132,603.224365 256.785248,601.944153 257.141479,600.327026 
	C256.985596,599.990051 256.978271,600.029724 256.978271,600.029724 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M800.814270,738.942627 
	C801.240662,738.919983 801.689392,738.901550 801.886963,739.346069 
	C801.115540,742.180908 800.608459,745.023804 798.938965,746.727905 
	C798.315430,747.364380 794.980469,745.344604 792.773804,744.494446 
	C792.831665,745.501526 792.931458,747.237488 793.031189,748.973450 
	C790.042480,746.358521 790.129944,743.023804 793.661438,740.313965 
	C794.176270,740.773193 794.293762,741.013428 794.411194,741.253601 
	C794.606018,740.827576 794.800842,740.401489 794.995667,739.975464 
	C796.793091,739.638733 798.590454,739.302002 800.814270,738.942627 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M220.048096,483.984802 
	C219.520508,483.888977 218.992920,483.793152 218.289505,483.495911 
	C218.539413,482.547119 218.965164,481.799774 219.702301,481.026550 
	C227.384430,477.976654 234.719315,474.858795 242.158676,472.014160 
	C243.520569,471.493408 245.307480,472.084259 246.593384,472.557373 
	C243.621582,473.999664 240.928574,474.985413 238.292862,476.105865 
	C232.196518,478.697479 226.127945,481.354370 220.048096,483.984802 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M475.146545,726.098267 
	C476.167755,717.980713 480.467316,714.990417 491.512970,715.271179 
	C491.995728,715.491028 492.072388,715.920532 491.643188,715.950989 
	C488.814789,716.261108 486.415619,716.540771 484.016449,716.820435 
	C484.053589,717.172180 484.090729,717.523987 484.127869,717.875793 
	C484.772552,717.931702 485.417236,717.987610 485.848572,718.311401 
	C482.206451,720.338257 480.752930,723.200867 480.696808,727.180542 
	C480.219604,727.972168 479.986847,728.563965 479.495636,728.966187 
	C477.873627,727.883789 476.510101,726.991028 475.146545,726.098267 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M793.019470,748.978027 
	C792.931458,747.237488 792.831665,745.501526 792.773804,744.494446 
	C794.980469,745.344604 798.315430,747.364380 798.938965,746.727905 
	C800.608459,745.023804 801.115540,742.180908 802.110718,739.364136 
	C803.554077,737.723145 804.971191,736.508606 806.688965,735.494873 
	C806.707031,736.811401 806.424377,737.927063 806.141724,739.042786 
	C805.732971,740.761536 805.324280,742.480225 804.636047,744.777832 
	C802.905029,747.228210 801.453491,749.099670 800.001953,750.971130 
	C798.660278,750.960999 797.318542,750.950867 795.482788,750.611084 
	C794.333374,749.844360 793.677979,749.407104 793.022583,748.969910 
	C793.022583,748.969910 793.007751,748.982605 793.019470,748.978027 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M689.029053,732.568909 
	C689.059753,733.627319 689.117249,734.342651 689.220337,735.692627 
	C688.865173,736.873840 688.464478,737.420349 687.798096,737.846497 
	C686.844177,737.399536 686.155945,737.072876 685.467651,736.746216 
	C685.060303,736.341370 684.652893,735.936523 684.145142,734.853577 
	C684.297058,731.705261 684.549255,729.235046 684.801514,726.764832 
	C685.217407,725.413391 685.633240,724.061951 686.207397,722.386963 
	C687.121338,722.210571 687.876953,722.357666 688.792908,723.136108 
	C689.311035,725.486511 689.668884,727.205566 690.026794,728.924561 
	C689.703186,730.024963 689.379517,731.125366 689.029053,732.568909 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M480.539856,741.181641 
	C479.358704,740.051453 478.177551,738.921265 477.352844,737.419556 
	C479.905457,737.203369 481.388885,736.814575 480.481964,734.014038 
	C480.083588,732.783813 480.096802,731.420288 480.266144,729.950562 
	C483.106201,728.521362 485.033325,728.391541 485.066223,732.026489 
	C483.088745,736.686340 484.560211,739.548706 489.496338,741.104431 
	C486.489380,741.473999 483.514618,741.327820 480.539856,741.181641 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M316.997986,296.063721 
	C316.890656,302.940948 317.151184,309.725586 322.614563,315.712891 
	C320.712341,316.045135 319.471619,316.261841 318.230865,316.478546 
	C317.744354,317.077881 317.257843,317.677185 316.771332,318.276520 
	C318.038208,318.809998 319.305115,319.343506 320.571991,319.876984 
	C320.039032,319.410797 319.506073,318.944641 318.973145,318.478485 
	C318.143341,320.142700 317.313568,321.806915 316.221130,323.694916 
	C315.880615,314.280487 315.802704,304.642334 316.104065,294.715027 
	C316.654846,294.971832 316.826416,295.517761 316.997986,296.063721 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M465.158997,681.279358 
	C465.660797,680.487061 466.425842,679.471313 466.952301,679.582703 
	C470.492981,680.332275 474.046234,681.144470 477.443573,682.360535 
	C478.261139,682.653137 478.507233,684.542358 478.915039,686.003052 
	C473.478394,687.114075 469.089325,685.218323 465.158997,681.279358 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M725.960938,545.032837 
	C718.900818,546.834412 718.593750,546.384277 720.971558,537.340332 
	C721.098083,536.548096 721.229431,536.141785 721.832642,535.428833 
	C723.781250,535.755920 725.257874,536.389587 726.734558,537.023193 
	C726.849426,537.442566 726.964233,537.861938 727.023865,539.036682 
	C727.017334,541.499756 727.066040,543.207458 726.826416,544.945251 
	C726.345642,544.994507 726.153259,545.013672 725.960938,545.032837 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M326.718109,719.223389 
	C327.658447,720.403259 328.324188,721.803894 328.988647,723.599609 
	C327.077362,724.460327 325.150635,724.867004 323.261047,725.404297 
	C319.858429,726.371765 317.938385,728.544312 317.855774,732.852234 
	C317.405914,733.690063 317.093201,733.890442 316.780457,734.090759 
	C317.106995,730.618896 317.098785,727.060791 317.957794,723.726135 
	C318.226135,722.684387 320.845184,722.266052 322.369873,721.524719 
	C323.740814,720.858093 325.086609,720.139709 326.718109,719.223389 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M759.172546,448.764648 
	C757.550232,448.505493 755.927856,448.246368 754.591919,448.032959 
	C755.624878,444.735168 756.445801,442.114227 757.281311,439.446686 
	C759.567322,439.613281 762.293518,439.544678 764.857422,440.156219 
	C765.661255,440.347961 766.028503,442.370178 766.120728,443.892273 
	C763.081055,444.962555 760.516296,445.695129 757.994446,446.415436 
	C758.453857,447.331573 758.813232,448.048096 759.172546,448.764648 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M461.301331,657.193970 
	C465.038940,656.166748 468.640594,655.534607 472.722046,655.366211 
	C473.122742,657.239990 473.043610,658.649963 472.964478,660.059998 
	C469.192841,661.415833 464.276062,659.840271 461.519409,664.686279 
	C461.234894,662.489014 461.200165,660.039001 461.301331,657.193970 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M296.233734,743.922424 
	C296.636078,743.215271 297.470184,742.551758 298.792328,741.500000 
	C297.802887,741.201416 297.197815,741.018799 296.285675,740.384705 
	C296.654846,734.954651 297.331085,729.976074 298.007324,724.997559 
	C297.681030,722.554871 297.354736,720.112122 297.062988,717.245117 
	C298.040558,717.937866 299.788940,719.051941 299.792969,720.172302 
	C299.815369,726.431763 299.415924,732.692627 299.190826,738.213196 
	C299.869690,740.219971 300.389160,741.755554 300.927734,743.649963 
	C299.519714,743.994629 298.092590,743.980347 296.233734,743.922424 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M764.088501,737.769714 
	C765.142395,738.836914 766.196289,739.904053 767.574829,741.041626 
	C769.021301,742.052307 770.199951,743.851929 771.255188,743.782288 
	C775.584045,743.496582 779.875305,742.641418 784.557068,741.949463 
	C784.769043,742.529785 784.809814,743.378784 784.408691,743.749756 
	C779.064636,748.691589 764.371643,745.798401 761.264587,739.190979 
	C761.045959,738.726074 761.448669,737.968933 761.841797,737.144897 
	C762.121155,736.942078 762.576355,736.960632 762.918701,737.176758 
	C763.471802,737.661316 763.747620,737.786926 764.088501,737.769714 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M224.023911,530.921204 
	C223.975220,531.783630 223.926529,532.645996 223.822876,533.939087 
	C220.751266,530.023315 215.848404,532.184692 211.914276,530.993896 
	C210.400833,530.535828 208.691422,530.725220 206.333969,530.571533 
	C207.909286,528.431702 209.114990,526.793945 210.639587,525.034424 
	C210.958466,524.912659 210.962616,524.957031 211.087997,525.264282 
	C211.992950,526.811279 210.920746,529.248596 214.427567,528.569397 
	C217.436249,527.986633 220.830627,529.395325 224.040039,530.172607 
	C224.025620,530.421875 224.023911,530.921204 224.023911,530.921204 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M820.846069,726.611694 
	C820.440308,729.192566 820.086914,731.405396 819.513489,734.996155 
	C818.439209,732.951294 818.036194,732.184265 817.398193,731.203369 
	C817.156677,730.557678 817.150146,730.125793 817.356812,729.204102 
	C817.405762,728.133545 817.241455,727.552795 817.077148,726.972046 
	C817.041931,724.620117 817.006714,722.268250 816.979126,719.135986 
	C816.994263,717.899048 817.001831,717.442444 817.009399,716.985901 
	C817.276489,714.334351 818.376404,713.870239 820.618774,715.837036 
	C820.936523,716.714600 820.989990,717.467468 821.030273,719.014526 
	C821.046875,721.214478 821.076721,722.620178 821.106628,724.025940 
	C821.037292,724.765198 820.967896,725.504517 820.846069,726.611694 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M259.893555,607.489746 
	C264.515533,606.357361 264.357666,601.438538 266.911743,598.435059 
	C267.645355,600.251221 268.107758,602.524475 269.086670,604.547852 
	C270.993195,608.488647 268.099365,608.355408 265.736908,609.115723 
	C264.430878,609.536072 263.373657,610.729553 261.740692,611.909546 
	C261.080872,610.369202 260.535736,609.096619 259.893555,607.489746 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M237.102844,563.187622 
	C238.121780,563.161621 239.140717,563.135620 240.559387,563.516357 
	C240.980179,564.957520 241.001266,565.992065 240.877304,567.348511 
	C239.748154,572.474121 235.631348,570.011292 232.635483,571.010986 
	C231.147369,572.048157 230.027344,573.076660 228.907318,574.105225 
	C228.532013,574.146240 228.156723,574.187317 227.443481,573.841309 
	C227.290939,570.835144 227.781937,568.981506 231.098480,568.162720 
	C233.370834,567.601685 235.119873,564.920837 237.102844,563.187622 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M672.047729,669.578003 
	C668.575500,670.527344 665.216675,671.095398 661.421021,671.737366 
	C661.421021,669.255737 661.421021,666.923340 661.421021,664.202759 
	C664.797913,664.832703 667.988708,665.427917 671.593872,666.034546 
	C672.059204,667.096191 672.110168,668.146484 672.047729,669.578003 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M759.189819,448.801941 
	C758.813232,448.048096 758.453857,447.331573 757.994446,446.415436 
	C760.516296,445.695129 763.081055,444.962555 765.974243,444.296509 
	C767.251465,445.436035 768.200195,446.509094 769.187439,447.625580 
	C767.750183,448.514069 766.487610,449.294556 765.277344,450.468384 
	C765.329590,450.861725 765.387878,450.895111 765.392700,450.862244 
	C764.446777,451.270966 763.530334,451.912872 762.533020,452.084198 
	C761.859253,452.199951 761.071594,451.652832 760.220337,450.850067 
	C759.820435,449.835144 759.534973,449.372406 759.249512,448.909668 
	C759.249573,448.909637 759.207153,448.839233 759.189819,448.801941 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M244.835342,448.065857 
	C242.502838,448.382538 240.170319,448.699219 237.441513,448.990967 
	C239.149673,445.051758 238.699173,439.380280 244.883057,438.080292 
	C245.342834,438.504303 245.474854,438.894165 245.341751,439.628540 
	C245.206604,440.350494 245.336578,440.727936 245.466568,441.105377 
	C245.494156,441.868378 245.521744,442.631348 245.296692,444.051300 
	C244.974503,445.827454 244.904922,446.946625 244.835342,448.065857 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M749.702271,518.989380 
	C747.306885,520.995239 749.902588,520.917419 750.851196,521.671204 
	C751.011414,522.688782 750.997620,523.497559 750.960815,524.711548 
	C743.412720,524.785583 742.094482,523.820984 740.977112,517.444153 
	C743.807068,517.602722 746.639160,518.147217 749.702271,518.989380 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M304.950317,718.353516 
	C306.168427,717.873291 307.355743,717.713440 308.778503,717.281311 
	C310.806152,716.690063 312.598358,716.371033 314.792114,716.095276 
	C313.225677,717.527405 311.257721,718.916321 309.340149,720.269714 
	C312.954315,723.610596 311.882812,728.109131 311.094543,732.496948 
	C310.360748,731.132385 309.974640,729.697083 310.049622,728.286194 
	C310.217590,725.125244 308.726166,723.814453 305.721619,723.880432 
	C305.454254,722.144897 305.186890,720.409363 304.950317,718.353516 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M357.177795,731.332153 
	C356.422302,732.436157 355.511963,733.232971 354.196472,734.091675 
	C353.791321,734.153442 353.360016,734.104370 353.089813,733.828003 
	C352.636719,733.240112 352.365295,733.099365 352.005371,733.129333 
	C351.648163,733.021912 351.290955,732.914429 350.426758,732.527466 
	C347.637604,731.132507 345.355469,730.017029 343.073364,728.901550 
	C343.073364,728.901550 343.071686,728.923035 343.090271,728.909790 
	C345.685577,724.854187 349.235382,727.375671 352.246979,727.847839 
	C353.962860,728.116943 355.438904,729.915344 357.177795,731.332153 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M210.954468,723.735474 
	C209.447327,724.056763 207.894852,724.221252 206.504532,723.821655 
	C205.324860,723.482544 204.332413,722.491943 203.257111,721.789673 
	C204.276215,720.547363 205.057297,718.761475 206.368927,718.187195 
	C208.459061,717.271851 210.902374,717.163086 213.083160,716.406433 
	C213.841614,716.192871 214.713257,716.286194 215.584915,716.379517 
	C215.209579,717.246277 214.964035,718.204407 214.431580,718.960510 
	C213.363800,720.476746 212.139267,721.882629 210.954468,723.735474 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M202.620331,741.858887 
	C204.638260,741.281494 206.337021,740.740051 207.489975,740.372559 
	C207.489975,738.933472 207.489975,738.004883 207.797302,737.036987 
	C209.460678,737.003601 210.816727,737.009521 212.462540,737.320190 
	C210.830902,739.744812 208.909500,741.864685 206.988098,743.984558 
	C203.367264,743.987549 199.746414,743.990540 196.294083,743.539062 
	C196.975861,742.388672 197.489120,741.692810 198.267120,740.961060 
	C198.707352,740.894714 198.882828,740.864197 199.058304,740.833679 
	C199.058304,740.833679 199.526443,740.936584 199.870819,741.174805 
	C200.419083,741.675903 200.687363,741.806885 201.020050,741.806030 
	C201.447098,741.835571 201.874130,741.865173 202.620331,741.858887 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M750.398193,724.998413 
	C750.186279,722.391479 751.216675,720.659912 754.588928,721.016724 
	C754.970032,721.035706 754.977539,721.012756 755.049316,721.341064 
	C757.620239,723.901489 758.437073,722.284058 758.959167,719.992065 
	C762.119385,720.959167 763.998413,725.305298 762.434631,728.893677 
	C759.833191,723.582031 754.645081,726.180481 750.398193,724.998413 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M236.711365,563.133789 
	C235.119873,564.920837 233.370834,567.601685 231.098480,568.162720 
	C227.781937,568.981506 227.290939,570.835144 227.160034,573.839172 
	C223.665176,575.620483 224.304840,573.101379 224.625824,571.277771 
	C225.015457,569.064148 225.730515,566.907776 226.337830,564.315186 
	C229.687210,563.629150 233.003540,563.354553 236.711365,563.133789 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M318.925140,533.979004 
	C318.159607,538.526794 317.394073,543.074585 316.369629,547.847595 
	C316.030609,538.004700 315.950500,527.936646 316.229736,517.442444 
	C316.589111,517.016357 317.082489,517.063110 317.056183,517.513367 
	C317.343018,521.626587 317.656189,525.289551 318.235229,529.168640 
	C318.674652,530.269958 318.848175,531.155212 319.016388,532.283936 
	C319.011078,532.527466 319.027863,533.014404 319.027863,533.014404 
	C319.027863,533.014404 319.012085,533.012329 318.843384,533.086975 
	C318.606476,533.260986 318.458313,533.410828 318.483734,533.451111 
	C318.605042,533.643372 318.773438,533.805908 318.925140,533.979004 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M239.856354,729.860962 
	C240.255142,731.576294 240.570343,732.934631 241.185501,735.585571 
	C238.927307,734.534607 237.666931,733.947998 236.086823,733.168640 
	C235.767059,732.975830 235.700287,732.723206 235.727722,732.267456 
	C235.189743,731.201599 234.624359,730.591492 234.058960,729.981384 
	C234.058945,729.981384 233.997375,729.989197 233.968597,729.815002 
	C233.866684,729.451416 233.758560,729.284668 233.615463,729.140686 
	C233.457840,728.644348 233.300232,728.147949 233.097519,726.899170 
	C234.283524,725.448730 235.514618,724.750793 236.745697,724.052856 
	C237.086151,724.587341 237.426590,725.121765 237.929398,726.080505 
	C238.189987,726.663940 238.313080,726.800720 238.461029,726.915100 
	C238.898285,727.778076 239.335526,728.641052 239.856354,729.860962 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M533.066650,683.322876 
	C535.079651,682.725891 537.087891,682.504700 539.473938,682.252869 
	C540.345459,682.215088 540.839172,682.208008 542.047424,682.205872 
	C543.216309,682.133667 543.670715,682.056519 544.125061,681.979370 
	C542.704834,683.632141 541.578491,686.102844 539.794739,686.743713 
	C537.201843,687.675232 534.184998,687.182068 533.066650,683.322876 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M317.930908,499.065247 
	C317.499756,499.601807 317.068604,500.138397 316.422943,500.829224 
	C316.088898,492.949860 315.969391,484.916260 315.965973,476.421265 
	C316.539948,476.136139 317.360565,476.252380 317.401154,476.497681 
	C317.994232,480.080963 318.483093,483.681519 318.838928,487.982605 
	C318.431000,492.146606 318.180939,495.605896 317.930908,499.065247 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M250.810547,582.213257 
	C251.175507,583.842957 251.532959,585.432495 251.853241,586.856689 
	C249.276779,588.297302 246.889191,589.632202 244.146713,591.096191 
	C243.791824,591.225281 243.438309,591.062500 243.254089,590.591187 
	C242.808685,589.047668 242.547531,587.975342 242.541229,587.949524 
	C244.967285,585.347046 246.895325,583.278748 249.038986,580.979187 
	C249.192444,581.080811 249.970856,581.596375 250.749283,582.111877 
	C250.749283,582.111877 250.803070,582.173157 250.810547,582.213257 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M378.840057,638.753784 
	C378.875488,647.271606 378.910889,655.789429 378.643738,664.706055 
	C378.172394,665.114624 378.003632,665.124390 377.834900,665.134094 
	C377.804230,656.651733 377.773560,648.169312 378.048218,639.296631 
	C378.515717,638.855530 378.677887,638.804626 378.840057,638.753784 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M236.788605,723.646118 
	C235.514618,724.750793 234.283524,725.448730 233.054810,726.509521 
	C232.421600,725.476624 231.786011,724.080994 231.485886,722.362244 
	C232.763031,721.492981 234.573853,720.619751 234.510620,720.451782 
	C233.938736,718.932129 233.038986,717.535828 232.235260,716.103394 
	C231.487381,717.408325 230.739502,718.713318 229.991623,720.018250 
	C228.725479,718.824829 227.459335,717.631348 225.691086,715.964661 
	C227.602921,715.388794 228.768661,714.999939 229.954544,714.687439 
	C233.653748,713.712769 236.081985,714.395996 235.929092,718.974243 
	C235.882004,720.384338 236.511703,721.817078 236.788605,723.646118 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M675.162231,737.946045 
	C675.993958,738.399597 676.825623,738.853149 678.013550,739.133911 
	C678.369751,738.961121 678.744629,738.933289 678.744629,738.933289 
	C678.441040,740.231628 678.495361,742.365906 677.768982,742.676941 
	C675.239502,743.760010 672.439636,744.211487 669.340332,744.840454 
	C667.784424,743.738892 666.630676,742.700012 665.649414,741.377319 
	C669.198853,740.826599 673.205872,742.429016 675.162231,737.946045 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M377.721161,665.538696 
	C378.003632,665.124390 378.172394,665.114624 378.579926,665.187988 
	C382.164581,665.520264 385.510498,665.769470 389.408752,666.356628 
	C389.893188,667.817139 389.825256,668.939636 389.757324,670.062134 
	C386.105591,669.987549 382.453857,669.912964 378.337860,669.888672 
	C377.873169,669.376831 377.872772,668.814575 377.959961,667.869019 
	C378.047577,667.485718 378.054291,667.555725 378.054291,667.555725 
	C377.905334,667.018250 377.756409,666.480774 377.721161,665.538696 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M316.801758,734.377808 
	C317.093201,733.890442 317.405914,733.690063 317.855896,733.245239 
	C319.764099,733.000854 321.535004,733.000854 324.628784,733.000854 
	C323.420471,736.531494 322.499176,739.223450 321.185883,742.003113 
	C320.793884,742.090820 320.395111,742.072144 320.171295,741.703613 
	C318.792480,740.202271 317.637451,739.069336 316.482452,737.936462 
	C316.469696,737.769226 316.456970,737.601990 316.584534,737.030151 
	C316.881470,736.376953 316.943451,736.106567 316.910736,735.814453 
	C316.881500,735.431274 316.852264,735.048096 316.801758,734.377808 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M233.003571,571.002380 
	C235.631348,570.011292 239.748154,572.474121 241.156342,567.578857 
	C241.828232,568.430115 242.076035,569.372986 242.323853,570.315796 
	C242.320648,570.648865 242.317444,570.981873 242.158524,571.967102 
	C241.697845,574.439514 242.926392,577.243896 239.253693,576.902710 
	C238.985336,576.616394 238.716980,576.330078 238.135773,575.670044 
	C237.627975,575.099915 237.393173,574.984253 237.118530,574.949341 
	C236.233887,574.388123 235.349258,573.826904 234.149460,572.791321 
	C233.557388,571.878784 233.280472,571.440613 233.003571,571.002380 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M640.918762,719.191895 
	C642.827576,720.216858 644.489014,721.393311 646.422241,722.722229 
	C646.709290,723.347107 646.724609,723.819458 646.312134,724.756470 
	C644.465820,727.028198 645.451965,731.293152 641.000000,730.000000 
	C640.939758,729.255127 640.879517,728.510254 641.155640,727.187134 
	C641.028992,725.070007 640.565979,723.531128 640.102966,721.992188 
	C639.925964,721.941833 639.748962,721.891418 639.297974,721.448120 
	C639.021423,720.643555 639.018738,720.231995 639.015991,719.820435 
	C639.567871,719.661438 640.119629,719.502380 640.918762,719.191895 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M344.967407,717.964966 
	C346.198669,717.284668 347.358490,716.326660 348.677490,715.987183 
	C350.812958,715.437622 353.053009,715.294189 355.647278,714.843994 
	C356.367371,716.525696 356.689026,718.342651 357.072296,720.563721 
	C356.954987,722.493103 356.776093,724.018250 356.597198,725.543396 
	C356.191803,725.821289 355.786377,726.099243 355.380981,726.377136 
	C354.967316,719.846191 352.157104,716.587830 345.450562,718.015076 
	C344.998260,717.997864 344.967377,717.964966 344.967407,717.964966 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M458.972717,741.177368 
	C463.056854,739.608154 467.140991,738.038940 471.247986,736.460938 
	C472.603210,740.077209 469.534088,743.378845 462.415741,743.720703 
	C460.981903,742.634521 459.977325,741.905945 458.972717,741.177368 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M317.348846,296.097656 
	C316.826416,295.517761 316.654846,294.971832 316.243286,294.270874 
	C316.021027,293.330048 316.038696,292.544250 316.330139,291.376404 
	C316.603912,290.994415 317.045471,290.924713 317.391205,290.897797 
	C318.787720,288.964539 319.838531,287.058197 320.910706,285.506775 
	C321.846161,287.281128 322.760315,288.700592 323.406311,289.703674 
	C322.521240,291.356842 321.796967,292.709656 321.054260,294.483215 
	C321.018768,296.599182 321.001709,298.294373 320.984680,299.989594 
	C319.889679,298.703613 318.794708,297.417603 317.348846,296.097656 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M413.954346,624.494080 
	C416.373352,624.074219 418.776825,624.057068 421.614990,624.143127 
	C421.856506,625.862732 421.663330,627.479126 421.456360,629.211121 
	C417.397522,630.253113 413.978302,630.557007 413.954346,624.494080 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M361.825378,734.321899 
	C358.263947,731.502808 360.297119,728.125916 361.085022,724.549683 
	C361.284698,724.206238 361.647430,724.115662 361.826752,724.063049 
	C362.750641,724.012695 363.495239,724.014954 364.625854,724.455322 
	C364.993317,726.950195 364.974762,729.006897 364.905945,731.393433 
	C363.845612,732.589478 362.835510,733.455688 361.825378,734.321899 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M252.943695,734.046082 
	C254.403519,735.022400 255.863327,735.998718 257.698242,736.982361 
	C261.228882,738.927307 264.384430,740.864929 268.358185,743.304932 
	C264.450958,742.837830 261.134430,742.441406 257.400757,742.037109 
	C256.477966,741.446228 255.972290,740.863098 255.186523,739.833984 
	C254.284851,738.581482 253.663330,737.774902 253.041809,736.968384 
	C253.041809,736.968384 253.003464,736.988708 253.013916,736.610168 
	C252.997467,735.503113 252.970581,734.774597 252.943695,734.046082 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M494.967712,729.084534 
	C493.912842,729.054260 492.857971,729.023987 491.018494,728.981506 
	C488.149780,728.311279 486.065613,727.653320 484.356262,726.844055 
	C488.147675,726.125183 491.564270,725.557556 495.353241,724.990845 
	C496.468719,724.981323 497.211792,724.970947 497.882324,725.256836 
	C498.851624,726.044739 499.893463,726.536438 500.935303,727.028076 
	C500.684723,727.500122 500.434143,727.972229 499.658447,728.714478 
	C497.744812,729.018005 496.356262,729.051270 494.967712,729.084534 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M582.903931,681.273926 
	C586.716125,684.242676 590.512573,682.576172 594.579163,681.232300 
	C593.720398,682.910950 592.576721,684.749695 591.036743,686.235718 
	C589.486572,686.072876 588.332703,686.262756 587.178894,686.452637 
	C585.699463,684.800537 584.220093,683.148438 582.903931,681.273926 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M551.549805,720.043884 
	C551.464233,718.609985 551.759644,717.157471 552.084839,715.326416 
	C556.841370,715.312500 561.568115,715.677246 566.772217,716.100830 
	C566.207275,717.201599 565.164856,718.243469 563.789673,719.645813 
	C563.054382,719.995605 562.651917,719.984924 562.249390,719.974243 
	C559.871887,719.695923 557.883911,715.637268 555.056641,719.616699 
	C553.981384,720.005188 552.956055,720.015198 551.549805,720.043884 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M678.987549,738.685303 
	C678.744629,738.933289 678.369751,738.961121 678.182068,738.971924 
	C677.991394,737.964539 677.988464,736.946228 677.975647,735.550964 
	C679.159180,732.501099 680.352539,729.828247 681.815735,726.551147 
	C681.736450,726.538574 682.924744,726.726562 684.457214,726.839722 
	C684.549255,729.235046 684.297058,731.705261 684.017212,734.596558 
	C682.403259,736.157532 680.816833,737.297424 678.987549,738.685303 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M479.923157,730.117249 
	C480.096802,731.420288 480.083588,732.783813 480.481964,734.014038 
	C481.388885,736.814575 479.905457,737.203369 477.306641,737.075439 
	C476.560211,736.342224 476.216492,735.581543 475.913055,734.084229 
	C475.658752,731.252380 475.364197,729.157043 475.069611,727.061768 
	C475.069611,727.061768 475.087311,726.578003 475.116943,726.338135 
	C476.510101,726.991028 477.873627,727.883789 479.529144,729.207764 
	C479.821106,729.638977 479.923126,730.117249 479.923157,730.117249 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M561.044434,741.305298 
	C562.100952,740.696533 563.265137,740.307129 565.217285,739.932251 
	C569.672424,740.303101 573.339478,740.659424 577.006592,741.015808 
	C577.006592,741.015808 577.000549,740.979797 576.995483,741.311401 
	C576.979675,742.083618 576.968994,742.524231 576.958313,742.964905 
	C576.958313,742.964905 576.994995,743.002441 576.588989,743.039612 
	C573.807190,743.474182 571.431274,743.871582 569.055420,744.268921 
	C567.907715,743.957092 566.760071,743.645264 565.093506,742.892334 
	C563.708252,742.304443 562.841858,742.157715 561.975525,742.010986 
	C561.632019,741.965698 561.357544,741.803650 561.044434,741.305298 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M458.729858,741.118591 
	C459.977325,741.905945 460.981903,742.634521 462.018555,743.695557 
	C457.265015,744.810913 453.458832,742.927979 450.128326,739.309509 
	C451.126953,738.101562 452.158936,737.243835 453.446747,736.259277 
	C453.768829,736.307495 453.835022,736.482544 453.980621,737.235229 
	C454.707916,738.205383 455.355774,738.597778 456.003662,738.990112 
	C456.350220,739.053406 456.624054,739.229675 457.000305,739.988647 
	C457.446869,740.641663 457.718323,740.824951 457.989777,741.008240 
	C457.989777,741.008240 458.486938,741.059814 458.729858,741.118591 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M525.335938,650.986816 
	C529.062012,650.852905 533.117371,650.988586 537.158081,650.804260 
	C538.804688,650.729187 540.419617,649.959778 542.172302,649.091370 
	C542.775696,649.937927 543.255737,651.199341 543.266113,652.805908 
	C539.093567,653.536743 535.390808,653.922363 531.687988,654.308044 
	C529.678406,653.247925 527.668884,652.187805 525.335938,650.986816 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M254.032288,590.997559 
	C254.201385,590.996521 254.370483,590.995544 254.793182,591.006104 
	C255.696106,593.747681 256.345428,596.477722 256.986511,599.618774 
	C256.978271,600.029724 256.985596,599.990051 257.005371,599.987488 
	C254.276184,599.309082 251.527222,598.633240 248.424713,597.629761 
	C248.911514,594.116394 254.698257,595.607544 254.032288,590.997559 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M692.208862,557.286194 
	C693.196045,556.909668 694.274109,556.866760 695.770020,557.213989 
	C696.781006,558.078491 697.374023,558.552979 697.967041,559.027405 
	C698.955261,561.317322 699.943481,563.607239 700.977295,566.239807 
	C698.115112,563.594910 695.207336,560.607422 692.208862,557.286194 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M242.509323,570.026978 
	C242.076035,569.372986 241.828232,568.430115 241.301392,567.256958 
	C241.001266,565.992065 240.980179,564.957520 240.941132,563.541260 
	C242.978043,562.395691 245.032898,561.632019 247.545715,560.777649 
	C248.746429,560.921814 249.489182,561.156677 250.272049,561.404175 
	C247.616394,564.325073 245.155594,567.031616 242.509323,570.026978 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M501.220154,726.795044 
	C499.893463,726.536438 498.851624,726.044739 498.152100,725.051147 
	C498.529907,720.053345 496.808746,716.864929 492.072388,715.920532 
	C492.072388,715.920532 491.995728,715.491028 491.952362,715.277649 
	C499.333221,715.063354 501.156616,717.248108 501.220154,726.795044 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M379.115997,638.430420 
	C378.677887,638.804626 378.515717,638.855530 378.099243,638.887268 
	C377.868591,638.012207 377.892273,637.156311 377.993439,635.902832 
	C378.070953,635.505371 378.077637,635.582520 378.077637,635.582520 
	C377.912659,634.698975 377.747681,633.815430 377.746460,632.508118 
	C377.910187,632.084351 378.351776,632.106506 378.586853,632.585449 
	C382.152374,636.961670 385.983093,634.517456 389.668121,633.919983 
	C390.005768,634.089294 390.125000,634.356201 389.896912,635.215820 
	C389.844330,636.075684 389.920624,636.440369 389.996887,636.805054 
	C389.992523,637.036194 389.988190,637.267395 389.830658,637.809631 
	C386.248962,638.116150 382.820465,638.111633 379.115997,638.430420 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M250.801697,581.756348 
	C249.970856,581.596375 249.192444,581.080811 249.038986,580.979187 
	C246.895325,583.278748 244.967285,585.347046 242.541229,587.949524 
	C242.547531,587.975342 242.808685,589.047668 243.094879,590.479370 
	C241.699356,589.719604 240.278824,588.600403 237.654129,586.532532 
	C244.364609,585.774658 246.865784,582.471436 248.478912,577.254822 
	C249.270645,578.636841 250.062378,580.018799 250.801697,581.756348 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M480.706726,741.537537 
	C483.514618,741.327820 486.489380,741.473999 489.705750,741.386108 
	C490.374298,741.083069 490.801208,741.014282 491.930115,740.952026 
	C493.759888,741.309448 494.887634,741.660461 496.015411,742.011414 
	C496.015411,742.011414 496.001221,742.000000 495.785309,742.069031 
	C495.288239,742.286926 495.080353,742.505432 494.945740,742.793579 
	C490.134338,744.380310 485.368103,745.260437 480.706726,741.537537 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M726.009644,545.374390 
	C726.153259,545.013672 726.345642,544.994507 727.074829,545.075684 
	C728.266296,545.519592 728.920837,545.863281 729.575439,546.206909 
	C729.575439,546.206909 729.774658,546.641113 729.891479,547.080811 
	C730.130615,547.801453 730.331604,548.004211 730.611389,548.128906 
	C730.611389,548.128906 730.724976,548.256775 730.775024,548.498535 
	C730.825073,548.740234 731.013306,549.006287 731.013306,549.006287 
	C731.319031,550.093994 731.624756,551.181702 731.989746,552.602417 
	C732.364624,554.055847 732.680298,555.176392 732.999634,556.645874 
	C733.003235,556.994812 732.986450,557.022827 732.756592,556.970764 
	C732.526733,556.918701 732.055420,556.927734 731.843506,556.884033 
	C731.631531,556.840393 731.201294,556.793274 731.107422,556.508057 
	C730.413757,555.776123 729.813843,555.329407 729.195740,554.864746 
	C729.177490,554.846741 729.141846,554.809937 729.095581,554.469849 
	C728.346924,552.426392 727.644531,550.723022 726.942139,549.019592 
	C726.647583,547.918396 726.353027,546.817139 726.009644,545.374390 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M672.850281,718.092590 
	C671.114990,718.062866 669.379700,718.033142 667.287903,717.978638 
	C668.495178,714.430298 670.058960,710.906738 671.622803,707.383118 
	C672.083618,707.433044 672.544434,707.482910 673.005249,707.532776 
	C673.005249,710.385864 673.005249,713.238892 672.970825,716.816895 
	C672.907715,717.725464 672.879028,717.909058 672.850281,718.092590 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M741.776611,538.910095 
	C743.134949,539.390015 744.493286,539.869934 745.767578,541.001038 
	C744.103394,542.475525 742.523315,543.298828 740.943237,544.122131 
	C740.229187,544.288025 739.515198,544.453918 738.371948,544.275391 
	C737.682861,543.100647 737.422913,542.270447 737.162964,541.440186 
	C737.448303,541.064941 737.733704,540.689697 738.405945,540.093994 
	C738.792847,539.873474 739.024902,539.596863 739.024902,539.596863 
	C739.024902,539.596863 739.167297,539.303955 739.600220,539.195862 
	C740.614319,539.028564 741.195435,538.969360 741.776611,538.910095 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M361.858337,734.662964 
	C362.835510,733.455688 363.845612,732.589478 365.165466,731.281372 
	C365.961792,730.560608 366.448364,730.281738 366.934937,730.002869 
	C367.680786,730.000061 368.426605,729.997253 369.543335,729.971008 
	C368.943054,732.086487 367.971893,734.225403 366.916687,736.725952 
	C365.518463,737.062256 364.204315,737.036987 362.497620,737.042358 
	C362.033844,736.383362 361.962555,735.693726 361.858337,734.662964 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M766.045166,462.778564 
	C765.732056,461.398407 765.418945,460.018188 765.042603,458.359253 
	C768.157715,458.083649 770.770386,457.852509 773.663818,457.347504 
	C778.260864,456.828583 778.260864,456.828583 777.375122,458.903198 
	C773.902771,460.167603 770.946838,461.533081 767.664795,462.925690 
	C766.907471,462.894775 766.476318,462.836670 766.045166,462.778564 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M522.273560,737.305176 
	C524.448059,736.950378 526.557007,736.964722 529.041260,736.942993 
	C528.228394,738.814819 527.040161,740.722778 525.851929,742.630737 
	C524.637268,740.978577 523.422607,739.326416 522.273560,737.305176 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M752.691162,742.004883 
	C752.181274,742.700317 751.359680,743.972961 750.735779,743.882507 
	C747.565857,743.422852 744.447205,742.609924 741.525085,741.520508 
	C742.199585,740.477417 742.659485,739.834595 743.386597,739.132690 
	C743.834473,739.047974 744.015137,739.022217 744.586060,738.986328 
	C746.019836,738.978271 747.063354,738.980469 748.106873,738.982666 
	C748.106873,738.982727 748.563782,738.947388 748.779053,739.321228 
	C750.007324,740.408875 751.020264,741.122681 752.033203,741.836487 
	C752.033203,741.836487 752.470642,741.953491 752.691162,742.004883 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M740.828003,719.086609 
	C741.861633,721.206726 742.502625,723.275879 742.704895,725.630127 
	C740.768555,725.575684 739.270874,725.236084 737.773315,724.896545 
	C737.609802,722.328552 737.446289,719.760559 737.558350,716.973450 
	C738.701172,717.514771 739.568298,718.275208 740.828003,719.086609 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M613.464111,742.791016 
	C611.797424,741.807007 610.421875,740.664978 609.046387,739.523071 
	C610.292908,738.542725 611.539368,737.562439 613.041138,736.276367 
	C618.044189,737.874878 614.806458,740.329285 613.464111,742.791016 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M207.196533,744.251465 
	C208.909500,741.864685 210.830902,739.744812 212.871338,737.311523 
	C213.358932,734.599487 213.727539,732.200867 214.126312,729.389343 
	C214.686890,729.315918 215.573532,729.561768 215.691193,730.009644 
	C216.610886,733.510193 212.811966,742.721558 209.703369,744.487549 
	C209.119720,744.819031 208.180252,744.524170 207.196533,744.251465 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M413.938416,623.358887 
	C413.938416,623.358887 413.926849,623.430664 413.961761,623.438232 
	C412.582733,623.599731 411.168762,623.753723 409.338074,623.787537 
	C409.494598,620.039795 407.552612,619.440857 404.436432,620.527893 
	C405.792084,616.239990 408.522675,617.279724 411.701416,618.348755 
	C412.810730,620.244934 413.374573,621.801941 413.938416,623.358887 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M531.701172,654.559326 
	C535.390808,653.922363 539.093567,653.536743 543.236694,653.156372 
	C543.868469,654.181030 544.059937,655.200378 544.151245,656.605713 
	C540.444824,656.667480 536.838745,656.343384 532.811157,656.067993 
	C532.164612,655.681396 531.939514,655.246033 531.701172,654.559326 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M260.908112,716.891052 
	C260.195892,716.945923 259.483643,717.000793 258.415009,717.067505 
	C261.335999,713.109863 270.287994,712.614624 273.947327,716.591553 
	C272.938873,716.908997 271.875031,716.838257 270.105713,716.515259 
	C266.569580,716.472351 263.738831,716.681702 260.908112,716.891052 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M496.366272,741.972839 
	C494.887634,741.660461 493.759888,741.309448 492.252563,740.921753 
	C493.935486,739.139587 495.946991,737.326965 498.098236,735.698975 
	C498.598938,735.320068 499.849548,735.254333 500.259674,735.612305 
	C500.727661,736.020752 501.039642,737.376343 500.726013,737.775391 
	C499.542267,739.281555 498.076996,740.566467 496.366272,741.972839 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M806.494873,738.983337 
	C806.424377,737.927063 806.707031,736.811401 806.982422,735.344360 
	C806.989441,734.551208 807.003723,734.109375 807.330566,733.303833 
	C808.055237,732.307617 808.467163,731.675049 808.879150,731.042419 
	C809.707458,728.831299 810.535706,726.620117 811.628906,724.191040 
	C810.987305,729.218323 811.747986,735.026306 806.494873,738.983337 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M201.515472,706.017700 
	C201.501419,708.586731 201.821075,711.583862 200.604828,713.696899 
	C199.660294,715.337891 196.799255,715.875793 194.381058,716.868530 
	C198.230240,714.485413 199.337433,710.026245 201.515472,706.017700 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M644.049622,739.035522 
	C643.935730,740.202942 643.821777,741.370422 643.707825,742.537842 
	C642.833191,742.231995 641.958618,741.926147 641.017944,741.291443 
	C640.951904,740.962585 640.970764,740.979431 641.151062,740.910889 
	C641.410583,740.741394 641.575989,740.578369 641.555115,740.549316 
	C641.414429,740.353638 641.234070,740.186401 641.066650,740.009949 
	C641.033264,737.971802 640.999878,735.933716 641.402832,733.464600 
	C642.560669,733.044189 643.282227,733.054688 644.123901,733.278748 
	C644.458801,733.758789 644.733582,733.927979 645.068359,733.999695 
	C645.128052,734.717224 645.187744,735.434814 645.300537,736.508423 
	C645.353577,736.864502 645.153931,736.908020 644.822388,737.127991 
	C644.343750,737.910461 644.196716,738.473022 644.049622,739.035522 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M763.613464,468.649109 
	C763.574402,467.735443 763.535339,466.821777 763.965515,465.491455 
	C767.878479,465.434357 771.322144,465.793884 774.639343,466.478455 
	C773.194946,467.311676 771.877075,467.819763 770.559143,468.327881 
	C770.559143,468.327881 770.181946,468.412109 770.181946,468.412109 
	C770.181946,468.412109 769.795959,468.391602 769.237061,468.291840 
	C766.989868,468.344421 765.301697,468.496765 763.613464,468.649109 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M540.639038,734.955078 
	C542.062073,734.977234 543.076355,734.998657 544.469727,735.013062 
	C544.848816,735.006165 544.880188,735.461365 544.595093,735.895935 
	C543.878052,737.556274 543.445984,738.782043 543.013977,740.007812 
	C542.872498,740.309753 542.664368,740.555115 541.822388,740.816895 
	C540.557678,740.882080 539.860168,740.874268 539.162598,740.866516 
	C539.162598,740.866516 539.007202,740.526062 538.978882,740.031616 
	C538.654053,739.030579 538.357483,738.523926 538.060974,738.017334 
	C537.707336,737.231812 537.353638,736.446350 536.981995,735.337097 
	C538.052734,734.993652 539.141418,734.973999 540.639038,734.955078 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M198.002380,740.996887 
	C197.489120,741.692810 196.975861,742.388672 196.113617,743.193787 
	C194.348114,742.344299 192.931610,741.385620 191.242401,739.891968 
	C189.844055,736.413513 191.987030,736.357666 194.052551,736.347595 
	C195.490250,738.080444 196.746323,739.538696 198.002380,740.996887 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M248.936478,719.798950 
	C248.139023,719.468323 247.455460,718.973206 246.595337,718.288574 
	C246.418762,718.099060 246.181168,717.888611 245.945435,717.612915 
	C245.479523,717.157104 245.218246,717.066284 244.925858,717.064819 
	C244.555527,716.431274 244.185181,715.797668 243.814850,715.164124 
	C246.616989,714.453918 249.650070,712.488403 250.997162,716.852661 
	C249.998276,717.824768 249.569214,718.534485 249.140137,719.244202 
	C249.140137,719.244202 249.050369,719.634338 248.936478,719.798950 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M753.980591,535.760620 
	C750.547119,535.657227 747.155029,535.289490 743.362183,534.581360 
	C742.904419,533.739563 742.847473,533.238220 742.790527,532.736877 
	C742.790588,532.736877 742.979126,532.373108 743.142700,532.249512 
	C747.138428,531.151855 751.128418,529.516785 753.814575,534.765747 
	C754.077332,535.143982 754.049622,535.320129 753.980591,535.760620 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M453.635559,722.752563 
	C451.816437,723.994873 450.240326,725.006531 448.277069,726.021240 
	C447.936462,725.278198 447.983002,724.532166 448.319580,723.278809 
	C448.828613,722.514832 448.930023,722.217834 448.913849,721.880554 
	C448.988495,721.567139 449.146362,721.303589 449.577148,720.955933 
	C450.698059,720.198486 451.564697,719.277161 452.583130,719.056580 
	C453.257385,718.910522 454.149292,719.769104 454.928955,720.505981 
	C454.568939,721.396667 454.223755,721.959290 453.635559,722.752563 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M593.037048,714.978577 
	C592.151794,715.004761 591.266541,715.030945 590.066284,715.091858 
	C589.589966,714.329651 589.428589,713.532715 589.508911,712.091431 
	C590.212830,710.061340 590.675110,708.675537 591.137329,707.289673 
	C591.137329,707.289673 591.584595,707.148132 592.130615,707.097351 
	C593.101929,707.033630 593.527283,707.020691 593.952576,707.007751 
	C593.984924,707.434021 594.017334,707.860352 594.041870,708.919006 
	C594.078125,709.754150 594.122131,709.956909 594.166138,710.159668 
	C594.167969,710.868286 594.169861,711.576965 593.848022,712.783569 
	C593.361877,713.847229 593.199463,714.412903 593.037048,714.978577 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M253.811752,590.737549 
	C254.698257,595.607544 248.911514,594.116394 248.022491,597.670044 
	C247.611267,598.097107 247.248688,598.156189 246.618149,598.259888 
	C246.427963,593.567810 248.892654,590.903687 253.811752,590.737549 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M767.990967,462.898529 
	C770.946838,461.533081 773.902771,460.167603 777.081909,459.047546 
	C776.578796,460.936981 775.852417,462.581024 774.771423,464.462250 
	C772.274841,464.099152 770.132935,463.498840 767.990967,462.898529 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M738.355713,715.970581 
	C737.821533,715.125793 737.632019,714.248352 737.353516,712.958740 
	C735.435791,714.340759 733.920410,715.432800 732.192444,716.340698 
	C733.374695,711.179932 735.520325,709.603271 738.656128,711.519165 
	C740.658142,712.742371 742.239380,714.437195 738.355713,715.970581 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M557.046082,710.105957 
	C558.116272,708.897278 559.175598,708.096802 560.234863,707.296326 
	C560.938721,708.873413 561.642639,710.450500 562.346497,712.027527 
	C561.828796,712.505676 561.311035,712.983765 560.793335,713.461853 
	C559.540588,712.479309 558.287842,711.496704 557.046082,710.105957 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M709.225586,728.930664 
	C708.830994,726.589905 708.850586,724.285217 708.818420,721.552734 
	C709.888733,721.083252 711.010803,721.041626 712.550110,720.996094 
	C712.980164,723.415283 712.992981,725.838318 712.969604,728.641479 
	C711.835571,729.003296 710.737610,728.985046 709.225586,728.930664 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M748.199280,738.548340 
	C747.063354,738.980469 746.019836,738.978271 744.543335,738.730774 
	C744.078613,738.315735 744.046875,738.146057 744.015137,737.976318 
	C744.343445,736.079041 744.474548,734.113098 745.098877,732.318848 
	C745.342163,731.619629 746.696228,731.306946 747.893799,730.935181 
	C748.259399,733.406067 748.275513,735.760010 748.199280,738.548340 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M353.580688,737.029419 
	C352.501251,738.307922 351.252350,740.317139 349.567627,740.797485 
	C346.980988,741.534790 344.160736,741.092773 343.290527,737.302124 
	C346.606750,739.048950 349.884430,739.966919 353.580688,737.029419 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M389.725739,633.735413 
	C385.983093,634.517456 382.152374,636.961670 378.794067,632.662476 
	C378.825958,632.063721 378.885712,631.866882 378.993835,631.365967 
	C380.410217,631.414368 381.763672,631.846375 383.149811,632.099854 
	C385.013855,632.440857 386.903076,632.644043 389.188324,633.059692 
	C389.595154,633.213013 389.783356,633.550842 389.725739,633.735413 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.915710,417.529297 
	C316.529205,417.952545 317.003479,418.816284 317.727539,420.185547 
	C317.985687,421.121246 317.994080,421.551483 317.744446,422.159027 
	C317.649384,423.211731 317.812286,424.087067 318.200165,425.084808 
	C318.614716,425.485779 318.804321,425.764374 318.686035,426.072205 
	C318.048737,426.513763 317.719360,426.926056 317.389984,427.338348 
	C317.927887,427.588348 318.465790,427.838379 318.993286,428.417877 
	C318.656464,429.501312 318.330017,430.255280 317.737610,431.171875 
	C316.976440,432.199707 316.481201,433.064911 315.985962,433.930145 
	C315.916107,428.610046 315.846283,423.289917 315.915710,417.529297 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M539.340088,621.041504 
	C539.821167,618.594177 540.654724,616.251160 541.985901,612.509216 
	C543.398682,615.690002 544.178833,617.446594 544.974365,619.547668 
	C544.864197,620.006104 544.738647,620.120178 544.073120,620.191895 
	C542.742615,620.460754 541.951904,620.772095 541.161255,621.083496 
	C540.671692,621.104248 540.182129,621.125000 539.340088,621.041504 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M230.075562,720.294922 
	C230.739502,718.713318 231.487381,717.408325 232.235260,716.103394 
	C233.038986,717.535828 233.938736,718.932129 234.510620,720.451782 
	C234.573853,720.619751 232.763031,721.492981 231.514221,722.000000 
	C230.857880,721.497803 230.508682,721.034668 230.075562,720.294922 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M480.266113,729.950562 
	C479.923126,730.117249 479.821106,729.638977 479.787598,729.397339 
	C479.986847,728.563965 480.219604,727.972168 481.078064,727.185608 
	C482.462982,726.992371 483.222229,726.993835 483.981476,726.995361 
	C486.065613,727.653320 488.149780,728.311279 490.622772,728.995911 
	C489.272339,729.989014 487.533051,730.955505 485.429993,731.974243 
	C485.033325,728.391541 483.106201,728.521362 480.266113,729.950562 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M767.664795,462.925690 
	C770.132935,463.498840 772.274841,464.099152 774.743042,464.811279 
	C775.069214,464.923096 775.063599,464.951141 775.041199,465.207275 
	C775.022156,465.633545 775.025452,465.803711 774.964844,466.021332 
	C774.900879,466.068817 774.765808,466.153381 774.765808,466.153381 
	C771.322144,465.793884 767.878479,465.434357 763.975098,465.148315 
	C763.515442,465.221802 763.287048,465.351685 763.163696,465.308228 
	C763.788452,464.473022 764.536438,463.681305 765.664795,462.834076 
	C766.476318,462.836670 766.907471,462.894775 767.664795,462.925690 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M779.058838,706.965576 
	C779.050659,710.025024 779.008423,713.080078 778.530762,716.589355 
	C777.144409,717.112183 776.193420,717.180847 775.242493,717.249573 
	C775.242493,717.249573 775.131592,717.135681 775.097046,716.759033 
	C775.082397,715.957214 775.102295,715.532043 775.122192,715.106873 
	C775.122192,715.106873 775.109314,715.089966 775.417358,714.936829 
	C776.167419,713.527100 776.609497,712.270508 777.051575,711.013916 
	C777.079163,710.561584 777.106689,710.109253 777.174072,709.010010 
	C777.823547,707.873901 778.433228,707.384705 779.042969,706.895630 
	C779.042969,706.895630 779.024841,706.961304 779.058838,706.965576 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M634.725098,712.284912 
	C631.858826,712.229065 629.343506,712.131042 626.363220,712.059692 
	C626.086975,711.410278 626.255554,710.153259 626.467590,710.145874 
	C629.382263,710.044495 632.302917,710.115234 635.222107,710.143738 
	C635.173462,710.843384 635.124817,711.543091 634.725098,712.284912 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M315.926453,434.335754 
	C316.481201,433.064911 316.976440,432.199707 317.772156,431.523804 
	C318.626068,432.868622 319.014008,434.147461 319.765564,435.155121 
	C321.618256,437.639282 320.532104,438.592896 317.843323,438.735199 
	C317.118591,438.321503 316.563629,438.150940 316.008667,437.980377 
	C315.961426,436.900696 315.914185,435.821014 315.926453,434.335754 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M741.298828,544.293823 
	C742.523315,543.298828 744.103394,542.475525 745.851685,541.369873 
	C747.561157,541.183350 749.102356,541.279175 750.898621,541.671326 
	C747.987305,542.800293 744.820862,543.632935 741.298828,544.293823 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M815.671387,712.082031 
	C815.156677,713.028748 814.369263,714.589172 813.271057,714.852295 
	C811.275818,715.330322 809.095703,715.036865 806.552795,715.111267 
	C807.112305,714.441772 808.018860,713.397278 809.131592,713.083313 
	C811.108032,712.525635 813.209412,712.410828 815.671387,712.082031 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M315.990173,456.430023 
	C319.468201,461.361816 316.731964,466.935913 316.370300,472.715759 
	C316.007599,470.929810 315.919708,468.851776 315.945007,466.140228 
	C316.058197,465.506744 315.920807,465.125610 315.920837,465.125610 
	C315.904297,462.374939 315.887787,459.624268 315.990173,456.430023 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M763.440613,468.952301 
	C765.301697,468.496765 766.989868,468.344421 769.050415,468.242157 
	C767.796997,470.026062 766.171265,471.759857 764.545532,473.493683 
	C764.119629,473.372040 763.693665,473.250397 763.267761,473.128754 
	C763.267761,471.837677 763.267761,470.546570 763.440613,468.952301 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M343.585266,262.426147 
	C344.098816,263.619263 344.152191,264.678558 344.222107,266.065552 
	C341.871674,265.325348 339.799957,264.672943 337.350647,264.017059 
	C336.972992,264.013580 336.478424,263.958252 336.326294,263.732941 
	C336.617676,263.297821 337.061218,263.088043 337.504761,262.878265 
	C339.378204,262.682983 341.251648,262.487671 343.585266,262.426147 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M644.003784,733.065186 
	C643.282227,733.054688 642.560669,733.044189 641.480591,733.033813 
	C641.085022,732.863159 641.048035,732.692383 641.308289,732.257080 
	C642.131409,731.893616 642.657288,731.794800 643.183167,731.695984 
	C643.176025,731.518188 643.168884,731.340393 643.161743,731.162537 
	C642.447754,731.110291 641.733765,731.057983 641.019775,731.005737 
	C641.013245,730.838196 641.006653,730.670593 641.000000,730.251526 
	C645.451965,731.293152 644.465820,727.028198 646.193726,725.092651 
	C646.185181,727.458557 645.867371,729.952942 645.050110,732.739990 
	C644.368347,733.043518 644.186096,733.054382 644.003784,733.065186 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M770.780457,468.588379 
	C771.877075,467.819763 773.194946,467.311676 774.639343,466.478455 
	C774.765808,466.153381 774.900879,466.068817 775.156250,466.066071 
	C775.411560,466.063324 775.803223,466.100464 775.803223,466.100464 
	C776.172607,467.389984 776.542053,468.679474 777.210327,471.012177 
	C774.456360,470.052582 772.729065,469.450745 770.780457,468.588379 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M593.280762,715.209167 
	C593.199463,714.412903 593.361877,713.847229 593.791870,713.133789 
	C594.835449,714.020020 595.611511,715.053955 596.722534,716.186401 
	C596.920532,717.120728 596.783447,717.956604 596.880737,718.962585 
	C596.656006,720.064026 596.196960,720.995361 595.419556,722.006714 
	C594.575684,719.871155 594.050110,717.655457 593.280762,715.209167 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M800.055725,751.315552 
	C801.453491,749.099670 802.905029,747.228210 804.608215,745.157593 
	C804.033813,747.475830 803.207703,749.993347 802.381653,752.510864 
	C801.624268,752.227173 800.866882,751.943542 800.055725,751.315552 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M337.180298,262.670288 
	C337.061218,263.088043 336.617676,263.297821 336.083984,263.676239 
	C334.641418,265.134338 333.288971,266.423859 331.936523,267.713348 
	C331.629059,267.604889 331.321564,267.496399 331.014069,267.387909 
	C331.356262,266.006714 331.698425,264.625519 332.084045,262.913208 
	C333.703613,262.542145 335.279724,262.502228 337.180298,262.670288 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M315.856384,279.342590 
	C316.448212,279.289581 316.728790,279.480560 317.092987,280.339478 
	C317.090393,283.672211 317.004211,286.337036 316.704956,289.008789 
	C316.491882,289.015717 316.065979,288.995361 316.065979,288.995361 
	C315.892365,285.859131 315.718750,282.722870 315.856384,279.342590 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M759.102539,551.330750 
	C760.252808,550.706238 761.450195,550.425110 762.928711,550.029907 
	C763.393372,551.397034 763.576904,552.878296 763.760498,554.359619 
	C763.355042,554.576050 762.949646,554.792542 762.544189,555.008972 
	C761.412659,553.897339 760.281128,552.785767 759.102539,551.330750 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M544.646362,263.714294 
	C541.937683,263.815521 539.059814,263.605072 536.039551,263.063599 
	C538.757141,262.955933 541.617188,263.179260 544.646362,263.714294 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M502.171265,268.027893 
	C502.160156,267.583313 502.149078,267.138733 502.488159,266.293823 
	C503.534302,264.793396 504.230286,263.693298 504.926270,262.593170 
	C505.724060,262.450439 506.521820,262.307739 507.496307,262.488251 
	C507.444458,263.196106 507.215881,263.580750 506.663635,264.150146 
	C505.892883,266.226013 505.445831,268.117126 504.666931,270.008179 
	C503.892883,269.996582 503.450684,269.985077 502.989990,269.693909 
	C502.704773,268.952118 502.438019,268.490021 502.171265,268.027893 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M590.736511,707.206787 
	C590.675110,708.675537 590.212830,710.061340 589.558899,711.682251 
	C588.243347,710.864380 587.119507,709.811401 584.968262,707.795959 
	C587.540283,707.473938 588.937988,707.298950 590.736511,707.206787 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M685.436829,640.992310 
	C688.390930,639.981140 691.771790,638.955750 695.565308,637.969482 
	C696.016846,638.476013 696.055725,638.943420 695.913574,639.717773 
	C692.442871,640.342529 689.153259,640.660278 685.436829,640.992310 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M726.777222,536.663635 
	C725.257874,536.389587 723.781250,535.755920 722.006165,535.159485 
	C722.254578,534.225647 722.801453,533.254700 723.348328,532.283752 
	C724.505493,533.623840 725.662720,534.963989 726.777222,536.663635 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M322.589020,715.015381 
	C322.997009,715.451843 323.000092,715.886658 323.004791,716.647339 
	C321.464447,717.448364 319.942688,718.167480 318.372253,718.297119 
	C317.570465,718.363220 316.681488,717.371948 315.797241,716.503540 
	C317.903076,715.770142 320.043610,715.391907 322.589020,715.015381 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M193.870941,736.072937 
	C191.987030,736.357666 189.844055,736.413513 190.996231,739.666626 
	C190.048828,738.550598 189.074905,737.124878 188.068375,735.341492 
	C189.242859,734.795227 190.449921,734.606628 191.912109,734.142090 
	C192.610321,734.166199 193.053391,734.466125 193.717651,735.155518 
	C193.916229,735.721069 193.893585,735.897034 193.870941,736.072937 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M222.829422,554.362061 
	C223.901108,556.210876 224.648117,557.955872 225.149414,559.900757 
	C223.201675,558.523682 219.616943,557.719849 222.829422,554.362061 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M595.182983,632.654907 
	C594.310974,630.630188 593.630798,628.319397 592.936890,625.632812 
	C593.740356,627.627625 594.557617,629.998230 595.182983,632.654907 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M673.166748,718.328979 
	C672.879028,717.909058 672.907715,717.725464 672.974854,717.265625 
	C675.488586,717.127319 677.963928,717.265198 680.713745,717.690063 
	C678.486633,718.173157 675.984985,718.369263 673.166748,718.328979 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M651.776428,718.240234 
	C654.147278,719.155884 656.129822,720.007263 658.112305,720.858704 
	C657.957581,721.133972 657.802795,721.409302 657.648010,721.684631 
	C654.871948,720.847656 652.095825,720.010742 649.218506,718.861206 
	C649.874207,718.424377 650.631165,718.300171 651.776428,718.240234 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M714.785583,738.088135 
	C714.473572,740.135254 713.780212,742.180115 713.080566,744.605713 
	C709.874023,742.272888 710.143555,740.874268 714.785583,738.088135 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M569.211975,615.212891 
	C569.019409,614.908936 568.920288,614.476135 569.360596,614.237671 
	C571.213562,613.599609 572.626221,613.200073 574.268799,612.823364 
	C574.498718,612.846252 574.960205,612.824585 574.960205,612.824585 
	C575.443970,612.916138 575.927673,613.007690 576.765930,613.199951 
	C574.548462,614.039429 571.976501,614.778137 569.211975,615.212891 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M174.007843,729.007690 
	C173.188705,729.909790 172.369553,730.811951 171.266144,731.843384 
	C171.726654,729.677979 172.471436,727.383179 173.613602,725.438049 
	C174.009933,726.861023 174.008896,727.934387 174.007843,729.007690 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M713.139893,747.087036 
	C715.647400,747.892395 718.141724,749.067322 720.861084,750.485413 
	C718.432983,749.637939 715.779846,748.547302 713.139893,747.087036 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M174.003815,729.003906 
	C174.008896,727.934387 174.009933,726.861023 173.990479,725.385254 
	C173.969971,724.982788 174.039566,724.540039 174.339508,724.156738 
	C175.037842,722.830444 175.436203,721.887451 175.834564,720.944519 
	C175.834564,720.944519 176.204483,720.885986 176.388580,720.888916 
	C176.826309,720.934326 177.079910,720.976685 177.643585,721.413574 
	C176.633469,724.203857 175.313293,726.599731 173.993103,728.995544 
	C173.993103,728.995544 173.999786,729.000183 174.003815,729.003906 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M764.720642,544.005737 
	C764.759399,542.956726 764.798157,541.907776 764.806885,540.466736 
	C764.988953,539.181152 765.201172,538.287598 766.067322,537.185547 
	C767.504211,536.905334 768.287109,536.833618 769.085693,536.847534 
	C769.101440,536.933167 768.994995,537.070923 768.994995,537.070923 
	C767.842407,539.551758 766.689880,542.032593 765.213623,544.509155 
	C764.833496,544.338562 764.777100,544.172180 764.720642,544.005737 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M728.784790,715.526428 
	C726.608887,715.878479 724.233948,715.924194 721.415771,715.906555 
	C723.254395,712.509705 725.913696,713.786743 728.784790,715.526428 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M239.261292,577.239136 
	C242.926392,577.243896 241.697845,574.439514 242.095520,572.209778 
	C242.486282,574.263123 242.784332,576.725891 243.082367,579.188721 
	C242.708023,579.372314 242.333679,579.555847 241.959335,579.739441 
	C241.062531,579.018188 240.165726,578.296875 239.261292,577.239136 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M742.467163,532.843262 
	C742.847473,533.238220 742.904419,533.739563 742.975342,534.619751 
	C742.614807,536.119873 742.240173,537.241150 741.821045,538.636230 
	C741.195435,538.969360 740.614319,539.028564 739.743225,539.118042 
	C740.350159,537.082092 741.246948,535.015869 742.467163,532.843262 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M774.708252,715.077759 
	C775.102295,715.532043 775.082397,715.957214 775.041870,716.701904 
	C772.362488,716.948669 769.703796,716.875977 767.002441,716.421021 
	C769.404602,715.708618 771.849487,715.378601 774.708252,715.077759 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M407.696960,262.464172 
	C405.710175,262.861420 403.414215,263.020447 401.009979,262.827393 
	C403.063721,262.392181 405.225739,262.309052 407.696960,262.464172 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M489.355225,623.871704 
	C490.618866,622.494629 492.285370,621.270813 494.820160,619.409424 
	C494.248566,621.614563 494.036682,622.432068 493.911743,623.489136 
	C492.585205,623.827515 491.171661,623.926270 489.355225,623.871704 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M729.685303,545.844421 
	C728.920837,545.863281 728.266296,545.519592 727.363281,545.045532 
	C727.066040,543.207458 727.017334,541.499756 727.023804,539.365295 
	C727.984436,541.119690 728.889832,543.300842 729.685303,545.844421 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M310.070007,744.408569 
	C312.038879,744.008667 314.090302,743.980164 316.579041,744.004028 
	C315.037567,747.229919 312.648285,746.509766 310.070007,744.408569 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M343.097412,729.262695 
	C345.355469,730.017029 347.637604,731.132507 350.146088,732.521240 
	C347.955475,731.737610 345.538483,730.680725 343.097412,729.262695 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M517.993469,623.022827 
	C518.485168,623.082397 518.976929,623.142029 519.795471,623.374329 
	C517.936462,623.888611 515.750610,624.230164 513.301636,624.071777 
	C513.053345,623.402100 513.068298,623.232422 513.083313,623.062683 
	C514.129395,623.059570 515.175415,623.056396 516.858337,623.089355 
	C517.661255,623.091187 517.827393,623.057007 517.993469,623.022827 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M806.558655,726.998230 
	C806.703186,728.112305 806.414490,729.230042 806.090210,730.703003 
	C806.054749,731.058228 806.054749,731.096130 805.694458,731.086365 
	C804.854431,731.106506 804.374634,731.136414 803.894897,731.166260 
	C803.517761,730.078613 803.140625,728.990906 802.693604,727.556458 
	C803.790894,727.140442 804.958130,727.071167 806.558655,726.998230 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M194.052551,736.347595 
	C193.893585,735.897034 193.916229,735.721069 193.955826,735.279541 
	C195.627579,736.735229 197.282379,738.456299 198.997742,740.505493 
	C198.882828,740.864197 198.707352,740.894714 198.267120,740.961060 
	C196.746323,739.538696 195.490250,738.080444 194.052551,736.347595 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M193.075806,719.213074 
	C194.949509,719.334900 196.798813,719.840454 198.698608,720.706421 
	C197.034821,719.860229 193.810883,724.503906 193.075806,719.213074 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M287.710022,714.060059 
	C286.360870,715.108765 284.600677,716.125549 282.476532,717.131958 
	C282.216888,713.366821 285.012360,714.124084 287.710022,714.060059 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M184.941772,710.709229 
	C183.582901,711.033752 182.206757,710.996704 180.416718,710.989929 
	C181.155609,707.226990 182.762177,706.753967 184.941772,710.709229 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M386.738098,262.414490 
	C386.924500,263.031860 386.704468,263.457886 386.253540,263.983276 
	C384.971558,264.348114 383.920441,264.613617 382.450592,264.984894 
	C382.397034,264.786072 382.177460,263.971283 381.932373,262.848358 
	C383.381805,262.434509 384.856720,262.328827 386.738098,262.414490 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M346.258423,743.652710 
	C348.084778,743.324280 350.127472,743.264648 352.609528,743.249634 
	C350.857513,743.503357 348.666138,743.712402 346.258423,743.652710 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M558.237244,618.176697 
	C559.848877,617.306763 561.656128,616.677124 563.762451,616.214722 
	C562.185303,617.060303 560.309082,617.738647 558.237244,618.176697 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M441.159546,734.748657 
	C437.069641,733.847168 440.274353,731.969299 440.665710,730.220703 
	C441.068756,731.524048 441.238129,733.017517 441.159546,734.748657 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M319.023346,397.053833 
	C318.304596,397.046387 317.585815,397.038940 316.508850,397.004517 
	C316.033905,395.922974 315.917206,394.868408 315.918915,393.430420 
	C316.465271,393.052795 316.893250,393.058685 317.785309,393.323120 
	C318.507385,394.739075 318.765381,395.896423 319.023346,397.053833 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M584.771729,625.327026 
	C584.550110,627.009399 584.027771,628.767273 583.238342,630.733276 
	C583.471191,629.095154 583.971069,627.248840 584.771729,625.327026 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M718.870972,715.909424 
	C717.341736,717.194458 715.621765,718.193726 713.585083,719.319092 
	C713.089783,715.363647 716.096558,715.793274 718.870972,715.909424 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M512.761719,622.903870 
	C513.068298,623.232422 513.053345,623.402100 513.032227,623.827332 
	C511.944641,624.109192 510.863373,624.135559 509.150208,624.030762 
	C508.359619,623.951599 508.200928,624.003723 508.042236,624.055847 
	C507.269318,624.091309 506.496429,624.126831 505.311035,623.860840 
	C504.879730,623.377502 504.860870,623.195740 505.136475,622.989014 
	C505.626740,622.942322 505.822540,622.920593 506.018341,622.898926 
	C506.018341,622.898926 506.495270,622.904053 507.005371,622.816101 
	C508.015106,622.462158 508.514771,622.196167 509.014435,621.930176 
	C510.156342,622.201782 511.298279,622.473450 512.761719,622.903870 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M702.852417,572.293213 
	C703.785706,572.001038 704.501343,571.961304 705.409668,572.218384 
	C704.733154,574.003357 703.863892,575.491333 702.994690,576.979370 
	C702.994690,576.979370 702.587341,576.985352 702.587341,576.985352 
	C702.587341,576.985352 702.180237,576.969482 701.978455,576.969910 
	C702.062744,575.495422 702.348816,574.020508 702.852417,572.293213 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M280.973083,725.690186 
	C278.307831,726.167480 277.946075,724.843811 278.870422,722.155518 
	C278.855316,721.589050 278.834503,721.129211 278.892426,721.082336 
	C278.950348,721.035461 279.064545,720.939758 279.064545,720.939758 
	C279.707703,722.389526 280.350891,723.839355 280.973083,725.690186 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M474.795959,727.316467 
	C475.364197,729.157043 475.658752,731.252380 475.871704,733.757812 
	C475.367493,731.968994 474.944885,729.770081 474.795959,727.316467 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M753.158691,543.111084 
	C753.574097,542.803711 753.980713,542.852051 754.677856,543.002563 
	C755.676270,544.684875 756.384094,546.264954 757.137451,548.162659 
	C755.838562,546.809082 754.494263,545.138000 753.158691,543.111084 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M244.885071,448.298187 
	C244.904922,446.946625 244.974503,445.827454 245.219574,444.429382 
	C246.009888,445.561279 246.624695,446.972076 246.924088,448.713257 
	C246.087402,449.026733 245.566132,449.009827 245.044846,448.992920 
	C245.044846,448.992920 244.934799,448.530548 244.885071,448.298187 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M245.037750,449.317749 
	C245.566132,449.009827 246.087402,449.026733 246.863373,449.052429 
	C247.486572,450.246613 247.855087,451.432007 247.997879,452.817810 
	C246.858322,451.893005 245.944489,450.767792 245.037750,449.317749 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M289.069519,744.246948 
	C290.781128,743.937866 292.463898,743.985718 294.569153,744.041870 
	C293.755371,744.746338 292.581421,745.680908 291.253876,746.027771 
	C290.679413,746.177856 289.788086,745.115417 289.069519,744.246948 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M755.960510,709.780640 
	C757.072266,709.822083 757.929443,710.108459 758.786560,710.394836 
	C758.295288,710.896057 757.804016,711.397278 757.312744,711.898499 
	C756.777100,711.274231 756.241516,710.649902 755.960510,709.780640 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M284.965363,740.079956 
	C286.452637,741.044006 287.649506,742.183838 288.965271,743.614746 
	C286.513641,744.018799 284.403381,743.575806 284.965363,740.079956 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M778.978699,706.559204 
	C778.433228,707.384705 777.823547,707.873901 777.146423,708.670776 
	C776.538269,708.103516 775.997498,707.228516 775.456665,706.353455 
	C776.609253,706.309875 777.761902,706.266357 778.978699,706.559204 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M495.050934,729.435425 
	C496.356262,729.051270 497.744812,729.018005 499.547729,728.960693 
	C498.869629,732.156921 497.075470,731.389771 495.050934,729.435425 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M672.347168,648.679321 
	C671.737244,647.538879 671.334045,646.153137 670.972046,644.383118 
	C671.526855,645.477234 672.040405,646.955688 672.347168,648.679321 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M821.452515,723.942871 
	C821.076721,722.620178 821.046875,721.214478 821.069946,719.389343 
	C823.844238,720.255066 823.687927,721.937744 821.452515,723.942871 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.936646,326.459106 
	C320.132904,327.856873 315.851990,330.362091 316.340759,332.690948 
	C315.993774,330.912079 315.901123,328.893951 315.936646,326.459106 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M232.635468,571.010986 
	C233.280472,571.440613 233.557388,571.878784 233.949219,572.549072 
	C232.600220,573.188538 231.136276,573.596008 229.289825,574.054321 
	C230.027344,573.076660 231.147369,572.048157 232.635468,571.010986 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M569.355835,744.500244 
	C571.431274,743.871582 573.807190,743.474182 576.575439,743.018311 
	C575.330750,746.852295 572.153503,744.388672 569.355835,744.500244 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M317.053314,589.689209 
	C316.925751,590.780518 316.776245,591.651611 316.382629,592.747314 
	C316.011139,591.616455 315.883759,590.261047 315.868469,588.572998 
	C316.330841,588.649902 316.681091,589.059387 317.053314,589.689209 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M317.082489,517.063110 
	C317.082489,517.063110 316.589111,517.016357 316.343750,516.981934 
	C316.010254,515.910645 315.922089,514.873840 316.177185,513.424438 
	C316.520386,513.011902 317.005676,512.963623 317.005676,512.963623 
	C317.005676,512.963623 317.032562,513.007874 317.228821,513.299561 
	C317.936340,514.702393 318.447601,515.813477 318.645569,516.944031 
	C317.915710,516.996643 317.499084,517.029907 317.082489,517.063110 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M685.368225,737.089294 
	C686.155945,737.072876 686.844177,737.399536 687.900940,738.138916 
	C688.566528,739.312012 688.863647,740.072266 689.160828,740.832581 
	C689.160828,740.832581 689.061035,740.936096 689.010742,740.988037 
	C687.729858,739.837463 686.499329,738.634888 685.368225,737.089294 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M640.109863,722.373169 
	C640.565979,723.531128 641.028992,725.070007 641.216675,726.816528 
	C640.666443,725.600830 640.391602,724.177429 640.109863,722.373169 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M394.344299,624.419739 
	C395.597809,624.044495 397.102325,623.874390 398.760559,623.921509 
	C397.474640,624.300842 396.034973,624.462891 394.344299,624.419739 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M205.011826,715.804871 
	C204.488342,714.935181 203.962067,713.673767 203.667679,712.191956 
	C204.269394,713.118774 204.639221,714.265930 205.011826,715.804871 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M776.705933,711.008301 
	C776.609497,712.270508 776.167419,713.527100 775.413696,714.936340 
	C774.762634,713.819214 774.423157,712.549438 774.009583,711.002625 
	C774.828003,711.002625 775.594116,711.002625 776.705933,711.008301 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M816.644348,716.978271 
	C817.001831,717.442444 816.994263,717.899048 816.946655,718.696411 
	C815.828308,719.337769 814.750061,719.638367 813.331543,719.965210 
	C814.087280,718.984558 815.183289,717.977661 816.644348,716.978271 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M315.928284,438.411926 
	C316.563629,438.150940 317.118591,438.321503 317.840881,439.064819 
	C318.032104,440.075043 318.055969,440.512543 318.079834,440.950043 
	C317.716339,441.783508 317.352844,442.616974 316.726105,443.768982 
	C316.462891,444.087494 315.984924,444.086639 315.984924,444.086639 
	C315.939240,442.338928 315.893585,440.591187 315.928284,438.411926 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M276.960266,720.097046 
	C276.354218,719.279175 275.748169,718.461243 274.969452,717.385254 
	C276.200226,718.148193 277.603668,719.169312 279.035828,720.565063 
	C279.064545,720.939758 278.950348,721.035461 278.658234,720.952026 
	C277.897491,720.611450 277.428894,720.354248 276.960266,720.097046 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M580.298340,612.420288 
	C581.516052,611.569824 582.960205,610.900513 584.754883,610.299072 
	C583.578491,611.111694 582.051697,611.856567 580.298340,612.420288 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M535.285034,622.191284 
	C536.226135,621.688354 537.363525,621.414551 538.836182,621.316162 
	C537.941406,621.801086 536.711365,622.110779 535.285034,622.191284 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M749.409790,730.956787 
	C749.159119,729.844604 749.246948,728.660828 749.570251,727.211182 
	C749.786560,728.258606 749.767395,729.571838 749.409790,730.956787 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M480.707092,262.328613 
	C479.755249,262.698151 478.403687,262.896667 476.975586,262.780579 
	C478.035156,262.363159 479.171295,262.260376 480.707092,262.328613 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M493.744995,262.591919 
	C492.681793,262.956726 491.365234,263.059784 489.971222,262.833923 
	C491.093079,262.446716 492.292358,262.388458 493.744995,262.591919 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M615.735107,625.206665 
	C615.499573,626.370239 614.974854,627.520203 614.166504,628.815613 
	C614.403870,627.705078 614.924805,626.449036 615.735107,625.206665 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M168.667389,737.070435 
	C168.577560,738.288391 168.128952,739.562500 167.319916,740.886475 
	C167.409180,739.666443 167.858887,738.396484 168.667389,737.070435 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M806.994934,717.401428 
	C807.835022,717.908569 808.684509,718.767517 809.293640,719.817871 
	C808.370239,719.257263 807.687317,718.505249 806.994934,717.401428 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M587.527771,686.586487 
	C588.332703,686.262756 589.486572,686.072876 590.721191,686.168152 
	C589.826904,686.542297 588.851746,686.631348 587.527771,686.586487 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M252.720779,733.851196 
	C252.970581,734.774597 252.997467,735.503113 253.033966,736.595642 
	C252.458542,735.510193 251.873489,734.060669 251.495514,732.340454 
	C251.967682,732.598572 252.232773,733.127441 252.720779,733.851196 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M594.400757,729.832275 
	C594.235840,728.257324 594.283020,726.414673 594.596191,724.292786 
	C594.779114,725.863892 594.695984,727.714294 594.400757,729.832275 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.870392,400.525330 
	C316.176483,402.536560 316.365021,404.966156 316.270752,407.643738 
	C315.909515,405.575714 315.831177,403.259735 315.870392,400.525330 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M736.904175,541.572754 
	C737.422913,542.270447 737.682861,543.100647 738.008972,544.186401 
	C737.316284,544.425964 736.557434,544.410034 735.798584,544.394104 
	C736.080811,543.497864 736.363098,542.601624 736.904175,541.572754 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M744.829102,709.138550 
	C744.748718,708.412598 744.884338,707.900085 745.019897,707.387512 
	C745.539734,707.903687 746.059509,708.419922 746.579346,708.936096 
	C746.067932,709.074707 745.556519,709.213257 744.829102,709.138550 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M339.761017,739.193237 
	C340.628601,739.781860 341.225739,740.582336 341.934753,741.667603 
	C341.194611,741.103333 340.342590,740.254211 339.761017,739.193237 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M751.983337,741.514038 
	C751.020264,741.122681 750.007324,740.408875 749.007141,739.343506 
	C749.991089,739.725159 750.962280,740.458374 751.983337,741.514038 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.408264,568.797668 
	C315.639954,569.742310 315.641266,570.982117 315.352539,572.123169 
	C315.100983,571.047180 315.139435,570.070007 315.408264,568.797668 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.889954,444.482056 
	C315.984924,444.086639 316.462891,444.087494 316.697693,444.130249 
	C317.129303,445.644653 317.326080,447.116302 317.043152,448.791260 
	C316.395355,448.975281 316.227264,448.955933 316.059204,448.936615 
	C315.971130,447.583557 315.883057,446.230499 315.889954,444.482056 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M738.818726,516.717896 
	C738.888489,517.788757 738.789917,518.633301 738.474976,519.708069 
	C738.389160,518.789429 738.519714,517.640442 738.818726,516.717896 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M577.314758,742.976074 
	C576.968994,742.524231 576.979675,742.083618 576.977295,741.312073 
	C577.725586,740.995178 578.487061,741.009155 579.629333,741.026978 
	C579.230530,741.682922 578.450867,742.335144 577.314758,742.976074 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M315.961609,595.238525 
	C316.266113,595.886719 316.416199,596.751465 316.331604,597.799316 
	C316.000336,597.140015 315.903748,596.297485 315.961609,595.238525 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M204.231750,708.786377 
	C203.628723,707.925842 203.307434,706.847839 202.889587,705.446411 
	C203.366501,706.271606 203.940002,707.420288 204.231750,708.786377 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M514.772461,262.471863 
	C514.847961,263.313263 514.562073,263.972961 514.276123,264.632660 
	C513.827271,264.150726 513.378418,263.668793 512.898254,262.903015 
	C513.381653,262.509552 513.896362,262.399872 514.772461,262.471863 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M479.257080,623.867065 
	C479.908417,623.457703 480.940460,623.228638 482.016296,623.315308 
	C481.252655,623.769897 480.445221,623.908691 479.257080,623.867065 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M351.105377,263.924225 
	C351.057037,263.615295 350.986572,263.311523 350.881012,262.780762 
	C351.662506,262.394836 352.456909,262.241089 353.643951,262.196106 
	C354.018036,262.669952 353.999512,263.034973 353.602539,263.686707 
	C352.517883,263.957001 351.811615,263.940613 351.105377,263.924225 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M428.760254,262.448608 
	C428.845459,263.282196 428.566956,263.954071 428.288452,264.625977 
	C427.833771,264.145233 427.379089,263.664490 426.898926,262.897034 
	C427.381195,262.502502 427.888855,262.394714 428.760254,262.448608 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M467.792664,262.448181 
	C467.105713,262.795776 466.065308,262.898743 464.981384,262.699219 
	C465.771637,262.332367 466.605438,262.267975 467.792664,262.448181 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M472.735870,262.460022 
	C472.114624,262.903442 471.078247,263.161591 469.926453,263.167999 
	C470.647583,262.702423 471.484161,262.488617 472.735870,262.460022 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M594.187866,706.831055 
	C593.527283,707.020691 593.101929,707.033630 592.357788,707.038940 
	C592.615967,706.423645 593.193115,705.816101 593.770264,705.208557 
	C593.987915,705.690491 594.205566,706.172363 594.187866,706.831055 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M504.628693,262.563324 
	C504.230286,263.693298 503.534302,264.793396 502.524170,265.961975 
	C502.170319,265.565125 502.130646,265.099854 502.354614,264.115417 
	C502.791260,263.232422 502.964233,262.868591 503.137207,262.504761 
	C503.535187,262.514343 503.933136,262.523895 504.628693,262.563324 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M305.173645,744.228271 
	C305.873260,743.778564 306.759949,743.590637 307.846039,743.630615 
	C307.150543,744.068970 306.255646,744.279480 305.173645,744.228271 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M504.842041,623.013977 
	C504.860870,623.195740 504.879730,623.377502 504.924316,623.832153 
	C504.362854,624.188477 503.775635,624.271973 503.099121,624.105713 
	C502.869720,623.541199 502.898743,623.242920 503.380676,622.719727 
	C504.056946,622.656799 504.449493,622.835388 504.842041,623.013977 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M539.156860,741.204163 
	C539.860168,740.874268 540.557678,740.882080 541.603088,740.922852 
	C541.017700,741.151123 540.084412,741.346497 539.156860,741.204163 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.931519,342.395630 
	C316.281860,342.989227 316.502258,343.937836 316.399963,344.925262 
	C315.985352,344.226257 315.893463,343.488464 315.931519,342.395630 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M764.527344,544.205200 
	C764.777100,544.172180 764.833496,544.338562 764.952271,544.761108 
	C764.709839,545.859741 764.405090,546.702209 763.794434,547.693298 
	C763.770386,546.696167 764.052185,545.550476 764.527344,544.205200 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M543.366821,739.935974 
	C543.445984,738.782043 543.878052,737.556274 544.560242,736.121338 
	C544.446838,737.229553 544.083313,738.546814 543.366821,739.935974 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M506.526978,644.892578 
	C506.743866,645.741516 506.715088,646.693176 506.477020,648.076294 
	C506.272278,647.336914 506.276794,646.166077 506.526978,644.892578 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M638.794128,719.641052 
	C639.018738,720.231995 639.021423,720.643555 639.036804,721.358765 
	C638.416809,721.366455 637.784180,721.070557 637.151489,720.774658 
	C637.625061,720.336975 638.098694,719.899353 638.794128,719.641052 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M280.297729,735.823547 
	C280.180054,734.831055 280.315186,733.657410 280.700378,732.232117 
	C280.817169,733.201111 280.683868,734.421753 280.297729,735.823547 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M253.031540,737.321899 
	C253.663330,737.774902 254.284851,738.581482 254.918976,739.710815 
	C254.294800,739.247620 253.658035,738.461487 253.031540,737.321899 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M506.987305,263.965363 
	C507.215881,263.580750 507.444458,263.196106 507.786896,262.621002 
	C508.422211,262.390869 508.943604,262.351196 509.805481,262.516418 
	C509.910339,263.088562 509.674744,263.455780 508.895264,263.910919 
	C507.896637,263.987671 507.441986,263.976532 506.987305,263.965363 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M264.763672,597.930847 
	C264.049683,598.001099 263.139099,597.799011 262.104614,597.327515 
	C262.842865,597.258240 263.704987,597.458374 264.763672,597.930847 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M175.504654,721.027283 
	C175.436203,721.887451 175.037842,722.830444 174.357529,723.933350 
	C174.441986,723.098877 174.808365,722.104431 175.504654,721.027283 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M808.485168,723.028992 
	C808.420288,723.883667 808.029236,724.825012 807.357178,725.929688 
	C807.437134,725.100586 807.798096,724.108154 808.485168,723.028992 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M799.366882,734.261230 
	C799.980652,734.687134 800.508362,735.416687 801.064758,736.446167 
	C800.489258,736.019043 799.885071,735.291870 799.366882,734.261230 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M541.381104,621.234619 
	C541.951904,620.772095 542.742615,620.460754 543.842896,620.300049 
	C543.301941,620.762451 542.451477,621.074097 541.381104,621.234619 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M224.240479,535.290588 
	C224.825027,535.687012 225.433090,536.378296 226.080917,537.379639 
	C225.501785,536.988281 224.882889,536.286926 224.240479,535.290588 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M178.528351,714.032166 
	C178.425171,714.875549 177.989182,715.784302 177.282715,716.858521 
	C177.406677,716.048523 177.801117,715.073059 178.528351,714.032166 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M796.966797,714.698792 
	C796.556396,715.027161 796.107422,715.057678 795.323059,715.043335 
	C795.224976,714.483154 795.462219,713.967896 795.699463,713.452637 
	C796.109009,713.768738 796.518555,714.084839 796.966797,714.698792 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M208.858871,715.806702 
	C208.443054,716.224365 207.872726,716.399780 207.125183,716.395142 
	C207.533432,715.998291 208.118912,715.781433 208.858871,715.806702 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M290.135132,715.884033 
	C289.569275,715.571899 289.274048,715.104980 288.944763,714.354004 
	C289.373688,714.048767 289.836670,714.027649 290.646790,714.011841 
	C290.797852,714.587830 290.601807,715.158508 290.135132,715.884033 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M595.170349,646.810913 
	C594.844360,646.402771 594.692261,645.851562 594.761230,645.176514 
	C595.102966,645.591064 595.223572,646.129456 595.170349,646.810913 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M529.158325,622.932251 
	C529.579590,622.498718 530.096008,622.292725 530.865845,622.261108 
	C530.497314,622.676880 529.875366,622.918274 529.158325,622.932251 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M793.056274,749.306885 
	C793.677979,749.407104 794.333374,749.844360 795.080750,750.587769 
	C794.478455,750.477295 793.784180,750.060608 793.056274,749.306885 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M816.832825,727.122498 
	C817.241455,727.552795 817.405762,728.133545 817.363342,728.880127 
	C816.967285,728.454895 816.777893,727.863953 816.832825,727.122498 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M537.871399,738.192261 
	C538.357483,738.523926 538.654053,739.030579 538.929993,739.851013 
	C538.500183,739.565613 538.091003,738.966370 537.871399,738.192261 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M283.068420,719.167358 
	C283.657654,719.368591 284.252502,719.888123 284.781006,720.731812 
	C284.167786,720.532532 283.620941,720.009094 283.068420,719.167358 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M785.313049,740.954163 
	C785.446106,740.355713 785.899414,739.715942 786.680054,739.043823 
	C786.549377,739.645325 786.091309,740.279053 785.313049,740.954163 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M254.456604,721.025513 
	C254.429794,721.650574 254.090408,722.347778 253.444611,723.148560 
	C253.473495,722.534058 253.808762,721.815857 254.456604,721.025513 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M595.121399,662.744019 
	C595.070679,662.271851 595.200928,661.589783 595.618042,660.943970 
	C595.704041,661.498169 595.503235,662.016174 595.121399,662.744019 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M252.668854,724.017944 
	C252.831451,724.553223 252.699463,725.122742 252.323700,725.866028 
	C252.178024,725.377258 252.276154,724.714661 252.668854,724.017944 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M350.792725,641.001221 
	C350.439728,641.285339 349.906982,641.468994 349.226074,641.456787 
	C349.589630,641.140930 350.101288,641.020874 350.792725,641.001221 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M237.652466,626.959229 
	C237.743866,627.515198 237.597946,628.076599 237.191467,628.795410 
	C237.092300,628.290161 237.253723,627.627441 237.652466,626.959229 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M389.872009,625.002625 
	C389.173584,624.646179 388.729065,624.211426 388.319916,623.517212 
	C388.876984,623.320618 389.398682,623.383423 390.214630,623.714355 
	C390.381226,624.296448 390.253571,624.610413 389.872009,625.002625 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M455.913025,738.693604 
	C455.355774,738.597778 454.707916,738.205383 453.995026,737.515564 
	C454.560791,737.611145 455.191589,738.004089 455.913025,738.693604 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M281.196747,729.877686 
	C280.815796,729.444214 280.671082,728.865723 280.745178,728.134583 
	C281.120300,728.565491 281.276642,729.149048 281.196747,729.877686 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M342.054260,742.242065 
	C342.585876,742.139648 343.125824,742.336243 343.831116,742.776123 
	C343.351807,742.859924 342.707214,742.700500 342.054260,742.242065 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M585.168396,732.878418 
	C584.803711,732.442932 584.677185,731.866516 584.762573,731.141663 
	C585.118469,731.574646 585.262512,732.156006 585.168396,732.878418 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M249.426971,719.115479 
	C249.569214,718.534485 249.998276,717.824768 250.778229,717.095703 
	C250.657349,717.713196 250.185577,718.349976 249.426971,719.115479 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M550.993774,714.588989 
	C550.576843,714.887268 550.107056,714.905884 549.289124,714.860352 
	C549.607605,714.633850 550.274353,714.471558 550.993774,714.588989 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M317.011292,512.610535 
	C317.005676,512.963623 316.520386,513.011902 316.278564,513.042969 
	C315.985931,511.977966 315.935150,510.881836 316.036163,509.401489 
	C316.464294,510.097351 316.740601,511.177399 317.011292,512.610535 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M315.968750,449.338928 
	C316.227264,448.955933 316.395355,448.975281 316.817200,449.015656 
	C316.939575,450.181854 316.808167,451.327026 316.439880,452.724121 
	C316.094788,451.897797 315.986542,450.819489 315.968750,449.338928 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M757.431274,549.163452 
	C757.978882,549.369263 758.478149,549.868713 758.995728,550.653687 
	C758.470337,550.445190 757.926636,549.951172 757.431274,549.163452 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M435.775635,262.167236 
	C435.381500,262.506775 434.882721,262.627228 434.255920,262.573364 
	C434.642242,262.248718 435.156616,262.098419 435.775635,262.167236 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M471.267639,624.185181 
	C471.000305,623.941162 471.023132,623.692627 471.092285,622.854004 
	C471.323792,622.020874 471.558258,621.842468 471.841949,621.728638 
	C472.199310,622.087036 472.556702,622.445435 473.003693,623.179688 
	C472.581451,623.763916 472.069641,623.972229 471.267639,624.185181 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M565.319702,616.138428 
	C565.636841,615.658691 566.252075,615.256897 566.990234,615.140991 
	C566.614746,615.690063 566.116211,615.953186 565.319702,616.138428 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M443.778076,262.422211 
	C443.411804,262.742981 442.689423,262.891418 441.929260,262.769287 
	C442.401642,262.415771 442.911804,262.332825 443.778076,262.422211 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M366.725677,262.416382 
	C366.408997,262.713623 365.730408,262.885193 364.972687,262.833496 
	C365.383636,262.503754 365.873688,262.397278 366.725677,262.416382 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M562.213989,742.235840 
	C562.841858,742.157715 563.708252,742.304443 564.784119,742.708862 
	C564.146606,742.797913 563.299500,742.629333 562.213989,742.235840 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M640.964600,731.252563 
	C641.733765,731.057983 642.447754,731.110291 643.161743,731.162537 
	C643.168884,731.340393 643.176025,731.518188 643.183167,731.695984 
	C642.657288,731.794800 642.131409,731.893616 641.301514,731.994995 
	C640.968201,731.831543 640.938782,731.665527 640.964600,731.252563 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M586.422363,609.994629 
	C586.793213,609.559265 587.476990,609.163635 588.476685,608.794861 
	C588.106812,609.225952 587.421082,609.630127 586.422363,609.994629 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M321.705048,262.811340 
	C321.908203,263.628296 321.908203,264.374054 321.908203,265.119812 
	C321.718445,265.132599 321.528687,265.145355 321.338959,265.158142 
	C321.236145,264.615479 321.133331,264.072845 321.067261,263.217346 
	C321.104034,262.904541 321.501892,262.740173 321.705048,262.811340 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.855042,350.418579 
	C316.120148,350.909454 316.269348,351.747894 316.182190,352.757935 
	C315.876923,352.208405 315.808044,351.487305 315.855042,350.418579 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M769.012695,535.072876 
	C769.369263,535.465393 769.725769,535.857849 769.992371,536.467163 
	C769.762634,536.775269 769.622864,536.866577 769.239014,537.014404 
	C768.994995,537.070923 769.101440,536.933167 769.070251,536.568298 
	C769.030273,535.826599 769.021484,535.449707 769.012695,535.072876 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M234.091797,730.296997 
	C234.624359,730.591492 235.189743,731.201599 235.763824,732.141907 
	C235.223236,731.852234 234.673935,731.232422 234.091797,730.296997 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M316.308472,602.614197 
	C316.362366,602.909302 316.130310,603.044312 316.001038,603.077271 
	C315.779633,602.600647 315.687561,602.090942 315.773315,601.377930 
	C316.052307,601.556030 316.153442,601.937561 316.308472,602.614197 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M440.977234,743.295654 
	C441.229279,743.101379 441.696442,743.050415 442.513428,742.987549 
	C442.306274,743.130066 441.749329,743.284485 440.977234,743.295654 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.893005,337.372314 
	C316.142853,337.605927 316.277893,338.141266 316.198608,338.803040 
	C315.915558,338.510956 315.846893,338.092499 315.893005,337.372314 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M281.854065,717.194641 
	C282.163666,717.465149 282.208618,717.854004 282.323090,718.528076 
	C282.124878,718.313171 281.857147,717.813049 281.854065,717.194641 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M245.574829,440.802979 
	C245.336578,440.727936 245.206604,440.350494 245.270432,439.934875 
	C245.537186,440.098022 245.610153,440.299316 245.574829,440.802979 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M449.875732,262.538208 
	C449.936615,263.028137 449.687958,263.340820 449.439270,263.653503 
	C449.259033,263.428009 449.078796,263.202515 448.885254,262.751129 
	C449.103394,262.470459 449.334778,262.415710 449.875732,262.538208 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M589.547363,731.067505 
	C589.808228,731.366516 589.910767,731.866455 589.893555,732.735962 
	C589.645630,732.493164 589.517334,731.880798 589.547363,731.067505 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M477.190399,624.211792 
	C477.496582,623.898621 477.923248,623.805908 478.653381,623.854126 
	C478.408203,624.140747 477.859558,624.286560 477.190399,624.211792 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M502.960327,262.359253 
	C502.964233,262.868591 502.791260,263.232422 502.368835,263.764893 
	C502.048492,263.587677 501.977631,263.241821 501.916138,262.631226 
	C502.177734,262.125061 502.463715,262.074188 502.960327,262.359253 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M370.265625,729.896240 
	C370.467865,729.613770 370.966827,729.322815 371.773865,729.053467 
	C371.575409,729.346069 371.068909,729.616882 370.265625,729.896240 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M317.408264,264.597046 
	C317.406586,264.994751 317.114227,265.452698 316.524323,265.947266 
	C316.523712,265.541687 316.820648,265.099487 317.408264,264.597046 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M389.490143,642.164062 
	C389.535675,641.985779 389.581238,641.807556 389.804382,641.420654 
	C390.525421,641.367004 391.068909,641.522095 391.778870,641.915283 
	C391.945312,642.153503 392.131836,642.445068 391.665771,642.546875 
	C390.629852,642.487183 390.059998,642.325623 389.490143,642.164062 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.951904,289.324463 
	C316.065979,288.995361 316.491882,289.015717 316.724182,289.329712 
	C316.986145,290.070679 317.015808,290.497711 317.045471,290.924713 
	C317.045471,290.924713 316.603912,290.994415 316.380615,290.987854 
	C316.050781,290.538757 315.944305,290.096161 315.951904,289.324463 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M544.246704,681.735901 
	C543.670715,682.056519 543.216309,682.133667 542.416382,682.217041 
	C542.536926,681.836365 543.003113,681.449646 543.789673,681.038086 
	C544.196228,681.173096 544.282349,681.332764 544.246704,681.735901 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M226.362946,538.259888 
	C226.589523,538.461792 226.805786,538.935486 227.022614,539.679199 
	C226.799637,539.476685 226.576126,539.004211 226.362946,538.259888 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M794.756836,739.988159 
	C794.800842,740.401489 794.606018,740.827576 794.411194,741.253601 
	C794.293762,741.013428 794.176270,740.773193 794.049316,740.276245 
	C794.039856,740.019409 794.517944,740.000916 794.756836,739.988159 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M759.201294,449.205719 
	C759.534973,449.372406 759.820435,449.835144 760.108765,450.582336 
	C759.792175,450.411743 759.472595,449.956757 759.201294,449.205719 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M390.183105,636.698730 
	C389.920624,636.440369 389.844330,636.075684 389.882568,635.451904 
	C390.121155,635.659302 390.245270,636.125793 390.183105,636.698730 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M698.138184,729.176941 
	C698.329102,729.005188 698.596069,729.128662 698.937988,729.544556 
	C698.742737,729.725708 698.476440,729.604065 698.138184,729.176941 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M363.274902,740.145508 
	C363.483643,740.025696 363.694397,740.177307 363.970184,740.547363 
	C363.741882,740.768005 363.489105,740.651672 363.274902,740.145508 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M319.087402,744.194641 
	C319.162903,743.999390 319.329254,743.972168 319.747437,743.967712 
	C319.725586,744.114563 319.451904,744.238647 319.087402,744.194641 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M457.895752,740.780762 
	C457.718323,740.824951 457.446869,740.641663 457.096191,740.218994 
	C457.339447,740.087463 457.601044,740.278748 457.895752,740.780762 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M251.726639,729.072876 
	C251.890244,729.160645 251.907181,729.332886 251.828186,729.746582 
	C251.620743,729.804688 251.487274,729.630005 251.410889,729.433105 
	C251.387329,729.372192 251.519241,729.250977 251.726639,729.072876 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M641.018555,740.245911 
	C641.234070,740.186401 641.414429,740.353638 641.555115,740.549316 
	C641.575989,740.578369 641.410583,740.741394 641.145813,740.902832 
	C640.960266,740.963318 640.970520,740.481873 641.018555,740.245911 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M233.575775,729.306519 
	C233.758560,729.284668 233.866684,729.451416 233.983475,729.787781 
	C233.790588,729.857666 233.626907,729.703552 233.575775,729.306519 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M524.716431,262.803955 
	C524.649780,263.030975 524.399048,263.178192 524.150696,263.090759 
	C524.153137,262.856110 524.532227,262.724091 524.716431,262.803955 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M166.735718,741.090088 
	C166.892227,741.317932 166.726852,741.589966 166.222702,741.884888 
	C166.088104,741.660095 166.264755,741.402283 166.735718,741.090088 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M200.896667,741.620178 
	C200.687363,741.806885 200.419083,741.675903 200.108215,741.207764 
	C200.304855,741.063782 200.562210,741.207764 200.896667,741.620178 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M584.933228,728.795410 
	C584.687439,728.915161 584.446045,728.738159 584.284973,728.235962 
	C584.544922,728.145447 584.735718,728.341431 584.933228,728.795410 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.953918,270.267639 
	C316.212372,270.201263 316.387939,270.342987 316.519257,270.517914 
	C316.547302,270.555267 316.414734,270.713196 316.189270,270.877747 
	C316.022339,270.939941 315.855194,270.487610 315.953918,270.267639 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M352.107300,733.326721 
	C352.365295,733.099365 352.636719,733.240112 352.876953,733.785400 
	C352.638977,733.932800 352.397247,733.767761 352.107300,733.326721 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M284.563232,737.126709 
	C284.732178,737.180359 284.789795,737.339294 284.784241,737.743774 
	C284.406494,737.803040 284.316772,737.550598 284.563232,737.126709 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M170.638382,733.105835 
	C170.822968,733.316406 170.690460,733.590576 170.220093,733.908936 
	C170.068420,733.704285 170.214767,733.443054 170.638382,733.105835 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M594.353882,732.986694 
	C594.160522,732.916992 594.108398,732.716187 594.104614,732.207397 
	C594.267029,732.218079 594.381042,732.536804 594.353882,732.986694 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M644.123901,733.278687 
	C644.186096,733.054382 644.368347,733.043518 644.825439,733.032471 
	C645.100159,733.032288 645.168823,733.518799 645.118591,733.759277 
	C644.733582,733.927979 644.458801,733.758789 644.123901,733.278687 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M192.522156,732.874695 
	C192.426163,732.965637 192.324005,732.510071 192.411865,732.294128 
	C192.718369,732.289917 192.757843,732.525146 192.522156,732.874695 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M302.108185,744.280640 
	C302.173950,744.097290 302.337433,744.060181 302.750824,744.050781 
	C302.735748,744.194641 302.470825,744.310852 302.108185,744.280640 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M495.184052,742.765137 
	C495.080353,742.505432 495.288239,742.286926 495.790527,742.073242 
	C495.926483,742.341187 495.730042,742.583984 495.184052,742.765137 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M377.912964,667.609375 
	C378.054291,667.555725 378.047577,667.485718 378.052582,667.451294 
	C378.057587,667.416931 377.658356,667.386658 377.658356,667.386658 
	C377.658356,667.386658 377.771637,667.663086 377.912964,667.609375 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M300.510132,673.859619 
	C300.341919,673.839661 300.096924,673.629517 299.960693,673.178528 
	C300.240967,673.156616 300.362274,673.400574 300.510132,673.859619 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M594.312744,710.019775 
	C594.122131,709.956909 594.078125,709.754150 594.043884,709.239746 
	C594.188965,709.245300 594.324219,709.562561 594.312744,710.019775 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M220.527328,469.179077 
	C220.753265,469.101105 220.893127,469.247772 221.049744,469.574280 
	C220.799835,469.772827 220.591415,469.656006 220.527328,469.179077 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M547.403137,619.647522 
	C547.393555,619.388855 547.669739,619.248901 548.211426,619.124023 
	C548.181885,619.284241 547.919189,619.493347 547.403137,619.647522 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M408.103394,624.251282 
	C408.192566,624.022583 408.351318,623.958008 408.748779,623.986755 
	C408.716003,624.191833 408.444489,624.303650 408.103394,624.251282 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M508.109924,624.214172 
	C508.200928,624.003723 508.359619,623.951599 508.746063,624.001465 
	C508.708405,624.193054 508.442993,624.282776 508.109924,624.214172 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M377.926025,635.636108 
	C378.077637,635.582520 378.070953,635.505371 378.076233,635.467407 
	C378.081543,635.429382 377.660553,635.388000 377.660553,635.388000 
	C377.660553,635.388000 377.774414,635.689697 377.926025,635.636108 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M775.806641,465.849182 
	C775.803223,466.100464 775.411560,466.063324 775.220215,466.018585 
	C775.025452,465.803711 775.022156,465.633545 775.051758,465.210266 
	C775.326477,465.170746 775.568237,465.384338 775.806641,465.849182 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M237.190887,575.156189 
	C237.393173,574.984253 237.627975,575.099915 237.928894,575.460693 
	C238.034866,575.625122 237.599533,575.639893 237.599533,575.639893 
	C237.599533,575.639893 237.263229,575.363037 237.190887,575.156189 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M315.605621,561.880981 
	C315.908020,561.992065 315.920044,562.294434 315.674622,562.792480 
	C315.297180,562.701843 315.263184,562.396484 315.605621,561.880981 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M316.106323,551.334045 
	C316.119049,551.136169 316.549133,551.312012 316.549133,551.312012 
	C316.549133,551.312012 316.563446,551.750061 316.419189,551.817261 
	C316.274902,551.884521 316.093567,551.531921 316.106323,551.334045 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M731.146729,548.921814 
	C731.013306,549.006287 730.825073,548.740234 730.800293,548.580200 
	C730.775574,548.420227 731.203979,548.431274 731.203979,548.431274 
	C731.203979,548.431274 731.280212,548.837341 731.146729,548.921814 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M730.632263,547.891663 
	C730.331604,548.004211 730.130615,547.801453 729.973877,547.304993 
	C730.293457,547.131226 730.531311,547.319580 730.632263,547.891663 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M738.873901,539.530762 
	C739.024902,539.596863 738.792847,539.873474 738.727295,539.930664 
	C738.661804,539.987915 738.499023,539.703247 738.499023,539.703247 
	C738.499023,539.703247 738.722961,539.464722 738.873901,539.530762 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.936890,385.273773 
	C316.181946,385.206543 316.351868,385.344238 316.476837,385.514862 
	C316.505219,385.553619 316.377716,385.706573 316.160706,385.871155 
	C315.999451,385.935303 315.845520,385.490601 315.936890,385.273773 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.941589,377.284424 
	C316.144775,377.307129 316.265045,377.555847 316.222687,377.876160 
	C316.060059,377.947723 315.858704,377.510406 315.941589,377.284424 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M316.022095,369.268829 
	C316.218018,369.296417 316.324493,369.552704 316.271698,369.881775 
	C316.112457,369.954559 315.932617,369.497528 316.022095,369.268829 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M812.419312,720.880920 
	C812.201904,720.674561 812.313965,720.404114 812.787842,720.095825 
	C812.937134,720.287170 812.803589,720.540100 812.419312,720.880920 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M315.894409,365.286957 
	C316.096527,365.308441 316.222717,365.558655 316.184326,365.881348 
	C316.019714,365.953796 315.818481,365.515717 315.894409,365.286957 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M448.726562,722.031738 
	C448.930023,722.217834 448.828613,722.514832 448.421631,722.916382 
	C448.187042,722.716858 448.288940,722.424072 448.726562,722.031738 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M589.667847,724.003540 
	C589.856934,724.091919 589.890503,724.303223 589.844910,724.826477 
	C589.630615,724.922546 589.468689,724.717651 589.374329,724.485168 
	C589.339172,724.398621 589.461792,724.247925 589.667847,724.003540 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M238.493134,726.746643 
	C238.313080,726.800720 238.189987,726.663940 238.024445,726.383179 
	C238.221939,726.232483 238.411316,726.338013 238.493134,726.746643 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M284.522766,727.128174 
	C284.625519,727.028137 284.777008,727.499756 284.697754,727.734314 
	C284.351135,727.766113 284.284943,727.519226 284.522766,727.128174 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M315.779907,465.169861 
	C315.920807,465.125610 316.058197,465.506744 315.989929,465.695068 
	C315.591095,465.781006 315.496918,465.557922 315.779907,465.169861 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M254.380035,459.829468 
	C254.339127,459.848022 254.384781,459.803497 254.403290,459.822083 
	C254.421783,459.840668 254.420929,459.810883 254.380035,459.829468 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M245.062622,717.234924 
	C245.218246,717.066284 245.479523,717.157104 245.855377,717.482239 
	C246.001068,717.627258 245.568176,717.631897 245.568176,717.631897 
	C245.568176,717.631897 245.199387,717.405029 245.062622,717.234924 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M257.826233,717.124939 
	C257.977783,717.391235 257.777130,717.647156 257.228333,717.901001 
	C257.192200,717.726929 257.390167,717.465454 257.826233,717.124939 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M663.892273,717.867310 
	C663.967407,718.023621 663.572754,718.075867 663.375244,718.086182 
	C663.390869,717.968079 663.604004,717.839600 663.892273,717.867310 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M256.761536,718.112549 
	C256.826996,718.288696 256.665466,718.582886 256.275452,718.992615 
	C256.209473,718.815613 256.372009,718.523071 256.761536,718.112549 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M649.991943,334.012207 
	C658.560120,344.045135 663.064880,355.035217 660.604187,359.902222 
	C665.930359,362.733398 668.761475,371.149872 666.056946,376.996155 
	C666.062805,378.401093 665.869202,379.436218 666.201111,379.648041 
	C669.348633,381.656708 669.316345,383.829651 667.403198,386.862946 
	C666.139343,388.866821 665.821960,391.467743 664.717346,393.894043 
	C662.111450,395.480347 658.236328,395.337372 658.067993,399.922943 
	C661.182617,398.781372 663.595642,397.896912 666.008667,397.012451 
	C667.684631,400.723236 669.360596,404.434021 670.695923,408.418060 
	C668.067383,407.544983 666.849854,407.921722 665.532349,410.530609 
	C663.706421,414.146423 665.675049,416.399902 667.413696,419.215240 
	C666.323975,419.965149 665.049072,420.512360 662.912109,421.429565 
	C669.822693,422.557800 663.134949,424.081818 663.853088,425.852844 
	C664.867249,425.923248 665.969238,425.999725 667.197754,426.366333 
	C668.213806,427.434906 669.103210,428.213379 669.995850,428.995483 
	C669.999023,428.999084 670.007324,429.007324 669.983765,429.327087 
	C669.315430,430.431885 668.670593,431.216980 667.725342,431.885193 
	C665.651123,432.632874 663.877380,433.497375 662.103638,434.361877 
	C662.356384,434.868591 662.609192,435.375275 662.861938,435.881958 
	C664.633850,435.234131 666.405701,434.586304 668.544312,433.959656 
	C670.429871,433.827148 671.948730,433.673462 674.751953,433.389832 
	C672.277405,435.382660 670.795044,436.576447 669.527832,437.596954 
	C670.023926,439.627380 670.469055,441.449219 670.861938,443.057037 
	C668.780090,442.494873 667.276733,442.088928 665.836670,441.700073 
	C666.786316,444.901276 667.394653,446.952026 667.662964,449.052673 
	C666.519287,450.433624 665.715698,451.764618 664.599670,453.063721 
	C663.867981,453.004059 663.448608,452.976288 663.028809,452.717224 
	C663.028259,452.485931 663.020752,452.023407 663.162231,451.691833 
	C663.915222,449.521332 664.526733,447.682404 665.300415,445.355988 
	C662.264526,446.889587 660.199097,447.932983 658.123047,448.604309 
	C658.105713,447.487823 658.099060,446.743378 658.421448,445.956238 
	C659.378357,444.768280 660.006104,443.623016 660.633911,442.477783 
	C659.116577,441.971436 657.599304,441.465088 656.052917,440.963745 
	C656.023865,440.968781 656.060242,440.922394 656.073730,440.651367 
	C657.268921,439.260712 658.450623,438.141083 659.632324,437.021454 
	C656.296570,433.872223 660.271118,431.887634 660.821838,429.347717 
	C661.365234,426.842010 661.693604,424.289673 662.116272,421.757782 
	C661.724060,421.692474 661.331787,421.627167 660.939575,421.561859 
	C660.250854,422.561890 659.562134,423.561920 659.622192,423.474701 
	C657.385864,422.055939 655.753296,421.020203 654.105103,419.648956 
	C654.101196,418.865814 654.112976,418.418243 654.451782,417.830475 
	C656.188110,417.457855 657.597351,417.225403 659.030701,417.280090 
	C659.364258,417.889374 659.673767,418.211487 659.983215,418.533600 
	C660.168640,418.323395 660.354004,418.113159 660.539429,417.902954 
	C660.024048,417.604095 659.508667,417.305206 658.949219,416.639801 
	C658.596191,414.010315 658.329163,411.740540 657.963684,409.486755 
	C657.713989,407.947083 657.326965,406.429657 656.882812,404.635376 
	C655.316345,402.864807 659.590881,399.453888 654.925842,398.800110 
	C654.874817,398.594788 654.836426,398.173370 655.023682,397.810791 
	C656.067017,393.703156 657.022522,389.975372 657.679626,386.195740 
	C657.786804,385.579163 656.576355,384.733490 655.968506,383.652771 
	C654.750061,382.603668 653.537964,381.895508 651.833923,380.899933 
	C653.585449,378.663849 655.507263,376.210388 657.477966,373.694489 
	C658.116821,373.967651 659.170410,374.418152 660.223938,374.868622 
	C660.543701,374.368317 660.863464,373.868011 661.183228,373.367706 
	C658.457458,371.594940 655.860657,369.512177 652.908325,368.284332 
	C652.235657,368.004639 650.333679,370.680817 648.664673,371.994965 
	C647.887878,371.994781 647.443909,371.996155 647.003174,371.668335 
	C646.987915,370.901001 646.969604,370.462830 646.993103,369.656525 
	C648.020508,367.187805 649.006104,365.087250 650.343384,362.942017 
	C655.857788,359.228638 653.249207,353.714386 654.138611,348.943665 
	C654.188354,348.928162 654.095642,348.966064 653.618896,349.001007 
	C652.243164,348.609985 650.917969,348.499939 650.320801,347.783264 
	C648.292175,345.348724 644.791138,345.426331 642.637695,348.067322 
	C641.904785,346.991882 641.513123,346.005554 641.351257,344.847107 
	C641.730347,344.131866 641.879700,343.588715 642.014160,343.025024 
	C641.999268,343.004456 642.035522,343.031555 642.385742,342.958893 
	C645.154663,339.928223 647.573303,336.970215 649.991943,334.012207 
M664.696899,383.097626 
	C664.804443,383.070282 664.911987,383.042908 665.301453,382.897430 
	C665.195740,382.925079 665.090027,382.952728 664.696899,383.097626 
M663.132080,381.683380 
	C663.507446,380.915802 663.882751,380.148224 664.489014,378.908417 
	C662.070557,379.054260 660.249573,379.164062 658.428589,379.273865 
	C658.327820,379.832306 658.227051,380.390747 658.126282,380.949188 
	C659.673767,381.385254 661.221252,381.821320 663.132080,381.683380 
M664.462830,429.524841 
	C664.213623,429.463959 663.964355,429.403046 663.715149,429.342133 
	C663.876953,429.498566 664.038696,429.654999 664.462830,429.524841 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M633.893433,571.892395 
	C632.456970,570.977112 631.020447,570.061768 628.968628,568.754395 
	C630.489624,568.159729 631.428040,567.792908 632.820251,567.248535 
	C631.716248,566.215393 630.761536,565.322021 629.806885,564.428650 
	C630.295288,564.543701 630.783630,564.658691 631.272034,564.773743 
	C632.181946,561.791077 633.091858,558.808411 634.123657,555.426025 
	C632.479431,555.254211 631.529785,555.154907 630.394592,554.742432 
	C632.319214,554.429260 634.429382,554.429260 636.539551,554.429260 
	C636.649841,554.937256 636.760132,555.445251 636.870422,555.953186 
	C637.580261,554.970459 638.290161,553.987671 639.029907,553.294067 
	C639.490967,553.889526 639.922119,554.195862 640.353210,554.502136 
	C640.230164,553.999878 640.107117,553.497620 639.971619,552.674683 
	C639.951477,551.926270 639.943787,551.498474 639.955261,550.717957 
	C639.635498,549.734375 639.296570,549.103577 638.957642,548.472778 
	C638.624146,549.290405 638.290588,550.108032 637.570068,550.935669 
	C633.153992,549.112061 632.911560,547.521606 635.928589,543.532288 
	C637.660706,544.031067 639.323303,544.509766 641.403809,544.997070 
	C643.805359,544.698242 645.788879,544.390686 647.772461,544.083191 
	C647.700378,543.599304 647.628296,543.115479 647.556213,542.631592 
	C646.196899,542.152100 644.837585,541.672607 642.102051,540.707703 
	C647.057617,540.065918 650.506897,539.619263 653.956238,539.172546 
	C653.846130,538.704651 653.736023,538.236816 653.625854,537.768921 
	C651.749756,537.511780 649.873657,537.254639 647.998779,536.998779 
	C648.000000,537.000000 648.002441,537.002441 648.166504,536.688232 
	C651.342407,535.593079 654.354187,534.812195 657.552795,533.982849 
	C657.139038,529.150635 653.308472,532.544067 650.592590,532.866272 
	C650.512634,532.298889 650.432678,531.731506 650.352722,531.164124 
	C652.558655,530.456787 654.764587,529.749451 657.739380,528.795593 
	C660.060974,524.995911 658.771179,520.517517 654.043945,518.634460 
	C653.972534,517.884216 653.944031,517.454163 654.263062,516.845459 
	C655.088257,512.448242 655.565918,508.229614 656.018433,503.667603 
	C655.993652,502.865448 655.994019,502.406677 656.308105,501.826416 
	C657.655762,501.858002 658.696045,501.977509 659.722351,502.170868 
	C662.201660,502.637970 664.674438,503.139557 667.149841,503.627197 
	C667.451477,503.004211 667.753113,502.381226 668.054749,501.758209 
	C665.972473,499.493469 663.890198,497.228729 661.952209,494.728943 
	C662.387573,494.273956 662.678650,494.054016 663.292236,493.845520 
	C663.991333,493.222382 664.367859,492.587830 664.790039,491.666260 
	C664.875244,491.189667 664.914917,491.000031 665.322388,490.870483 
	C666.963135,490.426697 668.236084,489.922852 669.509094,489.418976 
	C669.326660,488.983612 669.144165,488.548248 668.961670,488.112885 
	C667.625916,488.478180 666.290100,488.843445 664.719604,489.001343 
	C664.368958,488.195374 664.252930,487.596741 664.375854,486.989929 
	C664.614868,486.981689 665.089844,486.926514 665.281494,486.822205 
	C665.718628,486.538330 665.882629,486.302216 666.244080,485.829041 
	C667.004822,484.419342 667.486694,483.190125 667.968628,481.960938 
	C668.063293,481.167786 668.157959,480.374634 668.443176,477.984619 
	C670.712463,481.487335 673.882935,484.261414 673.423340,486.153931 
	C672.679443,489.217133 671.901550,493.984253 666.520813,493.547394 
	C666.376465,493.962555 666.232117,494.377747 666.087708,494.792938 
	C667.686951,495.486603 669.286194,496.180298 671.790588,497.266571 
	C673.651794,501.174255 671.192505,505.722382 669.973572,510.274139 
	C669.675354,511.387848 667.973755,512.187134 666.820801,512.992798 
	C665.730408,513.754639 664.513123,514.334900 663.351685,514.994995 
	C664.724304,515.703003 666.096924,516.411011 667.882812,517.332214 
	C667.838196,517.767151 667.939941,518.785034 667.614319,519.640015 
	C664.416809,528.035706 661.670288,536.546692 655.615173,543.665649 
	C652.270020,547.598511 650.828796,553.101074 647.723511,557.300232 
	C643.855164,562.531311 639.156006,567.148010 634.413452,572.019958 
	C634.011292,572.009888 633.893433,571.892395 633.893433,571.892395 
M662.101929,506.733307 
	C662.102905,506.238495 662.103882,505.743683 662.104919,505.248871 
	C661.556702,505.395325 661.008545,505.541809 660.460327,505.688263 
	C660.805542,506.080292 661.150818,506.472290 662.101929,506.733307 
M641.795532,547.924744 
	C642.130127,548.238220 642.464783,548.551697 642.799377,548.865173 
	C643.070312,548.558411 643.341248,548.251648 643.612183,547.944824 
	C643.137573,547.801392 642.663025,547.657959 641.795532,547.924744 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M576.944702,273.998291 
	C581.235107,275.142029 585.394470,276.458008 586.738403,282.215607 
	C587.605408,280.992371 588.109192,280.281616 588.709106,279.435150 
	C594.513611,282.735016 600.535217,285.746216 606.044617,289.507721 
	C608.048401,290.875793 608.830688,294.033020 610.039612,296.145569 
	C612.108887,296.517059 614.366089,296.922302 616.458252,297.691345 
	C614.870483,299.738342 613.447754,301.421570 612.086426,303.445801 
	C614.375061,306.301880 615.018799,308.300018 610.914917,309.702179 
	C609.709229,310.114166 608.969238,311.889313 607.642212,312.909607 
	C605.204468,311.896118 602.492371,311.553894 601.209595,310.017944 
	C598.436707,306.697906 596.371704,302.806396 591.099243,303.607971 
	C592.000488,302.146362 592.860474,301.010468 594.016296,299.483673 
	C591.169006,299.683777 589.098511,299.829315 587.014038,299.986816 
	C587.000000,299.998779 587.026672,299.977142 587.071167,299.665161 
	C587.659180,298.594635 588.202698,297.836090 588.360718,297.615479 
	C587.803284,296.866241 586.933716,295.748505 586.118225,294.592651 
	C583.024841,290.208069 583.038208,290.207825 580.058716,294.867462 
	C579.802490,295.268127 579.374268,295.558807 578.689087,295.895874 
	C577.903809,295.913727 577.455322,295.936218 577.052124,295.606476 
	C577.812317,293.540833 578.527344,291.827423 579.105957,290.440948 
	C576.606506,290.196716 574.640747,290.004669 572.717651,289.816772 
	C572.573181,285.230652 572.573181,285.230652 568.770020,285.994507 
	C565.921021,284.964050 565.235657,283.809753 567.943604,282.101807 
	C568.775513,281.577087 569.363220,280.665314 570.325073,279.728271 
	C572.706543,277.683228 574.825623,275.840759 576.944702,273.998291 
M600.726440,291.641235 
	C602.791016,292.677582 604.855530,293.713959 606.920105,294.750305 
	C607.192749,294.255371 607.465393,293.760468 607.738037,293.265533 
	C605.368774,291.687256 602.999512,290.108948 600.250732,288.277832 
	C600.250732,289.546692 600.250732,290.328247 600.726440,291.641235 
M603.717896,308.601654 
	C604.020142,308.379242 604.322449,308.156830 604.624695,307.934418 
	C604.440857,307.743256 604.256958,307.552063 604.073120,307.360901 
	C603.834778,307.650543 603.596436,307.940216 603.717896,308.601654 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M575.118896,612.563232 
	C574.960205,612.824585 574.498718,612.846252 574.127197,612.646729 
	C573.535217,612.229065 573.274902,612.081055 572.920776,611.653076 
	C569.231079,608.858093 565.595215,606.413208 562.200806,603.702637 
	C565.580688,601.256226 568.719055,599.075378 572.311401,596.579102 
	C572.479187,598.493591 572.579346,599.637268 572.679565,600.780884 
	C573.089539,600.994934 573.499451,601.209045 573.909363,601.423096 
	C574.766052,600.250854 575.619019,599.075928 576.480652,597.907349 
	C577.113220,597.049316 577.757141,596.199646 578.395996,595.346191 
	C578.823059,596.436157 579.432739,597.496338 579.617188,598.625916 
	C579.754883,599.469238 579.343506,600.402161 579.176575,601.295227 
	C579.586609,601.604736 579.996704,601.914307 580.406738,602.223816 
	C580.811218,600.851807 581.215637,599.479797 581.203857,599.519653 
	C584.879150,600.802856 587.336853,601.660889 589.618774,602.457642 
	C589.777832,600.024719 589.876160,598.520569 589.986755,596.683472 
	C590.003479,595.907043 590.007935,595.463562 590.376404,595.013550 
	C591.768188,595.072998 592.796082,595.138916 593.906189,595.210083 
	C593.613281,591.635803 593.352417,588.452576 593.119995,584.946777 
	C593.113953,584.411072 593.079529,584.197937 593.020142,583.989197 
	C592.995117,583.993530 593.002930,584.043579 593.358032,584.055786 
	C594.416443,583.972595 595.119812,583.877136 596.093079,583.946411 
	C596.191711,585.234009 596.020447,586.356750 595.816772,587.691650 
	C598.391724,587.376770 601.037842,587.053223 603.683899,586.729675 
	C603.654602,586.351624 603.625244,585.973511 603.595886,585.595398 
	C601.817566,584.822205 600.039246,584.049072 597.692505,583.028748 
	C602.298035,582.365295 606.043274,581.825745 610.116821,581.238892 
	C609.057556,578.650635 608.510742,577.314575 608.313232,575.927368 
	C609.471497,574.701538 610.280396,573.526917 611.825256,571.283447 
	C611.669617,573.884460 611.591553,575.188965 611.470337,577.214661 
	C613.293091,575.086975 614.589478,573.573792 616.064392,571.852112 
	C614.902832,570.879150 613.986694,570.111755 613.159424,569.418762 
	C615.562195,568.877197 617.772949,568.378906 619.983704,567.880615 
	C620.303101,568.596252 621.062622,569.762939 620.868469,569.952332 
	C619.823425,570.971985 618.541565,571.748901 616.070679,573.520874 
	C621.258667,573.276306 623.981140,581.299072 629.025757,573.315979 
	C629.321289,573.738831 629.616882,574.161621 629.912415,574.584473 
	C629.526367,576.019592 629.607605,577.981750 628.679077,578.804871 
	C622.230164,584.521912 616.906189,591.994812 607.371216,593.170471 
	C606.784729,593.242798 605.804504,593.743958 605.768005,594.119446 
	C605.078308,601.218933 598.611267,601.008667 594.179626,603.102417 
	C589.219421,605.445740 583.942017,607.117493 578.387817,608.723633 
	C577.747925,607.378357 577.522827,606.390808 577.297729,605.403259 
	C576.796326,605.450806 576.294922,605.498413 575.793579,605.546021 
	C575.871216,607.034851 575.948914,608.523682 576.026611,610.012512 
	C575.776978,610.775635 575.527283,611.538757 575.118896,612.563232 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M619.993896,567.565491 
	C617.772949,568.378906 615.562195,568.877197 613.159424,569.418762 
	C613.986694,570.111755 614.902832,570.879150 616.064392,571.852112 
	C614.589478,573.573792 613.293091,575.086975 611.470337,577.214661 
	C611.591553,575.188965 611.669617,573.884460 611.825256,571.283447 
	C610.280396,573.526917 609.471497,574.701538 607.973877,575.893738 
	C606.069031,575.385986 604.852783,574.860779 603.095093,574.101746 
	C604.710938,579.203430 603.158875,580.567322 598.902588,578.499268 
	C598.269348,576.057068 597.600830,574.005737 597.266357,571.945190 
	C598.044312,571.924683 598.488220,571.913391 599.180664,572.042847 
	C599.955566,571.803101 600.481934,571.422485 601.352600,571.042603 
	C602.523315,570.738220 603.349792,570.433044 604.502930,570.131714 
	C605.864929,570.096863 607.326843,570.488037 607.867798,569.951233 
	C611.945435,565.905029 615.918640,561.743408 619.699829,557.421326 
	C620.183044,556.869019 619.370789,555.183350 619.152954,553.687622 
	C619.168945,552.906067 619.190735,552.459717 619.633667,552.022949 
	C622.372803,552.017700 624.690857,552.002930 627.058105,552.125061 
	C627.186584,552.314148 627.265869,552.366455 627.345215,552.418701 
	C627.226196,552.282043 627.107239,552.145447 627.182312,551.709106 
	C628.591431,546.957214 629.806458,542.504944 631.359985,537.994263 
	C633.584778,535.411194 638.037048,534.095947 635.707214,529.748230 
	C637.265808,528.782349 638.780090,527.843872 640.639404,526.870728 
	C642.703308,526.195374 644.422302,525.554688 646.669922,524.716858 
	C644.790588,528.764893 642.977234,531.627441 642.144165,534.751404 
	C641.268982,538.033386 641.332520,541.565613 640.985840,544.988525 
	C639.323303,544.509766 637.660706,544.031067 635.928589,543.532288 
	C632.911560,547.521606 633.153992,549.112061 637.900146,550.968140 
	C639.056885,551.017273 639.496521,551.043945 639.936096,551.070679 
	C639.943787,551.498474 639.951477,551.926270 639.725647,552.670776 
	C639.492065,552.987427 639.000000,553.004883 639.000000,553.004883 
	C638.290161,553.987671 637.580261,554.970459 636.870422,555.953186 
	C636.760132,555.445251 636.649841,554.937256 636.539551,554.429260 
	C634.429382,554.429260 632.319214,554.429260 630.005798,554.758911 
	C628.898743,558.458191 627.277954,560.586975 623.250061,560.817810 
	C619.312439,561.043457 621.346497,565.195190 619.993896,567.565491 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M506.663635,264.150146 
	C507.441986,263.976532 507.896637,263.987671 508.692719,264.011078 
	C508.523621,265.701141 508.013123,267.378967 507.415466,269.343231 
	C508.167572,270.521912 509.100494,271.983917 510.297089,273.859222 
	C510.855164,269.663605 513.271179,270.165924 515.843140,271.766296 
	C515.822571,272.074554 515.906555,272.031097 515.990479,271.987640 
	C517.408325,271.715607 518.844788,271.158112 520.239075,271.248779 
	C521.749878,271.347015 523.251587,271.969910 524.698608,272.518646 
	C525.548950,272.841125 526.285095,273.464539 527.072632,273.952637 
	C527.209900,273.266052 527.362427,272.582123 527.481750,271.892456 
	C527.899170,269.480194 528.305054,267.065948 528.844849,264.329102 
	C529.703857,265.312195 530.433411,266.618591 531.550049,268.421631 
	C531.750488,271.444214 531.563843,273.970215 531.415771,275.973480 
	C533.847656,274.516785 535.900024,273.287384 537.986694,272.455505 
	C539.759888,273.933472 541.498840,275.013916 543.962341,276.544495 
	C543.962341,277.498108 543.962341,279.278839 543.635010,281.082367 
	C542.885132,282.315491 542.462585,283.525818 542.040771,284.734039 
	C538.153687,283.915375 534.190063,283.080597 529.469360,282.086334 
	C529.469360,282.440308 529.469360,281.301392 529.469360,281.493408 
	C525.970398,280.962494 523.127625,280.417053 520.258972,280.158173 
	C519.264526,280.068451 517.688049,280.362457 517.260620,281.039124 
	C514.483398,285.435669 513.019592,282.286774 511.337097,280.000061 
	C509.317719,285.097748 506.613129,282.132385 503.983154,280.468018 
	C501.604248,278.730957 499.296326,277.341125 497.171143,275.631195 
	C498.650909,274.863342 500.142853,274.680206 501.197418,273.903381 
	C502.638824,272.841583 503.747498,271.328094 504.998779,270.008209 
	C505.445831,268.117126 505.892883,266.226013 506.663635,264.150146 
M516.410706,275.477570 
	C516.410706,275.477570 516.518677,275.411621 516.410706,275.477570 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M612.024963,303.104828 
	C613.447754,301.421570 614.870483,299.738342 616.638062,298.008026 
	C623.546631,298.817230 622.202637,305.900024 626.524414,310.053589 
	C626.849365,308.861481 627.136963,307.806305 627.424622,306.751099 
	C628.107910,306.997406 628.791260,307.243744 629.474609,307.490051 
	C629.474609,309.841278 629.474609,312.192474 629.779297,315.106384 
	C632.440063,318.291351 633.484741,322.626648 638.337646,321.987183 
	C638.970642,321.995850 639.603577,322.004486 640.596436,322.368652 
	C640.977661,323.179657 640.999023,323.635162 641.020325,324.090668 
	C641.022095,324.488708 641.023804,324.886749 641.011292,326.005005 
	C640.961975,327.837860 640.926941,328.950500 640.583984,330.050476 
	C639.862244,330.033539 639.448547,330.029327 639.015137,329.766296 
	C638.875061,329.267944 638.754639,329.028381 638.634277,328.788818 
	C638.418762,329.196259 638.203247,329.603729 637.725098,330.192322 
	C635.062866,332.873413 633.828796,331.589752 632.967224,328.616699 
	C631.227234,324.980255 629.517578,321.725311 627.666931,318.201996 
	C624.133545,320.783356 622.081055,318.886627 619.736938,315.489105 
	C617.740234,312.594940 615.188599,310.402863 615.641541,306.323669 
	C615.744446,305.395996 613.297974,304.185272 612.024963,303.104828 
M627.377258,313.544159 
	C627.151794,313.487061 626.926270,313.429932 626.700806,313.372833 
	C626.845276,313.512360 626.989685,313.651917 627.377258,313.544159 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M665.089844,486.926514 
	C665.089844,486.926514 664.614868,486.981689 664.013855,487.040466 
	C662.562866,488.329102 661.712891,489.558960 660.863708,490.787628 
	C657.117371,488.785736 657.461060,486.743652 660.648743,484.881226 
	C658.752319,482.815338 657.132446,481.050781 655.512634,479.286224 
	C657.056274,478.466614 658.248474,478.595062 659.346680,478.986938 
	C660.391296,479.359741 661.337830,480.007721 662.327454,480.534729 
	C662.775330,478.535980 663.223206,476.537201 663.703491,474.393860 
	C662.170837,474.130859 661.067749,473.941589 659.986328,473.756042 
	C659.755798,469.772644 657.416260,465.877594 660.350952,461.867554 
	C661.446106,462.455353 662.784546,463.099213 662.896973,463.912537 
	C663.685303,469.615295 663.604370,469.626495 669.131042,467.312012 
	C669.180786,469.746429 669.091858,471.865082 669.002991,473.983765 
	C668.442871,476.613739 667.882751,479.243713 667.645630,481.917328 
	C667.486694,483.190125 667.004822,484.419342 666.050293,485.932983 
	C665.322571,486.390228 665.159973,486.626556 665.089844,486.926514 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M668.003052,449.002747 
	C667.394653,446.952026 666.786316,444.901276 665.836670,441.700073 
	C667.276733,442.088928 668.780090,442.494873 670.861938,443.057037 
	C670.469055,441.449219 670.023926,439.627380 669.527832,437.596954 
	C670.795044,436.576447 672.277405,435.382660 674.751953,433.389832 
	C671.948730,433.673462 670.429871,433.827148 668.493042,433.639526 
	C668.058655,432.866150 668.042236,432.434082 668.025757,432.002045 
	C668.670593,431.216980 669.315430,430.431885 670.329163,429.274780 
	C672.132019,428.266846 673.566040,427.630981 675.000000,426.995117 
	C675.462708,430.815338 678.828186,434.742493 674.512390,438.426910 
	C674.173645,438.716064 674.327393,440.088013 674.710938,440.635132 
	C677.237610,444.239380 675.085815,446.001282 672.141357,447.519684 
	C673.817749,450.255615 675.492493,452.988800 677.395081,456.093933 
	C675.338806,456.834534 673.997070,457.317810 672.057861,457.668213 
	C670.303833,454.686859 669.147156,451.838348 667.993958,448.993591 
	C667.997498,448.997314 668.003052,449.002747 668.003052,449.002747 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M543.962341,281.059601 
	C543.962341,279.278839 543.962341,277.498108 543.962341,276.544495 
	C541.498840,275.013916 539.759888,273.933472 538.243042,272.432068 
	C538.636292,272.003662 538.807434,271.996216 539.162842,272.145691 
	C541.232361,272.196960 543.117554,272.091339 545.135986,271.660461 
	C545.498901,270.197845 545.728516,269.060486 545.958130,267.923126 
	C548.286926,268.277679 550.796204,268.210907 552.892395,269.108673 
	C554.873230,269.957031 556.433350,271.314667 558.648804,269.415283 
	C560.032837,270.795929 561.035095,271.890167 562.021973,272.990631 
	C562.006470,272.996887 562.011475,272.972992 562.003723,273.276489 
	C562.002625,273.782227 562.009338,273.984497 562.020386,274.570282 
	C561.352905,276.306427 560.680969,277.659119 560.004517,279.005890 
	C560.000000,278.999969 559.987793,278.990448 559.977417,278.619690 
	C557.580444,272.117188 557.152893,280.099243 554.528748,278.064819 
	C552.713440,277.337067 549.843811,276.186676 546.618652,275.086700 
	C546.057739,276.527405 545.996216,277.960541 545.598755,279.293579 
	C545.313232,280.251312 544.578979,281.075317 544.022156,281.734314 
	C543.999329,281.509674 543.962341,281.059601 543.962341,281.059601 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M669.357178,473.984253 
	C669.091858,471.865082 669.180786,469.746429 669.150146,466.953339 
	C668.142944,465.270813 667.255249,464.262726 666.367615,463.254639 
	C665.929749,463.476257 665.491943,463.697876 665.054138,463.919525 
	C665.349976,462.651337 665.645752,461.383179 665.611694,461.529266 
	C664.019409,459.174805 663.048889,457.739685 662.420288,456.244598 
	C664.244873,456.285126 665.727417,456.385681 668.058838,456.543793 
	C666.564026,454.905823 665.738037,454.000732 664.912048,453.095612 
	C665.715698,451.764618 666.519287,450.433624 667.662964,449.052673 
	C668.003052,449.002747 667.997498,448.997314 668.008179,449.410522 
	C668.361572,452.262787 668.449646,454.779449 669.158997,457.106750 
	C669.492371,458.200287 671.021606,458.929352 672.008850,459.823578 
	C677.449585,459.446350 675.978088,463.520569 675.945923,466.307037 
	C675.910461,469.385529 675.378296,472.470581 674.881470,475.523285 
	C674.749146,476.336426 673.953979,477.041656 673.621948,477.552368 
	C672.162109,476.220581 670.936768,475.102692 669.357178,473.984253 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M649.962769,333.670898 
	C647.573303,336.970215 645.154663,339.928223 642.361206,342.713257 
	C641.865356,342.258362 641.744324,341.976410 641.623291,341.694458 
	C641.499023,341.786591 641.261536,341.952332 641.266724,341.960266 
	C641.507568,342.330688 641.769470,342.687317 642.029053,343.045563 
	C641.879700,343.588715 641.730347,344.131866 641.016785,344.871277 
	C638.511658,345.412384 636.570801,345.757263 634.629883,346.102112 
	C634.559448,345.740234 634.489014,345.378357 634.418579,345.016449 
	C635.120239,344.269623 635.821838,343.522827 636.829834,342.449829 
	C634.649902,342.625061 633.131470,342.747101 631.072327,342.912628 
	C631.864990,340.176117 632.484192,338.038605 633.505981,335.929138 
	C638.070435,335.631500 642.232300,335.305878 646.394165,334.980225 
	C646.353455,334.479919 646.312744,333.979645 646.272034,333.479340 
	C645.289429,333.087616 644.306824,332.695892 643.309326,332.298248 
	C643.965271,330.687408 644.507874,329.354919 645.050476,328.022461 
	C646.678223,329.791473 648.305908,331.560516 649.962769,333.670898 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M538.978577,271.988800 
	C538.807434,271.996216 538.636292,272.003662 538.208862,272.034546 
	C535.900024,273.287384 533.847656,274.516785 531.415771,275.973480 
	C531.563843,273.970215 531.750488,271.444214 531.877686,268.517517 
	C533.927856,265.129944 543.349060,264.718445 545.701172,267.722412 
	C545.728516,269.060486 545.498901,270.197845 544.795776,271.609589 
	C542.543945,272.066376 540.637939,265.739471 538.978577,271.988800 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M644.978027,327.658264 
	C644.507874,329.354919 643.965271,330.687408 643.309326,332.298248 
	C644.306824,332.695892 645.289429,333.087616 646.272034,333.479340 
	C646.312744,333.979645 646.353455,334.479919 646.394165,334.980225 
	C642.232300,335.305878 638.070435,335.631500 633.476807,335.616211 
	C633.034424,334.854492 633.023743,334.433746 633.407471,334.006287 
	C636.165283,332.687469 638.528625,331.375336 640.891968,330.063171 
	C640.926941,328.950500 640.961975,327.837860 641.016357,326.303467 
	C642.325623,326.352539 643.615601,326.823334 644.978027,327.658264 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M562.037415,272.984375 
	C561.035095,271.890167 560.032837,270.795929 558.969971,269.354950 
	C564.714050,270.572601 570.518677,272.137024 576.634033,273.849854 
	C574.825623,275.840759 572.706543,277.683228 570.093445,279.638672 
	C569.301331,279.584412 569.077759,279.346985 568.948120,278.629211 
	C569.171387,275.331116 568.970032,272.747528 564.837158,272.727844 
	C563.776489,272.650787 562.906921,272.817566 562.037415,272.984375 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M665.080811,393.801331 
	C665.821960,391.467743 666.139343,388.866821 667.403198,386.862946 
	C669.316345,383.829651 669.348633,381.656708 666.201111,379.648041 
	C665.869202,379.436218 666.062805,378.401093 666.013489,377.346405 
	C671.317749,379.468719 672.608704,385.926910 668.723267,392.801575 
	C667.265930,393.455139 666.173401,393.628235 665.080811,393.801331 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M638.468689,321.696136 
	C633.484741,322.626648 632.440063,318.291351 630.026123,315.332581 
	C632.266357,314.393982 634.741333,313.049438 635.766785,316.847412 
	C635.856079,317.178070 637.864563,316.990570 640.016113,317.093048 
	C639.539734,318.543213 639.069763,319.974152 638.468689,321.696136 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M667.228516,419.012543 
	C665.675049,416.399902 663.706421,414.146423 665.532349,410.530609 
	C666.849854,407.921722 668.067383,407.544983 670.710693,408.803955 
	C672.225525,408.619598 673.384888,408.322632 675.320374,407.826874 
	C674.541138,409.272369 673.934753,410.397278 672.887939,411.672546 
	C671.287354,411.850037 670.127136,411.877136 668.667908,412.070221 
	C668.290161,413.855621 668.211365,415.475037 667.893433,417.251770 
	C667.512329,417.943604 667.370422,418.478088 667.228516,419.012543 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M664.717346,393.894043 
	C666.173401,393.628235 667.265930,393.455139 668.681824,393.164368 
	C668.240784,394.332703 667.476379,395.618713 666.360352,396.958618 
	C663.595642,397.896912 661.182617,398.781372 658.067993,399.922943 
	C658.236328,395.337372 662.111450,395.480347 664.717346,393.894043 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M668.966919,411.904205 
	C670.127136,411.877136 671.287354,411.850037 672.749329,411.974945 
	C673.051086,412.126953 673.057373,412.469086 673.052002,412.987061 
	C673.702698,414.353973 674.358765,415.202881 675.014893,416.051788 
	C675.019043,416.464874 675.023132,416.877991 674.651550,417.629150 
	C673.520264,417.978241 672.764587,417.989227 671.806274,417.722412 
	C670.724670,415.597778 669.845825,413.750977 668.966919,411.904205 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M672.008972,418.000244 
	C672.764587,417.989227 673.520264,417.978241 674.653564,417.938965 
	C675.225769,420.129333 675.420288,422.347992 674.990784,424.801880 
	C673.901917,424.012878 673.437073,422.988586 672.941528,421.623627 
	C672.610107,420.188751 672.309509,419.094513 672.008972,418.000244 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M672.006470,459.538208 
	C671.021606,458.929352 669.492371,458.200287 669.158997,457.106750 
	C668.449646,454.779449 668.361572,452.262787 668.004639,449.406799 
	C669.147156,451.838348 670.303833,454.686859 671.729126,457.822815 
	C671.999817,458.491150 672.001953,458.872009 672.006470,459.538208 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M672.972290,421.964294 
	C673.437073,422.988586 673.901917,424.012878 674.710754,425.072083 
	C675.040649,425.526123 675.026550,425.945251 675.006226,426.679749 
	C673.566040,427.630981 672.132019,428.266846 670.352661,428.955017 
	C670.007324,429.007324 669.999023,428.999084 670.029175,428.662476 
	C670.035034,426.868469 670.010742,425.411041 669.981689,423.539276 
	C669.958313,421.743958 669.939636,420.363007 670.192383,419.140808 
	C671.299927,420.187775 672.136108,421.076019 672.972290,421.964294 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M576.313843,609.997437 
	C575.948914,608.523682 575.871216,607.034851 575.793579,605.546021 
	C576.294922,605.498413 576.796326,605.450806 577.297729,605.403259 
	C577.522827,606.390808 577.747925,607.378357 577.978271,608.737976 
	C577.522705,609.400818 577.061951,609.691528 576.313843,609.997437 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M627.261475,562.455200 
	C626.668701,563.152100 625.839905,563.626892 625.011108,564.101746 
	C624.833313,563.637573 624.655518,563.173462 624.477722,562.709290 
	C625.326965,562.550598 626.176208,562.391907 627.261475,562.455200 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M675.253662,415.852661 
	C674.358765,415.202881 673.702698,414.353973 673.045471,413.158051 
	C673.860413,413.758545 674.676453,414.706024 675.253662,415.852661 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M641.326904,324.034302 
	C640.999023,323.635162 640.977661,323.179657 640.912476,322.386353 
	C641.425781,322.247925 641.982910,322.447327 642.540100,322.646729 
	C642.237915,323.090454 641.935730,323.534210 641.326904,324.034302 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M634.071289,572.067566 
	C633.699707,572.623108 633.268127,573.120911 632.836548,573.618713 
	C633.001343,573.084595 633.166199,572.550476 633.612244,571.954346 
	C633.893433,571.892395 634.011292,572.009888 634.071289,572.067566 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M345.575653,650.248108 
	C347.219147,650.166077 348.862732,650.014343 350.506104,650.016602 
	C353.897095,650.021240 355.760101,651.777527 355.876221,655.160156 
	C355.989716,658.466553 355.936005,661.778564 356.101379,665.531799 
	C356.094299,667.790710 355.931061,669.605164 355.790894,671.421387 
	C355.478516,675.467590 353.236267,677.314270 349.247498,677.262390 
	C345.285950,677.210815 342.991302,675.437744 342.901733,671.294617 
	C342.862488,669.481628 342.824768,667.668640 342.679260,665.416992 
	C342.690582,660.961182 342.809021,656.943909 343.276642,652.392456 
	C344.275757,651.321533 344.925720,650.784790 345.575653,650.248108 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M386.917572,611.941223 
	C385.637756,618.304260 380.655823,620.964661 375.171906,623.598633 
	C369.577789,624.201965 364.264801,624.610779 358.252106,624.878601 
	C357.002136,624.710144 356.451874,624.682739 355.901581,624.655273 
	C355.491974,624.531738 355.082397,624.408203 354.086365,624.083740 
	C352.645416,623.730957 351.790894,623.579041 350.936371,623.427185 
	C349.880646,622.964783 348.824921,622.502380 347.176025,621.762329 
	C343.413391,619.646484 340.244019,617.808167 337.144836,615.909180 
	C337.215027,615.848572 337.339722,615.715698 337.617340,615.428040 
	C338.081848,614.950317 338.268738,614.760315 338.812805,614.605835 
	C347.837646,619.712585 357.076874,622.110596 367.202301,621.242004 
	C372.662598,620.773682 376.640350,617.697144 378.383453,613.333557 
	C379.536438,610.447266 377.655701,606.349060 377.119537,602.573853 
	C377.105896,602.354248 377.052856,601.914856 377.056305,601.719238 
	C377.059753,601.523560 377.057892,601.132202 377.425659,601.072754 
	C378.539154,601.012451 379.284882,601.011475 380.351593,601.025085 
	C381.100311,601.033508 381.528076,601.027344 382.285889,601.045410 
	C383.899078,601.732727 385.182220,602.395752 386.465332,603.058777 
	C386.675842,603.926758 386.886383,604.794800 387.212952,605.884705 
	C387.376190,606.705078 387.423340,607.303589 387.284302,608.620972 
	C387.037964,610.207031 386.977753,611.074097 386.917572,611.941223 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M332.914368,624.121033 
	C332.271942,623.453430 331.993164,622.684692 332.029724,622.785522 
	C328.408722,622.403687 325.256958,622.071411 322.222961,621.158752 
	C322.779846,619.763306 323.283691,618.211060 323.648376,618.243164 
	C329.486206,618.755676 329.015106,614.788940 329.054016,610.920044 
	C329.183655,610.783997 329.397095,610.474609 329.397095,610.474609 
	C331.504089,612.057678 333.611053,613.640747 335.899170,615.840881 
	C336.828857,617.932007 337.312683,619.653625 338.397064,620.813843 
	C339.587219,622.087280 341.330872,622.843384 342.833374,623.824951 
	C339.648254,623.889893 336.463135,623.954834 332.914368,624.121033 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M343.142212,623.910034 
	C341.330872,622.843384 339.587219,622.087280 338.397064,620.813843 
	C337.312683,619.653625 336.828857,617.932007 336.142334,616.126709 
	C336.204376,615.795532 336.277985,615.421021 336.403259,615.497192 
	C336.711212,615.699463 336.893890,615.825684 337.087769,615.928894 
	C337.098999,615.905945 337.074646,615.969849 337.074646,615.969849 
	C340.244019,617.808167 343.413391,619.646484 346.829956,621.733765 
	C345.868439,622.653625 344.659760,623.324402 343.142212,623.910034 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M351.012817,623.710754 
	C351.790894,623.579041 352.645416,623.730957 353.762756,624.094177 
	C353.046814,624.201843 352.068054,624.098083 351.012817,623.710754 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M355.991211,624.891479 
	C356.451874,624.682739 357.002136,624.710144 357.789978,624.909302 
	C357.378601,625.096558 356.729706,625.112122 355.991211,624.891479 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M318.471863,440.960693 
	C318.055969,440.512543 318.032104,440.075043 318.010681,439.307922 
	C320.532104,438.592896 321.618256,437.639282 319.765564,435.155121 
	C319.014008,434.147461 318.626068,432.868622 318.038116,431.361206 
	C318.330017,430.255280 318.656464,429.501312 318.989258,428.075562 
	C318.995056,426.950165 318.994507,426.496582 318.993958,426.042969 
	C318.804321,425.764374 318.614716,425.485779 318.198181,424.713196 
	C317.981659,423.473358 317.992035,422.727509 318.002441,421.981689 
	C317.994080,421.551483 317.985687,421.121246 317.977417,420.369324 
	C318.981201,419.262207 320.790527,418.535004 320.852112,417.681580 
	C321.153351,413.508728 320.988586,409.302216 320.988586,404.312836 
	C320.005554,405.728424 319.460114,406.513855 318.914673,407.299255 
	C318.509766,407.173706 318.104858,407.048187 317.699951,406.922638 
	C318.227325,404.663849 318.777313,402.409973 319.271515,400.143951 
	C319.486969,399.155884 319.587982,398.142853 319.382141,397.097473 
	C318.765381,395.896423 318.507385,394.739075 318.106384,393.334045 
	C322.679291,393.400726 324.841431,390.587189 324.352386,384.402039 
	C325.177063,384.932770 325.830292,385.353149 327.071838,385.910767 
	C328.126740,386.377838 328.593414,386.707642 329.046448,387.409424 
	C331.165253,391.273468 333.297638,394.765503 335.718140,398.729309 
	C334.993622,398.575073 335.848755,398.559357 335.934235,398.808167 
	C336.406006,400.181000 336.689941,401.618408 337.022034,403.029114 
	C337.005768,403.024536 336.989197,402.995117 336.628571,402.999847 
	C334.863922,405.041901 332.738281,406.911224 332.216278,409.153839 
	C331.318207,413.012085 331.419403,417.102936 331.088135,421.260071 
	C332.404755,420.872772 334.115875,420.369415 336.398529,419.697906 
	C335.773804,421.206146 335.337830,422.046906 335.072021,422.938385 
	C334.091980,426.225372 329.864014,428.821899 332.940826,433.368164 
	C333.333160,439.126434 333.582397,444.502502 334.185303,449.838623 
	C334.267792,450.568817 336.112274,451.099945 337.475891,451.820923 
	C338.198456,452.979797 338.916199,454.046906 338.897278,455.100800 
	C338.867920,456.734863 338.336334,458.359894 338.003784,460.229919 
	C337.989899,460.471100 337.999847,460.954071 337.763062,461.053894 
	C337.338623,461.429108 337.151001,461.704468 336.981812,461.971252 
	C337.000244,461.962646 336.961639,461.948334 336.576843,461.922058 
	C331.803284,462.475281 331.903442,465.685181 331.957794,469.203491 
	C331.571045,469.986389 331.294800,470.498871 331.009277,471.005829 
	C331.000000,471.000305 331.016510,471.017242 330.696259,470.987061 
	C329.578430,470.288513 328.780853,469.620178 327.983246,468.951843 
	C328.321503,467.534912 328.659760,466.117950 328.998047,464.700989 
	C328.502106,464.703796 328.006195,464.706573 327.256042,464.856018 
	C327.001801,465.002716 326.967041,464.976288 326.967041,464.976288 
	C326.567108,458.031342 326.567108,458.031342 328.164490,457.088928 
	C327.405334,451.294708 326.713165,446.011963 326.052734,440.971313 
	C323.959808,440.971313 321.411835,440.971313 318.471863,440.960693 
M331.263763,403.810852 
	C331.377106,403.969421 331.490417,404.128021 331.603760,404.286591 
	C331.581421,404.064484 331.559052,403.842346 331.263763,403.810852 
M336.483124,454.623566 
	C336.483124,454.623566 336.402069,454.491974 336.483124,454.623566 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M329.060089,387.037445 
	C328.593414,386.707642 328.126740,386.377838 327.362518,386.019958 
	C328.489044,383.320740 329.913116,380.649536 331.337189,377.978333 
	C330.938416,377.709686 330.539642,377.441010 330.140869,377.172333 
	C328.683472,378.078308 327.226105,378.984253 325.788818,379.877716 
	C319.999664,373.952454 325.099792,369.953888 327.767914,366.345306 
	C326.049927,363.230286 324.646393,360.685425 323.315491,358.272308 
	C323.163696,358.659912 322.883301,359.375977 322.602875,360.092041 
	C322.249603,360.033569 321.896362,359.975098 321.543091,359.916595 
	C321.820801,358.470062 321.635834,356.178558 322.458893,355.727905 
	C325.499115,354.063080 327.097107,351.426300 329.144226,348.294098 
	C330.128326,348.003448 330.566986,348.005310 331.005859,348.391266 
	C331.255157,351.795349 329.007446,355.510071 334.484985,357.975128 
	C332.856384,352.016968 331.992035,346.569977 337.564972,343.317963 
	C336.483215,341.944366 335.739410,340.999878 335.108368,339.784943 
	C335.798462,339.008270 336.375732,338.502045 337.236633,338.200928 
	C339.134888,340.797424 341.389069,342.993317 338.364929,346.092285 
	C337.850128,346.619812 338.929138,348.702728 339.213531,350.426575 
	C338.262085,352.272156 337.368927,353.758148 336.296509,355.542450 
	C337.791443,355.450745 339.128174,355.368774 340.278992,355.298187 
	C340.491913,358.398804 340.684113,361.197479 340.775391,362.526703 
	C339.972992,365.949707 339.588379,368.087433 338.939026,370.141510 
	C338.635223,371.102417 337.819977,371.901611 337.236542,372.774109 
	C336.802429,372.535492 336.368317,372.296906 335.934204,372.058289 
	C335.934204,373.697998 335.934204,375.337677 335.685669,376.999268 
	C335.183594,377.192505 334.930023,377.363861 334.676453,377.535217 
	C335.115997,377.689728 335.555573,377.844269 336.237122,377.826599 
	C336.805298,376.991425 337.131470,376.328430 337.789612,374.990631 
	C337.948700,376.411896 338.029968,377.137939 338.039948,378.241577 
	C335.927368,381.442749 333.886078,384.266296 331.554321,387.491669 
	C331.243408,387.435028 330.151764,387.236237 329.060089,387.037445 
M326.592255,357.273804 
	C326.369812,356.974609 326.147369,356.675415 325.924927,356.376221 
	C325.737579,356.556793 325.550262,356.737335 325.362915,356.917908 
	C325.649658,357.153351 325.936432,357.388794 326.592255,357.273804 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M328.643494,325.195007 
	C329.096985,324.927032 329.292236,324.922272 330.012177,324.779541 
	C330.359528,324.072784 330.182220,323.504059 330.004944,322.935333 
	C331.436432,320.326599 332.867950,317.717865 334.651672,315.431702 
	C335.328674,317.257416 335.653442,318.760559 335.978180,320.263672 
	C336.434052,320.096008 336.889954,319.928345 337.345825,319.760681 
	C336.564697,318.173279 335.783569,316.585876 335.002441,314.998474 
	C335.248657,313.539124 335.494843,312.079742 336.263824,310.305725 
	C338.160675,311.167725 339.534760,312.344391 341.134491,313.714294 
	C340.942444,313.875427 341.415710,313.478333 341.915710,313.488525 
	C341.656891,317.971710 341.371338,322.047577 340.704163,326.095398 
	C337.101135,327.128143 337.072632,329.403564 337.769836,332.325012 
	C335.303741,333.391693 334.008575,332.926727 333.996185,330.295898 
	C333.988586,328.686005 333.754791,327.077179 333.622559,325.467896 
	C331.386444,328.439362 332.715302,333.421844 327.053375,332.929779 
	C327.497345,330.439270 327.941315,327.948730 328.643494,325.195007 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M321.072723,294.062469 
	C321.796967,292.709656 322.521240,291.356842 323.406311,289.703674 
	C322.760315,288.700592 321.846161,287.281128 320.758362,285.180817 
	C320.055878,283.014252 319.527039,281.528534 318.998230,280.042786 
	C320.907684,276.341583 320.750671,276.604034 323.121674,278.835632 
	C324.684753,280.306793 327.190613,280.776276 329.859192,281.951935 
	C328.987396,285.087708 327.736176,289.322815 330.967041,293.031372 
	C328.620087,295.095734 328.645538,296.997467 330.034454,299.008270 
	C330.034454,299.008270 330.006653,299.011383 329.703949,299.044800 
	C329.272491,300.071472 329.143738,301.064728 329.014984,302.057953 
	C329.014984,302.057953 329.036407,302.483856 328.799469,302.832825 
	C328.346161,303.464386 328.129791,303.746979 327.913422,304.029602 
	C327.913422,304.029602 327.499939,304.029877 327.294312,304.024719 
	C326.401367,303.247864 325.714111,302.476135 325.247833,301.146881 
	C324.766724,300.033264 323.877747,299.592499 323.435638,298.876007 
	C323.187469,298.473785 323.678192,297.615662 324.136475,296.956696 
	C324.646759,295.949158 324.856567,294.947845 325.066406,293.946533 
	C324.821289,293.909912 324.576141,293.873291 324.331024,293.836639 
	C324.192078,294.245514 324.053131,294.654388 323.611511,295.021973 
	C322.563477,294.674622 321.818115,294.368530 321.072723,294.062469 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M327.040375,333.356323 
	C332.715302,333.421844 331.386444,328.439362 333.622559,325.467896 
	C333.754791,327.077179 333.988586,328.686005 333.996185,330.295898 
	C334.008575,332.926727 335.303741,333.391693 338.112854,332.289978 
	C338.959381,331.982635 339.189331,331.976349 339.350769,332.288574 
	C339.298279,333.036591 339.314331,333.466095 339.115051,334.157959 
	C338.281372,334.656464 337.663055,334.892639 336.928101,334.944214 
	C336.596710,334.843597 336.381927,334.927582 336.167175,335.011566 
	C336.411224,335.345642 336.655273,335.679718 336.916534,336.343964 
	C336.940155,337.114685 336.946594,337.555267 336.953003,337.995819 
	C336.375732,338.502045 335.798462,339.008270 334.822937,339.996796 
	C333.285004,342.988434 332.145325,345.497772 331.005676,348.007141 
	C330.566986,348.005310 330.128326,348.003448 329.361450,348.015137 
	C328.590851,347.056610 328.148376,346.084595 327.352966,345.056213 
	C326.999969,344.999847 326.989014,344.988098 326.989014,344.988098 
	C327.001801,341.253021 327.014587,337.517914 327.040375,333.356323 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M323.835999,296.962921 
	C323.678192,297.615662 323.187469,298.473785 323.435638,298.876007 
	C323.877747,299.592499 324.766724,300.033264 325.239868,300.785645 
	C323.712036,302.266693 322.413208,303.551361 321.114380,304.836060 
	C320.774872,304.602081 320.435333,304.368103 320.095825,304.134125 
	C320.376160,302.985596 320.656464,301.837036 320.960724,300.339050 
	C321.001709,298.294373 321.018768,296.599182 321.054260,294.483215 
	C321.818115,294.368530 322.563477,294.674622 323.626526,295.338928 
	C323.908142,296.119080 323.872070,296.541016 323.835999,296.962921 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M327.005493,345.005432 
	C326.786346,345.734894 326.611969,347.062927 326.329041,347.086426 
	C324.205200,347.263153 322.059937,347.182709 319.223206,347.182709 
	C319.869415,345.603363 320.611267,343.790253 321.139191,342.499939 
	C322.931519,343.347595 324.599670,344.136536 326.628418,344.956787 
	C326.989014,344.988098 326.999969,344.999847 327.005493,345.005432 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M327.001251,320.300018 
	C327.318634,320.467834 327.646637,320.945221 327.999634,321.727295 
	C327.687073,321.557831 327.349457,321.083710 327.001251,320.300018 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M593.117065,653.647095 
	C602.807068,653.636658 612.626526,653.985962 622.745728,654.587769 
	C619.384338,655.215393 615.720520,655.918640 612.062622,655.889099 
	C606.500122,655.844238 600.939514,655.321167 595.384216,654.918091 
	C594.653442,654.865112 593.958252,654.322571 593.117065,653.647095 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M394.868073,279.150482 
	C397.363251,281.267822 399.878052,283.337372 403.752563,283.220337 
	C404.998291,285.152222 405.997162,286.558624 407.001892,288.353729 
	C408.323944,289.851624 409.640167,290.960815 411.149963,292.233154 
	C410.877319,293.031982 410.388672,294.463654 409.630524,296.684906 
	C410.975159,295.490479 411.609497,294.927002 412.308807,294.305786 
	C412.907227,297.429993 413.434784,300.184174 414.000763,303.139038 
	C410.141266,302.170593 407.794983,305.247589 409.576294,310.345703 
	C409.840790,309.072601 410.105255,307.799500 410.369751,306.526398 
	C410.813629,306.847076 411.257538,307.167725 411.701416,307.488403 
	C412.133240,308.637970 412.565094,309.787506 412.616394,309.924133 
	C410.988220,311.578522 409.913025,312.670990 408.433807,314.174011 
	C411.408020,314.174011 413.036469,314.174011 415.087830,314.174011 
	C415.359955,313.636322 416.018280,312.335510 416.731171,310.926941 
	C416.336395,310.582672 415.849304,310.345398 415.665009,309.962402 
	C415.094330,308.776428 414.556366,307.557312 414.215668,306.294434 
	C414.155212,306.070374 415.445038,305.188232 415.530212,305.263916 
	C416.413818,306.049103 417.107300,307.048981 417.993927,307.829834 
	C419.084381,308.790161 420.308441,309.598846 422.087067,310.932098 
	C421.948792,308.037628 421.863647,306.255310 421.774078,304.380737 
	C422.919769,305.304840 423.958893,306.142975 425.025330,307.329742 
	C425.370056,308.786682 425.687378,309.894958 425.746277,311.199219 
	C424.803864,313.581238 424.970795,314.216858 427.446747,312.986145 
	C429.529480,311.950897 431.993195,311.682037 434.950684,310.898346 
	C435.082794,310.078247 439.312988,308.262970 434.859039,305.729553 
	C434.558044,305.262329 434.300934,305.094360 433.990601,305.009857 
	C433.996002,305.003571 433.978149,305.014771 434.041412,304.663147 
	C436.070190,302.874451 438.035706,301.437378 439.995972,300.353546 
	C440.341644,301.366669 440.533234,302.397125 441.068848,302.627411 
	C445.152710,304.383179 442.684174,307.725800 442.512543,310.106476 
	C442.431671,311.227844 439.421814,312.138062 437.249268,313.436829 
	C438.094757,314.807434 439.462891,317.025299 440.831024,319.243164 
	C440.317291,319.502686 439.803558,319.762177 439.289856,320.021698 
	C438.821503,319.431183 438.457397,318.507233 437.862579,318.321320 
	C436.712128,317.961700 435.431671,318.017914 434.205078,317.901764 
	C434.640045,319.900085 429.244110,318.740173 431.631287,321.997711 
	C429.168274,322.993896 427.064484,323.982819 424.752228,325.096558 
	C424.564301,325.589233 424.584839,325.957092 424.605377,326.324951 
	C424.750397,325.896667 424.895416,325.468353 425.294281,325.251648 
	C425.842377,326.392334 426.136627,327.321472 426.428894,328.244415 
	C427.629395,328.153137 428.795410,328.109985 429.947723,327.960846 
	C430.719482,327.860992 431.696381,327.830048 432.159393,327.356018 
	C432.508484,326.998596 432.195343,325.987305 432.164734,325.267609 
	C432.118530,324.181396 432.061707,323.095581 432.380127,322.010223 
	C437.286713,320.804688 434.039673,326.070557 436.584473,326.201935 
	C438.280090,326.013519 440.788086,325.582397 441.407501,326.469696 
	C443.103912,328.899689 440.643768,329.237885 438.980896,330.436401 
	C436.304382,332.365509 434.176300,331.862793 431.255707,331.401428 
	C427.833008,330.860687 424.136475,332.053345 419.879089,332.577911 
	C421.366455,331.154602 422.432465,330.134552 423.800232,328.825714 
	C417.886688,329.619843 421.171387,326.267578 421.551025,324.567688 
	C414.745789,321.908813 414.745789,321.908813 414.375153,325.210480 
	C418.967896,324.422485 419.449829,325.862122 416.856995,333.292542 
	C415.676147,332.809021 413.624603,332.517914 413.465424,331.804535 
	C412.907928,329.306061 412.653931,326.625061 412.923065,324.080933 
	C413.473755,318.875366 412.508820,317.314087 409.299011,317.842865 
	C409.512146,319.974365 409.718414,322.036987 409.650391,323.865173 
	C407.086273,322.493134 404.796448,321.355530 403.230988,320.577820 
	C403.230988,314.858673 403.230988,309.438110 403.230988,303.133331 
	C400.344666,306.663574 404.716370,311.883209 398.886841,312.652924 
	C397.431274,309.593597 395.953217,306.920929 394.475159,304.248260 
	C393.702820,304.342590 392.930450,304.436920 392.158112,304.531250 
	C391.054321,311.204681 389.950500,317.878082 388.684723,325.530792 
	C386.616241,323.189697 383.317627,321.153351 383.321045,319.122589 
	C383.329315,314.230011 384.865936,309.336609 385.845642,304.455475 
	C385.924164,304.064270 386.460114,303.764893 386.967224,303.228485 
	C387.712555,304.137573 388.361267,304.928741 388.936005,305.629761 
	C393.410492,303.202271 391.111908,301.258911 388.590027,299.544312 
	C389.918579,298.555389 390.960999,297.779449 392.340332,297.002014 
	C393.126282,296.979004 393.575348,296.957458 394.031525,297.297760 
	C393.837067,298.900787 393.635529,300.141998 393.354340,301.873474 
	C394.938263,301.233673 396.135773,300.749939 397.247223,300.300964 
	C397.549957,302.003113 397.796875,303.391479 398.248779,305.932404 
	C400.043365,303.282562 401.062317,301.777985 401.657166,300.899658 
	C398.865143,298.022125 396.429871,295.512268 394.139954,292.770691 
	C394.184113,291.676117 394.082916,290.813293 393.987549,289.556732 
	C392.580322,286.979004 397.512848,283.014404 392.110107,281.694458 
	C393.116425,280.651733 393.992249,279.901093 394.868073,279.150482 
M405.243469,316.804443 
	C405.367462,316.973297 405.491486,317.142151 405.615479,317.311005 
	C405.589172,317.074677 405.562866,316.838379 405.243469,316.804443 
M438.396515,328.479584 
	C438.396515,328.479584 438.522522,328.399445 438.396515,328.479584 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M392.003448,297.003479 
	C390.960999,297.779449 389.918579,298.555389 388.590027,299.544312 
	C391.111908,301.258911 393.410492,303.202271 388.936005,305.629761 
	C388.361267,304.928741 387.712555,304.137573 386.967224,303.228485 
	C386.460114,303.764893 385.924164,304.064270 385.845642,304.455475 
	C384.865936,309.336609 383.329315,314.230011 383.321045,319.122589 
	C383.317627,321.153351 386.616241,323.189697 388.684723,325.530792 
	C389.950500,317.878082 391.054321,311.204681 392.158112,304.531250 
	C392.930450,304.436920 393.702820,304.342590 394.475159,304.248260 
	C395.953217,306.920929 397.431274,309.593597 398.612671,312.651489 
	C397.946503,313.041321 397.576996,313.045959 397.139954,312.697296 
	C396.859497,311.761475 396.646545,311.178986 396.433594,310.596466 
	C395.992126,310.872101 395.550598,311.147705 395.109100,311.423340 
	C395.750671,312.335510 396.392242,313.247711 397.032013,314.457153 
	C397.027557,315.150879 397.024902,315.547272 396.714050,315.999268 
	C396.943878,317.707306 397.481903,319.359741 398.007507,321.007843 
	C397.995117,321.003510 397.991974,320.977386 397.987244,321.381165 
	C396.479584,325.416687 394.976715,329.048462 393.411469,332.830902 
	C389.676666,330.159760 387.813690,330.525055 386.625397,333.989136 
	C384.608032,334.679718 382.919952,335.320404 382.056610,335.648102 
	C381.195953,337.465729 380.594116,338.736786 379.985962,339.650940 
	C379.176788,338.773682 378.351166,337.791901 377.575989,337.830261 
	C376.021973,337.907166 374.495544,338.541718 372.950500,338.670532 
	C372.914246,338.032288 372.885254,337.669647 373.134308,337.069794 
	C373.752625,335.743713 374.092957,334.654816 374.519592,333.289703 
	C375.592041,333.625793 376.605621,333.943451 377.608154,334.257660 
	C382.352875,326.327454 382.179871,323.763855 377.219147,320.844055 
	C375.648926,322.966400 374.115723,325.038727 372.582489,327.111084 
	C372.077850,326.750397 371.573212,326.389740 371.068604,326.029053 
	C371.754456,324.979248 372.844604,323.997833 373.036346,322.864471 
	C373.367096,320.909607 373.178955,318.858032 373.068542,316.854797 
	C373.059937,316.698730 371.954437,316.603119 371.358765,316.479431 
	C371.131439,318.277710 370.922943,320.078766 370.656067,321.871155 
	C370.617188,322.132416 370.299683,322.352173 369.390259,323.504547 
	C368.915222,321.345428 368.599457,319.910278 368.281647,318.465668 
	C366.955444,318.910980 365.616760,319.360504 363.259338,320.152069 
	C364.342865,323.339874 365.405243,326.465546 366.701111,330.278137 
	C365.573364,330.445068 363.788116,330.709320 362.036560,330.630920 
	C362.382355,329.194550 362.694427,328.100861 362.972717,326.682495 
	C362.296356,325.909424 361.653748,325.461029 360.700562,324.999634 
	C359.976074,324.965210 359.562195,324.943756 359.093811,324.595520 
	C358.694824,323.504059 358.350311,322.739319 357.905579,321.760376 
	C357.622070,321.275452 357.377014,321.083618 357.051514,320.725372 
	C357.032867,320.480072 357.013580,319.988464 357.012054,319.743774 
	C357.010529,319.499084 357.003662,319.009766 357.311707,318.870361 
	C361.001434,317.307098 358.929169,315.332001 358.393585,313.245239 
	C357.621918,310.238464 357.430054,307.082855 356.995300,303.995331 
	C356.999878,303.999878 357.008545,304.008545 357.016052,304.366821 
	C358.275635,306.394440 359.527710,308.063782 361.564606,310.779541 
	C363.041382,306.579681 364.030579,303.766510 365.345337,301.129883 
	C366.365112,303.839935 367.059296,306.373444 367.753479,308.906921 
	C366.572876,309.120605 365.392273,309.334290 363.698364,309.640869 
	C364.587250,311.791870 365.522919,314.056061 366.456482,316.315125 
	C369.268311,315.238647 371.797791,314.270264 374.240356,313.335175 
	C375.127136,314.958801 375.882416,316.341583 377.048920,318.477325 
	C377.455505,316.305969 377.695312,315.025269 378.060028,313.077606 
	C379.080688,316.103210 379.819702,318.293854 380.558685,320.484528 
	C381.045685,320.335083 381.532684,320.185638 382.019684,320.036194 
	C381.352203,316.618683 380.684753,313.201141 379.932617,309.350159 
	C375.742035,311.234161 372.331543,309.720398 371.526062,306.091400 
	C370.520386,301.560516 371.039124,296.691315 371.099182,291.720154 
	C372.381378,290.290283 373.464142,289.108398 374.955200,287.480865 
	C375.386902,287.798553 376.578766,288.675720 378.934692,290.409485 
	C378.934692,286.827301 378.934692,284.355377 378.934692,281.156708 
	C375.356537,285.368439 374.759918,280.943207 372.849854,279.658386 
	C372.466827,277.849365 372.233917,276.381927 372.001007,274.914490 
	C375.416351,276.587982 378.831696,278.261444 382.906982,280.072266 
	C386.799774,280.737274 387.987671,279.038330 388.039337,276.102173 
	C388.039337,276.102173 388.060944,276.060577 388.400696,275.986053 
	C389.181183,275.219818 389.621948,274.528107 390.062683,273.836395 
	C391.109222,273.881531 392.155762,273.926697 393.594971,273.971863 
	C394.306793,275.438416 394.625977,276.904907 394.906616,278.760956 
	C393.992249,279.901093 393.116425,280.651733 391.721558,281.714661 
	C388.723480,282.927765 386.244446,283.828522 383.471222,284.836151 
	C383.034912,286.658600 382.515625,288.827759 381.720032,291.096375 
	C379.964508,292.128540 378.485321,293.061218 376.986389,293.679688 
	C376.514435,292.796509 376.062225,292.227600 375.610046,291.658661 
	C375.364594,291.965637 375.119141,292.272614 374.873688,292.579620 
	C375.580139,293.055359 376.286591,293.531128 377.041168,294.344543 
	C377.734039,296.380371 381.420227,298.197876 376.740875,299.721802 
	C376.002502,299.962219 375.132416,302.383392 375.537476,303.004974 
	C376.350281,304.252167 377.903229,305.276428 379.355835,305.718170 
	C379.928955,305.892487 381.082214,304.297089 381.913971,303.451172 
	C383.276703,302.065247 384.592560,300.633301 385.977478,299.168640 
	C384.655182,299.053772 383.204285,298.927765 381.582031,298.786865 
	C381.728668,296.079865 381.866180,293.541748 382.357544,290.946686 
	C383.332367,289.555054 383.953339,288.220367 384.199188,287.691956 
	C386.640137,287.153503 388.503113,286.742554 390.834595,286.228241 
	C390.041992,287.734161 389.491028,288.781006 388.714813,290.255798 
	C390.813568,290.134125 392.397644,290.042297 393.981720,289.950500 
	C394.082916,290.813293 394.184113,291.676117 393.804626,292.770386 
	C392.876678,293.002441 392.429413,293.003052 391.638214,292.964966 
	C387.918671,291.633057 386.803192,293.448090 386.275635,295.810822 
	C388.453247,296.256775 390.217041,296.617981 391.986328,296.985199 
	C391.991882,296.991211 392.003448,297.003479 392.003448,297.003479 
M383.224762,294.146210 
	C383.395844,294.086548 383.566956,294.026917 383.738037,293.967285 
	C383.566803,293.910889 383.395569,293.854492 383.224762,294.146210 
M394.432922,322.485718 
	C394.432922,322.485718 394.545044,322.416138 394.432922,322.485718 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M438.987732,282.989258 
	C439.992371,281.297699 440.996948,279.606140 441.814697,278.229218 
	C444.182922,279.571503 446.285217,280.763092 448.737793,282.153198 
	C448.860107,280.108307 448.941711,278.744598 449.042145,277.065674 
	C451.191376,279.355164 452.764496,281.030945 454.666565,283.235718 
	C454.461761,286.847900 453.928009,289.931152 453.347595,293.283997 
	C455.897156,293.131042 457.353668,293.043671 459.198792,292.941345 
	C461.907715,292.668732 464.228088,292.411072 466.879425,292.116638 
	C467.837036,290.266510 468.953491,288.109589 470.437866,286.010132 
	C474.051666,286.222412 477.297546,286.377197 480.543457,286.531982 
	C480.702423,286.199829 480.861420,285.867676 481.020416,285.535553 
	C479.659698,284.661346 478.299011,283.787170 476.997314,282.550171 
	C477.376129,280.766632 477.695892,279.345856 478.380310,277.933411 
	C480.502319,278.617737 482.259735,279.293732 484.015625,280.343018 
	C485.662903,281.807373 487.311707,282.898468 489.236481,284.202606 
	C491.002289,285.606812 492.492126,286.798004 493.837280,288.326019 
	C493.533386,294.177765 486.937073,292.127014 484.617065,296.316071 
	C486.691986,296.958344 488.336456,297.467346 489.986877,298.380127 
	C490.598145,300.584045 491.203400,302.384155 491.960815,304.636810 
	C491.387543,305.992523 490.546906,307.980560 490.148499,308.922729 
	C487.673218,307.998474 486.335999,307.499146 484.897156,306.789307 
	C484.606659,306.313660 484.355164,306.138184 484.029358,305.645447 
	C483.531281,303.885193 483.045013,302.531799 482.558746,301.178406 
	C481.713074,302.131592 480.867371,303.084778 480.008850,304.023468 
	C479.996002,304.009003 480.026184,304.026367 479.629395,304.014069 
	C478.179077,304.012970 477.125519,304.024200 476.051666,303.670868 
	C475.219269,301.788086 474.407227,300.269867 473.873138,299.271332 
	C470.628021,300.832336 467.654724,303.395172 464.590729,303.508575 
	C457.479492,303.771729 456.173706,304.531281 456.001221,312.000732 
	C456.000000,312.000000 456.001770,312.002441 455.762421,312.008942 
	C455.367645,312.368652 455.212219,312.721832 455.045776,313.293365 
	C455.034760,313.511719 455.012970,313.948456 454.863098,314.252625 
	C452.187866,315.347107 450.337891,312.547089 450.820618,308.663330 
	C451.769043,308.868958 452.717468,309.076385 453.665863,309.283813 
	C454.110474,308.924652 454.555054,308.565491 454.999664,308.206299 
	C453.659088,305.798340 452.318512,303.390381 450.990021,300.536621 
	C451.002106,297.420654 451.002106,294.750488 451.002106,292.080322 
	C450.789154,292.080383 450.576233,292.080444 450.363281,292.080536 
	C449.842133,295.397278 449.320984,298.714020 448.795074,302.061066 
	C447.780487,301.674957 446.897430,301.338928 445.659180,300.997131 
	C444.535553,300.328003 443.767181,299.664612 443.105835,298.725098 
	C442.469238,297.626160 441.725555,296.803375 441.155396,295.670654 
	C444.142944,292.980774 446.507690,290.461243 443.592621,285.134949 
	C442.395355,289.485107 437.932159,291.329987 439.722717,296.125122 
	C438.339417,297.055939 437.253204,297.840393 435.986389,298.755310 
	C432.795166,287.182068 432.811340,287.133820 440.907227,283.819397 
	C440.094574,283.467926 439.541168,283.228607 438.987732,282.989258 
M475.465942,297.266144 
	C475.594086,296.869415 475.743774,296.477417 475.832336,296.072052 
	C475.850220,295.990204 475.636627,295.857788 475.529785,295.748688 
	C475.401031,296.145844 475.272308,296.542999 475.465942,297.266144 
M487.844940,303.048828 
	C487.588745,303.201538 487.202728,303.303986 487.117859,303.523010 
	C487.051605,303.694153 487.359222,304.010071 487.500641,304.261658 
	C487.642700,304.009674 487.784729,303.757690 487.844940,303.048828 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M438.604340,282.987122 
	C439.541168,283.228607 440.094574,283.467926 440.907227,283.819397 
	C432.811340,287.133820 432.795166,287.182068 435.986389,298.755310 
	C437.253204,297.840393 438.339417,297.055939 439.963135,296.130188 
	C440.500671,295.988953 440.981842,295.980591 440.981842,295.980591 
	C441.725555,296.803375 442.469238,297.626160 442.786804,298.746338 
	C441.574219,299.362549 440.787720,299.681427 440.001221,300.000305 
	C438.035706,301.437378 436.070190,302.874451 433.693420,304.606506 
	C432.191833,302.937958 431.101501,300.974457 430.307068,298.993805 
	C431.068024,298.659851 431.533051,298.343079 432.283844,297.819580 
	C437.060852,291.508270 427.784821,291.872528 427.400299,287.624115 
	C426.942505,289.076538 426.484741,290.528992 425.760681,291.816162 
	C424.876831,289.944458 424.259277,288.237976 423.544189,286.262085 
	C422.526703,286.262085 420.857117,285.943268 419.410187,286.373444 
	C418.435242,286.663361 417.774750,288.010895 416.656372,288.898529 
	C415.916718,288.919312 415.494324,288.926849 415.051147,288.548828 
	C415.707184,285.802338 416.383942,283.441467 417.060730,281.080566 
	C421.782074,283.375183 426.364105,284.072479 431.482422,281.636627 
	C433.139374,280.848053 435.942261,282.467224 438.604340,282.987122 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M478.015686,277.925079 
	C477.695892,279.345856 477.376129,280.766632 476.625610,282.624878 
	C474.153259,284.025818 472.111603,284.989258 470.069946,285.952667 
	C468.953491,288.109589 467.837036,290.266510 466.879425,292.116638 
	C464.228088,292.411072 461.907715,292.668732 459.280945,292.623993 
	C459.807526,290.668213 460.640564,289.014801 461.613556,287.083618 
	C462.463318,287.791443 463.513184,288.665924 465.191101,290.063568 
	C465.940643,288.056976 466.912415,286.488983 466.987091,284.879333 
	C467.034058,283.866241 465.737061,282.790833 465.042969,281.743378 
	C462.245514,284.791107 461.223877,280.738525 459.296814,280.306213 
	C459.872803,278.556458 460.448822,276.806702 461.672119,275.313080 
	C463.709320,276.634003 465.099274,277.698822 466.489197,278.763672 
	C466.324951,279.117889 466.160706,279.472137 465.996460,279.826355 
	C467.605865,279.826355 469.215271,279.826355 470.481781,279.826355 
	C470.618500,276.781494 470.749664,273.861145 470.880798,270.940796 
	C470.880798,270.940796 470.922333,270.908875 471.227661,271.048492 
	C472.135284,273.798553 472.737549,276.408966 473.240021,278.586884 
	C476.352936,277.687256 474.804810,270.820648 480.474701,273.451324 
	C479.590454,275.060059 478.803070,276.492554 478.015686,277.925079 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M459.192780,280.607971 
	C461.223877,280.738525 462.245514,284.791107 465.042969,281.743378 
	C465.737061,282.790833 467.034058,283.866241 466.987091,284.879333 
	C466.912415,286.488983 465.940643,288.056976 465.191101,290.063568 
	C463.513184,288.665924 462.463318,287.791443 461.613556,287.083618 
	C460.640564,289.014801 459.807526,290.668213 458.892334,292.638916 
	C457.353668,293.043671 455.897156,293.131042 453.347595,293.283997 
	C453.928009,289.931152 454.461761,286.847900 454.988831,283.371185 
	C456.351013,282.288391 457.719879,281.599060 459.192780,280.607971 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M470.534668,270.949158 
	C470.749664,273.861145 470.618500,276.781494 470.481781,279.826355 
	C469.215271,279.826355 467.605865,279.826355 465.996460,279.826355 
	C466.160706,279.472137 466.324951,279.117889 466.489197,278.763672 
	C465.099274,277.698822 463.709320,276.634003 462.064880,275.302917 
	C466.107391,271.749268 461.773560,270.491974 460.148254,268.253693 
	C461.067657,265.865845 462.915253,264.572998 464.015686,267.257721 
	C465.316406,270.431030 467.589966,270.650879 470.534668,270.949158 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M406.996002,287.965057 
	C405.997162,286.558624 404.998291,285.152222 403.998474,283.369232 
	C405.368591,282.710968 406.737579,282.214233 408.111145,282.201324 
	C409.874939,282.184723 411.642456,282.556793 413.718323,283.183960 
	C414.019287,284.414368 414.010254,285.222260 413.721588,286.214844 
	C412.068939,290.115112 409.268646,287.953827 406.996002,287.965057 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M441.028168,272.269104 
	C438.524017,273.663727 438.524017,273.663727 437.360504,269.552399 
	C439.457306,269.148468 441.694946,268.023438 441.028168,272.269104 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M414.001221,286.030121 
	C414.010254,285.222260 414.019287,284.414368 414.018921,283.197784 
	C414.813599,282.212799 415.617737,281.636566 416.741272,281.070435 
	C416.383942,283.441467 415.707184,285.802338 414.765503,288.378204 
	C414.334137,287.738831 414.167664,286.884460 414.001221,286.030121 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M423.004639,270.995544 
	C423.495544,271.876923 423.981750,272.762726 424.467987,273.648560 
	C424.011322,273.801819 423.554688,273.955078 423.098053,274.108337 
	C423.053925,273.297546 423.009766,272.486725 422.977905,271.342712 
	C422.990143,271.009460 422.999908,271.000031 423.004639,270.995544 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M438.654602,277.969818 
	C438.332672,277.446747 438.203644,276.755890 438.074615,276.065002 
	C438.278625,276.035278 438.482605,276.005554 438.686615,275.975800 
	C438.740234,276.584534 438.793854,277.193268 438.654602,277.969818 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M695.902344,669.642944 
	C695.892822,668.329224 695.984192,667.395569 695.959839,666.196777 
	C695.893860,663.327087 695.982056,660.722595 695.985901,658.117920 
	C695.996399,650.975769 696.710022,650.250488 704.842224,650.138184 
	C705.978333,650.342651 706.426636,650.391907 706.875000,650.441223 
	C706.875000,650.441223 707.336487,650.457886 707.420410,650.196838 
	C707.504333,649.935791 707.440735,650.004028 707.440735,650.004028 
	C707.394226,650.226929 707.347717,650.449829 707.670898,651.214600 
	C708.498596,653.183289 708.956482,654.610046 709.414429,656.036743 
	C709.344482,658.726318 709.274597,661.415894 709.315430,664.542236 
	C709.321838,666.610718 709.138733,668.242004 709.128418,669.874390 
	C709.100586,674.263123 707.639648,677.267456 702.604004,677.278076 
	C697.664124,677.288513 695.915344,674.522400 695.902344,669.642944 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M309.198547,650.672485 
	C310.034760,653.164490 311.411713,657.281921 310.839813,657.573181 
	C307.852264,659.094910 304.369843,659.645081 301.056183,660.534058 
	C299.284058,652.377075 301.471954,649.996521 309.198547,650.672485 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M318.958832,516.924622 
	C318.447601,515.813477 317.936340,514.702393 317.244629,513.320068 
	C318.832336,512.730530 320.590942,512.243530 322.371460,512.142212 
	C324.081360,512.044922 325.813965,512.347290 328.719330,512.561035 
	C325.699097,509.214600 323.298767,506.647491 321.012726,503.982391 
	C319.895782,502.680298 318.991486,501.195831 317.960938,499.429565 
	C318.180939,495.605896 318.431000,492.146606 318.835632,488.325500 
	C321.930359,485.273163 323.230865,489.908295 326.154846,489.368958 
	C329.792969,492.253967 330.155792,489.586914 331.042175,487.270447 
	C332.010162,484.740540 332.934631,481.966675 334.675446,480.007751 
	C338.164246,476.081879 342.196014,472.638550 346.250916,468.996826 
	C346.498138,468.997589 346.992340,468.978363 347.238464,468.994476 
	C347.484589,469.010590 347.977875,469.010773 347.979218,469.369263 
	C347.983307,470.679382 347.986023,471.630981 348.116302,472.908875 
	C348.851440,473.487305 349.458984,473.739471 350.033997,473.994568 
	C350.001465,473.997467 350.011810,473.932953 350.002899,474.308929 
	C351.049530,476.803009 352.105103,478.921112 353.272949,481.404785 
	C357.372223,493.146301 353.152466,504.227600 341.373077,513.378113 
	C342.320496,505.691956 343.272949,497.964966 344.225403,490.237976 
	C344.026672,490.218323 343.827972,490.198669 343.629242,490.179047 
	C341.353699,499.110291 339.078156,508.041565 336.667511,516.960815 
	C336.532440,516.948730 336.261353,516.957275 336.204468,516.500854 
	C335.832428,511.398651 335.517242,506.752869 335.202057,502.107086 
	C333.034729,504.586639 335.947571,509.318573 330.311035,509.358093 
	C330.498413,511.127991 330.702484,513.055542 330.602875,515.032471 
	C330.563843,516.033386 330.828491,516.985046 331.405945,517.976807 
	C332.120331,518.146729 332.521881,518.276428 332.943542,518.758911 
	C334.002777,519.827454 335.041931,520.543335 336.081116,521.259155 
	C334.770294,522.941101 333.425110,524.597839 332.166443,526.317932 
	C331.699188,526.956421 330.941589,528.147583 331.150787,528.402710 
	C335.211578,533.356140 329.664368,533.813171 327.021301,535.960571 
	C325.878418,535.985840 325.422272,535.974670 324.966156,535.963501 
	C324.966156,535.963501 324.976837,535.492859 324.988342,534.921997 
	C324.997253,533.904480 324.994659,533.457703 324.995453,533.006348 
	C324.998840,533.001770 325.007721,532.992554 325.334290,532.825562 
	C326.182587,531.440674 326.704285,530.222778 327.031738,529.458435 
	C325.106384,526.841797 318.731049,528.810364 322.209656,523.242065 
	C322.608643,522.019348 323.408234,520.216736 322.832642,519.170654 
	C322.216400,518.050598 320.304962,517.643127 318.958832,516.924622 
M329.476807,528.593628 
	C329.476807,528.593628 329.399445,528.473389 329.476807,528.593628 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M369.008118,507.999786 
	C366.748291,508.773895 364.488464,509.548004 361.559875,510.311279 
	C360.596588,510.564270 360.302094,510.828094 360.007629,511.091919 
	C359.683380,511.219696 359.359192,511.347504 358.428040,511.247009 
	C357.431793,510.580078 357.042450,510.141479 356.684052,509.712433 
	C356.714996,509.721954 356.776031,509.733063 356.959839,509.779175 
	C357.257843,509.782440 357.469086,509.741180 357.469849,509.696442 
	C357.473511,509.478455 357.420868,509.259552 357.375427,508.886932 
	C357.362671,508.732910 357.300110,508.430206 357.652954,508.368317 
	C369.531067,503.023376 381.067963,497.765472 392.574402,492.441742 
	C395.968445,490.871429 399.276489,489.115204 402.868500,487.461578 
	C403.448212,487.444122 403.758057,487.336304 404.341797,487.124420 
	C404.951813,486.852234 405.262573,486.611176 405.692017,486.417114 
	C405.810669,486.464081 406.024414,486.324585 406.395599,486.297729 
	C408.520752,485.552551 410.274719,484.834198 412.372742,484.075195 
	C414.167297,483.410431 415.617828,482.786346 417.449585,482.145996 
	C422.321289,480.037720 426.811707,477.945679 431.523743,475.792053 
	C431.745331,475.730499 432.076385,475.411163 432.413879,475.354919 
	C433.596985,474.655792 434.442627,474.012939 435.287537,473.315796 
	C435.286743,473.261505 435.178375,473.254395 435.532837,473.223450 
	C440.337067,469.844360 444.786835,466.496185 449.506165,463.023132 
	C450.005615,462.403534 450.235504,461.908813 450.441254,461.329163 
	C450.417114,461.244232 450.383240,461.417572 450.720398,461.402832 
	C451.505402,460.626953 451.953308,459.865814 452.603455,459.077576 
	C453.071655,458.891907 453.184021,458.659241 453.471588,458.234253 
	C465.421753,445.153015 470.310028,430.210266 467.481506,412.889984 
	C468.356720,412.761047 468.849182,412.628265 469.454071,412.774017 
	C469.626617,413.243591 469.686737,413.434601 469.565765,414.004944 
	C469.996246,417.799133 470.971558,421.194275 471.157776,424.632141 
	C472.070801,441.490845 465.148804,455.178650 453.742676,467.062561 
	C452.159576,468.711975 451.355377,471.108978 450.108337,473.525635 
	C450.118805,474.600159 450.211517,475.308990 450.136383,476.238464 
	C449.845490,476.597015 449.722443,476.734955 449.229248,476.837341 
	C435.864716,481.588837 422.824554,486.256744 409.899628,491.224579 
	C405.088898,493.073639 400.547760,495.624237 395.488159,497.890564 
	C392.377594,498.605957 389.662537,499.285675 386.669495,500.101074 
	C382.621552,501.163055 378.743774,501.800781 375.131805,503.150085 
	C373.569763,503.733612 372.556458,505.786102 371.293915,507.171356 
	C371.293915,507.171356 371.181366,507.236298 370.833862,507.198914 
	C369.993591,507.440948 369.500854,507.720367 369.008118,507.999786 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M364.230835,513.681091 
	C372.269043,511.389984 380.176910,508.298889 388.370911,506.963623 
	C409.651306,503.495728 429.841187,508.322754 449.913940,516.867188 
	C450.743774,517.247803 450.975189,517.479919 451.230957,518.026184 
	C451.629242,518.393799 451.957336,518.492981 452.327087,518.618652 
	C452.368713,518.645081 452.460327,518.681763 452.308533,519.037842 
	C452.115295,519.899048 452.073853,520.404175 451.733948,520.974548 
	C451.033997,521.108154 450.632446,521.176392 449.901367,521.045654 
	C440.980255,517.844971 432.412201,514.771729 423.767426,511.931885 
	C422.679901,511.574615 421.188568,512.446533 419.857910,512.775146 
	C419.831451,512.801392 419.763611,512.825684 419.653442,512.814453 
	C419.543274,512.803284 419.321838,512.798523 418.953735,512.767090 
	C418.094116,512.755005 417.602631,512.774231 417.016357,512.473755 
	C415.617218,512.074707 414.312897,511.995422 412.592133,511.859009 
	C410.783966,511.812744 409.392242,511.823669 407.757843,511.577820 
	C403.392914,508.553955 401.060211,510.231323 399.919098,514.877991 
	C399.873688,507.102722 396.861206,509.618195 393.737122,512.622681 
	C393.183197,511.454010 392.669586,510.370331 392.516083,510.046509 
	C388.156342,511.599579 384.247314,512.951904 380.367950,514.384521 
	C375.848846,516.053345 371.355682,517.792542 366.533020,519.589722 
	C365.421173,520.044434 364.627533,520.410522 363.455994,520.789185 
	C361.384827,521.200867 359.691559,521.599915 357.874939,521.697388 
	C358.131897,520.578918 358.343231,519.606628 358.924438,518.974487 
	C360.613800,517.137024 362.451385,515.435791 364.230835,513.681091 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M363.814392,513.663086 
	C362.451385,515.435791 360.613800,517.137024 358.924438,518.974487 
	C358.343231,519.606628 358.131897,520.578918 357.566284,521.873230 
	C340.142303,531.306335 327.871826,544.285645 323.485046,564.158936 
	C323.204224,571.642822 323.194214,578.837036 323.205414,586.462158 
	C323.144379,588.967041 323.062164,591.041138 322.979919,593.115173 
	C322.979950,593.115173 322.934570,592.991150 322.785217,592.787842 
	C322.526703,592.264099 322.337708,592.000916 322.068817,591.795105 
	C320.895538,586.459595 319.722260,581.124023 318.614197,574.967346 
	C318.576263,572.794189 318.473114,571.442139 318.369995,570.090149 
	C318.877838,566.676514 319.385712,563.262817 319.887787,559.441650 
	C324.258240,547.254211 329.997803,536.387146 339.657196,527.914185 
	C346.737274,521.703735 354.374542,516.556458 363.814392,513.663086 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M450.696075,513.621033 
	C450.558014,513.243286 450.419983,512.865601 450.235291,511.930054 
	C451.856720,511.177826 453.705933,510.413971 455.164581,510.877960 
	C468.397614,515.087524 481.562469,519.511475 494.384369,524.109741 
	C492.363098,524.920471 490.731995,525.880127 489.041534,525.999512 
	C482.529968,526.459290 475.999390,526.650269 469.356873,526.645264 
	C463.057251,522.106384 456.876678,517.863708 450.696075,513.621033 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M450.482239,513.974731 
	C456.876678,517.863708 463.057251,522.106384 469.525391,526.966553 
	C472.034912,530.076233 474.256866,532.568359 476.574036,535.430054 
	C478.802673,539.497498 480.936096,543.195374 483.175781,547.250977 
	C484.142059,550.101746 485.166504,552.551453 485.834839,555.094849 
	C489.498077,569.036194 489.491058,582.787720 483.067505,596.106812 
	C482.475433,596.029358 482.237701,596.014648 481.994263,595.628784 
	C480.771423,592.818542 479.554291,590.379578 478.337189,587.940552 
	C478.861572,587.679688 479.385925,587.418762 479.910309,587.157837 
	C480.651276,588.053223 481.392212,588.948608 482.133148,589.843994 
	C482.595917,589.769958 483.058685,589.695923 483.521454,589.621887 
	C483.521454,585.595581 483.521454,581.569336 483.521454,577.255127 
	C486.903412,574.301636 484.887573,571.606201 481.990265,568.593018 
	C482.663208,566.047974 483.921783,563.892273 483.897461,561.751160 
	C483.807648,553.844055 479.827515,547.180664 476.315125,540.415833 
	C476.172699,540.141479 475.401398,540.193726 474.687256,539.896667 
	C473.962463,538.795044 473.473450,537.890564 472.892212,536.663208 
	C470.298798,533.500366 467.797638,530.660461 465.085876,527.551697 
	C460.736969,524.415833 456.598633,521.548767 452.460327,518.681763 
	C452.460327,518.681763 452.368713,518.645081 452.213928,518.398193 
	C451.811615,517.901489 451.512146,517.770325 451.160736,517.757751 
	C450.975189,517.479919 450.743774,517.247803 450.271606,516.908691 
	C450.140625,515.946838 450.204529,515.137634 450.482239,513.974731 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M494.747253,523.872681 
	C481.562469,519.511475 468.397614,515.087524 455.164581,510.877960 
	C453.705933,510.413971 451.856720,511.177826 450.024963,511.712646 
	C445.276855,510.660736 440.692444,509.268402 436.303711,507.466614 
	C440.296875,507.766418 444.094330,508.475677 448.376038,509.275391 
	C450.676422,505.373932 454.265228,508.340973 457.598511,508.772278 
	C461.055328,509.219543 464.507080,509.705750 468.271240,510.098175 
	C469.400848,510.230804 470.220337,510.440186 471.406677,510.830017 
	C472.882812,511.231659 474.071198,511.267670 475.089172,511.702576 
	C485.965607,516.349609 496.810638,521.070129 507.676056,525.743225 
	C509.670197,526.600891 511.839691,528.611511 513.776367,525.152954 
	C515.069153,525.400696 516.042847,525.691467 517.013794,526.331665 
	C517.641357,527.480469 518.271606,528.279968 518.936768,529.397095 
	C518.991516,530.140442 519.011475,530.566040 518.741638,531.210327 
	C518.306641,532.130127 518.161438,532.831238 517.595642,533.508423 
	C516.066406,533.502563 514.957764,533.520691 513.815125,533.150146 
	C512.186218,532.176270 510.591370,531.591064 508.962799,530.686646 
	C507.612976,529.853882 506.296844,529.340332 504.654053,528.843628 
	C503.891693,528.855164 503.455994,528.849854 502.981506,528.533142 
	C502.297791,527.814209 501.652924,527.406738 500.778748,526.753662 
	C498.615387,525.629700 496.681305,524.751221 494.747253,523.872681 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M467.961060,510.174927 
	C464.507080,509.705750 461.055328,509.219543 457.598511,508.772278 
	C454.265228,508.340973 450.676422,505.373932 448.376038,509.275391 
	C444.094330,508.475677 440.296875,507.766418 436.061066,507.195740 
	C422.122894,505.801697 408.623047,504.269073 394.707703,502.440247 
	C395.253265,501.598358 396.214325,501.052643 397.477783,500.531677 
	C398.781708,500.581665 399.783203,500.606903 401.155334,500.755432 
	C402.684845,500.593231 403.843750,500.307770 405.469208,500.007141 
	C413.631653,500.296326 421.327515,500.600677 429.447510,501.010101 
	C435.623749,501.991272 441.375854,502.867371 447.486389,503.894226 
	C454.550262,506.088318 461.255646,508.131622 467.961060,510.174927 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M336.802582,516.972839 
	C339.078156,508.041565 341.353699,499.110291 343.629242,490.179047 
	C343.827972,490.198669 344.026672,490.218323 344.225403,490.237976 
	C343.272949,497.964966 342.320496,505.691956 341.373077,513.378113 
	C353.152466,504.227600 357.372223,493.146301 353.560150,481.425018 
	C354.119354,481.092987 354.503693,481.106354 354.963074,481.430969 
	C355.650909,482.859436 356.263641,483.976685 356.889465,485.469055 
	C357.858612,487.636414 358.814606,489.428650 359.788300,491.583160 
	C357.674652,495.484894 355.543335,499.024384 353.240875,502.712952 
	C353.408569,503.947510 353.747437,505.032959 353.853149,506.358643 
	C351.782715,508.189728 349.817444,509.658813 348.149750,511.411072 
	C346.886749,512.738159 346.003326,514.426453 344.973755,515.977905 
	C344.996521,516.001770 344.963196,515.945496 344.675964,515.666870 
	C343.913635,515.911560 343.438538,516.434875 342.950500,516.945679 
	C342.937561,516.933167 342.902222,516.908875 342.902222,516.908875 
	C341.170593,517.687744 339.438965,518.466614 337.370911,518.921875 
	C336.957214,518.056519 336.879883,517.514648 336.802582,516.972839 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M397.175385,500.506927 
	C396.214325,501.052643 395.253265,501.598358 394.255188,502.419739 
	C390.885040,503.399567 387.557892,504.133545 384.217285,504.800323 
	C380.173279,505.607513 376.117950,506.357880 371.680725,507.152130 
	C372.556458,505.786102 373.569763,503.733612 375.131805,503.150085 
	C378.743774,501.800781 382.621552,501.163055 386.920837,500.401733 
	C390.691895,500.546783 393.933655,500.526855 397.175385,500.506927 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M322.005005,522.995117 
	C318.731049,528.810364 325.106384,526.841797 327.031738,529.458435 
	C326.704285,530.222778 326.182587,531.440674 325.328857,532.517456 
	C324.474396,528.225952 322.944031,532.522522 321.986633,532.017212 
	C321.551727,532.022705 321.116852,532.028198 320.103729,532.028381 
	C319.357574,532.028870 319.189606,532.034668 319.021667,532.040405 
	C318.848175,531.155212 318.674652,530.269958 318.244965,528.727051 
	C317.988800,525.325195 317.988800,522.581177 317.988800,519.720825 
	C319.476379,519.440369 320.645630,519.219910 322.005005,518.963623 
	C322.005005,520.404907 322.005005,521.700012 322.005005,522.995117 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M356.653137,509.702881 
	C357.042450,510.141479 357.431793,510.580078 358.171295,511.297363 
	C353.102051,514.786377 347.682617,517.996704 341.568665,521.618469 
	C342.437439,519.630859 342.898346,518.576355 343.130737,517.215393 
	C342.902222,516.908875 342.937561,516.933167 343.285431,517.146118 
	C344.076599,516.887817 344.519897,516.416687 344.963196,515.945496 
	C344.963196,515.945496 344.996521,516.001770 345.319580,516.133972 
	C349.312836,514.078430 352.983002,511.890656 356.653137,509.702881 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M336.667511,516.960815 
	C336.879883,517.514648 336.957214,518.056519 337.011353,519.015503 
	C336.832672,519.926025 336.677155,520.419434 336.301392,521.085938 
	C335.041931,520.543335 334.002777,519.827454 333.294922,518.622192 
	C334.504639,517.740967 335.382996,517.349121 336.261353,516.957275 
	C336.261353,516.957275 336.532440,516.948730 336.667511,516.960815 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M324.770935,536.209839 
	C325.422272,535.974670 325.878418,535.985840 326.676727,536.003906 
	C326.086700,537.601196 325.154510,539.191528 324.222290,540.781860 
	C323.733734,540.551331 323.245209,540.320862 322.756653,540.090332 
	C323.363037,538.878967 323.969391,537.667603 324.770935,536.209839 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M322.028778,532.326416 
	C322.944031,532.522522 324.474396,528.225952 325.002258,532.684448 
	C325.007721,532.992554 324.998840,533.001770 324.754028,533.119385 
	C324.294250,533.518616 324.079285,533.800232 323.864258,534.081848 
	C323.266479,533.599792 322.668701,533.117737 322.028778,532.326416 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M339.862427,523.630432 
	C339.365997,523.776917 338.891876,523.654114 338.417786,523.531311 
	C338.599060,523.165894 338.780334,522.800537 338.961609,522.435120 
	C339.269348,522.743774 339.577057,523.052490 339.862427,523.630432 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M334.989807,526.993408 
	C335.224762,526.464172 335.459686,525.934998 335.694580,525.405762 
	C335.904327,525.516052 336.114075,525.626282 336.323853,525.736511 
	C336.046356,526.083130 335.768860,526.429810 335.246277,526.888794 
	C335.001221,527.001160 334.989807,526.993408 334.989807,526.993408 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M369.299988,508.022827 
	C369.500854,507.720367 369.993591,507.440948 370.778076,507.232269 
	C370.577179,507.550598 370.084534,507.798218 369.299988,508.022827 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M335.006592,527.006836 
	C334.880554,527.438904 334.749176,527.865417 334.617767,528.291870 
	C334.608154,527.943420 334.598572,527.595032 334.789398,527.119995 
	C334.989807,526.993408 335.001221,527.001160 335.006592,527.006836 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M324.000366,534.297974 
	C324.079285,533.800232 324.294250,533.518616 324.750671,533.123962 
	C324.994659,533.457703 324.997253,533.904480 324.998108,534.686829 
	C324.709686,534.853027 324.423065,534.683594 324.000366,534.297974 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M318.956421,533.735168 
	C318.773438,533.805908 318.605042,533.643372 318.483734,533.451111 
	C318.458313,533.410828 318.606476,533.260986 318.839539,533.080811 
	C319.004364,533.000000 318.987701,533.491333 318.956421,533.735168 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M319.016357,532.283936 
	C319.189606,532.034668 319.357574,532.028870 319.777557,532.034790 
	C319.898407,532.343506 319.702789,532.589050 319.235291,532.898804 
	C319.027863,533.014404 319.011078,532.527466 319.016357,532.283936 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M360.300659,511.077820 
	C360.302094,510.828094 360.596588,510.564270 361.201660,510.332581 
	C361.206085,510.597687 360.899902,510.830719 360.300659,511.077820 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M366.982605,729.627686 
	C366.448364,730.281738 365.961792,730.560608 365.215698,730.951538 
	C364.974762,729.006897 364.993317,726.950195 364.998077,724.455811 
	C365.881561,722.731323 366.551636,721.171387 367.714600,720.204041 
	C373.445435,715.437439 378.239716,717.417114 379.637451,724.702209 
	C375.397583,726.232483 371.213928,727.742493 366.982605,729.627686 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M389.858459,670.450317 
	C389.825256,668.939636 389.893188,667.817139 389.841583,666.286133 
	C389.764587,663.586060 389.833649,661.294739 389.844910,659.003113 
	C389.886780,650.479492 390.992493,649.490784 400.213440,650.531982 
	C401.397766,651.297485 402.019714,651.826904 402.641663,652.356323 
	C402.641663,652.356323 402.937286,652.116699 402.733093,652.568420 
	C402.739655,653.684692 402.950439,654.349182 403.161194,655.013733 
	C403.108368,658.378418 403.055542,661.743042 403.156128,665.541504 
	C403.155029,667.612061 402.934937,669.245789 402.858887,670.886292 
	C402.666016,675.047913 400.719788,677.300720 396.365570,677.223572 
	C392.130798,677.148560 389.882751,675.186646 389.858459,670.450317 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M403.524536,655.013489 
	C402.950439,654.349182 402.739655,653.684692 402.572723,652.670837 
	C403.856232,652.321533 405.095856,652.321533 406.335510,652.321533 
	C406.493561,652.620728 406.651642,652.919922 406.809723,653.219177 
	C405.835785,653.817200 404.861847,654.415222 403.524536,655.013489 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M389.344971,642.384888 
	C390.059998,642.325623 390.629852,642.487183 391.639343,642.718018 
	C391.119232,642.726746 390.159485,642.666260 389.344971,642.384888 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M402.715637,651.969788 
	C402.019714,651.826904 401.397766,651.297485 400.620422,650.496216 
	C401.239868,650.677368 402.014771,651.130310 402.715637,651.969788 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M639.904053,650.649231 
	C642.070801,649.907043 643.914001,649.357422 646.123474,648.698669 
	C646.620667,650.662537 647.028931,652.275513 647.761475,655.169067 
	C644.426575,654.468750 641.770020,653.928101 639.148560,653.251526 
	C639.089172,653.236145 639.427612,651.679504 639.904053,650.649231 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M345.201660,650.184692 
	C344.925720,650.784790 344.275757,651.321533 343.314606,651.930176 
	C343.611481,651.375244 344.219574,650.748291 345.201660,650.184692 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M709.699707,655.976074 
	C708.956482,654.610046 708.498596,653.183289 707.965149,651.388062 
	C712.614868,650.928162 710.054321,653.954895 709.699707,655.976074 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M706.896545,650.119385 
	C706.426636,650.391907 705.978333,650.342651 705.257935,650.080017 
	C705.302124,649.390869 705.618347,648.914978 705.934631,648.439148 
	C706.262451,648.891968 706.590271,649.344727 706.896545,650.119385 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M707.469360,649.971069 
	C707.940247,649.851257 708.446167,649.696106 708.952087,649.540894 
	C708.934998,649.650391 708.940002,649.844482 708.897278,649.855530 
	C708.605652,649.931519 708.304016,649.969055 707.723145,650.011536 
	C707.440735,650.004028 707.504333,649.935791 707.469360,649.971069 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M696.521484,649.537476 
	C696.480835,649.559570 696.562195,649.515442 696.521484,649.537476 
z"/>
<path fill="#203428" opacity="1.000000" stroke="none" 
	d="
M713.003906,649.005493 
	C712.920837,649.045532 712.833862,649.079956 712.867920,649.053345 
	C712.989014,648.992249 713.000000,649.000000 713.003906,649.005493 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M478.380280,277.933411 
	C478.803070,276.492554 479.590454,275.060059 480.474701,273.451324 
	C474.804810,270.820648 476.352936,277.687256 473.240021,278.586884 
	C472.737549,276.408966 472.135284,273.798553 471.238647,271.024719 
	C471.006439,269.548645 471.068573,268.235962 471.111755,267.323730 
	C473.003723,266.852051 474.616211,266.450073 476.531769,265.972504 
	C476.531769,267.149628 476.531769,268.786438 476.531769,270.681793 
	C479.555908,269.270447 479.321411,263.609711 484.189880,265.314880 
	C483.897308,266.365051 483.615662,267.375885 482.995087,269.603271 
	C484.930145,268.125793 485.909088,267.403229 486.860413,266.645996 
	C488.182343,265.593811 489.623596,263.847290 489.658447,267.703857 
	C488.933868,268.532104 488.347504,268.906403 488.205444,269.406830 
	C488.106354,269.755859 488.616211,270.277771 489.043396,271.076477 
	C489.517273,270.192932 489.803192,269.826630 489.910950,269.413879 
	C490.027893,268.965973 489.979004,268.474762 490.002441,268.002441 
	C492.082458,268.002441 494.162476,268.002441 496.654907,268.002441 
	C496.785431,267.642487 497.139313,266.666473 497.670654,265.201080 
	C499.079224,267.012573 500.198822,268.452484 501.910492,269.941345 
	C502.671204,269.984711 502.839844,269.979156 503.008453,269.973572 
	C503.450684,269.985077 503.892883,269.996582 504.666931,270.008179 
	C503.747498,271.328094 502.638824,272.841583 501.197418,273.903381 
	C500.142853,274.680206 498.650909,274.863342 496.888000,275.410858 
	C495.571106,272.399628 493.263885,272.881622 490.958984,273.263824 
	C487.089447,273.905518 485.465363,276.839081 484.017151,279.969757 
	C482.259735,279.293732 480.502319,278.617737 478.380280,277.933411 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M502.989990,269.693909 
	C502.839844,269.979156 502.671204,269.984711 502.249451,269.994263 
	C502.017029,269.558105 502.037689,269.117950 502.114807,268.352844 
	C502.438019,268.490021 502.704773,268.952118 502.989990,269.693909 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M451.460022,264.413269 
	C451.526428,264.366150 451.393616,264.460358 451.460022,264.413269 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M727.204712,732.151001 
	C722.442566,733.034668 719.197754,731.007751 718.090210,727.088379 
	C717.083191,723.524536 717.705872,718.976379 722.174805,718.368530 
	C725.092346,717.971619 729.178650,719.386780 731.282959,721.466125 
	C734.058716,724.208984 731.866089,729.180115 727.204712,732.151001 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M717.160217,744.904663 
	C715.863892,740.967407 717.963806,738.426392 721.798828,739.173767 
	C726.126099,740.017151 730.360779,741.335632 734.671509,742.456726 
	C731.893982,747.415588 727.429382,748.057983 717.160217,744.904663 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M737.726196,725.240234 
	C739.270874,725.236084 740.768555,725.575684 742.645264,725.954224 
	C743.048340,729.653809 743.072327,733.314514 743.069458,737.757324 
	C743.068176,738.756897 743.093811,738.974304 743.119385,739.191711 
	C742.659485,739.834595 742.199585,740.477417 741.430176,741.213989 
	C740.129089,738.998169 739.137573,736.688538 737.983582,734.000549 
	C737.354065,733.517578 736.188538,732.623352 735.015869,731.371948 
	C735.008728,731.014771 735.290466,730.844849 735.789673,730.855713 
	C736.451538,730.216431 736.614258,729.566223 736.776978,728.916016 
	C737.077698,727.805298 737.378418,726.694580 737.726196,725.240234 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M736.544922,729.078857 
	C736.614258,729.566223 736.451538,730.216431 735.950562,730.821594 
	C735.845764,730.264954 736.079285,729.753357 736.544922,729.078857 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M267.108765,662.785828 
	C265.825409,666.132202 264.499634,669.145569 263.010376,672.459229 
	C259.816162,673.400757 256.785431,674.042114 253.377197,674.823853 
	C251.846405,674.881165 250.693115,674.798096 248.821671,674.663269 
	C248.821671,669.473145 248.821671,664.238770 248.714142,658.553833 
	C248.723724,654.008545 248.840836,649.913757 248.848373,645.357910 
	C248.738815,641.779297 248.738815,638.661743 248.738815,634.990234 
	C255.546158,635.111389 262.195557,633.643616 266.478058,640.960144 
	C266.977386,642.887085 267.181519,644.645264 267.514343,647.167480 
	C267.686951,650.935974 267.730865,653.940552 267.774811,656.945129 
	C267.538666,658.781006 267.302490,660.616943 267.108765,662.785828 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M541.034241,601.998779 
	C539.363342,601.112183 537.692444,600.225647 535.662659,599.687622 
	C534.415344,600.000183 532.836182,600.185303 532.743103,599.894470 
	C531.533447,596.116699 530.810425,599.220337 529.627747,599.999878 
	C528.543884,599.312317 527.775024,598.659851 527.033630,597.704834 
	C527.380554,596.919250 527.700073,596.436218 528.395264,595.925537 
	C530.281433,595.288696 531.797729,594.153259 533.301392,594.169678 
	C537.669678,594.217163 538.465942,591.792358 538.168213,587.854492 
	C536.790039,587.567200 535.398682,587.277161 533.955322,586.638306 
	C532.995483,585.097839 532.087708,583.906128 531.600342,582.713440 
	C533.653259,582.923706 535.299866,583.059753 536.915527,583.361267 
	C540.782837,584.082703 541.643921,583.121826 540.431213,579.247131 
	C539.989868,577.837036 539.339172,576.489868 538.936646,575.070679 
	C537.376709,569.570190 538.671631,567.614197 543.563232,564.885315 
	C546.020081,563.514832 548.515259,560.883301 549.269104,558.315857 
	C549.684753,556.899963 546.532410,554.436401 545.126892,552.115112 
	C551.507080,551.102600 552.557678,555.646973 553.855286,560.706726 
	C550.245911,563.357300 546.375793,565.979309 542.795959,568.950989 
	C541.590210,569.951904 540.063538,572.433655 540.503479,573.218628 
	C542.189636,576.227478 544.615356,578.821838 547.367676,582.341309 
	C546.241577,583.205933 544.349304,584.658813 542.442871,586.122559 
	C543.222961,587.614990 544.106812,589.305969 544.680725,590.870972 
	C542.898926,591.143188 541.427063,591.541321 539.701538,592.149841 
	C539.948303,593.213867 540.448730,594.067505 541.321777,594.940857 
	C543.476379,593.653625 545.258301,592.346741 547.279907,591.020874 
	C547.519531,591.001953 547.999573,590.974365 548.002808,591.235107 
	C548.018616,591.669250 548.031189,591.842590 548.203735,592.263306 
	C548.908569,592.694580 549.453491,592.878601 550.208130,593.303650 
	C551.278503,593.029846 552.139282,592.514893 553.426941,591.997192 
	C557.907532,591.985718 561.961121,591.977112 566.115234,592.231323 
	C566.478699,592.988770 566.741577,593.483398 566.633057,593.994751 
	C563.955811,594.388733 561.513123,594.415039 559.384216,595.245728 
	C557.403137,596.018738 555.756348,597.648438 553.551025,598.904785 
	C551.773071,598.971130 550.406494,599.033813 548.700134,599.122803 
	C547.255676,600.082458 546.151001,601.015808 545.046265,601.949219 
	C543.913635,601.946655 542.780884,601.944153 541.322632,601.972412 
	C540.997009,602.003296 541.034241,601.998779 541.034241,601.998779 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M522.444214,612.897766 
	C521.723145,611.322693 521.355652,609.727173 520.885254,607.684692 
	C517.598022,612.605774 517.469482,607.365112 515.626648,605.885681 
	C515.193970,608.129028 514.869263,609.812378 514.026978,611.753174 
	C513.338318,612.005432 513.167297,612.000305 512.998108,611.997559 
	C513.000000,612.000000 513.004883,612.003906 512.991516,611.606079 
	C512.794800,610.404419 512.649414,609.589661 512.421387,608.798767 
	C511.595764,605.935364 511.400146,602.480652 507.430115,607.067749 
	C505.939270,608.790283 502.341644,608.689392 499.169830,609.567871 
	C500.036560,607.119141 500.582611,605.576477 501.128632,604.033813 
	C497.731812,606.249451 496.284515,604.403503 495.126190,601.726562 
	C495.505310,600.641296 495.769714,599.851868 496.336792,599.249268 
	C498.706879,600.523010 500.774323,601.609924 502.613892,602.577026 
	C503.624420,599.972656 504.193298,598.506592 504.984253,596.468140 
	C506.651031,598.702515 507.607483,599.984619 508.563904,601.266785 
	C508.898895,601.114563 509.233856,600.962280 509.568848,600.810059 
	C509.568848,599.338928 509.568848,597.867737 509.568848,595.247253 
	C511.305084,595.912903 512.585449,596.403809 513.549622,596.970703 
	C512.921936,597.771545 512.610474,598.496338 512.298950,599.221130 
	C512.881104,599.084839 513.463318,598.948486 514.322510,598.794800 
	C514.784424,598.784851 514.969360,598.792236 515.115112,599.181213 
	C515.533997,600.687378 515.991882,601.812073 516.511414,603.088074 
	C518.412231,601.390198 519.703735,600.236572 521.246582,599.063599 
	C521.665283,599.028748 521.832642,599.013184 522.300293,598.878967 
	C523.406799,598.497681 524.213074,598.234924 525.013855,597.978821 
	C525.008362,597.985413 525.027649,597.980896 525.133667,598.255066 
	C525.493225,600.004883 525.746826,601.480591 526.000488,602.956238 
	C524.932983,606.263245 523.865417,609.570312 522.444214,612.897766 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M529.942749,600.034912 
	C530.810425,599.220337 531.533447,596.116699 532.743103,599.894470 
	C532.836182,600.185303 534.415344,600.000183 535.607666,599.994019 
	C535.219849,601.056091 534.532410,602.163025 533.835266,603.263977 
	C532.812988,604.878357 531.783936,606.488464 530.545166,608.434265 
	C529.706238,608.373291 528.138306,608.259399 526.415100,608.134155 
	C526.938660,606.661682 527.459229,605.197571 528.235229,603.212341 
	C528.974792,601.805786 529.458740,600.920349 529.942749,600.034912 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M544.874634,602.208435 
	C546.151001,601.015808 547.255676,600.082458 548.658569,599.454956 
	C547.308838,604.810669 552.933899,601.720764 553.998108,604.933960 
	C553.998108,605.685242 553.998108,607.341064 553.998108,608.996948 
	C553.998108,608.996948 554.000000,609.000000 554.000000,609.000000 
	C553.132141,607.853455 552.264221,606.706848 551.794800,606.086670 
	C549.286865,606.086670 547.182007,606.245178 545.152771,605.924744 
	C544.828918,605.873596 544.844360,603.674011 544.874634,602.208435 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M540.998047,601.984619 
	C541.280518,603.383789 541.561829,604.801697 541.843201,606.219604 
	C541.436218,606.563599 541.029175,606.907654 540.622192,607.251709 
	C539.595886,606.202393 538.569580,605.153076 537.543274,604.103760 
	C538.449829,603.407410 539.356384,602.711060 540.648560,602.006714 
	C541.034241,601.998779 540.997009,602.003296 540.998047,601.984619 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M529.627747,599.999878 
	C529.458740,600.920349 528.974792,601.805786 528.235840,602.858276 
	C527.541321,602.995972 527.101624,602.966736 526.331177,602.946899 
	C525.746826,601.480591 525.493225,600.004883 525.463013,598.261047 
	C526.126343,597.997681 526.566284,598.002502 527.006165,598.007324 
	C527.775024,598.659851 528.543884,599.312317 529.627747,599.999878 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M261.158478,717.066895 
	C263.738831,716.681702 266.569580,716.472351 269.706085,716.496033 
	C270.012695,716.995728 270.013550,717.262451 270.014801,717.931641 
	C267.548615,718.449951 265.082031,718.565674 262.300079,718.799438 
	C261.792755,718.359192 261.600800,717.800964 261.158478,717.066895 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M258.026917,735.642456 
	C257.503662,735.100830 256.987335,734.218079 256.706360,733.161316 
	C257.305756,733.758667 257.669800,734.530029 258.026917,735.642456 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M240.783051,466.755920 
	C235.661896,469.701263 230.322708,472.343506 224.450043,475.249756 
	C224.253876,473.257690 224.130310,472.002716 224.004089,470.347595 
	C227.198700,468.413086 230.271973,466.414368 233.638885,465.515625 
	C235.716202,464.961121 238.241089,466.083344 240.783051,466.755920 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M224.662933,455.065765 
	C223.712646,460.165466 227.590271,459.863007 230.806732,460.808167 
	C229.229263,462.151215 227.496902,463.557404 225.525864,464.105896 
	C224.048798,464.516907 222.264465,463.823822 220.616943,463.622314 
	C221.038742,462.359497 221.678986,461.121002 221.823120,459.827240 
	C221.978973,458.428101 221.671692,456.977386 221.548264,455.303162 
	C222.398666,455.209412 223.343475,455.105255 224.662933,455.065765 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M232.968796,461.550720 
	C237.316025,459.148193 241.799347,457.112518 246.651093,455.043518 
	C245.150223,462.473328 240.271271,464.895294 232.968796,461.550720 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M346.003662,468.996033 
	C342.196014,472.638550 338.164246,476.081879 334.675446,480.007751 
	C332.934631,481.966675 332.010162,484.740540 331.042175,487.270447 
	C330.155792,489.586914 329.792969,492.253967 326.346924,489.121216 
	C326.824463,487.290161 327.595367,485.617157 328.366241,483.944153 
	C327.940399,483.708649 327.514526,483.473114 327.088684,483.237610 
	C326.369263,484.030029 325.649872,484.822418 324.990509,485.548706 
	C324.501282,482.389252 324.045044,479.443085 323.294403,476.248474 
	C322.999969,476.000061 322.983398,475.987396 322.983398,475.987366 
	C323.970154,475.870667 324.956879,475.753937 326.299286,475.595154 
	C326.547791,474.246887 326.992279,471.835541 327.709991,469.187988 
	C328.780853,469.620178 329.578430,470.288513 330.827332,471.300964 
	C331.917755,473.178986 332.556854,474.712891 333.512329,477.006195 
	C334.604492,473.453979 334.856384,471.215271 331.018555,471.011353 
	C331.294800,470.498871 331.571045,469.986389 332.228760,469.021973 
	C334.060699,466.362762 335.511169,464.155548 336.961670,461.948334 
	C336.961639,461.948334 337.000244,461.962646 337.212036,461.866089 
	C337.615814,461.497742 337.807831,461.225891 337.999847,460.954071 
	C337.999847,460.954071 337.989899,460.471100 338.281311,460.410553 
	C339.200134,461.645966 339.827484,462.941895 340.448608,464.224945 
	C340.811646,463.817261 341.200439,463.380646 341.644318,462.882172 
	C341.982910,464.524628 342.294006,466.033691 342.632294,467.674652 
	C344.054260,466.096527 345.030396,465.013184 346.002319,464.353882 
	C345.999969,466.183990 346.001831,467.589996 346.003662,468.996033 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M320.254944,464.173279 
	C320.744629,462.958313 321.030701,462.026978 321.317200,461.094269 
	C322.541992,462.176971 323.749969,463.244812 325.255615,464.575775 
	C323.043304,466.093292 321.093842,467.430542 319.144348,468.767792 
	C318.829651,468.604401 318.514954,468.441040 318.200256,468.277679 
	C318.817291,467.004089 319.434357,465.730499 320.254944,464.173279 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M323.055908,457.507202 
	C323.688812,457.558502 324.280792,457.925995 324.872742,458.293488 
	C324.502533,458.702942 324.132324,459.112396 323.762085,459.521851 
	C323.513062,458.955688 323.264038,458.389557 323.055908,457.507202 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M322.997467,452.002625 
	C322.490051,451.454742 321.982666,450.906860 321.475250,450.358948 
	C321.835968,450.123627 322.196716,449.888306 322.557465,449.652954 
	C322.710754,450.224457 322.864044,450.795929 323.008667,451.683716 
	C323.000000,452.000000 322.997467,452.002625 322.997467,452.002625 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M323.007324,476.007355 
	C322.853241,476.872406 322.691833,477.730164 322.530426,478.587921 
	C322.180023,478.461823 321.829620,478.335724 321.479187,478.209625 
	C321.786194,477.547913 322.093201,476.886230 322.691803,476.105957 
	C322.983398,475.987396 322.999969,476.000061 323.007324,476.007355 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M323.001831,451.998169 
	C323.531799,452.246155 324.073853,452.473358 324.568207,452.778015 
	C324.622437,452.811432 324.402557,453.289642 324.309814,453.561584 
	C323.894318,453.240692 323.478821,452.919800 323.030396,452.300751 
	C322.997467,452.002625 323.000000,452.000000 323.001831,451.998169 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M324.654968,446.674438 
	C324.640045,446.967499 324.485962,447.128235 324.331909,447.289001 
	C324.393219,447.040039 324.454498,446.791077 324.654968,446.674438 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M327.017609,465.017822 
	C326.891022,465.463928 326.748627,465.894897 326.606262,466.325836 
	C326.587891,465.960144 326.569519,465.594482 326.759094,465.102539 
	C326.967041,464.976288 327.001801,465.002716 327.017609,465.017822 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M704.029419,580.019714 
	C704.038513,579.258850 704.047607,578.498047 704.367432,577.144226 
	C705.794556,575.756836 706.951172,574.235657 708.020386,574.294922 
	C712.428406,574.539001 716.809875,575.262695 721.201111,575.810059 
	C721.624268,575.632751 722.047424,575.455505 722.749756,575.525879 
	C723.705627,576.521118 724.382324,577.268616 725.076782,578.375183 
	C724.986572,580.240479 724.878540,581.746643 724.770508,583.252869 
	C721.118469,583.116882 717.466492,582.980957 713.326050,582.504639 
	C709.901611,581.449524 706.965515,580.734619 704.029419,580.019714 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M721.118286,575.484253 
	C716.809875,575.262695 712.428406,574.539001 708.020386,574.294922 
	C706.951172,574.235657 705.794556,575.756836 704.412720,576.765991 
	C703.955383,576.989868 703.763306,576.999023 703.282959,576.993774 
	C703.863892,575.491333 704.733154,574.003357 705.768188,572.229980 
	C706.302429,571.192383 706.670715,570.440186 707.445374,569.366760 
	C710.264587,568.981506 712.677368,568.917480 715.090210,568.853516 
	C715.495117,568.906982 715.900085,568.960449 716.607666,569.054749 
	C718.285339,571.116577 719.660400,573.137451 721.118286,575.484253 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M724.865845,583.579224 
	C724.878540,581.746643 724.986572,580.240479 725.511475,578.368896 
	C729.404480,578.397766 734.466248,576.161804 734.100037,582.929871 
	C734.100037,582.929871 734.031250,583.026001 733.618408,583.029419 
	C731.765076,584.060730 730.324585,585.088501 728.884033,586.116333 
	C727.576416,585.379395 726.268799,584.642517 724.865845,583.579224 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M734.467529,582.874756 
	C734.466248,576.161804 729.404480,578.397766 725.493652,578.009888 
	C724.382324,577.268616 723.705627,576.521118 722.973694,575.475281 
	C726.369690,575.624939 729.908630,575.737671 733.248657,576.611145 
	C736.892761,577.564087 736.951599,580.065613 734.467529,582.874756 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M728.952881,586.459961 
	C730.324585,585.088501 731.765076,584.060730 733.571777,583.065735 
	C733.877747,584.321838 733.715027,585.550964 733.785461,586.766663 
	C733.859497,588.043579 734.156860,589.307495 734.356628,590.577087 
	C733.012451,589.987854 731.594971,589.518250 730.355225,588.758545 
	C729.741760,588.382568 729.455872,587.472168 728.952881,586.459961 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M703.989990,580.404663 
	C706.965515,580.734619 709.901611,581.449524 712.930420,582.528137 
	C709.608704,583.875488 706.035034,585.545898 703.989990,580.404663 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M702.717285,584.599487 
	C702.861023,584.414307 703.107971,584.385071 703.354919,584.355835 
	C703.176758,584.489075 702.998657,584.622253 702.717285,584.599487 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M426.895782,611.361450 
	C426.740112,611.141968 426.584473,610.922607 426.065369,610.123840 
	C425.463684,608.521362 425.225403,607.498169 424.959930,606.358276 
	C420.391724,605.517090 415.242950,609.291443 414.958038,613.958374 
	C413.541107,612.346008 413.182678,608.310486 414.012512,606.748718 
	C414.430206,605.962708 414.197723,604.831299 414.319885,602.893494 
	C412.688019,604.221680 411.897583,604.864990 410.936310,605.647339 
	C410.222137,604.491150 409.523163,603.359497 408.447571,602.146851 
	C408.070953,602.065857 407.973846,601.963379 408.094879,601.670898 
	C408.995483,600.990417 409.907104,600.129578 410.529846,600.303345 
	C412.380676,600.820129 414.117859,601.744019 415.940643,602.526367 
	C416.845612,600.488220 417.405731,599.226685 418.101471,597.659729 
	C420.733063,599.992981 424.035309,604.972107 423.645569,596.581665 
	C423.626923,596.180420 425.377777,595.697021 427.087280,594.875977 
	C426.233093,596.563660 425.819489,597.380798 425.253143,598.499756 
	C426.537903,598.499756 427.570526,598.499756 428.542267,598.499756 
	C428.981354,600.263855 429.358215,601.778137 429.406982,601.974121 
	C432.849579,600.930481 435.892151,600.008118 438.907928,599.438843 
	C439.283264,600.843628 439.415649,602.543762 440.131287,602.841309 
	C444.739258,604.757446 447.789673,602.157227 451.013062,597.269836 
	C451.013062,601.505676 451.013062,604.245667 450.680176,607.011597 
	C449.279724,607.939453 448.212158,608.841309 447.011383,609.855652 
	C446.824921,608.198914 446.673096,606.850037 446.546783,605.727722 
	C443.276611,608.303467 440.217529,610.712891 436.979828,613.263062 
	C435.814178,610.727661 435.021729,609.004028 434.140869,607.088013 
	C432.521027,608.358948 431.261383,609.347290 429.772949,610.498779 
	C428.661377,610.895142 427.778595,611.128296 426.895782,611.361450 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M393.001160,602.000000 
	C391.628448,602.258911 390.255676,602.517761 388.417603,602.393250 
	C387.633728,600.343262 387.315125,598.676636 387.183777,596.759583 
	C394.692810,594.000427 386.860413,591.289856 387.316376,587.774292 
	C391.693878,589.961731 395.141846,591.922913 395.010651,597.440186 
	C394.341492,599.190613 393.671326,600.595337 393.001160,602.000000 
M392.182861,599.618225 
	C392.228058,599.261536 392.273254,598.904907 392.318420,598.548218 
	C392.061920,598.541809 391.805450,598.535400 391.548981,598.529053 
	C391.592285,598.888062 391.635620,599.247009 392.182861,599.618225 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M408.122192,602.114380 
	C406.532837,604.364502 404.892242,606.566101 402.538086,609.725159 
	C402.538086,607.234924 402.538086,605.953247 402.538086,605.123840 
	C400.969055,604.937927 399.630920,604.779358 398.148315,603.992493 
	C398.366791,602.553406 398.729736,601.742676 399.383789,601.069092 
	C401.629730,599.895203 403.584534,598.584167 405.325409,597.416687 
	C405.935547,598.464111 406.954712,600.213745 407.973846,601.963379 
	C407.973846,601.963379 408.070953,602.065857 408.122192,602.114380 
M404.257568,602.187561 
	C404.426208,602.130371 404.594879,602.073181 404.763519,602.015991 
	C404.595520,601.961121 404.427490,601.906250 404.257568,602.187561 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M414.970947,614.297119 
	C415.242950,609.291443 420.391724,605.517090 424.959930,606.358276 
	C425.225403,607.498169 425.463684,608.521362 425.854980,609.779541 
	C420.852661,608.985840 419.213593,614.142761 415.300385,615.947693 
	C414.965057,615.538574 414.974426,615.087219 414.970947,614.297119 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M391.537231,621.199768 
	C391.479523,618.120605 391.757538,614.973755 392.054382,611.404968 
	C393.167419,613.387573 394.261627,615.792114 395.630890,618.801086 
	C394.432739,619.544250 393.152832,620.338135 391.537231,621.199768 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M399.092682,600.931885 
	C398.729736,601.742676 398.366791,602.553406 397.989288,603.722046 
	C396.543243,603.409851 395.111816,602.739746 393.340759,602.034790 
	C393.671326,600.595337 394.341492,599.190613 395.381744,597.415100 
	C396.495026,597.019165 397.238190,596.994080 398.250122,597.202515 
	C398.710144,598.601318 398.901398,599.766602 399.092682,600.931885 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M386.996552,597.010010 
	C387.315125,598.676636 387.633728,600.343262 388.044006,602.351562 
	C387.863708,602.776794 387.591797,602.860352 386.892609,603.001343 
	C385.182220,602.395752 383.899078,601.732727 382.342163,600.707031 
	C382.714203,599.194641 383.360016,598.045044 384.379211,596.918701 
	C385.500580,596.964722 386.248566,596.987366 386.996552,597.010010 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M413.941223,622.886230 
	C413.374573,621.801941 412.810730,620.244934 412.076233,618.374756 
	C412.449036,617.439819 412.992493,616.818115 413.844910,616.116089 
	C414.083893,618.161682 414.013977,620.287659 413.941223,622.886230 
z"/>
<path fill="#16281D" opacity="1.000000" stroke="none" 
	d="
M392.038635,610.583862 
	C391.369080,610.158203 390.706757,609.390869 390.058533,608.260498 
	C390.730347,608.679077 391.388092,609.460632 392.038635,610.583862 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M770.960083,500.000519 
	C767.653015,500.286316 764.687256,500.722931 760.833496,501.290314 
	C761.680420,498.343353 762.530457,495.385559 763.342896,492.558563 
	C767.066711,493.467987 770.560242,494.222900 773.993469,495.191406 
	C776.625793,495.933960 776.912781,497.531036 775.263855,501.904510 
	C773.932739,501.315552 772.617126,500.733429 770.960083,500.000519 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M512.996216,611.995117 
	C513.167297,612.000305 513.338318,612.005432 513.766113,612.022827 
	C515.452637,612.441345 516.882446,612.847595 518.592773,613.333496 
	C518.492310,613.515930 518.263855,614.644043 517.682434,614.872070 
	C513.338440,616.575623 509.287079,620.587830 504.066498,616.447754 
	C503.406799,615.924622 501.012665,617.588745 498.948212,618.433350 
	C494.044891,612.417419 490.347198,622.084167 485.347107,620.372925 
	C485.783051,618.642395 486.170959,617.102600 486.558838,615.562866 
	C483.860901,616.202637 481.162964,616.842468 477.611359,617.684692 
	C478.819794,615.992859 480.021484,614.310547 481.223175,612.628174 
	C481.132080,612.334290 481.040955,612.040405 480.949829,611.746521 
	C479.601776,612.479370 478.170288,613.095093 476.929932,613.979065 
	C475.772552,614.803833 474.818298,615.913635 473.436035,616.572754 
	C473.657471,613.426514 474.582703,610.621094 474.703552,607.781372 
	C474.979248,601.302734 474.860901,594.807251 475.242615,588.351135 
	C476.282776,592.869812 476.982880,597.355957 477.713104,602.035156 
	C478.930542,601.878845 479.871246,601.758057 480.904602,602.015991 
	C483.314941,604.209045 485.632629,606.023499 487.731781,607.666809 
	C488.631897,606.848267 490.233063,605.392212 492.015442,604.183228 
	C493.134949,603.627625 494.073212,602.825073 495.011475,602.022461 
	C496.284515,604.403503 497.731812,606.249451 501.128632,604.033813 
	C500.582611,605.576477 500.036560,607.119141 499.169830,609.567871 
	C502.341644,608.689392 505.939270,608.790283 507.430115,607.067749 
	C511.400146,602.480652 511.595764,605.935364 512.421387,608.798767 
	C512.649414,609.589661 512.794800,610.404419 512.847168,611.656372 
	C512.809509,612.068054 512.902893,612.031555 512.996216,611.995117 
M490.795410,614.386963 
	C490.791321,614.784851 490.787262,615.182739 490.783173,615.580627 
	C490.948547,615.581909 491.257599,615.594238 491.258484,615.582886 
	C491.288940,615.187439 491.290405,614.789856 490.795410,614.386963 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M474.902588,588.318604 
	C474.860901,594.807251 474.979248,601.302734 474.703552,607.781372 
	C474.582703,610.621094 473.657471,613.426514 473.078674,616.613525 
	C472.681580,618.331604 472.303284,619.683594 471.883484,621.382141 
	C471.558258,621.842468 471.323792,622.020874 471.087921,622.479614 
	C468.272766,623.211914 465.508240,623.728516 462.263000,623.863892 
	C461.914612,622.934143 462.011169,622.374084 462.185059,621.839172 
	C463.384613,618.148376 468.132935,615.802429 466.246826,610.653076 
	C466.174469,609.926880 466.158356,609.510010 466.452148,609.020020 
	C466.895660,607.948975 467.029327,606.950928 467.154358,605.569092 
	C467.397583,603.434021 467.649445,601.682739 468.097351,599.556274 
	C468.385834,593.179504 469.223480,587.071472 468.384003,581.203369 
	C467.310364,573.698059 464.884705,566.386169 463.405396,559.017822 
	C464.411743,559.510559 465.419922,559.845642 465.640564,560.462402 
	C467.836609,566.602112 469.830139,572.814697 472.066437,578.938965 
	C472.515656,580.169189 473.748291,581.113281 474.703796,582.612976 
	C474.826080,584.797302 474.864349,586.557983 474.902588,588.318604 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M505.136475,622.989014 
	C504.449493,622.835388 504.056946,622.656799 503.441528,622.494141 
	C503.757355,621.795593 504.296082,621.081177 504.834839,620.366821 
	C505.221283,620.986938 505.607727,621.606995 506.006256,622.562988 
	C505.822540,622.920593 505.626740,622.942322 505.136475,622.989014 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M508.839417,621.714294 
	C508.514771,622.196167 508.015106,622.462158 507.243408,622.802063 
	C507.535706,622.416809 508.100067,621.957581 508.839417,621.714294 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M517.913086,622.850098 
	C517.827393,623.057007 517.661255,623.091187 517.249634,623.059387 
	C517.280273,622.888000 517.556519,622.782654 517.913086,622.850098 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M305.585510,724.240845 
	C308.726166,723.814453 310.217590,725.125244 310.049622,728.286194 
	C309.974640,729.697083 310.360748,731.132385 310.855103,732.822021 
	C311.146942,733.780823 311.121246,734.474182 311.091248,735.711060 
	C311.147339,736.511292 311.271729,736.731995 311.460083,736.916626 
	C311.454163,737.084839 311.448242,737.253113 311.144714,737.763550 
	C308.890778,738.408813 306.934418,738.711914 304.978058,739.015076 
	C304.978058,739.015076 305.000000,739.000000 304.998169,738.730103 
	C304.979218,738.274536 304.962128,738.088928 304.945038,737.903381 
	C304.947662,736.867798 304.950287,735.832214 305.240112,734.196655 
	C305.340576,732.391968 305.153839,731.187317 304.967102,729.982605 
	C305.127869,728.188843 305.288635,726.395020 305.585510,724.240845 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M305.010437,739.380615 
	C306.934418,738.711914 308.890778,738.408813 311.128845,738.015625 
	C310.987671,739.215881 310.564789,740.506165 310.141907,741.796509 
	C308.442200,741.113037 306.742493,740.429626 305.010437,739.380615 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M304.695160,730.147461 
	C305.153839,731.187317 305.340576,732.391968 305.262024,733.808838 
	C304.233124,733.965027 303.469482,733.909058 302.293335,733.822754 
	C303.148682,732.413025 303.785950,731.362732 304.695160,730.147461 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M316.348816,738.287598 
	C317.637451,739.069336 318.792480,740.202271 319.975464,741.665039 
	C318.740692,740.876221 317.477936,739.757446 316.348816,738.287598 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M300.394257,715.028076 
	C301.209869,714.810608 302.424652,714.637085 303.821198,714.727478 
	C302.933105,715.018250 301.863281,715.045166 300.394257,715.028076 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M311.543091,736.713989 
	C311.271729,736.731995 311.147339,736.511292 311.076721,736.058716 
	C311.360168,735.986816 311.546692,736.202881 311.543091,736.713989 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M304.756409,738.005676 
	C304.962128,738.088928 304.979218,738.274536 304.989990,738.739868 
	C304.845062,738.715698 304.706390,738.411804 304.756409,738.005676 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M316.729065,735.953125 
	C316.943451,736.106567 316.881470,736.376953 316.586609,736.778687 
	C316.267120,736.626648 316.300140,736.346619 316.729065,735.953125 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M304.417297,717.273560 
	C304.679199,717.224731 304.818726,717.416260 304.937500,717.827393 
	C304.663757,717.946350 304.456512,717.768677 304.417297,717.273560 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M356.990723,303.990784 
	C357.430054,307.082855 357.621918,310.238464 358.393585,313.245239 
	C358.929169,315.332001 361.001434,317.307098 357.304932,318.522308 
	C356.760132,317.670654 356.530121,317.027649 356.300110,316.384674 
	C356.272064,316.923737 356.243988,317.462830 356.215942,318.001892 
	C354.143188,318.668335 352.070404,319.334778 349.998840,320.000610 
	C350.000000,320.000000 349.998779,320.001221 349.993958,319.654602 
	C349.201294,318.888977 348.307739,318.008728 347.650452,318.159363 
	C346.670074,318.384033 345.856262,319.335571 344.936523,319.619263 
	C344.610535,316.829254 344.319611,314.397827 344.256348,311.807556 
	C344.005402,310.998138 343.526855,310.347626 343.048279,309.697083 
	C342.820526,309.873993 342.592773,310.050903 342.365021,310.227783 
	C342.913696,310.808014 343.462341,311.388214 343.695038,311.992249 
	C342.882385,312.371124 342.385681,312.726166 341.888977,313.081207 
	C341.415710,313.478333 340.942444,313.875427 341.134491,313.714294 
	C339.534760,312.344391 338.160675,311.167725 336.419189,309.964050 
	C336.043243,309.214600 336.034668,308.492126 336.345337,307.435913 
	C337.537659,307.605774 338.410706,308.109436 339.332367,308.641144 
	C339.540497,306.199677 339.714935,304.153503 340.172821,301.888580 
	C340.834167,300.952515 341.029022,299.873230 341.618805,299.574982 
	C346.473450,297.119965 345.066498,294.537994 341.919708,291.838287 
	C341.937073,291.435120 341.954407,291.031952 342.309418,290.327179 
	C344.814636,288.115295 346.982208,286.205048 350.287720,283.291870 
	C347.103912,283.099213 345.519287,283.003326 343.934662,282.907440 
	C344.718445,281.942169 345.502258,280.976898 346.952850,279.862915 
	C348.435638,278.807251 349.251587,277.900330 350.376892,276.958801 
	C351.422791,275.322784 352.159302,273.721375 352.922607,272.556702 
	C353.038330,274.897217 353.127258,276.800995 353.255005,279.535614 
	C357.273071,278.779419 360.550537,278.162598 364.091187,277.496277 
	C364.156464,278.248871 364.257874,279.418060 364.278229,279.652649 
	C366.313385,280.550781 367.683075,281.155273 369.029602,282.170288 
	C368.673248,284.385406 368.340118,286.190063 368.003479,287.997345 
	C368.000000,288.000000 367.994659,288.007324 367.651794,288.057861 
	C366.880249,289.059021 366.451569,290.009644 365.751221,291.182312 
	C365.333313,293.901550 365.187103,296.398804 365.029602,299.239075 
	C365.018768,300.039215 365.019287,300.496246 365.019775,300.953308 
	C364.030579,303.766510 363.041382,306.579681 361.564606,310.779541 
	C359.527710,308.063782 358.275635,306.394440 357.347412,304.362915 
	C358.113373,304.001343 358.555481,304.001892 359.052673,304.144623 
	C359.197205,304.336090 359.286652,304.385376 359.376099,304.434662 
	C359.251556,304.288940 359.127014,304.143250 358.974060,303.669983 
	C358.490326,302.511932 358.035004,301.681458 356.990723,299.776672 
	C356.990723,302.061859 356.990723,303.026306 356.990723,303.990784 
M346.045441,310.116302 
	C346.844452,310.483398 347.643433,310.850494 348.442413,311.217590 
	C348.909973,310.125977 349.931915,308.917145 349.730957,307.966888 
	C349.298523,305.921875 348.236725,304.009949 347.409454,301.987152 
	C346.941559,304.563446 346.507996,306.950775 346.045441,310.116302 
M351.567566,314.643555 
	C351.834961,314.129333 352.102356,313.615112 352.369781,313.100891 
	C351.834473,312.877106 351.299133,312.653290 350.763824,312.429504 
	C350.856842,313.076874 350.949890,313.724213 351.567566,314.643555 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M341.601898,291.879791 
	C345.066498,294.537994 346.473450,297.119965 341.618805,299.574982 
	C341.029022,299.873230 340.834167,300.952515 339.933105,301.755524 
	C338.940063,301.560760 338.470215,301.280273 337.999969,300.749939 
	C337.999573,300.500092 337.999817,300.000183 338.000000,299.999939 
	C338.000214,299.999695 338.005615,299.992676 338.247498,299.825867 
	C338.667603,299.114960 338.845856,298.570892 339.024109,298.026794 
	C339.037659,297.591187 339.051178,297.155548 339.083008,296.394318 
	C339.828949,294.686218 340.556519,293.303741 341.601898,291.879791 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M318.707397,280.035278 
	C319.527039,281.528534 320.055878,283.014252 320.737000,284.825928 
	C319.838531,287.058197 318.787720,288.964539 317.391205,290.897797 
	C317.015808,290.497711 316.986145,290.070679 316.937256,289.322754 
	C317.004211,286.337036 317.090393,283.672211 317.219910,280.570923 
	C317.647675,280.098877 318.032104,280.063324 318.707397,280.035278 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M338.000366,300.999786 
	C338.470215,301.280273 338.940063,301.560760 339.649658,301.974274 
	C339.714935,304.153503 339.540497,306.199677 339.332367,308.641144 
	C338.410706,308.109436 337.537659,307.605774 336.349243,307.073792 
	C336.339874,305.249237 336.645905,303.453033 337.226074,301.328491 
	C337.666901,301.000031 337.833649,300.999908 338.000366,300.999786 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M330.313812,298.846069 
	C328.645538,296.997467 328.620087,295.095734 330.747803,293.175171 
	C330.869812,294.897888 330.731506,296.790863 330.313812,298.846069 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M329.277771,301.848328 
	C329.143738,301.064728 329.272491,300.071472 329.712311,299.056213 
	C329.862427,299.902374 329.701508,300.770538 329.277771,301.848328 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M338.772247,297.929504 
	C338.845856,298.570892 338.667603,299.114960 338.151245,299.597504 
	C337.613190,299.250336 337.342560,299.072083 337.001221,299.001221 
	C337.507599,298.611542 338.014008,298.221863 338.772247,297.929504 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M330.002686,323.262207 
	C330.182220,323.504059 330.359528,324.072784 330.304810,324.765137 
	C330.048645,324.455566 330.024536,324.022308 330.002686,323.262207 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M328.179474,303.936890 
	C328.129791,303.746979 328.346161,303.464386 328.795959,303.046021 
	C328.834747,303.221558 328.640137,303.532867 328.179474,303.936890 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M336.996674,299.250793 
	C337.342560,299.072083 337.613190,299.250336 337.909363,299.764313 
	C338.005615,299.992676 338.000214,299.999695 337.750214,300.000427 
	C337.500214,300.001129 337.000427,299.999481 337.000427,299.999481 
	C337.000427,299.999481 336.992126,299.500366 336.996674,299.250793 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M336.999939,300.249542 
	C337.000427,299.999481 337.500214,300.001129 337.750000,300.000671 
	C337.999817,300.000183 337.999573,300.500092 337.999969,300.749939 
	C337.833649,300.999908 337.666901,301.000031 337.250305,300.999939 
	C337.000092,300.833008 336.999786,300.666321 336.999939,300.249542 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M369.052765,281.759766 
	C367.683075,281.155273 366.313385,280.550781 364.278229,279.652649 
	C364.257874,279.418060 364.156464,278.248871 364.091187,277.496277 
	C360.550537,278.162598 357.273071,278.779419 353.255005,279.535614 
	C353.127258,276.800995 353.038330,274.897217 352.737122,272.247742 
	C351.343384,271.325531 350.161957,271.149048 348.981171,270.719238 
	C348.977173,270.297150 348.972534,270.128326 348.967865,269.959534 
	C349.725891,269.289856 350.483917,268.620178 351.593994,267.949707 
	C351.946075,267.948914 352.012634,268.008545 352.152374,268.229858 
	C352.843048,268.313385 353.393982,268.175629 353.964966,268.024200 
	C353.984985,268.010529 354.025574,267.983887 354.025574,267.983887 
	C356.469757,269.344971 358.913910,270.706085 361.760712,272.406799 
	C363.108887,273.497620 364.054443,274.248810 365.045959,275.150574 
	C365.185333,275.352783 365.278687,275.404419 365.372070,275.456055 
	C365.248047,275.304047 365.124023,275.152008 364.997284,274.641998 
	C364.938782,273.575226 364.882996,272.866455 364.827209,272.157684 
	C365.019470,272.124268 365.211792,272.090820 366.075745,272.023926 
	C367.507782,271.987427 368.268250,271.984406 369.028687,271.981384 
	C369.028687,271.981415 368.999359,272.000000 369.048523,272.330231 
	C370.046173,273.094482 370.994690,273.528473 371.943207,273.962494 
	C371.943207,273.962494 371.983704,274.438751 371.992371,274.676636 
	C372.233917,276.381927 372.466827,277.849365 372.839569,280.005615 
	C372.650391,284.661133 370.340454,281.326569 369.052765,281.759766 
M357.470886,274.573883 
	C357.470886,274.573883 357.407623,274.468597 357.470886,274.573883 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M387.668976,276.014526 
	C387.987671,279.038330 386.799774,280.737274 383.271667,280.093597 
	C383.548431,276.863037 384.120514,273.748444 384.891418,269.551331 
	C386.008820,272.510834 386.653717,274.218872 387.668976,276.014526 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M372.089294,273.644653 
	C370.994690,273.528473 370.046173,273.094482 369.057007,272.315063 
	C370.942688,270.383148 372.869019,268.796631 374.795380,267.210114 
	C374.965240,267.404419 375.135132,267.598724 375.304993,267.793030 
	C374.281799,269.637604 373.258606,271.482178 372.089294,273.644653 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M351.201904,264.256714 
	C351.811615,263.940613 352.517883,263.957001 353.576630,263.960510 
	C354.824951,264.555695 355.720764,265.163788 356.999786,266.032013 
	C355.960510,266.611542 355.185059,267.043976 354.217590,267.730164 
	C354.025574,267.983887 353.984985,268.010529 353.643768,268.014404 
	C352.872589,268.015045 352.442596,268.011810 352.012634,268.008545 
	C352.012634,268.008545 351.946075,267.948914 351.911438,267.917297 
	C351.684021,266.786865 351.491211,265.688019 351.201904,264.256714 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M369.016602,271.612885 
	C368.268250,271.984406 367.507782,271.987427 366.367065,271.994629 
	C366.361328,271.083099 366.735931,270.167419 367.110504,269.251740 
	C367.741852,269.915955 368.373199,270.580170 369.016602,271.612885 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M390.078766,273.683472 
	C389.621948,274.528107 389.181183,275.219818 388.419861,275.972626 
	C388.493958,274.731445 388.888580,273.429169 389.652283,272.371643 
	C390.038147,272.818878 390.054932,273.021393 390.071716,273.223877 
	C390.071716,273.223877 390.094849,273.530548 390.078766,273.683472 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M364.997284,274.641998 
	C364.054443,274.248810 363.108887,273.497620 362.139282,272.441864 
	C362.115234,272.137268 362.092621,272.106781 362.319824,272.081940 
	C362.547058,272.057098 362.985687,272.023804 362.985687,272.023804 
	C363.395935,272.056213 363.806213,272.088654 364.521851,272.139404 
	C364.882996,272.866455 364.938782,273.575226 364.997284,274.641998 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M376.621948,272.043762 
	C376.243042,272.351624 375.863190,272.392670 375.483307,272.433716 
	C375.485382,272.160553 375.487427,271.887390 375.489502,271.614258 
	C375.866669,271.668488 376.243805,271.722717 376.621948,272.043762 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M362.985718,271.715759 
	C362.985687,272.023804 362.547058,272.057098 362.328461,272.071808 
	C362.109863,271.349426 362.109863,270.612305 362.109863,269.875214 
	C362.300232,269.861420 362.490570,269.847656 362.680939,269.833862 
	C362.782532,270.358490 362.884155,270.883087 362.985718,271.715759 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M390.307434,273.085999 
	C390.054932,273.021393 390.038147,272.818878 390.011902,272.311768 
	C390.173065,272.001648 390.343719,271.996094 390.770386,271.998169 
	C390.865295,272.319946 390.704224,272.634033 390.307434,273.085999 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M391.036377,271.290466 
	C391.236267,271.219910 391.432129,271.381805 391.581879,271.578735 
	C391.610382,271.616211 391.456604,271.792267 391.195892,271.965057 
	C391.004883,272.025726 391.016357,271.535034 391.036377,271.290466 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M235.051010,501.041626 
	C235.074799,504.199432 235.180130,507.359253 235.103104,510.514618 
	C234.990036,515.146973 232.777908,516.650330 228.302536,515.738708 
	C225.500626,515.167969 222.431900,515.613586 219.774612,514.727905 
	C217.956314,514.121887 216.223495,512.250000 215.210190,510.504303 
	C214.051666,508.508301 213.732773,506.024933 212.872330,503.166107 
	C214.363525,504.048645 215.938339,504.871429 217.397842,505.863800 
	C223.069641,509.720062 226.124329,509.455170 230.894028,504.578491 
	C232.037476,503.409454 233.149429,502.209625 234.638229,501.010345 
	C235.000595,500.996765 235.051010,501.041626 235.051010,501.041626 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M234.977997,500.971741 
	C235.084229,500.936188 235.213058,500.925720 235.196442,500.978455 
	C235.051010,501.041626 235.000595,500.996765 234.977997,500.971741 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M473.044678,660.510620 
	C473.043610,658.649963 473.122742,657.239990 473.138123,655.408081 
	C473.833984,654.040039 474.593597,653.093994 475.636902,652.035767 
	C480.055542,651.572937 484.580353,650.033752 487.017578,655.801636 
	C487.161743,660.592529 487.052673,665.077454 487.045288,669.562439 
	C487.039276,673.188049 485.663208,675.655884 481.462769,676.371582 
	C479.057861,675.922180 477.023926,675.363708 474.596649,674.784058 
	C473.843842,670.162292 473.484344,665.561829 473.044678,660.510620 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M343.537231,282.942291 
	C345.519287,283.003326 347.103912,283.099213 350.287720,283.291870 
	C346.982208,286.205048 344.814636,288.115295 342.313171,290.024536 
	C340.663147,288.366943 339.160492,286.821045 338.091278,285.018036 
	C337.267548,283.628937 336.988464,281.916840 336.483459,280.397186 
	C339.048492,281.391388 341.094147,282.184265 343.537231,282.942291 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M348.697083,269.761108 
	C348.972534,270.128326 348.977173,270.297150 348.682617,270.762024 
	C348.349609,273.205414 346.556305,275.851990 350.067566,276.993408 
	C349.251587,277.900330 348.435638,278.807251 347.323486,279.846802 
	C346.522522,278.128143 346.017792,276.276825 345.190613,273.242981 
	C344.522278,275.006897 344.275208,275.658966 344.014832,276.654663 
	C344.001495,276.998291 344.017578,276.981506 344.017578,276.981506 
	C343.347046,275.900055 342.676544,274.818604 342.014679,273.359497 
	C342.834686,272.514130 343.645996,272.046448 344.908752,271.318481 
	C344.799774,270.506287 344.595856,268.986633 344.186493,265.936127 
	C346.289124,267.734650 347.357697,268.648682 348.697083,269.761108 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M336.546082,266.917084 
	C336.906647,267.326172 336.952820,267.826843 337.007507,268.704803 
	C336.571472,269.415863 336.126953,269.749603 335.682404,270.083374 
	C335.865479,269.058472 336.048553,268.033569 336.546082,266.917084 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M343.992371,277.004700 
	C344.241425,277.369293 344.499664,277.727417 344.757904,278.085541 
	C344.629211,278.175598 344.500549,278.265656 344.371857,278.355713 
	C344.263245,278.063843 344.154633,277.771942 344.031799,277.230774 
	C344.017578,276.981506 344.001495,276.998291 343.992371,277.004700 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M417.538513,264.561829 
	C417.451569,264.624054 417.425171,264.380096 417.425171,264.380096 
	C417.425171,264.380096 417.625458,264.499634 417.538513,264.561829 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M415.566284,265.564758 
	C415.495850,265.608917 415.636749,265.520569 415.566284,265.564758 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M731.201294,556.793274 
	C731.201294,556.793274 731.631531,556.840393 731.905151,557.060059 
	C732.448059,557.194092 732.717224,557.108459 732.986450,557.022827 
	C732.986450,557.022827 733.003235,556.994812 733.020325,557.001221 
	C736.230164,557.789001 739.480835,558.395386 742.591370,559.425171 
	C744.160461,559.944580 745.475525,561.231262 746.941895,562.527710 
	C745.879395,563.231079 744.780518,563.577820 743.124756,563.692749 
	C739.984009,561.332886 737.085388,562.626770 734.260254,562.467712 
	C727.130127,562.066406 720.041382,560.930054 712.936096,560.088257 
	C712.196838,559.731384 711.457581,559.374451 710.388916,558.591309 
	C710.079346,557.137207 710.099182,556.109375 710.119080,555.081543 
	C711.577881,554.888184 713.036682,554.694824 715.090027,554.203918 
	C717.023560,553.419617 718.443176,552.375122 719.685730,552.554932 
	C722.879639,553.017029 725.995056,554.021973 729.141846,554.809937 
	C729.141846,554.809937 729.177490,554.846741 729.171387,555.207397 
	C729.843933,555.976501 730.522583,556.384888 731.201294,556.793274 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M712.990906,560.441589 
	C720.041382,560.930054 727.130127,562.066406 734.260254,562.467712 
	C737.085388,562.626770 739.984009,561.332886 742.779175,563.728271 
	C740.695129,564.982422 738.376709,566.846252 736.109131,566.786133 
	C730.091675,566.626831 724.044250,565.916077 718.122437,564.813904 
	C716.245850,564.464600 714.727844,562.189026 712.990906,560.441589 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M709.646118,555.049866 
	C710.099182,556.109375 710.079346,557.137207 710.082336,558.549683 
	C706.272949,558.935303 702.440796,558.936340 698.287842,558.982422 
	C697.374023,558.552979 696.781006,558.078491 696.030518,557.363647 
	C698.352051,556.417480 700.796204,555.510559 703.321594,555.071716 
	C705.217957,554.742126 707.218872,555.014038 709.646118,555.049866 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M715.028931,568.497314 
	C712.677368,568.917480 710.264587,568.981506 707.476196,569.036011 
	C706.211853,567.804382 705.323120,566.582214 704.434326,565.360107 
	C705.759644,565.207581 707.232544,564.612854 708.379028,564.995789 
	C710.669922,565.760803 712.781799,567.061584 715.028931,568.497314 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M644.284546,738.854736 
	C644.196716,738.473022 644.343750,737.910461 644.730347,737.172241 
	C644.819702,737.555664 644.669617,738.114807 644.284546,738.854736 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M220.002167,484.339294 
	C226.127945,481.354370 232.196518,478.697479 238.292862,476.105865 
	C240.928574,474.985413 243.621582,473.999664 246.687439,472.907471 
	C248.310120,473.417847 249.533890,473.973480 250.977692,474.797974 
	C250.459915,476.474396 249.722122,477.881958 248.658020,479.690948 
	C245.581390,483.207703 242.332352,485.288940 237.980804,485.083984 
	C231.810226,485.798096 225.639664,486.512177 219.382309,487.236328 
	C219.418091,487.077789 219.687180,485.885803 220.002167,484.339294 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M345.014099,718.013855 
	C344.162689,718.953186 343.295410,719.876526 342.428131,720.799866 
	C342.061890,720.316162 341.695648,719.832458 341.329407,719.348755 
	C342.314941,718.871704 343.300476,718.394592 344.626709,717.941223 
	C344.967377,717.964966 344.998260,717.997864 345.014099,718.013855 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M238.127029,485.388824 
	C242.332352,485.288940 245.581390,483.207703 248.680237,480.042511 
	C250.882645,485.416748 246.307831,489.881714 239.436493,488.959900 
	C238.795273,487.855377 238.534256,486.774536 238.127029,485.388824 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M768.951660,534.708008 
	C769.021484,535.449707 769.030273,535.826599 769.054565,536.482666 
	C768.287109,536.833618 767.504211,536.905334 766.327148,536.959290 
	C762.917725,535.344116 759.885132,533.778015 756.894958,532.134705 
	C755.581726,531.413025 754.354980,530.534058 753.047607,529.354065 
	C758.300964,530.768799 763.595703,532.555969 768.951660,534.708008 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M752.912292,527.780640 
	C752.300049,527.617737 751.679565,527.119629 751.028320,526.298584 
	C751.633057,526.465515 752.268555,526.955444 752.912292,527.780640 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M577.030457,740.679077 
	C573.339478,740.659424 569.672424,740.303101 565.607910,739.895142 
	C564.168884,737.279358 562.595581,734.791321 562.214539,732.132446 
	C561.687683,728.455566 562.058777,724.650024 562.147339,720.436890 
	C562.651917,719.984924 563.054382,719.995605 563.761719,719.984253 
	C565.516235,720.062012 566.966003,720.161865 568.858887,720.292236 
	C569.131042,727.522888 567.028564,736.225220 578.628784,736.938416 
	C579.000000,737.000000 578.991089,736.992859 578.991089,736.992859 
	C578.345520,738.109375 577.699890,739.225891 577.030457,740.679077 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M579.003418,737.004395 
	C579.104736,736.978577 579.202698,736.948425 579.145874,736.955566 
	C578.991089,736.992859 579.000000,737.000000 579.003418,737.004395 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M233.389145,746.378479 
	C234.241562,744.375732 235.364136,742.632202 236.486710,740.888733 
	C236.972351,741.246277 237.457977,741.603821 237.943604,741.961426 
	C238.394043,744.783813 237.089264,746.425598 233.389145,746.378479 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M297.661957,724.961548 
	C297.331085,729.976074 296.654846,734.954651 295.970154,740.346069 
	C295.541962,740.870544 295.122223,740.982056 294.219910,740.782043 
	C292.466888,737.303528 291.196411,734.136597 289.925934,730.969666 
	C289.934692,728.605225 289.943451,726.240784 290.033234,723.439331 
	C293.000854,721.824158 295.227264,723.118225 297.661957,724.961548 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M290.348602,720.972534 
	C290.010559,720.566223 289.965759,720.117493 289.962097,719.331909 
	C290.216125,719.640076 290.428986,720.285095 290.348602,720.972534 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M377.133179,602.793457 
	C377.655701,606.349060 379.536438,610.447266 378.383453,613.333557 
	C376.640350,617.697144 372.662598,620.773682 367.202301,621.242004 
	C357.076874,622.110596 347.837646,619.712585 338.686157,614.298584 
	C337.493286,613.448608 336.784210,612.941345 336.075134,612.434082 
	C335.652100,611.490173 335.229095,610.546265 335.156708,609.198853 
	C335.989105,608.514465 336.470917,608.233643 337.110291,608.190063 
	C337.873108,607.936707 338.478302,607.446167 339.439209,606.964111 
	C341.216675,606.126892 342.638458,605.281128 344.478821,604.186340 
	C344.368011,605.735962 344.275940,607.023560 344.176514,608.414124 
	C345.656769,607.861267 346.830719,607.422791 348.002625,606.991943 
	C348.000549,606.999573 348.015869,606.994812 347.989441,607.312012 
	C348.511871,609.621826 349.060791,611.614380 349.609711,613.606995 
	C350.173248,613.674927 350.736816,613.742920 351.300385,613.810852 
	C352.168884,611.888611 353.037354,609.966431 354.203369,607.872131 
	C354.684479,607.048767 354.868042,606.397461 355.046478,605.626160 
	C355.041351,605.506165 355.000214,605.269409 355.389954,605.195801 
	C359.497040,604.335999 362.843414,603.047119 364.527954,598.933777 
	C364.527954,601.530334 364.527954,604.126831 364.527954,606.723389 
	C365.110657,606.914917 365.693359,607.106506 366.276093,607.298035 
	C367.146423,605.572937 368.016785,603.847778 369.079041,602.424561 
	C371.865814,605.788147 374.484680,606.029114 377.133179,602.793457 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M336.952698,607.952759 
	C336.470917,608.233643 335.989105,608.514465 335.246277,608.894775 
	C334.985229,608.994263 335.011658,608.994080 334.884827,608.713501 
	C334.161743,608.285156 333.565491,608.137268 332.969269,607.989380 
	C332.969269,607.989380 333.007111,607.992310 333.083771,607.643799 
	C330.718109,604.187012 328.275787,601.078735 325.833466,597.970459 
	C324.979340,596.486206 324.125214,595.001892 323.125488,593.316406 
	C323.062164,591.041138 323.144379,588.967041 323.645325,586.458618 
	C324.939728,586.022400 325.815430,586.020508 326.765808,586.414917 
	C327.067383,588.132080 327.294250,589.452942 327.224915,590.912842 
	C326.692047,591.734619 326.455353,592.417236 326.218658,593.099854 
	C326.794556,593.070862 327.370453,593.041870 328.195801,593.220032 
	C329.220459,594.980530 329.995697,596.533875 330.795166,598.404907 
	C331.235687,599.437073 331.651947,600.151367 332.147766,601.212524 
	C332.646271,601.728516 333.065216,601.897766 333.488922,602.033997 
	C333.493652,602.001038 333.447144,602.048706 333.470001,602.412598 
	C334.646118,604.501892 335.799408,606.227295 336.952698,607.952759 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M325.874634,598.337036 
	C328.275787,601.078735 330.718109,604.187012 333.085022,607.662720 
	C330.645020,604.921265 328.280426,601.812439 325.874634,598.337036 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M326.185730,607.769409 
	C327.221100,608.427612 328.201080,609.161499 329.289062,610.184937 
	C329.397095,610.474609 329.183655,610.783997 328.690186,610.893921 
	C327.467499,609.998474 326.738251,608.993164 326.009033,607.987793 
	C326.009033,607.987793 326.130371,607.845032 326.185730,607.769409 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M318.216553,570.361694 
	C318.473114,571.442139 318.576263,572.794189 318.523193,574.527466 
	C318.265717,573.483521 318.164429,572.058411 318.216553,570.361694 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M336.051422,612.882324 
	C336.784210,612.941345 337.493286,613.448608 338.328979,614.263123 
	C338.268738,614.760315 338.081848,614.950317 337.549835,615.484009 
	C337.204712,615.827576 337.076538,615.951843 337.076538,615.951843 
	C336.893890,615.825684 336.711212,615.699463 336.282898,615.349121 
	C336.034088,614.526794 336.030884,613.928711 336.051422,612.882324 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M333.120758,608.273315 
	C333.565491,608.137268 334.161743,608.285156 334.888245,608.726929 
	C334.436462,608.866272 333.854370,608.711731 333.120758,608.273315 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M322.113281,592.044373 
	C322.337708,592.000916 322.526703,592.264099 322.724243,592.813232 
	C322.594330,592.792480 322.376038,592.543030 322.113281,592.044373 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M674.917480,737.702881 
	C673.205872,742.429016 669.198853,740.826599 665.385437,741.147705 
	C661.629333,737.949463 659.151733,725.865967 661.239746,722.045898 
	C661.622253,721.345947 663.165710,721.280701 664.604431,720.844604 
	C665.239746,720.805054 665.443848,720.843262 666.075562,721.141968 
	C666.964417,721.699951 667.425720,721.997314 667.887024,722.294678 
	C667.856445,722.664490 667.825867,723.034363 667.622559,723.992065 
	C667.661072,726.993103 667.872314,729.406250 668.083496,731.819397 
	C668.335754,732.371704 668.588013,732.924072 669.020752,734.049438 
	C670.151917,735.293213 671.102539,735.963989 672.053223,736.634766 
	C672.926392,736.909790 673.799622,737.184753 674.917480,737.702881 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M775.155029,717.587280 
	C776.193420,717.180847 777.144409,717.112183 778.451904,717.040161 
	C778.808411,717.036926 778.908508,717.092773 778.940979,717.494934 
	C778.977905,718.599487 778.982361,719.301941 778.778992,720.141113 
	C778.772217,720.867493 778.973267,721.457092 779.174316,722.046753 
	C779.360168,725.429382 779.546021,728.812012 779.767944,732.604004 
	C779.105286,733.281372 778.406616,733.549255 777.146973,733.572205 
	C776.042114,729.217407 775.498108,725.107605 775.148376,720.928467 
	C775.425659,720.751526 775.592773,720.562622 775.579041,720.548401 
	C775.396790,720.359802 775.189148,720.195801 774.987854,720.025696 
	C775.014465,719.325439 775.041077,718.625183 775.155029,717.587280 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M778.986816,720.004333 
	C778.982361,719.301941 778.977905,718.599487 778.952393,717.551025 
	C782.482849,717.528198 786.034424,717.851379 789.585999,718.174561 
	C789.578247,718.804871 789.570496,719.435242 789.562744,720.065552 
	C786.697327,720.065552 783.831848,720.065552 780.251343,720.052246 
	C779.353027,720.027466 779.169922,720.015869 778.986816,720.004333 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M778.778992,720.141113 
	C779.169922,720.015869 779.353027,720.027466 779.808899,720.078613 
	C779.910889,720.636047 779.740173,721.153870 779.371948,721.859253 
	C778.973267,721.457092 778.772217,720.867493 778.778992,720.141113 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M729.095581,554.469849 
	C725.995056,554.021973 722.879639,553.017029 719.685730,552.554932 
	C718.443176,552.375122 717.023560,553.419617 715.329956,553.930359 
	C715.060791,552.524475 715.146301,551.094604 715.271667,548.996826 
	C719.008423,548.996826 722.519226,548.996826 726.486084,549.008179 
	C727.644531,550.723022 728.346924,552.426392 729.095581,554.469849 
z"/>
<path fill="#0E2015" opacity="1.000000" stroke="none" 
	d="
M268.136200,657.103882 
	C267.730865,653.940552 267.686951,650.935974 267.706604,647.484253 
	C271.166290,645.929504 274.562378,644.821960 278.619873,643.498718 
	C278.619873,649.373657 278.619873,654.394897 278.619873,660.008179 
	C275.102936,659.054199 271.800262,658.158447 268.136200,657.103882 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M430.001770,610.335632 
	C431.261383,609.347290 432.521027,608.358948 434.140869,607.088013 
	C435.021729,609.004028 435.814178,610.727661 436.979828,613.263062 
	C440.217529,610.712891 443.276611,608.303467 446.546783,605.727722 
	C446.673096,606.850037 446.824921,608.198914 447.011383,609.855652 
	C448.212158,608.841309 449.279724,607.939453 450.797546,607.207642 
	C451.797791,607.207581 452.347809,607.037537 453.238953,606.950439 
	C454.760956,607.971130 455.941803,608.908752 457.354218,610.030212 
	C458.296021,608.234253 458.989410,606.911987 459.682800,605.589722 
	C460.234070,605.581177 460.785339,605.572632 461.336639,605.564148 
	C461.896332,608.375366 462.456055,611.186646 462.972900,614.369141 
	C461.862671,617.656433 460.795258,620.572449 459.727844,623.488525 
	C452.912231,623.442261 446.096619,623.396057 438.717468,622.882080 
	C439.130554,617.540466 437.550812,614.981018 432.799957,613.701477 
	C431.764954,612.388184 430.883362,611.361877 430.001770,610.335632 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M432.953400,613.988525 
	C437.550812,614.981018 439.130554,617.540466 438.288452,622.762695 
	C434.728302,625.719849 430.797607,625.140503 426.408142,623.895630 
	C425.681122,622.618774 425.369141,621.416016 425.418823,619.920166 
	C430.049835,620.137085 432.172516,617.916260 432.953400,613.988525 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M459.929810,623.719238 
	C460.795258,620.572449 461.862671,617.656433 463.251221,614.205933 
	C464.482635,612.768494 465.392883,611.865417 466.303131,610.962341 
	C468.132935,615.802429 463.384613,618.148376 462.185059,621.839172 
	C462.011169,622.374084 461.914612,622.934143 461.840057,623.790710 
	C461.309174,624.049194 460.720490,623.999573 459.929810,623.719238 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M690.370117,728.794189 
	C689.668884,727.205566 689.311035,725.486511 688.973755,723.375732 
	C690.561951,721.585571 691.956360,719.358643 693.738586,718.985962 
	C696.314026,718.447327 699.383667,718.725159 701.816467,719.708496 
	C703.019348,720.194824 703.269226,723.038635 703.950317,724.815735 
	C702.487793,725.271118 701.033020,725.753235 699.561218,726.176392 
	C696.616882,727.022583 693.663208,727.836548 690.370117,728.794189 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M774.954163,720.997803 
	C775.498108,725.107605 776.042114,729.217407 776.862061,733.598999 
	C779.266663,735.282043 781.395325,736.693359 784.093384,738.482239 
	C779.572693,741.381958 774.780457,739.690552 769.846069,739.802917 
	C766.001160,733.497681 765.119324,727.052124 767.566711,720.088623 
	C769.499207,720.088623 771.270630,720.088623 773.620117,720.294434 
	C774.395264,720.749512 774.647217,720.915344 774.954163,720.997803 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M289.673767,731.221558 
	C291.196411,734.136597 292.466888,737.303528 293.895294,740.813721 
	C292.999664,740.642029 291.258759,740.342529 291.017609,739.572998 
	C290.199554,736.962769 289.911896,734.186401 289.673767,731.221558 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M224.054443,529.923401 
	C220.830627,529.395325 217.436249,527.986633 214.427567,528.569397 
	C210.920746,529.248596 211.992950,526.811279 211.089142,525.286377 
	C216.545944,524.346558 222.116104,523.383484 227.713226,523.186157 
	C230.008652,523.105347 232.362350,524.677307 234.919220,525.801636 
	C234.302994,526.984741 233.457108,527.863647 232.020081,528.937378 
	C228.970779,529.395996 226.512619,529.659729 224.054443,529.923401 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M174.001923,729.400146 
	C175.313293,726.599731 176.633469,724.203857 178.026215,721.461914 
	C178.879395,720.095032 179.660019,719.074280 181.360184,716.851135 
	C183.430649,722.578796 185.157364,727.355469 186.878464,732.502808 
	C183.921692,732.897217 180.915802,733.262878 178.042130,732.802734 
	C176.575912,732.567932 175.346954,730.851501 174.001923,729.400146 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M808.531372,731.024536 
	C808.467163,731.675049 808.055237,732.307617 807.331909,732.972168 
	C807.020508,733.004089 806.569275,733.027344 806.313416,732.706787 
	C806.056641,731.956238 806.055725,731.526184 806.054749,731.096130 
	C806.054749,731.096130 806.054749,731.058228 806.074646,731.052246 
	C806.790894,731.033020 807.487305,731.019897 808.531372,731.024536 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M805.694458,731.086365 
	C806.055725,731.526184 806.056641,731.956238 806.087524,732.709045 
	C805.418945,732.625000 804.720398,732.218201 803.958374,731.488892 
	C804.374634,731.136414 804.854431,731.106506 805.694458,731.086365 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M758.615234,720.023682 
	C758.437073,722.284058 757.620239,723.901489 755.043945,721.349304 
	C756.068359,720.704590 757.169800,720.379944 758.615234,720.023682 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M775.148376,720.928467 
	C774.647217,720.915344 774.395264,720.749512 774.052734,720.323120 
	C774.086487,720.117859 774.265686,720.089600 774.716370,720.043457 
	C775.189148,720.195801 775.396790,720.359802 775.579041,720.548401 
	C775.592773,720.562622 775.425659,720.751526 775.148376,720.928467 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M763.959351,737.582642 
	C763.747620,737.786926 763.471802,737.661316 763.146240,737.187256 
	C763.343750,737.030823 763.610046,737.168762 763.959351,737.582642 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M668.117432,731.447693 
	C667.872314,729.406250 667.661072,726.993103 667.666016,724.267822 
	C667.971924,726.329102 668.061646,728.702515 668.117432,731.447693 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M671.992065,736.340332 
	C671.102539,735.963989 670.151917,735.293213 669.211060,734.291138 
	C670.124146,734.655212 671.027527,735.350586 671.992065,736.340332 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M667.823486,722.002502 
	C667.425720,721.997314 666.964417,721.699951 666.386841,721.155090 
	C666.767029,721.175232 667.263550,721.442810 667.823486,722.002502 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M689.486938,740.874146 
	C688.863647,740.072266 688.566528,739.312012 688.166626,738.259277 
	C688.464478,737.420349 688.865173,736.873840 689.267212,736.047485 
	C690.345825,737.281921 691.423035,738.796204 692.825134,740.767212 
	C691.394470,740.837708 690.603699,740.876709 689.486938,740.874146 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M743.386597,739.132690 
	C743.093811,738.974304 743.068176,738.756897 743.055420,738.210327 
	C743.068298,737.881226 743.539856,737.946289 743.777466,737.961304 
	C744.046875,738.146057 744.078613,738.315735 744.153076,738.740967 
	C744.015137,739.022217 743.834473,739.047974 743.386597,739.132690 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M276.999695,720.396057 
	C277.428894,720.354248 277.897491,720.611450 278.600311,720.998901 
	C278.834503,721.129211 278.855316,721.589050 278.817291,721.816528 
	C278.199219,721.594360 277.619171,721.144714 276.999695,720.396057 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M325.719788,608.110046 
	C326.738251,608.993164 327.467499,609.998474 328.560547,611.029968 
	C329.015106,614.788940 329.486206,618.755676 323.648376,618.243164 
	C323.283691,618.211060 322.779846,619.763306 322.142395,620.775391 
	C320.643890,618.600769 319.343719,616.229187 318.064941,613.468018 
	C319.627075,612.997437 321.167847,612.916382 324.008087,612.767029 
	C322.142273,611.418884 320.950470,610.557678 319.480072,609.495239 
	C320.693909,608.996643 321.953064,608.479492 323.587219,607.808289 
	C323.233856,607.726990 324.332214,607.979675 325.719788,608.110046 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M387.178772,611.710388 
	C386.977753,611.074097 387.037964,610.207031 387.284760,609.068970 
	C387.460907,609.691895 387.450439,610.585754 387.178772,611.710388 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M554.367188,609.067383 
	C553.998108,607.341064 553.998108,605.685242 553.998108,604.933960 
	C552.933899,601.720764 547.308838,604.810669 548.998413,599.428650 
	C550.406494,599.033813 551.773071,598.971130 553.738281,599.192993 
	C555.101013,600.625427 555.865112,601.773315 556.545532,602.795532 
	C557.193848,602.058228 558.031860,601.105225 558.707092,600.337341 
	C560.027283,601.811218 560.993347,602.889709 561.959412,603.968262 
	C565.595215,606.413208 569.231079,608.858093 572.581055,611.658691 
	C571.240295,612.344482 570.185364,612.674500 569.130493,613.004456 
	C566.957520,611.375793 564.784607,609.747131 562.259216,607.854309 
	C560.613831,610.194702 559.006409,612.481201 557.100647,615.192139 
	C556.162659,612.790344 555.449402,610.964111 554.367188,609.067383 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M569.097473,613.265015 
	C570.185364,612.674500 571.240295,612.344482 572.634888,612.008850 
	C573.274902,612.081055 573.535217,612.229065 573.897339,612.623901 
	C572.626221,613.200073 571.213562,613.599609 569.423950,614.024841 
	C569.052795,613.875549 569.058655,613.700500 569.097473,613.265015 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M432.799957,613.701477 
	C432.172516,617.916260 430.049835,620.137085 425.407501,619.541138 
	C424.698364,619.214783 424.362244,618.974365 423.671844,618.515503 
	C422.889648,617.862000 422.461700,617.427002 422.033752,616.991943 
	C422.129425,616.396179 422.225128,615.800415 422.545502,614.764832 
	C423.563873,613.605591 424.357574,612.886108 425.151245,612.166626 
	C425.151245,612.166626 425.076752,612.013306 425.384186,612.055420 
	C425.858032,611.777771 426.024475,611.458008 426.190918,611.138184 
	C426.190918,611.138184 426.490234,611.393860 426.692993,611.377686 
	C427.778595,611.128296 428.661377,610.895142 429.772949,610.498779 
	C430.883362,611.361877 431.764954,612.388184 432.799957,613.701477 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M424.746765,612.117676 
	C424.357574,612.886108 423.563873,613.605591 422.471375,614.471252 
	C422.895782,613.767883 423.619019,612.918274 424.746765,612.117676 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M421.789856,617.197998 
	C422.461700,617.427002 422.889648,617.862000 423.458221,618.575684 
	C422.914551,618.370850 422.230255,617.887512 421.789856,617.197998 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M425.923279,611.202026 
	C426.024475,611.458008 425.858032,611.777771 425.402283,612.138733 
	C425.293823,611.875244 425.474731,611.570618 425.923279,611.202026 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M224.040039,530.172607 
	C226.512619,529.659729 228.970779,529.395996 231.784698,529.139648 
	C230.679489,535.128479 229.397644,535.490356 224.332123,531.092773 
	C224.023911,530.921204 224.025620,530.421875 224.040039,530.172607 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M818.550415,712.468018 
	C818.591675,712.533508 818.509155,712.402466 818.550415,712.468018 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M318.414337,416.543030 
	C318.365997,416.469452 318.559082,416.442841 318.559082,416.442841 
	C318.559082,416.442841 318.462677,416.616638 318.414337,416.543030 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M484.356262,726.844055 
	C483.222229,726.993835 482.462982,726.992371 481.322479,726.985779 
	C480.752930,723.200867 482.206451,720.338257 486.247772,718.298096 
	C492.864105,718.360046 494.485321,719.752197 494.980865,724.989990 
	C491.564270,725.557556 488.147675,726.125183 484.356262,726.844055 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M495.353241,724.990845 
	C494.485321,719.752197 492.864105,718.360046 486.461151,718.030212 
	C485.417236,717.987610 484.772552,717.931702 484.127869,717.875793 
	C484.090729,717.523987 484.053589,717.172180 484.016449,716.820435 
	C486.415619,716.540771 488.814789,716.261108 491.643188,715.950989 
	C496.808746,716.864929 498.529907,720.053345 498.224670,724.754883 
	C497.211792,724.970947 496.468719,724.981323 495.353241,724.990845 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M322.209656,523.242065 
	C322.005005,521.700012 322.005005,520.404907 322.005005,518.963623 
	C320.645630,519.219910 319.476379,519.440369 317.988800,519.720825 
	C317.988800,522.581177 317.988800,525.325195 317.979065,528.510864 
	C317.656189,525.289551 317.343018,521.626587 317.056183,517.513367 
	C317.499084,517.029907 317.915710,516.996643 318.645569,516.944031 
	C320.304962,517.643127 322.216400,518.050598 322.832642,519.170654 
	C323.408234,520.216736 322.608643,522.019348 322.209656,523.242065 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M731.107422,556.508057 
	C730.522583,556.384888 729.843933,555.976501 729.189575,555.225464 
	C729.813843,555.329407 730.413757,555.776123 731.107422,556.508057 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M732.756592,556.970764 
	C732.717224,557.108459 732.448059,557.194092 732.117126,557.103760 
	C732.055420,556.927734 732.526733,556.918701 732.756592,556.970764 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M318.686035,426.072205 
	C318.994507,426.496582 318.995056,426.950165 318.999634,427.746094 
	C318.465790,427.838379 317.927887,427.588348 317.389984,427.338348 
	C317.719360,426.926056 318.048737,426.513763 318.686035,426.072205 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M317.744446,422.159027 
	C317.992035,422.727509 317.981659,423.473358 317.973206,424.590820 
	C317.812286,424.087067 317.649384,423.211731 317.744446,422.159027 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M654.124756,417.970642 
	C654.112976,418.418243 654.101196,418.865814 653.789551,419.881287 
	C652.376221,424.587006 651.262817,428.724792 650.097168,433.236786 
	C650.072144,434.112946 650.099365,434.614929 650.115601,435.536560 
	C650.130859,437.639496 650.157166,439.322784 649.890625,441.196655 
	C648.724731,443.264557 647.851746,445.141815 646.638916,447.064148 
	C645.205017,448.383942 644.110901,449.658661 642.919250,451.047058 
	C644.593933,451.876434 645.658081,452.403442 647.265991,453.199768 
	C643.780090,455.004242 640.882996,456.503937 637.719360,458.110046 
	C636.950745,458.491302 636.448608,458.766144 635.741699,459.140442 
	C635.278503,459.424530 635.108948,459.670471 635.019165,459.984680 
	C635.010132,459.991638 634.992676,460.006165 634.766235,460.150391 
	C634.693237,460.864624 634.846619,461.434631 635.000000,462.251648 
	C635.000000,462.498657 635.000000,462.992676 635.000732,463.244202 
	C635.003418,463.663422 635.005371,463.831085 634.759155,463.997803 
	C634.510986,463.996796 634.014648,463.991119 633.846008,463.744995 
	C633.118835,463.330109 632.560242,463.161316 632.001282,462.594299 
	C632.000610,461.134796 632.000305,460.073486 632.043335,458.630249 
	C632.561279,456.344025 633.035767,454.439697 633.530518,452.454163 
	C634.944885,452.804199 636.295410,453.138489 637.645996,453.472748 
	C637.843567,453.071472 638.041199,452.670166 638.238770,452.268890 
	C634.705383,449.687103 631.171997,447.105347 627.830688,444.663940 
	C632.871521,443.972321 633.503784,442.988403 632.139221,435.927216 
	C631.398438,438.141571 630.657715,440.355957 629.916931,442.570312 
	C629.577881,442.510651 629.238831,442.451019 628.899780,442.391357 
	C628.434631,440.028412 627.969482,437.665466 627.408569,434.816010 
	C629.545898,434.217621 631.700439,433.614441 633.854919,433.011292 
	C633.768921,433.543549 633.682861,434.075836 633.596802,434.608124 
	C636.070007,435.403748 638.543152,436.199371 641.320984,436.856995 
	C642.072144,435.273254 643.017029,433.766571 642.849304,432.395996 
	C642.592224,430.293976 641.569275,428.285675 640.867371,426.225769 
	C637.943176,425.494110 634.959900,424.747681 631.661255,423.861389 
	C630.241638,423.485657 629.137390,423.249756 627.887268,422.736938 
	C626.828064,421.656525 625.914734,420.853027 625.001099,419.602905 
	C625.000854,416.620697 625.000854,414.085144 625.000854,412.556915 
	C627.709900,412.093384 630.002502,412.366913 630.782898,411.408752 
	C631.904053,410.032349 632.497070,407.690369 632.281921,405.883606 
	C631.938599,403.000519 630.823486,400.191589 629.863342,397.410522 
	C629.576538,396.579926 628.717468,395.946899 627.238770,394.154297 
	C628.028687,397.458099 628.460876,399.265747 628.560669,401.090637 
	C626.671509,402.335754 625.114746,403.563568 623.534424,404.809937 
	C623.405884,404.160217 623.194031,403.089600 623.253906,401.823425 
	C625.301880,399.120331 625.366821,397.076996 621.991821,395.597473 
	C621.994995,394.487732 621.996704,393.747528 622.287842,393.126526 
	C623.383972,392.832092 624.190796,392.418488 624.998779,392.002441 
	C625.000000,392.000000 625.004883,391.998779 625.388184,392.003723 
	C628.090027,391.890198 630.411987,391.653778 632.725281,391.719452 
	C633.497559,391.741394 634.247864,392.536072 634.811096,393.176208 
	C635.396057,394.080292 636.178101,394.785614 636.960205,395.490967 
	C637.286865,394.587372 637.613586,393.683746 637.952942,392.401672 
	C637.982056,391.013031 637.998535,390.002869 638.380371,388.994293 
	C639.476257,389.003571 640.206848,389.011230 641.155823,389.277100 
	C642.245422,389.687653 643.116577,389.840057 644.322327,389.994141 
	C645.102966,389.998016 645.549011,390.000214 646.000061,390.413086 
	C646.256165,393.445465 646.507324,396.067230 646.768555,398.794189 
	C648.429993,398.083130 649.732239,397.525848 651.030273,396.976318 
	C651.026062,396.984070 651.032227,396.966888 650.966431,397.312592 
	C648.631592,399.128235 646.303040,400.515503 644.112671,402.094543 
	C642.392700,403.334473 638.590942,403.825745 642.743042,408.985931 
	C644.154419,406.600037 645.289795,404.680634 646.133728,403.253906 
	C648.392090,405.086609 650.187683,406.543732 652.003296,408.388184 
	C651.740601,411.779724 651.457886,414.783936 651.157959,417.970642 
	C652.191406,417.970642 653.158081,417.970642 654.124756,417.970642 
M640.457947,396.889191 
	C641.291443,397.352478 642.124878,397.815765 642.958374,398.279053 
	C643.306091,396.727905 643.653809,395.176758 644.001465,393.625610 
	C643.520447,393.400940 643.039368,393.176239 642.558289,392.951569 
	C641.766357,394.039795 640.974426,395.128021 640.457947,396.889191 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M633.013062,334.013000 
	C633.023743,334.433746 633.034424,334.854492 633.074219,335.588165 
	C632.484192,338.038605 631.864990,340.176117 631.072327,342.912628 
	C633.131470,342.747101 634.649902,342.625061 636.829834,342.449829 
	C635.821838,343.522827 635.120239,344.269623 634.418579,345.016449 
	C634.489014,345.378357 634.559448,345.740234 634.629883,346.102112 
	C636.570801,345.757263 638.511658,345.412384 640.786987,345.043396 
	C641.513123,346.005554 641.904785,346.991882 642.633179,348.431335 
	C642.223755,350.726624 641.477722,352.568787 640.929199,353.923248 
	C641.764465,355.015991 642.404846,356.109802 643.289551,356.945007 
	C644.348022,357.944366 645.723389,359.572052 646.771606,359.438385 
	C649.986389,359.028412 649.922791,360.914948 649.991699,362.986725 
	C649.006104,365.087250 648.020508,367.187805 646.580505,369.654663 
	C644.468567,370.003632 642.811096,369.986267 641.135620,369.543701 
	C640.298157,366.964233 642.711731,363.187256 637.994141,362.649170 
	C637.955566,361.408875 637.617676,360.298279 638.012512,359.605042 
	C639.652405,356.725708 637.574646,357.143158 635.959961,357.056580 
	C635.969116,357.029480 635.916382,357.051544 635.968079,356.710754 
	C635.618286,355.170532 635.216797,353.971069 634.815247,352.771606 
	C633.524902,351.483124 633.434509,349.776947 632.566040,348.695770 
	C629.242432,344.558167 627.253845,340.046295 626.648560,334.699860 
	C626.249756,331.177673 623.325073,330.571533 620.175415,331.092682 
	C618.765198,331.326080 617.337952,331.457367 615.222961,331.721985 
	C618.114380,329.327789 620.215393,327.588074 622.316406,325.848358 
	C621.744202,326.023010 621.171936,326.197632 620.599731,326.372284 
	C619.085632,324.767578 617.571472,323.162872 616.057373,321.558136 
	C616.410522,321.206970 616.763672,320.855804 617.116821,320.504608 
	C616.066711,320.966736 615.016663,321.428864 613.607239,322.049103 
	C613.607239,320.097290 613.607239,318.713959 613.607239,316.455322 
	C611.974976,317.591858 610.996582,318.273132 609.816406,318.691925 
	C609.081726,316.632843 608.548767,314.836273 608.015808,313.039703 
	C608.969238,311.889313 609.709229,310.114166 610.914917,309.702179 
	C615.018799,308.300018 614.375061,306.301880 612.086426,303.445801 
	C613.297974,304.185272 615.744446,305.395996 615.641541,306.323669 
	C615.188599,310.402863 617.740234,312.594940 619.736938,315.489105 
	C622.081055,318.886627 624.133545,320.783356 627.666931,318.201996 
	C629.517578,321.725311 631.227234,324.980255 632.652222,328.598328 
	C631.686890,329.484314 631.006287,330.007202 630.325623,330.530060 
	C631.221436,331.691040 632.117249,332.852020 633.013062,334.013000 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M646.978760,447.019073 
	C647.851746,445.141815 648.724731,443.264557 650.183716,441.337097 
	C652.533203,441.165375 654.296753,441.043884 656.060242,440.922394 
	C656.060242,440.922394 656.023865,440.968781 655.999146,441.313934 
	C655.405090,442.401520 654.788147,443.112885 654.278748,443.894348 
	C653.666687,444.833160 653.146118,445.831573 652.585938,446.804199 
	C653.801208,446.686737 655.020264,446.598572 656.230103,446.439758 
	C656.859314,446.357147 657.472168,446.149902 658.092407,445.998962 
	C658.099060,446.743378 658.105713,447.487823 657.780518,448.741882 
	C656.674438,450.290283 655.900146,451.329102 655.125854,452.367859 
	C656.092041,452.898315 657.058228,453.428772 658.194336,454.063507 
	C658.246643,454.461395 658.129028,454.755035 657.651733,455.068909 
	C655.837463,457.251129 653.355103,459.327332 653.166016,461.594818 
	C652.872864,465.108887 654.143799,468.753479 654.791321,472.577362 
	C652.968201,472.354065 651.542786,472.179474 649.774292,471.968201 
	C648.684937,471.327515 647.938660,470.723511 646.631531,469.665497 
	C652.946167,469.582764 650.891663,466.580780 650.020325,464.024719 
	C647.251770,465.406250 644.621155,466.718964 641.724915,467.777374 
	C641.361694,459.705780 646.828674,456.087463 653.008606,452.921173 
	C650.803345,450.762634 648.891052,448.890839 646.978760,447.019073 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M656.073730,440.651367 
	C654.296753,441.043884 652.533203,441.165375 650.476562,441.146454 
	C650.157166,439.322784 650.130859,437.639496 650.484314,435.500397 
	C654.093567,435.081207 654.954041,432.995544 656.146606,429.552795 
	C653.419678,431.057739 651.784546,431.960175 650.149353,432.862610 
	C651.262817,428.724792 652.376221,424.587006 653.805176,420.216858 
	C655.753296,421.020203 657.385864,422.055939 659.622192,423.474701 
	C659.562134,423.561920 660.250854,422.561890 660.939575,421.561859 
	C661.331787,421.627167 661.724060,421.692474 662.116272,421.757782 
	C661.693604,424.289673 661.365234,426.842010 660.821838,429.347717 
	C660.271118,431.887634 656.296570,433.872223 659.632324,437.021454 
	C658.450623,438.141083 657.268921,439.260712 656.073730,440.651367 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M650.343384,362.942017 
	C649.922791,360.914948 649.986389,359.028412 646.771606,359.438385 
	C645.723389,359.572052 644.348022,357.944366 643.289551,356.945007 
	C642.404846,356.109802 641.764465,355.015991 640.929199,353.923248 
	C641.477722,352.568787 642.223755,350.726624 642.974365,348.520447 
	C644.791138,345.426331 648.292175,345.348724 650.320801,347.783264 
	C650.917969,348.499939 652.243164,348.609985 653.617371,348.999023 
	C654.000000,349.000000 653.996216,348.997040 653.996216,348.997040 
	C653.249207,353.714386 655.857788,359.228638 650.343384,362.942017 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M648.997559,371.996521 
	C650.333679,370.680817 652.235657,368.004639 652.908325,368.284332 
	C655.860657,369.512177 658.457458,371.594940 661.183228,373.367706 
	C660.863464,373.868011 660.543701,374.368317 660.223938,374.868622 
	C659.170410,374.418152 658.116821,373.967651 657.477966,373.694489 
	C655.507263,376.210388 653.585449,378.663849 651.833923,380.899933 
	C653.537964,381.895508 654.750061,382.603668 655.587158,383.652466 
	C653.497009,384.329315 651.781799,384.665466 649.779785,384.902313 
	C648.948181,384.542786 648.403320,384.282532 647.863342,383.690857 
	C647.826721,382.920624 647.785156,382.481842 647.816284,381.781311 
	C647.932495,381.343750 647.976074,381.167938 648.392273,380.980713 
	C649.480713,380.335602 650.196472,379.701904 650.912292,379.068176 
	C650.253784,378.362701 649.595337,377.657227 648.940674,376.638458 
	C648.958069,375.908661 648.971680,375.492188 648.987427,374.690826 
	C648.992126,373.536163 648.994873,372.766327 648.997559,371.996521 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M651.983215,408.000885 
	C650.187683,406.543732 648.392090,405.086609 646.133728,403.253906 
	C645.289795,404.680634 644.154419,406.600037 642.743042,408.985931 
	C638.590942,403.825745 642.392700,403.334473 644.112671,402.094543 
	C646.303040,400.515503 648.631592,399.128235 651.302124,397.341492 
	C652.747925,397.407593 653.792175,397.790497 654.836426,398.173370 
	C654.836426,398.173370 654.874817,398.594788 654.787720,399.099182 
	C652.936462,401.567627 650.831848,403.493774 653.947021,406.328308 
	C653.265564,407.100708 652.624390,407.550812 651.983215,408.000885 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M658.011353,455.048645 
	C658.129028,454.755035 658.246643,454.461395 658.260254,453.697510 
	C659.777771,452.825958 661.399292,452.424683 663.020752,452.023407 
	C663.020752,452.023407 663.028259,452.485931 662.749756,452.953735 
	C662.340332,454.382538 662.209351,455.343597 662.078308,456.304626 
	C663.048889,457.739685 664.019409,459.174805 665.611694,461.529266 
	C665.645752,461.383179 665.349976,462.651337 665.054138,463.919525 
	C665.491943,463.697876 665.929749,463.476257 666.367615,463.254639 
	C667.255249,464.262726 668.142944,465.270813 669.011475,466.637573 
	C663.604370,469.626495 663.685303,469.615295 662.896973,463.912537 
	C662.784546,463.099213 661.446106,462.455353 660.018921,461.821472 
	C659.058411,461.164215 658.311035,460.209351 658.543457,459.716980 
	C659.009705,458.729523 660.011230,457.994751 660.791809,457.155670 
	C659.653625,457.155670 658.515503,457.155670 657.138000,457.155670 
	C657.520874,456.232056 657.766113,455.640350 658.011353,455.048645 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M655.023682,397.810791 
	C653.792175,397.790497 652.747925,397.407593 651.367920,396.995789 
	C651.032227,396.966888 651.026062,396.984070 651.075317,396.616821 
	C652.058350,393.777466 652.992065,391.305389 654.008667,388.613953 
	C652.085693,388.613953 650.772766,388.613953 649.260559,388.613953 
	C649.581055,387.177521 649.823792,386.089569 650.066528,385.001617 
	C651.781799,384.665466 653.497009,384.329315 655.593628,383.993469 
	C656.576355,384.733490 657.786804,385.579163 657.679626,386.195740 
	C657.022522,389.975372 656.067017,393.703156 655.023682,397.810791 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M652.003296,408.388184 
	C652.624390,407.550812 653.265564,407.100708 654.241211,406.442719 
	C655.384216,405.790619 656.192749,405.346436 657.001282,404.902283 
	C657.326965,406.429657 657.713989,407.947083 657.963684,409.486755 
	C658.329163,411.740540 658.596191,414.010315 658.952637,416.636627 
	C659.000000,417.000000 659.006592,416.992950 659.006592,416.992950 
	C657.597351,417.225403 656.188110,417.457855 654.451782,417.830475 
	C653.158081,417.970642 652.191406,417.970642 651.157959,417.970642 
	C651.457886,414.783936 651.740601,411.779724 652.003296,408.388184 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M648.019653,380.992096 
	C647.976074,381.167938 647.932495,381.343750 647.469055,381.770142 
	C645.686584,381.661102 644.324036,381.301422 642.973511,380.573029 
	C642.331482,379.140411 641.677368,378.076508 641.144653,376.703979 
	C641.228577,374.253204 641.191040,372.111084 641.153564,369.968933 
	C642.811096,369.986267 644.468567,370.003632 646.538696,370.022827 
	C646.969604,370.462830 646.987915,370.901001 646.678711,371.843170 
	C645.898865,374.240173 645.446594,376.133148 645.038452,378.353271 
	C645.977722,379.133118 646.872803,379.585815 647.845703,380.272980 
	C647.923584,380.507446 648.019653,380.992096 648.019653,380.992096 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M663.162231,451.691833 
	C661.399292,452.424683 659.777771,452.825958 658.090332,453.593262 
	C657.058228,453.428772 656.092041,452.898315 655.125854,452.367859 
	C655.900146,451.329102 656.674438,450.290283 657.791199,449.113922 
	C660.199097,447.932983 662.264526,446.889587 665.300415,445.355988 
	C664.526733,447.682404 663.915222,449.521332 663.162231,451.691833 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M658.421509,445.956238 
	C657.472168,446.149902 656.859314,446.357147 656.230103,446.439758 
	C655.020264,446.598572 653.801208,446.686737 652.585938,446.804199 
	C653.146118,445.831573 653.666687,444.833160 654.278748,443.894348 
	C654.788147,443.112885 655.405090,442.401520 656.028198,441.308899 
	C657.599304,441.465088 659.116577,441.971436 660.633911,442.477783 
	C660.006104,443.623016 659.378357,444.768280 658.421509,445.956238 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M669.921021,418.982056 
	C669.939636,420.363007 669.958313,421.743958 669.659058,423.570068 
	C668.584473,424.702209 667.827820,425.389221 667.071167,426.076233 
	C665.969238,425.999725 664.867249,425.923248 663.853088,425.852844 
	C663.134949,424.081818 669.822693,422.557800 662.912109,421.429565 
	C665.049072,420.512360 666.323975,419.965149 667.413696,419.215240 
	C667.370422,418.478088 667.512329,417.943604 668.108337,417.401245 
	C669.015259,417.922913 669.468140,418.452484 669.921021,418.982056 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M656.882812,404.635376 
	C656.192749,405.346436 655.384216,405.790619 654.281494,406.120392 
	C650.831848,403.493774 652.936462,401.567627 654.838745,399.304504 
	C659.590881,399.453888 655.316345,402.864807 656.882812,404.635376 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M662.950439,381.970398 
	C661.221252,381.821320 659.673767,381.385254 658.126282,380.949188 
	C658.227051,380.390747 658.327820,379.832306 658.428589,379.273865 
	C660.249573,379.164062 662.070557,379.054260 664.489014,378.908417 
	C663.882751,380.148224 663.507446,380.915802 662.950439,381.970398 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M667.725342,431.885193 
	C668.042236,432.434082 668.058655,432.866150 668.126343,433.618347 
	C666.405701,434.586304 664.633850,435.234131 662.861938,435.881958 
	C662.609192,435.375275 662.356384,434.868591 662.103638,434.361877 
	C663.877380,433.497375 665.651123,432.632874 667.725342,431.885193 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M644.994385,378.026123 
	C645.446594,376.133148 645.898865,374.240173 646.675537,372.172363 
	C647.443909,371.996155 647.887878,371.994781 648.664673,371.994965 
	C648.994873,372.766327 648.992126,373.536163 648.658569,374.706055 
	C647.216614,376.079468 646.105469,377.052795 644.994385,378.026123 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M662.420288,456.244629 
	C662.209351,455.343597 662.340332,454.382538 662.750305,453.184998 
	C663.448608,452.976288 663.867981,453.004059 664.599670,453.063721 
	C665.738037,454.000732 666.564026,454.905823 668.058838,456.543793 
	C665.727417,456.385681 664.244873,456.285126 662.420288,456.244629 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M667.197754,426.366333 
	C667.827820,425.389221 668.584473,424.702209 669.663818,423.984436 
	C670.010742,425.411041 670.035034,426.868469 670.026001,428.658875 
	C669.103210,428.213379 668.213806,427.434906 667.197754,426.366333 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M658.996643,417.003174 
	C659.508667,417.305206 660.024048,417.604095 660.539429,417.902954 
	C660.354004,418.113159 660.168640,418.323395 659.983215,418.533600 
	C659.673767,418.211487 659.364258,417.889374 659.030701,417.280090 
	C659.006592,416.992950 659.000000,417.000000 658.996643,417.003174 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M664.331665,429.668152 
	C664.038696,429.654999 663.876953,429.498566 663.715149,429.342133 
	C663.964355,429.403046 664.213623,429.463959 664.331665,429.668152 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M665.019531,383.015564 
	C664.911987,383.042908 664.804443,383.070282 664.848450,383.048767 
	C665.000000,382.999878 665.019531,383.015564 665.019531,383.015564 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M664.992188,382.990112 
	C665.090027,382.952728 665.195740,382.925079 665.160522,382.956482 
	C665.019531,383.015564 665.000000,382.999878 664.992188,382.990112 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M654.001465,349.002014 
	C654.095642,348.966064 654.188354,348.928162 654.138611,348.943665 
	C653.996216,348.997040 654.000000,349.000000 654.001465,349.002014 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M657.651733,455.068909 
	C657.766113,455.640350 657.520874,456.232056 657.138000,457.155670 
	C658.515503,457.155670 659.653625,457.155670 660.791809,457.155670 
	C660.011230,457.994751 659.009705,458.729523 658.543457,459.716980 
	C658.311035,460.209351 659.058411,461.164215 659.694763,461.955994 
	C657.416260,465.877594 659.755798,469.772644 659.986328,473.756042 
	C661.067749,473.941589 662.170837,474.130859 663.703491,474.393860 
	C663.223206,476.537201 662.775330,478.535980 662.327454,480.534729 
	C661.337830,480.007721 660.391296,479.359741 659.346680,478.986938 
	C658.248474,478.595062 657.056274,478.466614 655.512634,479.286224 
	C657.132446,481.050781 658.752319,482.815338 660.648743,484.881226 
	C657.461060,486.743652 657.117371,488.785736 660.863708,490.787628 
	C661.712891,489.558960 662.562866,488.329102 663.774902,487.048676 
	C664.252930,487.596741 664.368958,488.195374 664.727417,489.267273 
	C664.964783,490.097168 664.959656,490.453796 664.954590,490.810394 
	C664.914917,491.000031 664.875244,491.189667 664.510742,491.722839 
	C663.780457,492.655640 663.375061,493.244843 662.969666,493.834045 
	C662.678650,494.054016 662.387573,494.273956 661.615356,494.734772 
	C659.699890,494.551147 658.265564,494.126648 656.851013,493.707977 
	C655.546204,496.127686 654.244446,498.541840 652.807129,501.026306 
	C652.794922,501.082336 652.918213,501.068146 653.372314,501.043091 
	C654.466858,501.337463 655.230652,501.642670 655.994385,501.947876 
	C655.994019,502.406677 655.993652,502.865448 655.627197,503.656799 
	C653.518738,504.330841 651.776428,504.672333 649.777344,504.830994 
	C647.693359,503.527161 652.814026,500.151917 647.995361,499.617126 
	C648.668823,497.093201 649.358215,494.948425 650.298645,492.022827 
	C648.172546,492.992065 647.046204,493.505524 645.971619,493.649719 
	C646.426819,491.866699 646.830322,490.452881 647.583557,489.044098 
	C649.041199,488.972656 650.149109,488.896210 651.905640,488.774994 
	C650.754089,486.901733 649.861267,485.449310 649.034790,483.688843 
	C649.752136,482.590942 650.403015,481.801025 651.426270,481.002136 
	C654.973633,478.608032 654.537781,476.647369 650.178955,473.557343 
	C650.134827,472.833862 650.126099,472.419373 650.117371,472.004883 
	C651.542786,472.179474 652.968201,472.354065 654.791321,472.577362 
	C654.143799,468.753479 652.872864,465.108887 653.166016,461.594818 
	C653.355103,459.327332 655.837463,457.251129 657.651733,455.068909 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M641.403809,544.997131 
	C641.332520,541.565613 641.268982,538.033386 642.144165,534.751404 
	C642.977234,531.627441 644.790588,528.764893 646.669922,524.716858 
	C644.422302,525.554688 642.703308,526.195374 640.578125,526.495605 
	C640.148071,525.146912 640.124207,524.138733 640.461731,523.097168 
	C642.534485,522.360596 644.245789,521.657349 646.333984,520.926208 
	C648.413940,520.734436 650.143494,520.698242 651.808716,520.352356 
	C652.629517,520.181824 653.331360,519.438721 654.086975,518.954651 
	C658.771179,520.517517 660.060974,524.995911 657.739380,528.795593 
	C654.764587,529.749451 652.558655,530.456787 650.352722,531.164124 
	C650.432678,531.731506 650.512634,532.298889 650.592590,532.866272 
	C653.308472,532.544067 657.139038,529.150635 657.552795,533.982849 
	C654.354187,534.812195 651.342407,535.593079 647.925293,536.703552 
	C647.243225,537.151855 646.966370,537.270569 646.689575,537.389282 
	C646.778442,537.512695 646.867371,537.636047 646.956238,537.759460 
	C647.303345,537.505493 647.650452,537.251526 647.997559,536.997559 
	C649.873657,537.254639 651.749756,537.511780 653.625854,537.768921 
	C653.736023,538.236816 653.846130,538.704651 653.956238,539.172546 
	C650.506897,539.619263 647.057617,540.065918 642.102051,540.707703 
	C644.837585,541.672607 646.196899,542.152100 647.556213,542.631592 
	C647.628296,543.115479 647.700378,543.599304 647.772461,544.083191 
	C645.788879,544.390686 643.805359,544.698242 641.403809,544.997131 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M652.942627,500.956055 
	C654.244446,498.541840 655.546204,496.127686 656.851013,493.707977 
	C658.265564,494.126648 659.699890,494.551147 661.471069,494.969818 
	C663.890198,497.228729 665.972473,499.493469 668.054749,501.758209 
	C667.753113,502.381226 667.451477,503.004211 667.149841,503.627197 
	C664.674438,503.139557 662.201660,502.637970 659.722351,502.170868 
	C658.696045,501.977509 657.655762,501.858002 656.308105,501.826416 
	C655.230652,501.642670 654.466858,501.337463 653.349365,501.016876 
	C652.995605,501.001495 652.942688,500.956055 652.942627,500.956055 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M654.043945,518.634460 
	C653.331360,519.438721 652.629517,520.181824 651.808716,520.352356 
	C650.143494,520.698242 648.413940,520.734436 646.223755,520.639526 
	C645.785522,519.214355 645.834351,518.047852 645.891479,516.533386 
	C645.926086,514.122925 645.952393,512.060486 646.333984,509.992706 
	C647.508728,509.624176 648.328125,509.260956 649.487915,508.883148 
	C651.039062,508.354126 652.249939,507.839691 654.674561,506.809570 
	C653.493286,509.818298 653.020386,511.750946 652.032837,513.369629 
	C650.498108,515.885193 651.720947,516.617310 653.915588,517.024109 
	C653.944031,517.454163 653.972534,517.884216 654.043945,518.634460 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M654.263062,516.845459 
	C651.720947,516.617310 650.498108,515.885193 652.032837,513.369629 
	C653.020386,511.750946 653.493286,509.818298 654.674561,506.809570 
	C652.249939,507.839691 651.039062,508.354126 649.490723,508.550659 
	C649.446838,507.159790 649.740479,506.086823 650.034058,505.013855 
	C651.776428,504.672333 653.518738,504.330841 655.652344,504.000183 
	C655.565918,508.229614 655.088257,512.448242 654.263062,516.845459 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M665.322388,490.870483 
	C664.959656,490.453796 664.964783,490.097168 664.962097,489.474670 
	C666.290100,488.843445 667.625916,488.478180 668.961670,488.112885 
	C669.144165,488.548248 669.326660,488.983612 669.509094,489.418976 
	C668.236084,489.922852 666.963135,490.426697 665.322388,490.870483 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M661.799011,506.798798 
	C661.150818,506.472290 660.805542,506.080292 660.460327,505.688263 
	C661.008545,505.541809 661.556702,505.395325 662.104858,505.248871 
	C662.103882,505.743683 662.102905,506.238495 661.799011,506.798798 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M639.955261,550.717957 
	C639.496521,551.043945 639.056885,551.017273 638.287170,550.958130 
	C638.290588,550.108032 638.624146,549.290405 638.957642,548.472778 
	C639.296570,549.103577 639.635498,549.734375 639.955261,550.717957 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M641.991943,547.719604 
	C642.663025,547.657959 643.137573,547.801392 643.612183,547.944824 
	C643.341248,548.251648 643.070312,548.558411 642.799377,548.865173 
	C642.464783,548.551697 642.130127,548.238220 641.991943,547.719604 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M663.292236,493.845490 
	C663.375061,493.244843 663.780457,492.655640 664.465210,492.009857 
	C664.367859,492.587830 663.991333,493.222382 663.292236,493.845490 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M639.029907,553.294067 
	C639.000000,553.004883 639.492065,552.987427 639.738037,552.991394 
	C640.107117,553.497620 640.230164,553.999878 640.353210,554.502136 
	C639.922119,554.195862 639.490967,553.889526 639.029907,553.294067 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M665.281494,486.822205 
	C665.159973,486.626556 665.322571,486.390228 665.771484,486.113525 
	C665.882629,486.302216 665.718628,486.538330 665.281494,486.822205 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M607.642212,312.909607 
	C608.548767,314.836273 609.081726,316.632843 609.811401,319.028137 
	C610.007874,320.073944 610.007690,320.521118 609.631714,320.978394 
	C608.282715,322.527679 607.353027,324.096863 606.321655,325.596039 
	C605.571838,326.685944 604.356873,328.705048 603.914917,328.562042 
	C600.004578,327.296722 596.955750,328.751099 594.002686,330.704651 
	C593.407837,327.553711 591.992554,327.314789 589.475403,328.382751 
	C587.842163,329.075714 585.808899,328.825745 583.639343,328.956421 
	C582.883362,328.280884 582.442871,327.647217 582.005493,326.622803 
	C582.048767,325.452454 582.088989,324.672791 582.465698,323.921143 
	C583.388855,323.406769 584.531250,322.452271 584.479919,322.383026 
	C583.538879,321.114044 582.638000,319.648712 581.341919,318.863251 
	C580.339111,318.255554 578.803345,318.527283 577.504578,318.408051 
	C580.361816,316.777954 582.640869,315.377808 580.047058,311.599854 
	C580.360474,309.790649 580.635681,308.348511 580.938110,306.539856 
	C582.297180,303.796661 583.628906,301.419983 585.229492,299.136414 
	C586.007812,299.478760 586.517212,299.727936 587.026672,299.977142 
	C587.026672,299.977142 587.000000,299.998779 587.035522,300.341431 
	C588.176819,302.041168 587.046814,306.141174 591.057922,303.933685 
	C596.371704,302.806396 598.436707,306.697906 601.209595,310.017944 
	C602.492371,311.553894 605.204468,311.896118 607.642212,312.909607 
M585.482788,310.589600 
	C585.482788,310.589600 585.415466,310.477448 585.482788,310.589600 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M568.928772,279.039368 
	C569.077759,279.346985 569.301331,279.584412 569.831055,279.841217 
	C569.363220,280.665314 568.775513,281.577087 567.943604,282.101807 
	C565.235657,283.809753 565.921021,284.964050 569.015015,286.227356 
	C569.409180,288.330902 569.205627,290.169556 568.647217,292.028687 
	C567.050537,292.241638 565.808716,292.434113 563.785095,292.747772 
	C564.992493,291.002350 565.752319,289.903931 567.077759,287.987885 
	C561.419800,288.704559 556.213989,289.973083 552.002014,285.616821 
	C550.324158,281.839386 548.649231,278.437836 546.974243,275.036285 
	C549.843811,276.186676 552.713440,277.337067 554.528748,278.064819 
	C557.152893,280.099243 557.580444,272.117188 559.836365,278.675262 
	C559.806763,279.071655 559.907898,279.041748 560.009033,279.011810 
	C560.680969,277.659119 561.352905,276.306427 562.190430,274.437469 
	C562.426636,273.799469 562.586548,273.616730 562.552307,273.566620 
	C562.402771,273.347717 562.196899,273.167328 562.011475,272.972992 
	C562.011475,272.972992 562.006470,272.996887 562.021973,272.990601 
	C562.906921,272.817566 563.776489,272.650787 564.822266,273.055603 
	C564.988525,274.063904 564.978455,274.500610 564.998047,275.274902 
	C565.684509,276.114288 566.341370,276.616089 567.039795,277.424561 
	C567.697144,278.167236 568.312927,278.603302 568.928772,279.039368 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M569.002075,292.008209 
	C569.205627,290.169556 569.409180,288.330902 569.367737,286.259369 
	C572.573181,285.230652 572.573181,285.230652 572.717651,289.816772 
	C574.640747,290.004669 576.606506,290.196716 579.105957,290.440948 
	C578.527344,291.827423 577.812317,293.540833 576.945374,295.914551 
	C576.516724,298.369019 576.240051,300.163147 575.712402,301.981232 
	C575.294800,302.026917 575.128174,302.048706 574.721558,302.052917 
	C574.481506,302.035339 574.002441,301.989075 573.996460,301.634827 
	C573.320435,300.517731 572.650330,299.754852 571.985718,298.599976 
	C570.994934,296.141388 569.998535,294.074799 569.002075,292.008209 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M575.963318,301.957306 
	C576.240051,300.163147 576.516724,298.369019 576.900146,296.266785 
	C577.455322,295.936218 577.903809,295.913727 578.690308,296.304504 
	C579.698120,300.828766 583.032593,298.142120 584.960632,299.043274 
	C583.628906,301.419983 582.297180,303.796661 580.545105,306.569824 
	C577.762207,306.310547 575.399597,305.654846 573.033813,304.661499 
	C573.354553,303.545624 573.678528,302.767365 574.002441,301.989075 
	C574.002441,301.989075 574.481506,302.035339 574.748901,302.300842 
	C575.195923,302.813965 575.375488,303.061615 575.555115,303.309235 
	C575.691223,302.858582 575.827271,302.407959 575.963318,301.957306 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M585.229492,299.136414 
	C583.032593,298.142120 579.698120,300.828766 579.027222,296.309143 
	C579.374268,295.558807 579.802490,295.268127 580.058716,294.867462 
	C583.038208,290.207825 583.024841,290.208069 586.118225,294.592651 
	C586.933716,295.748505 587.803284,296.866241 588.360718,297.615479 
	C588.202698,297.836090 587.659180,298.594635 587.071167,299.665161 
	C586.517212,299.727936 586.007812,299.478760 585.229492,299.136414 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M591.099243,303.607971 
	C587.046814,306.141174 588.176819,302.041168 587.049561,300.329468 
	C589.098511,299.829315 591.169006,299.683777 594.016296,299.483673 
	C592.860474,301.010468 592.000488,302.146362 591.099243,303.607971 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M600.488586,291.375549 
	C600.250732,290.328247 600.250732,289.546692 600.250732,288.277832 
	C602.999512,290.108948 605.368774,291.687256 607.738037,293.265533 
	C607.465393,293.760468 607.192749,294.255371 606.920105,294.750305 
	C604.855530,293.713959 602.791016,292.677582 600.488586,291.375549 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M603.538025,308.415771 
	C603.596436,307.940216 603.834778,307.650543 604.073120,307.360901 
	C604.256958,307.552063 604.440857,307.743256 604.624695,307.934418 
	C604.322449,308.156830 604.020142,308.379242 603.538025,308.415771 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M596.932312,571.954346 
	C597.600830,574.005737 598.269348,576.057068 598.581116,578.507080 
	C597.150146,577.958008 596.075928,577.010376 594.272522,575.419495 
	C594.899353,578.799744 595.361267,581.290710 595.823181,583.781616 
	C595.119812,583.877136 594.416443,583.972595 593.368408,583.681335 
	C592.427307,582.580994 591.830811,581.867432 591.234375,581.153870 
	C590.810059,582.045410 590.385742,582.936890 589.617981,583.841064 
	C587.440796,584.813965 585.607117,585.774170 583.411011,586.791565 
	C580.031860,587.539978 577.015076,588.231201 574.056152,588.605347 
	C575.076111,587.512268 576.038147,586.736328 577.142700,585.639587 
	C577.592712,582.503967 577.900330,579.689087 578.246582,576.520081 
	C578.527283,576.492493 579.674683,576.379822 580.976685,576.251953 
	C579.700989,573.891602 577.666870,571.874207 577.723755,569.917664 
	C577.923401,563.062988 575.103455,559.758911 568.424011,560.384644 
	C567.672180,560.455078 567.031616,561.713745 566.339478,562.421326 
	C569.753113,565.636230 566.599304,567.705505 564.631897,570.017700 
	C563.178894,569.313599 562.103210,568.639282 560.982178,567.612122 
	C560.298584,565.517334 559.660339,563.775391 559.347717,562.055298 
	C563.998474,562.495789 562.655701,559.210632 562.989624,556.658447 
	C563.969543,555.876343 565.242737,554.838623 565.855347,555.140808 
	C567.712036,556.056274 569.312927,557.490662 572.201172,559.583557 
	C572.201172,556.841553 572.201172,555.149902 572.201172,552.803955 
	C575.497375,549.878357 576.106873,547.033875 573.854004,541.292358 
	C573.246521,542.524902 572.735535,543.561584 572.069824,544.912354 
	C571.095459,542.883118 570.348816,541.360901 569.631409,539.825012 
	C568.934998,538.333984 568.268494,536.828918 567.167480,534.400757 
	C570.114441,536.147339 571.925720,537.220825 574.624390,538.820251 
	C573.092651,535.732849 572.123901,533.780151 571.291382,532.102051 
	C573.277405,532.102051 574.678345,532.102051 576.432861,532.039185 
	C578.651184,530.776428 580.515930,529.576599 582.380737,528.376709 
	C582.207336,528.316895 582.033936,528.257141 581.860596,528.197327 
	C583.908691,527.133179 585.956787,526.069031 588.002441,525.002441 
	C588.000000,525.000000 587.996338,524.996887 587.998657,525.377563 
	C589.572754,528.449036 591.144531,531.139893 592.388977,533.270325 
	C593.574402,532.278931 595.145386,530.964966 597.298767,529.163879 
	C597.298767,533.567322 597.104248,536.541626 597.460815,539.448303 
	C597.533203,540.038574 600.034607,541.090515 600.817444,540.707092 
	C605.006470,538.655334 612.662415,542.651855 613.392151,545.846741 
	C611.898376,548.727783 611.092041,550.283020 610.317505,551.776917 
	C607.902344,549.912292 602.419861,553.549011 601.105042,548.623474 
	C603.597046,547.492981 605.483032,546.870605 607.035339,545.775269 
	C607.441711,545.488464 606.811584,543.732849 606.739929,543.229858 
	C603.999084,543.924438 601.975220,544.437378 599.654846,545.025452 
	C599.875916,547.442627 600.109985,550.002136 600.350708,552.633667 
	C601.287048,552.745178 601.932983,552.855164 602.583008,552.890503 
	C603.297791,552.929321 604.016357,552.899353 605.040649,552.899353 
	C599.710571,559.265503 599.710571,559.265503 593.903076,560.288147 
	C595.170166,557.604980 596.276978,555.923340 596.759277,554.078430 
	C597.418579,551.556946 597.602783,548.911255 598.009583,546.191833 
	C592.170593,549.180359 587.509216,551.566101 582.847839,553.951904 
	C582.536560,553.655090 582.225281,553.358337 581.914001,553.061584 
	C582.774170,551.580078 583.634338,550.098511 583.782959,549.842590 
	C582.794067,548.261353 581.926025,547.106018 581.385010,545.813721 
	C581.275757,545.552734 582.488220,544.257385 582.947266,544.331482 
	C584.224670,544.537598 585.497559,545.088074 586.638000,545.738159 
	C587.803589,546.402527 588.809814,547.346497 589.885803,548.168091 
	C590.284119,547.846558 590.682434,547.525024 591.080688,547.203491 
	C590.518921,546.167175 590.070679,545.041565 589.352905,544.127747 
	C588.918884,543.575134 587.468506,543.059692 587.487549,543.020569 
	C589.124023,539.659241 583.118835,537.827759 585.694153,534.017029 
	C586.086060,533.437195 583.622742,530.927734 582.480103,529.310791 
	C582.033386,529.596802 581.586731,529.882812 581.140076,530.168762 
	C581.018311,532.586426 580.896545,535.004150 580.774719,537.421814 
	C580.319214,537.729492 579.863647,538.037170 579.408142,538.344849 
	C579.136963,537.071899 578.865845,535.799011 578.594666,534.526123 
	C577.898438,534.868774 577.202271,535.211487 576.506042,535.554138 
	C576.506042,543.548889 576.506042,551.543701 576.506042,558.949585 
	C579.241638,560.189514 581.548584,561.235168 584.429688,562.541016 
	C585.275330,562.424316 587.206848,562.157776 589.138428,561.891235 
	C589.250793,562.354553 589.363220,562.817810 589.475586,563.281128 
	C587.093872,564.312012 584.642456,565.214905 582.372375,566.450989 
	C581.683960,566.825806 581.496826,568.121277 581.090942,569.371460 
	C581.764038,572.989807 582.426208,576.231934 583.231140,580.173096 
	C586.260986,576.097351 584.975891,572.556519 583.301514,568.870911 
	C585.640442,567.280701 586.911133,567.385681 588.008362,570.069031 
	C588.748291,571.878662 590.543701,573.256714 591.867004,574.827820 
	C592.203613,574.576294 592.540283,574.324768 592.876953,574.073242 
	C592.300415,571.942688 591.723938,569.812134 590.966248,567.012146 
	C593.533264,569.138611 595.232788,570.546509 596.932312,571.954346 
M589.320129,537.018494 
	C589.071045,536.865051 588.821899,536.711609 588.572815,536.558167 
	C588.482056,536.816956 588.391235,537.075745 588.300476,537.334595 
	C588.670959,537.378479 589.041443,537.422424 589.320129,537.018494 
M585.810608,582.784546 
	C585.976624,582.661865 586.142639,582.539124 586.308655,582.416443 
	C586.075928,582.443054 585.843262,582.469727 585.810608,582.784546 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M573.998230,588.922363 
	C577.015076,588.231201 580.031860,587.539978 583.488647,587.175659 
	C583.112427,589.332214 582.296326,591.161987 581.478943,592.994507 
	C583.268127,592.994507 585.131165,592.994507 586.997070,592.997253 
	C587.000000,593.000000 587.005188,593.005798 586.973511,593.292603 
	C586.352051,594.342468 585.762207,595.105530 585.172363,595.868530 
	C585.969604,596.244507 586.734680,596.750061 587.573547,596.958130 
	C588.331970,597.146362 589.171021,597.009766 589.974487,597.016418 
	C589.876160,598.520569 589.777832,600.024719 589.618774,602.457642 
	C587.336853,601.660889 584.879150,600.802856 581.203857,599.519653 
	C581.215637,599.479797 580.811218,600.851807 580.406738,602.223816 
	C579.996704,601.914307 579.586609,601.604736 579.176575,601.295227 
	C579.343506,600.402161 579.754883,599.469238 579.617188,598.625916 
	C579.432739,597.496338 578.823059,596.436157 578.395996,595.346191 
	C577.757141,596.199646 577.113220,597.049316 576.480652,597.907349 
	C575.619019,599.075928 574.766052,600.250854 573.909363,601.423096 
	C573.499451,601.209045 573.089539,600.994934 572.679565,600.780884 
	C572.579346,599.637268 572.479187,598.493591 572.311401,596.579102 
	C568.719055,599.075378 565.580688,601.256226 562.200806,603.702637 
	C560.993347,602.889709 560.027283,601.811218 558.707092,600.337341 
	C558.031860,601.105225 557.193848,602.058228 556.545532,602.795532 
	C555.865112,601.773315 555.101013,600.625427 554.149658,599.189270 
	C555.756348,597.648438 557.403137,596.018738 559.384216,595.245728 
	C561.513123,594.415039 563.955811,594.388733 567.002197,594.005188 
	C569.827881,592.306702 571.913086,590.614563 573.998230,588.922363 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M596.093079,583.946411 
	C595.361267,581.290710 594.899353,578.799744 594.272522,575.419495 
	C596.075928,577.010376 597.150146,577.958008 598.545898,578.897827 
	C603.158875,580.567322 604.710938,579.203430 603.095093,574.101746 
	C604.852783,574.860779 606.069031,575.385986 607.624573,575.944885 
	C608.510742,577.314575 609.057556,578.650635 610.116821,581.238892 
	C606.043274,581.825745 602.298035,582.365295 597.692505,583.028748 
	C600.039246,584.049072 601.817566,584.822205 603.595886,585.595398 
	C603.625244,585.973511 603.654602,586.351624 603.683899,586.729675 
	C601.037842,587.053223 598.391724,587.376770 595.816772,587.691650 
	C596.020447,586.356750 596.191711,585.234009 596.093079,583.946411 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M589.961426,583.828369 
	C590.385742,582.936890 590.810059,582.045410 591.234375,581.153870 
	C591.830811,581.867432 592.427307,582.580994 593.013306,583.669067 
	C593.002930,584.043579 592.995117,583.993530 592.832275,584.101807 
	C592.810181,584.563232 592.950867,584.916260 593.091553,585.269348 
	C593.352417,588.452576 593.613281,591.635803 593.906189,595.210083 
	C592.796082,595.138916 591.768188,595.072998 590.115662,594.831543 
	C588.662354,594.106018 587.833801,593.555908 587.005188,593.005798 
	C587.005188,593.005798 587.000000,593.000000 586.974487,592.651794 
	C587.158508,590.845154 587.146484,589.306580 587.637329,587.949951 
	C588.166626,586.487305 589.166748,585.195007 589.961426,583.828369 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M586.973511,593.292603 
	C587.833801,593.555908 588.662354,594.106018 589.751709,594.838074 
	C590.007935,595.463562 590.003479,595.907043 589.986755,596.683472 
	C589.171021,597.009766 588.331970,597.146362 587.573547,596.958130 
	C586.734680,596.750061 585.969604,596.244507 585.172363,595.868530 
	C585.762207,595.105530 586.352051,594.342468 586.973511,593.292603 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M593.119995,584.946777 
	C592.950867,584.916260 592.810181,584.563232 592.857300,584.097534 
	C593.079529,584.197937 593.113953,584.411072 593.119995,584.946777 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M588.004883,525.004883 
	C585.956787,526.069031 583.908691,527.133179 581.860596,528.197327 
	C582.033936,528.257141 582.207336,528.316895 582.380737,528.376709 
	C580.515930,529.576599 578.651184,530.776428 576.157043,531.896729 
	C575.021729,531.198486 574.515747,530.579712 574.383057,529.960266 
	C579.445679,529.768555 576.864319,526.627319 577.008057,524.535767 
	C577.106445,523.104553 577.477539,521.692078 577.726685,520.271240 
	C578.930542,520.994202 580.150513,521.692017 581.332336,522.449219 
	C582.105713,522.944580 582.823792,523.526184 583.625488,524.112183 
	C585.430542,521.895081 587.037781,519.920898 588.885437,517.651489 
	C591.413269,519.687561 593.520142,521.384521 595.946167,523.338562 
	C596.709900,521.712585 597.261780,520.537720 597.542847,519.939270 
	C598.955261,521.095947 600.265930,522.921204 601.187988,522.742432 
	C602.467285,522.494324 603.454773,520.741333 605.307556,518.894897 
	C606.164185,519.152771 607.959534,519.935974 609.821411,520.155762 
	C610.819397,520.273438 611.931885,519.421631 612.996338,519.003662 
	C613.000000,519.000000 613.007324,518.992676 613.339844,518.958496 
	C616.108032,517.946350 618.543701,516.968323 620.984741,515.581177 
	C621.910400,513.324280 622.830627,511.476349 623.891296,509.346436 
	C619.655823,505.528412 619.655823,505.528412 623.006775,500.616241 
	C621.570618,500.205566 620.269104,499.833374 620.269714,499.833588 
	C617.693237,502.367889 615.845520,504.185364 613.797729,505.735260 
	C612.735596,505.307739 611.873657,505.147827 610.988770,504.652161 
	C609.648376,502.860443 608.330994,501.404480 606.907776,499.701172 
	C606.548828,498.981567 606.295715,498.509308 606.379028,498.027283 
	C611.644348,498.116486 608.312500,495.575104 608.132202,493.683655 
	C608.082947,491.672607 607.901367,489.976685 607.637756,487.514160 
	C606.229797,489.181976 605.593262,489.936035 604.956665,490.690125 
	C604.651245,490.571442 604.345825,490.452728 604.040344,490.334045 
	C604.360229,488.551147 604.680176,486.768219 605.000427,484.992981 
	C605.000793,485.000671 604.985291,484.999908 605.375610,484.998291 
	C607.511902,484.330444 609.257874,483.664154 611.382751,483.000610 
	C613.548401,483.709839 615.335144,484.416382 617.132507,485.464233 
	C616.819031,487.359222 616.494873,488.912872 616.136230,490.631897 
	C617.032043,492.668518 618.130310,495.165527 619.486023,498.247650 
	C620.826965,496.500488 621.998901,494.973511 623.395264,493.154175 
	C623.742676,493.941223 624.045105,494.626343 624.629089,495.949371 
	C624.968689,493.564758 625.155457,492.253418 625.342163,490.942047 
	C625.898193,490.967285 626.454224,490.992523 627.010254,491.017761 
	C627.010254,493.176849 627.279541,495.379364 626.940186,497.483734 
	C626.541565,499.955414 625.415466,502.311401 625.037659,504.783722 
	C624.924377,505.524902 626.577332,507.322266 626.876770,507.208099 
	C628.405151,506.625366 629.755249,505.575104 631.445679,504.513916 
	C631.549316,505.059235 631.684814,505.772522 631.866943,506.731018 
	C636.560791,503.431244 637.855225,507.323242 640.489807,511.010620 
	C637.277832,511.357635 634.890808,511.615509 631.789185,511.950592 
	C634.301819,513.857788 636.521973,515.542908 637.745056,516.471252 
	C636.013184,520.258240 634.465332,523.642883 632.527283,527.016235 
	C631.088928,527.000122 630.040833,526.995178 628.996033,526.572449 
	C627.720886,522.522888 629.404175,517.761475 624.319824,514.184021 
	C624.169189,516.883911 624.082153,518.443726 623.997559,520.001770 
	C624.000000,520.000000 624.003601,519.995117 623.613403,519.998413 
	C621.483765,521.333191 619.744324,522.664734 617.702087,524.191528 
	C613.119019,526.407288 614.022156,530.220276 613.721008,534.568481 
	C615.566162,533.396973 616.747314,532.647034 618.272095,531.969299 
	C618.503662,533.534607 618.391663,535.027771 618.240234,537.045532 
	C618.832520,537.500977 620.087585,538.466064 621.768066,539.758362 
	C622.235107,537.428772 622.459778,535.523560 623.029236,533.727661 
	C623.448547,532.405396 624.318604,531.226196 624.993774,530.228760 
	C624.999756,530.474304 624.995117,530.965515 624.996460,531.383545 
	C624.997803,533.560730 624.997803,535.320007 624.997803,537.217163 
	C626.632568,537.133423 627.888550,537.069031 629.058228,537.358276 
	C626.576721,539.844360 624.181519,541.976929 621.955017,543.959290 
	C620.457275,543.465149 618.748779,542.901428 617.040222,542.337708 
	C616.694336,544.517944 615.928162,546.746216 616.170044,548.859192 
	C616.302002,550.011536 618.142578,550.968384 619.212524,552.013367 
	C619.190735,552.459717 619.168945,552.906067 618.735718,553.692505 
	C617.223816,554.014587 616.123413,553.996643 614.963989,553.640381 
	C614.037598,553.132751 613.170227,552.963257 612.302795,552.793762 
	C612.871704,553.525269 613.440613,554.256775 614.016174,555.378662 
	C612.721008,563.412292 607.738220,566.061951 601.404846,562.593567 
	C600.781555,562.252136 599.683228,562.778198 598.805359,562.901611 
	C599.059509,563.833191 599.052551,564.987549 599.619629,565.652039 
	C600.996399,567.265320 602.640137,568.650757 604.176270,570.127930 
	C603.349792,570.433044 602.523315,570.738220 601.203735,570.787720 
	C600.117798,570.988708 599.524963,571.445435 598.932068,571.902100 
	C598.488220,571.913391 598.044312,571.924683 597.266357,571.945190 
	C595.232788,570.546509 593.533264,569.138611 590.966248,567.012146 
	C591.723938,569.812134 592.300415,571.942688 592.876953,574.073242 
	C592.540283,574.324768 592.203613,574.576294 591.867004,574.827820 
	C590.543701,573.256714 588.748291,571.878662 588.008362,570.069031 
	C586.911133,567.385681 585.640442,567.280701 582.979309,568.870850 
	C581.936890,568.999939 581.508484,568.997559 581.080078,568.995117 
	C581.496826,568.121277 581.683960,566.825806 582.372375,566.450989 
	C584.642456,565.214905 587.093872,564.312012 589.475586,563.281128 
	C589.363220,562.817810 589.250793,562.354553 589.138428,561.891235 
	C587.206848,562.157776 585.275330,562.424316 584.429688,562.541016 
	C581.548584,561.235168 579.241638,560.189514 576.506042,558.949585 
	C576.506042,551.543701 576.506042,543.548889 576.506042,535.554138 
	C577.202271,535.211487 577.898438,534.868774 578.594666,534.526123 
	C578.865845,535.799011 579.136963,537.071899 579.408142,538.344849 
	C579.863647,538.037170 580.319214,537.729492 580.774719,537.421814 
	C580.896545,535.004150 581.018311,532.586426 581.140076,530.168762 
	C581.586731,529.882812 582.033386,529.596802 582.480103,529.310791 
	C583.622742,530.927734 586.086060,533.437195 585.694153,534.017029 
	C583.118835,537.827759 589.124023,539.659241 587.487549,543.020569 
	C587.468506,543.059692 588.918884,543.575134 589.352905,544.127747 
	C590.070679,545.041565 590.518921,546.167175 591.080688,547.203491 
	C590.682434,547.525024 590.284119,547.846558 589.885803,548.168091 
	C588.809814,547.346497 587.803589,546.402527 586.638000,545.738159 
	C585.497559,545.088074 584.224670,544.537598 582.947266,544.331482 
	C582.488220,544.257385 581.275757,545.552734 581.385010,545.813721 
	C581.926025,547.106018 582.794067,548.261353 583.782959,549.842590 
	C583.634338,550.098511 582.774170,551.580078 581.914001,553.061584 
	C582.225281,553.358337 582.536560,553.655090 582.847839,553.951904 
	C587.509216,551.566101 592.170593,549.180359 598.009583,546.191833 
	C597.602783,548.911255 597.418579,551.556946 596.759277,554.078430 
	C596.276978,555.923340 595.170166,557.604980 593.903076,560.288147 
	C599.710571,559.265503 599.710571,559.265503 605.040649,552.899353 
	C604.016357,552.899353 603.297791,552.929321 602.583008,552.890503 
	C601.932983,552.855164 601.287048,552.745178 600.350708,552.633667 
	C600.109985,550.002136 599.875916,547.442627 599.654846,545.025452 
	C601.975220,544.437378 603.999084,543.924438 606.739929,543.229858 
	C606.811584,543.732849 607.441711,545.488464 607.035339,545.775269 
	C605.483032,546.870605 603.597046,547.492981 601.105042,548.623474 
	C602.419861,553.549011 607.902344,549.912292 610.317505,551.776917 
	C611.092041,550.283020 611.898376,548.727783 613.392151,545.846741 
	C612.662415,542.651855 605.006470,538.655334 600.817444,540.707092 
	C600.034607,541.090515 597.533203,540.038574 597.460815,539.448303 
	C597.104248,536.541626 597.298767,533.567322 597.298767,529.163879 
	C595.145386,530.964966 593.574402,532.278931 592.388977,533.270325 
	C591.144531,531.139893 589.572754,528.449036 588.334534,525.323730 
	C588.968445,524.181458 589.268799,523.473694 589.569214,522.765930 
	C589.194275,522.638672 588.819336,522.511414 588.444397,522.384216 
	C588.297852,523.257751 588.151367,524.131348 588.004883,525.004883 
M604.774658,533.716797 
	C604.927368,534.513672 605.080017,535.310547 605.448975,537.235718 
	C606.393738,534.990906 607.604248,533.494934 607.331482,532.353271 
	C607.085876,531.325073 605.214417,530.685303 604.060059,529.874207 
	C603.796997,530.158997 603.533997,530.443787 603.270935,530.728577 
	C603.667664,531.524292 604.064392,532.320007 604.774658,533.716797 
M601.739197,529.144592 
	C601.600098,528.585083 601.460938,528.025574 601.321777,527.466064 
	C600.956482,527.664551 600.591125,527.863037 600.225830,528.061523 
	C600.581238,528.570496 600.936584,529.079529 601.739197,529.144592 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M632.917480,527.027466 
	C634.465332,523.642883 636.013184,520.258240 637.745056,516.471252 
	C636.521973,515.542908 634.301819,513.857788 631.789185,511.950592 
	C634.890808,511.615509 637.277832,511.357635 640.489807,511.010620 
	C637.855225,507.323242 636.560791,503.431244 631.866943,506.731018 
	C631.684814,505.772522 631.549316,505.059235 631.445679,504.513916 
	C629.755249,505.575104 628.405151,506.625366 626.876770,507.208099 
	C626.577332,507.322266 624.924377,505.524902 625.037659,504.783722 
	C625.415466,502.311401 626.541565,499.955414 626.940186,497.483734 
	C627.279541,495.379364 627.010254,493.176849 627.010254,491.017761 
	C626.454224,490.992523 625.898193,490.967285 625.342163,490.942047 
	C625.155457,492.253418 624.968689,493.564758 624.629089,495.949371 
	C624.045105,494.626343 623.742676,493.941223 623.395264,493.154175 
	C621.998901,494.973511 620.826965,496.500488 619.486023,498.247650 
	C618.130310,495.165527 617.032043,492.668518 616.136230,490.631897 
	C616.494873,488.912872 616.819031,487.359222 617.443359,485.436340 
	C618.161499,485.049225 618.579529,485.031342 619.132935,485.294006 
	C620.394897,486.474762 620.598145,487.812988 623.114380,487.645203 
	C626.264771,487.435181 630.852539,488.456573 629.770142,493.939789 
	C633.079834,493.939789 635.858032,493.939789 639.013855,493.939789 
	C637.226929,496.436218 635.577271,498.740784 633.927612,501.045380 
	C634.334290,501.373413 634.740967,501.701477 635.147644,502.029541 
	C638.738403,499.359344 642.329163,496.689148 645.919922,494.018982 
	C647.046204,493.505524 648.172546,492.992065 650.298645,492.022827 
	C649.358215,494.948425 648.668823,497.093201 647.848145,499.954895 
	C647.057312,502.854889 646.397705,505.037933 645.831177,506.912872 
	C644.143555,506.912872 642.709412,506.912872 641.275269,506.912842 
	C642.397339,507.724976 643.506897,508.555298 644.648193,509.339508 
	C645.051880,509.616943 645.532898,509.781769 645.978638,509.998047 
	C645.952393,512.060486 645.926086,514.122925 645.548828,516.494629 
	C644.404541,515.528442 643.611267,514.252991 642.359375,512.240295 
	C641.442383,513.914307 640.505310,514.923767 640.359070,516.037170 
	C640.052429,518.372742 640.161804,520.762817 640.100342,523.130554 
	C640.124207,524.138733 640.148071,525.146912 640.233154,526.530273 
	C638.780090,527.843872 637.265808,528.782349 635.707214,529.748230 
	C638.037048,534.095947 633.584778,535.411194 631.208252,537.748779 
	C630.193481,537.376038 629.669006,537.190369 629.144531,537.004700 
	C627.888550,537.069031 626.632568,537.133423 624.997803,537.217163 
	C624.997803,535.320007 624.997803,533.560730 625.363647,531.425781 
	C629.707825,532.536560 632.338745,531.615540 632.917480,527.027466 
M629.883484,496.257996 
	C630.734619,496.918152 631.585754,497.578308 632.436829,498.238495 
	C632.679260,497.870972 632.921692,497.503448 633.164124,497.135925 
	C632.283997,496.786835 631.403870,496.437744 629.883484,496.257996 
M632.639160,531.472534 
	C632.852173,531.526306 633.065125,531.580078 633.278137,531.633911 
	C633.142273,531.502563 633.006409,531.371216 632.639160,531.472534 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M629.058228,537.358276 
	C629.669006,537.190369 630.193481,537.376038 630.869751,537.807251 
	C629.806458,542.504944 628.591431,546.957214 627.188232,551.704712 
	C627.000000,552.000000 627.008911,551.988220 627.008911,551.988220 
	C624.690857,552.002930 622.372803,552.017700 619.633667,552.022949 
	C618.142578,550.968384 616.302002,550.011536 616.170044,548.859192 
	C615.928162,546.746216 616.694336,544.517944 617.040222,542.337708 
	C618.748779,542.901428 620.457275,543.465149 621.955017,543.959290 
	C624.181519,541.976929 626.576721,539.844360 629.058228,537.358276 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M604.502930,570.131714 
	C602.640137,568.650757 600.996399,567.265320 599.619629,565.652039 
	C599.052551,564.987549 599.059509,563.833191 598.805359,562.901611 
	C599.683228,562.778198 600.781555,562.252136 601.404846,562.593567 
	C607.738220,566.061951 612.721008,563.412292 614.127380,555.158997 
	C614.436584,554.277039 614.700317,554.086975 615.023010,553.978638 
	C616.123413,553.996643 617.223816,554.014587 618.741455,554.027710 
	C619.370789,555.183350 620.183044,556.869019 619.699829,557.421326 
	C615.918640,561.743408 611.945435,565.905029 607.867798,569.951233 
	C607.326843,570.488037 605.864929,570.096863 604.502930,570.131714 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M599.180664,572.042847 
	C599.524963,571.445435 600.117798,570.988708 600.859497,570.786926 
	C600.481934,571.422485 599.955566,571.803101 599.180664,572.042847 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M626.994141,552.004395 
	C627.107239,552.145447 627.226196,552.282043 627.345215,552.418701 
	C627.265869,552.366455 627.186584,552.314148 627.058105,552.125061 
	C627.008911,551.988220 627.000000,552.000000 626.994141,552.004395 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M493.981964,287.989227 
	C492.492126,286.798004 491.002289,285.606812 489.261871,283.876282 
	C489.044067,282.901581 489.076874,282.466187 489.332794,282.020935 
	C489.555908,282.011078 490.002411,281.998779 490.327698,281.852966 
	C495.120026,281.409851 499.587097,281.112579 504.054169,280.815277 
	C506.613129,282.132385 509.317719,285.097748 511.337097,280.000061 
	C513.019592,282.286774 514.483398,285.435669 517.260620,281.039124 
	C517.688049,280.362457 519.264526,280.068451 520.258972,280.158173 
	C523.127625,280.417053 525.970398,280.962494 529.469360,281.493408 
	C529.469360,281.301392 529.469360,282.440308 529.469360,282.086334 
	C534.190063,283.080597 538.153687,283.915375 542.040771,284.734039 
	C542.462585,283.525818 542.885132,282.315491 543.635010,281.082367 
	C543.962341,281.059601 543.999329,281.509674 544.027466,282.076599 
	C544.730164,284.066772 545.404663,285.490051 545.926758,287.239075 
	C545.511047,288.697937 545.247742,289.830994 544.559143,290.983887 
	C540.819031,290.881927 537.752380,290.723267 537.931091,295.668396 
	C538.051758,299.006592 537.282288,302.376984 536.911011,305.732971 
	C536.338379,305.824066 535.765747,305.915161 535.193176,306.006256 
	C534.570679,304.586792 533.180237,302.987335 533.463257,301.780182 
	C534.495178,297.378601 536.085938,293.108002 537.217590,289.565704 
	C531.462219,285.943420 527.042236,286.269592 525.396729,291.916321 
	C523.817505,297.335327 531.450928,295.544220 532.470764,299.189728 
	C530.256042,299.808167 528.126770,300.402740 525.626221,301.039185 
	C522.832520,301.063141 520.410156,301.045227 517.666687,300.873230 
	C515.440491,301.046814 512.943848,302.253082 511.784637,301.472687 
	C510.431732,300.561890 510.297272,297.895020 509.519836,296.037933 
	C509.325317,295.573334 508.692596,295.292175 508.207672,294.881317 
	C509.556885,293.777710 510.623962,292.904846 512.107666,291.691193 
	C507.112396,289.937408 506.911713,294.084564 505.365997,296.351318 
	C504.472687,294.334076 503.732788,292.663177 502.996460,290.996155 
	C503.000000,291.000000 503.007568,291.007263 502.935364,290.624939 
	C499.902771,289.491486 496.942383,288.740356 493.981964,287.989227 
M512.476990,295.599152 
	C512.476990,295.599152 512.398010,295.474915 512.476990,295.599152 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M503.983154,280.468018 
	C499.587097,281.112579 495.120026,281.409851 490.244324,281.686951 
	C489.593719,281.788147 489.351715,281.909485 489.109680,282.030823 
	C489.076874,282.466187 489.044067,282.901581 488.985901,283.663269 
	C487.311707,282.898468 485.662903,281.807373 484.015625,280.343018 
	C485.465363,276.839081 487.089447,273.905518 490.958984,273.263824 
	C493.263885,272.881622 495.571106,272.399628 496.705261,275.730957 
	C499.296326,277.341125 501.604248,278.730957 503.983154,280.468018 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M515.995239,271.993774 
	C515.906555,272.031097 515.822571,272.074554 515.875366,272.063538 
	C516.012146,272.009064 516.000000,271.999908 515.995239,271.993774 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M516.464722,275.444580 
	C516.518677,275.411621 516.410706,275.477570 516.464722,275.444580 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M633.407471,334.006287 
	C632.117249,332.852020 631.221436,331.691040 630.325623,330.530060 
	C631.006287,330.007202 631.686890,329.484314 632.682556,328.979797 
	C633.828796,331.589752 635.062866,332.873413 637.986938,330.196655 
	C638.685852,330.021606 638.860291,330.023376 639.034790,330.025146 
	C639.448547,330.029327 639.862244,330.033539 640.583984,330.050476 
	C638.528625,331.375336 636.165283,332.687469 633.407471,334.006287 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M639.015137,329.766296 
	C638.860291,330.023376 638.685852,330.021606 638.249573,330.015503 
	C638.203247,329.603729 638.418762,329.196259 638.634277,328.788818 
	C638.754639,329.028381 638.875061,329.267944 639.015137,329.766296 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M627.255737,313.667816 
	C626.989685,313.651917 626.845276,313.512360 626.700806,313.372833 
	C626.926270,313.429932 627.151794,313.487061 627.255737,313.667816 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M546.079163,286.913300 
	C545.404663,285.490051 544.730164,284.066772 544.050293,282.301270 
	C544.578979,281.075317 545.313232,280.251312 545.598755,279.293579 
	C545.996216,277.960541 546.057739,276.527405 546.618652,275.086700 
	C548.649231,278.437836 550.324158,281.839386 551.624146,285.658325 
	C549.525757,286.354950 547.802490,286.634125 546.079163,286.913300 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M539.162842,272.145691 
	C540.637939,265.739471 542.543945,272.066376 544.662537,271.934875 
	C543.117554,272.091339 541.232361,272.196960 539.162842,272.145691 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M562.003723,273.276489 
	C562.196899,273.167328 562.402771,273.347717 562.552307,273.566620 
	C562.586548,273.616730 562.426636,273.799469 562.186035,274.053986 
	C562.009338,273.984497 562.002625,273.782227 562.003723,273.276489 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M642.014160,343.025024 
	C641.769470,342.687317 641.507568,342.330688 641.266724,341.960266 
	C641.261536,341.952332 641.499023,341.786591 641.623291,341.694458 
	C641.744324,341.976410 641.865356,342.258362 642.010986,342.785919 
	C642.035522,343.031555 641.999268,343.004456 642.014160,343.025024 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M568.948120,278.629211 
	C568.312927,278.603302 567.697144,278.167236 567.010925,277.081177 
	C566.283142,275.933197 565.625793,275.435242 564.968384,274.937286 
	C564.978455,274.500610 564.988525,274.063904 565.013428,273.299438 
	C568.970032,272.747528 569.171387,275.331116 568.948120,278.629211 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M670.192383,419.140808 
	C669.468140,418.452484 669.015259,417.922913 668.347534,417.243896 
	C668.211365,415.475037 668.290161,413.855621 668.667908,412.070221 
	C669.845825,413.750977 670.724670,415.597778 671.806274,417.722412 
	C672.309509,419.094513 672.610107,420.188751 672.941467,421.623657 
	C672.136108,421.076019 671.299927,420.187775 670.192383,419.140808 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M384.005859,596.895386 
	C383.360016,598.045044 382.714203,599.194641 382.012085,600.682739 
	C381.528076,601.027344 381.100311,601.033508 380.311401,600.698975 
	C379.092163,600.015808 378.234070,599.673401 377.375977,599.330994 
	C377.269958,599.931396 377.163940,600.531799 377.057922,601.132202 
	C377.057892,601.132202 377.059753,601.523560 376.649872,601.740173 
	C374.169373,601.665222 372.098755,601.373657 369.670227,601.056580 
	C368.534241,600.468811 367.756134,599.906616 366.978058,599.344360 
	C367.200958,599.033813 367.423859,598.723267 367.646790,598.412720 
	C368.695190,598.932129 369.743622,599.451538 369.804169,599.481567 
	C370.994904,597.668274 371.912994,596.270203 373.030518,594.568420 
	C369.949646,594.568420 367.269501,594.568420 365.124329,594.568420 
	C363.818726,592.349243 362.698059,590.444336 361.661713,588.288757 
	C361.859161,587.700134 361.972229,587.362244 362.450104,587.024536 
	C367.454437,587.077087 367.018707,584.887573 365.041138,581.685791 
	C365.142822,580.934937 365.232544,580.510071 365.666351,580.063416 
	C367.329529,579.682068 368.648560,579.322510 370.174866,579.232422 
	C371.257416,579.666260 372.132721,579.830688 373.120911,580.252258 
	C373.498138,581.003113 373.762451,581.496887 373.729614,581.994995 
	C372.889404,582.081299 372.346375,582.163208 371.028229,582.362000 
	C372.455292,583.149841 373.223480,583.573975 373.863342,584.283813 
	C373.566193,585.362061 372.991455,586.414124 373.302185,586.900208 
	C374.372650,588.574768 375.752411,590.051697 376.687897,591.202393 
	C378.305603,591.202393 379.959076,591.202393 381.994476,591.202393 
	C381.665802,588.452209 381.339691,585.723389 381.005737,582.997681 
	C380.997925,583.000793 380.998566,583.015137 381.294861,582.992920 
	C382.054840,582.645020 382.518463,582.319336 383.308411,581.951416 
	C384.073517,581.250549 384.512360,580.591919 384.965149,579.589844 
	C385.283295,578.383667 385.587433,577.520996 386.130707,575.979919 
	C386.896759,577.684937 387.352661,578.699646 387.887268,579.889587 
	C388.694336,578.995911 389.180847,578.457153 389.987183,577.564270 
	C389.987183,579.361389 389.987183,580.681946 389.744263,582.167053 
	C389.332123,582.880493 389.162933,583.429260 388.740173,584.201172 
	C385.111603,587.905334 385.573975,592.765137 384.005859,596.895386 
M369.887634,584.664612 
	C369.535950,584.917542 369.184296,585.170471 368.832642,585.423401 
	C369.172241,585.571655 369.511841,585.719910 369.851440,585.868164 
	C370.031067,585.451538 370.210693,585.034912 369.887634,584.664612 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M370.028137,601.082092 
	C372.098755,601.373657 374.169373,601.665222 376.646423,601.935791 
	C377.052856,601.914856 377.105896,602.354248 377.119537,602.573853 
	C374.484680,606.029114 371.865814,605.788147 369.311005,602.297180 
	C369.576752,601.605957 369.802429,601.343994 370.028137,601.082092 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M377.425659,601.072754 
	C377.163940,600.531799 377.269958,599.931396 377.375977,599.330994 
	C378.234070,599.673401 379.092163,600.015808 379.990417,600.684326 
	C379.284882,601.011475 378.539154,601.012451 377.425659,601.072754 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M337.087769,615.928894 
	C337.076538,615.951843 337.204712,615.827576 337.272217,615.771606 
	C337.339722,615.715698 337.215027,615.848572 337.144836,615.909180 
	C337.074646,615.969849 337.098999,615.905945 337.087769,615.928894 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M346.006531,463.929840 
	C345.030396,465.013184 344.054260,466.096527 342.632294,467.674652 
	C342.294006,466.033691 341.982910,464.524628 341.644318,462.882172 
	C341.200439,463.380646 340.811646,463.817261 340.448608,464.224945 
	C339.827484,462.941895 339.200134,461.645966 338.295227,460.169373 
	C338.336334,458.359894 338.867920,456.734863 338.897278,455.100800 
	C338.916199,454.046906 338.198456,452.979797 337.441010,451.461548 
	C336.927887,448.014008 337.203003,445.467377 341.130249,446.926636 
	C341.130249,440.513672 341.130249,434.037720 341.130249,427.874969 
	C342.963470,429.306213 345.069336,430.950317 347.183105,432.600555 
	C350.602692,427.404846 350.521210,424.841522 346.713715,421.284424 
	C346.306915,422.277191 345.932343,423.191223 345.172607,425.045258 
	C344.941498,417.934875 341.770660,420.649719 338.875610,421.709290 
	C337.758850,420.015137 335.318573,418.595123 338.907959,417.295502 
	C339.413727,417.112366 339.926788,415.831055 339.795532,415.189087 
	C338.960236,411.103943 337.945099,407.055603 336.989197,402.995117 
	C336.989197,402.995117 337.005768,403.024536 337.407410,403.032257 
	C339.577484,402.652161 341.345917,402.264313 343.849518,401.715271 
	C337.723877,398.922760 336.692352,391.563110 339.964417,387.596619 
	C340.198547,384.540802 340.648346,382.640778 340.367981,380.855438 
	C340.197021,379.767029 338.901581,378.855194 338.111237,377.864014 
	C338.029968,377.137939 337.948700,376.411896 337.789612,374.990631 
	C337.131470,376.328430 336.805298,376.991425 336.229797,377.570801 
	C335.965057,377.317230 335.949646,377.147308 335.934204,376.977386 
	C335.934204,375.337677 335.934204,373.697998 335.934204,372.058289 
	C336.368317,372.296906 336.802429,372.535492 337.236542,372.774109 
	C337.819977,371.901611 338.635223,371.102417 338.939026,370.141510 
	C339.588379,368.087433 339.972992,365.949707 340.775391,362.526703 
	C340.684113,361.197479 340.491913,358.398804 340.278992,355.298187 
	C339.128174,355.368774 337.791443,355.450745 336.296509,355.542450 
	C337.368927,353.758148 338.262085,352.272156 339.567566,350.414124 
	C341.921539,351.367371 343.863159,352.692566 345.993164,354.146362 
	C346.950348,350.006744 348.748779,348.577667 353.152588,350.776093 
	C355.036377,351.716492 358.213257,350.392578 360.687653,349.691864 
	C362.002960,349.319336 363.176025,346.658905 363.829102,350.292603 
	C363.051758,352.015869 362.496277,353.465240 361.555420,354.938995 
	C359.448517,356.038208 357.726929,357.113007 355.768677,358.335571 
	C355.846832,358.390594 355.267029,357.971649 354.667450,357.583221 
	C354.625671,357.556152 354.436737,357.756165 353.732544,358.307861 
	C354.380646,359.160156 355.333893,360.873047 355.654785,360.761810 
	C357.889862,359.987061 359.993805,358.834137 362.591553,357.585236 
	C362.694672,358.671722 363.045319,360.054016 362.908295,361.386139 
	C362.584259,364.536133 362.348297,367.755157 361.472412,370.770752 
	C360.666016,373.547150 361.803192,373.994812 364.019409,374.286255 
	C364.110321,375.123688 364.196808,375.659515 364.283295,376.195374 
	C364.504059,376.138519 364.724854,376.081696 364.945648,376.024841 
	C364.625824,375.352875 364.306000,374.680878 364.001526,373.658936 
	C364.383209,368.564301 364.749573,363.819580 365.341553,359.051605 
	C365.567139,359.028320 366.018066,358.979462 366.413605,358.974518 
	C369.406586,357.976440 373.689148,359.099609 373.350098,354.155945 
	C374.718811,355.790955 375.767334,357.286346 377.061798,359.132507 
	C378.660217,356.877655 379.371216,355.874725 380.432343,354.910767 
	C386.898987,353.832001 382.588104,348.587067 384.698120,345.333466 
	C385.730377,345.825684 386.817505,346.344055 387.972473,346.894775 
	C388.705658,344.641144 389.448242,342.358734 389.971802,340.749451 
	C388.330200,340.522644 387.081207,340.350098 385.293762,340.103180 
	C384.704224,340.902313 383.358215,342.346100 382.492249,344.035919 
	C381.925934,345.141052 381.425049,347.070679 381.983429,347.822479 
	C384.235413,350.854523 382.285522,351.914917 379.754883,352.841858 
	C377.676453,351.084778 375.876068,349.524750 374.128540,347.612427 
	C375.806824,346.514008 377.432251,345.767853 379.329132,344.766052 
	C379.736481,343.004059 379.872345,341.497681 380.004578,339.995178 
	C380.000946,339.999023 379.992279,340.007843 379.992279,340.007843 
	C380.594116,338.736786 381.195953,337.465729 382.056610,335.648102 
	C382.919952,335.320404 384.608032,334.679718 386.664459,334.277161 
	C389.841583,334.259613 392.324188,334.564941 392.977356,338.251648 
	C392.944031,338.699127 392.930054,338.878082 392.673584,339.153931 
	C392.192230,339.703094 391.953430,340.155334 391.714600,340.607605 
	C392.111938,340.725891 392.509277,340.844177 392.927856,341.393555 
	C392.966766,343.551392 392.984467,345.278137 392.955994,347.387054 
	C390.028687,352.360626 394.023315,354.872467 395.734589,358.206665 
	C394.644653,361.279572 393.822327,364.139771 392.834961,366.757660 
	C392.071564,366.421356 391.473236,366.327393 390.874878,366.233398 
	C390.851868,366.446106 390.828827,366.658813 390.805817,366.871521 
	C391.537201,366.914337 392.268616,366.957184 393.003418,367.316803 
	C394.154358,368.978699 394.217010,368.963226 395.191376,367.635071 
	C397.127960,369.048462 399.047211,370.449188 400.994476,371.870361 
	C402.467194,368.611176 403.735443,365.804474 405.371216,362.998352 
	C406.473785,362.990906 407.208771,362.982880 407.924561,363.330200 
	C407.088379,365.219879 406.271393,366.754242 405.282837,368.610809 
	C406.575623,368.743500 407.716400,368.860565 408.876160,368.979584 
	C408.613770,370.584198 408.389191,371.957703 408.079590,373.851257 
	C409.638245,373.530945 410.712097,373.310303 412.023071,373.040894 
	C412.023071,374.054535 412.023071,375.016418 411.692505,375.984436 
	C410.921661,375.993286 410.481354,375.996033 409.727051,376.128906 
	C407.689728,377.687012 405.966370,379.114960 403.782227,380.924744 
	C410.127075,380.977509 411.202545,381.980621 411.367706,387.349701 
	C408.384369,383.290192 404.550232,383.456696 398.631561,385.659210 
	C400.346893,381.240356 401.579498,378.065125 402.764313,375.012970 
	C398.882874,374.035248 397.044098,375.888367 395.959320,378.643005 
	C394.972168,378.307648 393.968140,378.095337 393.135254,378.381348 
	C392.327881,378.658600 391.696381,379.448029 390.981873,379.624695 
	C391.951996,377.405121 392.927979,375.573425 394.063507,373.442169 
	C393.213104,373.145355 391.612152,372.586548 390.119629,371.748962 
	C390.285309,370.889801 390.342560,370.309418 390.399780,369.729065 
	C389.054138,370.375519 387.708496,371.022003 386.362854,371.668488 
	C386.551758,372.086334 386.740631,372.504181 386.929535,372.922028 
	C387.962372,372.618378 388.995209,372.314728 390.037659,372.403198 
	C389.501373,374.947083 388.955475,377.098907 388.337372,379.535156 
	C389.212646,379.682983 390.112793,379.835052 390.864899,380.297119 
	C389.555756,382.171539 388.082642,383.601837 387.334076,385.343536 
	C386.754852,386.691162 387.082703,388.428619 386.986572,390.376160 
	C386.660400,392.493317 386.354980,394.227051 385.997681,396.279419 
	C382.591766,396.454865 384.616394,392.610626 382.511902,391.607635 
	C387.066162,388.653748 383.073853,386.581970 381.800873,384.173004 
	C381.363525,383.345398 380.828766,381.721466 381.141541,381.473175 
	C385.433594,378.066895 380.259064,373.980316 382.048950,370.430389 
	C381.026642,371.466980 381.165894,372.725708 380.746094,373.756805 
	C379.689270,376.352417 378.380219,378.845306 377.172241,381.379395 
	C375.114441,379.914642 373.056671,378.449890 371.359741,376.989655 
	C376.911743,376.125549 376.911377,376.125641 375.934296,371.647125 
	C375.899231,371.486389 375.912598,371.311096 375.857208,371.159485 
	C375.291138,369.610352 374.715546,368.064636 374.142578,366.518005 
	C373.624817,366.657410 373.107025,366.796844 372.589233,366.936249 
	C372.904724,369.304596 373.220215,371.672943 373.521790,373.936920 
	C372.737000,374.896088 371.875946,375.948456 370.693512,376.978180 
	C369.454132,376.997772 368.228485,376.679047 367.675995,377.150055 
	C366.040894,378.543793 366.852539,381.565338 366.492340,382.164368 
	C363.359314,387.374390 367.906006,388.397888 370.156525,390.850037 
	C376.633514,382.759857 374.850922,383.484497 380.697083,390.921783 
	C378.357941,392.284027 376.013947,393.649109 374.409698,394.583405 
	C376.967377,396.659149 378.845551,397.954010 380.349365,399.592438 
	C380.659882,399.930786 379.576447,401.548523 378.862274,403.213531 
	C378.098267,401.303650 377.709625,400.332092 377.164703,398.969910 
	C375.212738,401.247833 373.593018,403.138000 371.700287,405.253113 
	C370.474579,406.315399 369.536682,407.170319 368.564758,407.984711 
	C367.725616,408.687897 366.847839,409.345001 365.673889,410.143799 
	C361.428040,409.432739 361.661743,405.674683 360.541779,402.831329 
	C359.903320,403.730164 359.449829,404.368591 358.759277,404.821594 
	C358.222168,404.077332 357.922119,403.518524 357.779480,403.252899 
	C356.829132,404.360535 355.778076,405.585541 354.364014,407.233612 
	C354.364014,408.434265 354.364014,410.486298 354.364014,412.538361 
	C354.120178,412.558990 353.876343,412.579590 353.632507,412.600220 
	C353.252838,411.751190 352.873199,410.902161 352.493561,410.053162 
	C352.257507,410.138580 352.021454,410.223999 351.785400,410.309418 
	C352.124115,412.061157 352.462860,413.812897 352.979828,416.486359 
	C355.186005,414.485474 356.583679,413.217804 358.284454,412.109558 
	C358.741760,413.658081 358.895966,415.047211 359.050201,416.436340 
	C358.602051,416.748383 358.153900,417.060425 357.705719,417.372467 
	C359.661987,417.921448 361.618256,418.470459 364.213074,419.198639 
	C363.357574,422.712433 362.446503,426.454498 361.535431,430.196564 
	C361.020874,430.095215 360.506317,429.993835 359.991760,429.892487 
	C359.991760,428.591217 359.991760,427.289978 360.242676,426.085144 
	C360.885254,426.160217 361.276917,426.138885 361.668549,426.117584 
	C361.148468,425.708527 360.628387,425.299469 360.091309,424.539978 
	C359.886169,420.744354 357.051361,420.122101 354.585541,419.630829 
	C354.148773,419.543762 353.120972,422.423187 352.374390,423.916138 
	C352.140869,423.091217 351.847626,422.055298 351.554382,421.019379 
	C351.271423,421.083618 350.988434,421.147827 350.705475,421.212067 
	C351.131500,423.867310 351.557526,426.522552 351.983551,429.177826 
	C352.315521,429.186615 352.647461,429.195435 352.979431,429.204254 
	C353.521576,427.287811 354.063751,425.371368 354.605896,423.454926 
	C354.268372,429.013062 361.961365,433.157867 357.543945,439.757965 
	C357.599274,439.845184 357.968994,440.378265 358.288483,440.939911 
	C359.735016,443.482971 360.868835,447.226349 363.122894,441.220886 
	C363.688263,439.714478 366.317383,438.982666 368.272705,437.717133 
	C368.834839,439.809875 369.175140,441.076813 369.515442,442.343750 
	C369.864258,442.268707 370.213043,442.193695 370.561859,442.118683 
	C370.427460,439.206909 370.293030,436.295135 370.158600,433.383362 
	C369.679169,433.395630 369.199738,433.407928 368.720306,433.420197 
	C368.229797,434.496857 367.739258,435.573517 366.933441,437.342285 
	C366.276733,435.900085 366.081573,435.104645 365.619446,434.520264 
	C363.665527,432.049408 360.785461,429.513916 366.876129,428.700348 
	C367.435577,428.625641 368.242676,427.124725 368.195129,426.330322 
	C367.928528,421.875031 367.434814,417.433319 367.333557,412.854248 
	C368.737854,412.469696 369.827271,412.217896 371.148682,412.242676 
	C372.906433,412.000580 374.432220,411.481934 376.466766,410.790314 
	C376.705017,411.001404 377.771484,411.946350 378.935791,412.977936 
	C380.762238,411.243713 382.334412,409.750885 384.711792,407.493500 
	C384.298676,409.917938 384.129425,411.835571 383.627777,413.661926 
	C382.597076,417.414215 379.320343,420.997101 383.022583,425.316284 
	C383.496613,426.207581 383.946838,426.783783 384.397064,427.360016 
	C384.642456,427.065704 384.887848,426.771393 385.133240,426.477081 
	C384.422577,425.984314 383.711884,425.491547 383.004944,424.656982 
	C384.105347,423.680359 385.201965,423.045502 386.298615,422.410645 
	C386.865723,423.607117 387.432861,424.803558 387.621765,426.075287 
	C386.854218,428.128723 386.464874,430.106842 385.806458,432.244629 
	C382.693512,435.983185 383.355591,431.068878 382.021667,430.656586 
	C381.668304,429.822449 381.327942,429.290100 380.987549,428.757751 
	C380.982269,429.503540 380.976990,430.249329 380.988647,431.418518 
	C381.004059,438.590790 380.738708,439.177856 378.104980,438.347809 
	C377.329681,441.741608 379.361664,442.189667 381.986084,442.389587 
	C381.655487,444.513611 381.323578,446.255402 380.725647,448.103821 
	C379.849701,450.307770 379.239838,452.405090 378.554565,454.761688 
	C377.252106,454.302094 375.886230,453.820129 374.373474,453.286316 
	C374.603943,458.332275 374.630402,458.350616 379.089722,457.393799 
	C378.989594,459.153198 378.911621,460.506317 378.481750,461.886810 
	C376.124756,460.750336 374.119629,459.586487 372.223206,458.485748 
	C371.983093,459.007507 371.442200,460.183014 370.499695,462.231171 
	C370.054138,459.200104 369.775146,457.302002 369.496124,455.403900 
	C369.161194,455.436493 368.826263,455.469086 368.491364,455.501678 
	C367.661560,458.004822 366.831787,460.507935 365.840607,462.769714 
	C365.025970,462.383484 364.372711,462.238556 362.431305,461.807922 
	C363.954376,463.111603 364.474060,463.556427 364.775085,464.284973 
	C363.381409,464.738800 362.206299,464.908905 361.025452,464.808258 
	C361.014435,464.357056 361.009186,464.176575 361.001953,463.998047 
	C361.000000,464.000000 360.997528,464.003632 361.263123,463.798401 
	C360.961761,462.239929 360.394836,460.886688 359.852844,459.592896 
	C360.992767,459.592896 362.235504,459.592896 363.515808,459.592896 
	C363.515808,457.692749 363.515808,456.022156 363.515808,454.316559 
	C361.214905,454.473633 359.381073,454.598785 357.547241,454.723969 
	C357.384735,454.127380 357.222198,453.530823 357.059692,452.934265 
	C359.120087,452.287811 361.199158,451.694244 363.233612,450.974487 
	C364.396149,450.563232 365.509521,449.985870 366.566956,449.352936 
	C366.658173,449.298340 366.254333,447.831451 365.922974,447.760956 
	C364.713898,447.503754 363.431763,447.452087 362.188751,447.523834 
	C360.188049,447.639221 358.196533,447.914154 356.402954,448.100708 
	C355.357605,450.786652 354.174988,453.825195 352.992401,456.863708 
	C352.492065,456.682831 351.991699,456.501953 351.491333,456.321075 
	C352.061157,454.120117 352.630981,451.919159 353.294495,449.356323 
	C351.442261,450.645630 349.883453,451.730682 348.604553,452.620911 
	C350.734344,457.337982 347.743713,460.493408 346.006531,463.929840 
M347.435974,393.408691 
	C345.680389,395.753723 345.789581,397.682068 347.934753,399.988403 
	C348.730347,400.843842 348.320251,403.436523 347.643036,404.843781 
	C347.135559,405.898285 345.320160,406.323334 344.087097,407.028656 
	C344.270660,407.431305 344.454193,407.833954 344.637756,408.236603 
	C347.300995,408.068329 349.964264,407.900055 352.899689,407.714600 
	C353.864899,398.665710 354.783356,390.055389 355.701813,381.445068 
	C355.243011,381.298828 354.784180,381.152557 354.325378,381.006287 
	C352.612244,386.163086 350.899109,391.319885 349.185974,396.476685 
	C348.895782,396.414154 348.605591,396.351624 348.315369,396.289093 
	C348.209595,395.192322 348.103821,394.095551 348.275757,392.892212 
	C348.184265,392.928864 348.092743,392.965485 347.435974,393.408691 
M369.525665,421.345154 
	C370.259247,423.146912 370.992828,424.948639 371.726440,426.750397 
	C371.377380,426.918152 371.028351,427.085876 370.679321,427.253632 
	C372.972961,428.346924 375.266571,429.440216 377.987854,430.737366 
	C377.987854,425.535492 377.987854,420.534576 377.987854,415.257660 
	C374.483032,415.498352 371.146667,416.509369 369.525665,421.345154 
M359.583740,379.373260 
	C356.219696,384.535461 356.486023,387.410370 361.425903,393.021912 
	C362.547150,388.656494 359.563843,384.194733 362.784180,380.816620 
	C361.960510,380.316010 361.136841,379.815399 359.583740,379.373260 
M346.356079,357.814941 
	C346.146729,360.891235 345.528625,364.129761 349.435028,365.572540 
	C353.256226,360.261139 353.336975,359.032257 349.693481,355.895966 
	C349.559753,357.296234 349.452332,358.420868 349.269714,360.332672 
	C348.142059,358.987213 347.473206,358.189178 346.356079,357.814941 
M363.987823,402.668640 
	C364.126282,403.085785 364.264740,403.502899 364.403198,403.920013 
	C364.545044,403.832977 364.792877,403.765656 364.813263,403.656036 
	C365.388824,400.560791 365.941437,397.461304 366.497772,394.362488 
	C366.007294,394.277924 365.516785,394.193359 365.026306,394.108765 
	C364.673309,396.715759 364.320312,399.322754 363.987823,402.668640 
M359.362701,394.453125 
	C358.717285,394.940918 358.071838,395.428680 357.426392,395.916443 
	C357.733429,396.207642 358.040436,396.498810 358.347473,396.790009 
	C358.819275,396.178833 359.291077,395.567657 359.362701,394.453125 
M363.529846,389.175690 
	C363.677795,389.655090 363.825775,390.134521 363.973724,390.613922 
	C364.276001,390.340729 364.578308,390.067566 364.880585,389.794373 
	C364.565521,389.454590 364.250458,389.114807 363.529846,389.175690 
M404.377808,371.766602 
	C404.901398,372.119507 405.425018,372.472382 405.948608,372.825256 
	C406.050507,372.575378 406.273407,372.128571 406.235962,372.105560 
	C405.763702,371.815460 405.253845,371.586548 404.377808,371.766602 
M343.512848,434.415314 
	C343.816101,434.131805 344.119354,433.848297 344.422607,433.564789 
	C344.312683,433.458069 344.118317,433.248810 344.105194,433.259583 
	C343.801514,433.509827 343.517517,433.783997 343.512848,434.415314 
M373.477386,451.371552 
	C373.528778,451.161407 373.580170,450.951233 373.631592,450.741089 
	C373.507721,450.874298 373.383820,451.007538 373.477386,451.371552 
M370.490265,372.593048 
	C370.490265,372.593048 370.421997,372.479004 370.490265,372.593048 
M366.598511,441.511292 
	C366.598511,441.511292 366.470947,441.593414 366.598511,441.511292 
M367.514130,392.396362 
	C367.514130,392.396362 367.593262,392.521454 367.514130,392.396362 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M339.034271,421.998138 
	C341.770660,420.649719 344.941498,417.934875 345.172607,425.045258 
	C345.932343,423.191223 346.306915,422.277191 346.713715,421.284424 
	C350.521210,424.841522 350.602692,427.404846 347.183105,432.600555 
	C345.069336,430.950317 342.963470,429.306213 341.130249,427.874969 
	C341.130249,434.037720 341.130249,440.513672 341.130249,446.926636 
	C337.203003,445.467377 336.927887,448.014008 337.106079,451.363861 
	C336.112274,451.099945 334.267792,450.568817 334.185303,449.838623 
	C333.582397,444.502502 333.333160,439.126434 333.283630,433.386780 
	C334.050018,433.023438 334.505096,433.036316 335.024109,433.195892 
	C335.190460,433.387939 335.292847,433.433289 335.395264,433.478668 
	C335.277344,433.307892 335.159424,433.137085 335.039246,432.582001 
	C336.369415,428.797852 337.701843,425.397980 339.034271,421.998138 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M338.875610,421.709290 
	C337.701843,425.397980 336.369415,428.797852 335.017578,432.601410 
	C334.998169,433.005127 334.960144,433.049164 334.960144,433.049194 
	C334.505096,433.036316 334.050018,433.023438 333.252167,432.992004 
	C329.864014,428.821899 334.091980,426.225372 335.072021,422.938385 
	C335.337830,422.046906 335.773804,421.206146 336.398529,419.697906 
	C334.115875,420.369415 332.404755,420.872772 331.088135,421.260071 
	C331.419403,417.102936 331.318207,413.012085 332.216278,409.153839 
	C332.738281,406.911224 334.863922,405.041901 336.628571,402.999847 
	C337.945099,407.055603 338.960236,411.103943 339.795532,415.189087 
	C339.926788,415.831055 339.413727,417.112366 338.907959,417.295502 
	C335.318573,418.595123 337.758850,420.015137 338.875610,421.709290 
M334.585999,416.212769 
	C334.281769,415.093109 333.977539,413.973450 333.673309,412.853790 
	C333.363495,412.959106 333.053680,413.064392 332.743866,413.169678 
	C333.194611,414.314117 333.645386,415.458557 334.585999,416.212769 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M338.039948,378.241577 
	C338.901581,378.855194 340.197021,379.767029 340.367981,380.855438 
	C340.648346,382.640778 340.198547,384.540802 339.964417,387.596619 
	C336.692352,391.563110 337.723877,398.922760 343.849518,401.715271 
	C341.345917,402.264313 339.577484,402.652161 337.423676,403.036804 
	C336.689941,401.618408 336.406006,400.181000 335.934235,398.808167 
	C335.848755,398.559357 334.993622,398.575073 335.718140,398.729309 
	C333.297638,394.765503 331.165253,391.273468 329.046448,387.409424 
	C330.151764,387.236237 331.243408,387.435028 331.554321,387.491669 
	C333.886078,384.266296 335.927368,381.442749 338.039948,378.241577 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M336.576843,461.922058 
	C335.511169,464.155548 334.060699,466.362762 332.339264,468.751526 
	C331.903442,465.685181 331.803284,462.475281 336.576843,461.922058 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M331.400208,403.715546 
	C331.559052,403.842346 331.581421,404.064484 331.603760,404.286591 
	C331.490417,404.128021 331.377106,403.969421 331.400208,403.715546 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M337.763062,461.053894 
	C337.807831,461.225891 337.615814,461.497742 337.193573,461.874695 
	C337.151001,461.704468 337.338623,461.429108 337.763062,461.053894 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M336.442596,454.557770 
	C336.402069,454.491974 336.483124,454.623566 336.442596,454.557770 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M364.050934,350.018707 
	C363.176025,346.658905 362.002960,349.319336 360.687653,349.691864 
	C358.213257,350.392578 355.036377,351.716492 353.152588,350.776093 
	C348.748779,348.577667 346.950348,350.006744 345.993164,354.146362 
	C343.863159,352.692566 341.921539,351.367371 339.625854,350.054596 
	C338.929138,348.702728 337.850128,346.619812 338.364929,346.092285 
	C341.389069,342.993317 339.134888,340.797424 337.236633,338.200928 
	C336.946594,337.555267 336.940155,337.114685 336.965515,336.124634 
	C336.997253,335.575165 337.044708,335.128784 337.044708,335.128784 
	C337.663055,334.892639 338.281372,334.656464 339.492737,334.174957 
	C343.432770,333.406677 346.779755,332.883759 350.385895,332.320343 
	C345.878510,328.032745 349.162933,324.010223 349.997650,320.001221 
	C352.070404,319.334778 354.143188,318.668335 356.215942,318.001892 
	C356.243988,317.462830 356.272064,316.923737 356.300110,316.384674 
	C356.530121,317.027649 356.760132,317.670654 356.996887,318.661682 
	C357.003662,319.009766 357.010529,319.499084 356.839172,319.836456 
	C356.801941,320.439453 356.936066,320.705048 357.070160,320.970673 
	C357.377014,321.083618 357.622070,321.275452 357.766083,322.082977 
	C356.917511,324.032379 356.108246,325.444946 355.298981,326.857544 
	C355.388336,327.102081 355.477661,327.346619 355.567017,327.591125 
	C356.760773,326.701538 357.954529,325.811920 359.148285,324.922302 
	C359.562195,324.943756 359.976074,324.965210 360.732605,325.322693 
	C361.718994,326.108246 362.362732,326.557709 363.006500,327.007172 
	C362.694427,328.100861 362.382355,329.194550 362.002869,330.927582 
	C360.390533,332.271088 358.845581,332.975250 358.137543,333.297943 
	C360.478302,335.808777 362.481689,338.041138 364.583679,340.176392 
	C365.595703,341.204468 366.793671,342.049530 368.109528,343.225708 
	C369.495453,343.940338 370.679260,344.406769 371.933899,344.935944 
	C372.004730,344.998657 372.150635,345.119049 372.134460,345.518219 
	C371.966919,351.080109 367.100037,348.130920 364.799896,350.020203 
	C364.550293,350.013916 364.050934,350.018707 364.050934,350.018707 
M359.481201,328.590668 
	C359.481201,328.590668 359.408844,328.472290 359.481201,328.590668 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M331.005859,348.391266 
	C332.145325,345.497772 333.285004,342.988434 334.710144,340.267242 
	C335.739410,340.999878 336.483215,341.944366 337.564972,343.317963 
	C331.992035,346.569977 332.856384,352.016968 334.484985,357.975128 
	C329.007446,355.510071 331.255157,351.795349 331.005859,348.391266 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M326.407715,357.449005 
	C325.936432,357.388794 325.649658,357.153351 325.362915,356.917908 
	C325.550262,356.737335 325.737579,356.556793 325.924927,356.376221 
	C326.147369,356.675415 326.369812,356.974609 326.407715,357.449005 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M335.685669,376.999268 
	C335.949646,377.147308 335.965057,377.317230 335.987793,377.742981 
	C335.555573,377.844269 335.115997,377.689728 334.676453,377.535217 
	C334.930023,377.363861 335.183594,377.192505 335.685669,376.999268 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M349.998840,320.000610 
	C349.162933,324.010223 345.878510,328.032745 350.385895,332.320343 
	C346.779755,332.883759 343.432770,333.406677 339.708069,333.912598 
	C339.314331,333.466095 339.298279,333.036591 339.604065,332.252594 
	C340.312500,329.973206 340.699158,328.048340 341.085785,326.123474 
	C341.371338,322.047577 341.656891,317.971710 341.915710,313.488525 
	C342.385681,312.726166 342.882385,312.371124 343.692932,312.007141 
	C344.006805,311.998199 344.028717,311.966431 344.028687,311.966431 
	C344.319611,314.397827 344.610535,316.829254 344.905884,319.938538 
	C344.705505,321.469818 344.500702,322.323212 344.155670,323.761169 
	C346.474884,322.268768 348.236816,321.135010 349.998779,320.001221 
	C349.998779,320.001221 350.000000,320.000000 349.998840,320.000610 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M340.704163,326.095398 
	C340.699158,328.048340 340.312500,329.973206 339.672546,331.934082 
	C339.189331,331.976349 338.959381,331.982635 338.386353,332.023987 
	C337.072632,329.403564 337.101135,327.128143 340.704163,326.095398 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M335.001221,314.999237 
	C335.783569,316.585876 336.564697,318.173279 337.345825,319.760681 
	C336.889954,319.928345 336.434052,320.096008 335.978180,320.263672 
	C335.653442,318.760559 335.328674,317.257416 335.000732,315.378357 
	C334.997559,315.002441 335.000000,315.000000 335.001221,314.999237 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M324.136444,296.956696 
	C323.872070,296.541016 323.908142,296.119080 323.929199,295.380219 
	C324.053131,294.654388 324.192078,294.245514 324.331024,293.836639 
	C324.576141,293.873291 324.821289,293.909912 325.066406,293.946533 
	C324.856567,294.947845 324.646759,295.949158 324.136444,296.956696 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M336.928101,334.944214 
	C337.044708,335.128784 336.997253,335.575165 336.948303,335.794495 
	C336.655273,335.679718 336.411224,335.345642 336.167175,335.011566 
	C336.381927,334.927582 336.596710,334.843597 336.928101,334.944214 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M393.002136,347.004852 
	C392.984467,345.278137 392.966766,343.551392 392.948608,341.076355 
	C392.937469,339.904358 392.926788,339.480682 392.916107,339.057037 
	C392.930054,338.878082 392.944031,338.699127 393.336121,338.300476 
	C394.137787,339.886597 394.561340,341.692474 395.179321,344.327209 
	C396.300049,340.447845 398.049316,338.519104 401.719604,342.156433 
	C401.829956,341.544525 401.940338,340.932648 402.050690,340.320740 
	C400.272095,339.219666 398.493469,338.118591 396.304474,336.763458 
	C396.493774,334.918671 396.746033,332.460541 397.054565,330.361450 
	C399.022095,332.767944 400.933289,334.815460 403.898987,337.992615 
	C403.368530,334.240173 403.030243,331.847137 402.692108,329.455109 
	C400.573639,329.657104 398.788055,329.827332 397.168152,329.683777 
	C398.263977,326.892487 399.194061,324.414978 400.459137,321.991760 
	C401.863403,323.019989 402.932678,323.993896 403.918762,325.219696 
	C403.862793,325.859039 403.890076,326.246552 403.917358,326.634033 
	C404.281708,326.087036 404.646027,325.540039 405.360565,324.995239 
	C407.115417,324.698212 408.520050,324.398926 409.924683,324.099640 
	C409.718414,322.036987 409.512146,319.974365 409.299011,317.842865 
	C412.508820,317.314087 413.473755,318.875366 412.923065,324.080933 
	C412.653931,326.625061 412.907928,329.306061 413.465424,331.804535 
	C413.624603,332.517914 415.676147,332.809021 416.856995,333.292542 
	C419.449829,325.862122 418.967896,324.422485 414.375153,325.210480 
	C414.745789,321.908813 414.745789,321.908813 421.551025,324.567688 
	C421.171387,326.267578 417.886688,329.619843 423.800232,328.825714 
	C422.432465,330.134552 421.366455,331.154602 419.879089,332.577911 
	C424.136475,332.053345 427.833008,330.860687 431.255707,331.401428 
	C434.176300,331.862793 436.304382,332.365509 438.980896,330.436401 
	C440.643768,329.237885 443.103912,328.899689 441.407501,326.469696 
	C440.788086,325.582397 438.280090,326.013519 436.584473,326.201935 
	C434.039673,326.070557 437.286713,320.804688 432.375427,322.005432 
	C432.000000,322.000000 431.990509,321.990448 431.990509,321.990448 
	C429.244110,318.740173 434.640045,319.900085 434.205078,317.901764 
	C435.431671,318.017914 436.712128,317.961700 437.862579,318.321320 
	C438.457397,318.507233 438.821503,319.431183 439.289856,320.021698 
	C439.803558,319.762177 440.317291,319.502686 440.831024,319.243164 
	C439.462891,317.025299 438.094757,314.807434 437.249268,313.436829 
	C439.421814,312.138062 442.431671,311.227844 442.512543,310.106476 
	C442.684174,307.725800 445.152710,304.383179 441.068848,302.627411 
	C440.533234,302.397125 440.341644,301.366669 439.995972,300.353577 
	C440.787720,299.681427 441.574219,299.362549 442.679749,299.022461 
	C443.767181,299.664612 444.535553,300.328003 445.659546,301.369324 
	C446.018219,302.491852 446.021332,303.236420 445.689178,304.035858 
	C445.049103,304.791351 444.744324,305.491974 444.439514,306.192627 
	C444.806305,306.320404 445.173096,306.448212 445.539886,306.575989 
	C445.698975,305.709167 445.858063,304.842316 446.309143,304.096802 
	C447.380890,304.502899 448.160675,304.787628 449.013000,305.215851 
	C449.192017,305.400970 449.298401,305.442627 449.404816,305.484283 
	C449.292511,305.304138 449.180237,305.124023 449.192902,304.643982 
	C449.871216,303.223511 450.424561,302.102966 450.977936,300.982422 
	C452.318512,303.390381 453.659088,305.798340 454.999664,308.206299 
	C454.555054,308.565491 454.110474,308.924652 453.665863,309.283813 
	C452.717468,309.076385 451.769043,308.868958 450.820618,308.663330 
	C450.337891,312.547089 452.187866,315.347107 455.249908,314.258545 
	C457.235809,313.794678 458.685028,313.629059 460.184784,313.457703 
	C461.015137,314.553650 461.897644,315.718445 462.780151,316.883240 
	C463.299011,316.534729 463.817841,316.186188 464.336670,315.837646 
	C462.638153,313.215668 460.939636,310.593689 458.857880,307.380066 
	C457.495575,309.584869 456.748993,310.793152 456.002441,312.001465 
	C456.173706,304.531281 457.479492,303.771729 464.590729,303.508575 
	C467.654724,303.395172 470.628021,300.832336 473.873138,299.271332 
	C474.407227,300.269867 475.219269,301.788086 476.012238,303.971985 
	C475.665924,305.116364 475.338745,305.595093 474.757263,305.855011 
	C473.667969,304.934174 472.815887,303.668579 472.002716,303.693115 
	C471.233704,303.716309 470.438232,305.041168 469.771301,305.885864 
	C467.054993,309.326233 471.319641,312.604126 469.991486,316.353333 
	C469.294373,317.783844 468.654785,318.887390 467.737183,320.007996 
	C466.291809,320.033661 465.124420,320.042267 463.641418,320.074463 
	C462.536957,320.521118 461.748108,320.944244 460.756561,321.476074 
	C460.202972,319.539276 459.745667,317.939240 459.288361,316.339203 
	C458.889801,316.308685 458.491241,316.278137 458.092682,316.247620 
	C457.488556,318.346588 456.884460,320.445557 455.942902,323.717010 
	C455.248383,321.258545 454.890228,319.990845 454.595001,318.945740 
	C452.112732,319.627014 449.823700,320.255249 447.515900,320.888641 
	C452.272003,323.957794 454.598450,335.041687 462.727539,326.056244 
	C464.197113,327.058136 465.566864,327.992004 466.921631,329.280884 
	C466.610077,330.772400 466.313599,331.908875 465.955963,333.369446 
	C464.597351,333.772400 463.299896,333.851318 461.768311,333.690735 
	C460.704071,333.869659 459.782501,334.172455 459.068634,334.737640 
	C458.288025,335.355621 457.699432,336.216187 457.008850,336.746857 
	C456.990540,336.522675 456.943909,336.075256 456.984558,335.804413 
	C457.012146,335.352020 456.999084,335.170441 457.130432,334.950073 
	C457.186432,334.945038 457.097992,334.978790 456.704346,334.892944 
	C455.586761,335.505493 454.774384,336.237610 453.628296,336.960754 
	C451.558472,336.592987 449.822357,336.234222 448.017303,335.597809 
	C447.257812,335.479645 446.567291,335.639069 445.576385,335.813538 
	C444.875427,335.837769 444.474854,335.846985 444.047455,335.547943 
	C443.967987,334.913391 443.968903,334.322174 443.854919,334.299133 
	C439.460327,333.410553 434.207489,337.473206 433.764496,342.253082 
	C430.662628,345.126434 427.722229,347.681824 424.947266,350.093414 
	C424.947266,351.549316 424.947266,353.276855 424.541077,355.009277 
	C423.006836,354.861298 420.968048,354.899078 420.890076,354.526550 
	C420.006653,350.304993 416.469910,352.127472 413.241211,350.306335 
	C414.530426,352.599884 415.931396,354.040802 415.627380,354.787903 
	C415.120667,356.033020 412.885590,357.884674 412.490082,357.645142 
	C410.461823,356.416870 408.788666,354.602112 407.278198,352.946289 
	C407.875000,352.505798 408.184998,352.111328 408.494995,351.716827 
	C408.235260,351.630432 407.776123,351.426270 407.746735,351.475983 
	C407.459412,351.962494 407.243683,352.491302 406.753845,352.896942 
	C406.009125,352.534454 405.518127,352.283112 405.015930,351.702454 
	C404.991028,350.935303 404.977325,350.497467 405.304749,349.999634 
	C410.402191,345.934387 410.402191,345.934387 407.910370,343.343719 
	C406.917175,344.443420 405.927551,345.539124 404.477356,347.144836 
	C404.477356,344.411957 404.477356,342.484406 404.477356,340.556854 
	C404.222443,340.525146 403.967529,340.493439 403.712616,340.461731 
	C403.165710,341.931000 402.618835,343.400269 401.891144,344.985413 
	C401.632965,345.218933 401.463745,345.400330 401.493713,345.443542 
	C401.645569,345.662445 401.849152,345.845490 402.021240,346.286743 
	C402.007904,346.532410 401.978729,347.023651 401.709106,347.206238 
	C400.954010,348.598999 400.468597,349.809235 399.590637,351.003662 
	C397.656586,351.165222 396.115021,351.342560 394.664246,351.509491 
	C394.066742,349.890137 393.534454,348.447510 393.002136,347.004852 
M447.482635,330.541321 
	C447.517120,330.386047 447.599823,330.221283 447.571625,330.078369 
	C447.547058,329.953644 447.365326,329.742462 447.308075,329.759094 
	C446.292145,330.053894 445.284637,330.377747 444.275330,330.695312 
	C444.377991,331.036072 444.480621,331.376831 444.583282,331.717560 
	C445.398590,331.450867 446.213928,331.184143 447.482635,330.541321 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M413.721619,286.214844 
	C414.167664,286.884460 414.334137,287.738831 414.786255,288.763794 
	C415.494324,288.926849 415.916718,288.919312 416.711639,289.319305 
	C417.442566,293.586700 417.801025,297.446533 418.231201,302.078857 
	C419.770081,301.083344 421.397186,300.030731 423.027527,299.316833 
	C423.351501,300.439850 423.672211,301.224213 423.998199,302.376312 
	C424.334961,304.156403 424.666473,305.568756 424.997986,306.981079 
	C423.958893,306.142975 422.919769,305.304840 421.774078,304.380737 
	C421.863647,306.255310 421.948792,308.037628 422.087067,310.932098 
	C420.308441,309.598846 419.084381,308.790161 417.993927,307.829834 
	C417.107300,307.048981 416.413818,306.049103 415.530212,305.263916 
	C415.445038,305.188232 414.155212,306.070374 414.215668,306.294434 
	C414.556366,307.557312 415.094330,308.776428 415.665009,309.962402 
	C415.849304,310.345398 416.336395,310.582672 416.731171,310.926941 
	C416.018280,312.335510 415.359955,313.636322 415.087830,314.174011 
	C413.036469,314.174011 411.408020,314.174011 408.433807,314.174011 
	C409.913025,312.670990 410.988220,311.578522 412.616394,309.924133 
	C412.565094,309.787506 412.133240,308.637970 411.701416,307.488403 
	C411.257538,307.167725 410.813629,306.847076 410.369751,306.526398 
	C410.105255,307.799500 409.840790,309.072601 409.576294,310.345703 
	C407.794983,305.247589 410.141266,302.170593 414.000763,303.139038 
	C413.434784,300.184174 412.907227,297.429993 412.308807,294.305786 
	C411.609497,294.927002 410.975159,295.490479 409.630524,296.684906 
	C410.388672,294.463654 410.877319,293.031982 411.149963,292.233154 
	C409.640167,290.960815 408.323944,289.851624 407.001892,288.353729 
	C409.268646,287.953827 412.068939,290.115112 413.721619,286.214844 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M404.001984,324.967834 
	C402.932678,323.993896 401.863403,323.019989 400.258667,321.760254 
	C399.146149,321.308746 398.569061,321.143066 397.991974,320.977386 
	C397.991974,320.977386 397.995117,321.003510 398.048523,320.683716 
	C398.993164,319.331268 399.884369,318.298615 400.814941,317.220337 
	C399.410370,316.747559 398.216278,316.345612 397.022217,315.943695 
	C397.024902,315.547272 397.027557,315.150879 397.068542,314.177856 
	C397.140411,313.417694 397.173950,313.234161 397.207458,313.050629 
	C397.576996,313.045959 397.946503,313.041321 398.590179,313.038147 
	C404.716370,311.883209 400.344666,306.663574 403.230988,303.133331 
	C403.230988,309.438110 403.230988,314.858673 403.230988,320.577820 
	C404.796448,321.355530 407.086273,322.493134 409.650391,323.865173 
	C408.520050,324.398926 407.115417,324.698212 405.108521,324.984192 
	C404.338196,324.969879 404.170105,324.968842 404.001984,324.967834 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M381.996338,290.996887 
	C382.515625,288.827759 383.034912,286.658600 383.471222,284.836151 
	C386.244446,283.828522 388.723480,282.927765 391.591064,282.006775 
	C397.512848,283.014404 392.580322,286.979004 393.987549,289.556732 
	C392.397644,290.042297 390.813568,290.134125 388.714813,290.255798 
	C389.491028,288.781006 390.041992,287.734161 390.834595,286.228241 
	C388.503113,286.742554 386.640137,287.153503 384.199188,287.691956 
	C383.953339,288.220367 383.332367,289.555054 382.355713,290.944855 
	C382.000000,291.000000 381.996338,290.996887 381.996338,290.996887 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M425.025330,307.329742 
	C424.666473,305.568756 424.334961,304.156403 424.285858,302.257751 
	C425.827759,301.916870 427.087311,302.062347 427.891449,302.155243 
	C428.596130,301.100739 429.298065,300.050385 430.000610,299.000610 
	C430.001221,299.001221 430.011169,299.010986 430.011169,299.010986 
	C431.101501,300.974457 432.191833,302.937958 433.630157,304.958099 
	C433.978149,305.014771 433.996002,305.003571 433.987152,305.269012 
	C433.986023,305.707153 433.993774,305.879822 433.894714,306.259216 
	C433.596405,306.723724 433.348419,306.903564 432.644989,306.999451 
	C430.839661,307.651459 429.433258,308.309479 427.784668,309.131348 
	C427.029907,309.864532 426.517303,310.433899 426.004700,311.003235 
	C425.687378,309.894958 425.370056,308.786682 425.025330,307.329742 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M391.982147,293.003662 
	C392.429413,293.003052 392.876678,293.002441 393.659302,293.002136 
	C396.429871,295.512268 398.865143,298.022125 401.657166,300.899658 
	C401.062317,301.777985 400.043365,303.282562 398.248779,305.932404 
	C397.796875,303.391479 397.549957,302.003113 397.247223,300.300964 
	C396.135773,300.749939 394.938263,301.233673 393.354340,301.873474 
	C393.635529,300.141998 393.837067,298.900787 393.986267,296.991028 
	C393.321381,295.873108 392.708893,295.423737 392.056519,294.647461 
	C392.005127,293.881622 391.993622,293.442657 391.982147,293.003662 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M425.746277,311.199219 
	C426.517303,310.433899 427.029907,309.864532 428.177582,309.129028 
	C430.223083,308.310425 431.633484,307.657990 433.043884,307.005493 
	C433.348419,306.903564 433.596405,306.723724 434.134796,306.231140 
	C434.481689,305.996368 434.961609,305.939117 434.961609,305.939117 
	C439.312988,308.262970 435.082794,310.078247 434.950684,310.898346 
	C431.993195,311.682037 429.529480,311.950897 427.446747,312.986145 
	C424.970795,314.216858 424.803864,313.581238 425.746277,311.199219 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M432.004700,322.004822 
	C432.061707,323.095581 432.118530,324.181396 432.164734,325.267609 
	C432.195343,325.987305 432.508484,326.998596 432.159393,327.356018 
	C431.696381,327.830048 430.719482,327.860992 429.947723,327.960846 
	C428.795410,328.109985 427.629395,328.153137 426.428894,328.244415 
	C426.136627,327.321472 425.842377,326.392334 425.275208,325.233521 
	C425.002289,325.003876 424.960693,324.971771 424.960693,324.971771 
	C427.064484,323.982819 429.168274,322.993896 431.631287,321.997711 
	C431.990509,321.990448 432.000000,322.000000 432.004700,322.004822 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M392.096375,294.974335 
	C392.708893,295.423737 393.321381,295.873108 393.979126,296.629211 
	C393.575348,296.957458 393.126282,296.979004 392.340332,297.002014 
	C392.003448,297.003479 391.991882,296.991211 391.996826,296.651062 
	C392.033325,295.865417 392.064850,295.419861 392.096375,294.974335 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M405.390015,316.703247 
	C405.562866,316.838379 405.589172,317.074677 405.615479,317.311005 
	C405.491486,317.142151 405.367462,316.973297 405.390015,316.703247 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M425.021362,325.021973 
	C424.895416,325.468353 424.750397,325.896667 424.605377,326.324951 
	C424.584839,325.957092 424.564301,325.589233 424.752228,325.096558 
	C424.960693,324.971771 425.002289,325.003876 425.021362,325.021973 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M438.459534,328.439514 
	C438.522522,328.399445 438.396515,328.479584 438.459534,328.439514 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M434.859039,305.729553 
	C434.961609,305.939117 434.481689,305.996368 434.241608,306.024445 
	C433.993774,305.879822 433.986023,305.707153 433.981750,305.275299 
	C434.300934,305.094360 434.558044,305.262329 434.859039,305.729553 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M367.907440,342.977478 
	C366.793671,342.049530 365.595703,341.204468 364.583679,340.176392 
	C362.481689,338.041138 360.478302,335.808777 358.137543,333.297943 
	C358.845581,332.975250 360.390533,332.271088 361.969177,331.270264 
	C363.788116,330.709320 365.573364,330.445068 366.701111,330.278137 
	C365.405243,326.465546 364.342865,323.339874 363.259338,320.152069 
	C365.616760,319.360504 366.955444,318.910980 368.281647,318.465668 
	C368.599457,319.910278 368.915222,321.345428 369.390259,323.504547 
	C370.299683,322.352173 370.617188,322.132416 370.656067,321.871155 
	C370.922943,320.078766 371.131439,318.277710 371.358765,316.479431 
	C371.954437,316.603119 373.059937,316.698730 373.068542,316.854797 
	C373.178955,318.858032 373.367096,320.909607 373.036346,322.864471 
	C372.844604,323.997833 371.754456,324.979248 371.068604,326.029053 
	C371.573212,326.389740 372.077850,326.750397 372.582489,327.111084 
	C374.115723,325.038727 375.648926,322.966400 377.219147,320.844055 
	C382.179871,323.763855 382.352875,326.327454 377.608154,334.257660 
	C376.605621,333.943451 375.592041,333.625793 374.519592,333.289703 
	C374.092957,334.654816 373.752625,335.743713 372.889771,337.269043 
	C370.880646,339.462830 369.394043,341.220154 367.907440,342.977478 
M369.240387,334.177063 
	C369.055389,333.391785 368.870392,332.606506 368.518524,331.112823 
	C366.971466,332.899719 365.799500,334.253357 364.549438,335.697235 
	C366.771790,336.234406 368.009216,336.533539 369.246643,336.832642 
	C369.372070,336.120575 369.497467,335.408478 369.240387,334.177063 
M366.509674,321.399963 
	C366.509674,321.399963 366.582428,321.521698 366.509674,321.399963 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M365.345337,301.129883 
	C365.019287,300.496246 365.018768,300.039215 365.386475,299.237122 
	C370.133026,297.309113 366.299866,294.250275 367.384796,292.006897 
	C368.817932,291.995361 369.858826,291.981781 370.899719,291.968201 
	C371.039124,296.691315 370.520386,301.560516 371.526062,306.091400 
	C372.331543,309.720398 375.742035,311.234161 379.932617,309.350159 
	C380.684753,313.201141 381.352203,316.618683 382.019684,320.036194 
	C381.532684,320.185638 381.045685,320.335083 380.558685,320.484528 
	C379.819702,318.293854 379.080688,316.103210 378.060028,313.077606 
	C377.695312,315.025269 377.455505,316.305969 377.048920,318.477325 
	C375.882416,316.341583 375.127136,314.958801 374.240356,313.335175 
	C371.797791,314.270264 369.268311,315.238647 366.456482,316.315125 
	C365.522919,314.056061 364.587250,311.791870 363.698364,309.640869 
	C365.392273,309.334290 366.572876,309.120605 367.753479,308.906921 
	C367.059296,306.373444 366.365112,303.839935 365.345337,301.129883 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M371.099182,291.720184 
	C369.858826,291.981781 368.817932,291.995361 367.273743,291.790039 
	C366.577148,291.299133 366.327942,291.095520 366.022888,290.960266 
	C366.451569,290.009644 366.880249,289.059021 367.705719,288.198914 
	C368.191284,288.338623 368.280121,288.387787 368.368927,288.436920 
	C368.248260,288.289520 368.127594,288.142120 368.006958,287.994690 
	C368.340118,286.190063 368.673248,284.385406 369.029602,282.170288 
	C370.340454,281.326569 372.650391,284.661133 372.989685,280.347229 
	C374.759918,280.943207 375.356537,285.368439 378.934692,281.156708 
	C378.934692,284.355377 378.934692,286.827301 378.934692,290.409485 
	C376.578766,288.675720 375.386902,287.798553 374.955200,287.480865 
	C373.464142,289.108398 372.381378,290.290283 371.099182,291.720184 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M397.987244,321.381165 
	C398.569061,321.143066 399.146149,321.308746 399.923706,321.705933 
	C399.194061,324.414978 398.263977,326.892487 397.166931,329.684998 
	C397.000000,330.000000 396.998291,330.002441 396.998291,330.002441 
	C396.746033,332.460541 396.493774,334.918671 396.304474,336.763458 
	C398.493469,338.118591 400.272095,339.219666 402.050690,340.320740 
	C401.940338,340.932648 401.829956,341.544525 401.719604,342.156433 
	C398.049316,338.519104 396.300049,340.447845 395.179321,344.327209 
	C394.561340,341.692474 394.137787,339.886597 393.355499,338.031921 
	C392.324188,334.564941 389.841583,334.259613 386.993744,334.227234 
	C387.813690,330.525055 389.676666,330.159760 393.411469,332.830902 
	C394.976715,329.048462 396.479584,325.416687 397.987244,321.381165 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M377.006104,293.993896 
	C378.485321,293.061218 379.964508,292.128540 381.720032,291.096375 
	C381.996338,290.996887 382.000000,291.000000 382.001831,291.001831 
	C381.866180,293.541748 381.728668,296.079865 381.582031,298.786865 
	C383.204285,298.927765 384.655182,299.053772 385.977478,299.168640 
	C384.592560,300.633301 383.276703,302.065247 381.913971,303.451172 
	C381.082214,304.297089 379.928955,305.892487 379.355835,305.718170 
	C377.903229,305.276428 376.350281,304.252167 375.537476,303.004974 
	C375.132416,302.383392 376.002502,299.962219 376.740875,299.721802 
	C381.420227,298.197876 377.734039,296.380371 377.044647,294.341095 
	C377.000000,294.000000 377.006104,293.993896 377.006104,293.993896 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M368.109528,343.225708 
	C369.394043,341.220154 370.880646,339.462830 372.611755,337.506226 
	C372.885254,337.669647 372.914246,338.032288 372.979309,339.029175 
	C374.021545,340.849976 375.027679,342.036560 376.240234,343.439026 
	C377.316956,344.110535 378.187317,344.566101 379.057648,345.021698 
	C377.432251,345.767853 375.806824,346.514008 373.971741,347.907837 
	C372.888641,349.519684 371.997009,351.303253 371.145477,351.284302 
	C369.122009,351.239319 366.040649,354.956055 365.049500,350.026520 
	C367.100037,348.130920 371.966919,351.080109 372.339020,345.359070 
	C372.575165,344.427002 372.590515,344.053223 372.605896,343.679443 
	C372.358276,344.077362 372.110687,344.475311 371.863098,344.873230 
	C370.679260,344.406769 369.495453,343.940338 368.109528,343.225708 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M376.033844,343.223114 
	C375.027679,342.036560 374.021545,340.849976 372.986572,339.304749 
	C374.495544,338.541718 376.021973,337.907166 377.575989,337.830261 
	C378.351166,337.791901 379.176788,338.773682 379.985962,339.650940 
	C379.992279,340.007843 380.000946,339.999023 379.652954,340.043640 
	C378.214569,341.133179 377.124207,342.178162 376.033844,343.223114 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M392.056488,294.647461 
	C392.064850,295.419861 392.033325,295.865417 391.991302,296.645081 
	C390.217041,296.617981 388.453247,296.256775 386.275635,295.810822 
	C386.803192,293.448090 387.918671,291.633057 391.638214,292.964966 
	C391.993622,293.442657 392.005127,293.881622 392.056488,294.647461 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M396.714050,315.999268 
	C398.216278,316.345612 399.410370,316.747559 400.814941,317.220337 
	C399.884369,318.298615 398.993164,319.331268 398.060944,320.688049 
	C397.481903,319.359741 396.943878,317.707306 396.714050,315.999268 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M359.093811,324.595551 
	C357.954529,325.811920 356.760773,326.701538 355.567017,327.591125 
	C355.477661,327.346619 355.388336,327.102081 355.298981,326.857544 
	C356.108246,325.444946 356.917511,324.032379 357.866272,322.297180 
	C358.350311,322.739319 358.694824,323.504059 359.093811,324.595551 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M397.139954,312.697296 
	C397.173950,313.234161 397.140411,313.417694 397.070312,313.880554 
	C396.392242,313.247711 395.750671,312.335510 395.109100,311.423340 
	C395.550598,311.147705 395.992126,310.872101 396.433594,310.596466 
	C396.646545,311.178986 396.859497,311.761475 397.139954,312.697296 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M376.996521,294.003448 
	C376.286591,293.531128 375.580139,293.055359 374.873688,292.579620 
	C375.119141,292.272614 375.364594,291.965637 375.610046,291.658661 
	C376.062225,292.227600 376.514435,292.796509 376.986389,293.679688 
	C377.006104,293.993896 377.000000,294.000000 376.996521,294.003448 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M362.972717,326.682495 
	C362.362732,326.557709 361.718994,326.108246 361.043213,325.335693 
	C361.653748,325.461029 362.296356,325.909424 362.972717,326.682495 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M383.224548,293.972168 
	C383.395569,293.854492 383.566803,293.910889 383.738037,293.967285 
	C383.566956,294.026917 383.395844,294.086548 383.224548,293.972168 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M357.051514,320.725372 
	C356.936066,320.705048 356.801941,320.439453 356.840698,320.081146 
	C357.013580,319.988464 357.032867,320.480072 357.051514,320.725372 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M394.488983,322.450928 
	C394.545044,322.416138 394.432922,322.485718 394.488983,322.450928 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M466.017120,333.045380 
	C466.313599,331.908875 466.610077,330.772400 467.098206,328.942749 
	C468.048950,325.921143 469.150482,323.567596 465.316071,322.732910 
	C464.697754,322.598297 464.401947,320.982178 463.957031,320.050903 
	C465.124420,320.042267 466.291809,320.033661 467.993317,320.213196 
	C471.472076,322.443359 474.416718,324.485352 476.904785,326.210724 
	C479.207306,324.911346 480.941162,323.932831 482.675049,322.954346 
	C482.609711,322.599304 482.544373,322.244232 482.479034,321.889191 
	C480.525055,322.179779 478.571075,322.470337 475.970184,322.857117 
	C477.637177,318.945557 477.233368,316.398987 472.975098,315.678864 
	C472.966064,314.909180 472.964630,314.469391 472.980255,314.015137 
	C472.997284,314.000671 473.026276,313.966797 473.357422,313.969025 
	C474.130341,313.970612 474.572052,313.969971 475.335999,313.813477 
	C477.114197,310.447235 478.570190,307.236786 480.026184,304.026367 
	C480.026184,304.026367 479.996002,304.009003 480.270874,304.220673 
	C481.308685,305.840210 481.564362,308.342468 484.041138,306.052277 
	C484.355164,306.138184 484.606659,306.313660 484.890411,307.162781 
	C484.985260,308.884979 484.985260,310.023193 484.985260,311.205658 
	C483.297577,310.818359 481.938782,310.506531 480.028351,310.068115 
	C483.088776,317.726990 487.934143,309.481079 491.284210,312.378815 
	C487.955688,316.199646 487.955688,316.199646 497.770874,323.556519 
	C497.864655,322.601349 497.943054,321.803162 498.278625,321.113770 
	C499.032379,321.477295 499.528931,321.731995 500.389954,322.009735 
	C502.165649,322.370056 503.576843,322.707367 504.916931,323.405212 
	C501.548218,325.511902 498.250580,327.258026 494.930908,328.699097 
	C493.405731,327.188416 491.902649,325.982758 490.672211,324.995819 
	C489.340576,326.813324 488.076935,328.538025 486.813324,330.262726 
	C486.396423,330.154541 485.979523,330.046387 485.562653,329.938202 
	C485.379181,328.629364 485.195740,327.320526 485.349731,325.965668 
	C487.510834,324.227386 488.407745,322.702881 484.805389,322.063538 
	C484.577698,322.276184 484.434692,322.441010 484.467346,322.490997 
	C484.601593,322.696411 484.789246,322.866913 484.967102,323.417694 
	C484.980896,324.519775 484.984894,325.254395 484.617554,325.999115 
	C482.737549,327.939423 481.228882,329.869598 479.848816,331.635254 
	C481.855530,331.894775 483.003296,332.043182 484.118713,332.566681 
	C482.895386,334.632202 481.704346,336.322662 480.164673,338.507904 
	C476.938202,333.120392 474.517822,336.258942 471.621277,338.005127 
	C469.493927,336.374695 467.755524,334.710022 466.017120,333.045380 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M498.021423,321.004974 
	C497.943054,321.803162 497.864655,322.601349 497.770874,323.556519 
	C487.955688,316.199646 487.955688,316.199646 491.284210,312.378815 
	C487.934143,309.481079 483.088776,317.726990 480.028351,310.068115 
	C481.938782,310.506531 483.297577,310.818359 484.985260,311.205658 
	C484.985260,310.023193 484.985260,308.884979 484.992004,307.373291 
	C486.335999,307.499146 487.673218,307.998474 490.148499,308.922729 
	C490.546906,307.980560 491.387543,305.992523 491.960815,304.636810 
	C491.203400,302.384155 490.598145,300.584045 490.046265,298.067444 
	C491.160248,297.947510 492.220856,298.544006 493.579926,299.308350 
	C493.701660,298.726685 494.073090,297.967102 493.971100,297.277557 
	C493.197937,292.050323 495.690643,290.356598 500.520721,291.007965 
	C501.334839,291.117767 502.177887,291.012939 503.007568,291.007263 
	C503.007568,291.007263 503.000000,291.000000 502.973694,291.325287 
	C502.091553,292.225861 501.293060,292.929504 500.365417,293.343994 
	C499.200012,293.864685 497.928864,294.148621 496.703339,294.534729 
	C496.864014,294.868866 497.024719,295.203003 497.185394,295.537170 
	C498.977295,295.898529 500.769226,296.259888 503.729492,296.856842 
	C501.463837,297.798584 500.366058,298.254852 499.998779,298.407532 
	C499.998779,300.740021 499.998779,302.369385 499.693756,304.028320 
	C498.622467,304.677917 497.856171,305.297943 497.015991,305.621582 
	C493.711761,303.740570 494.709198,306.766327 493.904846,308.292694 
	C494.200684,310.064606 494.637543,311.524811 495.064728,313.357239 
	C495.084351,314.470947 495.113647,315.212433 495.110291,316.324646 
	C496.058868,318.131866 497.040131,319.568420 498.021423,321.004974 
M491.415253,316.703400 
	C491.692474,316.992523 491.967072,317.284485 492.261078,317.555359 
	C492.268951,317.562653 492.487030,317.341797 492.607086,317.227325 
	C492.329926,316.938629 492.052765,316.649963 491.415253,316.703400 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M502.935364,290.624939 
	C502.177887,291.012939 501.334839,291.117767 500.520721,291.007965 
	C495.690643,290.356598 493.197937,292.050323 493.971100,297.277557 
	C494.073090,297.967102 493.701660,298.726685 493.579926,299.308350 
	C492.220856,298.544006 491.160248,297.947510 490.040283,297.663696 
	C488.336456,297.467346 486.691986,296.958344 484.617065,296.316071 
	C486.937073,292.127014 493.533386,294.177765 493.837280,288.326019 
	C496.942383,288.740356 499.902771,289.491486 502.935364,290.624939 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M456.001221,312.000732 
	C456.748993,310.793152 457.495575,309.584869 458.857880,307.380066 
	C460.939636,310.593689 462.638153,313.215668 464.336670,315.837646 
	C463.817841,316.186188 463.299011,316.534729 462.780151,316.883240 
	C461.897644,315.718445 461.015137,314.553650 460.184784,313.457703 
	C458.685028,313.629059 457.235809,313.794678 455.399780,313.954346 
	C455.012970,313.948456 455.034760,313.511719 455.256409,313.166504 
	C455.735168,312.601166 455.909760,312.328217 456.001770,312.002441 
	C456.001770,312.002441 456.000000,312.000000 456.001221,312.000732 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M441.155396,295.670654 
	C440.981842,295.980591 440.500671,295.988953 440.260254,295.983856 
	C437.932159,291.329987 442.395355,289.485107 443.592621,285.134949 
	C446.507690,290.461243 444.142944,292.980774 441.155396,295.670654 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M479.629395,304.014069 
	C478.570190,307.236786 477.114197,310.447235 475.332336,313.427368 
	C475.008148,310.822662 475.009827,308.448212 475.011536,306.073792 
	C475.338745,305.595093 475.665924,305.116364 476.032562,304.336548 
	C477.125519,304.024200 478.179077,304.012970 479.629395,304.014069 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M470.437866,286.010132 
	C472.111603,284.989258 474.153259,284.025818 476.566620,282.987671 
	C478.299011,283.787170 479.659698,284.661346 481.020416,285.535553 
	C480.861420,285.867676 480.702423,286.199829 480.543457,286.531982 
	C477.297546,286.377197 474.051666,286.222412 470.437866,286.010132 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M446.024445,303.981018 
	C446.021332,303.236420 446.018219,302.491852 446.014740,301.375061 
	C446.897430,301.338928 447.780487,301.674957 448.795074,302.061066 
	C449.320984,298.714020 449.842133,295.397278 450.363281,292.080536 
	C450.576233,292.080444 450.789154,292.080383 451.002106,292.080322 
	C451.002106,294.750488 451.002106,297.420654 450.990021,300.536621 
	C450.424561,302.102966 449.871216,303.223511 449.159332,304.674438 
	C449.000793,305.004761 448.940430,305.072388 448.940430,305.072388 
	C448.160675,304.787628 447.380890,304.502899 446.300598,304.109192 
	C446.000061,304.000214 446.024445,303.981018 446.024445,303.981018 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M484.029358,305.645447 
	C481.564362,308.342468 481.308685,305.840210 480.283722,304.235138 
	C480.867371,303.084778 481.713074,302.131592 482.558746,301.178406 
	C483.045013,302.531799 483.531281,303.885193 484.029358,305.645447 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M475.304749,297.103149 
	C475.272308,296.542999 475.401031,296.145844 475.529785,295.748688 
	C475.636627,295.857788 475.850220,295.990204 475.832336,296.072052 
	C475.743774,296.477417 475.594086,296.869415 475.304749,297.103149 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M487.885864,303.277283 
	C487.784729,303.757690 487.642700,304.009674 487.500671,304.261658 
	C487.359222,304.010071 487.051605,303.694153 487.117859,303.523010 
	C487.202728,303.303986 487.588745,303.201538 487.885864,303.277283 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M455.762421,312.008942 
	C455.909760,312.328217 455.735168,312.601166 455.267395,312.948151 
	C455.212219,312.721832 455.367645,312.368652 455.762421,312.008942 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M423.024261,298.978149 
	C421.397186,300.030731 419.770081,301.083344 418.231201,302.078857 
	C417.801025,297.446533 417.442566,293.586700 417.028870,289.306061 
	C417.774750,288.010895 418.435242,286.663361 419.410187,286.373444 
	C420.857117,285.943268 422.526703,286.262085 423.544189,286.262085 
	C424.259277,288.237976 424.876831,289.944458 425.747894,291.821533 
	C426.001373,291.992096 426.011597,291.973328 426.027100,292.306335 
	C425.735748,293.764404 425.428955,294.889435 424.860809,296.118408 
	C424.409393,296.535583 424.219330,296.848816 424.039368,297.368591 
	C424.049500,297.575165 424.067841,297.988342 423.841064,298.091675 
	C423.327576,298.388184 423.130890,298.649231 423.024261,298.978149 
M421.567444,290.300598 
	C421.294159,290.015015 421.023529,289.726562 420.733582,289.459015 
	C420.725677,289.451752 420.510040,289.669647 420.391266,289.782532 
	C420.665070,290.068298 420.938873,290.354095 421.567444,290.300598 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M426.014160,291.986755 
	C426.484741,290.528992 426.942505,289.076538 427.400299,287.624115 
	C427.784821,291.872528 437.060852,291.508270 432.277100,297.553772 
	C431.978271,297.317657 431.971954,297.140717 431.970459,296.597290 
	C429.987366,294.811646 427.999481,293.392487 426.011597,291.973328 
	C426.011597,291.973328 426.001373,291.992096 426.014160,291.986755 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M431.965668,296.963745 
	C431.971954,297.140717 431.978271,297.317657 431.991333,297.760468 
	C431.533051,298.343079 431.068024,298.659851 430.307068,298.993805 
	C430.011169,299.010986 430.001221,299.001221 430.105743,298.742310 
	C430.480988,297.996948 430.751709,297.510468 431.258331,297.009888 
	C431.494202,296.995728 431.965668,296.963745 431.965668,296.963745 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M346.002319,464.353882 
	C347.743713,460.493408 350.734344,457.337982 348.604553,452.620911 
	C349.883453,451.730682 351.442261,450.645630 353.294495,449.356323 
	C352.630981,451.919159 352.061157,454.120117 351.491333,456.321075 
	C351.991699,456.501953 352.492065,456.682831 352.992401,456.863708 
	C354.174988,453.825195 355.357605,450.786652 356.402954,448.100708 
	C358.196533,447.914154 360.188049,447.639221 362.188751,447.523834 
	C363.431763,447.452087 364.713898,447.503754 365.922974,447.760956 
	C366.254333,447.831451 366.658173,449.298340 366.566956,449.352936 
	C365.509521,449.985870 364.396149,450.563232 363.233612,450.974487 
	C361.199158,451.694244 359.120087,452.287811 357.059692,452.934265 
	C357.222198,453.530823 357.384735,454.127380 357.547241,454.723969 
	C359.381073,454.598785 361.214905,454.473633 363.515808,454.316559 
	C363.515808,456.022156 363.515808,457.692749 363.515808,459.592896 
	C362.235504,459.592896 360.992767,459.592896 359.852844,459.592896 
	C360.394836,460.886688 360.961761,462.239929 361.106750,463.491852 
	C359.348694,462.673309 358.012573,461.956116 357.125031,461.479706 
	C356.698975,459.433380 356.339722,457.707916 355.899200,455.592102 
	C354.623108,457.976440 353.816956,459.482727 352.973083,460.703674 
	C352.630463,460.094604 352.325531,459.770905 352.020630,459.447205 
	C351.835968,459.659943 351.651306,459.872711 351.466644,460.085510 
	C351.973633,460.394318 352.480591,460.703094 353.012329,461.343506 
	C353.391296,463.637329 353.745544,465.599518 354.094666,467.533508 
	C356.197510,466.456909 358.600708,465.226501 361.003937,463.996124 
	C361.009186,464.176575 361.014435,464.357056 361.230774,465.070557 
	C362.225952,468.576508 363.009979,471.549408 363.794037,474.522339 
	C363.165283,474.307770 362.536530,474.093170 361.907776,473.878601 
	C360.602631,475.603546 359.297455,477.328491 357.650269,479.005829 
	C356.210632,477.578125 355.196014,476.114471 353.953339,474.880463 
	C353.666321,474.595459 352.598755,475.096436 351.645203,475.085358 
	C350.937256,474.600983 350.474548,474.266968 350.011810,473.932953 
	C350.011810,473.932953 350.001465,473.997467 349.876465,473.704407 
	C349.163910,473.135101 348.576324,472.858856 347.988770,472.582611 
	C347.986023,471.630981 347.983307,470.679382 348.209229,469.210083 
	C348.391876,468.279144 348.345856,467.865845 348.299805,467.452576 
	C347.863983,467.961182 347.428162,468.469788 346.992340,468.978363 
	C346.992340,468.978363 346.498138,468.997589 346.250916,468.996826 
	C346.001831,467.589996 345.999969,466.183990 346.002319,464.353882 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M336.204468,516.500854 
	C335.382996,517.349121 334.504639,517.740967 333.274872,518.269531 
	C332.521881,518.276428 332.120331,518.146729 331.385132,517.608154 
	C331.003204,516.460510 330.954865,515.721802 330.906525,514.983093 
	C330.702484,513.055542 330.498413,511.127991 330.311035,509.358093 
	C335.947571,509.318573 333.034729,504.586639 335.202057,502.107086 
	C335.517242,506.752869 335.832428,511.398651 336.204468,516.500854 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M350.002899,474.308929 
	C350.474548,474.266968 350.937256,474.600983 351.721161,475.413391 
	C352.990906,477.634430 353.939453,479.377106 354.888000,481.119751 
	C354.503693,481.106354 354.119354,481.092987 353.447876,481.059387 
	C352.105103,478.921112 351.049530,476.803009 350.002899,474.308929 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M330.602875,515.032471 
	C330.954865,515.721802 331.003204,516.460510 331.072327,517.567932 
	C330.828491,516.985046 330.563843,516.033386 330.602875,515.032471 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M347.238464,468.994476 
	C347.428162,468.469788 347.863983,467.961182 348.299805,467.452576 
	C348.345856,467.865845 348.391876,468.279144 348.207886,468.851593 
	C347.977875,469.010773 347.484589,469.010590 347.238464,468.994476 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M348.116333,472.908875 
	C348.576324,472.858856 349.163910,473.135101 349.908997,473.701477 
	C349.458984,473.739471 348.851440,473.487305 348.116333,472.908875 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M329.438110,528.533508 
	C329.399445,528.473389 329.476807,528.593628 329.438110,528.533508 
z"/>
<path fill="#FDFDFC" opacity="1.000000" stroke="none" 
	d="
M449.599365,476.872864 
	C449.722443,476.734955 449.845490,476.597015 450.464172,476.244263 
	C457.033936,468.714508 464.018951,461.962158 468.966370,453.951416 
	C476.178528,442.273621 477.716309,429.126709 473.513031,415.828247 
	C472.605896,412.958191 469.994354,410.626801 467.923706,407.886688 
	C467.482483,407.404144 467.285431,407.082184 467.110291,406.423035 
	C466.381561,405.432892 465.630890,404.779968 464.665039,404.022003 
	C464.449829,403.916992 464.010437,403.726471 463.980194,403.396545 
	C459.428070,399.983734 454.906189,396.900848 450.321777,393.484375 
	C450.068970,392.748810 449.878754,392.346802 449.798370,391.564453 
	C449.839203,390.139954 449.770264,389.095764 449.885620,387.654083 
	C449.952789,385.821289 449.835663,384.386017 449.844910,382.598389 
	C449.908295,381.508728 449.845276,380.771393 449.937134,379.618958 
	C449.967621,377.454834 449.843201,375.705811 449.840454,373.604767 
	C449.878021,372.518616 449.793884,371.784515 449.815430,370.635010 
	C450.105499,367.239899 449.977539,364.191315 450.613159,361.311218 
	C450.907196,359.978821 452.667389,358.003143 453.802094,357.976288 
	C462.765808,357.764130 471.875488,356.998688 480.674500,358.259033 
	C491.596802,359.823547 502.264374,363.166168 513.309204,365.970215 
	C514.018250,366.347717 514.460144,366.495819 514.958618,366.948853 
	C516.841003,368.264069 518.666687,369.274353 520.633179,370.580750 
	C521.801453,371.634827 522.828979,372.392792 523.932922,373.449219 
	C524.951660,374.525116 525.893982,375.302551 526.898193,376.436340 
	C534.066711,388.599335 538.621948,401.216400 540.322083,414.996552 
	C542.298584,431.016663 543.277893,447.004547 541.626526,463.091705 
	C540.337952,475.644989 539.012573,488.168915 533.260498,499.736115 
	C528.570679,509.166962 522.653137,517.221497 511.861694,520.391846 
	C506.597870,516.914490 501.849731,513.154053 496.508392,510.668335 
	C488.578613,506.978027 480.228851,504.190216 471.729553,500.839539 
	C464.326172,498.783295 457.251068,496.905823 450.141846,494.740723 
	C450.004639,494.291016 449.901550,494.128906 449.820740,493.586975 
	C449.830475,492.449860 449.817902,491.692596 449.984009,490.568481 
	C450.054504,488.867584 449.946289,487.533539 449.940247,485.739166 
	C449.894714,482.476868 449.747040,479.674866 449.599365,476.872864 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M450.384338,393.817963 
	C454.906189,396.900848 459.428070,399.983734 464.014008,403.794617 
	C465.084961,407.310425 466.091858,410.098297 467.098755,412.886139 
	C470.310028,430.210266 465.421753,445.153015 453.300293,457.866516 
	C452.201782,455.506500 451.616333,453.392273 451.000854,451.286804 
	C450.481750,449.510986 449.927826,447.745361 449.350708,445.665283 
	C449.595856,444.579834 449.879578,443.804321 450.499695,442.932556 
	C451.364258,442.494965 452.177765,442.273438 452.378998,441.794647 
	C457.766418,428.974518 462.643799,416.120941 452.981232,402.740723 
	C452.391022,401.953278 451.850891,401.468445 451.178650,400.647949 
	C448.381866,398.390808 445.717255,396.469299 443.059204,394.282196 
	C443.041473,393.662811 443.017242,393.308990 443.336365,392.965820 
	C444.137024,392.954895 444.594391,392.933319 445.408539,392.979980 
	C446.469421,393.220001 447.173492,393.391815 447.974548,393.819489 
	C448.842438,393.989563 449.613403,393.903778 450.384338,393.817963 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M402.623962,487.444214 
	C399.276489,489.115204 395.968445,490.871429 392.574402,492.441742 
	C381.067963,497.765472 369.531067,503.023376 357.562103,507.914886 
	C357.065521,506.609833 357.012665,505.696320 357.117157,504.500366 
	C360.549194,501.677643 363.823853,499.137360 367.366394,496.608765 
	C368.145569,496.166016 368.656830,495.711578 369.472412,495.222900 
	C370.231323,494.824280 370.685974,494.459900 371.493286,494.040344 
	C379.865875,490.596130 386.131073,485.457245 389.327515,477.028687 
	C389.909973,475.492920 391.398590,474.300781 392.837891,473.074829 
	C393.130127,474.157562 392.939148,475.122528 392.997803,476.072052 
	C393.178894,479.006195 386.833801,481.909760 393.121643,485.116760 
	C393.889221,484.639954 394.478943,483.908600 395.072235,483.142792 
	C395.075836,483.108337 395.050201,483.043945 395.440002,483.044556 
	C396.868744,483.001099 397.907684,482.957062 399.287964,482.966339 
	C401.280945,483.258545 402.932617,483.497437 404.862885,483.776642 
	C404.558258,484.555481 404.276703,485.275299 403.634735,485.991119 
	C402.069000,485.806702 400.863678,485.626312 399.658356,485.445892 
	C399.576416,485.734924 399.494476,486.023987 399.412537,486.313019 
	C400.483002,486.690063 401.553497,487.067139 402.623962,487.444214 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M403.995117,485.995117 
	C404.276703,485.275299 404.558258,484.555481 404.862885,483.776642 
	C402.932617,483.497437 401.280945,483.258545 399.368225,482.627319 
	C400.375610,480.890137 401.643982,479.545258 403.248169,478.108459 
	C403.803436,477.941071 404.022919,477.865570 404.565613,477.764221 
	C406.150238,477.440460 407.411682,477.142578 408.958496,476.808472 
	C409.493378,476.496002 409.742920,476.219696 410.290833,475.777161 
	C419.709564,470.669189 428.829895,465.727478 437.966980,461.178955 
	C437.818268,463.171326 437.652740,464.770477 437.479919,466.439941 
	C438.562317,466.498169 439.557281,466.551666 440.195435,466.585999 
	C438.480316,468.865631 436.829346,471.059998 435.178345,473.254395 
	C435.178375,473.254395 435.286743,473.261505 434.928101,473.228882 
	C433.738403,473.934540 432.907379,474.672852 432.076385,475.411163 
	C432.076385,475.411163 431.745331,475.730499 431.383667,475.437378 
	C426.370819,477.483612 421.719574,479.822937 417.068359,482.162262 
	C415.617828,482.786346 414.167297,483.410431 412.174255,483.795563 
	C409.762604,484.479248 407.893524,485.401917 406.024414,486.324585 
	C406.024414,486.324585 405.810669,486.464081 405.304657,486.299255 
	C404.534058,486.091278 404.269470,486.048065 404.002441,486.002441 
	C404.000000,486.000000 403.995117,485.995117 403.995117,485.995117 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M449.229248,476.837341 
	C449.747040,479.674866 449.894714,482.476868 449.686371,485.979218 
	C449.237488,488.144196 449.144623,489.608856 448.661041,491.057739 
	C446.843079,490.679291 445.415833,490.316589 444.080261,489.669983 
	C441.129059,488.203888 438.142609,486.298676 435.026459,486.055450 
	C432.255951,485.839233 429.252808,487.255554 426.543610,488.402924 
	C425.703094,488.758881 425.488159,490.592010 424.877686,492.070679 
	C424.514496,493.022827 424.260406,493.653137 423.668579,494.492523 
	C419.936676,494.249298 415.663757,497.197601 413.689606,492.340271 
	C407.647278,494.211639 401.765472,496.033295 395.883667,497.854919 
	C400.547760,495.624237 405.088898,493.073639 409.899628,491.224579 
	C422.824554,486.256744 435.864716,481.588837 449.229248,476.837341 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M468.167908,408.047272 
	C469.994354,410.626801 472.605896,412.958191 473.513031,415.828247 
	C477.716309,429.126709 476.178528,442.273621 468.966370,453.951416 
	C464.018951,461.962158 457.033936,468.714508 450.632019,476.023621 
	C450.211517,475.308990 450.118805,474.600159 450.419189,473.403137 
	C452.588898,471.167603 454.396240,469.450317 456.137054,467.668030 
	C467.949951,455.573425 474.603729,441.371704 473.107025,424.175812 
	C472.794830,420.588776 470.908020,417.138733 469.746826,413.625580 
	C469.686737,413.434601 469.626617,413.243591 469.459167,412.440002 
	C468.957153,410.567413 468.562531,409.307343 468.167908,408.047272 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M395.488159,497.890564 
	C401.765472,496.033295 407.647278,494.211639 413.689606,492.340271 
	C415.663757,497.197601 419.936676,494.249298 424.073334,494.466797 
	C425.888611,494.325104 426.961304,494.418182 428.046082,494.850281 
	C427.473663,496.371521 426.889160,497.553741 426.436829,498.468628 
	C427.136719,499.127869 428.080048,500.016449 429.023407,500.904999 
	C421.327515,500.600677 413.631653,500.296326 405.265747,499.740265 
	C403.325378,499.869751 402.055023,500.250977 400.784698,500.632202 
	C399.783203,500.606903 398.781708,500.581665 397.477783,500.531677 
	C393.933655,500.526855 390.691895,500.546783 387.198853,500.266052 
	C389.662537,499.285675 392.377594,498.605957 395.488159,497.890564 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M435.532837,473.223450 
	C436.829346,471.059998 438.480316,468.865631 440.195435,466.585999 
	C439.557281,466.551666 438.562317,466.498169 437.479919,466.439941 
	C437.652740,464.770477 437.818268,463.171326 438.195435,460.959656 
	C442.067810,455.556519 445.728546,450.765869 449.389282,445.975220 
	C449.927826,447.745361 450.481750,449.510986 451.000854,451.286804 
	C451.616333,453.392273 452.201782,455.506500 452.971527,457.984680 
	C453.184021,458.659241 453.071655,458.891907 452.215698,459.081970 
	C451.211517,459.881500 450.797363,460.649536 450.383240,461.417572 
	C450.383240,461.417572 450.417114,461.244232 450.099243,461.215759 
	C449.599823,461.840881 449.418213,462.494476 449.236633,463.148010 
	C444.786835,466.496185 440.337067,469.844360 435.532837,473.223450 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M469.565765,414.004944 
	C470.908020,417.138733 472.794830,420.588776 473.107025,424.175812 
	C474.603729,441.371704 467.949951,455.573425 456.137054,467.668030 
	C454.396240,469.450317 452.588898,471.167603 450.501465,473.037415 
	C451.355377,471.108978 452.159576,468.711975 453.742676,467.062561 
	C465.148804,455.178650 472.070801,441.490845 471.157776,424.632141 
	C470.971558,421.194275 469.996246,417.799133 469.565765,414.004944 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M356.959808,504.782806 
	C357.012665,505.696320 357.065521,506.609833 357.209229,507.976776 
	C357.300110,508.430206 357.362671,508.732910 357.158752,509.004272 
	C356.954834,509.275604 356.776031,509.733063 356.776031,509.733063 
	C356.776031,509.733063 356.714996,509.721954 356.684082,509.712402 
	C352.983002,511.890656 349.312836,514.078430 345.296814,516.110107 
	C346.003326,514.426453 346.886749,512.738159 348.149750,511.411072 
	C349.817444,509.658813 351.782715,508.189728 354.168213,506.309387 
	C355.464172,505.607513 356.211975,505.195160 356.959808,504.782806 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M417.449585,482.145996 
	C421.719574,479.822937 426.370819,477.483612 431.162109,475.498962 
	C426.811707,477.945679 422.321289,480.037720 417.449585,482.145996 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M467.923706,407.886688 
	C468.562531,409.307343 468.957153,410.567413 469.346741,412.161499 
	C468.849182,412.628265 468.356720,412.761047 467.481506,412.889984 
	C466.091858,410.098297 465.084961,407.310425 464.044250,404.124512 
	C464.010437,403.726471 464.449829,403.916992 464.676392,404.339661 
	C465.631439,405.428253 466.359924,406.094238 467.088409,406.760193 
	C467.285431,407.082184 467.482483,407.404144 467.923706,407.886688 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M406.395599,486.297729 
	C407.893524,485.401917 409.762604,484.479248 411.830200,483.836243 
	C410.274719,484.834198 408.520752,485.552551 406.395599,486.297729 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M402.868500,487.461609 
	C401.553497,487.067139 400.483002,486.690063 399.412537,486.313019 
	C399.494476,486.023987 399.576416,485.734924 399.658356,485.445892 
	C400.863678,485.626312 402.069000,485.806702 403.634705,485.991119 
	C403.995117,485.995117 404.000000,486.000000 404.006958,486.290222 
	C404.023499,486.772156 404.033020,486.963867 404.042542,487.155548 
	C403.758057,487.336304 403.448212,487.444122 402.868500,487.461609 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M432.413879,475.354919 
	C432.907379,474.672852 433.738403,473.934540 434.928864,473.283142 
	C434.442627,474.012939 433.596985,474.655792 432.413879,475.354919 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M450.720367,461.402832 
	C450.797363,460.649536 451.211517,459.881500 452.013428,459.109070 
	C451.953308,459.865814 451.505402,460.626953 450.720367,461.402832 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M449.506165,463.023132 
	C449.418213,462.494476 449.599823,461.840881 450.123413,461.300720 
	C450.235504,461.908813 450.005615,462.403534 449.506165,463.023132 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M356.959839,509.779175 
	C356.776031,509.733063 356.954834,509.275604 357.171509,509.158264 
	C357.420868,509.259552 357.473511,509.478455 357.469849,509.696442 
	C357.469086,509.741180 357.257843,509.782440 356.959839,509.779175 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M404.341797,487.124420 
	C404.033020,486.963867 404.023499,486.772156 404.009399,486.292664 
	C404.269470,486.048065 404.534058,486.091278 405.185974,486.252289 
	C405.262573,486.611176 404.951813,486.852234 404.341797,487.124420 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M323.755890,563.869202 
	C327.871826,544.285645 340.142303,531.306335 357.689636,522.174805 
	C359.691559,521.599915 361.384827,521.200867 363.657166,521.080322 
	C365.533142,520.881226 366.829987,520.403687 368.284851,519.921143 
	C368.442871,519.916199 368.755402,519.964478 369.033020,519.889038 
	C369.557037,519.530396 369.803467,519.247131 370.345612,518.944458 
	C371.036285,518.911682 371.431183,518.898254 372.103821,519.183472 
	C373.334381,519.640625 374.391724,520.151001 375.224121,519.903809 
	C384.533264,517.139282 393.819519,517.572937 403.048401,520.106934 
	C404.189545,520.420227 404.996887,521.949219 405.581360,522.928101 
	C404.090210,523.345581 402.976532,523.750000 401.547913,524.204529 
	C400.824219,524.143982 400.415497,524.033264 400.113220,523.644165 
	C399.123138,522.085510 398.026642,520.805359 396.290833,518.778687 
	C395.437836,521.104126 395.032990,522.207825 394.195984,523.350586 
	C379.761963,522.989929 366.635559,526.029358 354.280579,532.788147 
	C345.393127,537.649963 339.627228,545.404968 333.478027,553.279663 
	C331.655762,555.609985 330.432190,558.106750 328.689667,560.164001 
	C327.393494,561.694214 325.424957,562.654846 323.755890,563.869202 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M419.884338,512.748962 
	C421.188568,512.446533 422.679901,511.574615 423.767426,511.931885 
	C432.412201,514.771729 440.980255,517.844971 449.899719,521.442017 
	C449.652832,524.810364 449.540497,527.382568 452.965332,528.733887 
	C453.268585,530.066467 453.600616,531.070801 453.948761,532.410828 
	C453.333740,533.817383 452.702667,534.888245 452.013855,535.638489 
	C451.593628,534.711182 451.231110,534.104492 451.182312,534.022827 
	C449.691162,535.416443 448.414490,536.609558 446.936951,537.542969 
	C445.090302,535.618835 443.444550,533.954529 442.077698,532.082520 
	C445.990997,530.264893 445.874084,528.257324 442.986633,525.567017 
	C442.716034,523.335083 442.442749,521.491943 442.202393,519.871033 
	C439.748291,520.302856 437.896820,520.628601 436.011780,520.666870 
	C435.557159,520.090454 435.136139,519.801575 434.715149,519.512695 
	C434.619202,519.774231 434.397797,520.235474 434.446747,520.266235 
	C434.924408,520.566162 435.447205,520.794128 436.079956,521.292847 
	C436.463043,522.031494 436.723389,522.519897 436.675323,523.163696 
	C435.621643,524.225952 434.876404,525.132751 434.037903,526.152954 
	C436.890472,528.080688 443.604492,524.615173 440.670715,531.942261 
	C435.313202,529.544617 430.316132,527.146851 425.187897,524.457336 
	C424.341553,523.884094 423.626404,523.602722 422.560364,523.224243 
	C421.248535,522.998230 420.287567,522.869324 419.173492,522.411133 
	C414.679352,520.717957 410.338379,519.353943 406.046112,517.650024 
	C407.050537,516.905884 408.020508,516.531860 408.957245,516.087708 
	C409.649109,515.759705 410.297668,515.340271 411.372192,514.959229 
	C417.598633,516.248840 423.418427,517.540771 429.238190,518.832703 
	C429.366821,518.249268 429.495483,517.665771 429.624115,517.082275 
	C426.377533,515.637878 423.130920,514.193420 419.884338,512.748962 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M410.965515,514.961487 
	C410.297668,515.340271 409.649109,515.759705 408.957245,516.087708 
	C408.020508,516.531860 407.050537,516.905884 405.622375,517.636841 
	C403.685028,517.758301 402.188416,517.679871 400.761505,517.322937 
	C396.967743,516.373840 393.092285,514.177856 389.453613,514.538574 
	C383.492737,515.129272 377.693512,517.351013 371.826111,518.884888 
	C371.431183,518.898254 371.036285,518.911682 370.085388,519.046265 
	C369.271393,519.433167 369.013367,519.698792 368.755371,519.964478 
	C368.755402,519.964478 368.442871,519.916199 367.955414,519.844360 
	C367.262390,519.682068 367.056824,519.591614 366.851257,519.501160 
	C371.355682,517.792542 375.848846,516.053345 380.367950,514.384521 
	C384.247314,512.951904 388.156342,511.599579 392.516083,510.046509 
	C392.669586,510.370331 393.183197,511.454010 393.737122,512.622681 
	C396.861206,509.618195 399.873688,507.102722 399.919098,514.877991 
	C401.060211,510.231323 403.392914,508.553955 407.824951,511.991913 
	C408.183105,512.937378 408.231506,513.212036 408.274902,513.469482 
	C408.269897,513.452271 408.266968,513.448608 408.519470,513.762756 
	C409.503174,514.371765 410.234314,514.666626 410.965515,514.961487 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M408.279907,513.486633 
	C408.231506,513.212036 408.183105,512.937378 408.067566,512.248657 
	C409.392242,511.823669 410.783966,511.812744 412.776978,512.190063 
	C414.622498,512.650024 415.866821,512.721741 417.111115,512.793457 
	C417.602631,512.774231 418.094116,512.755005 418.915588,512.967590 
	C419.418213,513.074829 419.590912,512.950256 419.763611,512.825684 
	C419.763611,512.825684 419.831451,512.801392 419.857910,512.775146 
	C423.130920,514.193420 426.377533,515.637878 429.624115,517.082275 
	C429.495483,517.665771 429.366821,518.249268 429.238190,518.832703 
	C423.418427,517.540771 417.598633,516.248840 411.372192,514.959229 
	C410.234314,514.666626 409.503174,514.371765 408.554382,513.789185 
	C408.336731,513.501526 408.279907,513.486633 408.279907,513.486633 
z"/>
<path fill="#F0DDAC" opacity="1.000000" stroke="none" 
	d="
M452.994110,528.405701 
	C449.540497,527.382568 449.652832,524.810364 450.229248,521.640991 
	C450.632446,521.176392 451.033997,521.108154 451.948914,521.251709 
	C454.765015,522.985596 457.105011,524.457520 459.318024,526.100342 
	C459.562775,526.282043 459.082367,527.440552 458.519531,528.064453 
	C456.398560,528.123840 454.696320,528.264771 452.994110,528.405701 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M458.938293,528.145996 
	C459.082367,527.440552 459.562775,526.282043 459.318024,526.100342 
	C457.105011,524.457520 454.765015,522.985596 452.247314,521.186401 
	C452.073853,520.404175 452.115295,519.899048 452.308533,519.037842 
	C456.598633,521.548767 460.736969,524.415833 464.775635,527.763672 
	C464.440948,529.189636 464.205841,530.134705 463.606842,531.057617 
	C461.808075,530.072327 460.373169,529.109192 458.938293,528.145996 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M417.016357,512.473755 
	C415.866821,512.721741 414.622498,512.650024 413.193359,512.247253 
	C414.312897,511.995422 415.617218,512.074707 417.016357,512.473755 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M366.533020,519.589722 
	C367.056824,519.591614 367.262390,519.682068 367.797394,519.849304 
	C366.829987,520.403687 365.533142,520.881226 364.035095,521.067688 
	C364.627533,520.410522 365.421173,520.044434 366.533020,519.589722 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M451.230957,518.026184 
	C451.512146,517.770325 451.811615,517.901489 452.172272,518.371704 
	C451.957336,518.492981 451.629242,518.393799 451.230957,518.026184 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M419.653442,512.814453 
	C419.590912,512.950256 419.418213,513.074829 419.283691,512.999023 
	C419.321838,512.798523 419.543274,512.803284 419.653442,512.814453 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M323.485046,564.158936 
	C325.424957,562.654846 327.393494,561.694214 328.689667,560.164001 
	C330.432190,558.106750 331.655762,555.609985 333.339539,553.642090 
	C328.153412,563.988647 325.804657,574.655945 326.691101,586.018555 
	C325.815430,586.020508 324.939728,586.022400 323.624146,586.027771 
	C323.194214,578.837036 323.204224,571.642822 323.485046,564.158936 
M325.776794,567.937622 
	C325.641205,567.597595 325.505646,567.257568 325.370087,566.917542 
	C325.396393,567.344177 325.422699,567.770874 325.776794,567.937622 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M494.384369,524.109741 
	C496.681305,524.751221 498.615387,525.629700 500.640411,527.039795 
	C500.483429,528.364990 500.235474,529.158569 499.587555,529.975952 
	C497.301788,529.573914 495.433228,529.034729 493.526794,528.744568 
	C488.912689,528.042236 484.188599,527.105347 481.014771,532.010925 
	C481.004639,532.004883 481.014526,532.018433 480.757812,532.174805 
	C480.345825,532.893799 480.190582,533.456421 480.048340,534.243958 
	C480.061310,534.468872 480.068146,534.919434 479.621490,534.937622 
	C478.276154,534.990662 477.377502,535.025574 476.478821,535.060425 
	C474.256866,532.568359 472.034912,530.076233 469.644440,527.262817 
	C475.999390,526.650269 482.529968,526.459290 489.041534,525.999512 
	C490.731995,525.880127 492.363098,524.920471 494.384369,524.109741 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M483.421875,596.169434 
	C489.491058,582.787720 489.498077,569.036194 485.834839,555.094849 
	C485.166504,552.551453 484.142059,550.101746 483.198334,546.957947 
	C483.438965,545.922668 483.763245,545.538269 484.087524,545.153809 
	C485.220520,546.452026 486.353485,547.750244 487.629333,549.333740 
	C487.886169,550.027405 488.000122,550.435852 488.056549,551.239502 
	C489.185364,554.464783 490.371704,557.294861 491.775391,560.399414 
	C492.430420,561.744019 492.868042,562.814148 493.153259,564.252563 
	C493.019318,565.411072 493.037750,566.201294 492.808868,567.138916 
	C492.997833,567.882080 493.434143,568.477783 493.898407,569.532715 
	C493.883514,575.912170 493.840668,581.832336 493.452606,587.890625 
	C492.780609,589.705444 492.453827,591.382019 492.078186,593.469788 
	C492.040741,595.261108 492.052124,596.641296 491.805237,598.004395 
	C491.375824,598.007385 491.204681,598.027588 490.669067,598.087891 
	C487.369934,603.110413 487.369934,603.110413 482.501465,600.469116 
	C482.887726,598.779541 483.154785,597.474487 483.421875,596.169434 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M474.923096,540.093811 
	C475.401398,540.193726 476.172699,540.141479 476.315125,540.415833 
	C479.827515,547.180664 483.807648,553.844055 483.897461,561.751160 
	C483.921783,563.892273 482.663208,566.047974 481.998840,568.596680 
	C482.004211,568.996277 482.005157,568.980042 481.703857,569.118042 
	C479.611450,569.450989 477.820282,569.645874 475.975922,569.499512 
	C474.298676,568.106873 472.674652,567.055542 470.972412,565.655945 
	C469.729706,563.229614 468.798645,560.962891 467.322937,559.136169 
	C466.243591,557.800110 464.448151,557.042542 462.983032,555.656982 
	C462.999481,554.548096 463.004852,553.809326 463.360657,553.049194 
	C465.659363,553.445190 467.607605,553.862427 469.311493,554.227356 
	C470.794556,557.176941 472.326263,560.223267 473.857941,563.269592 
	C474.508942,563.150940 475.159973,563.032288 475.810974,562.913696 
	C476.390900,557.806213 476.970825,552.698792 477.587646,547.266602 
	C476.239441,547.174561 475.128998,547.098816 473.835419,546.755981 
	C469.823120,543.512085 475.305450,542.385681 474.923096,540.093811 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M471.050629,566.004211 
	C472.674652,567.055542 474.298676,568.106873 475.708008,569.738770 
	C477.020355,572.427612 478.547394,574.535889 480.765472,577.598206 
	C481.317322,573.761780 481.661255,571.370911 482.005157,568.980042 
	C482.005157,568.980042 482.004211,568.996277 481.995667,568.992615 
	C484.887573,571.606201 486.903412,574.301636 483.521454,577.255127 
	C483.521454,581.569336 483.521454,585.595581 483.521454,589.621887 
	C483.058685,589.695923 482.595917,589.769958 482.133148,589.843994 
	C481.392212,588.948608 480.651276,588.053223 479.910309,587.157837 
	C479.385925,587.418762 478.861572,587.679688 478.337189,587.940552 
	C479.554291,590.379578 480.771423,592.818542 481.856781,595.681763 
	C481.816681,596.070618 481.908325,596.035278 482.000000,596.000000 
	C482.237701,596.014648 482.475433,596.029358 483.067505,596.106812 
	C483.154785,597.474487 482.887726,598.779541 482.276733,600.482117 
	C481.559235,601.132202 481.185608,601.384766 480.811981,601.637329 
	C479.871246,601.758057 478.930542,601.878845 477.713104,602.035156 
	C476.982880,597.355957 476.282776,592.869812 475.242615,588.351135 
	C474.864349,586.557983 474.826080,584.797302 474.848267,582.218140 
	C473.622681,576.267761 472.336670,571.135986 471.050629,566.004211 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M474.687256,539.896667 
	C475.305450,542.385681 469.823120,543.512085 473.776855,547.084473 
	C469.530609,548.114868 465.159821,548.549744 460.439514,548.925842 
	C459.229858,547.598450 458.210571,546.407776 457.543427,545.044678 
	C456.253448,542.408997 455.161957,539.676086 454.348633,537.018188 
	C456.085724,537.880310 457.462372,538.707825 459.897003,540.171326 
	C461.182007,538.659302 463.577576,535.840637 466.290588,533.170288 
	C468.733551,534.541199 470.859009,535.763611 472.984467,536.986084 
	C473.473450,537.890564 473.962463,538.795044 474.687256,539.896667 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M487.486450,549.048462 
	C486.353485,547.750244 485.220520,546.452026 484.087524,545.153809 
	C483.763245,545.538269 483.438965,545.922668 483.092102,546.600159 
	C480.936096,543.195374 478.802673,539.497498 476.574036,535.430054 
	C477.377502,535.025574 478.276154,534.990662 479.861267,535.186157 
	C481.314636,536.611938 482.081573,537.807434 482.941162,539.346680 
	C484.518005,542.809753 486.002228,545.929138 487.486450,549.048462 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M472.892212,536.663208 
	C470.859009,535.763611 468.733551,534.541199 466.150513,532.920532 
	C465.118866,532.041443 464.544830,531.560608 463.970764,531.079773 
	C464.205841,530.134705 464.440948,529.189636 464.986267,528.032593 
	C467.797638,530.660461 470.298798,533.500366 472.892212,536.663208 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M487.629333,549.333740 
	C486.002228,545.929138 484.518005,542.809753 483.329224,539.323425 
	C484.122253,538.815002 484.619781,538.673584 485.425781,538.702148 
	C490.484558,543.172485 495.234833,547.472839 499.985107,551.773193 
	C500.316376,551.514099 500.647644,551.255005 500.978912,550.995972 
	C499.761993,549.248108 498.392487,547.584229 497.372009,545.728394 
	C496.398010,543.957153 495.787628,541.986084 495.334229,540.193970 
	C500.599335,544.328308 505.547668,548.371399 510.606079,552.668701 
	C511.053955,553.086792 511.391663,553.250732 511.729370,553.414673 
	C511.548523,552.889099 511.367706,552.363525 511.027374,551.541504 
	C505.896301,546.172546 500.924683,541.100098 495.891357,535.762695 
	C496.232544,534.987122 496.635468,534.476501 497.259552,534.224365 
	C502.487396,537.215210 507.494110,539.947632 512.500793,542.679993 
	C512.812256,542.163330 513.123657,541.646606 513.435120,541.129883 
	C509.296082,538.415405 505.157043,535.700928 501.027954,532.637024 
	C501.102020,531.596008 501.166107,530.904480 501.523895,530.226868 
	C502.199097,530.331970 502.580566,530.423035 503.027893,530.857788 
	C506.678864,531.980591 510.263977,532.759705 513.849121,533.538818 
	C514.957764,533.520691 516.066406,533.502563 517.814941,533.733276 
	C519.304871,534.006531 520.154846,534.031067 520.983521,534.437683 
	C522.341187,535.547485 523.720154,536.275208 525.389771,537.138184 
	C526.787537,538.430847 527.894714,539.588318 529.015930,541.118652 
	C532.339355,544.377625 535.648743,547.263733 539.062988,550.523132 
	C540.603088,551.333191 542.038330,551.769958 543.397583,552.569336 
	C542.775452,553.921814 542.451172,555.516235 541.649658,555.810242 
	C530.636780,559.851807 530.732361,567.522034 534.370483,576.746826 
	C533.922913,577.126465 533.756897,577.293213 533.205566,577.505554 
	C528.199036,575.778870 523.577759,574.006592 518.875549,572.067017 
	C518.794556,571.899719 518.446594,571.768982 518.316772,571.477234 
	C517.114319,571.455139 516.041748,571.724854 514.750122,572.211487 
	C513.914734,573.247192 513.298462,574.066040 512.380798,574.932251 
	C511.830078,575.465698 511.580780,575.951843 511.233948,576.634399 
	C510.968475,577.077576 510.753357,577.272400 510.166656,577.412659 
	C509.584229,577.956909 509.326233,578.503723 508.919495,579.299927 
	C508.124329,580.257690 507.477905,580.966064 506.527374,581.724121 
	C505.541168,582.226135 504.859100,582.678528 503.802582,583.068420 
	C502.424988,582.801575 501.421844,582.597290 501.295990,582.571594 
	C501.666809,580.211731 502.807678,577.889893 502.087036,576.649475 
	C499.500183,572.196716 496.283966,568.109558 493.305664,563.884216 
	C492.868042,562.814148 492.430420,561.744019 491.900696,560.021057 
	C490.577118,556.526855 489.345612,553.685547 488.114075,550.844238 
	C488.000122,550.435852 487.886169,550.027405 487.629333,549.333740 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M512.112061,520.679077 
	C522.653137,517.221497 528.570679,509.166962 533.260498,499.736115 
	C539.012573,488.168915 540.337952,475.644989 541.626526,463.091705 
	C543.277893,447.004547 542.298584,431.016663 540.322083,414.996552 
	C538.621948,401.216400 534.066711,388.599335 527.208923,376.407959 
	C527.665527,376.012115 527.873169,376.001007 528.359985,376.142944 
	C529.745667,376.885742 530.852173,377.475494 532.011841,378.413147 
	C533.382446,380.840454 534.699951,382.919830 536.101257,385.239929 
	C536.447998,385.685272 536.710876,385.889923 537.162231,386.315643 
	C537.903748,386.681244 538.456909,386.825775 539.005615,387.230164 
	C538.998352,387.663300 538.995483,387.836548 538.989990,388.249390 
	C538.987305,388.489044 538.997559,388.968262 538.623413,389.023193 
	C538.166077,391.328705 537.403381,393.837738 538.140747,395.776215 
	C539.690369,399.850250 538.196838,405.194275 543.248840,408.192719 
	C543.688538,411.285004 543.849731,414.145538 543.997192,417.458923 
	C544.332825,421.302979 544.682251,424.694244 545.024536,428.451111 
	C545.352966,429.902893 545.688599,430.989044 545.859131,432.409241 
	C545.539734,438.021515 545.450134,443.302643 545.216370,448.577332 
	C544.855713,456.712311 544.396301,464.842896 543.706665,473.228760 
	C543.434570,474.920044 543.434570,476.357849 543.434570,478.464844 
	C547.750183,476.372437 548.681152,473.389130 549.221191,470.008087 
	C549.640747,470.002075 549.808594,470.001312 550.359070,470.008728 
	C552.155823,470.641541 553.569885,471.266205 555.218994,471.994720 
	C557.413879,467.889618 553.488098,462.003876 559.612732,459.374817 
	C560.367249,461.176941 561.156128,463.061249 561.734619,465.071533 
	C561.262451,465.416016 561.096313,465.692108 560.713013,465.908630 
	C559.634094,466.551697 558.867920,467.311890 558.076782,468.383026 
	C558.061035,469.109528 558.070251,469.525055 558.173218,470.263489 
	C558.054565,471.381775 558.106873,472.509552 557.589294,472.921600 
	C551.849731,477.491302 551.864258,480.196533 557.564087,485.118927 
	C558.364441,485.810150 558.512817,487.256378 558.963013,488.353149 
	C558.558350,488.643921 558.153687,488.934692 557.749084,489.225464 
	C556.668823,488.413177 554.831482,487.751831 554.634399,486.763458 
	C553.562500,481.386627 549.919678,483.159607 546.952637,483.281586 
	C547.875549,486.913849 548.743896,490.331055 549.630249,493.819397 
	C549.993774,493.775604 550.830994,493.674713 552.205627,493.509094 
	C551.581604,495.182770 551.144653,496.354492 550.784058,497.321564 
	C548.322876,497.043457 546.160095,496.799042 543.168091,496.460938 
	C547.031494,501.513336 544.260315,504.329498 540.875793,507.130768 
	C539.702576,508.101807 539.005249,509.647705 538.044189,510.968750 
	C537.997864,511.006470 537.910095,511.087585 537.521118,511.076233 
	C536.078430,512.808777 535.024780,514.552734 533.738098,516.682495 
	C532.996033,516.752563 531.616211,516.882874 529.964478,517.038879 
	C530.156982,515.473999 530.343262,513.960327 530.529541,512.446655 
	C530.275024,512.356140 530.020508,512.265686 529.765991,512.175232 
	C528.541138,514.091431 527.316345,516.007568 526.091553,517.923767 
	C525.749390,517.725098 525.407288,517.526489 525.065186,517.327820 
	C525.065186,518.507690 525.065186,519.687561 525.065186,520.989380 
	C520.090820,519.111267 520.041504,519.126831 517.016357,523.872375 
	C521.362488,524.070801 525.289856,524.250122 529.472107,524.441040 
	C529.545410,523.584839 529.637939,522.503479 529.686523,521.935242 
	C531.576965,522.762268 533.054993,523.408875 533.606812,523.650269 
	C532.729919,526.448792 531.861877,529.219116 530.996887,531.994751 
	C531.000000,532.000000 530.991333,531.993530 530.600220,531.976929 
	C529.438843,530.789307 528.861206,529.400208 527.847534,528.504761 
	C526.681580,527.474731 525.129944,526.881287 523.745789,526.098267 
	C523.345398,527.252808 523.038879,528.452271 522.515076,529.547791 
	C522.088379,530.440308 521.401672,531.208557 520.596313,531.914185 
	C519.918762,531.528259 519.475098,531.259949 519.031433,530.991699 
	C519.011475,530.566040 518.991516,530.140442 518.984253,529.030579 
	C518.336792,527.558350 517.676697,526.770325 517.016602,525.982300 
	C516.042847,525.691467 515.069153,525.400696 513.656982,524.821899 
	C512.849731,523.248962 512.480896,521.964050 512.112061,520.679077 
M546.189941,481.650726 
	C546.135559,481.027435 546.081116,480.404114 546.026733,479.780823 
	C545.767334,479.845734 545.278564,479.942963 545.282532,479.970825 
	C545.360352,480.524445 545.495300,481.070038 546.189941,481.650726 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M511.861694,520.391846 
	C512.480896,521.964050 512.849731,523.248962 513.338013,524.864990 
	C511.839691,528.611511 509.670197,526.600891 507.676056,525.743225 
	C496.810638,521.070129 485.965607,516.349609 475.089172,511.702576 
	C474.071198,511.267670 472.882812,511.231659 471.496887,510.500427 
	C473.824188,508.151093 476.428131,506.311737 478.949066,504.531036 
	C476.419159,503.241425 474.238495,502.129822 472.057831,501.018250 
	C480.228851,504.190216 488.578613,506.978027 496.508392,510.668335 
	C501.849731,513.154053 506.597870,516.914490 511.861694,520.391846 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M471.729553,500.839539 
	C474.238495,502.129822 476.419159,503.241425 478.949066,504.531036 
	C476.428131,506.311737 473.824188,508.151093 471.130005,510.320007 
	C470.220337,510.440186 469.400848,510.230804 468.271240,510.098175 
	C461.255646,508.131622 454.550262,506.088318 447.777466,503.673950 
	C449.690186,503.023865 451.670227,502.744904 453.214935,502.527252 
	C451.657227,500.153198 450.340210,498.145966 449.256897,496.000366 
	C449.719086,495.584137 449.947510,495.306244 450.175934,495.028320 
	C457.251068,496.905823 464.326172,498.783295 471.729553,500.839539 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M513.815125,533.150146 
	C510.263977,532.759705 506.678864,531.980591 503.010925,530.717529 
	C502.928101,530.233582 502.975159,529.954956 503.312866,529.977112 
	C504.101593,529.998718 504.552643,529.998108 505.347168,530.043274 
	C506.792603,530.394653 507.894531,530.700195 508.996490,531.005798 
	C510.591370,531.591064 512.186218,532.176270 513.815125,533.150146 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M508.962799,530.686646 
	C507.894531,530.700195 506.792603,530.394653 505.343506,529.750610 
	C504.991180,529.216919 504.985931,529.021790 504.980713,528.826721 
	C506.296844,529.340332 507.612976,529.853882 508.962799,530.686646 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M502.945068,530.373901 
	C502.580566,530.423035 502.199097,530.331970 501.218262,530.133667 
	C500.408417,530.001648 500.197968,529.976868 499.987549,529.952087 
	C500.235474,529.158569 500.483429,528.364990 500.869690,527.285339 
	C501.652924,527.406738 502.297791,527.814209 502.973816,528.811646 
	C502.995026,529.585999 502.985077,529.770447 502.975159,529.954956 
	C502.975159,529.954956 502.928101,530.233582 502.945068,530.373901 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M518.741638,531.210327 
	C519.475098,531.259949 519.918762,531.528259 520.619202,532.251831 
	C520.918945,533.156677 520.961914,533.606140 521.004883,534.055603 
	C520.154846,534.031067 519.304871,534.006531 518.235535,533.757202 
	C518.161438,532.831238 518.306641,532.130127 518.741638,531.210327 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M517.013794,526.331665 
	C517.676697,526.770325 518.336792,527.558350 518.949402,528.712891 
	C518.271606,528.279968 517.641357,527.480469 517.013794,526.331665 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M503.312866,529.977112 
	C502.985077,529.770447 502.995026,529.585999 503.012634,529.123047 
	C503.455994,528.849854 503.891693,528.855164 504.654053,528.843628 
	C504.985931,529.021790 504.991180,529.216919 505.000031,529.704834 
	C504.552643,529.998108 504.101593,529.998718 503.312866,529.977112 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M449.023193,496.138733 
	C450.340210,498.145966 451.657227,500.153198 453.214935,502.527252 
	C451.670227,502.744904 449.690186,503.023865 447.419037,503.523132 
	C441.375854,502.867371 435.623749,501.991272 429.447510,501.010101 
	C428.080048,500.016449 427.136719,499.127869 426.436829,498.468628 
	C426.889160,497.553741 427.473663,496.371521 428.415253,494.866089 
	C430.173279,494.716736 431.574158,494.890533 433.027222,495.380066 
	C434.716064,495.500031 436.352753,495.304321 438.325989,495.076965 
	C439.108917,495.099640 439.555237,495.153961 440.128967,495.397705 
	C440.484192,495.814087 440.758698,495.935730 441.359375,496.081055 
	C442.409332,496.061981 443.179810,495.913849 444.329346,495.780273 
	C445.465179,495.845520 446.221954,495.896240 447.064880,496.188293 
	C447.775085,496.332672 448.399139,496.235687 449.023193,496.138733 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M401.155334,500.755463 
	C402.055023,500.250977 403.325378,499.869751 404.799194,499.755402 
	C403.843750,500.307770 402.684845,500.593231 401.155334,500.755463 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M378.833618,461.859436 
	C378.911621,460.506317 378.989594,459.153198 379.449158,457.321350 
	C380.217712,453.894165 380.604675,450.945648 380.991699,447.997162 
	C381.323578,446.255402 381.655487,444.513611 382.264099,442.141052 
	C382.877045,440.122559 383.213287,438.734863 383.753174,436.506531 
	C385.690002,437.370361 387.338409,438.105560 389.039490,439.321625 
	C390.652466,453.831055 388.288116,467.042572 380.200836,478.788696 
	C376.658905,483.933044 372.357239,488.554291 368.176666,493.533630 
	C367.655212,493.831909 367.357666,494.009674 366.728271,494.252075 
	C362.068298,497.065796 357.740173,499.814850 353.412018,502.563873 
	C355.543335,499.024384 357.674652,495.484894 359.879700,491.203369 
	C359.605713,488.998047 359.258026,487.534760 358.953308,486.028625 
	C358.996307,485.985748 359.078247,485.896210 359.362427,485.802216 
	C360.399475,485.455536 361.152344,485.202881 361.963867,485.334656 
	C362.159882,487.000488 362.297180,488.281921 362.535889,490.509308 
	C364.549408,488.061523 365.788727,486.554962 367.276733,484.827850 
	C368.932953,482.325928 370.340485,480.044556 371.951965,477.989227 
	C374.381775,472.763336 376.607697,467.311371 378.833618,461.859436 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M353.240875,502.712952 
	C357.740173,499.814850 362.068298,497.065796 366.726440,494.554077 
	C367.070465,495.393280 367.084503,495.995178 367.098541,496.597076 
	C363.823853,499.137360 360.549194,501.677643 357.117157,504.500366 
	C356.211975,505.195160 355.464172,505.607513 354.401337,506.069153 
	C353.747437,505.032959 353.408569,503.947510 353.240875,502.712952 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M354.963074,481.430969 
	C353.939453,479.377106 352.990906,477.634430 351.966370,475.563751 
	C352.598755,475.096436 353.666321,474.595459 353.953339,474.880463 
	C355.196014,476.114471 356.210632,477.578125 357.649780,479.344116 
	C358.353668,481.785370 358.715942,483.840790 359.078247,485.896210 
	C359.078247,485.896210 358.996307,485.985748 358.705811,485.888824 
	C357.902344,485.559235 357.389374,485.326599 356.876373,485.093933 
	C356.263641,483.976685 355.650909,482.859436 354.963074,481.430969 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M356.889465,485.469055 
	C357.389374,485.326599 357.902344,485.559235 358.662842,485.931702 
	C359.258026,487.534760 359.605713,488.998047 359.862000,490.841125 
	C358.814606,489.428650 357.858612,487.636414 356.889465,485.469055 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M344.675964,515.666870 
	C344.519897,516.416687 344.076599,516.887817 343.298340,517.158630 
	C343.438538,516.434875 343.913635,515.911560 344.675964,515.666870 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M490.001221,268.001221 
	C489.979004,268.474762 490.027893,268.965973 489.910950,269.413879 
	C489.803192,269.826630 489.517273,270.192932 489.043396,271.076477 
	C488.616211,270.277771 488.106354,269.755859 488.205444,269.406830 
	C488.347504,268.906403 488.933868,268.532104 489.664062,268.050751 
	C489.997559,267.997559 490.000000,268.000000 490.001221,268.001221 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M543.473572,552.206787 
	C542.038330,551.769958 540.603088,551.333191 539.324219,550.256165 
	C538.837402,548.311096 538.402771,546.834473 537.502563,545.741333 
	C536.123169,544.066528 534.423096,542.655884 533.153564,540.914856 
	C535.985168,538.325928 538.339539,535.708984 541.110596,533.653625 
	C543.907654,531.579041 548.460205,531.278137 547.665466,526.056702 
	C547.595520,525.597046 548.693909,524.747131 549.406433,524.416565 
	C553.039368,522.730774 554.240173,519.798096 554.229004,515.841858 
	C555.861084,515.656982 557.224609,515.656982 559.177246,515.656982 
	C559.177246,514.198242 558.805664,512.036377 559.285767,510.083984 
	C559.731079,508.272705 561.086060,506.685059 562.281250,505.199951 
	C562.522095,506.470398 562.522095,507.539856 562.522095,509.087646 
	C564.091614,508.452698 565.282776,507.970795 566.353210,507.537750 
	C567.280151,510.292542 568.086853,512.690247 568.932861,515.204590 
	C567.581299,514.958862 566.524780,514.766785 565.468262,514.574707 
	C564.987793,515.199951 564.507263,515.825195 564.026794,516.450439 
	C566.236694,517.469543 568.446533,518.488647 570.656433,519.507751 
	C570.312683,519.949219 569.968933,520.390686 569.625244,520.832092 
	C568.209290,521.264954 566.793396,521.697754 565.377441,522.130554 
	C565.269226,522.645386 565.160950,523.160217 565.052673,523.675110 
	C566.981934,524.411316 568.911194,525.147583 570.840454,525.883850 
	C571.264587,525.348816 571.688721,524.813782 572.112793,524.278748 
	C572.745117,526.172791 573.377441,528.066895 574.009766,529.960938 
	C574.515747,530.579712 575.021729,531.198486 575.803467,531.959656 
	C574.678345,532.102051 573.277405,532.102051 571.291382,532.102051 
	C572.123901,533.780151 573.092651,535.732849 574.624390,538.820251 
	C571.925720,537.220825 570.114441,536.147339 567.167480,534.400757 
	C568.268494,536.828918 568.934998,538.333984 569.631409,539.825012 
	C570.348816,541.360901 571.095459,542.883118 572.069824,544.912354 
	C572.735535,543.561584 573.246521,542.524902 573.854004,541.292358 
	C576.106873,547.033875 575.497375,549.878357 572.201172,552.803955 
	C572.201172,555.149902 572.201172,556.841553 572.201172,559.583557 
	C569.312927,557.490662 567.712036,556.056274 565.855347,555.140808 
	C565.242737,554.838623 563.969543,555.876343 562.608154,556.605103 
	C560.572998,556.929504 558.928162,556.950134 557.283264,556.970764 
	C557.862915,558.658325 558.442566,560.345886 559.022156,562.033447 
	C559.660339,563.775391 560.298584,565.517334 560.606567,567.615967 
	C559.526733,567.984070 558.777161,567.995544 557.695312,567.861084 
	C555.556885,567.618835 553.750671,567.522522 553.243774,567.495544 
	C552.571716,570.484558 552.134521,572.429077 551.687927,574.415161 
	C549.887573,573.925720 548.722351,573.608948 547.098022,573.167358 
	C548.275391,577.033569 550.241211,577.414246 552.717651,575.541016 
	C553.992676,574.576538 554.929932,573.165649 556.010620,571.969849 
	C556.001465,571.983276 556.032959,571.979065 556.054199,572.284241 
	C556.481140,572.921021 556.886902,573.252563 557.292603,573.584167 
	C557.525513,573.059082 557.758484,572.534058 558.339111,572.009277 
	C559.868591,572.585327 561.281616,573.894531 562.191589,573.607788 
	C565.797302,572.471497 565.871521,574.673035 566.049622,577.414062 
	C567.375671,580.688049 564.425354,584.781067 568.625366,586.886719 
	C567.755188,588.580688 566.885010,590.274597 566.014771,591.968567 
	C561.961121,591.977112 557.907532,591.985718 553.260620,591.752502 
	C551.777710,592.027954 550.888062,592.545227 549.998474,593.062561 
	C549.453491,592.878601 548.908569,592.694580 548.458862,592.072510 
	C548.920227,590.639526 549.286316,589.644775 549.652466,588.650024 
	C549.273132,588.556641 548.893799,588.463318 548.514465,588.369934 
	C548.342834,589.238098 548.171204,590.106262 547.999573,590.974365 
	C547.999573,590.974365 547.519531,591.001953 546.937988,591.009583 
	C545.901184,591.010498 545.445923,591.003723 544.990662,590.997009 
	C544.106812,589.305969 543.222961,587.614990 542.442871,586.122559 
	C544.349304,584.658813 546.241577,583.205933 547.367676,582.341309 
	C544.615356,578.821838 542.189636,576.227478 540.503479,573.218628 
	C540.063538,572.433655 541.590210,569.951904 542.795959,568.950989 
	C546.375793,565.979309 550.245911,563.357300 553.855286,560.706726 
	C552.557678,555.646973 551.507080,551.102600 544.868042,552.110718 
	C544.138428,552.359802 543.805969,552.283264 543.473572,552.206787 
M560.178650,521.023499 
	C559.181458,521.027649 557.932068,520.652649 557.238831,521.113770 
	C555.729492,522.117554 553.842407,523.338196 553.358948,524.865662 
	C552.927124,526.229919 553.634277,528.621887 556.590881,527.516235 
	C556.975342,527.372437 558.246399,529.665466 559.147705,530.797119 
	C559.411804,531.128723 559.817200,531.347839 560.405029,531.814819 
	C560.933777,530.360229 561.369507,529.161499 561.787903,528.010620 
	C560.463745,527.062317 559.334839,526.253784 558.483643,525.644165 
	C559.438904,523.871155 560.214417,522.431641 561.276489,520.899963 
	C561.186768,520.936584 561.097107,520.973145 560.178650,521.023499 
M556.218628,534.819336 
	C556.841125,535.437744 557.463684,536.056152 558.086243,536.674561 
	C558.333496,535.815125 558.580750,534.955688 558.828064,534.096252 
	C558.064453,534.125977 557.300842,534.155762 556.218628,534.819336 
M539.816956,542.502136 
	C539.133484,542.125854 538.449951,541.749512 537.766479,541.373230 
	C537.622070,541.944885 537.477600,542.516541 537.333191,543.088196 
	C538.109741,543.118347 538.886230,543.148499 539.816956,542.502136 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M543.397583,552.569397 
	C543.805969,552.283264 544.138428,552.359802 544.729736,552.440796 
	C546.532410,554.436401 549.684753,556.899963 549.269104,558.315857 
	C548.515259,560.883301 546.020081,563.514832 543.563232,564.885315 
	C538.671631,567.614197 537.376709,569.570190 538.936646,575.070679 
	C539.339172,576.489868 539.989868,577.837036 540.431213,579.247131 
	C541.643921,583.121826 540.782837,584.082703 536.915527,583.361267 
	C535.299866,583.059753 533.653259,582.923706 531.346191,582.577759 
	C530.296570,582.374451 529.921448,582.305725 529.420044,581.988037 
	C528.655823,581.403931 528.018005,581.068848 527.333130,580.698059 
	C527.286133,580.662354 527.173218,580.627686 527.017029,580.323242 
	C523.002197,577.261230 520.082764,577.925049 517.647400,582.114258 
	C516.710571,583.725830 514.838989,584.780518 513.446838,586.143555 
	C509.174591,590.326416 506.090393,589.332458 504.177063,583.130920 
	C504.859100,582.678528 505.541168,582.226135 506.601837,582.091614 
	C507.587738,583.588867 508.195038,584.768311 508.802368,585.947693 
	C509.719910,586.540833 510.637482,587.133911 511.555023,587.727051 
	C514.236816,583.012024 516.918579,578.296997 519.600403,573.581909 
	C520.117798,574.138184 520.635254,574.694458 521.152649,575.250793 
	C520.420593,574.245300 519.688599,573.239807 518.956543,572.234314 
	C523.577759,574.006592 528.199036,575.778870 533.435547,577.664185 
	C534.251221,577.362793 534.451599,576.948364 534.651978,576.533936 
	C530.732361,567.522034 530.636780,559.851807 541.649658,555.810242 
	C542.451172,555.516235 542.775452,553.921814 543.397583,552.569397 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M529.546387,582.237061 
	C529.921448,582.305725 530.296570,582.374451 530.925781,582.578796 
	C532.087708,583.906128 532.995483,585.097839 533.916809,586.995239 
	C531.960022,590.451660 529.989746,593.202393 528.019531,595.953186 
	C527.700073,596.436218 527.380554,596.919250 527.033630,597.704834 
	C526.566284,598.002502 526.126343,597.997681 525.357056,597.986877 
	C525.027649,597.980896 525.008362,597.985413 525.007324,597.608887 
	C525.000488,596.491516 524.994629,595.750610 525.247864,594.808960 
	C526.198853,593.337952 526.890808,592.067810 527.696106,590.589417 
	C524.367126,589.699890 521.181824,588.848694 518.050964,587.635864 
	C520.468079,584.158264 523.003052,581.277527 527.173218,580.627686 
	C527.173218,580.627686 527.286133,580.662354 527.194092,581.044006 
	C526.420532,582.585327 525.739014,583.744995 525.057495,584.904663 
	C525.332581,585.156799 525.607605,585.408997 525.882690,585.661194 
	C527.103882,584.519836 528.325134,583.378418 529.546387,582.237061 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M566.115234,592.231323 
	C566.885010,590.274597 567.755188,588.580688 568.625366,586.886719 
	C564.425354,584.781067 567.375671,580.688049 566.377441,577.408997 
	C567.097534,577.088989 567.532043,577.131775 568.119324,577.485474 
	C568.759583,580.111267 569.247131,582.426086 569.734741,584.740967 
	C571.639038,584.195190 573.543274,583.649475 575.574951,583.067200 
	C575.891724,583.710144 576.445984,584.835205 577.000244,585.960327 
	C576.038147,586.736328 575.076111,587.512268 574.056152,588.605347 
	C571.913086,590.614563 569.827881,592.306702 567.373596,593.988525 
	C566.741577,593.483398 566.478699,592.988770 566.115234,592.231323 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M528.395203,595.925537 
	C529.989746,593.202393 531.960022,590.451660 533.968811,587.344055 
	C535.398682,587.277161 536.790039,587.567200 538.168213,587.854492 
	C538.465942,591.792358 537.669678,594.217163 533.301392,594.169678 
	C531.797729,594.153259 530.281433,595.288696 528.395203,595.925537 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M544.680725,590.870972 
	C545.445923,591.003723 545.901184,591.010498 546.698364,591.028564 
	C545.258301,592.346741 543.476379,593.653625 541.335327,594.606689 
	C540.635803,593.481689 540.295532,592.710632 539.955200,591.939514 
	C541.427063,591.541321 542.898926,591.143188 544.680725,590.870972 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M550.208130,593.303650 
	C550.888062,592.545227 551.777710,592.027954 552.833679,591.755371 
	C552.139282,592.514893 551.278503,593.029846 550.208130,593.303650 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M548.002808,591.235107 
	C548.171204,590.106262 548.342834,589.238098 548.514465,588.369934 
	C548.893799,588.463318 549.273132,588.556641 549.652466,588.650024 
	C549.286316,589.644775 548.920227,590.639526 548.298950,591.825195 
	C548.031189,591.842590 548.018616,591.669250 548.002808,591.235107 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M539.701538,592.149841 
	C540.295532,592.710632 540.635803,593.481689 540.962646,594.587036 
	C540.448730,594.067505 539.948303,593.213867 539.701538,592.149841 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M527.017029,580.323242 
	C523.003052,581.277527 520.468079,584.158264 517.832886,587.902832 
	C511.877594,593.105652 506.154846,593.974548 501.615326,588.974548 
	C499.684662,590.705017 498.059998,591.716309 497.070374,593.162231 
	C495.866821,594.920715 495.198242,597.045349 494.298279,599.011597 
	C494.876892,599.028564 495.455505,599.045471 496.034119,599.062378 
	C495.769714,599.851868 495.505310,600.641296 495.126190,601.726562 
	C494.073212,602.825073 493.134949,603.627625 492.090912,603.821655 
	C492.026917,602.475098 492.068726,601.737183 492.333557,600.801636 
	C492.392242,599.743164 492.227905,598.882324 492.063538,598.021484 
	C492.052124,596.641296 492.040741,595.261108 492.445679,593.381348 
	C493.173920,591.171936 493.485870,589.462219 493.797821,587.752441 
	C493.840668,581.832336 493.883514,575.912170 493.947601,569.222717 
	C493.664642,567.966064 493.360443,567.478821 493.056213,566.991516 
	C493.037750,566.201294 493.019318,565.411072 493.153259,564.252563 
	C496.283966,568.109558 499.500183,572.196716 502.087036,576.649475 
	C502.807678,577.889893 501.666809,580.211731 501.295990,582.571594 
	C501.421844,582.597290 502.424988,582.801575 503.802582,583.068420 
	C506.090393,589.332458 509.174591,590.326416 513.446838,586.143555 
	C514.838989,584.780518 516.710571,583.725830 517.647400,582.114258 
	C520.082764,577.925049 523.002197,577.261230 527.017029,580.323242 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M496.336792,599.249268 
	C495.455505,599.045471 494.876892,599.028564 494.298279,599.011597 
	C495.198242,597.045349 495.866821,594.920715 497.070374,593.162231 
	C498.059998,591.716309 499.684662,590.705017 501.615326,588.974548 
	C506.154846,593.974548 511.877594,593.105652 517.778442,588.264526 
	C521.181824,588.848694 524.367126,589.699890 527.696106,590.589417 
	C526.890808,592.067810 526.198853,593.337952 524.860596,594.846558 
	C521.762817,595.382324 519.311462,595.679565 516.457336,596.025635 
	C516.201904,596.569336 515.678101,597.684509 515.154236,598.799683 
	C514.969360,598.792236 514.784424,598.784851 514.306824,598.476685 
	C513.964661,597.748779 513.915222,597.321716 513.865784,596.894714 
	C512.585449,596.403809 511.305084,595.912903 509.568848,595.247253 
	C509.568848,597.867737 509.568848,599.338928 509.568848,600.810059 
	C509.233856,600.962280 508.898895,601.114563 508.563904,601.266785 
	C507.607483,599.984619 506.651031,598.702515 504.984253,596.468140 
	C504.193298,598.506592 503.624420,599.972656 502.613892,602.577026 
	C500.774323,601.609924 498.706879,600.523010 496.336792,599.249268 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M515.115112,599.181213 
	C515.678101,597.684509 516.201904,596.569336 516.457336,596.025635 
	C519.311462,595.679565 521.762817,595.382324 524.601501,595.047424 
	C524.994629,595.750610 525.000488,596.491516 525.012817,597.602295 
	C524.213074,598.234924 523.406799,598.497681 522.200989,598.712769 
	C521.532715,598.804443 521.263916,598.943665 520.995178,599.082947 
	C519.703735,600.236572 518.412231,601.390198 516.511414,603.088074 
	C515.991882,601.812073 515.533997,600.687378 515.115112,599.181213 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M513.549622,596.970703 
	C513.915222,597.321716 513.964661,597.748779 514.029785,598.493958 
	C513.463318,598.948486 512.881104,599.084839 512.298950,599.221130 
	C512.610474,598.496338 512.921936,597.771545 513.549622,596.970703 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M521.246582,599.063599 
	C521.263916,598.943665 521.532715,598.804443 521.900757,598.831360 
	C521.832642,599.013184 521.665283,599.028748 521.246582,599.063599 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M331.009277,471.005829 
	C334.856384,471.215271 334.604492,473.453979 333.512329,477.006195 
	C332.556854,474.712891 331.917755,473.178986 331.147583,471.331146 
	C331.016510,471.017242 331.000000,471.000305 331.009277,471.005829 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M388.993774,583.978088 
	C389.162933,583.429260 389.332123,582.880493 390.116211,582.158325 
	C392.149994,580.327332 393.568878,578.669739 395.039246,577.347778 
	C396.724884,577.453796 398.358978,577.224121 400.131348,576.943787 
	C400.181458,576.930969 400.093323,576.968811 399.588867,577.011597 
	C397.786011,577.006653 396.399445,576.996826 395.016541,576.591064 
	C395.211884,574.137939 395.403564,572.080750 395.498505,571.061829 
	C391.348389,569.950317 387.713074,568.976624 383.710876,567.997864 
	C382.218323,567.992737 381.092651,567.992737 381.323151,567.992737 
	C380.016724,570.744934 379.084229,572.709351 378.151733,574.673828 
	C377.912933,574.269226 377.674133,573.864563 377.435333,573.459900 
	C376.292328,573.637878 375.149353,573.815857 373.768005,574.152832 
	C373.050873,577.270386 371.346832,574.921265 370.308777,574.726257 
	C371.729675,572.164795 373.897308,569.887024 369.628235,567.950928 
	C368.226166,567.321228 367.098419,566.834778 365.638672,566.305542 
	C364.873566,566.166626 364.440399,566.070496 364.007355,565.591675 
	C362.171814,562.757690 360.336182,560.306335 358.500549,557.854919 
	C358.131104,558.139343 357.761658,558.423767 357.392212,558.708130 
	C357.889252,560.223877 358.386322,561.739624 358.925476,563.383728 
	C355.685760,563.119934 352.932373,562.378296 350.383240,562.818787 
	C347.176331,563.372864 344.136444,564.893799 341.016785,565.557739 
	C340.878754,563.647400 340.748474,562.176819 340.718964,560.365967 
	C342.229492,557.685059 343.639191,555.344543 345.407349,552.995605 
	C346.451385,552.257385 347.136932,551.527588 347.922211,550.512085 
	C348.696533,549.491943 349.371155,548.757629 350.420319,548.024048 
	C351.830750,546.661865 352.866608,545.299011 353.935333,543.944702 
	C353.968170,543.953369 353.930084,543.897095 354.327393,543.930359 
	C357.615326,544.287170 359.212982,543.176636 359.335815,539.980103 
	C360.069427,539.767090 360.492798,539.660156 361.032104,539.834351 
	C361.991425,540.043884 362.834778,539.972229 363.997253,539.873413 
	C363.997253,541.942871 363.997253,543.976318 363.994141,546.347900 
	C363.979645,547.360596 363.968231,548.035217 363.661316,548.766724 
	C362.971527,549.453674 362.577271,550.083618 362.213440,551.097168 
	C364.395142,557.406921 366.501343,558.363708 371.345520,555.469849 
	C372.234711,555.621765 372.737488,555.743591 373.189392,556.234009 
	C373.807007,561.803040 378.885590,558.769775 381.190338,560.915344 
	C382.373138,557.923889 383.555939,554.932434 384.607605,552.272644 
	C385.775543,552.438049 387.871490,552.734863 389.990997,553.398438 
	C392.649536,559.182495 398.236755,559.946167 403.304382,561.992676 
	C403.716980,561.322693 403.827759,560.664734 403.942810,559.677734 
	C403.977081,558.911255 404.007080,558.473755 404.369934,557.977295 
	C404.466614,556.952148 404.230469,555.985901 403.719604,554.845398 
	C402.008179,553.056396 400.571472,551.441589 398.960144,549.538208 
	C397.243805,547.002380 395.702118,544.755188 394.500061,542.418396 
	C397.205719,543.276245 399.571716,544.223633 401.939331,545.549316 
	C402.020935,547.290466 402.100922,548.653198 402.148743,549.468567 
	C403.422211,548.433960 404.641602,547.443237 406.151825,546.412720 
	C406.942505,546.623779 407.442322,546.874695 408.005524,547.449341 
	C408.686127,549.572021 409.303375,551.370850 409.920593,553.169739 
	C410.029144,552.761475 410.137726,552.353271 410.246277,551.945007 
	C411.831665,552.627258 413.417053,553.309570 414.966553,554.348633 
	C411.772644,557.933411 414.229095,560.410034 416.024811,563.220703 
	C416.012573,563.467957 415.992828,563.962769 415.870819,564.248962 
	C415.166901,565.356812 414.584961,566.178528 413.771393,567.136597 
	C407.883148,569.645203 413.753540,573.780640 411.372955,576.653931 
	C411.815369,576.872498 412.257751,577.091003 412.700134,577.309570 
	C413.833832,575.847534 414.967499,574.385437 416.311066,572.933044 
	C416.520935,572.942688 416.941101,572.939697 417.085693,573.263367 
	C417.423279,575.271301 417.616211,576.955566 417.756470,578.180054 
	C420.081421,578.427734 422.185486,578.651855 424.455872,578.893738 
	C424.091675,577.419128 423.729645,575.953369 423.367615,574.487549 
	C423.677704,574.298523 423.987823,574.109558 424.297913,573.920593 
	C425.320068,575.066772 426.342224,576.212952 427.176910,577.148926 
	C428.944550,576.706848 430.458130,576.328247 433.011566,575.689636 
	C430.681152,574.435425 429.344696,573.716187 428.276306,572.809448 
	C429.643829,572.090332 430.743317,571.558655 432.159637,571.162476 
	C433.979828,572.211487 435.483185,573.125122 437.041382,574.376404 
	C439.387451,574.473816 441.678680,574.233521 444.340302,573.992493 
	C447.813202,574.998901 450.915680,576.006042 454.044250,577.379028 
	C456.498627,582.056824 452.610687,583.833313 450.588196,586.591370 
	C452.495331,585.990906 453.993927,584.966858 455.533600,584.008789 
	C457.963379,582.496826 459.933136,582.121704 459.735596,586.149292 
	C459.313324,586.862549 459.143738,587.418518 458.614655,587.983887 
	C456.257202,588.265442 454.259308,588.537537 452.261383,588.809631 
	C452.385376,589.454102 452.509399,590.098572 452.633392,590.743042 
	C454.523071,589.130737 458.877808,594.745850 459.269958,587.851196 
	C459.682587,587.128540 459.847198,586.570374 460.327087,585.978638 
	C461.416992,585.621460 462.191681,585.297791 462.967712,585.369385 
	C463.201782,586.696960 463.228638,588.310974 463.699951,588.453308 
	C467.312805,589.544434 467.112793,591.817200 466.120972,594.663208 
	C465.756042,595.710388 465.969940,596.959167 465.563416,598.109009 
	C461.618530,601.057556 457.624207,602.140076 453.082153,600.400757 
	C455.180725,598.550476 457.286377,597.026672 459.302612,595.567566 
	C451.335785,592.570984 446.268677,593.245483 443.930420,597.713440 
	C443.629608,597.355286 443.328796,596.997131 443.027985,596.638977 
	C441.663544,597.454529 440.299103,598.270081 438.934692,599.085693 
	C435.892151,600.008118 432.849579,600.930481 429.406982,601.974121 
	C429.358215,601.778137 428.981354,600.263855 428.542267,598.499756 
	C427.570526,598.499756 426.537903,598.499756 425.253143,598.499756 
	C425.819489,597.380798 426.233093,596.563660 427.087280,594.875977 
	C425.377777,595.697021 423.626923,596.180420 423.645569,596.581665 
	C424.035309,604.972107 420.733063,599.992981 418.101471,597.659729 
	C417.405731,599.226685 416.845612,600.488220 415.940643,602.526367 
	C414.117859,601.744019 412.380676,600.820129 410.529846,600.303345 
	C409.907104,600.129578 408.995483,600.990417 408.094879,601.670898 
	C406.954712,600.213745 405.935547,598.464111 405.325409,597.416687 
	C403.584534,598.584167 401.629730,599.895203 399.383789,601.069092 
	C398.901398,599.766602 398.710144,598.601318 398.259094,596.797607 
	C397.875549,593.798157 398.659882,590.605347 397.427429,589.259644 
	C395.268280,586.901978 391.871857,585.677368 388.993774,583.978088 
M407.015320,581.584961 
	C407.024384,582.020081 407.033447,582.455200 406.511993,583.355164 
	C405.186493,585.001404 403.860992,586.647644 402.535461,588.293823 
	C402.821045,588.761536 403.106598,589.229248 403.392151,589.696960 
	C405.272827,588.797852 407.153503,587.898743 409.671906,587.059265 
	C409.922211,588.532410 410.172516,590.005493 410.623535,592.659851 
	C414.234924,594.592468 412.551971,589.189819 413.979248,587.935059 
	C415.862091,586.279785 417.003723,583.781433 418.373627,581.777710 
	C417.361206,580.466553 416.383362,579.200134 416.159454,578.910095 
	C413.528046,579.668457 411.249359,580.325134 408.700226,580.538452 
	C408.142273,580.669678 407.584320,580.800903 407.015320,581.584961 
M396.654510,585.530823 
	C397.299316,585.046509 397.944122,584.562256 398.588928,584.077942 
	C398.283905,583.786255 397.978851,583.494568 397.673828,583.202881 
	C397.197784,583.814636 396.721771,584.426392 396.654510,585.530823 
M403.982574,581.758118 
	C403.821045,582.275574 403.647827,582.790405 403.532898,583.318054 
	C403.527252,583.343994 404.030487,583.480896 404.296722,583.566101 
	C404.371552,582.929321 404.446350,582.292480 403.982574,581.758118 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M438.907928,599.438843 
	C440.299103,598.270081 441.663544,597.454529 443.027985,596.638977 
	C443.328796,596.997131 443.629608,597.355286 443.930420,597.713440 
	C446.268677,593.245483 451.335785,592.570984 459.302612,595.567566 
	C457.286377,597.026672 455.180725,598.550476 452.859253,600.666138 
	C452.728241,603.127808 452.813049,604.997620 452.897827,606.867432 
	C452.347809,607.037537 451.797791,607.207581 451.130432,607.181641 
	C451.013062,604.245667 451.013062,601.505676 451.013062,597.269836 
	C447.789673,602.157227 444.739258,604.757446 440.131287,602.841309 
	C439.415649,602.543762 439.283264,600.843628 438.907928,599.438843 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M388.740143,584.201172 
	C391.871857,585.677368 395.268280,586.901978 397.427429,589.259644 
	C398.659882,590.605347 397.875549,593.798157 397.990356,596.564087 
	C397.238190,596.994080 396.495026,597.019165 395.380737,597.069336 
	C395.141846,591.922913 391.693878,589.961731 387.316376,587.774292 
	C386.860413,591.289856 394.692810,594.000427 387.183777,596.759583 
	C386.248566,596.987366 385.500580,596.964722 384.379211,596.918701 
	C385.573975,592.765137 385.111603,587.905334 388.740143,584.201172 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M391.930908,599.612122 
	C391.635620,599.247009 391.592285,598.888062 391.548950,598.529053 
	C391.805450,598.535400 392.061920,598.541809 392.318420,598.548218 
	C392.273254,598.904907 392.228058,599.261536 391.930908,599.612122 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M404.258545,602.019409 
	C404.427490,601.906250 404.595520,601.961121 404.763519,602.015991 
	C404.594879,602.073181 404.426208,602.130371 404.258545,602.019409 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M492.110535,600.999207 
	C492.068726,601.737183 492.026917,602.475098 491.909668,603.574646 
	C490.233063,605.392212 488.631897,606.848267 487.731781,607.666809 
	C485.632629,606.023499 483.314941,604.209045 480.904602,602.015991 
	C481.185608,601.384766 481.559235,601.132202 482.157593,600.866699 
	C487.369934,603.110413 487.369934,603.110413 490.677002,598.424438 
	C491.403107,599.480347 491.756836,600.239746 492.110535,600.999207 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M491.045685,614.389771 
	C491.290405,614.789856 491.288940,615.187439 491.258484,615.582886 
	C491.257599,615.594238 490.948547,615.581909 490.783173,615.580627 
	C490.787262,615.182739 490.791321,614.784851 491.045685,614.389771 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M512.998108,611.997559 
	C512.902893,612.031555 512.809509,612.068054 512.860535,612.054199 
	C513.004883,612.003906 513.000000,612.000000 512.998108,611.997559 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M465.919830,598.116028 
	C465.969940,596.959167 465.756042,595.710388 466.120972,594.663208 
	C467.112793,591.817200 467.312805,589.544434 463.699951,588.453308 
	C463.228638,588.310974 463.201782,586.696960 463.327515,585.362915 
	C466.039520,584.800537 466.692871,583.889587 465.016968,581.568115 
	C464.678558,579.790894 464.337952,578.395447 464.007019,576.606567 
	C461.784149,571.465332 459.551636,566.717651 457.269531,561.600464 
	C453.796509,556.839172 450.373138,552.447205 446.657776,547.888916 
	C445.049774,546.009338 443.733765,544.296082 442.573120,542.203735 
	C444.151062,540.880493 445.573700,539.936462 447.295288,539.022827 
	C447.991852,539.046631 448.389526,539.040100 448.915771,539.349670 
	C451.043091,542.095764 453.055298,544.514771 455.037048,546.958557 
	C457.479828,549.970825 459.897217,553.003662 462.322510,556.335938 
	C462.560211,557.426453 462.800964,558.208740 463.041748,558.990967 
	C464.884705,566.386169 467.310364,573.698059 468.384003,581.203369 
	C469.223480,587.071472 468.385834,593.179504 467.822021,599.494263 
	C466.873688,599.243713 466.396759,598.679871 465.919830,598.116028 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M465.563416,598.109009 
	C466.396759,598.679871 466.873688,599.243713 467.625946,599.869568 
	C467.649445,601.682739 467.397583,603.434021 466.980164,605.863037 
	C466.590454,607.391602 466.366364,608.242310 466.142273,609.093079 
	C466.158356,609.510010 466.174469,609.926880 466.246826,610.653076 
	C465.392883,611.865417 464.482635,612.768494 463.294067,613.834717 
	C462.456055,611.186646 461.896332,608.375366 461.336639,605.564148 
	C460.785339,605.572632 460.234070,605.581177 459.682800,605.589722 
	C458.989410,606.911987 458.296021,608.234253 457.354218,610.030212 
	C455.941803,608.908752 454.760956,607.971130 453.238953,606.950439 
	C452.813049,604.997620 452.728241,603.127808 452.866364,600.992615 
	C457.624207,602.140076 461.618530,601.057556 465.563416,598.109009 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M463.405396,559.017822 
	C462.800964,558.208740 462.560211,557.426453 462.483643,556.343262 
	C462.647858,556.042236 462.971924,556.027100 462.971924,556.027100 
	C464.448151,557.042542 466.243591,557.800110 467.322937,559.136169 
	C468.798645,560.962891 469.729706,563.229614 470.972412,565.655945 
	C472.336670,571.135986 473.622681,576.267761 474.764221,581.794434 
	C473.748291,581.113281 472.515656,580.169189 472.066437,578.938965 
	C469.830139,572.814697 467.836609,566.602112 465.640564,560.462402 
	C465.419922,559.845642 464.411743,559.510559 463.405396,559.017822 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M466.452148,609.020020 
	C466.366364,608.242310 466.590454,607.391602 466.988770,606.246826 
	C467.029327,606.950928 466.895660,607.948975 466.452148,609.020020 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M346.059937,309.727203 
	C346.507996,306.950775 346.941559,304.563446 347.409454,301.987152 
	C348.236725,304.009949 349.298523,305.921875 349.730957,307.966888 
	C349.931915,308.917145 348.909973,310.125977 348.442413,311.217590 
	C347.643433,310.850494 346.844452,310.483398 346.059937,309.727203 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M350.376923,276.958801 
	C346.556305,275.851990 348.349609,273.205414 348.681946,271.015320 
	C350.161957,271.149048 351.343384,271.325531 352.710327,271.810974 
	C352.159302,273.721375 351.422791,275.322784 350.376923,276.958801 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M349.993958,319.654602 
	C348.236816,321.135010 346.474884,322.268768 344.155670,323.761169 
	C344.500702,322.323212 344.705505,321.469818 344.940918,320.297119 
	C345.856262,319.335571 346.670074,318.384033 347.650452,318.159363 
	C348.307739,318.008728 349.201294,318.888977 349.993958,319.654602 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M365.751190,291.182312 
	C366.327942,291.095520 366.577148,291.299133 366.881531,291.788025 
	C366.299866,294.250275 370.133026,297.309113 365.397827,298.894043 
	C365.187103,296.398804 365.333313,293.901550 365.751190,291.182312 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M351.305237,314.507568 
	C350.949890,313.724213 350.856842,313.076874 350.763794,312.429504 
	C351.299133,312.653290 351.834473,312.877106 352.369751,313.100891 
	C352.102356,313.615112 351.834961,314.129333 351.305237,314.507568 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M356.995300,303.995331 
	C356.990723,303.026306 356.990723,302.061859 356.990723,299.776672 
	C358.035004,301.681458 358.490326,302.511932 358.972839,303.671204 
	C359.000000,304.000000 358.997559,304.002441 358.997559,304.002441 
	C358.555481,304.001892 358.113373,304.001343 357.339905,304.004669 
	C357.008545,304.008545 356.999878,303.999878 356.995300,303.995331 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M344.008911,311.983307 
	C343.462341,311.388214 342.913696,310.808014 342.365021,310.227783 
	C342.592773,310.050903 342.820526,309.873993 343.048279,309.697083 
	C343.526855,310.347626 344.005402,310.998138 344.256348,311.807556 
	C344.028717,311.966431 344.006805,311.998199 344.008911,311.983307 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M359.001221,303.998779 
	C359.127014,304.143250 359.251556,304.288940 359.376099,304.434662 
	C359.286652,304.385376 359.197205,304.336090 359.052673,304.144623 
	C358.997559,304.002441 359.000000,304.000000 359.001221,303.998779 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M352.152374,268.229858 
	C352.442596,268.011810 352.872589,268.015045 353.623718,268.028076 
	C353.393982,268.175629 352.843048,268.313385 352.152374,268.229858 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M357.439270,274.521240 
	C357.407623,274.468597 357.470886,274.573883 357.439270,274.521240 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M365.045959,275.150574 
	C365.124023,275.152008 365.248047,275.304047 365.372070,275.456055 
	C365.278687,275.404419 365.185333,275.352783 365.045959,275.150574 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M333.447144,602.048706 
	C333.447144,602.048706 333.493652,602.001038 333.469696,601.608765 
	C332.957458,598.483276 332.469208,595.750000 332.005188,592.638550 
	C331.951691,591.761108 331.873962,591.261841 331.776947,590.401855 
	C331.941559,589.348389 332.125427,588.655640 332.531372,587.857910 
	C333.193146,587.523438 333.632843,587.294006 334.117432,587.476929 
	C334.162354,589.741394 334.162354,591.593506 334.162354,593.774292 
	C335.725586,592.386780 336.663452,591.554260 337.717987,591.048218 
	C338.223572,591.919250 338.612518,592.463806 338.937561,593.334900 
	C338.751465,594.088379 338.629272,594.515442 338.205383,595.033691 
	C338.203827,596.190491 338.180634,597.857605 338.857727,598.221497 
	C342.557434,600.209595 347.899445,598.309998 351.922424,593.889099 
	C351.945526,593.892761 351.891479,593.868042 352.235168,593.899109 
	C353.459473,593.701294 354.340118,593.472473 355.148499,593.608276 
	C353.507233,596.880920 351.938293,599.788940 350.379150,602.678711 
	C352.555176,603.898682 353.777679,604.584045 355.000214,605.269409 
	C355.000214,605.269409 355.041351,605.506165 354.794922,605.850952 
	C354.334259,606.811829 354.120056,607.428040 353.905853,608.044189 
	C353.037354,609.966431 352.168884,611.888611 351.300385,613.810852 
	C350.736816,613.742920 350.173248,613.674927 349.609711,613.606995 
	C349.060791,611.614380 348.511871,609.621826 348.272583,607.273193 
	C348.904663,606.611938 349.227142,606.306641 349.549591,606.001404 
	C349.338165,605.819397 349.126770,605.637329 348.915344,605.455322 
	C348.611755,605.964966 348.308197,606.474609 348.004639,606.984314 
	C346.830719,607.422791 345.656769,607.861267 344.176514,608.414124 
	C344.275940,607.023560 344.368011,605.735962 344.478821,604.186340 
	C342.638458,605.281128 341.216675,606.126892 339.248108,606.671753 
	C336.949890,604.930176 335.198517,603.489441 333.447144,602.048706 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M355.389954,605.195801 
	C353.777679,604.584045 352.555176,603.898682 350.379150,602.678711 
	C351.938293,599.788940 353.507233,596.880920 355.453644,593.509033 
	C356.217072,592.366028 356.603027,591.686890 356.994751,591.005188 
	C357.000519,591.002625 357.010406,590.990662 357.349426,591.015503 
	C358.984772,590.206665 360.281067,589.373047 361.577332,588.539429 
	C362.698059,590.444336 363.818726,592.349243 365.124329,594.568420 
	C367.269501,594.568420 369.949646,594.568420 373.030518,594.568420 
	C371.912994,596.270203 370.994904,597.668274 369.804169,599.481567 
	C369.743622,599.451538 368.695190,598.932129 367.646790,598.412720 
	C367.423859,598.723267 367.200958,599.033813 366.978058,599.344360 
	C367.756134,599.906616 368.534241,600.468811 369.670227,601.056580 
	C369.802429,601.343994 369.576752,601.605957 369.119110,601.995239 
	C368.016785,603.847778 367.146423,605.572937 366.276093,607.298035 
	C365.693359,607.106506 365.110657,606.914917 364.527954,606.723389 
	C364.527954,604.126831 364.527954,601.530334 364.527954,598.933777 
	C362.843414,603.047119 359.497040,604.335999 355.389954,605.195801 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M333.470001,602.412598 
	C335.198517,603.489441 336.949890,604.930176 338.892365,606.663208 
	C338.478302,607.446167 337.873108,607.936707 337.110291,608.190002 
	C335.799408,606.227295 334.646118,604.501892 333.470001,602.412598 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M354.203369,607.872131 
	C354.120056,607.428040 354.334259,606.811829 354.800049,605.970947 
	C354.868042,606.397461 354.684479,607.048767 354.203369,607.872131 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M332.309265,587.962830 
	C332.125427,588.655640 331.941559,589.348389 331.526062,590.527344 
	C331.523254,591.681213 331.752106,592.348999 331.980927,593.016724 
	C332.469208,595.750000 332.957458,598.483276 333.464966,601.641724 
	C333.065216,601.897766 332.646271,601.728516 332.161926,600.871521 
	C331.654663,599.484924 331.212799,598.786011 330.770935,598.087158 
	C329.995697,596.533875 329.220459,594.980530 328.224609,592.928589 
	C327.843018,591.877869 327.682068,591.325806 327.521088,590.773743 
	C327.294250,589.452942 327.067383,588.132080 326.765808,586.414917 
	C325.804657,574.655945 328.153412,563.988647 333.715454,553.619873 
	C339.627228,545.404968 345.393127,537.649963 354.280579,532.788147 
	C366.635559,526.029358 379.761963,522.989929 394.233093,523.346985 
	C394.702332,523.304382 394.773804,523.291504 394.898132,523.509521 
	C395.341736,523.776062 395.661041,523.824524 396.377350,523.953979 
	C397.851868,523.997559 398.929321,523.960022 400.006805,523.922546 
	C400.415497,524.033264 400.824219,524.143982 401.909760,524.362427 
	C413.847534,526.594177 424.303497,530.697205 433.595642,537.414246 
	C431.894073,537.490295 430.393921,537.317993 428.722015,537.043457 
	C428.550232,536.941223 428.187012,536.774292 428.087189,536.438477 
	C423.668915,534.602417 419.350464,533.102173 414.736877,531.623047 
	C414.047913,531.632141 413.654022,531.620178 413.110535,531.284363 
	C410.246887,530.402527 407.532867,529.844604 404.417297,529.253784 
	C402.671661,529.214233 401.327515,529.207520 399.785828,528.862000 
	C397.365295,528.606812 395.142242,528.690430 392.527405,528.747986 
	C391.089539,528.753662 390.043518,528.785339 388.765869,528.591187 
	C387.356812,528.586426 386.179321,528.807495 384.574158,529.030823 
	C378.149048,530.101257 372.151642,531.169434 365.788757,532.199463 
	C363.958038,532.844238 362.270111,533.258850 361.066803,534.257446 
	C356.281616,538.228882 351.664459,542.401917 346.953918,546.464172 
	C339.116608,553.222778 333.743927,561.322815 332.841095,572.209839 
	C332.466797,572.963562 332.192810,573.450989 331.645935,573.968506 
	C331.243958,574.664307 331.114899,575.329956 330.911255,576.311523 
	C330.717865,577.043335 330.599060,577.459229 330.315613,578.086304 
	C330.379211,578.893494 330.607452,579.489624 330.753418,580.451782 
	C330.667816,582.482605 330.664520,584.147400 330.706482,586.093994 
	C331.270874,586.904846 331.790070,587.433838 332.309265,587.962830 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M327.224915,590.912842 
	C327.682068,591.325806 327.843018,591.877869 327.975159,592.721375 
	C327.370453,593.041870 326.794556,593.070862 326.218658,593.099854 
	C326.455353,592.417236 326.692047,591.734619 327.224915,590.912842 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M330.795166,598.404907 
	C331.212799,598.786011 331.654663,599.484924 332.082336,600.524780 
	C331.651947,600.151367 331.235687,599.437073 330.795166,598.404907 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M641.135620,369.543701 
	C641.191040,372.111084 641.228577,374.253204 640.838745,376.861908 
	C639.935181,378.552307 639.459045,379.776154 638.624756,381.029175 
	C637.167236,380.882721 635.711487,381.091675 635.032532,380.462463 
	C632.579346,378.188934 630.844055,377.756775 629.866760,381.606293 
	C629.836670,381.724792 629.455078,381.754059 628.942505,381.920898 
	C629.098083,380.324982 629.236511,378.905914 629.384888,377.384430 
	C631.244202,377.118378 632.819580,376.892944 634.394958,376.667511 
	C634.667297,376.305908 634.939697,375.944305 635.212036,375.582703 
	C633.976807,375.268402 632.741638,374.954102 630.720886,374.439941 
	C633.717529,373.058716 634.832397,372.544830 637.405273,371.358917 
	C634.287903,368.141022 631.795044,365.567749 629.302185,362.994476 
	C628.975769,363.301697 628.649353,363.608917 628.322876,363.916138 
	C629.248901,365.936035 630.174927,367.955963 631.608276,371.082611 
	C627.662781,368.894409 625.313354,367.591339 621.865723,365.679291 
	C620.019104,366.701996 617.106567,368.315002 613.403931,370.365570 
	C615.274414,371.278198 616.693176,371.561798 617.420471,372.426849 
	C618.366150,373.551636 619.693481,375.801147 619.250488,376.423584 
	C614.949219,382.467163 618.649902,388.886719 618.160034,395.061157 
	C617.959656,397.587189 619.875305,397.926331 621.990234,395.967041 
	C625.366821,397.076996 625.301880,399.120331 622.888306,401.819214 
	C621.168152,402.347931 620.085327,402.685272 618.707581,402.919861 
	C616.942627,403.882355 615.472534,404.947540 613.870239,405.704071 
	C612.807739,404.784912 611.734497,403.520325 610.973694,403.686401 
	C608.686951,404.185699 606.522034,405.242798 603.051636,406.559509 
	C605.631104,407.420959 607.304443,407.979767 608.935364,408.524445 
	C608.657654,409.260254 608.327759,410.134308 607.620605,411.006653 
	C605.755310,410.721497 604.267212,410.437988 602.339233,410.070679 
	C602.606018,411.677277 602.800293,412.847626 602.793579,414.137512 
	C602.605286,414.597198 602.617981,414.937286 602.630615,415.277405 
	C602.748474,414.853607 602.866394,414.429779 603.316711,414.045807 
	C604.225037,415.375061 604.524780,416.918732 605.431519,417.903503 
	C607.652771,420.315735 605.891357,420.572876 603.969299,420.602509 
	C602.864807,420.237274 601.833252,420.158661 600.808105,420.209167 
	C599.613403,420.268066 598.424255,420.439819 596.440918,420.644196 
	C597.999451,418.051453 598.941650,416.484039 600.234741,414.332825 
	C598.176208,414.586609 596.975159,414.734680 595.793030,414.880402 
	C596.366394,413.379272 596.829163,412.167725 597.291931,410.956146 
	C595.677429,410.956146 594.062988,410.956146 591.732117,410.956146 
	C591.992859,409.176666 592.278259,407.228668 592.606079,404.991364 
	C591.572510,404.070068 590.265076,402.904633 588.914429,401.700684 
	C587.585815,404.247253 586.396423,406.527008 585.209839,408.801392 
	C583.502808,408.206024 582.130859,407.727509 580.784485,407.257935 
	C577.762939,413.597076 576.694580,414.182220 572.578552,412.534241 
	C571.145691,414.750427 569.809387,416.817291 568.647217,418.614807 
	C569.723083,420.508545 570.837952,422.470886 572.144897,424.771393 
	C574.296509,421.741455 576.351501,418.847473 578.382812,415.986938 
	C578.773438,416.536438 579.467957,417.513519 579.914001,418.141083 
	C581.049988,415.646637 582.088318,413.366455 583.126709,411.086243 
	C585.124939,412.913147 587.123169,414.740021 589.502014,416.914948 
	C587.720276,417.516663 586.888977,417.797424 586.148499,418.047485 
	C586.793152,420.400055 587.365662,422.489441 587.792053,424.045532 
	C589.028259,424.699066 590.162781,425.030121 590.905090,425.760040 
	C591.808899,426.648834 592.382690,427.873322 593.099243,428.952576 
	C594.392944,427.946716 595.686584,426.940887 597.264343,426.213989 
	C597.548401,430.077332 597.548401,433.661713 597.548401,437.100311 
	C596.555969,437.838715 595.045227,438.962738 592.733765,440.682556 
	C595.532227,441.169678 597.080017,441.439117 598.752258,441.730194 
	C597.572754,443.928253 596.459534,446.002686 595.214355,448.323120 
	C600.554260,447.577026 605.294678,446.914703 610.035034,446.252380 
	C609.956055,445.810822 609.877014,445.369232 609.798035,444.927643 
	C608.601440,444.776489 607.404907,444.625336 605.847473,444.428619 
	C606.579529,442.452026 607.165161,440.870972 608.021362,438.559387 
	C605.675110,438.447205 603.934998,438.364014 602.226562,438.282349 
	C603.516174,436.184021 604.674561,434.299225 605.410522,433.101746 
	C608.464233,431.142395 611.216248,429.496613 613.761841,427.577026 
	C614.049194,427.360321 613.411804,424.868835 612.797546,424.666992 
	C606.488831,422.593903 608.378113,418.942780 610.652283,415.773346 
	C609.590942,413.868164 608.791504,412.433197 608.313354,411.137939 
	C610.109192,412.501984 611.583801,413.726288 613.423340,414.962158 
	C614.519714,414.961884 615.251038,414.949951 616.329529,415.001312 
	C617.823669,416.300873 618.970703,417.537140 620.960388,419.681732 
	C621.418762,416.943115 622.066162,415.148499 621.928772,413.416168 
	C621.715881,410.733307 622.506104,409.033386 625.126831,408.276306 
	C628.887390,407.189880 629.521057,404.459259 628.893066,401.073364 
	C628.460876,399.265747 628.028687,397.458099 627.238770,394.154297 
	C628.717468,395.946899 629.576538,396.579926 629.863342,397.410522 
	C630.823486,400.191589 631.938599,403.000519 632.281921,405.883606 
	C632.497070,407.690369 631.904053,410.032349 630.782898,411.408752 
	C630.002502,412.366913 627.709900,412.093384 625.000854,412.556915 
	C625.000854,414.085144 625.000854,416.620697 624.620239,419.674469 
	C622.406067,423.575287 621.807251,426.673309 626.653503,429.350159 
	C627.198608,426.846466 627.615906,424.930176 628.033142,423.013885 
	C629.137390,423.249756 630.241638,423.485657 631.669922,424.221924 
	C632.178955,425.341919 632.193604,426.345795 632.577759,426.516022 
	C634.417419,427.331299 634.286560,431.901947 637.867615,429.347595 
	C638.917175,431.896729 639.966736,434.445892 641.016296,436.995026 
	C638.543152,436.199371 636.070007,435.403748 633.596802,434.608124 
	C633.682861,434.075836 633.768921,433.543549 633.854919,433.011292 
	C631.700439,433.614441 629.545898,434.217621 627.408569,434.816010 
	C627.969482,437.665466 628.434631,440.028412 628.899780,442.391357 
	C629.238831,442.451019 629.577881,442.510651 629.916931,442.570312 
	C630.657715,440.355957 631.398438,438.141571 632.139221,435.927216 
	C633.503784,442.988403 632.871521,443.972321 627.830688,444.663940 
	C631.171997,447.105347 634.705383,449.687103 638.238770,452.268890 
	C638.041199,452.670166 637.843567,453.071472 637.645996,453.472748 
	C636.295410,453.138489 634.944885,452.804199 633.530518,452.454163 
	C633.035767,454.439697 632.561279,456.344025 631.742065,458.476562 
	C630.614807,457.573029 629.832153,456.441254 628.967529,455.190979 
	C623.504700,462.055725 621.553467,453.397705 617.677002,452.969604 
	C620.811279,451.622925 623.945557,450.276245 627.079895,448.929565 
	C626.987122,448.472290 626.894348,448.015045 626.801575,447.557800 
	C623.098755,447.557800 619.219116,446.868469 615.744873,447.759613 
	C612.534119,448.583130 610.112183,451.038452 609.755005,455.216583 
	C609.537170,457.764801 607.560730,460.181793 606.247925,462.588898 
	C606.110962,462.839874 605.115479,462.622620 604.419556,462.622620 
	C604.949219,461.125977 605.381714,459.903931 605.443420,459.729675 
	C602.358215,459.092743 599.668457,458.537476 596.860352,457.694580 
	C596.342224,456.753998 595.942383,456.101044 595.244202,454.960815 
	C598.666443,455.809235 601.479492,456.506653 604.292603,457.204071 
	C604.641968,456.536041 604.991333,455.868011 605.340759,455.199982 
	C603.556335,453.796722 601.771973,452.393494 600.128296,450.944061 
	C600.182556,450.936066 600.096069,450.974243 599.629517,451.050751 
	C597.501343,451.389832 595.753174,451.690613 593.971313,451.642944 
	C593.421082,450.073792 592.904419,448.852997 592.427246,447.725555 
	C591.146973,449.339844 590.127014,450.625916 589.107056,451.911957 
	C588.274597,450.548859 587.513306,449.133545 586.571533,447.850708 
	C586.238708,447.397369 585.465332,447.267548 585.074341,446.749268 
	C586.432800,445.078735 587.609924,443.648987 589.445557,441.419373 
	C587.220093,441.950012 585.558411,442.346252 583.334290,442.876556 
	C580.380310,440.369171 571.627075,440.261658 577.371399,431.413879 
	C577.018677,431.167236 576.666016,430.920624 576.313293,430.673981 
	C574.533936,431.846222 572.754517,433.018433 570.114990,434.757294 
	C572.126160,435.466125 573.020813,435.781464 573.000061,435.774170 
	C573.246399,438.082275 573.687317,439.759277 573.523193,441.374786 
	C573.362610,442.955627 572.585266,444.473816 571.705261,446.017517 
	C569.873657,447.014404 568.415466,448.013306 566.766113,448.831390 
	C566.737549,443.568756 568.918640,438.232727 564.941223,434.040039 
	C565.674561,432.211395 566.320496,430.600800 567.307861,428.990051 
	C567.985535,428.288086 568.321716,427.586304 568.657959,426.884491 
	C567.112183,426.587830 565.566467,426.291168 564.035461,425.598938 
	C564.066711,422.812592 564.083191,420.421875 564.361694,417.790588 
	C566.071411,414.758881 567.519043,411.967682 568.833862,409.432465 
	C566.455627,409.627014 564.294678,409.803772 562.107910,409.668701 
	C562.098816,408.940186 562.115662,408.523499 562.472778,408.073853 
	C566.926880,406.154724 562.975464,404.015594 562.862122,401.646362 
	C562.419556,398.596069 562.263916,395.873566 561.772766,393.213043 
	C561.305603,390.681976 561.124878,387.713959 559.658081,385.851685 
	C557.944946,383.676483 552.964661,383.528168 556.378235,378.896545 
	C556.629150,378.556091 556.128357,377.661591 555.988892,376.594238 
	C555.997192,374.445770 555.993713,372.727783 556.231262,370.734833 
	C557.659668,371.044952 558.863770,371.598816 560.029480,372.224426 
	C561.057129,372.775970 562.041748,373.407654 562.999390,374.353668 
	C560.647888,379.554626 561.720703,384.135468 564.194458,389.872223 
	C566.152771,387.187469 567.556641,385.262909 568.588745,383.847931 
	C568.977051,384.236694 569.944519,385.205292 571.207581,386.469818 
	C570.146240,387.678040 568.741089,389.277740 567.575012,390.605194 
	C568.342896,393.454498 569.061340,396.120270 569.650269,398.305450 
	C573.622986,399.161163 571.785583,394.914429 575.227173,393.679169 
	C580.023193,391.957855 583.722412,397.786987 587.670044,393.850403 
	C586.900208,395.709259 586.130371,397.568085 585.280945,399.618988 
	C581.572388,397.425018 578.055847,395.427917 574.606567,399.997375 
	C571.651428,400.853882 569.092163,401.715485 565.798462,402.824310 
	C566.774048,403.882538 567.576904,405.432465 568.276428,405.387146 
	C570.538086,405.240723 572.761719,404.507996 574.953308,404.328888 
	C571.734375,408.448181 574.801575,408.792969 576.752319,409.754517 
	C577.300903,409.431915 577.849426,409.109344 578.398010,408.786774 
	C577.265320,407.190765 576.132690,405.594788 575.013000,403.598816 
	C575.016479,402.131744 575.007019,401.064667 575.328735,400.029114 
	C578.619324,401.296661 581.578796,402.532684 584.002380,403.544922 
	C585.303833,401.932343 586.930359,399.916931 588.644958,397.792450 
	C591.351624,399.832520 593.532104,401.476044 595.375244,402.865295 
	C596.767761,400.251373 598.133118,398.019745 599.109375,395.629181 
	C599.362488,395.009338 598.397888,393.892151 597.993286,392.616333 
	C598.403137,390.630463 598.812317,389.031616 599.221497,387.432770 
	C601.023071,395.958679 605.843262,400.998718 612.452759,400.382263 
	C612.466980,395.142517 607.564392,395.918671 604.846863,394.587311 
	C606.343079,391.490326 607.667908,388.748199 608.996338,386.003082 
	C609.000000,386.000061 609.004883,385.993896 609.356262,386.027466 
	C610.910217,386.326569 612.112854,386.592072 612.722717,386.726715 
	C610.982239,380.430054 609.280762,374.274384 607.757385,368.763062 
	C605.573547,369.485962 603.534241,370.161011 601.014709,370.995056 
	C600.743530,372.911987 600.418274,375.211212 600.029907,377.956055 
	C598.036743,375.629395 596.292969,373.593781 594.549133,371.558197 
	C594.864380,371.282776 595.179626,371.007324 595.494812,370.731903 
	C596.386169,371.070831 597.277527,371.409760 598.152039,371.742279 
	C598.683533,369.496338 599.189819,367.357117 599.871826,364.475433 
	C601.366943,366.410889 602.243103,367.545044 603.518799,369.196411 
	C602.880981,360.479889 602.880981,360.479889 604.371216,359.702606 
	C605.935608,361.872498 607.438293,363.956787 608.940979,366.041107 
	C609.568420,365.893738 610.195923,365.746338 610.823425,365.598969 
	C611.314819,362.282715 611.806274,358.966431 612.303406,355.611603 
	C609.179260,356.102570 607.244751,356.406586 605.310242,356.710602 
	C605.199585,356.215210 605.088928,355.719788 604.978271,355.224396 
	C606.023682,354.583649 607.069092,353.942902 608.952148,352.788757 
	C606.665222,352.298431 605.385437,352.024048 604.112183,351.747742 
	C604.273865,350.654877 604.428894,349.563904 604.523438,348.899048 
	C602.502747,346.722565 597.897766,350.201080 598.002441,345.008484 
	C598.002441,345.002350 598.014343,345.001984 598.263794,345.004456 
	C598.513306,345.006958 599.012207,345.001221 599.250732,345.004303 
	C599.489319,345.007385 599.966431,345.010681 600.201538,345.279907 
	C603.314514,346.376862 606.192322,347.204620 609.388306,348.026733 
	C610.130310,348.018616 610.554138,348.016205 610.993530,348.393372 
	C614.124329,353.038879 617.509766,352.749939 620.004272,348.002441 
	C620.000000,348.000000 620.007812,348.007935 620.388672,348.012268 
	C622.108398,348.449646 623.447205,348.882690 625.131104,349.427307 
	C620.895264,352.699585 617.378540,355.416351 613.211182,358.635712 
	C617.190247,361.181061 620.536194,362.425720 624.558838,360.986359 
	C628.331055,359.636719 632.129517,358.360229 635.916382,357.051544 
	C635.916382,357.051544 635.969116,357.029480 635.971558,357.435791 
	C637.246155,359.316437 632.581421,363.172943 638.031799,362.970825 
	C642.711731,363.187256 640.298157,366.964233 641.135620,369.543701 
M612.371948,421.484711 
	C613.298462,423.274200 614.181763,425.087769 615.160461,426.848236 
	C617.194641,430.507080 619.134949,434.233612 621.443726,437.713470 
	C622.030151,438.597382 623.854004,438.660309 625.109314,439.100433 
	C625.566956,438.666260 626.024597,438.232086 626.482239,437.797943 
	C624.413208,434.121887 622.344116,430.445831 620.133606,426.518433 
	C620.186646,425.913727 620.911438,423.705200 620.324463,423.271332 
	C618.057007,421.595337 616.422607,416.880280 612.371948,421.484711 
M592.799988,435.251373 
	C593.065613,434.087769 593.331299,432.924194 593.596985,431.760590 
	C593.230408,431.539093 592.863831,431.317596 592.497192,431.096100 
	C591.625977,432.361298 590.754761,433.626495 589.883484,434.891663 
	C590.746826,435.205322 591.610107,435.518982 592.799988,435.251373 
M576.940308,426.250305 
	C576.726562,425.310822 576.512817,424.371368 576.299011,423.431885 
	C575.799805,423.728577 575.300537,424.025238 574.801331,424.321899 
	C575.473572,425.199280 576.145752,426.076630 576.940308,426.250305 
M613.385132,374.205139 
	C613.783325,374.209381 614.181458,374.213623 614.579651,374.217865 
	C614.580994,374.052826 614.593445,373.744324 614.582153,373.743469 
	C614.185974,373.712891 613.787598,373.711273 613.385132,374.205139 
M609.703125,437.573730 
	C609.989014,437.299408 610.278076,437.027985 610.545654,436.736847 
	C610.553467,436.728271 610.335571,436.512238 610.222900,436.392975 
	C609.936890,436.667755 609.650879,436.942505 609.703125,437.573730 
M638.253601,433.812256 
	C638.371155,433.974274 638.488770,434.136261 638.606323,434.298279 
	C638.582153,434.072327 638.557983,433.846344 638.253601,433.812256 
M626.226135,362.199341 
	C626.399780,362.140961 626.573425,362.082611 626.747070,362.024231 
	C626.571594,361.967682 626.396118,361.911133 626.226135,362.199341 
M587.613159,435.515839 
	C587.613159,435.515839 587.447937,435.418732 587.447937,435.418732 
	C587.447937,435.418732 587.469482,435.608765 587.613159,435.515839 
M611.512695,443.399445 
	C611.512695,443.399445 611.590027,443.523987 611.512695,443.399445 
M591.591919,443.516663 
	C591.591919,443.516663 591.471130,443.587860 591.591919,443.516663 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M596.978699,457.982208 
	C599.668457,458.537476 602.358215,459.092743 605.443420,459.729675 
	C605.381714,459.903931 604.949219,461.125977 604.419556,462.622620 
	C605.115479,462.622620 606.110962,462.839874 606.247925,462.588898 
	C607.560730,460.181793 609.537170,457.764801 609.755005,455.216583 
	C610.112183,451.038452 612.534119,448.583130 615.744873,447.759613 
	C619.219116,446.868469 623.098755,447.557800 626.801575,447.557800 
	C626.894348,448.015045 626.987122,448.472290 627.079895,448.929565 
	C623.945557,450.276245 620.811279,451.622925 617.677002,452.969604 
	C621.553467,453.397705 623.504700,462.055725 628.967529,455.190979 
	C629.832153,456.441254 630.614807,457.573029 631.698730,458.858521 
	C632.000305,460.073486 632.000610,461.134796 631.835388,462.910431 
	C630.534912,464.053589 629.212341,465.085815 628.298889,464.801880 
	C625.130005,463.816986 623.897400,465.458801 622.652710,467.973999 
	C621.056396,467.773315 619.820740,467.565063 618.565369,467.353485 
	C618.614014,471.471741 616.526672,473.405426 612.970825,473.709625 
	C612.987000,472.912750 612.994751,472.457581 613.287231,471.974426 
	C613.893005,471.641388 614.214050,471.336365 614.535034,471.031311 
	C614.326721,470.847900 614.118408,470.664459 613.910095,470.481018 
	C613.605957,470.986572 613.301758,471.492065 612.647095,471.936584 
	C611.573669,470.685333 610.850647,469.495056 610.412964,468.774536 
	C607.584290,471.313873 605.661316,473.040100 602.630676,475.760681 
	C606.340149,475.447113 609.192993,475.205963 612.031738,475.306335 
	C612.011047,476.103729 612.004395,476.559631 612.104492,477.271118 
	C612.473633,478.013123 612.736084,478.499542 612.947571,479.316040 
	C612.265625,480.763367 611.634705,481.880615 611.003784,482.997864 
	C609.257874,483.664154 607.511902,484.330444 605.385193,484.601746 
	C605.159485,482.301361 605.314636,480.395935 605.482483,478.334015 
	C601.130920,481.379669 601.068848,482.876068 605.000000,484.985291 
	C604.680176,486.768219 604.360229,488.551147 604.040344,490.334045 
	C604.345825,490.452728 604.651245,490.571442 604.956665,490.690125 
	C605.593262,489.936035 606.229797,489.181976 607.637756,487.514160 
	C607.901367,489.976685 608.082947,491.672607 607.772339,493.681183 
	C606.560669,494.004120 605.841125,494.014435 604.914673,493.778717 
	C603.300354,493.856354 601.892944,494.179993 600.160034,494.578491 
	C600.673279,492.440704 601.239563,490.728394 601.443665,488.973938 
	C601.554993,488.017365 601.136780,486.954559 600.753906,486.023376 
	C600.679077,485.841370 599.205566,485.925476 599.163147,486.089752 
	C598.903870,487.093781 598.779724,488.157715 598.795837,489.198822 
	C598.806213,489.872742 599.132263,490.541779 599.351929,491.340607 
	C597.644775,491.529480 596.388000,491.668518 593.841370,491.950256 
	C596.075317,493.388367 597.202026,494.113678 599.267517,495.443329 
	C595.838623,496.101685 593.466492,496.557159 590.719604,497.008240 
	C589.602234,497.023682 588.859619,497.043518 588.088562,496.654663 
	C589.364380,492.909241 590.668518,489.572571 591.874207,486.487823 
	C592.408081,486.460022 591.520325,486.506256 591.008240,486.532928 
	C590.388550,483.368652 589.791321,480.319061 589.184265,477.219421 
	C587.733276,477.219421 586.448608,477.219421 584.765564,477.219421 
	C585.688354,475.476746 586.344360,474.237854 587.346130,473.050690 
	C593.888916,477.517944 593.899170,477.519592 596.948547,475.055328 
	C595.810120,474.245514 593.555603,473.406982 593.508118,472.458099 
	C593.387695,470.049103 594.113525,467.550079 594.791016,465.169769 
	C595.108459,464.054474 596.096313,463.129974 596.780334,462.119019 
	C595.537354,461.359985 594.294373,460.600922 592.778564,459.675293 
	C592.305481,463.963013 591.925171,467.410217 591.470032,471.535675 
	C589.454895,470.370239 588.217041,469.654388 587.124512,468.665771 
	C587.756531,467.518005 588.243286,466.642975 588.894653,465.472137 
	C583.698975,466.068481 584.399536,470.317230 582.765442,473.363556 
	C580.280273,473.503723 578.035889,473.246185 575.812561,473.368744 
	C575.320984,473.395844 574.903442,474.767517 574.451843,475.520081 
	C575.245605,475.722870 576.062500,476.154144 576.827759,476.076477 
	C578.061096,475.951324 579.260315,475.490631 580.473938,475.171692 
	C580.873230,475.599731 581.272522,476.027740 581.671814,476.455780 
	C579.250732,481.411682 576.829712,486.367554 574.187317,491.776550 
	C570.110718,489.835052 566.044006,487.898285 561.982178,485.625092 
	C561.993225,484.840179 561.999390,484.391632 562.202881,483.701111 
	C563.538452,483.582581 564.676758,483.705994 564.546204,483.691833 
	C566.784851,481.418610 568.917664,479.924866 570.085022,477.879944 
	C571.269897,475.804260 571.501831,473.184601 572.151123,470.803192 
	C569.341736,470.925110 568.584595,468.374390 566.994812,466.580688 
	C566.633057,465.852478 566.302246,465.411194 565.935791,464.600952 
	C563.915344,460.796295 561.930603,457.360596 559.769287,453.662537 
	C559.058350,451.930725 558.523926,450.461304 558.281494,448.882141 
	C560.058228,449.433777 561.542847,450.095154 563.032043,451.135437 
	C565.683167,455.951294 568.311646,460.411377 575.052246,459.416138 
	C573.500427,458.482422 571.948608,457.548676 570.083923,456.426697 
	C569.956665,455.128479 569.776001,453.284546 569.489136,450.357635 
	C571.857910,451.848175 573.369568,452.850647 574.931763,453.766937 
	C576.298218,454.568481 577.721497,455.273132 579.119629,456.020599 
	C579.572571,455.609253 580.025452,455.197906 580.478394,454.786530 
	C573.848694,453.229584 576.315186,450.040833 578.559753,446.976379 
	C580.953979,446.986450 582.923523,446.988251 584.893066,446.990021 
	C585.465332,447.267548 586.238708,447.397369 586.571533,447.850708 
	C587.513306,449.133545 588.274597,450.548859 589.107056,451.911957 
	C590.127014,450.625916 591.146973,449.339844 592.427246,447.725555 
	C592.904419,448.852997 593.421082,450.073792 593.717896,451.866058 
	C594.180420,454.585785 592.603943,458.151886 596.978699,457.982208 
M591.525024,457.617737 
	C591.469299,457.837982 591.413574,458.058228 591.357910,458.278503 
	C591.495239,458.137207 591.632629,457.995911 591.525024,457.617737 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M621.991821,395.597473 
	C619.875305,397.926331 617.959656,397.587189 618.160034,395.061157 
	C618.649902,388.886719 614.949219,382.467163 619.250488,376.423584 
	C619.693481,375.801147 618.366150,373.551636 617.420471,372.426849 
	C616.693176,371.561798 615.274414,371.278198 613.403931,370.365570 
	C617.106567,368.315002 620.019104,366.701996 621.865723,365.679291 
	C625.313354,367.591339 627.662781,368.894409 631.608276,371.082611 
	C630.174927,367.955963 629.248901,365.936035 628.322876,363.916138 
	C628.649353,363.608917 628.975769,363.301697 629.302185,362.994476 
	C631.795044,365.567749 634.287903,368.141022 637.405273,371.358917 
	C634.832397,372.544830 633.717529,373.058716 630.720886,374.439941 
	C632.741638,374.954102 633.976807,375.268402 635.212036,375.582703 
	C634.939697,375.944305 634.667297,376.305908 634.394958,376.667511 
	C632.819580,376.892944 631.244202,377.118378 629.384888,377.384430 
	C629.236511,378.905914 629.098083,380.324982 628.942505,381.920898 
	C629.455078,381.754059 629.836670,381.724792 629.866760,381.606293 
	C630.844055,377.756775 632.579346,378.188934 635.032532,380.462463 
	C635.711487,381.091675 637.167236,380.882721 639.023804,381.024353 
	C640.841187,380.974121 641.901306,380.957947 642.961487,380.941742 
	C644.324036,381.301422 645.686584,381.661102 647.396362,382.031921 
	C647.785156,382.481842 647.826721,382.920624 647.559937,383.879211 
	C646.832764,386.266846 646.413940,388.134644 645.995117,390.002441 
	C645.549011,390.000214 645.102966,389.998016 644.325317,389.627563 
	C643.391479,388.302246 642.789185,387.345184 642.186890,386.388092 
	C641.770386,387.265045 641.353943,388.141968 640.937439,389.018921 
	C640.206848,389.011230 639.476257,389.003571 638.232666,388.667358 
	C636.588928,387.386688 635.458191,386.434601 634.327515,385.482544 
	C631.978088,389.133820 628.279846,387.786591 625.146179,387.768250 
	C625.028870,386.613159 624.767822,385.688568 624.449097,384.559967 
	C626.913391,383.102966 628.304443,374.821899 626.322571,372.975922 
	C624.433228,375.066254 622.557922,377.141022 620.928833,378.943451 
	C621.471619,379.996826 622.737732,381.971344 622.536743,382.134155 
	C616.411377,387.094421 622.400146,386.832031 625.003418,388.401001 
	C625.006226,389.866791 625.005554,390.932770 625.004883,391.998779 
	C625.004883,391.998779 625.000000,392.000000 624.658264,392.001556 
	C623.543823,392.337830 622.771118,392.672546 621.998413,393.007324 
	C621.996704,393.747528 621.994995,394.487732 621.991821,395.597473 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M612.998535,478.985931 
	C612.736084,478.499542 612.473633,478.013123 612.473511,477.227783 
	C616.429993,477.413391 617.495605,471.575409 621.669800,473.214569 
	C620.484009,471.046997 626.353943,470.685669 623.013306,467.966461 
	C623.897400,465.458801 625.130005,463.816986 628.298889,464.801880 
	C629.212341,465.085815 630.534912,464.053589 631.835815,463.308655 
	C632.560242,463.161316 633.118835,463.330109 633.840942,464.083618 
	C634.007812,465.119965 634.011169,465.571533 634.065674,466.371765 
	C634.980530,468.466675 635.844177,470.212952 636.582153,471.705109 
	C637.263245,471.505737 638.144592,471.247681 639.017212,471.389465 
	C638.107727,473.672913 637.637512,476.695618 636.226440,477.229004 
	C631.793701,478.904572 627.006958,479.643738 622.164307,480.791290 
	C622.142334,480.563354 622.066589,479.778290 622.127441,478.938904 
	C622.178406,478.926147 622.092773,478.967682 621.661255,479.040955 
	C619.867249,479.730591 618.419128,480.388428 616.649231,480.998413 
	C615.217834,480.295685 614.108215,479.640808 612.998535,478.985931 
M630.480042,467.590027 
	C630.480042,467.590027 630.411865,467.476990 630.480042,467.590027 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M639.025940,470.989655 
	C638.144592,471.247681 637.263245,471.505737 636.582153,471.705109 
	C635.844177,470.212952 634.980530,468.466675 634.384949,466.323822 
	C635.108459,465.288818 635.563904,464.650360 636.334900,464.032959 
	C637.747742,463.385559 638.844971,462.717133 639.958435,461.707886 
	C639.983093,460.912170 639.991577,460.457306 640.346313,459.997620 
	C641.298706,459.630737 641.904907,459.268616 642.511108,458.906494 
	C641.675049,458.608673 640.839050,458.310852 639.666870,458.013245 
	C638.882507,458.010162 638.434143,458.006897 637.985840,458.003601 
	C640.882996,456.503937 643.780090,455.004242 647.265991,453.199768 
	C645.658081,452.403442 644.593933,451.876434 642.919250,451.047058 
	C644.110901,449.658661 645.205017,448.383942 646.638916,447.064148 
	C648.891052,448.890839 650.803345,450.762634 653.008606,452.921173 
	C646.828674,456.087463 641.361694,459.705780 641.720825,468.145050 
	C640.996887,469.507904 640.011414,470.248779 639.025940,470.989655 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M619.002441,403.022583 
	C620.085327,402.685272 621.168152,402.347931 622.616638,402.014801 
	C623.194031,403.089600 623.405884,404.160217 623.534424,404.809937 
	C625.114746,403.563568 626.671509,402.335754 628.560669,401.090637 
	C629.521057,404.459259 628.887390,407.189880 625.126831,408.276306 
	C622.506104,409.033386 621.715881,410.733307 621.928772,413.416168 
	C622.066162,415.148499 621.418762,416.943115 620.960388,419.681732 
	C618.970703,417.537140 617.823669,416.300873 616.395020,414.662170 
	C618.871521,410.975555 619.503784,407.144806 619.002441,403.022583 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M646.000061,390.413086 
	C646.413940,388.134644 646.832764,386.266846 647.554993,384.210693 
	C648.403320,384.282532 648.948181,384.542786 649.779785,384.902313 
	C649.823792,386.089569 649.581055,387.177521 649.260559,388.613953 
	C650.772766,388.613953 652.085693,388.613953 654.008667,388.613953 
	C652.992065,391.305389 652.058350,393.777466 651.079529,396.609070 
	C649.732239,397.525848 648.429993,398.083130 646.768555,398.794189 
	C646.507324,396.067230 646.256165,393.445465 646.000061,390.413086 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M625.388184,392.003723 
	C625.005554,390.932770 625.006226,389.866791 625.003418,388.400391 
	C625.000000,388.000000 625.002380,387.998779 625.002380,387.998779 
	C628.279846,387.786591 631.978088,389.133820 634.327515,385.482544 
	C635.458191,386.434601 636.588928,387.386688 637.867310,388.665710 
	C637.998535,390.002869 637.982056,391.013031 637.726013,392.559753 
	C636.660339,393.056702 635.834290,393.017120 635.008240,392.977509 
	C634.247864,392.536072 633.497559,391.741394 632.725281,391.719452 
	C630.411987,391.653778 628.090027,391.890198 625.388184,392.003723 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M641.320984,436.856995 
	C639.966736,434.445892 638.917175,431.896729 637.867615,429.347595 
	C634.286560,431.901947 634.417419,427.331299 632.577759,426.516022 
	C632.193604,426.345795 632.178955,425.341919 631.985352,424.361755 
	C634.959900,424.747681 637.943176,425.494110 640.867371,426.225769 
	C641.569275,428.285675 642.592224,430.293976 642.849304,432.395996 
	C643.017029,433.766571 642.072144,435.273254 641.320984,436.856995 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M627.887268,422.736938 
	C627.615906,424.930176 627.198608,426.846466 626.653503,429.350159 
	C621.807251,426.673309 622.406067,423.575287 624.620483,420.121124 
	C625.914734,420.853027 626.828064,421.656525 627.887268,422.736938 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M637.719360,458.110046 
	C638.434143,458.006897 638.882507,458.010162 639.665894,458.344604 
	C640.000671,459.118011 640.000366,459.560211 640.000000,460.002441 
	C639.991577,460.457306 639.983093,460.912170 639.599243,461.732300 
	C637.815918,462.395966 636.407959,462.694305 635.000000,462.992676 
	C635.000000,462.992676 635.000000,462.498657 635.001038,461.918427 
	C634.998962,460.894196 634.995789,460.450165 634.992676,460.006165 
	C634.992676,460.006165 635.010132,459.991638 635.226685,459.888184 
	C635.708862,459.603027 635.876587,459.355133 635.946472,459.040955 
	C636.448608,458.766144 636.950745,458.491302 637.719360,458.110046 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M640.320190,396.552734 
	C640.974426,395.128021 641.766357,394.039795 642.558289,392.951569 
	C643.039368,393.176239 643.520447,393.400940 644.001465,393.625610 
	C643.653809,395.176758 643.306091,396.727905 642.958374,398.279053 
	C642.124878,397.815765 641.291443,397.352478 640.320190,396.552734 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M650.097168,433.236786 
	C651.784546,431.960175 653.419678,431.057739 656.146606,429.552795 
	C654.954041,432.995544 654.093567,435.081207 650.495239,435.080750 
	C650.099365,434.614929 650.072144,434.112946 650.097168,433.236786 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M641.155823,389.277100 
	C641.353943,388.141968 641.770386,387.265045 642.186890,386.388092 
	C642.789185,387.345184 643.391479,388.302246 643.990845,389.625854 
	C643.116577,389.840057 642.245422,389.687653 641.155823,389.277100 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M634.811096,393.176208 
	C635.834290,393.017120 636.660339,393.056702 637.713318,392.938232 
	C637.613586,393.683746 637.286865,394.587372 636.960205,395.490967 
	C636.178101,394.785614 635.396057,394.080292 634.811096,393.176208 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M635.000732,463.244202 
	C636.407959,462.694305 637.815918,462.395966 639.583008,462.073151 
	C638.844971,462.717133 637.747742,463.385559 636.081787,464.027527 
	C635.344543,464.000275 635.175964,463.999542 635.007324,463.998779 
	C635.005371,463.831085 635.003418,463.663422 635.000732,463.244202 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M622.287842,393.126495 
	C622.771118,392.672546 623.543823,392.337830 624.657043,392.003998 
	C624.190796,392.418488 623.383972,392.832092 622.287842,393.126495 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M634.759155,463.997803 
	C635.175964,463.999542 635.344543,464.000275 635.766235,464.006470 
	C635.563904,464.650360 635.108459,465.288818 634.333740,465.975159 
	C634.011169,465.571533 634.007812,465.119965 634.009583,464.329773 
	C634.014648,463.991119 634.510986,463.996796 634.759155,463.997803 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M634.766235,460.150391 
	C634.995789,460.450165 634.998962,460.894196 635.001038,461.671387 
	C634.846619,461.434631 634.693237,460.864624 634.766235,460.150391 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M635.741699,459.140442 
	C635.876587,459.355133 635.708862,459.603027 635.235718,459.881226 
	C635.108948,459.670471 635.278503,459.424530 635.741699,459.140442 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M635.968079,356.710754 
	C632.129517,358.360229 628.331055,359.636719 624.558838,360.986359 
	C620.536194,362.425720 617.190247,361.181061 613.211182,358.635712 
	C617.378540,355.416351 620.895264,352.699585 625.131104,349.427307 
	C623.447205,348.882690 622.108398,348.449646 620.265259,347.804749 
	C619.408630,347.607269 619.056396,347.621674 618.704102,347.636108 
	C619.138916,347.759033 619.573730,347.881958 620.008545,348.004883 
	C617.509766,352.749939 614.124329,353.038879 611.005981,348.017700 
	C611.580322,346.242218 612.157715,345.221924 613.142578,343.481659 
	C613.976868,346.112549 614.432373,347.548981 615.209473,349.999512 
	C616.545410,347.689331 617.293030,346.396545 617.920166,345.312134 
	C619.984070,345.440796 621.698853,345.547699 623.413635,345.654633 
	C622.553833,344.274139 621.243774,344.297363 620.789612,343.685364 
	C618.094849,340.054291 614.526672,339.431580 610.333557,339.834320 
	C609.647217,339.900238 608.770508,337.984253 607.985596,336.987732 
	C607.989441,336.991180 607.980591,336.979370 608.262451,337.078369 
	C608.973328,336.492645 609.402283,335.807953 609.831299,335.123230 
	C608.887512,335.082153 607.943726,335.041077 606.760498,334.786194 
	C608.192871,330.365967 609.878540,326.164703 611.466614,321.926849 
	C611.511719,321.806458 610.517334,321.296539 610.007568,320.968262 
	C610.007690,320.521118 610.007874,320.073944 610.013123,319.290588 
	C610.996582,318.273132 611.974976,317.591858 613.607239,316.455322 
	C613.607239,318.713959 613.607239,320.097290 613.607239,322.049103 
	C615.016663,321.428864 616.066711,320.966736 617.116821,320.504608 
	C616.763672,320.855804 616.410522,321.206970 616.057373,321.558136 
	C617.571472,323.162872 619.085632,324.767578 620.599731,326.372284 
	C621.171936,326.197632 621.744202,326.023010 622.316406,325.848358 
	C620.215393,327.588074 618.114380,329.327789 615.222961,331.721985 
	C617.337952,331.457367 618.765198,331.326080 620.175415,331.092682 
	C623.325073,330.571533 626.249756,331.177673 626.648560,334.699860 
	C627.253845,340.046295 629.242432,344.558167 632.566040,348.695770 
	C633.434509,349.776947 633.524902,351.483124 634.815247,352.771606 
	C635.216797,353.971069 635.618286,355.170532 635.968079,356.710754 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M637.994141,362.649170 
	C632.581421,363.172943 637.246155,359.316437 635.962402,357.462891 
	C637.574646,357.143158 639.652405,356.725708 638.012512,359.605042 
	C637.617676,360.298279 637.955566,361.408875 637.994141,362.649170 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M639.017212,471.389465 
	C640.011414,470.248779 640.996887,469.507904 641.986450,468.399353 
	C644.621155,466.718964 647.251770,465.406250 650.020325,464.024719 
	C650.891663,466.580780 652.946167,469.582764 646.631531,469.665497 
	C647.938660,470.723511 648.684937,471.327515 649.774292,471.968201 
	C650.126099,472.419373 650.134827,472.833862 650.119873,473.988037 
	C650.415405,476.822174 650.734680,478.916656 651.053894,481.011108 
	C650.403015,481.801025 649.752136,482.590942 648.650024,483.689209 
	C646.742859,484.188507 645.286987,484.379486 643.831055,484.570465 
	C642.002319,482.270386 639.784546,480.580109 637.306824,479.470184 
	C636.435547,479.079865 634.927002,480.112244 633.709473,480.494995 
	C637.726562,482.474060 640.481079,483.411041 640.761536,484.835266 
	C641.565491,488.917206 644.005005,489.308105 647.233826,489.039062 
	C646.830322,490.452881 646.426819,491.866699 645.971619,493.649719 
	C642.329163,496.689148 638.738403,499.359344 635.147644,502.029541 
	C634.740967,501.701477 634.334290,501.373413 633.927612,501.045380 
	C635.577271,498.740784 637.226929,496.436218 639.013855,493.939789 
	C635.858032,493.939789 633.079834,493.939789 629.770142,493.939789 
	C630.852539,488.456573 626.264771,487.435181 623.114380,487.645203 
	C620.598145,487.812988 620.394897,486.474762 619.095581,484.985779 
	C618.272278,483.280090 617.621582,482.163177 616.970947,481.046265 
	C618.419128,480.388428 619.867249,479.730591 621.657715,479.036377 
	C622.000000,479.000000 621.990906,478.993195 621.990906,478.993195 
	C622.066589,479.778290 622.142334,480.563354 622.164307,480.791290 
	C627.006958,479.643738 631.793701,478.904572 636.226440,477.229004 
	C637.637512,476.695618 638.107727,473.672913 639.017212,471.389465 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M645.038513,378.353271 
	C646.105469,377.052795 647.216614,376.079468 648.656494,375.090881 
	C648.971680,375.492188 648.958069,375.908661 648.687378,376.865601 
	C648.209534,378.283569 647.988708,379.161041 647.767944,380.038513 
	C646.872803,379.585815 645.977722,379.133118 645.038513,378.353271 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M647.845764,380.272980 
	C647.988708,379.161041 648.209534,378.283569 648.683594,377.178894 
	C649.595337,377.657227 650.253784,378.362701 650.912292,379.068176 
	C650.196472,379.701904 649.480713,380.335602 648.392273,380.980713 
	C648.019653,380.992096 647.923584,380.507446 647.845764,380.272980 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M642.973511,380.573029 
	C641.901306,380.957947 640.841187,380.974121 639.381958,380.995178 
	C639.459045,379.776154 639.935181,378.552307 640.717285,377.170532 
	C641.677368,378.076508 642.331482,379.140411 642.973511,380.573029 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M647.583557,489.044098 
	C644.005005,489.308105 641.565491,488.917206 640.761536,484.835266 
	C640.481079,483.411041 637.726562,482.474060 633.709473,480.494995 
	C634.927002,480.112244 636.435547,479.079865 637.306824,479.470184 
	C639.784546,480.580109 642.002319,482.270386 643.831055,484.570465 
	C645.286987,484.379486 646.742859,484.188507 648.583618,483.997192 
	C649.861267,485.449310 650.754089,486.901733 651.905640,488.774994 
	C650.149109,488.896210 649.041199,488.972656 647.583557,489.044098 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M646.333984,509.992706 
	C645.532898,509.781769 645.051880,509.616943 644.648193,509.339508 
	C643.506897,508.555298 642.397339,507.724976 641.275269,506.912842 
	C642.709412,506.912872 644.143555,506.912872 645.831177,506.912872 
	C646.397705,505.037933 647.057312,502.854889 647.864136,500.334106 
	C652.814026,500.151917 647.693359,503.527161 649.777344,504.830994 
	C649.740479,506.086823 649.446838,507.159790 649.150391,508.565247 
	C648.328125,509.260956 647.508728,509.624176 646.333984,509.992706 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M651.426270,481.002136 
	C650.734680,478.916656 650.415405,476.822174 650.155273,474.296997 
	C654.537781,476.647369 654.973633,478.608032 651.426270,481.002136 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M653.018555,501.027710 
	C652.918213,501.068146 652.794922,501.082336 652.807129,501.026306 
	C652.942688,500.956055 652.995605,501.001495 653.018555,501.027710 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M640.461670,523.097168 
	C640.161804,520.762817 640.052429,518.372742 640.359070,516.037170 
	C640.505310,514.923767 641.442383,513.914307 642.359375,512.240295 
	C643.611267,514.252991 644.404541,515.528442 645.540527,516.842651 
	C645.834351,518.047852 645.785522,519.214355 645.846924,520.667480 
	C644.245789,521.657349 642.534485,522.360596 640.461670,523.097168 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M647.998779,536.998779 
	C647.650452,537.251526 647.303345,537.505493 646.956238,537.759460 
	C646.867371,537.636047 646.778442,537.512695 646.689575,537.389282 
	C646.966370,537.270569 647.243225,537.151855 647.761230,537.017822 
	C648.002441,537.002441 648.000000,537.000000 647.998779,536.998779 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M607.981812,336.984283 
	C608.770508,337.984253 609.647217,339.900238 610.333557,339.834320 
	C614.526672,339.431580 618.094849,340.054291 620.789612,343.685364 
	C621.243774,344.297363 622.553833,344.274139 623.413635,345.654633 
	C621.698853,345.547699 619.984070,345.440796 617.920166,345.312134 
	C617.293030,346.396545 616.545410,347.689331 615.209473,349.999512 
	C614.432373,347.548981 613.976868,346.112549 613.142578,343.481659 
	C612.157715,345.221924 611.580322,346.242218 610.990479,347.638123 
	C610.554138,348.016205 610.130310,348.018616 609.230103,347.691681 
	C608.278442,346.021179 607.803162,344.680054 607.283569,343.213928 
	C604.690613,343.850647 602.328552,344.430664 599.966431,345.010681 
	C599.966431,345.010681 599.489319,345.007385 599.162476,344.831726 
	C598.561890,344.771362 598.288147,344.886688 598.014343,345.002014 
	C598.014343,345.001984 598.002441,345.002350 597.616638,345.008911 
	C592.031067,345.664795 593.141479,349.653931 592.694458,352.951141 
	C591.910217,352.301514 591.443420,351.687500 591.242188,350.845764 
	C591.285095,345.844666 589.546265,344.125305 584.502686,343.984497 
	C582.777283,343.623840 581.403259,343.317413 579.914062,342.789124 
	C579.594116,342.287994 579.325867,342.098663 578.679443,342.022522 
	C577.591187,342.363220 576.817627,342.680481 575.683350,343.054382 
	C573.567505,345.808990 571.471008,346.835480 568.451050,344.834351 
	C567.900574,344.469604 566.787598,344.953613 565.776001,345.134155 
	C565.750488,345.480133 565.883362,345.737762 566.008179,345.998047 
	C566.000000,346.000763 566.008484,345.985931 566.004639,346.415039 
	C566.982483,350.564514 567.964111,354.284851 569.219299,359.041901 
	C571.231262,356.877777 572.099182,355.944244 573.227722,355.012024 
	C573.662170,355.016113 573.835999,355.018921 574.006836,355.403839 
	C573.500854,357.696503 572.997742,359.606995 572.494690,361.517487 
	C574.322144,360.925812 575.039185,359.632324 576.076233,358.691437 
	C577.247437,357.628815 578.706055,356.883087 580.037903,356.325836 
	C580.056824,357.085968 580.077515,357.519806 580.065063,358.347656 
	C578.232666,362.972595 576.433472,367.203552 574.554749,371.621674 
	C571.152527,370.351990 567.926819,369.148163 564.255737,367.778137 
	C564.142578,370.082428 564.071289,371.534851 563.898682,373.205994 
	C563.610596,373.700592 563.359924,373.893677 563.045410,374.003937 
	C562.041748,373.407654 561.057129,372.775970 560.029480,372.224426 
	C558.863770,371.598816 557.659668,371.044952 555.901245,370.758972 
	C554.223755,371.696136 553.117310,372.334259 551.660767,372.923676 
	C550.241943,371.156555 549.329834,369.311127 548.064941,367.751953 
	C546.495850,365.817841 544.634521,364.120789 542.592529,362.254822 
	C541.868591,362.150696 541.451660,362.113129 540.860840,362.023987 
	C540.686890,361.972443 540.325134,361.944977 540.241699,361.695709 
	C540.128906,361.273682 540.099426,361.100952 540.097351,360.723877 
	C540.124817,360.519531 540.138733,360.107483 540.409790,359.947784 
	C540.503052,358.485443 540.325195,357.182831 540.110718,355.533691 
	C541.363159,353.685120 542.652100,352.182983 544.081909,350.516754 
	C543.571899,349.904785 542.783691,348.958954 542.003723,347.648132 
	C543.306580,342.094849 547.276794,344.665894 549.199768,344.737396 
	C549.505615,342.162140 549.752930,340.079224 550.367981,337.957184 
	C552.159119,336.194977 553.537109,334.431427 555.022888,332.764038 
	C555.919800,331.757538 556.984192,330.900269 557.981140,330.295776 
	C558.791321,331.491943 559.593689,332.368256 560.502686,333.361084 
	C560.098328,334.345917 559.557922,335.661987 558.670898,337.042847 
	C557.092346,340.640778 555.860413,344.173889 554.628479,347.707001 
	C555.434692,348.455017 556.240967,349.203003 557.047241,349.951019 
	C555.725708,342.939209 560.695740,346.976685 561.631775,345.435577 
	C561.005066,342.189880 560.503784,339.593719 560.349731,336.942169 
	C562.706177,333.411560 565.058105,334.105988 567.368958,336.767883 
	C569.164734,331.985382 569.164734,331.985382 566.016968,330.636017 
	C571.084229,321.327332 561.898193,328.546051 560.990540,326.062836 
	C560.990540,327.044800 560.990540,328.026794 560.620117,329.001038 
	C559.509521,328.995911 558.769409,328.998505 557.705322,328.951111 
	C557.120117,328.437500 556.533081,327.856262 556.651062,327.529816 
	C557.325073,325.664978 558.166870,323.860779 559.216064,322.133057 
	C560.355164,322.461670 561.229919,322.693176 562.471497,322.944702 
	C565.178833,321.878479 567.519409,320.792206 570.720642,319.306519 
	C566.486572,317.124634 564.035583,314.138580 566.270447,309.022858 
	C565.500427,309.091797 564.730347,309.160736 563.960327,309.229706 
	C563.616516,311.495392 563.272705,313.761108 562.698608,316.018372 
	C562.468262,316.009918 562.007324,316.003662 561.968018,315.647766 
	C561.256287,314.376892 560.781250,313.112885 559.879700,312.602997 
	C553.522827,309.007690 548.685730,304.257996 547.526794,296.539246 
	C545.609375,297.070526 544.152039,297.474304 542.419067,297.954468 
	C542.419067,295.972778 542.419067,294.442719 542.419067,293.368256 
	C544.457764,293.123962 546.051270,292.933014 547.644836,292.742065 
	C547.642883,292.341034 547.640930,291.939972 547.638977,291.538910 
	C546.754150,291.347290 545.869263,291.155670 544.984436,290.964050 
	C545.247742,289.830994 545.511047,288.697937 545.926758,287.239075 
	C547.802490,286.634125 549.525757,286.354950 551.626953,286.034210 
	C556.213989,289.973083 561.419800,288.704559 567.077759,287.987885 
	C565.752319,289.903931 564.992493,291.002350 563.785095,292.747772 
	C565.808716,292.434113 567.050537,292.241638 568.647217,292.028687 
	C569.998535,294.074799 570.994934,296.141388 571.686523,298.448578 
	C570.442566,298.215088 569.503357,297.740997 568.564209,297.266907 
	C568.362549,297.559631 568.160889,297.852356 567.959229,298.145081 
	C569.651794,300.429749 571.344421,302.714447 573.036987,304.999115 
	C575.399597,305.654846 577.762207,306.310547 580.517822,306.936340 
	C580.635681,308.348511 580.360474,309.790649 579.726685,311.612946 
	C578.941956,311.998688 578.515869,312.004272 577.699158,312.009460 
	C575.249023,312.686157 573.189453,313.363251 572.464722,313.601501 
	C572.695557,316.913422 572.058105,319.697845 573.130859,320.768188 
	C575.653748,323.285248 578.993347,324.983765 582.002441,327.013519 
	C582.442871,327.647217 582.883362,328.280884 583.658691,329.368225 
	C584.480347,332.338654 584.967163,334.855377 585.547668,337.856293 
	C589.289246,338.556458 593.144653,339.277924 597.058838,340.343079 
	C598.141357,341.071167 599.164978,341.455566 600.188660,341.839996 
	C600.295471,341.393616 600.402344,340.947205 600.509155,340.500824 
	C599.339417,340.334045 598.169739,340.167267 596.993042,339.658966 
	C596.528198,338.189331 596.070435,337.061218 595.458313,335.552795 
	C596.991638,335.949921 598.602539,335.942078 599.623047,336.714386 
	C602.261108,338.710999 602.555176,336.853638 603.360107,335.043457 
	C605.129578,335.728149 606.555664,336.356201 607.981812,336.984283 
M577.223999,339.117737 
	C578.609192,339.392029 579.994385,339.666290 581.379517,339.940552 
	C581.379395,339.218384 581.379211,338.496216 581.379089,337.774048 
	C579.957092,338.006165 578.535034,338.238281 577.223999,339.117737 
M570.859253,309.280396 
	C569.850403,308.537720 568.841614,307.795013 567.832764,307.052338 
	C567.512817,307.656006 567.192871,308.259674 566.872925,308.863373 
	C568.176880,309.217316 569.480896,309.571259 570.859253,309.280396 
M552.449890,366.739777 
	C552.618286,367.734741 552.768311,368.734314 553.012573,369.710266 
	C553.024170,369.756317 553.873718,369.592590 554.333069,369.526550 
	C553.916199,368.613770 553.499268,367.700989 552.449890,366.739777 
M581.933838,333.560883 
	C581.246643,333.327362 580.559448,333.093872 579.872192,332.860382 
	C579.913452,333.527191 579.954651,334.193970 579.995911,334.860779 
	C580.653137,334.633606 581.310364,334.406433 581.933838,333.560883 
M564.681946,341.529449 
	C565.022766,340.978149 565.363525,340.426880 565.704346,339.875580 
	C565.429199,339.740295 565.153992,339.605042 564.878906,339.469757 
	C564.671509,340.009338 564.464111,340.548920 564.681946,341.529449 
M551.456970,290.840271 
	C551.411438,291.257416 551.365845,291.674561 551.320312,292.091705 
	C551.837830,291.999817 552.355347,291.907898 552.872864,291.815979 
	C552.563904,291.420471 552.255005,291.024933 551.456970,290.840271 
M569.358521,329.205078 
	C569.723877,329.253052 570.089294,329.301056 570.454651,329.349030 
	C570.457458,329.087921 570.460266,328.826782 570.463074,328.565674 
	C570.096436,328.606842 569.729736,328.648041 569.358521,329.205078 
M579.334656,329.630035 
	C579.410706,329.887634 579.486816,330.145264 579.562927,330.402863 
	C579.848938,330.123566 580.134949,329.844269 580.420898,329.564941 
	C580.164673,329.482758 579.908447,329.400574 579.334656,329.630035 
M569.821594,324.243958 
	C569.877686,324.413422 569.933777,324.582886 569.989868,324.752350 
	C570.044922,324.581787 570.099976,324.411224 569.821594,324.243958 
M576.244934,363.170288 
	C576.410217,363.115021 576.575439,363.059753 576.740723,363.004486 
	C576.574463,362.950684 576.408142,362.896881 576.244934,363.170288 
M552.597961,364.523621 
	C552.597961,364.523621 552.475098,364.600006 552.597961,364.523621 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M597.000000,339.999390 
	C593.144653,339.277924 589.289246,338.556458 585.547668,337.856293 
	C584.967163,334.855377 584.480347,332.338654 583.974182,329.410095 
	C585.808899,328.825745 587.842163,329.075714 589.475403,328.382751 
	C591.992554,327.314789 593.407837,327.553711 593.884216,330.984131 
	C595.100403,331.694061 596.572754,332.227539 597.661621,331.795197 
	C601.046692,330.451111 602.258057,332.212280 603.016846,334.986847 
	C602.555176,336.853638 602.261108,338.710999 599.623047,336.714386 
	C598.602539,335.942078 596.991638,335.949921 595.458313,335.552795 
	C596.070435,337.061218 596.528198,338.189331 596.993042,339.658722 
	C597.000000,340.000000 597.000000,339.999390 597.000000,339.999390 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M603.360107,335.043457 
	C602.258057,332.212280 601.046692,330.451111 597.661621,331.795197 
	C596.572754,332.227539 595.100403,331.694061 593.921936,331.322937 
	C596.955750,328.751099 600.004578,327.296722 603.914917,328.562042 
	C604.356873,328.705048 605.571838,326.685944 606.321655,325.596039 
	C607.353027,324.096863 608.282715,322.527679 609.631775,320.978394 
	C610.517334,321.296539 611.511719,321.806458 611.466614,321.926849 
	C609.878540,326.164703 608.192871,330.365967 606.866089,335.042511 
	C607.467773,336.001526 607.724182,336.490448 607.980591,336.979370 
	C607.980591,336.979370 607.989441,336.991180 607.985596,336.987732 
	C606.555664,336.356201 605.129578,335.728149 603.360107,335.043457 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M582.005493,326.622803 
	C578.993347,324.983765 575.653748,323.285248 573.130859,320.768188 
	C572.058105,319.697845 572.695557,316.913422 572.464722,313.601501 
	C573.189453,313.363251 575.249023,312.686157 577.674561,312.336853 
	C578.017761,313.101654 577.994995,313.538727 577.985291,313.988525 
	C577.998352,314.001282 577.986023,313.970215 577.617065,313.973297 
	C575.001526,315.919250 575.380554,319.671234 577.885620,321.083679 
	C579.359558,321.914764 580.718506,322.949799 582.129150,323.893127 
	C582.088989,324.672791 582.048767,325.452454 582.005493,326.622803 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M582.465637,323.921143 
	C580.718506,322.949799 579.359558,321.914764 577.885620,321.083679 
	C575.380554,319.671234 575.001526,315.919250 577.633362,314.218262 
	C578.136536,314.741638 578.254456,315.023132 578.372375,315.304626 
	C578.497498,315.215088 578.622559,315.125549 578.747681,315.036011 
	C578.489197,314.682587 578.230774,314.329193 577.972290,313.975769 
	C577.994995,313.538727 578.017761,313.101654 578.065125,312.337219 
	C578.515869,312.004272 578.941956,311.998688 579.688477,311.980011 
	C582.640869,315.377808 580.361816,316.777954 577.504578,318.408051 
	C578.803345,318.527283 580.339111,318.255554 581.341919,318.863251 
	C582.638000,319.648712 583.538879,321.114044 584.479919,322.383026 
	C584.531250,322.452271 583.388855,323.406769 582.465637,323.921143 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M585.449097,310.533508 
	C585.415466,310.477448 585.482788,310.589600 585.449097,310.533508 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M564.998047,275.274902 
	C565.625793,275.435242 566.283142,275.933197 566.969360,276.774536 
	C566.341370,276.616089 565.684509,276.114288 564.998047,275.274902 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M560.004517,279.005890 
	C559.907898,279.041748 559.806763,279.071655 559.846680,279.046021 
	C559.987793,278.990448 560.000000,278.999969 560.004517,279.005890 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M573.033813,304.661499 
	C571.344421,302.714447 569.651794,300.429749 567.959229,298.145081 
	C568.160889,297.852356 568.362549,297.559631 568.564209,297.266907 
	C569.503357,297.740997 570.442566,298.215088 571.680908,298.840576 
	C572.650330,299.754852 573.320435,300.517731 573.996460,301.634827 
	C573.678528,302.767365 573.354553,303.545624 573.033813,304.661499 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M575.712402,301.981232 
	C575.827271,302.407959 575.691223,302.858582 575.555115,303.309235 
	C575.375488,303.061615 575.195923,302.813965 574.988953,302.318390 
	C575.128174,302.048706 575.294800,302.026917 575.712402,301.981232 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M577.142700,585.639587 
	C576.445984,584.835205 575.891724,583.710144 575.574951,583.067200 
	C573.543274,583.649475 571.639038,584.195190 569.734741,584.740967 
	C569.247131,582.426086 568.759583,580.111267 568.136963,577.071167 
	C568.159363,574.441223 568.316895,572.536438 568.465515,570.739502 
	C567.299072,570.505981 566.154114,570.276794 565.009155,570.047546 
	C566.599304,567.705505 569.753113,565.636230 566.339478,562.421326 
	C567.031616,561.713745 567.672180,560.455078 568.424011,560.384644 
	C575.103455,559.758911 577.923401,563.062988 577.723755,569.917664 
	C577.666870,571.874207 579.700989,573.891602 580.976685,576.251953 
	C579.674683,576.379822 578.527283,576.492493 578.246582,576.520081 
	C577.900330,579.689087 577.592712,582.503967 577.142700,585.639587 
M573.688354,565.514709 
	C573.581970,566.482788 573.475525,567.450928 573.369080,568.419006 
	C573.833252,568.398193 574.297424,568.377441 574.761597,568.356628 
	C574.624512,567.410461 574.487488,566.464294 573.688354,565.514709 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M564.631897,570.017700 
	C566.154114,570.276794 567.299072,570.505981 568.465515,570.739502 
	C568.316895,572.536438 568.159363,574.441223 567.984253,576.760315 
	C567.532043,577.131775 567.097534,577.088989 566.335083,577.051147 
	C565.871521,574.673035 565.797302,572.471497 562.191589,573.607788 
	C561.281616,573.894531 559.868591,572.585327 558.012756,571.993774 
	C556.903503,571.978394 556.468201,571.978760 556.032959,571.979126 
	C556.032959,571.979065 556.001465,571.983276 556.054077,571.649048 
	C556.746887,570.212158 557.387207,569.109619 558.027527,568.007019 
	C558.777161,567.995544 559.526733,567.984070 560.651917,567.968750 
	C562.103210,568.639282 563.178894,569.313599 564.631897,570.017700 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M589.617920,583.841064 
	C589.166748,585.195007 588.166626,586.487305 587.637329,587.949951 
	C587.146484,589.306580 587.158508,590.845154 586.971619,592.649048 
	C585.131165,592.994507 583.268127,592.994507 581.478943,592.994507 
	C582.296326,591.161987 583.112427,589.332214 583.850952,587.118408 
	C585.607117,585.774170 587.440796,584.813965 589.617920,583.841064 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M559.347717,562.055298 
	C558.442566,560.345886 557.862915,558.658325 557.283264,556.970764 
	C558.928162,556.950134 560.572998,556.929504 562.599365,556.962158 
	C562.655701,559.210632 563.998474,562.495789 559.347717,562.055298 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M581.091003,569.371460 
	C581.508484,568.997559 581.936890,568.999939 582.687500,569.002441 
	C584.975891,572.556519 586.260986,576.097351 583.231140,580.173096 
	C582.426208,576.231934 581.764038,572.989807 581.091003,569.371460 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M589.366028,537.242432 
	C589.041443,537.422424 588.670959,537.378479 588.300476,537.334595 
	C588.391235,537.075745 588.482056,536.816956 588.572815,536.558167 
	C588.821899,536.711609 589.071045,536.865051 589.366028,537.242432 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M585.710571,582.640503 
	C585.843262,582.469727 586.075928,582.443054 586.308655,582.416382 
	C586.142639,582.539124 585.976624,582.661865 585.710571,582.640503 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M574.383057,529.960266 
	C573.377441,528.066895 572.745117,526.172791 572.112793,524.278748 
	C571.688721,524.813782 571.264587,525.348816 570.840454,525.883850 
	C568.911194,525.147583 566.981934,524.411316 565.052673,523.675110 
	C565.160950,523.160217 565.269226,522.645386 565.377441,522.130554 
	C566.793396,521.697754 568.209290,521.264954 569.625244,520.832092 
	C569.968933,520.390686 570.312683,519.949219 570.656433,519.507751 
	C568.446533,518.488647 566.236694,517.469543 564.026794,516.450439 
	C564.507263,515.825195 564.987793,515.199951 565.468262,514.574707 
	C566.524780,514.766785 567.581299,514.958862 568.932861,515.204590 
	C568.086853,512.690247 567.280151,510.292542 566.353210,507.537750 
	C565.282776,507.970795 564.091614,508.452698 562.522095,509.087646 
	C562.522095,507.539856 562.522095,506.470398 562.272217,504.842651 
	C562.350830,499.691132 561.378784,494.534393 567.286011,491.697784 
	C567.868774,493.905853 568.302490,495.549316 568.838257,497.579254 
	C572.068237,494.965179 575.501770,493.090149 577.603638,490.234833 
	C579.770447,487.291351 580.640564,483.393402 582.078003,479.913025 
	C582.558105,479.995911 583.038269,480.078796 583.518433,480.161682 
	C583.676941,481.931427 583.835510,483.701141 583.973999,485.246552 
	C585.198303,484.518097 586.583374,483.694000 588.720093,482.422668 
	C587.726807,486.580780 586.661133,489.496948 586.419922,492.479797 
	C586.172058,495.544159 584.664673,497.064362 581.726257,498.020325 
	C582.155090,499.200623 582.922729,500.306427 583.635437,501.446533 
	C584.291809,502.496552 584.889160,503.583527 585.512451,504.654236 
	C586.453003,503.897491 587.482727,503.226288 588.311401,502.362213 
	C589.331055,501.298981 590.183044,500.074921 591.449402,499.000061 
	C592.316833,500.817108 593.561462,502.750366 593.157104,504.234344 
	C592.784790,505.600677 590.639771,506.484039 588.941101,507.848969 
	C591.031006,509.176758 593.127930,510.509064 594.750854,511.540222 
	C595.399292,506.966583 595.983765,502.843689 596.584534,498.606201 
	C598.645508,498.091858 600.790466,497.556549 603.244202,497.135498 
	C604.382874,497.512177 605.212708,497.774597 606.042603,498.037018 
	C606.295715,498.509308 606.548828,498.981567 606.901611,500.107086 
	C607.000854,501.838196 607.000427,502.916046 606.949341,504.332336 
	C606.020874,505.505676 605.143127,506.340576 604.483398,506.968140 
	C605.943542,510.066162 607.288818,512.920288 608.293213,515.051270 
	C611.656738,515.051270 614.337830,515.051270 617.255249,515.312317 
	C618.654236,515.712341 619.816772,515.851257 620.979370,515.990234 
	C618.543701,516.968323 616.108032,517.946350 613.304565,518.672974 
	C612.628540,518.097961 612.320251,517.774353 612.011963,517.450806 
	C611.828552,517.662476 611.645081,517.874084 611.461609,518.085693 
	C611.971985,518.392883 612.482361,518.700073 612.992676,519.007324 
	C611.931885,519.421631 610.819397,520.273438 609.821411,520.155762 
	C607.959534,519.935974 606.164185,519.152771 605.307556,518.894897 
	C603.454773,520.741333 602.467285,522.494324 601.187988,522.742432 
	C600.265930,522.921204 598.955261,521.095947 597.542847,519.939270 
	C597.261780,520.537720 596.709900,521.712585 595.946167,523.338562 
	C593.520142,521.384521 591.413269,519.687561 588.885437,517.651489 
	C587.037781,519.920898 585.430542,521.895081 583.625488,524.112183 
	C582.823792,523.526184 582.105713,522.944580 581.332336,522.449219 
	C580.150513,521.692017 578.930542,520.994202 577.726685,520.271240 
	C577.477539,521.692078 577.106445,523.104553 577.008057,524.535767 
	C576.864319,526.627319 579.445679,529.768555 574.383057,529.960266 
M577.078430,515.465271 
	C579.153503,516.036316 581.228638,516.607361 583.303711,517.178406 
	C583.411499,516.643127 583.519287,516.107849 583.627075,515.572632 
	C581.816711,513.221863 579.927979,511.295197 577.078430,515.465271 
M602.320984,504.619873 
	C602.189209,503.654785 602.057434,502.689697 601.925720,501.724579 
	C601.497742,501.830353 601.069824,501.936157 600.641846,502.041992 
	C600.987366,502.924438 601.332886,503.806854 602.320984,504.619873 
M580.610779,489.485687 
	C580.833069,489.541290 581.055420,489.596924 581.277710,489.652527 
	C581.135559,489.515594 580.993469,489.378632 580.610779,489.485687 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M618.004883,523.996338 
	C619.744324,522.664734 621.483765,521.333191 623.664917,520.141235 
	C624.193909,520.331787 624.281250,520.382690 624.368530,520.433594 
	C624.244080,520.290222 624.119568,520.146912 623.995117,520.003540 
	C624.082153,518.443726 624.169189,516.883911 624.319824,514.184021 
	C629.404175,517.761475 627.720886,522.522888 628.607056,526.573975 
	C627.139099,527.989868 626.063477,528.986572 624.987793,529.983215 
	C624.318604,531.226196 623.448547,532.405396 623.029236,533.727661 
	C622.459778,535.523560 622.235107,537.428772 621.768066,539.758362 
	C620.087585,538.466064 618.832520,537.500977 618.240234,537.045532 
	C618.391663,535.027771 618.503662,533.534607 618.299866,531.616089 
	C617.990967,528.792542 617.997925,526.394470 618.004883,523.996338 
M625.584839,522.515381 
	C625.584839,522.515381 625.474731,522.582214 625.584839,522.515381 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M620.984741,515.581177 
	C619.816772,515.851257 618.654236,515.712341 617.406250,514.949341 
	C617.622498,512.300110 617.924194,510.274994 618.140747,508.821289 
	C616.412903,507.641785 615.207642,506.819061 614.001221,505.998169 
	C614.000000,506.000000 613.997803,506.002838 613.997803,506.002869 
	C615.845520,504.185364 617.693237,502.367889 620.269714,499.833588 
	C620.269104,499.833374 621.570618,500.205566 623.006775,500.616241 
	C619.655823,505.528412 619.655823,505.528412 623.891296,509.346436 
	C622.830627,511.476349 621.910400,513.324280 620.984741,515.581177 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M617.702087,524.191528 
	C617.997925,526.394470 617.990967,528.792542 617.956238,531.543945 
	C616.747314,532.647034 615.566162,533.396973 613.721008,534.568481 
	C614.022156,530.220276 613.119019,526.407288 617.702087,524.191528 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M624.993774,530.228760 
	C626.063477,528.986572 627.139099,527.989868 628.603760,526.991699 
	C630.040833,526.995178 631.088928,527.000122 632.527283,527.016235 
	C632.338745,531.615540 629.707825,532.536560 625.362305,531.007812 
	C624.995117,530.965515 624.999756,530.474304 624.993774,530.228760 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M616.649231,480.998413 
	C617.621582,482.163177 618.272278,483.280090 618.960266,484.705200 
	C618.579529,485.031342 618.161499,485.049225 617.432617,485.095001 
	C615.335144,484.416382 613.548401,483.709839 611.382751,483.000610 
	C611.634705,481.880615 612.265625,480.763367 612.947571,479.316040 
	C614.108215,479.640808 615.217834,480.295685 616.649231,480.998413 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M606.379028,498.027283 
	C605.212708,497.774597 604.382874,497.512177 603.100708,496.924652 
	C603.472717,495.741272 604.297119,494.882996 605.121521,494.024719 
	C605.841125,494.014435 606.560669,494.004120 607.640137,493.996277 
	C608.312500,495.575104 611.644348,498.116486 606.379028,498.027283 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M604.617920,533.416260 
	C604.064392,532.320007 603.667664,531.524292 603.270935,530.728577 
	C603.533997,530.443787 603.796997,530.158997 604.060059,529.874207 
	C605.214417,530.685303 607.085876,531.325073 607.331482,532.353271 
	C607.604248,533.494934 606.393738,534.990906 605.448975,537.235718 
	C605.080017,535.310547 604.927368,534.513672 604.617920,533.416260 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M607.000000,503.993896 
	C607.000427,502.916046 607.000854,501.838196 607.007446,500.354431 
	C608.330994,501.404480 609.648376,502.860443 610.983154,504.658020 
	C611.000427,504.999603 610.993713,505.009247 610.648682,504.976562 
	C609.202454,504.627228 608.101257,504.310547 607.000000,503.993896 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M614.963989,553.640381 
	C614.700317,554.086975 614.436584,554.277039 614.120667,554.768616 
	C613.440613,554.256775 612.871704,553.525269 612.302795,552.793762 
	C613.170227,552.963257 614.037598,553.132751 614.963989,553.640381 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M601.515625,529.366577 
	C600.936584,529.079529 600.581238,528.570496 600.225830,528.061523 
	C600.591125,527.863037 600.956482,527.664551 601.321777,527.466064 
	C601.460938,528.025574 601.600098,528.585083 601.515625,529.366577 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M588.002441,525.002441 
	C588.151367,524.131348 588.297852,523.257751 588.444397,522.384216 
	C588.819336,522.511414 589.194275,522.638672 589.569214,522.765930 
	C589.268799,523.473694 588.968445,524.181458 588.332214,524.943054 
	C587.996338,524.996887 588.000000,525.000000 588.002441,525.002441 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M611.006042,504.993774 
	C611.873657,505.147827 612.735596,505.307739 613.797729,505.735229 
	C613.997803,506.002838 614.000000,506.000000 613.662231,506.005920 
	C612.547485,505.677643 611.770630,505.343445 610.993713,505.009247 
	C610.993713,505.009247 611.000427,504.999603 611.006042,504.993774 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M630.203613,496.173340 
	C631.403870,496.437744 632.283997,496.786835 633.164124,497.135925 
	C632.921692,497.503448 632.679260,497.870972 632.436829,498.238495 
	C631.585754,497.578308 630.734619,496.918152 630.203613,496.173340 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M632.754883,531.356201 
	C633.006409,531.371216 633.142273,531.502563 633.278137,531.633911 
	C633.065125,531.580078 632.852173,531.526306 632.754883,531.356201 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M544.559143,290.983887 
	C545.869263,291.155670 546.754150,291.347290 547.638977,291.538910 
	C547.640930,291.939972 547.642883,292.341034 547.644836,292.742065 
	C546.051270,292.933014 544.457764,293.123962 542.419067,293.368256 
	C542.419067,294.442719 542.419067,295.972778 542.419067,297.954468 
	C544.152039,297.474304 545.609375,297.070526 547.526794,296.539246 
	C548.685730,304.257996 553.522827,309.007690 559.879700,312.602997 
	C560.781250,313.112885 561.256287,314.376892 561.591919,315.649353 
	C560.503418,316.004150 559.751709,316.001465 558.997253,315.675903 
	C558.774780,314.798462 558.555054,314.243866 558.335327,313.689270 
	C557.894287,314.794708 557.453308,315.900177 557.005005,317.250854 
	C556.997742,317.496033 556.987793,317.986572 556.639038,317.936462 
	C555.515869,316.248688 554.741455,314.611053 553.832520,312.670837 
	C550.746582,311.021057 547.799744,309.662811 544.813965,308.396271 
	C544.746399,308.367584 544.237854,309.378357 543.673096,309.692810 
	C543.742188,307.415466 544.073242,305.349548 544.404236,303.283661 
	C544.156006,303.166412 543.907776,303.049164 543.659546,302.931946 
	C541.737061,305.772858 539.814575,308.613800 537.811340,311.574066 
	C538.379700,313.742493 539.075745,316.398132 539.516724,318.080750 
	C537.953064,320.719940 536.817993,322.635651 535.147095,325.455902 
	C533.264221,323.710144 531.252441,321.844910 529.103943,319.852844 
	C529.056213,320.697113 529.019592,321.344513 528.611572,321.994507 
	C527.497498,321.996063 526.754700,321.994965 526.159058,321.679962 
	C529.986328,317.850800 526.495056,316.059937 524.482544,314.402344 
	C526.389832,312.604553 529.428833,310.974457 529.342407,309.531494 
	C529.213623,307.380096 530.396545,303.379974 526.000366,302.662170 
	C525.999695,301.885254 525.998596,301.441284 525.997559,300.997314 
	C528.126770,300.402740 530.256042,299.808167 532.470764,299.189728 
	C531.450928,295.544220 523.817505,297.335327 525.396729,291.916321 
	C527.042236,286.269592 531.462219,285.943420 537.217590,289.565704 
	C536.085938,293.108002 534.495178,297.378601 533.463257,301.780182 
	C533.180237,302.987335 534.570679,304.586792 535.193176,306.006256 
	C535.765747,305.915161 536.338379,305.824066 536.911011,305.732971 
	C537.282288,302.376984 538.051758,299.006592 537.931091,295.668396 
	C537.752380,290.723267 540.819031,290.881927 544.559143,290.983887 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M525.626221,301.039185 
	C525.998596,301.441284 525.999695,301.885254 526.040405,303.041443 
	C526.194641,305.320160 526.962097,307.847717 526.312500,308.288971 
	C523.279602,310.349121 519.855591,311.833405 517.212830,313.193451 
	C517.047180,314.172668 517.017395,315.626068 516.532043,316.906891 
	C515.864075,318.669830 514.851746,320.302277 513.780518,322.115906 
	C513.593018,322.598480 513.611816,322.955780 513.630615,323.313049 
	C513.758911,322.880524 513.887207,322.448029 514.340454,322.018799 
	C516.258057,321.147186 517.850586,320.272278 520.740112,318.684784 
	C519.594238,322.976440 518.794373,325.972137 517.647156,328.939514 
	C514.198792,327.937378 511.097839,326.963593 507.997070,325.743713 
	C507.997253,325.497620 508.000000,325.005493 508.000122,324.982208 
	C508.000214,324.958923 508.010101,324.913391 508.375793,324.943726 
	C509.909119,324.251831 511.076721,323.529633 512.244324,322.807434 
	C512.083252,322.527527 511.922241,322.247650 511.761200,321.967743 
	C510.497528,321.967743 509.233887,321.967743 507.600525,321.971436 
	C506.491486,321.978394 505.752136,321.981628 505.039520,321.689514 
	C506.061493,320.768036 507.056732,320.141937 508.719421,319.095886 
	C505.889923,317.896820 503.862854,317.037842 501.717712,316.128784 
	C501.091797,318.295502 500.558624,320.141083 500.025452,321.986694 
	C499.528931,321.731995 499.032379,321.477295 498.278625,321.113770 
	C497.040131,319.568420 496.058868,318.131866 495.456696,316.329956 
	C496.908234,315.003845 497.980652,314.043091 499.053101,313.082336 
	C498.756256,312.728088 498.459442,312.373840 498.162628,312.019592 
	C497.133209,312.341400 496.103821,312.663208 495.074402,312.984985 
	C494.637543,311.524811 494.200684,310.064606 494.260895,308.295959 
	C495.535309,307.297668 496.312622,306.607849 497.089905,305.917969 
	C497.856171,305.297943 498.622467,304.677917 499.932709,304.303040 
	C502.648499,305.034576 504.820312,305.520996 506.959686,306.320007 
	C506.284424,307.421356 505.641602,308.210083 504.859894,309.053589 
	C504.814423,309.072693 504.907806,309.036957 505.382935,309.000397 
	C506.783356,308.548065 507.802063,308.096588 508.820801,307.645081 
	C508.216309,307.094452 507.611847,306.543793 507.076965,305.620361 
	C510.590546,305.247589 514.034546,305.247589 517.770508,305.247589 
	C517.840759,303.883820 517.914307,302.455566 517.987793,301.027344 
	C520.410156,301.045227 522.832520,301.063141 525.626221,301.039185 
M501.789368,310.380096 
	C501.784912,310.780579 501.780457,311.181091 501.775970,311.581573 
	C501.942261,311.582428 502.252930,311.594666 502.253906,311.582489 
	C502.285278,311.184418 502.286896,310.783997 501.789368,310.380096 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M506.992126,306.007416 
	C504.820312,305.520996 502.648499,305.034576 500.237732,304.273468 
	C499.998779,302.369385 499.998779,300.740021 499.998779,298.407532 
	C500.366058,298.254852 501.463837,297.798584 503.729492,296.856842 
	C500.769226,296.259888 498.977295,295.898529 497.185394,295.537170 
	C497.024719,295.203003 496.864014,294.868866 496.703339,294.534729 
	C497.928864,294.148621 499.200012,293.864685 500.365417,293.343994 
	C501.293060,292.929504 502.091553,292.225861 502.970154,291.321442 
	C503.732788,292.663177 504.472687,294.334076 505.365997,296.351318 
	C506.911713,294.084564 507.112396,289.937408 512.107666,291.691193 
	C510.623962,292.904846 509.556885,293.777710 508.207672,294.881317 
	C508.692596,295.292175 509.325317,295.573334 509.519836,296.037933 
	C510.297272,297.895020 510.431732,300.561890 511.784637,301.472687 
	C512.943848,302.253082 515.440491,301.046814 517.666748,300.873230 
	C517.914307,302.455566 517.840759,303.883820 517.770508,305.247589 
	C514.034546,305.247589 510.590546,305.247589 507.073242,305.623779 
	C507.000000,306.000000 506.992126,306.007416 506.992126,306.007416 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M512.437500,295.537048 
	C512.398010,295.474915 512.476990,295.599152 512.437500,295.537048 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M489.332794,282.020935 
	C489.351715,281.909485 489.593719,281.788147 489.919067,281.832794 
	C490.002411,281.998779 489.555908,282.011078 489.332794,282.020935 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M394.987793,577.012207 
	C393.568878,578.669739 392.149994,580.327332 390.359131,581.993652 
	C389.987183,580.681946 389.987183,579.361389 389.987183,577.564270 
	C389.180847,578.457153 388.694336,578.995911 387.887268,579.889587 
	C387.352661,578.699646 386.896759,577.684937 386.130707,575.979919 
	C385.587433,577.520996 385.283295,578.383667 384.640686,579.615845 
	C383.867279,579.989380 383.432343,579.993469 382.998688,579.998779 
	C383.000000,580.000000 383.002625,580.002441 382.966217,579.662109 
	C381.888062,578.804810 380.362305,577.584595 379.896790,577.904968 
	C378.586945,578.806396 377.713409,580.341736 376.223785,582.173035 
	C376.257477,582.175842 375.142120,582.083191 374.026764,581.990601 
	C373.762451,581.496887 373.498138,581.003113 373.067200,579.915039 
	C371.970245,578.862061 371.039917,578.403503 370.095032,577.585327 
	C370.127289,576.508972 370.174042,575.792297 370.220764,575.075684 
	C371.346832,574.921265 373.050873,577.270386 373.909790,574.452576 
	C376.621429,577.689514 379.205292,576.816284 381.103363,574.453552 
	C382.542114,572.662659 383.117950,570.178528 384.077789,568.002930 
	C387.713074,568.976624 391.348389,569.950317 395.498505,571.061829 
	C395.403564,572.080750 395.211884,574.137939 395.010132,576.597534 
	C395.000061,577.000000 394.987793,577.012207 394.987793,577.012207 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M381.013580,582.994507 
	C381.339691,585.723389 381.665802,588.452209 381.994476,591.202393 
	C379.959076,591.202393 378.305603,591.202393 376.687897,591.202393 
	C375.752411,590.051697 374.372650,588.574768 373.302185,586.900208 
	C372.991455,586.414124 373.566193,585.362061 374.271179,584.284668 
	C376.876068,583.664734 378.944824,583.329651 381.013580,582.994507 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M370.308777,574.726257 
	C370.174042,575.792297 370.127289,576.508972 370.050964,577.838867 
	C370.003479,578.622437 369.985535,578.792725 369.967621,578.963013 
	C368.648560,579.322510 367.329529,579.682068 365.607117,579.710632 
	C365.513245,577.995239 365.822693,576.610779 366.148651,575.200928 
	C366.165131,575.175537 366.213959,575.142578 366.559265,575.078369 
	C367.903870,572.707458 368.903168,570.400818 369.902466,568.094116 
	C373.897308,569.887024 371.729675,572.164795 370.308777,574.726257 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M381.005737,582.997681 
	C378.944824,583.329651 376.876068,583.664734 374.399506,583.998962 
	C373.223480,583.573975 372.455292,583.149841 371.028229,582.362000 
	C372.346375,582.163208 372.889404,582.081299 373.729614,581.994995 
	C375.142120,582.083191 376.257477,582.175842 376.223785,582.173035 
	C377.713409,580.341736 378.586945,578.806396 379.896790,577.904968 
	C380.362305,577.584595 381.888062,578.804810 382.613586,579.702820 
	C381.864471,581.060974 381.431519,582.038086 380.998596,583.015137 
	C380.998566,583.015137 380.997925,583.000793 381.005737,582.997681 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M366.132172,575.226318 
	C365.822693,576.610779 365.513245,577.995239 365.263000,579.732483 
	C365.232544,580.510071 365.142822,580.934937 364.754333,581.796509 
	C363.704376,582.528687 362.953278,582.824097 362.128723,582.726257 
	C363.422546,579.968811 361.606934,575.778809 366.132172,575.226318 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M362.202148,583.119568 
	C362.953278,582.824097 363.704376,582.528687 364.742340,582.122498 
	C367.018707,584.887573 367.454437,587.077087 362.411652,586.647949 
	C361.909363,585.778381 361.810303,585.285645 361.789276,584.513184 
	C361.978882,583.862061 362.090515,583.490845 362.202148,583.119568 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M361.711304,584.792969 
	C361.810303,585.285645 361.909363,585.778381 362.046844,586.647705 
	C361.972229,587.362244 361.859161,587.700134 361.661682,588.288696 
	C360.281067,589.373047 358.984772,590.206665 357.355164,590.666870 
	C358.585022,588.459961 360.148163,586.626465 361.711304,584.792969 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M370.174866,579.232422 
	C369.985535,578.792725 370.003479,578.622437 370.065491,578.198608 
	C371.039917,578.403503 371.970245,578.862061 372.954285,579.657837 
	C372.132721,579.830688 371.257416,579.666260 370.174866,579.232422 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M381.294891,582.992920 
	C381.431519,582.038086 381.864471,581.060974 382.650024,580.043152 
	C383.002625,580.002441 383.000000,580.000000 382.998077,580.331543 
	C382.991455,581.106567 382.986786,581.550110 382.982086,581.993591 
	C382.518463,582.319336 382.054840,582.645020 381.294891,582.992920 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M383.308411,581.951416 
	C382.986786,581.550110 382.991455,581.106567 382.996765,580.330322 
	C383.432343,579.993469 383.867279,579.989380 384.626709,579.959351 
	C384.512360,580.591919 384.073517,581.250549 383.308411,581.951416 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M370.138977,584.641479 
	C370.210693,585.034912 370.031067,585.451538 369.851440,585.868164 
	C369.511841,585.719910 369.172241,585.571655 368.832642,585.423401 
	C369.184296,585.170471 369.535950,584.917542 370.138977,584.641479 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M371.973297,405.028137 
	C373.593018,403.138000 375.212738,401.247833 377.164703,398.969910 
	C377.709625,400.332092 378.098267,401.303650 378.862274,403.213531 
	C379.576447,401.548523 380.659882,399.930786 380.349365,399.592438 
	C378.845551,397.954010 376.967377,396.659149 374.409698,394.583405 
	C376.013947,393.649109 378.357941,392.284027 380.697083,390.921783 
	C374.850922,383.484497 376.633514,382.759857 370.156525,390.850037 
	C367.906006,388.397888 363.359314,387.374390 366.492340,382.164368 
	C366.852539,381.565338 366.040894,378.543793 367.675995,377.150055 
	C368.228485,376.679047 369.454132,376.997772 370.685730,376.978119 
	C370.999298,377.000702 370.998901,376.985107 370.998901,376.985107 
	C373.056671,378.449890 375.114441,379.914642 377.172241,381.379395 
	C378.380219,378.845306 379.689270,376.352417 380.746094,373.756805 
	C381.165894,372.725708 381.026642,371.466980 382.048950,370.430389 
	C380.259064,373.980316 385.433594,378.066895 381.141541,381.473175 
	C380.828766,381.721466 381.363525,383.345398 381.800873,384.173004 
	C383.073853,386.581970 387.066162,388.653748 382.511902,391.607635 
	C384.616394,392.610626 382.591766,396.454865 386.311249,396.285919 
	C387.096008,395.966431 387.515320,395.959076 387.954468,396.330872 
	C387.490540,398.066864 387.006744,399.423676 386.738251,400.176697 
	C388.616913,401.955383 390.683807,403.912262 393.262451,406.353668 
	C392.747192,401.275452 392.280640,396.677521 391.814117,392.079559 
	C395.242676,401.140533 395.242676,401.140533 395.967285,400.934662 
	C395.299316,395.520203 394.649048,390.249359 394.156067,384.658081 
	C394.881348,382.553101 395.449341,380.768585 396.017334,378.984070 
	C397.044098,375.888367 398.882874,374.035248 402.764313,375.012970 
	C401.579498,378.065125 400.346893,381.240356 398.631561,385.659210 
	C404.550232,383.456696 408.384369,383.290192 411.367706,387.349701 
	C411.202545,381.980621 410.127075,380.977509 403.782227,380.924744 
	C405.966370,379.114960 407.689728,377.687012 409.758423,376.507477 
	C411.321075,377.643951 412.538422,378.531982 414.402893,379.892151 
	C415.738098,371.302002 416.867340,364.037140 417.989197,356.819580 
	C419.042999,357.132263 420.539459,357.576294 422.140076,358.289581 
	C422.814545,359.070618 423.384827,359.582397 423.927612,360.445923 
	C423.329559,363.093384 422.759003,365.389069 422.188446,367.684753 
	C421.626892,368.017700 421.065308,368.350677 420.503754,368.683624 
	C422.743835,369.789886 424.983948,370.896179 427.186646,372.258118 
	C427.106445,372.680634 427.063599,372.847504 426.766846,373.198242 
	C425.339905,376.255585 424.166840,379.129028 422.638062,382.059052 
	C420.293518,384.346649 418.304626,386.577637 416.471832,388.633575 
	C417.620636,390.087952 418.826538,391.614532 419.869263,393.396301 
	C418.806305,394.113251 417.906494,394.575012 416.667389,395.017395 
	C415.875793,394.979370 415.423492,394.960663 414.918243,394.598022 
	C413.312988,394.539886 411.760681,394.825714 409.740417,395.197723 
	C406.910828,392.380402 404.776337,392.579376 403.513519,396.503571 
	C406.056458,397.037354 408.593262,397.569855 410.971863,398.380859 
	C410.163513,399.729126 409.513306,400.798920 408.574707,402.075134 
	C407.410828,404.522888 406.535339,406.764252 405.324982,409.005127 
	C404.416260,409.776459 403.842407,410.548187 403.303833,411.626282 
	C403.508636,412.939026 403.678223,413.945465 403.487213,414.958496 
	C401.733765,414.644836 400.340912,414.324585 398.660950,413.852783 
	C398.251465,412.135620 398.129150,410.570038 398.269165,408.790497 
	C398.460083,407.294708 398.388672,406.012909 398.317230,404.731079 
	C398.011383,404.778778 397.705505,404.826447 397.399628,404.874115 
	C397.596893,406.246613 397.794159,407.619110 397.644409,409.020538 
	C395.806396,409.623535 394.315399,410.197571 392.291901,410.976685 
	C392.644775,409.675903 392.835571,408.972443 393.051483,408.176605 
	C388.027161,407.259979 388.543365,411.095001 387.661804,414.040039 
	C387.027679,414.792419 386.728088,415.501068 386.428497,416.209686 
	C386.794373,416.337982 387.160248,416.466278 387.526123,416.594543 
	C387.685303,415.730927 387.844482,414.867279 388.284302,414.256256 
	C389.057465,416.309998 389.043884,418.540924 390.138916,419.830322 
	C393.209869,423.446381 391.210846,424.819580 388.000000,426.000000 
	C387.432861,424.803558 386.865723,423.607117 386.298615,422.410645 
	C385.201965,423.045502 384.105347,423.680359 383.004333,424.657593 
	C383.000000,425.000000 382.998779,425.001221 382.998779,425.001221 
	C379.320343,420.997101 382.597076,417.414215 383.627777,413.661926 
	C384.129425,411.835571 384.298676,409.917938 384.711792,407.493500 
	C382.334412,409.750885 380.762238,411.243713 378.935791,412.977936 
	C377.771484,411.946350 376.705017,411.001404 376.466766,410.790314 
	C374.432220,411.481934 372.906433,412.000580 371.224976,411.911743 
	C371.370636,409.212219 371.671936,407.120178 371.973297,405.028137 
M376.256836,402.827393 
	C376.377258,402.994202 376.497650,403.160980 376.618042,403.327789 
	C376.593628,403.095612 376.569214,402.863464 376.256836,402.827393 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M359.991760,425.988708 
	C359.991760,427.289978 359.991760,428.591217 359.991760,429.892487 
	C360.506317,429.993835 361.020874,430.095215 361.535431,430.196564 
	C362.446503,426.454498 363.357574,422.712433 364.213074,419.198639 
	C361.618256,418.470459 359.661987,417.921448 357.705719,417.372467 
	C358.153900,417.060425 358.602051,416.748383 359.050201,416.436340 
	C358.895966,415.047211 358.741760,413.658081 358.294495,411.712585 
	C358.333099,409.106476 358.664703,407.056763 358.996338,405.007019 
	C359.449829,404.368591 359.903320,403.730164 360.541779,402.831329 
	C361.661743,405.674683 361.428040,409.432739 365.930054,410.350586 
	C366.672546,411.286346 366.845612,412.136658 367.018646,412.986969 
	C367.434814,417.433319 367.928528,421.875031 368.195129,426.330322 
	C368.242676,427.124725 367.435577,428.625641 366.876129,428.700348 
	C360.785461,429.513916 363.665527,432.049408 365.619446,434.520264 
	C366.081573,435.104645 366.276733,435.900085 366.933441,437.342285 
	C367.739258,435.573517 368.229797,434.496857 368.720306,433.420197 
	C369.199738,433.407928 369.679169,433.395630 370.158600,433.383362 
	C370.293030,436.295135 370.427460,439.206909 370.561859,442.118683 
	C370.213043,442.193695 369.864258,442.268707 369.515442,442.343750 
	C369.175140,441.076813 368.834839,439.809875 368.272705,437.717133 
	C366.317383,438.982666 363.688263,439.714478 363.122894,441.220886 
	C360.868835,447.226349 359.735016,443.482971 358.288483,440.939911 
	C357.968994,440.378265 357.599274,439.845184 357.543945,439.757965 
	C361.961365,433.157867 354.268372,429.013062 354.605896,423.454926 
	C354.063751,425.371368 353.521576,427.287811 352.979431,429.204254 
	C352.647461,429.195435 352.315521,429.186615 351.983551,429.177826 
	C351.557526,426.522552 351.131500,423.867310 350.705475,421.212067 
	C350.988434,421.147827 351.271423,421.083618 351.554382,421.019379 
	C351.847626,422.055298 352.140869,423.091217 352.374390,423.916138 
	C353.120972,422.423187 354.148773,419.543762 354.585541,419.630829 
	C357.051361,420.122101 359.886169,420.744354 360.047363,424.582275 
	C360.020447,424.975037 359.928558,425.055267 359.654785,424.925690 
	C358.737671,424.889435 358.094330,424.982758 357.451019,425.076050 
	C358.303833,425.387024 359.156647,425.697998 360.005066,426.004761 
	C360.000671,426.000549 359.991760,425.988708 359.991760,425.988708 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M422.035889,358.020294 
	C420.539459,357.576294 419.042999,357.132263 417.989197,356.819580 
	C416.867340,364.037140 415.738098,371.302002 414.402893,379.892151 
	C412.538422,378.531982 411.321075,377.643951 410.072418,376.377319 
	C410.481354,375.996033 410.921661,375.993286 411.967041,375.764771 
	C413.271576,372.347778 413.970978,369.156586 414.419281,367.111053 
	C412.365173,363.960449 410.772552,361.517639 408.954041,358.728424 
	C408.598297,360.223724 408.271027,361.599304 407.943787,362.974884 
	C407.208771,362.982880 406.473785,362.990906 405.330902,362.657806 
	C404.493774,361.127808 404.064514,359.938873 403.635254,358.749969 
	C403.453857,359.124420 403.272491,359.498871 403.091125,359.873322 
	C401.684814,359.465851 400.283844,359.038666 398.870361,358.657745 
	C397.923462,358.402557 396.958862,358.212952 396.002197,357.993988 
	C394.023315,354.872467 390.028687,352.360626 392.955963,347.387054 
	C393.534454,348.447510 394.066742,349.890137 394.664246,351.509491 
	C396.115021,351.342560 397.656586,351.165222 399.564453,351.345398 
	C399.740784,352.591553 399.550781,353.480164 399.050934,355.817444 
	C401.659729,354.164917 403.343445,353.098358 405.027130,352.031799 
	C405.518127,352.283112 406.009125,352.534454 406.750061,352.892883 
	C407.000000,353.000000 406.991455,352.992310 406.991455,352.992310 
	C408.788666,354.602112 410.461823,356.416870 412.490082,357.645142 
	C412.885590,357.884674 415.120667,356.033020 415.627380,354.787903 
	C415.931396,354.040802 414.530426,352.599884 413.241211,350.306335 
	C416.469910,352.127472 420.006653,350.304993 420.890076,354.526550 
	C420.968048,354.899078 423.006836,354.861298 424.502228,355.323364 
	C423.925018,356.428497 422.980469,357.224365 422.035889,358.020294 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M359.362427,485.802216 
	C358.715942,483.840790 358.353668,481.785370 357.991821,479.391663 
	C359.297455,477.328491 360.602631,475.603546 361.907776,473.878601 
	C362.536530,474.093170 363.165283,474.307770 363.794037,474.522339 
	C363.009979,471.549408 362.225952,468.576508 361.236572,465.341309 
	C362.206299,464.908905 363.381409,464.738800 364.996063,464.179932 
	C365.711578,463.594421 365.900360,463.334381 366.002014,463.011078 
	C366.831787,460.507935 367.661560,458.004822 368.491364,455.501678 
	C368.826263,455.469086 369.161194,455.436493 369.496124,455.403900 
	C369.775146,457.302002 370.054138,459.200104 370.499695,462.231171 
	C371.442200,460.183014 371.983093,459.007507 372.223206,458.485748 
	C374.119629,459.586487 376.124756,460.750336 378.481750,461.886810 
	C376.607697,467.311371 374.381775,472.763336 371.998352,477.611267 
	C372.960999,473.028381 373.405365,469.321442 368.361298,467.827606 
	C367.147766,472.228668 365.965332,476.516815 364.484253,480.966827 
	C363.425476,482.402527 362.665344,483.676361 361.905182,484.950195 
	C361.152344,485.202881 360.399475,485.455536 359.362427,485.802216 
M375.515381,463.395447 
	C375.515381,463.395447 375.583099,463.505096 375.515381,463.395447 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M347.998047,392.998779 
	C348.103821,394.095551 348.209595,395.192322 348.315369,396.289093 
	C348.605591,396.351624 348.895782,396.414154 349.185974,396.476685 
	C350.899109,391.319885 352.612244,386.163086 354.325378,381.006287 
	C354.784180,381.152557 355.243011,381.298828 355.701813,381.445068 
	C354.783356,390.055389 353.864899,398.665710 352.899689,407.714600 
	C349.964264,407.900055 347.300995,408.068329 344.637756,408.236603 
	C344.454193,407.833954 344.270660,407.431305 344.087097,407.028656 
	C345.320160,406.323334 347.135559,405.898285 347.643036,404.843781 
	C348.320251,403.436523 348.730347,400.843842 347.934753,399.988403 
	C345.789581,397.682068 345.680389,395.753723 347.717987,393.204346 
	C348.000000,393.000000 347.998047,392.998779 347.998047,392.998779 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M388.986816,438.840790 
	C387.338409,438.105560 385.690002,437.370361 383.753174,436.506531 
	C383.213287,438.734863 382.877045,440.122559 382.262817,441.758789 
	C379.361664,442.189667 377.329681,441.741608 378.104980,438.347809 
	C380.738708,439.177856 381.004059,438.590790 381.254761,431.415680 
	C381.680908,430.979034 381.857819,430.968689 382.034698,430.958374 
	C383.355591,431.068878 382.693512,435.983185 386.102234,432.396484 
	C387.419098,433.468781 388.171143,434.548798 388.923157,435.628845 
	C389.288086,435.579681 389.653046,435.530548 390.017975,435.481384 
	C390.017975,433.661377 390.017975,431.841370 390.161041,429.717224 
	C390.990417,429.094482 391.676727,428.775848 392.363037,428.457214 
	C392.504395,429.379486 392.851532,430.331329 392.724609,431.215057 
	C392.615509,431.974823 391.735413,432.622131 391.620331,433.383331 
	C391.510529,434.109955 391.948334,434.919373 392.145416,435.692383 
	C392.640137,435.322510 393.134857,434.952667 393.629578,434.582794 
	C393.868927,433.063507 394.108307,431.544220 394.318237,430.211792 
	C395.185699,432.406677 396.093781,434.704315 397.000946,437.000977 
	C397.000000,437.000000 396.997955,436.998047 396.984894,437.353394 
	C396.268555,439.098389 395.565277,440.488007 394.756470,442.086182 
	C395.560028,442.577271 396.716492,443.284027 398.577911,444.421631 
	C396.900085,445.159393 395.944092,445.579773 394.658661,445.833832 
	C392.341095,443.554230 393.707184,438.815948 388.986816,438.840790 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M395.734589,358.206665 
	C396.958862,358.212952 397.923462,358.402557 398.870361,358.657745 
	C400.283844,359.038666 401.684814,359.465851 403.091125,359.873322 
	C403.272491,359.498871 403.453857,359.124420 403.635254,358.749969 
	C404.064514,359.938873 404.493774,361.127808 404.963348,362.657227 
	C403.735443,365.804474 402.467194,368.611176 400.994476,371.870361 
	C399.047211,370.449188 397.127960,369.048462 395.191376,367.635071 
	C394.217010,368.963226 394.154358,368.978699 393.003418,367.316803 
	C393.822327,364.139771 394.644653,361.279572 395.734589,358.206665 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M369.605988,421.010376 
	C371.146667,416.509369 374.483032,415.498352 377.987854,415.257660 
	C377.987854,420.534576 377.987854,425.535492 377.987854,430.737366 
	C375.266571,429.440216 372.972961,428.346924 370.679321,427.253632 
	C371.028351,427.085876 371.377380,426.918152 371.726440,426.750397 
	C370.992828,424.948639 370.259247,423.146912 369.605988,421.010376 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M364.799896,350.020203 
	C366.040649,354.956055 369.122009,351.239319 371.145477,351.284302 
	C371.997009,351.303253 372.888641,349.519684 373.918854,348.260101 
	C375.876068,349.524750 377.676453,351.084778 379.749634,353.147888 
	C380.042328,354.057892 380.062225,354.464844 380.082153,354.871765 
	C379.371216,355.874725 378.660217,356.877655 377.061798,359.132507 
	C375.767334,357.286346 374.718811,355.790955 373.111572,354.010986 
	C371.307007,354.484985 369.972321,355.132202 368.841156,356.034515 
	C367.787567,356.875031 366.950470,357.987000 366.018066,358.979431 
	C366.018066,358.979462 365.567139,359.028320 365.302704,358.677307 
	C364.005768,357.189087 362.973267,356.051849 361.940765,354.914612 
	C362.496277,353.465240 363.051758,352.015869 363.829102,350.292603 
	C364.050934,350.018707 364.550293,350.013916 364.799896,350.020203 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M407.924561,363.330200 
	C408.271027,361.599304 408.598297,360.223724 408.954041,358.728424 
	C410.772552,361.517639 412.365173,363.960449 414.419281,367.111053 
	C413.970978,369.156586 413.271576,372.347778 412.297607,375.758667 
	C412.023071,375.016418 412.023071,374.054535 412.023071,373.040894 
	C410.712097,373.310303 409.638245,373.530945 408.079590,373.851257 
	C408.389191,371.957703 408.613770,370.584198 408.876160,368.979584 
	C407.716400,368.860565 406.575623,368.743500 405.282837,368.610809 
	C406.271393,366.754242 407.088379,365.219879 407.924561,363.330200 
M410.518738,366.410492 
	C410.518738,366.410492 410.582733,366.520233 410.518738,366.410492 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M361.555420,354.938995 
	C362.973267,356.051849 364.005768,357.189087 365.077118,358.700592 
	C364.749573,363.819580 364.383209,368.564301 364.009064,373.653839 
	C364.001282,373.998688 364.014954,373.984680 364.014954,373.984680 
	C361.803192,373.994812 360.666016,373.547150 361.472412,370.770752 
	C362.348297,367.755157 362.584259,364.536133 362.908295,361.386139 
	C363.045319,360.054016 362.694672,358.671722 362.591553,357.585236 
	C359.993805,358.834137 357.889862,359.987061 355.654785,360.761810 
	C355.333893,360.873047 354.380646,359.160156 353.732544,358.307861 
	C354.436737,357.756165 354.625671,357.556152 354.667450,357.583221 
	C355.267029,357.971649 355.846832,358.390594 355.768677,358.335571 
	C357.726929,357.113007 359.448517,356.038208 361.555420,354.938995 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M380.432312,354.910736 
	C380.062225,354.464844 380.042328,354.057892 380.027679,353.344940 
	C382.285522,351.914917 384.235413,350.854523 381.983429,347.822479 
	C381.425049,347.070679 381.925934,345.141052 382.492249,344.035919 
	C383.358215,342.346100 384.704224,340.902313 385.293762,340.103180 
	C387.081207,340.350098 388.330200,340.522644 389.971802,340.749451 
	C389.448242,342.358734 388.705658,344.641144 387.972473,346.894775 
	C386.817505,346.344055 385.730377,345.825684 384.698120,345.333466 
	C382.588104,348.587067 386.898987,353.832001 380.432312,354.910736 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M395.959320,378.643005 
	C395.449341,380.768585 394.881348,382.553101 393.794373,384.703430 
	C391.181488,386.714294 389.087555,388.359283 386.996704,390.002014 
	C386.999756,389.999786 387.007324,389.992767 387.007324,389.992767 
	C387.082703,388.428619 386.754852,386.691162 387.334076,385.343536 
	C388.082642,383.601837 389.555756,382.171539 390.858521,380.303467 
	C391.000153,379.999847 390.987671,380.012543 390.987671,380.012543 
	C391.696381,379.448029 392.327881,378.658600 393.135254,378.381348 
	C393.968140,378.095337 394.972168,378.307648 395.959320,378.643005 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M359.948486,379.343994 
	C361.136841,379.815399 361.960510,380.316010 362.784180,380.816620 
	C359.563843,384.194733 362.547150,388.656494 361.425903,393.021912 
	C356.486023,387.410370 356.219696,384.535461 359.948486,379.343994 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M346.580231,357.603027 
	C347.473206,358.189178 348.142059,358.987213 349.269714,360.332672 
	C349.452332,358.420868 349.559753,357.296234 349.693481,355.895966 
	C353.336975,359.032257 353.256226,360.261139 349.434814,365.572632 
	C345.528625,364.129761 346.146729,360.891235 346.580231,357.603027 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M358.759277,404.821594 
	C358.664703,407.056763 358.333099,409.106476 357.991425,411.553192 
	C356.583679,413.217804 355.186005,414.485474 352.979828,416.486359 
	C352.462860,413.812897 352.124115,412.061157 351.785400,410.309418 
	C352.021454,410.223999 352.257507,410.138580 352.493561,410.053162 
	C352.873199,410.902161 353.252838,411.751190 353.632507,412.600220 
	C353.876343,412.579590 354.120178,412.558990 354.364014,412.538361 
	C354.364014,410.486298 354.364014,408.434265 354.364014,407.233612 
	C355.778076,405.585541 356.829132,404.360535 357.779480,403.252899 
	C357.922119,403.518524 358.222168,404.077332 358.759277,404.821594 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M371.007080,377.000763 
	C371.875946,375.948456 372.737000,374.896088 373.521790,373.936920 
	C373.220215,371.672943 372.904724,369.304596 372.589233,366.936249 
	C373.107025,366.796844 373.624817,366.657410 374.142578,366.518005 
	C374.715546,368.064636 375.291138,369.610352 375.857208,371.159485 
	C375.912598,371.311096 375.899231,371.486389 375.934296,371.647125 
	C376.911377,376.125641 376.911743,376.125549 371.359741,376.989655 
	C370.998901,376.985107 370.999298,377.000702 371.007080,377.000763 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M380.725647,448.103821 
	C380.604675,450.945648 380.217712,453.894165 379.471283,456.915100 
	C374.630402,458.350616 374.603943,458.332275 374.373474,453.286316 
	C375.886230,453.820129 377.252106,454.302094 378.554565,454.761688 
	C379.239838,452.405090 379.849701,450.307770 380.725647,448.103821 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M367.333557,412.854248 
	C366.845612,412.136658 366.672546,411.286346 366.243286,410.229248 
	C366.847839,409.345001 367.725616,408.687897 368.564758,407.984711 
	C369.536682,407.170319 370.474579,406.315399 371.700256,405.253113 
	C371.671936,407.120178 371.370636,409.212219 370.992981,411.635193 
	C369.827271,412.217896 368.737854,412.469696 367.333557,412.854248 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M390.017975,430.021362 
	C390.017975,431.841370 390.017975,433.661377 390.017975,435.481384 
	C389.653046,435.530548 389.288086,435.579681 388.923157,435.628845 
	C388.171143,434.548798 387.419098,433.468781 386.371338,432.236877 
	C386.464874,430.106842 386.854218,428.128723 387.620911,426.453552 
	C388.671539,427.844788 389.344757,428.933075 390.017975,430.021362 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M391.006531,379.993469 
	C390.112793,379.835052 389.212646,379.682983 388.337372,379.535156 
	C388.955475,377.098907 389.501373,374.947083 390.023315,372.397278 
	C389.999298,371.999237 390.011200,372.027771 390.011200,372.027771 
	C391.612152,372.586548 393.213104,373.145355 394.063507,373.442169 
	C392.927979,375.573425 391.951996,377.405121 390.981873,379.624695 
	C390.987671,380.012543 391.000153,379.999847 391.006531,379.993469 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M366.413574,358.974487 
	C366.950470,357.987000 367.787567,356.875031 368.841156,356.034515 
	C369.972321,355.132202 371.307007,354.484985 372.791382,353.871399 
	C373.689148,359.099609 369.406586,357.976440 366.413574,358.974487 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M363.977600,402.299194 
	C364.320312,399.322754 364.673309,396.715759 365.026306,394.108765 
	C365.516785,394.193359 366.007294,394.277924 366.497772,394.362488 
	C365.941437,397.461304 365.388824,400.560791 364.813263,403.656036 
	C364.792877,403.765656 364.545044,403.832977 364.403198,403.920013 
	C364.264740,403.502899 364.126282,403.085785 363.977600,402.299194 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M376.240234,343.439026 
	C377.124207,342.178162 378.214569,341.133179 379.656555,340.039795 
	C379.872345,341.497681 379.736481,343.004059 379.329132,344.766052 
	C378.187317,344.566101 377.316956,344.110535 376.240234,343.439026 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M387.934631,395.951691 
	C387.515320,395.959076 387.096008,395.966431 386.363129,395.967285 
	C386.354980,394.227051 386.660400,392.493317 386.986572,390.376160 
	C387.007324,389.992767 386.999756,389.999786 387.304688,390.159058 
	C387.717987,392.196136 387.826324,394.073914 387.934631,395.951691 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M390.013672,372.005157 
	C388.995209,372.314728 387.962372,372.618378 386.929535,372.922028 
	C386.740631,372.504181 386.551758,372.086334 386.362854,371.668488 
	C387.708496,371.022003 389.054138,370.375519 390.399780,369.729065 
	C390.342560,370.309418 390.285309,370.889801 390.119629,371.748962 
	C390.011200,372.027771 389.999298,371.999237 390.013672,372.005157 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M359.562805,394.704834 
	C359.291077,395.567657 358.819275,396.178833 358.347473,396.790009 
	C358.040436,396.498810 357.733429,396.207642 357.426392,395.916443 
	C358.071838,395.428680 358.717285,394.940918 359.562805,394.704834 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M383.000610,424.999390 
	C383.711884,425.491547 384.422577,425.984314 385.133240,426.477081 
	C384.887848,426.771393 384.642456,427.065704 384.397064,427.360016 
	C383.946838,426.783783 383.496613,426.207581 383.022583,425.316284 
	C382.998779,425.001221 383.000000,425.000000 383.000610,424.999390 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M382.021667,430.656586 
	C381.857819,430.968689 381.680908,430.979034 381.237854,430.992249 
	C380.976990,430.249329 380.982269,429.503540 380.987549,428.757751 
	C381.327942,429.290100 381.668304,429.822449 382.021667,430.656586 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M363.732605,388.975372 
	C364.250458,389.114807 364.565521,389.454590 364.880585,389.794373 
	C364.578308,390.067566 364.276001,390.340729 363.973724,390.613922 
	C363.825775,390.134521 363.677795,389.655090 363.732605,388.975372 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M404.565704,371.554291 
	C405.253845,371.586548 405.763702,371.815460 406.235962,372.105560 
	C406.273407,372.128571 406.050507,372.575378 405.948608,372.825256 
	C405.425018,372.472382 404.901398,372.119507 404.565704,371.554291 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M365.840607,462.769714 
	C365.900360,463.334381 365.711578,463.594421 365.214661,463.896240 
	C364.474060,463.556427 363.954376,463.111603 362.431305,461.807922 
	C364.372711,462.238556 365.025970,462.383484 365.840607,462.769714 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M360.064392,424.932739 
	C360.628387,425.299469 361.148468,425.708527 361.668549,426.117584 
	C361.276917,426.138885 360.885254,426.160217 360.242676,426.085144 
	C359.991760,425.988708 360.000671,426.000549 359.989014,425.765930 
	C359.977356,425.531342 359.928558,425.055267 359.928558,425.055267 
	C359.928558,425.055267 360.020447,424.975037 360.064392,424.932739 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M392.834961,366.757660 
	C392.268616,366.957184 391.537201,366.914337 390.805817,366.871521 
	C390.828827,366.658813 390.851868,366.446106 390.874878,366.233398 
	C391.473236,366.327393 392.071564,366.421356 392.834961,366.757660 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M363.993713,374.003784 
	C364.306000,374.680878 364.625824,375.352875 364.945648,376.024841 
	C364.724854,376.081696 364.504059,376.138519 364.283295,376.195374 
	C364.196808,375.659515 364.110321,375.123688 364.019409,374.286255 
	C364.014954,373.984680 364.001282,373.998688 363.993713,374.003784 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M343.371338,434.234497 
	C343.517517,433.783997 343.801514,433.509827 344.105194,433.259583 
	C344.118317,433.248810 344.312683,433.458069 344.422607,433.564789 
	C344.119354,433.848297 343.816101,434.131805 343.371338,434.234497 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M392.673584,339.153931 
	C392.926788,339.480682 392.937469,339.904358 392.927399,340.645233 
	C392.509277,340.844177 392.111938,340.725891 391.714600,340.607605 
	C391.953430,340.155334 392.192230,339.703094 392.673584,339.153931 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M373.368652,451.256165 
	C373.383820,451.007538 373.507721,450.874298 373.631592,450.741089 
	C373.580170,450.951233 373.528778,451.161407 373.368652,451.256165 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M370.456116,372.536011 
	C370.421997,372.479004 370.490265,372.593048 370.456116,372.536011 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M348.000610,393.001068 
	C348.092743,392.965485 348.184265,392.928864 348.136902,392.945496 
	C347.998047,392.998779 348.000000,393.000000 348.000610,393.001068 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M366.534729,441.552368 
	C366.470947,441.593414 366.598511,441.511292 366.534729,441.552368 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M367.553711,392.458923 
	C367.593262,392.521454 367.514130,392.396362 367.553711,392.458923 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M335.019836,432.985718 
	C335.159424,433.137085 335.277344,433.307892 335.395264,433.478668 
	C335.292847,433.433289 335.190460,433.387939 335.024109,433.195862 
	C334.960144,433.049164 334.998169,433.005127 335.019836,432.985718 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M334.341064,416.407898 
	C333.645386,415.458557 333.194611,414.314117 332.743866,413.169678 
	C333.053680,413.064392 333.363495,412.959106 333.673309,412.853790 
	C333.977539,413.973450 334.281769,415.093109 334.341064,416.407898 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M359.445007,328.531494 
	C359.408844,328.472290 359.481201,328.590668 359.445007,328.531494 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M447.877563,393.563599 
	C447.173492,393.391815 446.469421,393.220001 445.393463,392.580933 
	C444.552338,387.619324 444.083099,383.125031 443.663483,379.105988 
	C442.346802,377.314667 441.113556,375.636871 439.919373,373.587006 
	C440.630859,370.384003 436.263672,368.012604 439.185394,365.001282 
	C440.092010,364.066833 441.140900,363.270355 442.124695,362.410736 
	C441.844116,361.956299 441.563568,361.501892 441.283020,361.047455 
	C439.432953,362.085358 437.582916,363.123230 435.823029,364.110535 
	C435.054962,363.488800 433.965088,362.606598 432.546387,361.458221 
	C432.713287,360.415710 432.993835,358.663452 433.274353,356.911163 
	C433.510620,357.218384 433.746857,357.525604 433.983093,357.832825 
	C436.293518,355.984619 438.603943,354.136383 441.241058,352.026855 
	C438.524994,350.434540 436.405212,349.191833 434.172546,347.882904 
	C435.544678,347.036865 436.629120,346.368225 437.800293,345.646088 
	C437.157867,344.311432 436.611847,343.177063 436.422729,342.010651 
	C437.834290,342.348236 438.888947,342.717865 440.085144,343.387451 
	C440.479462,345.374512 440.732269,347.061676 440.879181,348.041962 
	C443.853577,346.736145 446.314850,345.655640 448.776093,344.575104 
	C448.957886,344.918488 449.139648,345.261871 449.321411,345.605225 
	C449.806793,344.502930 450.292206,343.400635 450.881866,342.061523 
	C451.647736,342.992340 452.129944,343.578369 453.274170,344.969025 
	C453.953827,342.190399 454.472168,340.071198 455.228516,337.811981 
	C455.958954,337.139709 456.451447,336.607483 456.943909,336.075256 
	C456.943909,336.075256 456.990540,336.522675 456.976471,337.102600 
	C456.432465,341.560944 459.315399,339.746887 461.083740,340.376343 
	C462.832245,342.049866 464.421356,343.404877 466.219147,344.937836 
	C465.138214,345.504059 463.502411,346.360931 462.249359,347.017334 
	C463.249512,349.055054 464.218597,351.029480 465.138428,353.352203 
	C464.677948,354.266846 464.306702,355.287537 463.849121,355.327881 
	C460.103912,355.657745 456.344177,355.920197 452.585724,355.970978 
	C449.597443,356.011353 447.359070,355.924347 448.468109,360.296875 
	C449.098602,362.782684 447.895752,365.688721 447.890747,368.406219 
	C447.889099,369.286499 449.069855,370.168945 449.709747,371.050415 
	C449.793884,371.784515 449.878021,372.518616 449.500183,373.698425 
	C449.286224,376.107422 449.534241,378.070740 449.782227,380.034058 
	C449.845276,380.771393 449.908295,381.508728 449.515442,382.646851 
	C445.857025,385.153809 446.494873,386.767456 449.701324,388.051605 
	C449.770264,389.095764 449.839203,390.139954 449.477600,391.619690 
	C448.657227,392.558014 448.267395,393.060822 447.877563,393.563599 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M436.065826,342.042694 
	C436.611847,343.177063 437.157867,344.311432 437.800293,345.646088 
	C436.629120,346.368225 435.544678,347.036865 434.172546,347.882904 
	C436.405212,349.191833 438.524994,350.434540 441.241058,352.026855 
	C438.603943,354.136383 436.293518,355.984619 433.983093,357.832825 
	C433.746857,357.525604 433.510620,357.218384 433.274353,356.911163 
	C432.993835,358.663452 432.713287,360.415710 432.546387,361.458221 
	C433.965088,362.606598 435.054962,363.488800 435.823029,364.110535 
	C437.582916,363.123230 439.432953,362.085358 441.283020,361.047455 
	C441.563568,361.501892 441.844116,361.956299 442.124695,362.410736 
	C441.140900,363.270355 440.092010,364.066833 439.185394,365.001282 
	C436.263672,368.012604 440.630859,370.384003 439.565613,373.598419 
	C436.837433,373.365601 434.464661,372.860687 432.180359,372.092529 
	C430.739136,371.607910 429.430206,370.729675 428.038452,369.630188 
	C427.366943,366.108246 426.718933,362.984161 425.997894,359.507751 
	C425.634918,359.611938 424.795013,359.853027 423.955109,360.094116 
	C423.384827,359.582397 422.814545,359.070618 422.140076,358.289551 
	C422.980469,357.224365 423.925018,356.428497 424.908417,355.318512 
	C424.947266,353.276855 424.947266,351.549316 424.947266,350.093414 
	C427.722229,347.681824 430.662628,345.126434 434.121552,342.275940 
	C435.115295,342.001465 435.590576,342.022064 436.065826,342.042694 
M433.429260,353.212891 
	C433.520203,352.480957 433.611176,351.749023 433.702148,351.017090 
	C433.508057,351.009277 433.313995,351.001465 433.119934,350.993652 
	C433.119934,351.592590 433.119934,352.191498 433.429260,353.212891 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M463.641418,320.074463 
	C464.401947,320.982178 464.697754,322.598297 465.316071,322.732910 
	C469.150482,323.567596 468.048950,325.921143 467.113220,328.587769 
	C465.566864,327.992004 464.197113,327.058136 462.727539,326.056244 
	C454.598450,335.041687 452.272003,323.957794 447.515900,320.888641 
	C449.823700,320.255249 452.112732,319.627014 454.595001,318.945740 
	C454.890228,319.990845 455.248383,321.258545 455.942902,323.717010 
	C456.884460,320.445557 457.488556,318.346588 458.092682,316.247620 
	C458.491241,316.278137 458.889801,316.308685 459.288361,316.339203 
	C459.745667,317.939240 460.202972,319.539276 460.756561,321.476074 
	C461.748108,320.944244 462.536957,320.521118 463.641418,320.074463 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M454.990540,337.952026 
	C454.472168,340.071198 453.953827,342.190399 453.274170,344.969025 
	C452.129944,343.578369 451.647736,342.992340 450.881866,342.061523 
	C450.292206,343.400635 449.806793,344.502930 449.321411,345.605225 
	C449.139648,345.261871 448.957886,344.918488 448.776093,344.575104 
	C446.314850,345.655640 443.853577,346.736145 440.879181,348.041962 
	C440.732269,347.061676 440.479462,345.374512 440.421539,343.224426 
	C441.769073,340.459747 442.921661,338.157990 444.074249,335.856232 
	C444.474854,335.846985 444.875427,335.837769 445.941895,335.883057 
	C447.100586,335.916870 447.593414,335.896149 448.086243,335.875427 
	C449.822357,336.234222 451.558472,336.592987 453.847229,337.072266 
	C454.687317,337.375458 454.884186,337.628540 454.990540,337.952026 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M465.187683,353.003906 
	C464.218597,351.029480 463.249512,349.055054 462.249359,347.017334 
	C463.502411,346.360931 465.138214,345.504059 466.219147,344.937836 
	C464.421356,343.404877 462.832245,342.049866 461.464478,340.356720 
	C463.084015,340.018585 464.482269,340.018585 465.169617,340.018585 
	C464.011658,337.792572 463.007050,335.861359 462.002441,333.930176 
	C463.299896,333.851318 464.597351,333.772400 465.955963,333.369446 
	C467.755524,334.710022 469.493927,336.374695 471.626190,338.271423 
	C472.023468,338.680817 472.026886,338.858124 471.771057,339.277679 
	C470.466431,343.252594 466.252350,346.220490 468.730774,350.904053 
	C468.813416,351.060150 466.904083,352.270416 465.739410,352.993408 
	C465.555511,352.997498 465.187683,353.003906 465.187683,353.003906 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M472.982758,316.008728 
	C477.233368,316.398987 477.637177,318.945557 475.970184,322.857117 
	C478.571075,322.470337 480.525055,322.179779 482.479034,321.889191 
	C482.544373,322.244232 482.609711,322.599304 482.675049,322.954346 
	C480.941162,323.932831 479.207306,324.911346 476.904785,326.210724 
	C474.416718,324.485352 471.472076,322.443359 468.271301,320.196167 
	C468.654785,318.887390 469.294373,317.783844 470.357361,316.345032 
	C471.514709,316.009460 472.248749,316.009094 472.982758,316.008728 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M472.975098,315.678864 
	C472.248749,316.009094 471.514709,316.009460 470.414856,316.018127 
	C471.319641,312.604126 467.054993,309.326233 469.771301,305.885864 
	C470.438232,305.041168 471.233704,303.716309 472.002716,303.693115 
	C472.815887,303.668579 473.667969,304.934174 474.757263,305.855011 
	C475.009827,308.448212 475.008148,310.822662 475.010132,313.583191 
	C474.572052,313.969971 474.130341,313.970612 473.292480,313.831116 
	C472.801971,313.645813 472.707581,313.600647 472.613190,313.555481 
	C472.729858,313.713501 472.846527,313.871552 472.963196,314.029572 
	C472.964630,314.469391 472.966064,314.909180 472.975098,315.678864 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M444.047455,335.547943 
	C442.921661,338.157990 441.769073,340.459747 440.280029,342.924500 
	C438.888947,342.717865 437.834290,342.348236 436.422729,342.010651 
	C435.590576,342.022064 435.115295,342.001465 434.282990,341.957977 
	C434.207489,337.473206 439.460327,333.410553 443.854919,334.299133 
	C443.968903,334.322174 443.967987,334.913391 444.047455,335.547943 
M440.470154,340.220123 
	C440.573151,339.485352 440.676147,338.750580 440.779144,338.015808 
	C440.552521,338.004547 440.325928,337.993256 440.099304,337.981964 
	C440.099304,338.595245 440.099304,339.208496 440.470154,340.220123 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M401.978729,347.023651 
	C401.978729,347.023651 402.007904,346.532410 402.047363,345.994171 
	C402.081848,345.260437 402.076904,345.064972 402.071960,344.869537 
	C402.618835,343.400269 403.165710,341.931000 403.712616,340.461731 
	C403.967529,340.493439 404.222443,340.525146 404.477356,340.556854 
	C404.477356,342.484406 404.477356,344.411957 404.477356,347.144836 
	C405.927551,345.539124 406.917175,344.443420 407.910370,343.343719 
	C410.402191,345.934387 410.402191,345.934387 404.974060,349.933350 
	C403.527740,348.959259 402.753235,347.991455 401.978729,347.023651 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M461.768311,333.690704 
	C463.007050,335.861359 464.011658,337.792572 465.169617,340.018585 
	C464.482269,340.018585 463.084015,340.018585 461.305054,340.038208 
	C459.315399,339.746887 456.432465,341.560944 456.994812,337.326782 
	C457.699432,336.216187 458.288025,335.355621 459.068634,334.737640 
	C459.782501,334.172455 460.704071,333.869659 461.768311,333.690704 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M397.001221,329.998779 
	C398.788055,329.827332 400.573639,329.657104 402.692108,329.455109 
	C403.030243,331.847137 403.368530,334.240173 403.898987,337.992615 
	C400.933289,334.815460 399.022095,332.767944 397.054565,330.361450 
	C396.998291,330.002441 397.000000,330.000000 397.001221,329.998779 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M401.709106,347.206238 
	C402.753235,347.991455 403.527740,348.959259 404.632965,349.993347 
	C404.977325,350.497467 404.991028,350.935303 405.015930,351.702454 
	C403.343445,353.098358 401.659729,354.164917 399.050934,355.817444 
	C399.550781,353.480164 399.740784,352.591553 399.957001,351.361206 
	C400.468597,349.809235 400.954010,348.598999 401.709106,347.206238 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M455.228485,337.811981 
	C454.884186,337.628540 454.687317,337.375458 454.180969,337.081238 
	C454.774384,336.237610 455.586761,335.505493 456.699310,334.886475 
	C456.999481,334.999542 456.986023,334.988892 456.986023,334.988892 
	C456.999084,335.170441 457.012146,335.352020 456.984558,335.804413 
	C456.451447,336.607483 455.958954,337.139709 455.228485,337.811981 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M447.255920,330.729370 
	C446.213928,331.184143 445.398590,331.450867 444.583282,331.717560 
	C444.480621,331.376831 444.377991,331.036072 444.275330,330.695312 
	C445.284637,330.377747 446.292145,330.053894 447.308075,329.759094 
	C447.365326,329.742462 447.547058,329.953644 447.571625,330.078369 
	C447.599823,330.221283 447.517120,330.386047 447.255920,330.729370 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M446.008606,303.987854 
	C445.858063,304.842316 445.698975,305.709167 445.539886,306.575989 
	C445.173096,306.448212 444.806305,306.320404 444.439514,306.192627 
	C444.744324,305.491974 445.049103,304.791351 445.689178,304.035858 
	C446.024445,303.981018 446.000061,304.000214 446.008606,303.987854 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M448.017303,335.597839 
	C447.593414,335.896149 447.100586,335.916870 446.242249,335.868042 
	C446.567291,335.639069 447.257812,335.479645 448.017303,335.597839 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M407.003784,353.004059 
	C407.243683,352.491302 407.459412,351.962494 407.746735,351.475983 
	C407.776123,351.426270 408.235260,351.630432 408.494995,351.716827 
	C408.184998,352.111328 407.875000,352.505798 407.278198,352.946289 
	C406.991455,352.992310 407.000000,353.000000 407.003784,353.004059 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M403.918762,325.219696 
	C404.170105,324.968842 404.338196,324.969879 404.758301,324.981934 
	C404.646027,325.540039 404.281708,326.087036 403.917358,326.634033 
	C403.890076,326.246552 403.862793,325.859039 403.918762,325.219696 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M449.034363,304.974304 
	C449.180237,305.124023 449.292511,305.304138 449.404816,305.484283 
	C449.298401,305.442627 449.192017,305.400970 449.013000,305.215851 
	C448.940430,305.072388 449.000793,305.004761 449.034363,304.974304 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M401.891113,344.985413 
	C402.076904,345.064972 402.081848,345.260437 402.060730,345.748505 
	C401.849152,345.845490 401.645569,345.662445 401.493713,345.443542 
	C401.463745,345.400330 401.632965,345.218933 401.891113,344.985413 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M457.004517,335.006042 
	C457.097992,334.978790 457.186432,334.945038 457.130432,334.950073 
	C456.986023,334.988892 456.999481,334.999542 457.004517,335.006042 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M431.022461,297.024017 
	C430.751709,297.510468 430.480988,297.996948 430.105133,298.741699 
	C429.298065,300.050385 428.596130,301.100739 427.891449,302.155243 
	C427.087311,302.062347 425.827759,301.916870 424.280579,301.889954 
	C423.672211,301.224213 423.351501,300.439850 423.027527,299.316833 
	C423.130890,298.649231 423.327576,298.388184 424.232788,298.089722 
	C426.908295,297.664307 428.965393,297.344147 431.022461,297.024017 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M432.644989,306.999451 
	C431.633484,307.657990 430.223083,308.310425 428.419739,308.965210 
	C429.433258,308.309479 430.839661,307.651459 432.644989,306.999451 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M369.431641,334.436737 
	C369.497467,335.408478 369.372070,336.120575 369.246643,336.832642 
	C368.009216,336.533539 366.771790,336.234406 364.549438,335.697235 
	C365.799500,334.253357 366.971466,332.899719 368.518524,331.112823 
	C368.870392,332.606506 369.055389,333.391785 369.431641,334.436737 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M366.546051,321.460815 
	C366.582428,321.521698 366.509674,321.399963 366.546051,321.460815 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M368.003479,287.997345 
	C368.127594,288.142120 368.248260,288.289520 368.368927,288.436920 
	C368.280121,288.387787 368.191284,288.338623 368.048584,288.148376 
	C367.994659,288.007324 368.000000,288.000000 368.003479,287.997345 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M371.933899,344.935944 
	C372.110687,344.475311 372.358276,344.077362 372.605896,343.679443 
	C372.590515,344.053223 372.575165,344.427002 372.355225,344.959900 
	C372.150635,345.119049 372.004730,344.998657 371.933899,344.935944 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M472.030334,339.035461 
	C472.026886,338.858124 472.023468,338.680817 472.015137,338.237183 
	C474.517822,336.258942 476.938202,333.120392 480.164673,338.507904 
	C481.704346,336.322662 482.895386,334.632202 484.376770,332.554565 
	C485.011261,332.206787 485.355347,332.246185 485.780823,332.654846 
	C487.477661,337.348511 490.270935,338.214447 495.607819,336.018768 
	C495.932159,337.442535 496.255035,338.859894 496.854065,341.489563 
	C497.760254,338.897339 498.215179,337.595978 498.594269,336.511536 
	C501.491516,337.922668 504.099121,339.192749 506.596832,340.409271 
	C507.368103,339.323334 508.181580,338.177948 509.241943,337.244659 
	C509.705902,338.295990 509.923004,339.135162 509.956879,340.282104 
	C509.093170,342.081482 508.412689,343.573090 507.515198,345.390625 
	C507.519989,349.468140 507.741821,353.219727 507.646851,356.953552 
	C506.556335,356.608337 505.782593,356.280945 504.804962,355.701874 
	C503.120667,354.610077 501.640259,353.769958 500.099365,352.636597 
	C499.712372,351.863220 499.385925,351.383026 499.053223,350.703735 
	C499.046967,350.504669 499.029541,350.106659 499.352417,349.935120 
	C500.874695,348.701233 502.074036,347.638947 503.273407,346.576660 
	C502.954712,346.207001 502.636017,345.837372 502.317322,345.467743 
	C500.487061,346.286774 498.656799,347.105835 496.484131,347.942261 
	C494.754059,348.650879 493.366394,349.342133 491.622375,349.969543 
	C490.222931,348.349274 489.179840,346.792908 488.461853,345.721588 
	C487.110809,345.824982 485.584717,345.941803 483.816162,346.025024 
	C483.573669,345.991455 483.085663,345.952240 482.898621,345.713409 
	C482.149048,345.267090 481.586548,345.059601 480.782043,344.617065 
	C476.587067,343.842346 478.035736,347.397644 476.617004,349.043396 
	C475.773163,347.923981 474.797211,346.748535 474.947632,345.741302 
	C475.202454,344.034576 476.188782,342.440948 476.757324,340.762390 
	C476.778839,340.698822 475.650757,340.245880 474.715668,339.979553 
	C473.916565,339.980499 473.460175,339.976196 472.901245,339.758331 
	C472.619293,339.259247 472.363159,339.089508 472.030334,339.035461 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M505.012787,321.984894 
	C505.752136,321.981628 506.491486,321.978394 507.607056,322.341431 
	C507.992249,323.442963 508.001160,324.178162 508.010101,324.913391 
	C508.010101,324.913391 508.000214,324.958923 507.628937,325.039062 
	C506.495270,326.808502 505.759125,328.510315 504.960205,330.182129 
	C504.516937,331.109650 503.968842,331.987091 503.468018,332.887085 
	C502.622345,332.328339 501.537964,331.940643 500.986633,331.171051 
	C500.144745,329.995911 499.671173,328.556946 498.958069,327.054779 
	C496.913025,329.759857 496.106323,330.826935 494.743652,332.629456 
	C494.373352,330.768188 494.196503,329.879211 494.253052,328.989868 
	C494.486420,328.989471 494.952942,329.004211 494.952942,329.004211 
	C498.250580,327.258026 501.548218,325.511902 504.922913,323.140015 
	C505.004272,322.337799 505.008545,322.161346 505.012787,321.984894 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M494.930908,328.699127 
	C494.952942,329.004211 494.486420,328.989471 493.891266,329.032043 
	C490.763885,330.144928 488.231689,331.215240 485.699463,332.285583 
	C485.355347,332.246185 485.011261,332.206787 484.409088,332.179504 
	C483.003296,332.043182 481.855530,331.894775 479.848816,331.635254 
	C481.228882,329.869598 482.737549,327.939423 484.623291,326.004822 
	C485.000397,326.000397 485.012268,326.011688 485.012268,326.011688 
	C485.195740,327.320526 485.379181,328.629364 485.562653,329.938202 
	C485.979523,330.046387 486.396423,330.154541 486.813324,330.262726 
	C488.076935,328.538025 489.340576,326.813324 490.672211,324.995819 
	C491.902649,325.982758 493.405731,327.188416 494.930908,328.699127 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M505.039520,321.689514 
	C505.008545,322.161346 505.004272,322.337799 504.994019,322.779480 
	C503.576843,322.707367 502.165649,322.370056 500.389954,322.009735 
	C500.558624,320.141083 501.091797,318.295502 501.717712,316.128784 
	C503.862854,317.037842 505.889923,317.896820 508.719421,319.095886 
	C507.056732,320.141937 506.061493,320.768036 505.039520,321.689514 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M484.970856,321.962921 
	C488.407745,322.702881 487.510834,324.227386 485.349701,325.965668 
	C485.012268,326.011688 485.000397,326.000397 484.994629,325.994690 
	C484.984894,325.254395 484.980896,324.519775 484.978577,323.145721 
	C484.977081,322.325134 484.973969,322.144012 484.970856,321.962921 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M484.805389,322.063538 
	C484.973969,322.144012 484.977081,322.325134 484.968750,322.778259 
	C484.789246,322.866913 484.601593,322.696411 484.467346,322.490997 
	C484.434692,322.441010 484.577698,322.276184 484.805389,322.063538 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M495.064728,313.357208 
	C496.103821,312.663208 497.133209,312.341400 498.162628,312.019592 
	C498.459442,312.373840 498.756256,312.728088 499.053101,313.082336 
	C497.980652,314.043091 496.908234,315.003845 495.489380,315.959290 
	C495.113647,315.212433 495.084351,314.470947 495.064728,313.357208 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M497.015991,305.621582 
	C496.312622,306.607849 495.535309,307.297668 494.401917,307.984253 
	C494.709198,306.766327 493.711761,303.740570 497.015991,305.621582 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M491.595428,316.532349 
	C492.052765,316.649963 492.329926,316.938629 492.607086,317.227325 
	C492.487030,317.341797 492.268951,317.562653 492.261078,317.555359 
	C491.967072,317.284485 491.692474,316.992523 491.595428,316.532349 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M431.258331,297.009888 
	C428.965393,297.344147 426.908295,297.664307 424.459534,297.986389 
	C424.067841,297.988342 424.049500,297.575165 424.272278,297.229492 
	C424.704071,296.594055 424.913116,296.304260 425.122131,296.014465 
	C425.428955,294.889435 425.735748,293.764404 426.027100,292.306335 
	C427.999481,293.392487 429.987366,294.811646 431.970459,296.597290 
	C431.965668,296.963745 431.494202,296.995728 431.258331,297.009888 
M428.410248,295.483276 
	C428.410248,295.483276 428.524170,295.415283 428.410248,295.483276 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M421.390045,290.470245 
	C420.938873,290.354095 420.665070,290.068298 420.391266,289.782532 
	C420.510040,289.669647 420.725677,289.451752 420.733582,289.459015 
	C421.023529,289.726562 421.294159,290.015015 421.390045,290.470245 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M424.860779,296.118408 
	C424.913116,296.304260 424.704071,296.594055 424.262146,297.022949 
	C424.219330,296.848816 424.409393,296.535583 424.860779,296.118408 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M361.001953,463.998047 
	C358.600708,465.226501 356.197510,466.456909 354.094666,467.533508 
	C353.745544,465.599518 353.391296,463.637329 353.018341,461.337769 
	C352.999603,461.000397 353.010773,460.989014 353.010773,460.989014 
	C353.816956,459.482727 354.623108,457.976440 355.899200,455.592102 
	C356.339722,457.707916 356.698975,459.433380 357.125031,461.479706 
	C358.012573,461.956116 359.348694,462.673309 360.841187,463.697083 
	C360.997528,464.003632 361.000000,464.000000 361.001953,463.998047 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M352.993591,461.006165 
	C352.480591,460.703094 351.973633,460.394318 351.466644,460.085510 
	C351.651306,459.872711 351.835968,459.659943 352.020630,459.447205 
	C352.325531,459.770905 352.630463,460.094604 352.973083,460.703674 
	C353.010773,460.989014 352.999603,461.000397 352.993591,461.006165 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M465.138428,353.352203 
	C465.187683,353.003906 465.555511,352.997498 465.837341,353.385315 
	C469.744476,353.847626 473.369781,353.922089 477.315399,353.833038 
	C478.761780,351.476135 479.887878,349.282745 481.246460,347.078857 
	C481.478973,347.068420 481.942535,347.112885 481.952148,347.417786 
	C481.960968,348.128662 481.960144,348.534637 481.718872,349.136597 
	C481.294403,354.095459 485.162445,348.174072 485.798492,351.002686 
	C486.074738,352.832855 483.072998,356.200653 488.264465,356.330078 
	C493.600861,357.775970 498.670319,358.948273 503.994202,360.370850 
	C506.041931,360.256500 507.835236,359.891815 509.770691,359.498260 
	C510.847656,361.553406 511.944824,363.647095 513.041992,365.740784 
	C502.264374,363.166168 491.596802,359.823547 480.674500,358.259033 
	C471.875488,356.998688 462.765808,357.764130 453.802094,357.976288 
	C452.667389,358.003143 450.907196,359.978821 450.613159,361.311218 
	C449.977539,364.191315 450.105499,367.239899 449.815430,370.635010 
	C449.069855,370.168945 447.889099,369.286499 447.890747,368.406219 
	C447.895752,365.688721 449.098602,362.782684 448.468109,360.296875 
	C447.359070,355.924347 449.597443,356.011353 452.585724,355.970978 
	C456.344177,355.920197 460.103912,355.657745 463.849121,355.327881 
	C464.306702,355.287537 464.677948,354.266846 465.138428,353.352203 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M446.978729,495.946960 
	C446.221954,495.896240 445.465179,495.845520 444.113037,495.542664 
	C442.662018,495.209534 441.806427,495.128540 440.705505,495.040466 
	C440.460175,495.033386 440.001587,495.208282 440.001587,495.208282 
	C439.555237,495.153961 439.108917,495.099640 437.917542,494.973022 
	C435.773376,494.955292 434.374237,495.009827 432.975067,495.064331 
	C431.574158,494.890533 430.173279,494.716736 428.403198,494.527100 
	C426.961304,494.418182 425.888611,494.325104 424.411133,494.257721 
	C424.260406,493.653137 424.514496,493.022827 425.325378,492.078033 
	C431.917664,491.160339 437.953125,490.557129 443.988586,489.953918 
	C445.415833,490.316589 446.843079,490.679291 448.858337,491.073425 
	C449.446320,491.104889 449.805298,490.935333 449.805298,490.935333 
	C449.817902,491.692596 449.830475,492.449860 449.488373,493.588837 
	C448.415375,494.629364 447.697052,495.288177 446.978729,495.946960 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M528.080872,375.989899 
	C527.873169,376.001007 527.665527,376.012115 527.147095,376.051636 
	C525.893982,375.302551 524.951660,374.525116 523.942383,373.072357 
	C522.747803,371.692871 521.620117,370.988739 520.492371,370.284607 
	C518.666687,369.274353 516.841003,368.264069 515.009277,366.549438 
	C515.060547,365.583771 515.117798,365.322479 515.541809,365.046234 
	C518.607483,365.691864 521.306458,366.352417 524.273682,366.820160 
	C526.042114,366.447571 527.542175,366.267761 529.034546,366.477051 
	C528.711487,369.907410 528.396179,372.948669 528.080872,375.989899 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M515.175049,365.061157 
	C515.117798,365.322479 515.060547,365.583771 514.952637,366.244507 
	C514.460144,366.495819 514.018250,366.347717 513.309204,365.970215 
	C511.944824,363.647095 510.847656,361.553406 509.770691,359.498260 
	C507.835236,359.891815 506.041931,360.256500 504.067291,359.993164 
	C504.260254,358.227966 504.634552,357.090759 505.008850,355.953552 
	C505.782593,356.280945 506.556335,356.608337 508.028381,356.960449 
	C510.342590,356.541962 511.958435,356.098755 513.971863,355.546478 
	C514.395813,358.899231 514.785461,361.980194 515.175049,365.061157 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M449.885620,387.654083 
	C446.494873,386.767456 445.857025,385.153809 449.389008,382.999207 
	C449.835663,384.386017 449.952789,385.821289 449.885620,387.654083 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M449.937134,379.618958 
	C449.534241,378.070740 449.286224,376.107422 449.378479,374.050476 
	C449.843201,375.705811 449.967621,377.454834 449.937134,379.618958 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M520.633179,370.580750 
	C521.620117,370.988739 522.747803,371.692871 523.865967,372.773865 
	C522.828979,372.392792 521.801453,371.634827 520.633179,370.580750 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M447.064880,496.188293 
	C447.697052,495.288177 448.415375,494.629364 449.466064,493.968689 
	C449.901550,494.128906 450.004639,494.291016 450.141846,494.740723 
	C449.947510,495.306244 449.719086,495.584137 449.256897,496.000366 
	C448.399139,496.235687 447.775085,496.332672 447.064880,496.188293 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M447.974548,393.819519 
	C448.267395,393.060822 448.657227,392.558014 449.367798,392.000031 
	C449.878754,392.346802 450.068970,392.748810 450.321777,393.484375 
	C449.613403,393.903778 448.842438,393.989563 447.974548,393.819519 
z"/>
<path fill="#4E855B" opacity="1.000000" stroke="none" 
	d="
M449.984009,490.568481 
	C449.805298,490.935333 449.446320,491.104889 449.249023,491.089172 
	C449.144623,489.608856 449.237488,488.144196 449.584229,486.439514 
	C449.946289,487.533539 450.054504,488.867584 449.984009,490.568481 
z"/>
<path fill="#DAC887" opacity="1.000000" stroke="none" 
	d="
M467.110291,406.423035 
	C466.359924,406.094238 465.631439,405.428253 464.891602,404.444641 
	C465.630890,404.779968 466.381561,405.432892 467.110291,406.423035 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M449.350708,445.665283 
	C445.728546,450.765869 442.067810,455.556519 438.178650,460.566467 
	C428.829895,465.727478 419.709564,470.669189 410.017212,475.832336 
	C409.187805,476.317413 408.930450,476.581055 408.673096,476.844696 
	C407.411682,477.142578 406.150238,477.440460 404.525330,477.418915 
	C405.833710,474.433441 407.505524,471.767395 409.460114,468.931213 
	C412.302979,464.329834 414.864807,459.899536 417.422241,455.466705 
	C418.319427,453.911621 419.205780,452.350281 420.253693,450.946350 
	C420.410309,451.100891 420.778503,451.341888 420.842468,451.701233 
	C423.047729,455.118866 425.118835,458.064392 419.427643,459.778687 
	C418.727234,459.989685 418.581390,462.041779 418.178558,463.240692 
	C418.502533,463.371063 418.826508,463.501404 419.150513,463.631744 
	C419.690186,462.833344 420.229889,462.034912 421.102936,460.743286 
	C421.699615,462.424835 422.076538,463.487061 422.478973,464.621185 
	C432.171997,457.132385 439.486298,448.436646 443.459198,436.879028 
	C445.734009,434.502136 447.705841,432.285431 449.779419,430.406219 
	C449.876465,433.541107 449.871765,436.338531 449.681396,439.157257 
	C449.631592,439.402496 449.767456,439.626404 449.979065,440.242218 
	C450.090912,441.432343 450.127075,442.230560 450.163269,443.028778 
	C449.879578,443.804321 449.595856,444.579834 449.350708,445.665283 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M428.061951,370.028015 
	C429.430206,370.729675 430.739136,371.607910 432.180359,372.092529 
	C434.464661,372.860687 436.837433,373.365601 439.526550,373.970490 
	C441.113556,375.636871 442.346802,377.314667 443.663483,379.105988 
	C444.083099,383.125031 444.552338,387.619324 445.036652,392.512695 
	C444.594391,392.933319 444.137024,392.954895 443.114929,392.680756 
	C438.500061,390.592712 433.029053,389.912628 430.162445,389.489105 
	C428.388885,389.227081 426.880829,387.168060 424.837555,385.595520 
	C426.994659,385.077148 428.382904,384.743530 429.760620,384.412445 
	C429.760620,380.945129 429.760620,377.724640 429.760620,375.003113 
	C428.885834,374.368103 427.953278,373.691223 427.020752,373.014343 
	C427.063599,372.847504 427.106445,372.680634 427.401367,372.081909 
	C427.789612,371.109344 427.925781,370.568695 428.061951,370.028015 
z"/>
<path fill="#FDF9E6" opacity="1.000000" stroke="none" 
	d="
M449.867096,439.135925 
	C449.871765,436.338531 449.876465,433.541107 449.965790,429.965759 
	C450.059723,421.769714 449.925049,414.346069 450.211029,406.938629 
	C450.262482,405.605927 452.046967,404.340088 453.031311,403.043396 
	C462.643799,416.120941 457.766418,428.974518 452.378998,441.794647 
	C452.177765,442.273438 451.364258,442.494965 450.499634,442.932556 
	C450.127075,442.230560 450.090912,441.432343 450.104126,440.057831 
	C450.153503,439.481537 449.867096,439.135925 449.867096,439.135925 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M452.981232,402.740723 
	C452.046967,404.340088 450.262482,405.605927 450.211029,406.938629 
	C449.925049,414.346069 450.059723,421.769714 449.864075,429.628296 
	C447.705841,432.285431 445.734009,434.502136 443.198486,436.786377 
	C442.400604,436.580841 442.166351,436.307770 442.159210,435.745544 
	C445.162598,433.435760 446.744232,431.378174 442.998596,428.677582 
	C442.373260,428.226654 442.003693,426.976440 442.045349,426.121429 
	C442.231720,422.298706 439.868591,421.836487 436.952637,421.656799 
	C436.992096,420.896301 437.014679,420.461700 437.426575,420.016296 
	C439.799255,419.527527 441.782593,419.049561 443.762146,418.572510 
	C443.414154,417.043488 443.198151,416.094543 443.025391,414.788147 
	C444.446045,413.619598 445.823456,412.808472 447.556396,411.787994 
	C447.966400,408.960602 448.518738,405.151550 449.438904,401.252930 
	C450.308105,401.103485 450.809418,401.043579 451.310760,400.983643 
	C451.850891,401.468445 452.391022,401.953278 452.981232,402.740723 
M448.312836,417.303314 
	C448.328949,417.144989 448.386536,416.975830 448.348999,416.831482 
	C448.314728,416.699829 448.177307,416.595001 448.085388,416.478333 
	C447.694275,417.267090 447.303131,418.055817 446.912018,418.844543 
	C447.166107,418.932404 447.420227,419.020264 447.674316,419.108093 
	C447.832245,418.686646 447.990173,418.265167 448.312836,417.303314 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M426.766846,373.198242 
	C427.953278,373.691223 428.885834,374.368103 429.760620,375.003113 
	C429.760620,377.724640 429.760620,380.945129 429.760620,384.412445 
	C428.382904,384.743530 426.994659,385.077148 424.837555,385.595520 
	C426.880829,387.168060 428.388885,389.227081 430.162445,389.489105 
	C433.029053,389.912628 438.500061,390.592712 442.771606,392.670135 
	C443.017242,393.308990 443.041473,393.662811 442.675781,394.338074 
	C436.518372,394.102081 430.750885,393.544617 424.607849,392.965607 
	C423.481812,392.958130 422.731293,392.972229 421.993225,392.610870 
	C422.335052,388.824402 422.664429,385.413452 422.993774,382.002472 
	C424.166840,379.129028 425.339905,376.255585 426.766846,373.198242 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M424.983398,392.987183 
	C430.750885,393.544617 436.518372,394.102081 442.669250,394.603668 
	C445.717255,396.469299 448.381866,398.390808 451.178650,400.647949 
	C450.809418,401.043579 450.308105,401.103485 449.064941,401.114197 
	C446.546661,400.718079 444.770172,400.371216 442.623474,399.984192 
	C441.509033,399.916199 440.764801,399.888367 439.820374,399.630859 
	C438.747620,399.609314 437.875031,399.817413 436.622803,399.978760 
	C435.232758,399.883148 434.222382,399.834290 433.089386,399.455444 
	C430.833405,398.104462 428.789703,396.622101 426.542786,396.185516 
	C424.033630,395.697937 423.980713,394.800171 424.983398,392.987183 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M389.039490,439.321625 
	C393.707184,438.815948 392.341095,443.554230 394.589661,446.204437 
	C395.020599,447.466888 395.191040,448.192413 395.133759,449.341553 
	C394.178955,456.163330 393.451874,462.561493 392.497253,469.092041 
	C392.350037,469.806213 392.430359,470.388000 392.484955,471.299164 
	C392.462463,472.069000 392.465668,472.509491 392.468872,472.949982 
	C391.398590,474.300781 389.909973,475.492920 389.327515,477.028687 
	C386.131073,485.457245 379.865875,490.596130 371.141876,494.018494 
	C369.969666,494.045349 369.501526,494.038879 368.884705,493.867859 
	C368.736023,493.703278 368.400513,493.413055 368.400513,493.413086 
	C372.357239,488.554291 376.658905,483.933044 380.200836,478.788696 
	C388.288116,467.042572 390.652466,453.831055 389.039490,439.321625 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M392.837891,473.074829 
	C392.465668,472.509491 392.462463,472.069000 392.845276,471.312408 
	C393.469208,470.967346 393.707092,470.938416 394.327118,470.897705 
	C395.405243,470.273010 396.544495,469.769104 396.726379,469.029663 
	C398.066650,463.581421 399.215851,458.086121 400.244110,453.426208 
	C398.947510,451.645172 397.985199,450.323303 397.269592,448.999634 
	C397.516296,448.997772 398.009644,448.995392 398.313965,449.121399 
	C400.483551,447.745667 402.348785,446.243927 404.124359,444.814362 
	C405.142548,445.939270 406.169739,447.074158 407.527557,448.574310 
	C406.528717,449.260651 405.266541,450.127960 403.991486,450.757690 
	C403.862488,450.247253 403.746399,449.974396 403.630280,449.701569 
	C403.508942,449.789185 403.277039,449.946289 403.282166,449.954407 
	C403.506958,450.312134 403.752686,450.656738 404.007141,451.377228 
	C405.389435,453.023560 406.760468,454.295532 408.629364,456.029419 
	C403.007233,455.226532 402.533997,457.412781 404.876465,461.454376 
	C405.472015,462.481964 405.193787,464.016022 405.300873,465.590759 
	C405.256683,466.228058 405.228699,466.591949 404.914093,467.129395 
	C403.802948,469.210724 403.054718,471.496796 400.683258,466.317169 
	C400.371063,469.984070 400.198425,472.011902 399.878815,474.276703 
	C400.166199,475.673401 400.600555,476.833069 400.604095,477.989258 
	C398.597595,478.079865 397.021912,478.173950 395.050201,478.291687 
	C395.050201,480.168854 395.050201,481.606415 395.050201,483.043945 
	C395.050201,483.043945 395.075836,483.108337 394.754517,483.121582 
	C393.936707,483.710602 393.440216,484.286407 392.943726,484.862213 
	C386.833801,481.909760 393.178894,479.006195 392.997803,476.072052 
	C392.939148,475.122528 393.130127,474.157562 392.837891,473.074829 
M399.250153,465.812347 
	C399.370270,465.976624 399.490356,466.140900 399.610474,466.305206 
	C399.585541,466.076080 399.560638,465.846954 399.250153,465.812347 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M395.440002,483.044556 
	C395.050201,481.606415 395.050201,480.168854 395.050201,478.291687 
	C397.021912,478.173950 398.597595,478.079865 400.919403,477.997864 
	C402.081146,478.073395 402.496735,478.136871 402.912354,478.200348 
	C401.643982,479.545258 400.375610,480.890137 399.026917,482.574036 
	C397.907684,482.957062 396.868744,483.001099 395.440002,483.044556 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M393.121643,485.116760 
	C393.440216,484.286407 393.936707,483.710602 394.750916,483.156006 
	C394.478943,483.908600 393.889221,484.639954 393.121643,485.116760 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M367.366394,496.608765 
	C367.084503,495.995178 367.070465,495.393280 367.058258,494.489410 
	C367.357666,494.009674 367.655212,493.831909 368.176636,493.533600 
	C368.400513,493.413055 368.736023,493.703278 368.902466,494.175537 
	C369.101990,494.850952 369.135071,495.054047 369.168121,495.257111 
	C368.656830,495.711578 368.145569,496.166016 367.366394,496.608765 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M369.472412,495.222900 
	C369.135071,495.054047 369.101990,494.850952 369.051178,494.340118 
	C369.501526,494.038879 369.969666,494.045349 370.789185,494.073669 
	C370.685974,494.459900 370.231323,494.824280 369.472412,495.222900 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M403.248169,478.108459 
	C402.496735,478.136871 402.081146,478.073395 401.350220,478.001343 
	C400.600555,476.833069 400.166199,475.673401 400.188538,474.148956 
	C402.468140,472.210449 404.291107,470.636658 406.496185,469.057465 
	C407.644684,469.068481 408.411011,469.084900 409.177368,469.101349 
	C407.505524,471.767395 405.833710,474.433441 404.202148,477.444794 
	C404.022919,477.865570 403.803436,477.941071 403.248169,478.108459 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M408.958496,476.808472 
	C408.930450,476.581055 409.187805,476.317413 409.718811,475.998596 
	C409.742920,476.219696 409.493378,476.496002 408.958496,476.808472 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M444.080261,489.669983 
	C437.953125,490.557129 431.917664,491.160339 425.434509,491.756226 
	C425.488159,490.592010 425.703094,488.758881 426.543610,488.402924 
	C429.252808,487.255554 432.255951,485.839233 435.026459,486.055450 
	C438.142609,486.298676 441.129059,488.203888 444.080261,489.669983 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M433.797119,537.165894 
	C424.303497,530.697205 413.847534,526.594177 402.224731,524.312256 
	C402.976532,523.750000 404.090210,523.345581 405.987549,522.968018 
	C410.956360,522.910034 415.141479,522.825195 419.326630,522.740417 
	C420.287567,522.869324 421.248535,522.998230 422.655029,523.526123 
	C423.840088,524.199768 424.579590,524.474487 425.319061,524.749146 
	C430.316132,527.146851 435.313202,529.544617 440.851471,532.053833 
	C441.392670,532.165344 441.798767,532.290283 441.798767,532.290283 
	C443.444550,533.954529 445.090302,535.618835 446.889160,537.839722 
	C447.026947,538.594971 447.011627,538.793701 446.996338,538.992493 
	C445.573700,539.936462 444.151062,540.880493 442.310608,542.118896 
	C441.247070,541.794189 440.601410,541.174988 439.955383,540.234985 
	C439.477509,539.714661 439.000031,539.515259 438.193481,539.277283 
	C436.508636,538.547791 435.152863,537.856812 433.797119,537.165894 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M419.173492,522.411133 
	C415.141479,522.825195 410.956360,522.910034 406.365051,522.954834 
	C404.996887,521.949219 404.189545,520.420227 403.048401,520.106934 
	C393.819519,517.572937 384.533264,517.139282 375.224121,519.903809 
	C374.391724,520.151001 373.334381,519.640625 372.103821,519.183472 
	C377.693512,517.351013 383.492737,515.129272 389.453613,514.538574 
	C393.092285,514.177856 396.967743,516.373840 400.761505,517.322937 
	C402.188416,517.679871 403.685028,517.758301 405.573608,517.976807 
	C410.338379,519.353943 414.679352,520.717957 419.173492,522.411133 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M400.113220,523.644165 
	C398.929321,523.960022 397.851868,523.997559 396.237671,523.753418 
	C395.427032,523.231628 395.118011,523.171509 394.773804,523.291504 
	C394.773804,523.291504 394.702332,523.304382 394.665222,523.307983 
	C395.032990,522.207825 395.437836,521.104126 396.290833,518.778687 
	C398.026642,520.805359 399.123138,522.085510 400.113220,523.644165 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M369.033020,519.889038 
	C369.013367,519.698792 369.271393,519.433167 369.789612,519.065674 
	C369.803467,519.247131 369.557037,519.530396 369.033020,519.889038 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M463.606873,531.057617 
	C464.544830,531.560608 465.118866,532.041443 465.833008,532.772095 
	C463.577576,535.840637 461.182007,538.659302 459.897003,540.171326 
	C457.462372,538.707825 456.085724,537.880310 454.506165,536.714355 
	C455.255585,534.849609 457.694519,533.195251 453.932678,532.075195 
	C453.600616,531.070801 453.268585,530.066467 452.965332,528.733887 
	C454.696320,528.264771 456.398560,528.123840 458.519531,528.064453 
	C460.373169,529.109192 461.808075,530.072327 463.606873,531.057617 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M453.948761,532.410828 
	C457.694519,533.195251 455.255585,534.849609 454.145691,536.679688 
	C455.161957,539.676086 456.253448,542.408997 457.543427,545.044678 
	C458.210571,546.407776 459.229858,547.598450 460.523499,549.257080 
	C461.641388,550.788208 462.325806,551.929321 463.010223,553.070496 
	C463.004852,553.809326 462.999481,554.548096 462.983032,555.656982 
	C462.971924,556.027100 462.647858,556.042236 462.486725,556.034912 
	C459.897217,553.003662 457.479828,549.970825 455.037048,546.958557 
	C453.055298,544.514771 451.043091,542.095764 449.284363,539.322998 
	C450.373413,537.973267 451.222473,536.966187 452.071564,535.959106 
	C452.702667,534.888245 453.333740,533.817383 453.948761,532.410828 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M442.077698,532.082520 
	C441.798767,532.290283 441.392670,532.165344 441.211914,532.053772 
	C443.604492,524.615173 436.890472,528.080688 434.037903,526.152954 
	C434.876404,525.132751 435.621643,524.225952 436.915344,523.347534 
	C439.303802,524.235901 441.143860,525.095886 442.983948,525.955872 
	C445.874084,528.257324 445.990997,530.264893 442.077698,532.082520 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M442.986633,525.567017 
	C441.143860,525.095886 439.303802,524.235901 437.223755,523.192078 
	C436.723389,522.519897 436.463043,522.031494 436.102020,521.270874 
	C436.001343,520.998596 436.045349,520.954407 436.045349,520.954407 
	C437.896820,520.628601 439.748291,520.302856 442.202393,519.871033 
	C442.442749,521.491943 442.716034,523.335083 442.986633,525.567017 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M452.013855,535.638489 
	C451.222473,536.966187 450.373413,537.973267 449.155762,539.006958 
	C448.389526,539.040100 447.991852,539.046631 447.295288,539.022827 
	C447.011627,538.793701 447.026947,538.594971 447.090027,538.099487 
	C448.414490,536.609558 449.691162,535.416443 451.182312,534.022827 
	C451.231110,534.104492 451.593628,534.711182 452.013855,535.638489 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M435.979248,521.020630 
	C435.447205,520.794128 434.924408,520.566162 434.446747,520.266235 
	C434.397797,520.235474 434.619202,519.774231 434.715149,519.512695 
	C435.136139,519.801575 435.557159,520.090454 436.011780,520.666870 
	C436.045349,520.954407 436.001343,520.998596 435.979248,521.020630 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M425.187897,524.457336 
	C424.579590,524.474487 423.840088,524.199768 423.005920,523.623169 
	C423.626404,523.602722 424.341553,523.884094 425.187897,524.457336 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M408.274902,513.469482 
	C408.279907,513.486633 408.336731,513.501526 408.301849,513.475098 
	C408.266968,513.448608 408.269897,513.452271 408.274902,513.469482 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M325.612915,568.067566 
	C325.422699,567.770874 325.396393,567.344177 325.370087,566.917542 
	C325.505646,567.257568 325.641205,567.597595 325.612915,568.067566 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M499.587524,529.975952 
	C500.197968,529.976868 500.408417,530.001648 500.924561,530.119629 
	C501.166107,530.904480 501.102020,531.596008 500.664551,532.653198 
	C499.206909,533.334534 498.122620,533.650208 497.038391,533.965881 
	C496.635468,534.476501 496.232544,534.987122 495.548584,535.761230 
	C490.512238,537.097534 496.444946,538.825928 495.017395,540.102844 
	C495.787628,541.986084 496.398010,543.957153 497.372009,545.728394 
	C498.392487,547.584229 499.761993,549.248108 500.978912,550.995972 
	C500.647644,551.255005 500.316376,551.514099 499.985107,551.773193 
	C495.234833,547.472839 490.484558,543.172485 485.393463,538.345520 
	C484.768677,535.149963 485.258514,531.943909 481.024872,532.016968 
	C484.188599,527.105347 488.912689,528.042236 493.526794,528.744568 
	C495.433228,529.034729 497.301788,529.573914 499.587524,529.975952 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M481.014771,532.010925 
	C485.258514,531.943909 484.768677,535.149963 485.084991,538.175537 
	C484.619781,538.673584 484.122253,538.815002 483.236633,538.979614 
	C482.081573,537.807434 481.314636,536.611938 480.307922,535.167969 
	C480.068146,534.919434 480.061310,534.468872 480.281799,534.067383 
	C480.673035,533.116699 480.843781,532.567566 481.014526,532.018433 
	C481.014526,532.018433 481.004639,532.004883 481.014771,532.010925 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M480.757782,532.174805 
	C480.843781,532.567566 480.673035,533.116699 480.268799,533.842407 
	C480.190582,533.456421 480.345825,532.893799 480.757782,532.174805 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M488.056549,551.239502 
	C489.345612,553.685547 490.577118,556.526855 491.683319,559.746521 
	C490.371704,557.294861 489.185364,554.464783 488.056549,551.239502 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M493.452606,587.890625 
	C493.485870,589.462219 493.173920,591.171936 492.494507,592.970215 
	C492.453827,591.382019 492.780609,589.705444 493.452606,587.890625 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M492.333557,600.801636 
	C491.756836,600.239746 491.403107,599.480347 491.041473,598.384399 
	C491.204681,598.027588 491.375824,598.007385 491.805237,598.004395 
	C492.227905,598.882324 492.392242,599.743164 492.333557,600.801636 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M492.808868,567.138916 
	C493.360443,567.478821 493.664642,567.966064 493.919678,568.763428 
	C493.434143,568.477783 492.997833,567.882080 492.808868,567.138916 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M463.360657,553.049194 
	C462.325806,551.929321 461.641388,550.788208 460.872986,549.315796 
	C465.159821,548.549744 469.530609,548.114868 473.959991,547.351501 
	C475.128998,547.098816 476.239441,547.174561 477.587646,547.266602 
	C476.970825,552.698792 476.390900,557.806213 475.810974,562.913696 
	C475.159973,563.032288 474.508942,563.150940 473.857941,563.269592 
	C472.326263,560.223267 470.794556,557.176941 469.311493,554.227356 
	C467.607605,553.862427 465.659363,553.445190 463.360657,553.049194 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M481.703857,569.118042 
	C481.661255,571.370911 481.317322,573.761780 480.765472,577.598206 
	C478.547394,574.535889 477.020355,572.427612 475.761230,570.080078 
	C477.820282,569.645874 479.611450,569.450989 481.703857,569.118042 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M481.862488,596.052979 
	C481.908325,596.035278 481.816681,596.070618 481.862488,596.052979 
z"/>
<path fill="#E4D296" opacity="1.000000" stroke="none" 
	d="
M553.960449,516.026733 
	C554.240173,519.798096 553.039368,522.730774 549.406433,524.416565 
	C548.693909,524.747131 547.595520,525.597046 547.665466,526.056702 
	C548.460205,531.278137 543.907654,531.579041 541.110596,533.653625 
	C538.339539,535.708984 535.985168,538.325928 532.771484,540.869995 
	C531.063965,540.944153 530.032959,540.844971 529.001953,540.745789 
	C527.894714,539.588318 526.787537,538.430847 525.551147,536.857788 
	C527.278381,534.959290 529.134888,533.476379 530.991333,531.993530 
	C530.991333,531.993530 531.000000,532.000000 531.351807,532.040161 
	C536.593994,532.001953 538.416077,527.372375 541.720215,525.007385 
	C545.941040,521.986267 549.241394,517.679199 552.969727,514.234863 
	C553.320740,515.037781 553.640625,515.532288 553.960449,516.026733 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M518.875549,572.067017 
	C519.688599,573.239807 520.420593,574.245300 521.152649,575.250793 
	C520.635254,574.694458 520.117798,574.138184 519.600403,573.581909 
	C516.918579,578.296997 514.236816,583.012024 511.555023,587.727051 
	C510.637482,587.133911 509.719910,586.540833 508.802368,585.947693 
	C508.195038,584.768311 507.587738,583.588867 506.905975,582.041992 
	C507.477905,580.966064 508.124329,580.257690 509.205017,579.225586 
	C509.923248,578.406311 510.207153,577.910706 510.491089,577.415161 
	C510.753357,577.272400 510.968475,577.077576 511.498230,576.540100 
	C512.134094,575.794617 512.408142,575.339783 512.682190,574.884888 
	C513.298462,574.066040 513.914734,573.247192 515.185669,572.208252 
	C516.709045,571.915100 517.577820,571.842041 518.446594,571.768982 
	C518.446594,571.768982 518.794556,571.899719 518.875549,572.067017 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M530.600220,531.976929 
	C529.134888,533.476379 527.278381,534.959290 525.260559,536.722534 
	C523.720154,536.275208 522.341187,535.547485 520.983521,534.437683 
	C520.961914,533.606140 520.918945,533.156677 520.853149,532.369568 
	C521.401672,531.208557 522.088379,530.440308 522.515076,529.547791 
	C523.038879,528.452271 523.345398,527.252808 523.745789,526.098267 
	C525.129944,526.881287 526.681580,527.474731 527.847534,528.504761 
	C528.861206,529.400208 529.438843,530.789307 530.600220,531.976929 
M524.474976,528.580933 
	C524.474976,528.580933 524.405090,528.465027 524.474976,528.580933 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M495.334229,540.193970 
	C496.444946,538.825928 490.512238,537.097534 495.610321,536.026245 
	C500.924683,541.100098 505.896301,546.172546 510.854492,551.685425 
	C510.841095,552.125854 510.495972,552.414551 510.495972,552.414551 
	C505.547668,548.371399 500.599335,544.328308 495.334229,540.193970 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M497.259521,534.224365 
	C498.122620,533.650208 499.206909,533.334534 500.654602,533.002625 
	C505.157043,535.700928 509.296082,538.415405 513.435120,541.129883 
	C513.123657,541.646606 512.812256,542.163330 512.500793,542.679993 
	C507.494110,539.947632 502.487396,537.215210 497.259521,534.224365 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M529.015930,541.118652 
	C530.032959,540.844971 531.063965,540.944153 532.477051,541.088257 
	C534.423096,542.655884 536.123169,544.066528 537.502563,545.741333 
	C538.402771,546.834473 538.837402,548.311096 539.219360,549.882874 
	C535.648743,547.263733 532.339355,544.377625 529.015930,541.118652 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M518.316772,571.477234 
	C517.577820,571.842041 516.709045,571.915100 515.404724,571.991333 
	C516.041748,571.724854 517.114319,571.455139 518.316772,571.477234 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M510.166656,577.412659 
	C510.207153,577.910706 509.923248,578.406311 509.353760,578.976196 
	C509.326233,578.503723 509.584229,577.956909 510.166656,577.412659 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M512.380798,574.932251 
	C512.408142,575.339783 512.134094,575.794617 511.595764,576.343750 
	C511.580780,575.951843 511.830078,575.465698 512.380798,574.932251 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M511.013977,551.981934 
	C511.367706,552.363525 511.548523,552.889099 511.729370,553.414673 
	C511.391663,553.250732 511.053955,553.086792 510.606079,552.668701 
	C510.495972,552.414551 510.841095,552.125854 511.013977,551.981934 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M542.899475,362.321320 
	C544.634521,364.120789 546.495850,365.817841 548.064941,367.751953 
	C549.329834,369.311127 550.241943,371.156555 551.665039,373.290039 
	C552.019470,374.898499 552.019470,376.091919 552.019470,377.024658 
	C553.663879,377.024658 554.820496,377.024658 555.977051,377.024658 
	C556.128357,377.661591 556.629150,378.556091 556.378235,378.896545 
	C552.964661,383.528168 557.944946,383.676483 559.658081,385.851685 
	C561.124878,387.713959 561.305603,390.681976 561.772766,393.213043 
	C562.263916,395.873566 562.419556,398.596069 562.522949,401.701324 
	C562.260193,404.109711 562.196350,406.108246 562.132446,408.106781 
	C562.115662,408.523499 562.098816,408.940186 561.721558,409.758209 
	C560.778015,412.057251 560.194885,413.954987 559.633545,415.781708 
	C561.537537,416.740662 562.818542,417.385895 564.099609,418.031128 
	C564.083191,420.421875 564.066711,422.812592 563.689148,425.632019 
	C562.882385,427.376587 562.436707,428.692474 561.951965,430.381012 
	C561.390930,432.380768 560.869019,434.007812 560.347107,435.634888 
	C560.066772,435.584961 559.786438,435.535034 559.506104,435.485138 
	C559.506104,433.833069 559.506104,432.181030 559.506104,429.790771 
	C555.599731,436.386871 556.237366,438.644653 560.893921,443.141052 
	C562.907410,445.085327 563.667114,448.328003 564.667236,450.953491 
	C563.901245,450.869537 563.464417,450.813019 563.027527,450.756500 
	C561.542847,450.095154 560.058228,449.433777 558.051514,448.607971 
	C551.525574,445.212067 553.834167,453.076721 550.261963,453.838135 
	C548.574036,449.836609 550.149719,446.364716 552.169067,443.439728 
	C554.709778,439.759521 554.609741,436.913452 551.356323,434.211548 
	C551.005920,434.982483 550.706543,435.641174 550.407104,436.299866 
	C550.282837,435.558075 550.158630,434.816284 550.034363,434.074493 
	C548.697632,433.408051 547.360962,432.741608 546.024231,432.075195 
	C545.688599,430.989044 545.352966,429.902893 545.359985,428.457825 
	C549.116638,430.540375 551.733948,428.378601 555.809082,426.573181 
	C552.898071,426.104492 551.334900,425.852814 549.862732,425.615814 
	C549.554077,423.221741 549.282349,421.113831 549.333923,418.866821 
	C550.617554,417.867828 552.521790,416.803894 552.387085,416.180817 
	C551.412231,411.672668 554.216064,409.348541 556.954102,406.689117 
	C557.906067,405.764465 557.899292,403.852783 558.330444,402.391907 
	C556.945923,402.503693 555.541931,402.512451 554.184692,402.768707 
	C553.455872,402.906311 552.807251,403.469055 551.505798,404.173950 
	C554.669434,406.808136 553.561157,408.355011 550.092651,409.765533 
	C550.092651,407.366486 549.582520,405.272583 550.188293,403.577545 
	C552.007996,398.486359 548.790039,397.606262 545.108276,396.553040 
	C545.046631,395.835419 545.016724,395.424530 545.193115,394.766602 
	C548.908203,395.698029 552.417053,396.876495 555.937317,398.058807 
	C556.103821,397.377167 556.519409,395.675934 556.792358,394.558594 
	C552.570618,392.940247 548.692688,391.453674 543.719116,389.547089 
	C546.873230,386.705811 549.138550,384.665161 552.052490,382.040222 
	C546.408813,383.938782 544.402893,381.110229 543.124268,376.884979 
	C544.327576,376.613678 545.443604,376.362061 546.559570,376.110443 
	C546.509338,375.710297 546.459106,375.310120 546.408875,374.909973 
	C543.643921,374.909973 540.878967,374.909973 538.091919,374.554596 
	C538.064514,373.485321 538.059265,372.771454 538.326660,371.939362 
	C539.828308,372.174896 541.057434,372.528656 542.691223,372.998871 
	C542.640869,370.069580 542.736023,367.712738 538.602905,368.962402 
	C537.821228,366.555481 537.416443,364.253815 536.913086,361.665649 
	C537.922607,360.955200 539.030701,360.531342 540.138733,360.107483 
	C540.138733,360.107483 540.124817,360.519531 539.911743,360.848083 
	C539.907471,361.432739 540.116272,361.688873 540.325134,361.944977 
	C540.325134,361.944977 540.686890,361.972443 540.854065,362.356445 
	C541.340393,363.343781 541.659485,363.947144 541.978638,364.550507 
	C542.285583,363.807434 542.592529,363.064362 542.899475,362.321320 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M552.938477,513.926392 
	C549.241394,517.679199 545.941040,521.986267 541.720215,525.007385 
	C538.416077,527.372375 536.593994,532.001953 531.348633,532.034912 
	C531.861877,529.219116 532.729919,526.448792 533.606812,523.650269 
	C533.054993,523.408875 531.576965,522.762268 529.686523,521.935242 
	C529.637939,522.503479 529.545410,523.584839 529.472107,524.441040 
	C525.289856,524.250122 521.362488,524.070801 517.016357,523.872375 
	C520.041504,519.126831 520.090820,519.111267 525.065186,520.989380 
	C525.065186,519.687561 525.065186,518.507690 525.065186,517.327820 
	C525.407288,517.526489 525.749390,517.725098 526.091553,517.923767 
	C527.316345,516.007568 528.541138,514.091431 529.765991,512.175232 
	C530.020508,512.265686 530.275024,512.356140 530.529541,512.446655 
	C530.343262,513.960327 530.156982,515.473999 529.964478,517.038879 
	C531.616211,516.882874 532.996033,516.752563 533.738098,516.682495 
	C535.024780,514.552734 536.078430,512.808777 537.562134,511.310394 
	C538.139954,511.816772 538.287781,512.077637 538.435608,512.338501 
	C538.320557,511.869324 538.205505,511.400177 538.090576,510.931030 
	C539.005249,509.647705 539.702576,508.101807 540.875793,507.130768 
	C544.260315,504.329498 547.031494,501.513336 543.168091,496.460938 
	C546.160095,496.799042 548.322876,497.043457 550.784058,497.321564 
	C551.144653,496.354492 551.581604,495.182770 552.205627,493.509094 
	C550.830994,493.674713 549.993774,493.775604 549.630249,493.819397 
	C548.743896,490.331055 547.875549,486.913849 546.952637,483.281586 
	C549.919678,483.159607 553.562500,481.386627 554.634399,486.763458 
	C554.831482,487.751831 556.668823,488.413177 557.749084,489.225464 
	C558.153687,488.934692 558.558350,488.643921 558.963013,488.353149 
	C558.512817,487.256378 558.364441,485.810150 557.564087,485.118927 
	C551.864258,480.196533 551.849731,477.491302 557.589294,472.921600 
	C558.106873,472.509552 558.054565,471.381775 558.458069,470.234283 
	C558.959045,469.577057 559.268799,469.271942 559.578613,468.966797 
	C559.086304,468.668579 558.594055,468.370331 558.101746,468.072083 
	C558.867920,467.311890 559.634094,466.551697 560.846802,466.213409 
	C561.890625,467.957581 562.487976,469.279785 563.185791,470.824341 
	C559.876282,471.218750 559.818054,473.053711 561.238647,475.656952 
	C561.756714,476.606354 561.741699,477.846680 561.707520,479.166748 
	C561.599304,480.904572 558.485657,482.831085 562.005554,483.943115 
	C561.999390,484.391632 561.993225,484.840179 561.749573,485.836426 
	C563.071289,491.606567 560.255127,497.776917 554.183289,502.167145 
	C555.169312,504.077637 556.169434,506.015442 557.341370,508.286163 
	C555.314514,508.807281 553.932861,509.162537 552.469177,509.538849 
	C552.630249,511.044373 552.784363,512.485413 552.938477,513.926392 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M545.140015,396.859802 
	C548.790039,397.606262 552.007996,398.486359 550.188293,403.577545 
	C549.582520,405.272583 550.092651,407.366486 550.092651,409.765533 
	C553.561157,408.355011 554.669434,406.808136 551.505798,404.173950 
	C552.807251,403.469055 553.455872,402.906311 554.184692,402.768707 
	C555.541931,402.512451 556.945923,402.503693 558.330444,402.391907 
	C557.899292,403.852783 557.906067,405.764465 556.954102,406.689117 
	C554.216064,409.348541 551.412231,411.672668 552.387085,416.180817 
	C552.521790,416.803894 550.617554,417.867828 549.043335,418.664124 
	C546.956543,418.069092 545.483765,417.537598 544.010925,417.006104 
	C543.849731,414.145538 543.688538,411.285004 543.254883,407.776917 
	C542.285034,404.532715 541.371643,401.969727 540.959778,399.328522 
	C540.640137,397.278503 540.946533,395.130890 541.015991,393.310730 
	C541.463013,393.887909 541.868591,394.181061 542.274231,394.474213 
	C542.367554,394.216248 542.582153,393.759583 542.535339,393.730957 
	C542.056885,393.438690 541.535950,393.215973 540.826416,392.699829 
	C540.083130,391.273285 539.540344,390.120789 538.997559,388.968262 
	C538.997559,388.968262 538.987305,388.489044 539.362671,388.245300 
	C542.588745,387.066498 543.046082,382.427643 541.280823,379.658203 
	C539.647644,381.315430 537.832581,383.157318 536.017456,384.999207 
	C534.699951,382.919830 533.382446,380.840454 532.338623,378.366577 
	C535.346008,378.568054 534.823120,373.312866 538.114014,374.909973 
	C540.878967,374.909973 543.643921,374.909973 546.408875,374.909973 
	C546.459106,375.310120 546.509338,375.710297 546.559570,376.110443 
	C545.443604,376.362061 544.327576,376.613678 543.124268,376.884979 
	C544.402893,381.110229 546.408813,383.938782 552.052490,382.040222 
	C549.138550,384.665161 546.873230,386.705811 543.719116,389.547089 
	C548.692688,391.453674 552.570618,392.940247 556.792358,394.558594 
	C556.519409,395.675934 556.103821,397.377167 555.937317,398.058807 
	C552.417053,396.876495 548.908203,395.698029 544.917114,395.011414 
	C544.434814,397.172943 544.434814,398.842560 544.434814,400.512207 
	C544.813293,400.480957 545.191772,400.449738 545.570251,400.418518 
	C545.426819,399.232300 545.283386,398.046051 545.140015,396.859802 
M544.419556,403.478424 
	C544.419556,403.478424 544.526184,403.414917 544.419556,403.478424 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M545.859131,432.409241 
	C547.360962,432.741608 548.697632,433.408051 550.034363,434.074493 
	C550.158630,434.816284 550.282837,435.558075 550.407104,436.299866 
	C550.706543,435.641174 551.005920,434.982483 551.356323,434.211548 
	C554.609741,436.913452 554.709778,439.759521 552.169067,443.439728 
	C550.149719,446.364716 548.574036,449.836609 550.261963,453.838135 
	C553.834167,453.076721 551.525574,445.212067 557.759460,448.717712 
	C558.523926,450.461304 559.058350,451.930725 559.434753,453.701202 
	C557.587891,454.628540 555.899048,455.254822 554.210205,455.881104 
	C556.790771,459.635406 551.538696,461.925140 552.807495,465.345520 
	C551.228149,465.475281 550.016724,465.574799 548.553650,465.695038 
	C549.102661,467.356537 549.539490,468.678558 549.976379,470.000580 
	C549.808594,470.001312 549.640747,470.002075 548.920776,470.143127 
	C546.905334,471.180695 545.442078,472.078033 543.978821,472.975342 
	C544.396301,464.842896 544.855713,456.712311 545.216370,448.577332 
	C545.450134,443.302643 545.539734,438.021515 545.859131,432.409241 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M550.359070,470.008728 
	C549.539490,468.678558 549.102661,467.356537 548.553650,465.695038 
	C550.016724,465.574799 551.228149,465.475281 552.807495,465.345520 
	C551.538696,461.925140 556.790771,459.635406 554.210205,455.881104 
	C555.899048,455.254822 557.587891,454.628540 559.611328,453.963562 
	C561.930603,457.360596 563.915344,460.796295 565.534546,464.603149 
	C564.094360,464.964691 563.019714,464.955109 561.945068,464.945557 
	C561.156128,463.061249 560.367249,461.176941 559.612732,459.374817 
	C553.488098,462.003876 557.413879,467.889618 555.218994,471.994720 
	C553.569885,471.266205 552.155823,470.641541 550.359070,470.008728 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M538.091919,374.554596 
	C534.823120,373.312866 535.346008,378.568054 532.285522,378.018677 
	C530.852173,377.475494 529.745667,376.885742 528.359985,376.142944 
	C528.396179,372.948669 528.711487,369.907410 529.412964,366.455933 
	C531.425903,367.154175 533.052734,368.262634 534.517822,369.260864 
	C535.079712,367.614105 536.045715,364.783142 537.011658,361.952179 
	C537.416443,364.253815 537.821228,366.555481 538.474487,369.251617 
	C538.500000,370.449951 538.277039,371.253754 538.054077,372.057556 
	C538.059265,372.771454 538.064514,373.485321 538.091919,374.554596 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M543.997192,417.458923 
	C545.483765,417.537598 546.956543,418.069092 548.719971,418.803284 
	C549.282349,421.113831 549.554077,423.221741 549.862732,425.615814 
	C551.334900,425.852814 552.898071,426.104492 555.809082,426.573181 
	C551.733948,428.378601 549.116638,430.540375 545.367065,428.092224 
	C544.682251,424.694244 544.332825,421.302979 543.997192,417.458923 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M561.734619,465.071503 
	C563.019714,464.955109 564.094360,464.964691 565.570251,464.972076 
	C566.302246,465.411194 566.633057,465.852478 566.720825,466.797974 
	C566.703369,470.005463 566.928894,472.708801 567.189148,475.828003 
	C566.978088,475.882050 565.675659,476.215607 564.458679,476.527252 
	C564.532715,478.058746 564.590515,479.253510 564.685425,481.215302 
	C563.371643,480.121429 562.670776,479.537842 561.969849,478.954254 
	C561.741699,477.846680 561.756714,476.606354 561.238647,475.656952 
	C559.818054,473.053711 559.876282,471.218750 563.185791,470.824341 
	C562.487976,469.279785 561.890625,467.957581 561.159546,466.330566 
	C561.096313,465.692108 561.262451,465.416016 561.734619,465.071503 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M540.974609,393.026642 
	C540.946533,395.130890 540.640137,397.278503 540.959778,399.328522 
	C541.371643,401.969727 542.285034,404.532715 542.976379,407.545166 
	C538.196838,405.194275 539.690369,399.850250 538.140747,395.776215 
	C537.403381,393.837738 538.166077,391.328705 538.623413,389.023193 
	C539.540344,390.120789 540.083130,391.273285 540.813110,392.712799 
	C541.000244,392.999786 540.974609,393.026642 540.974609,393.026642 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M536.101257,385.239929 
	C537.832581,383.157318 539.647644,381.315430 541.280823,379.658203 
	C543.046082,382.427643 542.588745,387.066498 539.365356,388.005676 
	C538.995483,387.836548 538.998352,387.663300 538.839600,386.980774 
	C538.109863,386.345886 537.541870,386.220245 536.973816,386.094574 
	C536.710876,385.889923 536.447998,385.685272 536.101257,385.239929 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M543.706665,473.228790 
	C545.442078,472.078033 546.905334,471.180695 548.669067,470.148315 
	C548.681152,473.389130 547.750183,476.372437 543.434570,478.464844 
	C543.434570,476.357849 543.434570,474.920044 543.706665,473.228790 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M545.904297,481.634003 
	C545.495300,481.070038 545.360352,480.524445 545.282532,479.970825 
	C545.278564,479.942963 545.767334,479.845734 546.026733,479.780823 
	C546.081116,480.404114 546.135559,481.027435 545.904297,481.634003 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M558.076782,468.383057 
	C558.594055,468.370331 559.086304,468.668579 559.578613,468.966797 
	C559.268799,469.271942 558.959045,469.577057 558.364380,469.911377 
	C558.070251,469.525055 558.061035,469.109528 558.076782,468.383057 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M537.162231,386.315643 
	C537.541870,386.220245 538.109863,386.345886 538.843994,386.720947 
	C538.456909,386.825775 537.903748,386.681244 537.162231,386.315643 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M433.027222,495.380066 
	C434.374237,495.009827 435.773376,494.955292 437.580994,495.004669 
	C436.352753,495.304321 434.716064,495.500031 433.027222,495.380066 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M440.950806,495.047516 
	C441.806427,495.128540 442.662018,495.209534 443.733978,495.528137 
	C443.179810,495.913849 442.409332,496.061981 441.329987,495.854675 
	C441.021149,495.499176 440.950806,495.047516 440.950806,495.047516 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M440.705505,495.040466 
	C440.950806,495.047516 441.021149,495.499176 441.050537,495.725586 
	C440.758698,495.935730 440.484192,495.814087 440.128967,495.397705 
	C440.001587,495.208282 440.460175,495.033386 440.705505,495.040466 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M364.782928,480.804962 
	C365.965332,476.516815 367.147766,472.228668 368.361298,467.827606 
	C373.405365,469.321442 372.960999,473.028381 371.794434,477.385193 
	C370.340485,480.044556 368.932953,482.325928 366.991302,484.629822 
	C365.899109,483.369873 365.341003,482.087402 364.782928,480.804962 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M364.484253,480.966827 
	C365.341003,482.087402 365.899109,483.369873 366.742615,484.850342 
	C365.788727,486.554962 364.549408,488.061523 362.535889,490.509308 
	C362.297180,488.281921 362.159882,487.000488 361.963867,485.334656 
	C362.665344,483.676361 363.425476,482.402527 364.484253,480.966827 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M552.969727,514.234863 
	C552.784363,512.485413 552.630249,511.044373 552.469177,509.538849 
	C553.932861,509.162537 555.314514,508.807281 557.341370,508.286163 
	C556.169434,506.015442 555.169312,504.077637 554.183289,502.167145 
	C560.255127,497.776917 563.071289,491.606567 561.744629,486.172852 
	C566.044006,487.898285 570.110718,489.835052 574.187317,491.776550 
	C576.829712,486.367554 579.250732,481.411682 581.671814,476.455780 
	C581.272522,476.027740 580.873230,475.599731 580.473938,475.171692 
	C579.260315,475.490631 578.061096,475.951324 576.827759,476.076477 
	C576.062500,476.154144 575.245605,475.722870 574.451843,475.520081 
	C574.903442,474.767517 575.320984,473.395844 575.812561,473.368744 
	C578.035889,473.246185 580.280273,473.503723 583.062134,473.357056 
	C584.744995,473.064880 585.882629,473.039978 587.014709,473.011017 
	C587.009094,473.006958 587.000366,472.998962 587.000366,472.998962 
	C586.344360,474.237854 585.688354,475.476746 584.765564,477.219421 
	C586.448608,477.219421 587.733276,477.219421 589.184265,477.219421 
	C589.791321,480.319061 590.388550,483.368652 591.008240,486.532928 
	C591.520325,486.506256 592.408081,486.460022 591.874207,486.487823 
	C590.668518,489.572571 589.364380,492.909241 587.902039,496.943970 
	C585.842957,497.748199 583.942078,497.854462 582.041138,497.960724 
	C584.664673,497.064362 586.172058,495.544159 586.419922,492.479797 
	C586.661133,489.496948 587.726807,486.580780 588.720093,482.422668 
	C586.583374,483.694000 585.198303,484.518097 583.973999,485.246552 
	C583.835510,483.701141 583.676941,481.931427 583.518433,480.161682 
	C583.038269,480.078796 582.558105,479.995911 582.078003,479.913025 
	C580.640564,483.393402 579.770447,487.291351 577.603638,490.234833 
	C575.501770,493.090149 572.068237,494.965179 568.838257,497.579254 
	C568.302490,495.549316 567.868774,493.905853 567.286011,491.697784 
	C561.378784,494.534393 562.350830,499.691132 562.031372,504.641632 
	C561.086060,506.685059 559.731079,508.272705 559.285767,510.083984 
	C558.805664,512.036377 559.177246,514.198242 559.177246,515.656982 
	C557.224609,515.656982 555.861084,515.656982 554.229004,515.841858 
	C553.640625,515.532288 553.320740,515.037781 552.969727,514.234863 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M560.989929,520.992188 
	C560.214417,522.431641 559.438904,523.871155 558.483643,525.644165 
	C559.334839,526.253784 560.463745,527.062317 561.787903,528.010620 
	C561.369507,529.161499 560.933777,530.360229 560.405029,531.814819 
	C559.817200,531.347839 559.411804,531.128723 559.147705,530.797119 
	C558.246399,529.665466 556.975342,527.372437 556.590881,527.516235 
	C553.634277,528.621887 552.927124,526.229919 553.358948,524.865662 
	C553.842407,523.338196 555.729492,522.117554 557.238831,521.113770 
	C557.932068,520.652649 559.181458,521.027649 560.589355,521.011719 
	C561.000000,521.000000 560.989929,520.992188 560.989929,520.992188 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M557.695312,567.861084 
	C557.387207,569.109619 556.746887,570.212158 556.063232,571.635620 
	C554.929932,573.165649 553.992676,574.576538 552.717651,575.541016 
	C550.241211,577.414246 548.275391,577.033569 547.098022,573.167358 
	C548.722351,573.608948 549.887573,573.925720 551.687927,574.415161 
	C552.134521,572.429077 552.571716,570.484558 553.243774,567.495544 
	C553.750671,567.522522 555.556885,567.618835 557.695312,567.861084 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M556.377930,534.502441 
	C557.300842,534.155762 558.064453,534.125977 558.828064,534.096252 
	C558.580750,534.955688 558.333496,535.815125 558.086243,536.674561 
	C557.463684,536.056152 556.841125,535.437744 556.377930,534.502441 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M539.739868,542.840332 
	C538.886230,543.148499 538.109741,543.118347 537.333191,543.088196 
	C537.477600,542.516541 537.622070,541.944885 537.766479,541.373230 
	C538.449951,541.749512 539.133484,542.125854 539.739868,542.840332 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M556.054199,572.284302 
	C556.468201,571.978760 556.903503,571.978394 557.665039,571.993530 
	C557.758484,572.534058 557.525513,573.059082 557.292603,573.584167 
	C556.886902,573.252563 556.481140,572.921021 556.054199,572.284302 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M561.003662,521.004883 
	C561.097107,520.973145 561.186768,520.936584 561.133179,520.946045 
	C560.989929,520.992188 561.000000,521.000000 561.003662,521.004883 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M529.420044,581.988037 
	C528.325134,583.378418 527.103882,584.519836 525.882690,585.661194 
	C525.607605,585.408997 525.332581,585.156799 525.057495,584.904663 
	C525.739014,583.744995 526.420532,582.585327 527.241089,581.079712 
	C528.018005,581.068848 528.655823,581.403931 529.420044,581.988037 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M534.370483,576.746826 
	C534.451599,576.948364 534.251221,577.362793 533.820862,577.618591 
	C533.756897,577.293213 533.922913,577.126465 534.370483,576.746826 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M330.661255,585.812134 
	C330.664520,584.147400 330.667816,582.482605 330.763733,580.072876 
	C330.730988,578.843628 330.605621,578.359375 330.480255,577.875122 
	C330.599060,577.459229 330.717865,577.043335 331.172852,576.151062 
	C331.645630,575.095886 331.782227,574.517151 331.918854,573.938354 
	C332.192810,573.450989 332.466797,572.963562 333.154968,572.092651 
	C335.060211,569.812256 336.551300,567.915405 338.414520,566.016968 
	C339.532593,566.009521 340.278564,566.003540 341.024506,565.997559 
	C344.136444,564.893799 347.176331,563.372864 350.383240,562.818787 
	C352.932373,562.378296 355.685760,563.119934 358.925476,563.383728 
	C358.386322,561.739624 357.889252,560.223877 357.392212,558.708130 
	C357.761658,558.423767 358.131104,558.139343 358.500549,557.854919 
	C360.336182,560.306335 362.171814,562.757690 363.715637,565.779114 
	C362.537018,567.687378 361.438477,568.931946 360.798370,570.379272 
	C357.083405,578.778564 353.187622,586.989502 343.642883,590.861694 
	C341.863129,591.563904 340.432281,592.286133 339.001465,593.008423 
	C338.612518,592.463806 338.223572,591.919250 337.924377,590.816284 
	C338.443146,589.562988 338.872131,588.867981 339.624237,588.089722 
	C342.520081,586.199768 343.882904,584.049805 342.121948,580.678711 
	C342.833801,579.391052 343.369293,578.388184 343.904785,577.385376 
	C343.561462,577.130127 343.218140,576.874939 342.874786,576.619751 
	C341.208862,578.384155 339.542938,580.148560 337.670410,581.925659 
	C337.463806,581.938293 337.049500,581.949097 337.018066,581.618774 
	C336.136963,581.278381 334.947784,580.905823 334.500214,581.325073 
	C333.072357,582.662537 331.919983,584.294128 330.661255,585.812134 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M338.042358,566.018494 
	C336.551300,567.915405 335.060211,569.812256 333.255310,571.826294 
	C333.743927,561.322815 339.116608,553.222778 346.953918,546.464172 
	C351.664459,542.401917 356.281616,538.228882 361.066803,534.257446 
	C362.270111,533.258850 363.958038,532.844238 365.826233,532.524902 
	C367.181793,533.285889 368.134369,533.683167 369.312103,534.371460 
	C381.360535,537.581238 393.183807,540.500000 405.171509,543.633667 
	C405.501129,544.348206 405.666351,544.847839 405.459045,545.220093 
	C404.036926,545.118774 402.987305,545.144836 401.937714,545.170959 
	C399.571716,544.223633 397.205719,543.276245 394.198486,542.192627 
	C391.668762,541.763428 389.783844,541.445007 387.891235,541.181824 
	C380.143066,540.104431 372.402313,538.958618 364.632416,538.068787 
	C363.465637,537.935242 362.158417,539.027893 360.916168,539.553223 
	C360.492798,539.660156 360.069427,539.767090 358.989746,539.991455 
	C356.865662,541.371582 355.397858,542.634338 353.930054,543.897095 
	C353.930084,543.897095 353.968170,543.953369 353.599304,543.988525 
	C352.168854,545.356812 351.107300,546.690063 350.045746,548.023315 
	C349.371155,548.757629 348.696533,549.491943 347.580566,550.549561 
	C346.442413,551.583252 345.745667,552.293640 345.048889,553.004028 
	C343.639191,555.344543 342.229492,557.685059 340.424408,560.518738 
	C339.366821,562.680725 338.704590,564.349609 338.042358,566.018494 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M343.991760,590.881836 
	C353.187622,586.989502 357.083405,578.778564 360.798370,570.379272 
	C361.438477,568.931946 362.537018,567.687378 363.715515,566.161743 
	C364.440399,566.070496 364.873566,566.166626 365.621277,566.671753 
	C366.028534,569.768005 366.121246,572.455261 366.213959,575.142578 
	C366.213959,575.142578 366.165131,575.175537 366.148651,575.200928 
	C361.606934,575.778809 363.422546,579.968811 362.128723,582.726257 
	C362.090515,583.490845 361.978882,583.862061 361.789307,584.513184 
	C360.148163,586.626465 358.585022,588.459961 357.016144,590.642090 
	C357.010406,590.990662 357.000519,591.002625 356.633118,590.964478 
	C354.807648,591.906982 353.349579,592.887512 351.891479,593.868042 
	C351.891479,593.868042 351.945526,593.892761 351.535065,593.915771 
	C348.401642,594.639954 345.678650,595.341064 342.969208,595.657959 
	C343.319092,593.809753 343.655426,592.345764 343.991760,590.881836 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M361.032104,539.834351 
	C362.158417,539.027893 363.465637,537.935242 364.632416,538.068787 
	C372.402313,538.958618 380.143066,540.104431 387.891235,541.181824 
	C389.783844,541.445007 391.668762,541.763428 393.858826,542.282227 
	C395.702118,544.755188 397.243805,547.002380 398.670471,549.777832 
	C398.367737,551.259155 398.180054,552.212341 397.593079,553.161255 
	C396.135986,553.097717 395.078217,553.038452 394.011047,552.989624 
	C394.001648,553.000000 394.014404,552.977844 393.906769,552.656616 
	C388.907379,549.098022 384.125824,545.666565 379.036041,542.778198 
	C377.946442,542.159912 375.739532,543.510498 374.030212,544.220154 
	C374.000580,544.663574 373.988068,544.840942 373.633606,544.968262 
	C371.564850,543.585693 369.838104,542.253174 368.363190,541.114990 
	C366.590118,543.102844 365.293701,544.556274 363.997253,546.009766 
	C363.997253,543.976318 363.997253,541.942871 363.997253,539.873413 
	C362.834778,539.972229 361.991425,540.043884 361.032104,539.834351 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M415.002441,553.991821 
	C413.417053,553.309570 411.831665,552.627258 410.246277,551.945007 
	C410.137726,552.353271 410.029144,552.761475 409.920593,553.169739 
	C409.303375,551.370850 408.686127,549.572021 408.365845,547.469849 
	C410.228363,548.036377 411.713104,549.161499 413.386322,549.691650 
	C414.624329,550.083801 416.107666,549.701477 417.780579,549.788879 
	C422.424896,555.566772 426.770386,561.220032 430.920044,566.942383 
	C430.724213,567.011475 430.308868,567.010986 429.907837,567.002075 
	C428.013214,565.657837 426.519592,564.322510 424.980316,562.635803 
	C421.623901,559.520264 418.313171,556.756042 415.002441,553.991821 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M389.967407,553.031677 
	C387.871490,552.734863 385.775543,552.438049 384.607605,552.272644 
	C383.555939,554.932434 382.373138,557.923889 381.190338,560.915344 
	C378.885590,558.769775 373.807007,561.803040 373.571869,556.229980 
	C376.500702,556.077637 378.996185,556.298035 382.480530,556.605713 
	C381.180176,554.067139 380.595093,552.924866 379.943909,551.627563 
	C379.877838,551.472534 379.763519,551.153381 379.990906,550.988892 
	C379.461884,549.618713 378.705475,548.413086 378.329315,547.200073 
	C382.545258,549.051086 386.380951,550.909546 390.154510,552.834106 
	C390.092377,552.900146 389.967407,553.031677 389.967407,553.031677 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M397.992340,553.165527 
	C398.180054,552.212341 398.367737,551.259155 398.845093,550.066406 
	C400.571472,551.441589 402.008179,553.056396 403.717651,555.221802 
	C404.005981,556.527039 404.021545,557.281616 404.037109,558.036194 
	C404.007080,558.473755 403.977081,558.911255 403.711121,559.852295 
	C403.317657,560.905396 403.160126,561.455017 403.002563,562.004639 
	C398.236755,559.946167 392.649536,559.182495 389.990997,553.398438 
	C389.967407,553.031677 390.092377,552.900146 390.526276,552.905151 
	C391.978241,552.932739 392.996338,552.955261 394.014404,552.977844 
	C394.014404,552.977844 394.001648,553.000000 394.004089,553.334229 
	C395.281860,554.327637 396.557159,554.986755 397.832489,555.645813 
	C397.885773,554.819031 397.939056,553.992310 397.992340,553.165527 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M425.026001,562.987183 
	C426.519592,564.322510 428.013214,565.657837 429.913513,567.316040 
	C430.827759,568.768311 431.335297,569.897644 431.842804,571.026978 
	C430.743317,571.558655 429.643829,572.090332 428.013367,572.610596 
	C426.926147,571.899414 426.369904,571.199585 425.813660,570.499756 
	C420.696320,574.789062 420.454132,569.659912 418.928162,566.634033 
	C417.929230,565.531860 416.961029,564.747314 415.992828,563.962769 
	C415.992828,563.962769 416.012573,563.467957 416.427002,563.229980 
	C419.569611,562.990417 422.297791,562.988831 425.026001,562.987183 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M418.958862,566.951660 
	C420.454132,569.659912 420.696320,574.789062 425.813660,570.499756 
	C426.369904,571.199585 426.926147,571.899414 427.745331,572.798096 
	C429.344696,573.716187 430.681152,574.435425 433.011566,575.689636 
	C430.458130,576.328247 428.944550,576.706848 427.176910,577.148926 
	C426.342224,576.212952 425.320068,575.066772 424.297913,573.920593 
	C423.987823,574.109558 423.677704,574.298523 423.367615,574.487549 
	C423.729645,575.953369 424.091675,577.419128 424.455872,578.893738 
	C422.185486,578.651855 420.081421,578.427734 417.756470,578.180054 
	C417.616211,576.955566 417.423279,575.271301 417.148254,572.907349 
	C417.697113,570.468933 418.328003,568.710327 418.958862,566.951660 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M454.018158,577.013245 
	C450.915680,576.006042 447.813202,574.998901 444.436279,573.689209 
	C444.469421,572.600952 444.777039,571.815308 445.463806,571.018311 
	C448.261536,571.180420 451.621918,568.306274 452.465851,574.184875 
	C452.941254,571.901062 453.193939,570.687073 453.358826,569.894958 
	C455.777100,570.295227 457.891174,570.645203 460.019440,571.379089 
	C460.375458,573.505432 460.717255,575.247742 460.708893,576.996826 
	C459.240204,577.667725 458.121704,578.331970 457.000244,578.641174 
	C456.775879,577.294495 456.554474,576.302856 456.257965,574.974792 
	C455.219666,575.919739 454.618896,576.466492 454.018158,577.013245 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M380.010010,551.782654 
	C380.595093,552.924866 381.180176,554.067139 382.480530,556.605713 
	C378.996185,556.298035 376.500702,556.077637 373.622742,555.861328 
	C372.737488,555.743591 372.234711,555.621765 371.120483,555.258850 
	C367.733673,553.583008 364.958344,552.148315 362.182983,550.713562 
	C362.577271,550.083618 362.971527,549.453674 364.006714,548.924500 
	C366.092743,549.322571 367.537811,549.619812 369.333740,550.013916 
	C371.094208,550.443848 372.503845,550.776917 374.145264,551.378052 
	C375.505737,551.599426 376.634430,551.552734 378.158325,551.515869 
	C378.810059,551.465271 379.066620,551.404907 379.462585,551.504700 
	C379.601990,551.664917 380.010010,551.782654 380.010010,551.782654 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M408.970673,580.981812 
	C411.249359,580.325134 413.528046,579.668457 416.159454,578.910095 
	C416.383362,579.200134 417.361206,580.466553 418.373627,581.777710 
	C417.003723,583.781433 415.862091,586.279785 413.979248,587.935059 
	C412.551971,589.189819 414.234924,594.592468 410.623535,592.659851 
	C410.172516,590.005493 409.922211,588.532410 409.395386,586.700073 
	C409.619690,585.446960 410.120514,584.553040 410.821320,583.302124 
	C409.237366,583.129517 408.139923,583.009888 407.042480,582.890320 
	C407.033447,582.455200 407.024384,582.020081 407.343018,581.302551 
	C408.104004,581.007324 408.537354,580.994568 408.970673,580.981812 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M432.159637,571.162476 
	C431.335297,569.897644 430.827759,568.768311 430.314545,567.324951 
	C430.308868,567.010986 430.724213,567.011475 431.284058,566.986572 
	C432.301086,567.130066 432.758301,567.298462 433.251404,567.799194 
	C434.913513,569.014648 436.539795,569.897827 438.166046,570.781006 
	C438.743744,569.231445 439.899506,567.632751 439.772949,566.143066 
	C439.543518,563.442627 438.550079,560.807068 438.181580,558.225952 
	C439.522552,558.984619 440.557556,559.661804 441.771362,560.662659 
	C442.530518,563.632874 443.110809,566.279541 443.415649,569.095703 
	C441.088989,570.856384 439.037750,572.447571 436.986542,574.038696 
	C435.483185,573.125122 433.979828,572.211487 432.159637,571.162476 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M424.980316,562.635864 
	C422.297791,562.988831 419.569611,562.990417 416.439209,562.982666 
	C414.229095,560.410034 411.772644,557.933411 414.966553,554.348633 
	C418.313171,556.756042 421.623901,559.520264 424.980316,562.635864 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M454.044250,577.379028 
	C454.618896,576.466492 455.219666,575.919739 456.257965,574.974792 
	C456.554474,576.302856 456.775879,577.294495 457.226196,578.920471 
	C458.642242,580.214355 459.765930,581.073364 461.034515,581.476929 
	C462.284912,581.874695 463.682037,581.811096 465.014801,581.949890 
	C466.692871,583.889587 466.039520,584.800537 463.326172,584.967651 
	C462.191681,585.297791 461.416992,585.621460 460.321442,585.972900 
	C460.000580,586.000671 459.988281,585.991882 459.988281,585.991882 
	C459.933136,582.121704 457.963379,582.496826 455.533600,584.008789 
	C453.993927,584.966858 452.495331,585.990906 450.588196,586.591370 
	C452.610687,583.833313 456.498627,582.056824 454.044250,577.379028 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M368.982880,549.917114 
	C367.537811,549.619812 366.092743,549.322571 364.302246,548.867554 
	C363.968231,548.035217 363.979645,547.360596 363.994141,546.347900 
	C365.293701,544.556274 366.590118,543.102844 368.363190,541.114990 
	C369.838104,542.253174 371.564850,543.585693 373.634644,545.212280 
	C373.977692,545.506348 373.955353,545.994324 373.679840,546.196411 
	C371.930511,547.571350 370.456696,548.744202 368.982880,549.917114 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M417.481689,549.664246 
	C416.107666,549.701477 414.624329,550.083801 413.386322,549.691650 
	C411.713104,549.161499 410.228363,548.036377 408.302429,547.146118 
	C407.442322,546.874695 406.942505,546.623779 406.164062,546.134644 
	C405.867493,545.713440 405.849548,545.530457 405.831573,545.347534 
	C405.666351,544.847839 405.501129,544.348206 405.220886,543.248108 
	C405.108429,542.391479 405.110992,542.135254 405.476257,541.908203 
	C409.258179,543.921631 412.677429,545.906006 416.315430,548.072083 
	C416.850037,548.723999 417.165863,549.194153 417.481689,549.664246 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M383.710876,567.997803 
	C383.117950,570.178528 382.542114,572.662659 381.103363,574.453552 
	C379.205292,576.816284 376.621429,577.689514 374.148193,574.293579 
	C375.149353,573.815857 376.292328,573.637878 377.435333,573.459900 
	C377.674133,573.864563 377.912933,574.269226 378.151733,574.673828 
	C379.084229,572.709351 380.016724,570.744934 381.323151,567.992737 
	C381.092651,567.992737 382.218323,567.992737 383.710876,567.997803 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M362.213440,551.097168 
	C364.958344,552.148315 367.733673,553.583008 370.734100,555.228760 
	C366.501343,558.363708 364.395142,557.406921 362.213440,551.097168 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M418.928162,566.634033 
	C418.328003,568.710327 417.697113,570.468933 417.003662,572.583618 
	C416.941101,572.939697 416.520935,572.942688 416.122894,572.704163 
	C414.965973,572.359009 414.207092,572.252319 413.450958,572.146057 
	C413.633026,570.449036 413.818054,568.724609 414.003052,567.000183 
	C414.584961,566.178528 415.166901,565.356812 415.870819,564.248962 
	C416.961029,564.747314 417.929230,565.531860 418.928162,566.634033 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M406.777222,583.122742 
	C408.139923,583.009888 409.237366,583.129517 410.821320,583.302124 
	C410.120514,584.553040 409.619690,585.446960 409.076538,586.670288 
	C407.153503,587.898743 405.272827,588.797852 403.392151,589.696960 
	C403.106598,589.229248 402.821045,588.761536 402.535461,588.293823 
	C403.860992,586.647644 405.186493,585.001404 406.777222,583.122742 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M437.041382,574.376404 
	C439.037750,572.447571 441.088989,570.856384 443.735413,569.231689 
	C444.581940,569.808594 444.833282,570.419128 445.084625,571.029663 
	C444.777039,571.815308 444.469421,572.600952 444.065857,573.689941 
	C441.678680,574.233521 439.387451,574.473816 437.041382,574.376404 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M413.771393,567.136597 
	C413.818054,568.724609 413.633026,570.449036 413.450958,572.146057 
	C414.207092,572.252319 414.965973,572.359009 415.913025,572.694519 
	C414.967499,574.385437 413.833832,575.847534 412.700134,577.309570 
	C412.257751,577.091003 411.815369,576.872498 411.372955,576.653931 
	C413.753540,573.780640 407.883148,569.645203 413.771393,567.136597 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M366.559265,575.078369 
	C366.121246,572.455261 366.028534,569.768005 365.953247,566.714478 
	C367.098419,566.834778 368.226166,567.321228 369.628204,567.950928 
	C368.903168,570.400818 367.903870,572.707458 366.559265,575.078369 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M459.021973,588.015747 
	C458.877808,594.745850 454.523071,589.130737 452.633392,590.743042 
	C452.509399,590.098572 452.385376,589.454102 452.261383,588.809631 
	C454.259308,588.537537 456.257202,588.265442 458.626221,587.995300 
	C458.997284,587.997314 459.021973,588.015747 459.021973,588.015747 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M338.414490,566.016968 
	C338.704590,564.349609 339.366821,562.680725 340.323608,560.859009 
	C340.748474,562.176819 340.878754,563.647400 341.016785,565.557800 
	C340.278564,566.003540 339.532593,566.009521 338.414490,566.016968 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M354.327393,543.930359 
	C355.397858,542.634338 356.865662,541.371582 358.679504,540.097473 
	C359.212982,543.176636 357.615326,544.287170 354.327393,543.930359 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M350.420319,548.024048 
	C351.107300,546.690063 352.168854,545.356812 353.566467,543.979858 
	C352.866608,545.299011 351.830750,546.661865 350.420319,548.024048 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M405.459045,545.220093 
	C405.849548,545.530457 405.867493,545.713440 405.873230,546.174438 
	C404.641602,547.443237 403.422211,548.433960 402.148743,549.468567 
	C402.100922,548.653198 402.020935,547.290466 401.939331,545.549316 
	C402.987305,545.144836 404.036926,545.118774 405.459045,545.220093 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M399.993103,576.994507 
	C398.358978,577.224121 396.724884,577.453796 395.039276,577.347778 
	C394.987793,577.012207 395.000061,577.000000 395.006470,576.993530 
	C396.399445,576.996826 397.786011,577.006653 399.586273,577.008179 
	C400.000000,577.000000 399.993103,576.994507 399.993103,576.994507 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M396.450134,585.284485 
	C396.721771,584.426392 397.197784,583.814636 397.673828,583.202881 
	C397.978851,583.494568 398.283905,583.786255 398.588928,584.077942 
	C397.944122,584.562256 397.299316,585.046509 396.450134,585.284485 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M404.369934,557.977295 
	C404.021545,557.281616 404.005981,556.527039 403.992371,555.396057 
	C404.230469,555.985901 404.466614,556.952148 404.369934,557.977295 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M345.407379,552.995605 
	C345.745667,552.293640 346.442413,551.583252 347.480835,550.835327 
	C347.136932,551.527588 346.451385,552.257385 345.407379,552.995605 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M404.251862,581.706909 
	C404.446350,582.292480 404.371552,582.929321 404.296753,583.566101 
	C404.030487,583.480896 403.527252,583.343994 403.532898,583.318054 
	C403.647827,582.790405 403.821045,582.275574 404.251862,581.706909 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M403.304382,561.992676 
	C403.160126,561.455017 403.317657,560.905396 403.706848,560.181274 
	C403.827759,560.664734 403.716980,561.322693 403.304382,561.992676 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M408.835449,580.760132 
	C408.537354,580.994568 408.104004,581.007324 407.348511,580.976196 
	C407.584320,580.800903 408.142273,580.669678 408.835449,580.760132 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M460.006226,586.006470 
	C459.847198,586.570374 459.682587,587.128540 459.269958,587.851196 
	C459.021973,588.015747 458.997284,587.997314 458.985718,587.985840 
	C459.143738,587.418518 459.313324,586.862549 459.735596,586.149292 
	C459.988281,585.991882 460.000580,586.000671 460.006226,586.006470 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M400.002594,577.003357 
	C400.093323,576.968811 400.181458,576.930969 400.131348,576.943787 
	C399.993103,576.994507 400.000000,577.000000 400.002594,577.003357 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M439.955750,540.555847 
	C440.601410,541.174988 441.247070,541.794189 442.155273,542.498108 
	C443.733765,544.296082 445.049774,546.009338 446.643127,548.156982 
	C446.000977,548.697693 445.081512,548.803894 443.905518,548.671509 
	C441.438416,547.260742 439.227783,546.088562 437.008362,544.565308 
	C436.287964,543.618591 435.576355,543.022888 434.565796,542.344543 
	C433.866760,542.158630 433.466614,542.055420 432.935364,541.644104 
	C431.500763,539.939270 430.197296,538.542419 428.893799,537.145630 
	C430.393921,537.317993 431.894073,537.490295 433.595642,537.414246 
	C435.152863,537.856812 436.508636,538.547791 438.396240,539.525513 
	C439.270660,540.060120 439.613190,540.307983 439.955750,540.555847 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M444.162018,548.910156 
	C445.081512,548.803894 446.000977,548.697693 446.935120,548.323364 
	C450.373138,552.447205 453.796509,556.839172 456.925720,561.606506 
	C456.181549,561.944885 455.731567,561.907837 455.139526,561.658142 
	C454.748566,561.217773 454.499664,560.990051 454.165649,560.451050 
	C453.401978,559.726318 452.723419,559.312988 451.837646,558.866638 
	C451.630402,558.833679 451.216278,558.765320 451.153442,558.407898 
	C448.781067,555.003723 446.471558,551.956909 444.162018,548.910156 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M455.281616,561.870850 
	C455.731567,561.907837 456.181549,561.944885 456.975311,561.975952 
	C459.551636,566.717651 461.784149,571.465332 463.639038,576.607300 
	C462.527313,576.997742 461.793182,576.993958 461.059052,576.990112 
	C460.717255,575.247742 460.375458,573.505432 459.999023,571.063965 
	C459.346039,569.571716 458.727631,568.778625 458.080139,567.608765 
	C457.127930,565.444946 456.204773,563.657898 455.281616,561.870850 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M460.708893,576.996826 
	C461.793182,576.993958 462.527313,576.997742 463.629395,577.000793 
	C464.337952,578.395447 464.678558,579.790894 465.016968,581.568115 
	C463.682037,581.811096 462.284912,581.874695 461.034515,581.476929 
	C459.765930,581.073364 458.642242,580.214355 457.229126,579.275513 
	C458.121704,578.331970 459.240204,577.667725 460.708893,576.996826 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M339.301147,588.172974 
	C338.872131,588.867981 338.443146,589.562988 337.807739,590.489868 
	C336.663452,591.554260 335.725586,592.386780 334.162354,593.774292 
	C334.162354,591.593506 334.162354,589.741394 334.104492,587.465698 
	C334.046631,587.042114 334.039429,587.031799 334.348938,586.930054 
	C334.788391,586.225342 334.918304,585.622498 335.021667,585.016235 
	C334.995117,585.012878 335.037842,585.045593 335.362366,585.049561 
	C336.143555,584.379272 336.600189,583.705139 337.032928,583.012695 
	C337.009033,582.994446 337.034668,583.048706 337.263916,582.936890 
	C337.621094,582.521057 337.749054,582.217041 337.877014,581.912964 
	C339.542938,580.148560 341.208862,578.384155 342.874786,576.619751 
	C343.218140,576.874939 343.561462,577.130127 343.904785,577.385376 
	C343.369293,578.388184 342.833801,579.391052 341.808197,580.842773 
	C340.645782,583.585388 339.973480,585.879211 339.301147,588.172974 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M342.955688,596.042236 
	C345.678650,595.341064 348.401642,594.639954 351.511963,593.912109 
	C347.899445,598.309998 342.557434,600.209595 338.857727,598.221497 
	C338.180634,597.857605 338.203827,596.190491 338.504822,595.303589 
	C340.389130,595.668884 341.672424,595.855530 342.955688,596.042236 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M330.706451,586.093994 
	C331.919983,584.294128 333.072357,582.662537 334.500214,581.325073 
	C334.947784,580.905823 336.136963,581.278381 336.989655,581.893555 
	C337.006683,582.682007 337.020691,582.865356 337.034668,583.048706 
	C337.034668,583.048706 337.009033,582.994446 336.701904,583.027710 
	C335.942444,583.722534 335.490173,584.384094 335.037842,585.045593 
	C335.037842,585.045593 334.995117,585.012878 334.757629,585.167419 
	C334.359894,585.891907 334.199646,586.461853 334.039429,587.031799 
	C334.039429,587.031799 334.046631,587.042114 334.059570,587.053345 
	C333.632843,587.294006 333.193146,587.523438 332.531372,587.857910 
	C331.790070,587.433838 331.270874,586.904846 330.706451,586.093994 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M342.969208,595.657959 
	C341.672424,595.855530 340.389130,595.668884 338.806458,595.212341 
	C338.629272,594.515442 338.751465,594.088379 338.937561,593.334900 
	C340.432281,592.286133 341.863129,591.563904 343.642853,590.861694 
	C343.655426,592.345764 343.319092,593.809753 342.969208,595.657959 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M352.235168,593.899109 
	C353.349579,592.887512 354.807648,591.906982 356.627380,590.967041 
	C356.603027,591.686890 356.217072,592.366028 355.525940,593.144409 
	C354.340118,593.472473 353.459473,593.701294 352.235168,593.899109 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M348.002594,606.991943 
	C348.308197,606.474609 348.611755,605.964966 348.915344,605.455322 
	C349.126770,605.637329 349.338165,605.819397 349.549591,606.001404 
	C349.227142,606.306641 348.904663,606.611938 348.299042,606.955994 
	C348.015869,606.994812 348.000549,606.999573 348.002594,606.991943 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M332.005188,592.638550 
	C331.752106,592.348999 331.523254,591.681213 331.545319,590.888062 
	C331.873962,591.261841 331.951691,591.761108 332.005188,592.638550 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M428.722015,537.043457 
	C430.197296,538.542419 431.500763,539.939270 432.587128,541.690247 
	C430.862610,543.443604 429.355225,544.842834 427.542969,546.178650 
	C426.831757,546.031555 426.425415,545.947815 425.980164,545.522522 
	C421.939545,539.881470 415.942596,537.825562 410.098907,535.632141 
	C404.519440,533.537903 398.849823,530.936890 392.764282,535.111938 
	C392.340576,535.508423 392.147797,535.789612 391.616882,536.064575 
	C390.462280,535.749023 389.645813,535.439697 389.082336,534.920654 
	C388.038483,532.128296 386.616364,532.318420 384.664124,534.661011 
	C379.178894,534.561523 374.132904,534.320984 369.086945,534.080444 
	C368.134369,533.683167 367.181793,533.285889 366.191711,532.563110 
	C372.151642,531.169434 378.149048,530.101257 384.798279,529.276306 
	C386.632538,529.285339 387.815002,529.051147 388.997467,528.817017 
	C390.043518,528.785339 391.089539,528.753662 392.850037,528.968872 
	C395.704132,529.210815 397.843750,529.205811 399.983368,529.200745 
	C401.327515,529.207520 402.671661,529.214233 404.485901,529.577271 
	C407.724030,530.491821 410.492096,531.049988 413.260162,531.608154 
	C413.654022,531.620178 414.047913,531.632141 414.848022,532.001465 
	C419.565186,533.830688 423.876099,535.302490 428.187012,536.774292 
	C428.187012,536.774292 428.550232,536.941223 428.722015,537.043457 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M428.087189,536.438477 
	C423.876099,535.302490 419.565186,533.830688 415.143127,531.980347 
	C419.350464,533.102173 423.668915,534.602417 428.087189,536.438477 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M413.110535,531.284302 
	C410.492096,531.049988 407.724030,530.491821 404.887390,529.610107 
	C407.532867,529.844604 410.246887,530.402527 413.110535,531.284302 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M399.785828,528.862000 
	C397.843750,529.205811 395.704132,529.210815 393.241882,528.994934 
	C395.142242,528.690430 397.365295,528.606812 399.785828,528.862000 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M388.765869,528.591187 
	C387.815002,529.051147 386.632538,529.285339 385.225952,529.273987 
	C386.179321,528.807495 387.356812,528.586426 388.765869,528.591187 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M331.645935,573.968506 
	C331.782227,574.517151 331.645630,575.095886 331.247437,575.835205 
	C331.114899,575.329956 331.243958,574.664307 331.645935,573.968506 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M330.315613,578.086304 
	C330.605621,578.359375 330.730988,578.843628 330.846008,579.706787 
	C330.607452,579.489624 330.379211,578.893494 330.315613,578.086304 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M394.898132,523.509521 
	C395.118011,523.171509 395.427032,523.231628 395.840637,523.672363 
	C395.661041,523.824524 395.341736,523.776062 394.898132,523.509521 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M593.011841,352.986755 
	C593.141479,349.653931 592.031067,345.664795 597.616638,345.015045 
	C597.897766,350.201080 602.502747,346.722565 604.523438,348.899048 
	C604.428894,349.563904 604.273865,350.654877 604.112183,351.747742 
	C605.385437,352.024048 606.665222,352.298431 608.952148,352.788757 
	C607.069092,353.942902 606.023682,354.583649 604.978271,355.224396 
	C605.088928,355.719788 605.199585,356.215210 605.310242,356.710602 
	C607.244751,356.406586 609.179260,356.102570 612.303406,355.611603 
	C611.806274,358.966431 611.314819,362.282715 610.823425,365.598969 
	C610.195923,365.746338 609.568420,365.893738 608.940979,366.041107 
	C607.438293,363.956787 605.935608,361.872498 604.371216,359.702606 
	C602.880981,360.479889 602.880981,360.479889 603.518799,369.196411 
	C602.243103,367.545044 601.366943,366.410889 599.871826,364.475433 
	C599.189819,367.357117 598.683533,369.496338 598.152039,371.742279 
	C597.277527,371.409760 596.386169,371.070831 595.494812,370.731903 
	C595.179626,371.007324 594.864380,371.282776 594.549133,371.558197 
	C596.292969,373.593781 598.036743,375.629395 600.029907,377.956055 
	C600.418274,375.211212 600.743530,372.911987 601.014709,370.995056 
	C603.534241,370.161011 605.573547,369.485962 607.757385,368.763062 
	C609.280762,374.274384 610.982239,380.430054 612.722717,386.726715 
	C612.112854,386.592072 610.910217,386.326569 609.236694,385.816559 
	C608.405457,385.590149 608.045227,385.608276 607.684937,385.626404 
	C608.120850,385.752991 608.556763,385.879547 608.992676,386.006104 
	C607.667908,388.748199 606.343079,391.490326 604.846863,394.587311 
	C607.564392,395.918671 612.466980,395.142517 612.452759,400.382263 
	C605.843262,400.998718 601.023071,395.958679 599.221497,387.432770 
	C598.812317,389.031616 598.403137,390.630463 597.658508,392.613586 
	C596.876465,392.996460 596.429871,392.994995 595.948364,392.637756 
	C595.282288,390.522614 594.651123,388.763245 594.008789,387.002319 
	C593.997559,387.000732 594.010315,387.020020 594.367676,386.946564 
	C597.305420,385.710480 599.885803,384.547821 602.466125,383.385193 
	C602.615601,383.095276 602.765137,382.805389 602.914673,382.515472 
	C600.281372,382.338440 597.648132,382.161407 595.044983,381.632965 
	C595.619263,379.724152 596.163391,378.166748 596.798950,376.347778 
	C594.763367,376.621033 593.342590,376.811737 591.539673,377.004517 
	C590.138306,377.015381 589.119202,377.024200 588.070923,376.680267 
	C588.019775,374.885010 587.997681,373.442505 588.127747,371.664978 
	C591.102905,367.172119 590.682495,362.598907 590.241089,357.826477 
	C591.602722,357.244171 592.711426,356.825989 594.227051,356.254303 
	C593.885315,355.335419 593.448608,354.161102 593.011841,352.986755 
M603.368347,375.215698 
	C603.732605,375.263824 604.096802,375.311981 604.461060,375.360138 
	C604.464294,375.100037 604.467529,374.839966 604.470764,374.579895 
	C604.105469,374.620819 603.740234,374.661774 603.368347,375.215698 
M600.175354,359.750275 
	C600.119751,359.581970 600.064148,359.413666 600.008606,359.245361 
	C599.953613,359.414886 599.898621,359.584381 600.175354,359.750275 
M601.404663,357.484192 
	C601.404663,357.484192 601.530151,357.404358 601.404663,357.484192 
M608.591003,378.519958 
	C608.591003,378.519958 608.478455,378.588379 608.591003,378.519958 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M588.100037,377.033020 
	C589.119202,377.024200 590.138306,377.015381 591.559631,377.378540 
	C591.980835,378.499451 591.999878,379.248322 591.796143,380.103516 
	C591.295471,380.408569 591.104370,380.670563 590.667725,381.044678 
	C587.980530,383.968750 587.792664,384.662842 589.008789,386.392639 
	C590.932007,389.485321 592.853699,392.186554 595.107300,395.354401 
	C595.559875,394.134644 595.771545,393.564087 595.983215,392.993530 
	C596.429871,392.994995 596.876465,392.996460 597.657898,393.000641 
	C598.397888,393.892151 599.362488,395.009338 599.109375,395.629181 
	C598.133118,398.019745 596.767761,400.251373 595.375244,402.865295 
	C593.532104,401.476044 591.351624,399.832520 588.644958,397.792450 
	C586.930359,399.916931 585.303833,401.932343 584.002380,403.544922 
	C581.578796,402.532684 578.619324,401.296661 575.329956,400.030334 
	C575.000000,400.000000 575.002441,400.002441 575.002441,400.002441 
	C578.055847,395.427917 581.572388,397.425018 585.280945,399.618988 
	C586.130371,397.568085 586.900208,395.709259 587.670044,393.850403 
	C583.722412,397.786987 580.023193,391.957855 575.227173,393.679169 
	C571.785583,394.914429 573.622986,399.161163 569.650269,398.305450 
	C569.061340,396.120270 568.342896,393.454498 567.575012,390.605194 
	C568.741089,389.277740 570.146240,387.678040 571.207581,386.469818 
	C569.944519,385.205292 568.977051,384.236694 568.588745,383.847931 
	C567.556641,385.262909 566.152771,387.187469 564.194458,389.872223 
	C561.720703,384.135468 560.647888,379.554626 562.999390,374.353668 
	C563.359924,373.893677 563.610596,373.700592 564.300659,373.204620 
	C566.324158,373.996063 567.844543,375.007538 569.364868,376.019043 
	C569.220337,376.378113 569.075745,376.737152 568.931152,377.096222 
	C570.885498,377.096222 572.839905,377.096222 574.794250,377.096222 
	C574.781006,377.495361 574.767822,377.894470 574.754578,378.293610 
	C573.109558,378.903595 571.464478,379.513580 569.601379,380.204437 
	C571.519165,381.314087 573.154785,382.260498 574.491943,383.034241 
	C573.511963,385.926117 572.679138,388.383881 571.846252,390.841644 
	C572.370911,391.019989 572.895569,391.198364 573.420166,391.376740 
	C573.885925,390.283417 574.351685,389.190094 574.814087,388.104584 
	C576.767395,388.850311 578.437439,389.487854 581.020081,390.473846 
	C580.755981,385.438416 580.546814,381.451385 580.315552,377.042633 
	C582.352783,378.284546 583.855774,379.200775 585.725403,380.340515 
	C586.479614,379.290009 587.289795,378.161499 588.100037,377.033020 
M564.923157,376.819580 
	C564.721130,377.665649 564.519165,378.511688 564.317139,379.357758 
	C564.723755,379.423004 565.130432,379.488251 565.537048,379.553528 
	C565.537048,378.602325 565.537048,377.651154 564.923157,376.819580 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M596.980286,425.935028 
	C595.686584,426.940887 594.392944,427.946716 593.099243,428.952576 
	C592.382690,427.873322 591.808899,426.648834 590.905090,425.760040 
	C590.162781,425.030121 589.028259,424.699066 587.792053,424.045532 
	C587.365662,422.489441 586.793152,420.400055 586.148499,418.047485 
	C586.888977,417.797424 587.720276,417.516663 589.502014,416.914948 
	C587.123169,414.740021 585.124939,412.913147 583.126709,411.086243 
	C582.088318,413.366455 581.049988,415.646637 579.914001,418.141083 
	C579.467957,417.513519 578.773438,416.536438 578.382812,415.986938 
	C576.351501,418.847473 574.296509,421.741455 572.144897,424.771393 
	C570.837952,422.470886 569.723083,420.508545 568.647217,418.614807 
	C569.809387,416.817291 571.145691,414.750427 572.578552,412.534241 
	C576.694580,414.182220 577.762939,413.597076 580.784485,407.257935 
	C582.130859,407.727509 583.502808,408.206024 585.209839,408.801392 
	C586.396423,406.527008 587.585815,404.247253 588.914429,401.700684 
	C590.265076,402.904633 591.572510,404.070068 592.606079,404.991364 
	C592.278259,407.228668 591.992859,409.176666 591.732117,410.956146 
	C594.062988,410.956146 595.677429,410.956146 597.291931,410.956146 
	C596.829163,412.167725 596.366394,413.379272 595.793030,414.880402 
	C596.975159,414.734680 598.176208,414.586609 600.234741,414.332825 
	C598.941650,416.484039 597.999451,418.051453 596.440918,420.644196 
	C598.424255,420.439819 599.613403,420.268066 600.808105,420.209167 
	C601.833252,420.158661 602.864807,420.237274 603.960632,420.967896 
	C604.156311,422.969910 604.284851,424.262848 604.476746,426.192688 
	C601.890808,424.919922 599.944214,423.961792 597.742554,423.169983 
	C597.319580,423.908905 597.151611,424.481537 596.983582,425.274384 
	C596.983459,425.494598 596.980286,425.935028 596.980286,425.935028 
M594.623474,424.511993 
	C594.623474,424.511993 594.459595,424.417267 594.459595,424.417267 
	C594.459595,424.417267 594.481750,424.602173 594.623474,424.511993 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M597.264343,426.213989 
	C596.980286,425.935028 596.983459,425.494598 597.228455,425.095276 
	C597.648132,424.131836 597.822815,423.567749 597.997559,423.003662 
	C599.944214,423.961792 601.890808,424.919922 604.476746,426.192688 
	C604.284851,424.262848 604.156311,422.969910 604.036438,421.311584 
	C605.891357,420.572876 607.652771,420.315735 605.431519,417.903503 
	C604.524780,416.918732 604.225037,415.375061 603.324951,414.042816 
	C603.000793,414.000000 602.994629,414.017975 602.994629,414.017975 
	C602.800293,412.847626 602.606018,411.677277 602.339233,410.070679 
	C604.267212,410.437988 605.755310,410.721497 607.621704,411.002502 
	C608.000000,411.000000 607.992126,410.998199 607.992126,410.998199 
	C608.791504,412.433197 609.590942,413.868164 610.652283,415.773346 
	C608.378113,418.942780 606.488831,422.593903 612.797546,424.666992 
	C613.411804,424.868835 614.049194,427.360321 613.761841,427.577026 
	C611.216248,429.496613 608.464233,431.142395 605.410522,433.101746 
	C604.674561,434.299225 603.516174,436.184021 602.226562,438.282349 
	C603.934998,438.364014 605.675110,438.447205 608.021362,438.559387 
	C607.165161,440.870972 606.579529,442.452026 605.847473,444.428619 
	C607.404907,444.625336 608.601440,444.776489 609.798035,444.927643 
	C609.877014,445.369232 609.956055,445.810822 610.035034,446.252380 
	C605.294678,446.914703 600.554260,447.577026 595.214355,448.323120 
	C596.459534,446.002686 597.572754,443.928253 598.752258,441.730194 
	C597.080017,441.439117 595.532227,441.169678 592.733765,440.682556 
	C595.045227,438.962738 596.555969,437.838715 597.548401,437.100311 
	C597.548401,433.661713 597.548401,430.077332 597.264343,426.213989 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M564.996338,450.980896 
	C563.667114,448.328003 562.907410,445.085327 560.893921,443.141052 
	C556.237366,438.644653 555.599731,436.386871 559.506104,429.790771 
	C559.506104,432.181030 559.506104,433.833069 559.506104,435.485138 
	C559.786438,435.535034 560.066772,435.584961 560.347107,435.634888 
	C560.869019,434.007812 561.390930,432.380768 562.286865,430.377747 
	C563.107239,430.001190 563.553650,430.000580 564.054321,430.141052 
	C564.196411,430.331909 564.284119,430.381653 564.371826,430.431396 
	C564.247925,430.287598 564.123962,430.143799 564.034119,429.696960 
	C565.034302,429.259338 566.000305,429.124756 566.966370,428.990173 
	C566.320496,430.600800 565.674561,432.211395 564.941223,434.040039 
	C568.918640,438.232727 566.737549,443.568756 566.730591,449.152771 
	C566.256287,450.096954 565.626343,450.538910 564.996338,450.980896 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M585.074341,446.749268 
	C582.923523,446.988251 580.953979,446.986450 578.517944,446.656281 
	C577.399719,446.045074 576.260681,445.409424 576.170898,445.533478 
	C575.182251,446.898926 574.356812,448.382568 573.487183,449.834167 
	C573.150635,449.765717 572.814026,449.697296 572.477478,449.628876 
	C572.344543,448.425751 572.211609,447.222626 572.078674,446.019501 
	C572.585266,444.473816 573.362610,442.955627 573.523193,441.374786 
	C573.687317,439.759277 573.246399,438.082275 573.000061,435.774170 
	C573.020813,435.781464 572.126160,435.466125 570.114990,434.757294 
	C572.754517,433.018433 574.533936,431.846222 576.313293,430.673981 
	C576.666016,430.920624 577.018677,431.167236 577.371399,431.413879 
	C571.627075,440.261658 580.380310,440.369171 583.334290,442.876556 
	C585.558411,442.346252 587.220093,441.950012 589.445557,441.419373 
	C587.609924,443.648987 586.432800,445.078735 585.074341,446.749268 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M612.371460,421.130249 
	C616.422607,416.880280 618.057007,421.595337 620.324463,423.271332 
	C620.911438,423.705200 620.186646,425.913727 620.133606,426.518433 
	C622.344116,430.445831 624.413208,434.121887 626.482239,437.797943 
	C626.024597,438.232086 625.566956,438.666260 625.109314,439.100433 
	C623.854004,438.660309 622.030151,438.597382 621.443726,437.713470 
	C619.134949,434.233612 617.194641,430.507080 615.160461,426.848236 
	C614.181763,425.087769 613.298462,423.274200 612.371460,421.130249 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M571.705261,446.017517 
	C572.211609,447.222626 572.344543,448.425751 572.477478,449.628876 
	C572.814026,449.697296 573.150635,449.765717 573.487183,449.834167 
	C574.356812,448.382568 575.182251,446.898926 576.170898,445.533478 
	C576.260681,445.409424 577.399719,446.045074 578.093262,446.648010 
	C576.315186,450.040833 573.848694,453.229584 580.478394,454.786530 
	C580.025452,455.197906 579.572571,455.609253 579.119629,456.020599 
	C577.721497,455.273132 576.298218,454.568481 574.931763,453.766937 
	C573.369568,452.850647 571.857910,451.848175 569.489136,450.357635 
	C569.776001,453.284546 569.956665,455.128479 570.083923,456.426697 
	C571.948608,457.548676 573.500427,458.482422 575.052246,459.416138 
	C568.311646,460.411377 565.683167,455.951294 563.032043,451.135437 
	C563.464417,450.813019 563.901245,450.869537 564.667236,450.953491 
	C565.626343,450.538910 566.256287,450.096954 566.921753,449.333618 
	C568.415466,448.013306 569.873657,447.014404 571.705261,446.017517 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M607.998901,411.004181 
	C608.327759,410.134308 608.657654,409.260254 608.935364,408.524445 
	C607.304443,407.979767 605.631104,407.420959 603.051636,406.559509 
	C606.522034,405.242798 608.686951,404.185699 610.973694,403.686401 
	C611.734497,403.520325 612.807739,404.784912 613.879517,406.081482 
	C613.700134,409.495239 613.379272,412.222900 613.058411,414.950562 
	C611.583801,413.726288 610.109192,412.501984 608.313354,411.137939 
	C607.992126,410.998199 608.000000,411.000000 607.998901,411.004181 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M564.361694,417.790588 
	C562.818542,417.385895 561.537537,416.740662 559.633545,415.781708 
	C560.194885,413.954987 560.778015,412.057251 561.747437,410.070007 
	C564.294678,409.803772 566.455627,409.627014 568.833862,409.432465 
	C567.519043,411.967682 566.071411,414.758881 564.361694,417.790588 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M596.860352,457.694580 
	C592.603943,458.151886 594.180420,454.585785 593.751587,452.214478 
	C595.753174,451.690613 597.501343,451.389832 599.624756,451.044556 
	C600.000000,451.000000 599.987549,450.990265 599.987549,450.990234 
	C601.771973,452.393494 603.556335,453.796722 605.340759,455.199982 
	C604.991333,455.868011 604.641968,456.536041 604.292603,457.204071 
	C601.479492,456.506653 598.666443,455.809235 595.244202,454.960815 
	C595.942383,456.101044 596.342224,456.753998 596.860352,457.694580 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M613.423340,414.962158 
	C613.379272,412.222900 613.700134,409.495239 614.011719,406.390137 
	C615.472534,404.947540 616.942627,403.882355 618.707581,402.919861 
	C619.503784,407.144806 618.871521,410.975555 616.047852,414.598877 
	C615.251038,414.949951 614.519714,414.961884 613.423340,414.962158 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M574.998779,399.998779 
	C575.007019,401.064667 575.016479,402.131744 575.013000,403.599426 
	C575.000000,404.000000 575.000000,404.001221 575.000000,404.001221 
	C572.761719,404.507996 570.538086,405.240723 568.276428,405.387146 
	C567.576904,405.432465 566.774048,403.882538 565.798462,402.824310 
	C569.092163,401.715485 571.651428,400.853882 574.606567,399.997375 
	C575.002441,400.002441 575.000000,400.000000 574.998779,399.998779 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M600.201538,345.279907 
	C602.328552,344.430664 604.690613,343.850647 607.283569,343.213928 
	C607.803162,344.680054 608.278442,346.021179 608.911926,347.697327 
	C606.192322,347.204620 603.314514,346.376862 600.201538,345.279907 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M567.307861,428.990051 
	C566.000305,429.124756 565.034302,429.259338 564.034119,429.696930 
	C563.553650,430.000580 563.107239,430.001190 562.325989,430.005066 
	C562.436707,428.692474 562.882385,427.376587 563.674377,426.027588 
	C565.566467,426.291168 567.112183,426.587830 568.657959,426.884491 
	C568.321716,427.586304 567.985535,428.288086 567.307861,428.990051 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M555.988892,376.594238 
	C554.820496,377.024658 553.663879,377.024658 552.019470,377.024658 
	C552.019470,376.091919 552.019470,374.898499 552.015198,373.338745 
	C553.117310,372.334259 554.223755,371.696136 555.660278,371.033875 
	C555.993713,372.727783 555.997192,374.445770 555.988892,376.594238 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M575.000000,403.999390 
	C576.132690,405.594788 577.265320,407.190765 578.398010,408.786774 
	C577.849426,409.109344 577.300903,409.431915 576.752319,409.754517 
	C574.801575,408.792969 571.734375,408.448181 574.953308,404.328918 
	C575.000000,404.001221 575.000000,404.000000 575.000000,403.999390 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M592.636719,435.541992 
	C591.610107,435.518982 590.746826,435.205322 589.883484,434.891663 
	C590.754761,433.626495 591.625977,432.361298 592.497192,431.096100 
	C592.863831,431.317596 593.230408,431.539093 593.596985,431.760590 
	C593.331299,432.924194 593.065613,434.087769 592.636719,435.541992 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M562.472778,408.073853 
	C562.196350,406.108246 562.260193,404.109711 562.663208,402.056213 
	C562.975464,404.015594 566.926880,406.154724 562.472778,408.073853 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M576.879150,426.602173 
	C576.145752,426.076630 575.473572,425.199280 574.801331,424.321899 
	C575.300537,424.025238 575.799805,423.728577 576.299011,423.431885 
	C576.512817,424.371368 576.726562,425.310822 576.879150,426.602173 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M613.387329,373.955322 
	C613.787598,373.711273 614.185974,373.712891 614.582153,373.743469 
	C614.593445,373.744324 614.580994,374.052826 614.579651,374.217865 
	C614.181458,374.213623 613.783325,374.209381 613.387329,373.955322 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M609.533997,437.395508 
	C609.650879,436.942505 609.936890,436.667755 610.222900,436.392975 
	C610.335571,436.512238 610.553467,436.728271 610.545654,436.736847 
	C610.278076,437.027985 609.989014,437.299408 609.533997,437.395508 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M602.992554,414.002991 
	C602.866394,414.429779 602.748474,414.853607 602.630615,415.277405 
	C602.617981,414.937286 602.605286,414.597198 602.793579,414.137512 
	C602.994629,414.017975 603.000793,414.000000 602.992554,414.002991 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M638.393677,433.716309 
	C638.557983,433.846344 638.582153,434.072327 638.606323,434.298279 
	C638.488770,434.136261 638.371155,433.974274 638.393677,433.716309 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M626.223389,362.026978 
	C626.396118,361.911133 626.571594,361.967682 626.747070,362.024231 
	C626.573425,362.082611 626.399780,362.140961 626.223389,362.026978 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M598.263794,345.004486 
	C598.288147,344.886688 598.561890,344.771362 598.923950,344.828613 
	C599.012207,345.001221 598.513306,345.006958 598.263794,345.004486 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M587.541321,435.562317 
	C587.469482,435.608765 587.447937,435.418732 587.447937,435.418732 
	C587.447937,435.418732 587.613159,435.515839 587.541321,435.562317 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M600.004761,451.006226 
	C600.096069,450.974243 600.182556,450.936066 600.128296,450.944092 
	C599.987549,450.990265 600.000000,451.000000 600.004761,451.006226 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M611.551392,443.461731 
	C611.590027,443.523987 611.512695,443.399445 611.551392,443.461731 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M591.531494,443.552246 
	C591.471130,443.587860 591.591919,443.516663 591.531494,443.552246 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M604.914673,493.778687 
	C604.297119,494.882996 603.472717,495.741272 602.791870,496.810394 
	C600.790466,497.556549 598.645508,498.091858 596.584534,498.606201 
	C595.983765,502.843689 595.399292,506.966583 594.750854,511.540222 
	C593.127930,510.509064 591.031006,509.176758 588.941101,507.848969 
	C590.639771,506.484039 592.784790,505.600677 593.157104,504.234344 
	C593.561462,502.750366 592.316833,500.817108 591.435059,498.683014 
	C591.083984,497.861725 591.089172,497.437195 591.094360,497.012634 
	C593.466492,496.557159 595.838623,496.101685 599.267517,495.443329 
	C597.202026,494.113678 596.075317,493.388367 593.841370,491.950256 
	C596.388000,491.668518 597.644775,491.529480 599.351929,491.340607 
	C599.132263,490.541779 598.806213,489.872742 598.795837,489.198822 
	C598.779724,488.157715 598.903870,487.093781 599.163147,486.089752 
	C599.205566,485.925476 600.679077,485.841370 600.753906,486.023376 
	C601.136780,486.954559 601.554993,488.017365 601.443665,488.973938 
	C601.239563,490.728394 600.673279,492.440704 600.160034,494.578491 
	C601.892944,494.179993 603.300354,493.856354 604.914673,493.778687 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M561.707520,479.166748 
	C562.670776,479.537842 563.371643,480.121429 564.685425,481.215302 
	C564.590515,479.253510 564.532715,478.058746 564.458679,476.527252 
	C565.675659,476.215607 566.978088,475.882050 567.189148,475.828003 
	C566.928894,472.708801 566.703369,470.005463 566.751831,467.084869 
	C568.584595,468.374390 569.341736,470.925110 572.151123,470.803192 
	C571.501831,473.184601 571.269897,475.804260 570.085022,477.879944 
	C568.917664,479.924866 566.784851,481.418610 564.546204,483.691833 
	C564.676758,483.705994 563.538452,483.582581 562.202881,483.701111 
	C558.485657,482.831085 561.599304,480.904572 561.707520,479.166748 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M586.979248,468.938507 
	C588.217041,469.654388 589.454895,470.370239 591.470032,471.535675 
	C591.925171,467.410217 592.305481,463.963013 592.778564,459.675293 
	C594.294373,460.600922 595.537354,461.359985 596.780334,462.119019 
	C596.096313,463.129974 595.108459,464.054474 594.791016,465.169769 
	C594.113525,467.550079 593.387695,470.049103 593.508118,472.458099 
	C593.555603,473.406982 595.810120,474.245514 596.948547,475.055328 
	C593.899170,477.519592 593.888916,477.517944 587.346130,473.050690 
	C587.000366,472.998962 587.009094,473.006958 586.994751,472.684509 
	C586.980042,471.220886 586.979675,470.079712 586.979248,468.938507 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M590.719604,497.008240 
	C591.089172,497.437195 591.083984,497.861725 591.093201,498.603333 
	C590.183044,500.074921 589.331055,501.298981 588.311401,502.362213 
	C587.482727,503.226288 586.453003,503.897491 585.512451,504.654236 
	C584.889160,503.583527 584.291809,502.496552 583.635437,501.446533 
	C582.922729,500.306427 582.155090,499.200623 581.726257,498.020325 
	C583.942078,497.854462 585.842957,497.748199 587.930420,497.352661 
	C588.859619,497.043518 589.602234,497.023682 590.719604,497.008240 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M622.652710,467.973999 
	C626.353943,470.685669 620.484009,471.046997 621.669800,473.214569 
	C617.495605,471.575409 616.429993,477.413391 612.366699,476.972168 
	C612.004395,476.559631 612.011047,476.103729 612.232971,475.208984 
	C612.699402,474.587616 612.870789,474.348022 612.962341,474.051361 
	C616.526672,473.405426 618.614014,471.471741 618.565369,467.353485 
	C619.820740,467.565063 621.056396,467.773315 622.652710,467.973999 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M612.970825,473.709625 
	C612.870789,474.348022 612.699402,474.587616 612.247070,474.867462 
	C609.192993,475.205963 606.340149,475.447113 602.630676,475.760681 
	C605.661316,473.040100 607.584290,471.313873 610.412964,468.774536 
	C610.850647,469.495056 611.573669,470.685333 612.648315,471.937805 
	C613.000000,472.000000 613.002441,472.002441 613.002441,472.002441 
	C612.994751,472.457581 612.987000,472.912750 612.970825,473.709625 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M587.124512,468.665771 
	C586.979675,470.079712 586.980042,471.220886 587.000366,472.688599 
	C585.882629,473.039978 584.744995,473.064880 583.310547,473.096252 
	C584.399536,470.317230 583.698975,466.068481 588.894653,465.472137 
	C588.243286,466.642975 587.756531,467.518005 587.124512,468.665771 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M605.000366,484.992981 
	C601.068848,482.876068 601.130920,481.379669 605.482483,478.334015 
	C605.314636,480.395935 605.159485,482.301361 604.994873,484.603333 
	C604.985291,484.999908 605.000793,485.000671 605.000366,484.992981 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M612.998779,471.998779 
	C613.301758,471.492065 613.605957,470.986572 613.910095,470.481018 
	C614.118408,470.664459 614.326721,470.847900 614.535034,471.031311 
	C614.214050,471.336365 613.893005,471.641388 613.287231,471.974426 
	C613.002441,472.002441 613.000000,472.000000 612.998779,471.998779 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M591.647461,457.736176 
	C591.632629,457.995911 591.495239,458.137207 591.357910,458.278503 
	C591.413574,458.058228 591.469299,457.837982 591.647461,457.736176 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M625.000000,388.000610 
	C622.400146,386.832031 616.411377,387.094421 622.536743,382.134155 
	C622.737732,381.971344 621.471619,379.996826 620.928833,378.943451 
	C622.557922,377.141022 624.433228,375.066254 626.322571,372.975922 
	C628.304443,374.821899 626.913391,383.102966 624.449097,384.559967 
	C624.767822,385.688568 625.028870,386.613159 625.146179,387.768250 
	C625.002380,387.998779 625.000000,388.000000 625.000000,388.000610 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M622.003540,479.004608 
	C622.092773,478.967682 622.178406,478.926147 622.127441,478.938904 
	C621.990906,478.993195 622.000000,479.000000 622.003540,479.004608 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M630.445923,467.533508 
	C630.411865,467.476990 630.480042,467.590027 630.445923,467.533508 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M640.346313,459.997620 
	C640.000366,459.560211 640.000671,459.118011 640.001953,458.344391 
	C640.839050,458.310852 641.675049,458.608673 642.511108,458.906494 
	C641.904907,459.268616 641.298706,459.630737 640.346313,459.997620 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M608.262451,337.078369 
	C607.724182,336.490448 607.467773,336.001526 607.105652,335.256317 
	C607.943726,335.041077 608.887512,335.082153 609.831299,335.123230 
	C609.402283,335.807953 608.973328,336.492645 608.262451,337.078369 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M620.004272,348.002441 
	C619.573730,347.881958 619.138916,347.759033 618.704102,347.636108 
	C619.056396,347.621674 619.408630,347.607269 619.884399,347.800415 
	C620.007812,348.007935 620.000000,348.000000 620.004272,348.002441 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M526.011963,321.993896 
	C526.754700,321.994965 527.497498,321.996063 528.616638,322.249695 
	C528.995178,322.672272 528.997253,322.842316 529.000366,323.346283 
	C529.004333,324.125671 529.007263,324.571136 529.005127,325.007690 
	C529.000000,324.998779 529.019897,325.005524 528.848633,325.087219 
	C528.609314,325.273743 528.460266,325.437653 528.486511,325.473602 
	C528.627075,325.666016 528.811340,325.826569 529.248291,326.175232 
	C532.307861,330.848328 535.966675,327.288483 539.223389,327.220978 
	C540.045837,329.061981 540.795654,330.740417 541.545532,332.418823 
	C536.910706,328.482941 533.733521,331.327484 530.490967,334.194519 
	C534.653992,333.083405 537.529724,342.485901 542.147400,335.006744 
	C542.809509,336.546112 543.234558,337.534271 543.747253,338.726196 
	C545.291565,337.733246 546.625793,336.875336 548.232300,336.129028 
	C549.003113,336.825867 549.501709,337.411102 550.000244,337.996338 
	C549.752930,340.079224 549.505615,342.162140 549.199768,344.737396 
	C547.276794,344.665894 543.306580,342.094849 541.727173,347.833710 
	C538.075806,352.446014 534.759949,353.037079 532.208008,349.979401 
	C532.389282,349.798187 532.430298,349.692902 532.471313,349.587646 
	C532.292847,349.699341 532.114380,349.811035 531.734680,349.915009 
	C531.533447,349.907257 531.130981,349.922607 530.765503,349.870911 
	C528.905762,348.587646 527.411499,347.356049 525.534363,345.808838 
	C525.209534,345.838379 524.087280,345.940521 523.122314,345.727539 
	C523.510742,342.914276 523.741943,340.416168 523.967896,337.551727 
	C522.642090,335.790680 521.321472,334.395935 520.350342,332.968628 
	C522.471558,331.598724 524.243286,330.261383 526.147400,328.616760 
	C526.185730,326.535065 526.091675,324.760712 525.999390,322.738190 
	C526.001160,322.490051 526.011963,321.993896 526.011963,321.993896 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M588.070923,376.680237 
	C587.289795,378.161499 586.479614,379.290009 585.725403,380.340515 
	C583.855774,379.200775 582.352783,378.284546 580.315552,377.042633 
	C580.546814,381.451385 580.755981,385.438416 581.020081,390.473846 
	C578.437439,389.487854 576.767395,388.850311 574.814087,388.104584 
	C574.351685,389.190094 573.885925,390.283417 573.420166,391.376740 
	C572.895569,391.198364 572.370911,391.019989 571.846252,390.841644 
	C572.679138,388.383881 573.511963,385.926117 574.491943,383.034241 
	C573.154785,382.260498 571.519165,381.314087 569.601379,380.204437 
	C571.464478,379.513580 573.109558,378.903595 574.754578,378.293610 
	C574.767822,377.894470 574.781006,377.495361 574.794250,377.096222 
	C572.839905,377.096222 570.885498,377.096222 568.931152,377.096222 
	C569.075745,376.737152 569.220337,376.378113 569.364868,376.019043 
	C567.844543,375.007538 566.324158,373.996063 564.401855,372.985901 
	C564.071289,371.534851 564.142578,370.082428 564.255737,367.778137 
	C567.926819,369.148163 571.152527,370.351990 574.554749,371.621674 
	C576.433472,367.203552 578.232666,362.972595 580.411377,358.404968 
	C581.989807,358.477570 583.337158,358.684601 584.311096,359.400055 
	C584.677979,359.669556 584.179626,361.116730 583.930115,361.752380 
	C583.205505,361.030487 582.626404,360.583252 582.578613,360.546326 
	C581.106934,363.583466 579.739929,366.404694 578.272827,369.432526 
	C583.061401,370.590454 581.858887,365.418121 584.284790,363.997864 
	C584.530151,364.000702 585.020752,364.014862 585.008057,364.403015 
	C585.006409,367.384583 581.689636,370.921906 587.274109,371.998962 
	C587.507935,371.997040 587.975586,372.000000 587.975586,372.000000 
	C587.997681,373.442505 588.019775,374.885010 588.070923,376.680237 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M522.965027,346.042664 
	C524.087280,345.940521 525.209534,345.838379 525.534363,345.808838 
	C527.411499,347.356049 528.905762,348.587646 530.517639,350.150818 
	C529.217224,350.884796 527.799194,351.287109 526.381104,351.689453 
	C526.273865,352.122711 526.166565,352.556000 526.059265,352.989288 
	C527.717529,353.668945 529.375793,354.348633 531.057007,355.371338 
	C533.279968,359.608643 534.673218,357.700714 536.300415,355.157318 
	C537.808899,355.479401 538.978088,355.679779 540.147339,355.880188 
	C540.325195,357.182831 540.503052,358.485443 540.409790,359.947784 
	C539.030701,360.531342 537.922607,360.955200 536.913086,361.665649 
	C536.045715,364.783142 535.079712,367.614105 534.517822,369.260864 
	C533.052734,368.262634 531.425903,367.154175 529.420654,366.066833 
	C527.542175,366.267761 526.042114,366.447571 524.268982,366.406616 
	C523.205811,360.118805 528.379944,361.515472 531.637695,360.513885 
	C531.648132,360.089508 531.658569,359.665131 531.669006,359.240753 
	C529.946655,358.763184 528.224243,358.285614 526.501892,357.808044 
	C526.275513,358.303314 526.049072,358.798584 525.822693,359.293854 
	C525.341675,358.021149 524.860657,356.748413 524.379639,355.475677 
	C524.163086,355.912567 523.946533,356.349457 523.729980,356.786346 
	C521.692017,356.295013 519.595520,355.965302 517.636597,355.255768 
	C516.321289,354.779327 515.200867,353.764832 513.996643,352.995544 
	C514.000000,353.000000 514.009033,353.006683 514.069458,352.660278 
	C515.374634,351.253784 516.619446,350.193726 518.530884,348.565979 
	C515.370728,348.302521 513.195312,348.121155 511.014221,347.547119 
	C510.984406,346.359558 510.960266,345.564636 510.937134,344.438965 
	C510.999420,343.318512 511.060669,342.528900 511.126495,341.680389 
	C515.229736,343.192352 519.097351,344.617493 522.965027,346.042664 
M523.984558,351.355865 
	C523.343445,350.793335 522.702332,350.230774 522.061218,349.668243 
	C521.819275,350.523865 521.577332,351.379517 521.335388,352.235138 
	C522.216309,352.168854 523.097290,352.102570 523.984558,351.355865 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M580.029175,343.010986 
	C581.403259,343.317413 582.777283,343.623840 584.487549,344.319153 
	C583.451721,346.222961 582.079773,347.737854 580.462219,349.524017 
	C586.053223,350.171539 581.267151,352.387848 580.979004,354.417908 
	C580.653076,355.129761 580.346375,355.564667 580.039673,355.999573 
	C578.706055,356.883087 577.247437,357.628815 576.076233,358.691437 
	C575.039185,359.632324 574.322144,360.925812 572.494690,361.517487 
	C572.997742,359.606995 573.500854,357.696503 574.277405,355.200562 
	C575.928772,353.601166 577.306763,352.587189 577.997559,352.078857 
	C577.270996,348.701202 576.657532,345.849487 576.044067,342.997803 
	C576.817627,342.680481 577.591187,342.363220 578.684937,342.314575 
	C579.157593,343.102417 579.309937,343.621674 579.462280,344.140900 
	C579.651245,343.764282 579.840210,343.387634 580.029175,343.010986 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M575.683350,343.054382 
	C576.657532,345.849487 577.270996,348.701202 577.997559,352.078857 
	C577.306763,352.587189 575.928772,353.601166 574.280273,354.818420 
	C573.835999,355.018921 573.662170,355.016113 573.232117,354.603882 
	C573.388489,350.763092 572.323181,349.041412 569.035217,350.549622 
	C567.883118,348.812592 566.945801,347.399261 566.008484,345.985931 
	C566.008484,345.985931 566.000000,346.000763 565.993408,345.760193 
	C565.986877,345.519592 565.934326,345.045807 565.934326,345.045807 
	C566.787598,344.953613 567.900574,344.469604 568.451050,344.834351 
	C571.471008,346.835480 573.567505,345.808990 575.683350,343.054382 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M580.998291,354.140900 
	C581.267151,352.387848 586.053223,350.171539 580.462219,349.524017 
	C582.079773,347.737854 583.451721,346.222961 584.838867,344.373383 
	C589.546265,344.125305 591.285095,345.844666 590.901855,350.774597 
	C589.173584,350.291382 588.051270,349.651581 587.509460,349.342773 
	C586.472534,351.282318 585.721680,352.686798 584.573730,354.076019 
	C583.117249,354.087463 582.057739,354.114197 580.998291,354.140900 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M580.979004,354.417908 
	C582.057739,354.114197 583.117249,354.087463 584.584412,354.446533 
	C584.179810,359.804413 588.722900,356.304932 589.988159,357.990601 
	C590.682495,362.598907 591.102905,367.172119 588.127747,371.664978 
	C587.975586,372.000000 587.507935,371.997040 587.269470,371.609619 
	C587.419617,369.660980 587.736267,368.077545 588.211182,366.543030 
	C589.348083,362.869080 587.650452,362.878967 585.020752,364.014862 
	C585.020752,364.014862 584.530151,364.000702 584.281128,363.669678 
	C584.046570,362.901489 584.061096,362.464264 584.075684,362.027069 
	C584.179626,361.116730 584.677979,359.669556 584.311096,359.400055 
	C583.337158,358.684601 581.989807,358.477570 580.444580,358.010986 
	C580.077515,357.519806 580.056824,357.085968 580.037903,356.325806 
	C580.346375,355.564667 580.653076,355.129761 580.979004,354.417908 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M531.034058,355.028320 
	C529.375793,354.348633 527.717529,353.668945 526.059265,352.989288 
	C526.166565,352.556000 526.273865,352.122711 526.381104,351.689453 
	C527.799194,351.287109 529.217224,350.884796 530.883179,350.202515 
	C531.130981,349.922607 531.533447,349.907257 531.765869,349.949860 
	C531.998352,349.992462 532.067749,350.055328 532.067749,350.055328 
	C534.759949,353.037079 538.075806,352.446014 541.718872,348.198730 
	C542.783691,348.958954 543.571899,349.904785 544.081909,350.516754 
	C542.652100,352.182983 541.363159,353.685120 540.110718,355.533691 
	C538.978088,355.679779 537.808899,355.479401 535.890503,355.157288 
	C533.772217,355.033173 532.403137,355.030731 531.034058,355.028320 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M550.368042,337.957184 
	C549.501709,337.411102 549.003113,336.825867 547.982727,335.922974 
	C547.983032,333.270874 547.983032,333.270874 552.149048,335.018951 
	C552.785339,330.521149 553.386841,326.269348 554.388855,322.010010 
	C555.856445,322.006134 556.923462,322.009796 558.230835,322.017944 
	C558.471191,322.022461 558.951721,322.036011 558.951721,322.036011 
	C558.166870,323.860779 557.325073,325.664978 556.651062,327.529816 
	C556.533081,327.856262 557.120117,328.437500 557.692017,329.194855 
	C558.002625,329.488617 557.973328,329.975922 557.973328,329.975922 
	C556.984192,330.900269 555.919800,331.757538 555.022888,332.764038 
	C553.537109,334.431427 552.159119,336.194977 550.368042,337.957184 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M562.928955,316.026825 
	C563.272705,313.761108 563.616516,311.495392 563.960327,309.229706 
	C564.730347,309.160736 565.500427,309.091797 566.270447,309.022858 
	C564.035583,314.138580 566.486572,317.124634 570.720642,319.306519 
	C567.519409,320.792206 565.178833,321.878479 562.494324,322.563690 
	C562.409851,320.117371 562.669434,318.072113 562.928955,316.026825 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M590.241089,357.826477 
	C588.722900,356.304932 584.179810,359.804413 584.981445,354.461792 
	C585.721680,352.686798 586.472534,351.282318 587.509460,349.342773 
	C588.051270,349.651581 589.173584,350.291382 590.636292,351.002319 
	C591.443420,351.687500 591.910217,352.301514 592.694458,352.951141 
	C593.448608,354.161102 593.885315,355.335419 594.227051,356.254303 
	C592.711426,356.825989 591.602722,357.244171 590.241089,357.826477 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M557.981140,330.295776 
	C557.973328,329.975922 558.002625,329.488617 558.015930,329.244873 
	C558.769409,328.998505 559.509521,328.995911 560.777466,329.306396 
	C562.536804,330.080078 563.768372,330.540649 565.088745,331.172119 
	C565.446655,331.223572 565.715820,331.104156 565.990845,330.990204 
	C565.996765,330.995667 565.984436,330.980560 565.984436,330.980560 
	C569.164734,331.985382 569.164734,331.985382 567.368958,336.767883 
	C565.058105,334.105988 562.706177,333.411560 560.103394,336.937256 
	C559.509949,336.987762 559.017517,336.978027 559.017517,336.978027 
	C559.557922,335.661987 560.098328,334.345917 560.502686,333.361084 
	C559.593689,332.368256 558.791321,331.491943 557.981140,330.295776 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M566.004639,346.415039 
	C566.945801,347.399261 567.883118,348.812592 569.035217,350.549622 
	C572.323181,349.041412 573.388489,350.763092 572.971436,354.602600 
	C572.099182,355.944244 571.231262,356.877777 569.219299,359.041901 
	C567.964111,354.284851 566.982483,350.564514 566.004639,346.415039 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M558.670898,337.042847 
	C559.017517,336.978027 559.509949,336.987762 559.756226,336.992676 
	C560.503784,339.593719 561.005066,342.189880 561.631775,345.435577 
	C560.695740,346.976685 555.725708,342.939209 557.047241,349.951019 
	C556.240967,349.203003 555.434692,348.455017 554.628479,347.707001 
	C555.860413,344.173889 557.092346,340.640778 558.670898,337.042847 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M562.698608,316.018372 
	C562.669434,318.072113 562.409851,320.117371 562.127502,322.543671 
	C561.229919,322.693176 560.355164,322.461670 559.216064,322.133057 
	C558.951721,322.036011 558.471191,322.022461 558.202759,321.669861 
	C557.618835,320.207031 557.303345,319.096802 556.987793,317.986572 
	C556.987793,317.986572 556.997742,317.496033 557.301270,317.228088 
	C558.069824,316.639679 558.534912,316.319244 559.000000,315.998779 
	C559.751709,316.001465 560.503418,316.004150 561.631226,316.005249 
	C562.007324,316.003662 562.468262,316.009918 562.698608,316.018372 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M565.000000,331.001221 
	C563.768372,330.540649 562.536804,330.080078 561.147827,329.314148 
	C560.990540,328.026794 560.990540,327.044800 560.990540,326.062836 
	C561.898193,328.546051 571.084229,321.327332 566.016968,330.636017 
	C565.984436,330.980560 565.996765,330.995667 565.744751,330.996124 
	C565.492676,330.996582 565.000000,331.001221 565.000000,331.001221 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M577.168518,338.794067 
	C578.535034,338.238281 579.957092,338.006165 581.379089,337.774048 
	C581.379211,338.496216 581.379395,339.218384 581.379517,339.940552 
	C579.994385,339.666290 578.609192,339.392029 577.168518,338.794067 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M570.822083,309.602783 
	C569.480896,309.571259 568.176880,309.217316 566.872925,308.863373 
	C567.192871,308.259674 567.512817,307.656006 567.832764,307.052338 
	C568.841614,307.795013 569.850403,308.537720 570.822083,309.602783 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M552.766113,366.763977 
	C553.499268,367.700989 553.916199,368.613770 554.333069,369.526550 
	C553.873718,369.592590 553.024170,369.756317 553.012573,369.710266 
	C552.768311,368.734314 552.618286,367.734741 552.766113,366.763977 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M581.950684,333.870056 
	C581.310364,334.406433 580.653137,334.633606 579.995850,334.860779 
	C579.954651,334.193970 579.913452,333.527191 579.872192,332.860382 
	C580.559448,333.093872 581.246643,333.327362 581.950684,333.870056 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M597.000000,340.000244 
	C598.169739,340.167267 599.339417,340.334045 600.509155,340.500824 
	C600.402344,340.947205 600.295471,341.393616 600.188660,341.839996 
	C599.164978,341.455566 598.141357,341.071167 597.058838,340.343079 
	C597.000000,339.999390 597.000000,340.000000 597.000000,340.000244 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M564.469360,341.308960 
	C564.464111,340.548920 564.671509,340.009338 564.878906,339.469757 
	C565.153992,339.605042 565.429199,339.740295 565.704346,339.875580 
	C565.363525,340.426880 565.022766,340.978149 564.469360,341.308960 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M542.592529,362.254822 
	C542.592529,363.064362 542.285583,363.807434 541.978638,364.550507 
	C541.659485,363.947144 541.340393,363.343781 541.027954,362.407990 
	C541.451660,362.113129 541.868591,362.150696 542.592529,362.254822 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M551.701538,290.734863 
	C552.255005,291.024933 552.563904,291.420471 552.872864,291.815979 
	C552.355347,291.907898 551.837830,291.999817 551.320312,292.091705 
	C551.365845,291.674561 551.411438,291.257416 551.701538,290.734863 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M569.360840,328.947144 
	C569.729736,328.648041 570.096436,328.606842 570.463074,328.565674 
	C570.460266,328.826782 570.457458,329.087921 570.454651,329.349060 
	C570.089294,329.301056 569.723877,329.253052 569.360840,328.947144 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M579.914062,342.789093 
	C579.840210,343.387634 579.651245,343.764282 579.462280,344.140900 
	C579.309937,343.621674 579.157593,343.102417 578.999695,342.291168 
	C579.325867,342.098663 579.594116,342.287994 579.914062,342.789093 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M579.493408,329.474243 
	C579.908447,329.400574 580.164673,329.482758 580.420898,329.564941 
	C580.134949,329.844269 579.848938,330.123566 579.562927,330.402863 
	C579.486816,330.145264 579.410706,329.887634 579.493408,329.474243 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M569.988281,324.242310 
	C570.099976,324.411224 570.044922,324.581787 569.989868,324.752350 
	C569.933777,324.582886 569.877686,324.413422 569.988281,324.242310 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M576.243408,363.006683 
	C576.408142,362.896881 576.574463,362.950684 576.740723,363.004486 
	C576.575439,363.059753 576.410217,363.115021 576.243408,363.006683 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M552.536499,364.561829 
	C552.475098,364.600006 552.597961,364.523621 552.536499,364.561829 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M540.241699,361.695709 
	C540.116272,361.688873 539.907471,361.432739 539.884277,361.052429 
	C540.099426,361.100952 540.128906,361.273682 540.241699,361.695709 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M565.776001,345.134155 
	C565.934326,345.045807 565.986877,345.519592 566.001587,345.757477 
	C565.883362,345.737762 565.750488,345.480133 565.776001,345.134155 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M577.985352,313.988525 
	C578.230774,314.329193 578.489197,314.682587 578.747681,315.036011 
	C578.622559,315.125549 578.497498,315.215088 578.372375,315.304626 
	C578.254456,315.023132 578.136536,314.741638 578.002319,314.215179 
	C577.986023,313.970215 577.998352,314.001282 577.985352,313.988525 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M574.019409,565.516418 
	C574.487488,566.464294 574.624512,567.410461 574.761597,568.356628 
	C574.297424,568.377441 573.833252,568.398193 573.369080,568.419006 
	C573.475525,567.450928 573.581970,566.482788 574.019409,565.516418 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M610.648682,504.976562 
	C611.770630,505.343445 612.547485,505.677643 613.663452,506.004089 
	C615.207642,506.819061 616.412903,507.641785 618.140747,508.821289 
	C617.924194,510.274994 617.622498,512.300110 617.169861,514.688232 
	C614.337830,515.051270 611.656738,515.051270 608.293213,515.051270 
	C607.288818,512.920288 605.943542,510.066162 604.483398,506.968140 
	C605.143127,506.340576 606.020874,505.505676 606.949341,504.332336 
	C608.101257,504.310547 609.202454,504.627228 610.648682,504.976562 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M577.069885,515.063171 
	C579.927979,511.295197 581.816711,513.221863 583.627075,515.572632 
	C583.519287,516.107849 583.411499,516.643127 583.303711,517.178406 
	C581.228638,516.607361 579.153503,516.036316 577.069885,515.063171 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M601.999695,504.654572 
	C601.332886,503.806854 600.987366,502.924438 600.641846,502.041992 
	C601.069824,501.936157 601.497742,501.830353 601.925720,501.724609 
	C602.057434,502.689697 602.189209,503.654785 601.999695,504.654572 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M612.996338,519.003662 
	C612.482361,518.700073 611.971985,518.392883 611.461609,518.085693 
	C611.645081,517.874084 611.828552,517.662476 612.011963,517.450806 
	C612.320251,517.774353 612.628540,518.097961 612.972046,518.707153 
	C613.007324,518.992676 613.000000,519.000000 612.996338,519.003662 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M580.731079,489.363708 
	C580.993469,489.378632 581.135559,489.515594 581.277710,489.652527 
	C581.055420,489.596924 580.833069,489.541290 580.731079,489.363708 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M625.529785,522.548828 
	C625.474731,522.582214 625.584839,522.515381 625.529785,522.548828 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M623.997559,520.001770 
	C624.119568,520.146912 624.244080,520.290222 624.368530,520.433594 
	C624.281250,520.382690 624.193909,520.331787 624.055054,520.138000 
	C624.003601,519.995117 624.000000,520.000000 623.997559,520.001770 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M553.988281,322.017517 
	C553.386841,326.269348 552.785339,330.521149 552.149048,335.018951 
	C547.983032,333.270874 547.983032,333.270874 547.710510,335.811401 
	C546.625793,336.875336 545.291565,337.733246 543.747253,338.726196 
	C543.234558,337.534271 542.809509,336.546112 542.147400,335.006744 
	C537.529724,342.485901 534.653992,333.083405 530.490967,334.194519 
	C533.733521,331.327484 536.910706,328.482941 541.545532,332.418823 
	C540.795654,330.740417 540.045837,329.061981 539.223389,327.220978 
	C535.966675,327.288483 532.307861,330.848328 529.261719,325.927246 
	C529.007263,325.502167 529.019897,325.005524 529.019897,325.005524 
	C529.019897,325.005524 529.000000,324.998779 529.232910,324.858826 
	C529.310303,324.150055 529.154846,323.581207 528.999390,323.012390 
	C528.997253,322.842316 528.995178,322.672272 528.988037,322.247070 
	C529.019592,321.344513 529.056213,320.697113 529.103943,319.852844 
	C531.252441,321.844910 533.264221,323.710144 535.147095,325.455902 
	C536.817993,322.635651 537.953064,320.719940 539.516724,318.080750 
	C539.075745,316.398132 538.379700,313.742493 537.811340,311.574066 
	C539.814575,308.613800 541.737061,305.772858 543.659546,302.931946 
	C543.907776,303.049164 544.156006,303.166412 544.404236,303.283661 
	C544.073242,305.349548 543.742188,307.415466 543.577759,310.008301 
	C543.495300,311.360413 543.246216,312.185608 542.721802,313.231934 
	C540.851624,318.387634 541.471741,321.378937 543.997498,322.356140 
	C544.158691,320.441345 544.327637,318.433380 544.496643,316.425415 
	C545.738098,317.832001 546.979492,319.238556 548.945190,321.465759 
	C549.798889,321.557953 551.891296,321.783966 553.990295,322.005920 
	C553.996887,322.001923 553.988281,322.017517 553.988281,322.017517 
M548.791992,329.378937 
	C548.787964,329.778503 548.783997,330.178070 548.780029,330.577667 
	C548.946045,330.579071 549.256348,330.591583 549.257263,330.580292 
	C549.287720,330.182770 549.289307,329.782990 548.791992,329.378937 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M526.159058,321.679962 
	C526.011963,321.993896 526.001160,322.490051 525.626160,322.749359 
	C524.204529,323.798615 523.157959,324.588562 522.111389,325.378540 
	C523.412598,326.560394 524.713745,327.742218 526.014954,328.924042 
	C524.243286,330.261383 522.471558,331.598724 520.347717,332.965881 
	C519.995544,332.995667 519.987000,332.988098 519.940430,332.671356 
	C519.262207,331.915680 518.630615,331.476776 517.999329,331.018921 
	C517.999695,331.000031 518.000000,330.961548 518.000122,330.629456 
	C517.998291,329.854187 517.996399,329.411011 517.994507,328.967834 
	C518.794373,325.972137 519.594238,322.976440 520.740112,318.684784 
	C517.850586,320.272278 516.258057,321.147186 514.333496,322.011719 
	C514.001526,322.001343 513.986938,321.990631 513.986938,321.990601 
	C514.851746,320.302277 515.864075,318.669830 516.532043,316.906891 
	C517.017395,315.626068 517.047180,314.172668 517.212830,313.193451 
	C519.855591,311.833405 523.279602,310.349121 526.312500,308.288971 
	C526.962097,307.847717 526.194641,305.320160 526.040039,303.374390 
	C530.396545,303.379974 529.213623,307.380096 529.342407,309.531494 
	C529.428833,310.974457 526.389832,312.604553 524.482544,314.402344 
	C526.495056,316.059937 529.986328,317.850800 526.159058,321.679962 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M542.997192,313.010803 
	C543.246216,312.185608 543.495300,311.360413 543.839661,310.219757 
	C544.237854,309.378357 544.746399,308.367584 544.813965,308.396271 
	C547.799744,309.662811 550.746582,311.021057 553.452820,312.693146 
	C552.473022,314.347443 551.738525,315.676880 550.653076,317.008636 
	C549.444824,315.865051 548.587585,314.719177 547.657776,313.476318 
	C546.383301,313.349030 544.690247,313.179901 542.997192,313.010803 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M551.004028,317.006317 
	C551.738525,315.676880 552.473022,314.347443 553.587280,312.995728 
	C554.741455,314.611053 555.515869,316.248688 556.639038,317.936462 
	C557.303345,319.096802 557.618835,320.207031 557.962402,321.665344 
	C556.923462,322.009796 555.856445,322.006134 554.388794,322.010010 
	C553.988281,322.017517 553.996887,322.001923 553.968140,321.666046 
	C552.960876,319.888885 551.982483,318.447601 551.004028,317.006317 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M558.997253,315.675903 
	C558.534912,316.319244 558.069824,316.639679 557.308533,316.982910 
	C557.453308,315.900177 557.894287,314.794708 558.335327,313.689270 
	C558.555054,314.243866 558.774780,314.798462 558.997253,315.675903 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M485.780823,332.654846 
	C488.231689,331.215240 490.763885,330.144928 493.657898,329.032410 
	C494.196503,329.879211 494.373352,330.768188 494.743652,332.629456 
	C496.106323,330.826935 496.913025,329.759857 498.958069,327.054779 
	C499.671173,328.556946 500.144745,329.995911 500.986633,331.171051 
	C501.537964,331.940643 502.622345,332.328339 503.468018,332.887085 
	C503.968842,331.987091 504.516937,331.109650 504.960205,330.182129 
	C505.759125,328.510315 506.495270,326.808502 507.628845,325.062378 
	C508.000000,325.005493 507.997253,325.497620 507.860168,326.050659 
	C507.435944,328.693512 507.148743,330.783264 506.750610,333.680389 
	C509.047852,332.529327 510.653259,331.799927 512.185547,330.940155 
	C514.697632,329.530579 515.013123,331.003479 515.105530,333.264465 
	C514.260254,334.323761 513.395203,335.253357 512.341492,335.849548 
	C511.327484,336.423218 510.118408,336.652130 508.995056,337.032532 
	C508.181580,338.177948 507.368103,339.323334 506.596832,340.409271 
	C504.099121,339.192749 501.491516,337.922668 498.594269,336.511536 
	C498.215179,337.595978 497.760254,338.897339 496.854065,341.489563 
	C496.255035,338.859894 495.932159,337.442535 495.607819,336.018768 
	C490.270935,338.214447 487.477661,337.348511 485.780823,332.654846 
M493.184540,333.765045 
	C493.127686,333.601746 493.070801,333.438416 493.013947,333.275116 
	C492.960968,333.437775 492.908020,333.600433 493.184540,333.765045 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M515.003235,332.988922 
	C515.013123,331.003479 514.697632,329.530579 512.185547,330.940155 
	C510.653259,331.799927 509.047852,332.529327 506.750610,333.680389 
	C507.148743,330.783264 507.435944,328.693512 507.859985,326.296753 
	C511.097839,326.963593 514.198792,327.937378 517.647156,328.939514 
	C517.996399,329.411011 517.998291,329.854187 517.729797,330.769348 
	C516.640686,331.823853 515.821960,332.406403 515.003235,332.988922 
M509.264099,329.816925 
	C509.373352,329.971405 509.482635,330.125885 509.591888,330.280365 
	C509.570679,330.064484 509.549500,329.848572 509.264099,329.816925 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M508.375793,324.943726 
	C508.001160,324.178162 507.992249,323.442963 507.976776,322.337738 
	C509.233887,321.967743 510.497528,321.967743 511.761200,321.967743 
	C511.922241,322.247650 512.083252,322.527527 512.244324,322.807434 
	C511.076721,323.529633 509.909119,324.251831 508.375793,324.943726 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M504.998779,308.998779 
	C505.641602,308.210083 506.284424,307.421356 506.959686,306.320007 
	C506.992126,306.007416 507.000000,306.000000 507.003693,305.996582 
	C507.611847,306.543793 508.216309,307.094452 508.820801,307.645081 
	C507.802063,308.096588 506.783356,308.548065 505.382324,308.999786 
	C505.000000,309.000000 504.998779,308.998779 504.998779,308.998779 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M502.041138,310.382019 
	C502.286896,310.783997 502.285278,311.184418 502.253906,311.582489 
	C502.252930,311.594666 501.942261,311.582428 501.775970,311.581573 
	C501.780457,311.181091 501.784912,310.780579 502.041138,310.382019 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M514.008545,322.008423 
	C513.887207,322.448029 513.758911,322.880524 513.630615,323.313049 
	C513.611816,322.955780 513.593018,322.598480 513.780518,322.115906 
	C513.986938,321.990631 514.001526,322.001343 514.008545,322.008423 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M505.000610,309.000610 
	C504.907806,309.036957 504.814423,309.072693 504.859894,309.053589 
	C504.998779,308.998779 505.000000,309.000000 505.000610,309.000610 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M390.161041,429.717224 
	C389.344757,428.933075 388.671539,427.844788 387.999146,426.378265 
	C391.210846,424.819580 393.209869,423.446381 390.138916,419.830322 
	C389.043884,418.540924 389.057465,416.309998 388.282501,414.254425 
	C388.000000,414.000000 387.996338,413.996338 387.996338,413.996338 
	C388.543365,411.095001 388.027161,407.259979 393.051483,408.176605 
	C392.835571,408.972443 392.644775,409.675903 392.291901,410.976685 
	C394.315399,410.197571 395.806396,409.623535 397.648499,409.024536 
	C397.999603,408.999634 398.006805,409.004456 398.006805,409.004456 
	C398.129150,410.570038 398.251465,412.135620 398.672241,414.225769 
	C398.970886,415.496674 398.971069,416.243073 398.986206,416.995117 
	C399.001129,417.000793 398.989105,416.971008 398.627380,416.970428 
	C397.018921,416.501343 395.772156,416.032837 394.217377,415.448608 
	C394.137177,417.169830 394.071167,418.586609 393.793793,420.122253 
	C393.608429,420.599487 393.634460,420.957886 393.660461,421.316315 
	C393.771606,420.875519 393.882751,420.434692 394.373444,420.004028 
	C399.549805,419.025482 396.030060,423.717621 398.317505,424.944305 
	C399.064178,424.326233 399.887756,423.644501 400.763397,422.919678 
	C401.617859,427.054840 403.086273,431.216583 397.930115,433.874359 
	C397.261108,434.219208 397.294098,435.926025 397.001892,437.001953 
	C396.093781,434.704315 395.185699,432.406677 394.318237,430.211792 
	C394.108307,431.544220 393.868927,433.063507 393.629578,434.582794 
	C393.134857,434.952667 392.640137,435.322510 392.145416,435.692383 
	C391.948334,434.919373 391.510529,434.109955 391.620331,433.383331 
	C391.735413,432.622131 392.615509,431.974823 392.724609,431.215057 
	C392.851532,430.331329 392.504395,429.379486 392.363037,428.457214 
	C391.676727,428.775848 390.990417,429.094482 390.161041,429.717224 
M390.644836,417.277222 
	C391.102295,417.191467 391.559784,417.105713 391.845856,416.658508 
	C391.550110,416.771545 391.254364,416.884613 390.644836,417.277222 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M387.954468,396.330872 
	C387.826324,394.073914 387.717987,392.196136 387.301636,390.161316 
	C389.087555,388.359283 391.181488,386.714294 393.637115,385.023895 
	C394.649048,390.249359 395.299316,395.520203 395.967285,400.934662 
	C395.242676,401.140533 395.242676,401.140533 391.814117,392.079559 
	C392.280640,396.677521 392.747192,401.275452 393.262451,406.353668 
	C390.683807,403.912262 388.616913,401.955383 386.738251,400.176697 
	C387.006744,399.423676 387.490540,398.066864 387.954468,396.330872 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M394.005157,420.003418 
	C394.071167,418.586609 394.137177,417.169830 394.217377,415.448608 
	C395.772156,416.032837 397.018921,416.501343 398.662231,417.264343 
	C399.468506,417.870300 399.878174,418.181793 400.287842,418.493286 
	C400.377899,418.227783 400.586731,417.755615 400.539734,417.728577 
	C400.040283,417.441528 399.500153,417.225220 398.971252,416.989441 
	C398.971069,416.243073 398.970886,415.496674 398.959381,414.377319 
	C400.340912,414.324585 401.733765,414.644836 403.540833,415.364258 
	C403.967163,416.850220 403.979218,417.937012 403.724182,419.246521 
	C403.303406,423.436890 403.128632,427.403870 403.004364,431.372437 
	C402.935455,433.571838 402.950958,435.773895 402.623352,437.937561 
	C401.222443,437.571228 400.126617,437.242126 398.691406,436.915955 
	C397.900635,436.945282 397.449310,436.971649 396.997955,436.998047 
	C396.997955,436.998047 397.000000,437.000000 397.000946,437.000977 
	C397.294098,435.926025 397.261108,434.219208 397.930115,433.874359 
	C403.086273,431.216583 401.617859,427.054840 400.763397,422.919678 
	C399.887756,423.644501 399.064178,424.326233 398.317505,424.944305 
	C396.030060,423.717621 399.549805,419.025482 394.376434,420.007019 
	C393.999878,419.999939 394.005157,420.003418 394.005157,420.003418 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M424.607849,392.965576 
	C423.980713,394.800171 424.033630,395.697937 426.542786,396.185516 
	C428.789703,396.622101 430.833405,398.104462 432.664917,399.527435 
	C429.912354,400.283630 427.461609,400.637878 424.824738,400.764221 
	C424.120758,401.092072 423.602966,401.647797 422.748901,402.277557 
	C421.725952,402.333557 421.039215,402.315521 420.024658,402.109192 
	C418.116028,400.608734 416.535248,399.296539 415.087372,397.712952 
	C415.815765,396.639984 416.411224,395.838379 417.006714,395.036774 
	C417.906494,394.575012 418.806305,394.113251 420.195312,393.393433 
	C421.116608,393.085693 421.548706,393.036011 421.980774,392.986328 
	C422.731293,392.972229 423.481812,392.958130 424.607849,392.965576 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M428.038452,369.630188 
	C427.925781,370.568695 427.789612,371.109344 427.438721,371.826233 
	C424.983948,370.896179 422.743835,369.789886 420.503754,368.683624 
	C421.065308,368.350677 421.626892,368.017700 422.188446,367.684753 
	C422.759003,365.389069 423.329559,363.093384 423.927612,360.445923 
	C424.795013,359.853027 425.634918,359.611938 425.997894,359.507751 
	C426.718933,362.984161 427.366943,366.108246 428.038452,369.630188 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M411.130005,398.102295 
	C408.593262,397.569855 406.056458,397.037354 403.513519,396.503571 
	C404.776337,392.579376 406.910828,392.380402 409.740417,395.197723 
	C411.760681,394.825714 413.312988,394.539886 414.647034,394.754761 
	C413.329193,396.204437 412.229614,397.153381 411.130005,398.102295 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M421.993225,392.610840 
	C421.548706,393.036011 421.116608,393.085693 420.358459,393.138245 
	C418.826538,391.614532 417.620636,390.087952 416.471832,388.633575 
	C418.304626,386.577637 420.293518,384.346649 422.638062,382.059052 
	C422.664429,385.413452 422.335052,388.824402 421.993225,392.610840 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M405.659851,409.005585 
	C406.535339,406.764252 407.410828,404.522888 408.625031,402.123169 
	C408.963745,401.964813 409.061859,402.058990 409.047058,402.434448 
	C409.642426,404.635101 410.252533,406.460297 410.768280,408.003082 
	C409.015656,408.375153 407.556030,408.685028 405.987427,409.003967 
	C405.878418,409.012970 405.659821,409.005585 405.659851,409.005585 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M410.971863,398.380829 
	C412.229614,397.153381 413.329193,396.204437 414.700012,395.098724 
	C415.423492,394.960663 415.875793,394.979370 416.667358,395.017395 
	C416.411224,395.838379 415.815765,396.639984 414.796509,397.864502 
	C412.602448,399.544586 410.832153,400.801788 409.061859,402.058990 
	C409.061859,402.058990 408.963745,401.964813 408.913422,401.916748 
	C409.513306,400.798920 410.163513,399.729126 410.971863,398.380829 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M403.991272,419.023804 
	C403.979218,417.937012 403.967163,416.850220 403.901428,415.357666 
	C403.678223,413.945465 403.508636,412.939026 403.632080,411.534210 
	C404.611084,411.075989 405.297119,411.016205 405.992981,411.412750 
	C405.987122,414.606140 405.971405,417.343201 405.665100,420.028381 
	C404.913452,419.658966 404.452362,419.341400 403.991272,419.023804 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M388.001831,414.001831 
	C387.844482,414.867279 387.685303,415.730927 387.526123,416.594543 
	C387.160248,416.466278 386.794373,416.337982 386.428497,416.209686 
	C386.728088,415.501068 387.027679,414.792419 387.661804,414.040039 
	C387.996338,413.996338 388.000000,414.000000 388.001831,414.001831 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M405.983154,410.956390 
	C405.297119,411.016205 404.611084,411.075989 403.596802,411.227875 
	C403.842407,410.548187 404.416260,409.776459 405.324951,409.005127 
	C405.659821,409.005585 405.878418,409.012970 405.967957,409.331207 
	C406.032684,410.085083 406.007935,410.520752 405.983154,410.956390 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M397.995514,408.995605 
	C397.794159,407.619110 397.596893,406.246613 397.399628,404.874115 
	C397.705505,404.826447 398.011383,404.778778 398.317230,404.731079 
	C398.388672,406.012909 398.460083,407.294708 398.269165,408.790497 
	C398.006805,409.004456 397.999603,408.999634 397.995514,408.995605 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M376.400818,402.729340 
	C376.569214,402.863464 376.593628,403.095612 376.618042,403.327789 
	C376.497650,403.160980 376.377258,402.994202 376.400818,402.729340 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M359.654785,424.925690 
	C359.928558,425.055267 359.977356,425.531342 359.993408,425.770142 
	C359.156647,425.697998 358.303833,425.387024 357.451019,425.076050 
	C358.094330,424.982758 358.737671,424.889435 359.654785,424.925690 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M375.549255,463.450256 
	C375.583099,463.505096 375.515381,463.395447 375.549255,463.450256 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M396.984894,437.353394 
	C397.449310,436.971649 397.900635,436.945282 398.688171,437.291321 
	C399.908417,439.524994 400.792450,441.386230 401.654358,443.200806 
	C400.440552,445.130554 399.225098,447.062958 398.009644,448.995392 
	C398.009644,448.995392 397.516296,448.997772 396.992126,448.971680 
	C396.099152,448.936401 395.730347,448.927185 395.361511,448.917969 
	C395.191040,448.192413 395.020599,447.466888 394.919128,446.370728 
	C395.944092,445.579773 396.900085,445.159393 398.577911,444.421631 
	C396.716492,443.284027 395.560028,442.577271 394.756470,442.086182 
	C395.565277,440.488007 396.268555,439.098389 396.984894,437.353394 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M410.550720,366.465363 
	C410.582733,366.520233 410.518738,366.410492 410.550720,366.465363 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M433.274597,353.001648 
	C433.119934,352.191498 433.119934,351.592590 433.119934,350.993652 
	C433.313995,351.001465 433.508057,351.009277 433.702148,351.017090 
	C433.611176,351.749023 433.520203,352.480957 433.274597,353.001648 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M476.995117,353.996552 
	C473.369781,353.922089 469.744476,353.847626 466.021240,353.381226 
	C466.904083,352.270416 468.813416,351.060150 468.730774,350.904053 
	C466.252350,346.220490 470.466431,343.252594 471.771057,339.277679 
	C472.363159,339.089508 472.619293,339.259247 472.898071,340.075500 
	C473.209229,341.166656 473.421051,341.727051 473.632874,342.287415 
	C474.108032,341.516388 474.583191,340.745361 475.058350,339.974365 
	C475.650757,340.245880 476.778839,340.698822 476.757324,340.762390 
	C476.188782,342.440948 475.202454,344.034576 474.947632,345.741302 
	C474.797211,346.748535 475.773163,347.923981 476.621887,349.452332 
	C476.999115,351.252808 476.997101,352.624664 476.995117,353.996552 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M472.980225,314.015137 
	C472.846527,313.871552 472.729858,313.713501 472.613190,313.555481 
	C472.707581,313.600647 472.801971,313.645813 472.961304,313.828918 
	C473.026276,313.966797 472.997284,314.000671 472.980225,314.015137 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M440.284729,340.020935 
	C440.099304,339.208496 440.099304,338.595245 440.099304,337.981964 
	C440.325928,337.993256 440.552521,338.004547 440.779144,338.015808 
	C440.676147,338.750580 440.573151,339.485352 440.284729,340.020935 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M511.019836,347.939789 
	C513.195312,348.121155 515.370728,348.302521 518.530884,348.565979 
	C516.619446,350.193726 515.374634,351.253784 513.937012,352.716125 
	C513.827209,353.075989 513.910217,353.033508 513.993286,352.991058 
	C515.200867,353.764832 516.321289,354.779327 517.636597,355.255768 
	C519.595520,355.965302 521.692017,356.295013 523.729980,356.786346 
	C523.946533,356.349457 524.163086,355.912567 524.379639,355.475677 
	C524.860657,356.748413 525.341675,358.021149 525.822693,359.293854 
	C526.049072,358.798584 526.275513,358.303314 526.501892,357.808044 
	C528.224243,358.285614 529.946655,358.763184 531.669006,359.240753 
	C531.658569,359.665131 531.648132,360.089508 531.637695,360.513885 
	C528.379944,361.515472 523.205811,360.118805 524.000610,366.599365 
	C521.306458,366.352417 518.607483,365.691864 515.541809,365.046234 
	C514.785461,361.980194 514.395813,358.899231 513.971863,355.546478 
	C511.958435,356.098755 510.342590,356.541962 508.345215,356.978271 
	C507.741821,353.219727 507.519989,349.468140 507.837219,345.366577 
	C508.589081,345.015472 508.801910,345.014252 509.013519,345.344574 
	C509.681488,346.430664 510.350647,347.185211 511.019836,347.939789 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M504.804932,355.701874 
	C504.634552,357.090759 504.260254,358.227966 503.812866,359.742859 
	C498.670319,358.948273 493.600861,357.775970 488.272003,355.957520 
	C489.334656,353.552032 490.656738,351.792725 491.978760,350.033386 
	C493.366394,349.342133 494.754059,348.650879 496.672913,348.198059 
	C497.812592,348.993195 498.421051,349.549927 499.029541,350.106659 
	C499.029541,350.106659 499.046967,350.504669 498.692322,350.734375 
	C497.131622,351.720367 495.925629,352.476685 494.719635,353.233002 
	C494.896698,353.613708 495.073761,353.994415 495.250793,354.375153 
	C496.887177,353.893372 498.523529,353.411591 500.159882,352.929810 
	C501.640259,353.769958 503.120667,354.610077 504.804932,355.701874 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M509.014709,345.013062 
	C508.801910,345.014252 508.589081,345.015472 508.054260,345.040710 
	C508.412689,343.573090 509.093170,342.081482 510.392517,340.286011 
	C514.063660,340.176544 517.115173,340.461029 520.168945,340.488464 
	C520.770264,340.493835 521.380432,339.507935 522.151733,338.731506 
	C522.868958,338.295227 523.421082,338.106628 523.973145,337.918030 
	C523.741943,340.416168 523.510742,342.914276 523.122314,345.727539 
	C519.097351,344.617493 515.229736,343.192352 511.126495,341.680389 
	C511.060669,342.528900 510.999420,343.318512 510.618347,344.489868 
	C509.870605,344.918732 509.442657,344.965912 509.014709,345.013062 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M491.622375,349.969543 
	C490.656738,351.792725 489.334656,353.552032 488.005066,355.683929 
	C483.072998,356.200653 486.074738,352.832855 485.798492,351.002686 
	C485.162445,348.174072 481.294403,354.095459 482.056519,349.147583 
	C483.109283,347.994598 483.583984,347.026611 484.058655,346.058594 
	C485.584717,345.941803 487.110809,345.824982 488.461853,345.721588 
	C489.179840,346.792908 490.222931,348.349274 491.622375,349.969543 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M521.986511,338.979187 
	C521.380432,339.507935 520.770264,340.493835 520.168945,340.488464 
	C517.115173,340.461029 514.063660,340.176544 510.575745,339.978241 
	C509.923004,339.135162 509.705902,338.295990 509.241943,337.244690 
	C510.118408,336.652130 511.327484,336.423218 512.341492,335.849548 
	C513.395203,335.253357 514.260254,334.323761 515.105530,333.264465 
	C515.821960,332.406403 516.640686,331.823853 517.729736,331.101440 
	C518.000000,330.961548 517.999695,331.000031 518.000122,331.346924 
	C518.001160,332.132263 518.001770,332.570770 517.907043,333.257874 
	C517.884521,334.326355 517.957397,335.146271 517.853027,336.065552 
	C517.823547,336.431366 517.971375,336.697754 518.372192,337.182434 
	C519.745605,337.926819 520.866089,338.453003 521.986511,338.979187 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M477.315369,353.833038 
	C476.997101,352.624664 476.999115,351.252808 476.996277,349.471985 
	C478.035736,347.397644 476.587067,343.842346 480.756836,344.990540 
	C480.987091,346.095825 481.000519,346.592590 481.013977,347.089325 
	C479.887878,349.282745 478.761780,351.476135 477.315369,353.833038 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M499.352417,349.935120 
	C498.421051,349.549927 497.812592,348.993195 497.015320,348.180664 
	C498.656799,347.105835 500.487061,346.286774 502.317322,345.467743 
	C502.636017,345.837372 502.954712,346.207001 503.273407,346.576660 
	C502.074036,347.638947 500.874695,348.701233 499.352417,349.935120 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M500.099365,352.636597 
	C498.523529,353.411591 496.887177,353.893372 495.250793,354.375153 
	C495.073761,353.994415 494.896698,353.613708 494.719635,353.233002 
	C495.925629,352.476685 497.131622,351.720367 498.698547,350.933441 
	C499.385925,351.383026 499.712372,351.863220 500.099365,352.636597 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M483.816162,346.025024 
	C483.583984,347.026611 483.109283,347.994598 482.296936,348.951599 
	C481.960144,348.534637 481.960968,348.128662 482.192993,347.277008 
	C482.644653,346.538269 482.865143,346.245239 483.085663,345.952240 
	C483.085663,345.952240 483.573669,345.991455 483.816162,346.025024 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M474.715637,339.979553 
	C474.583191,340.745361 474.108032,341.516388 473.632874,342.287415 
	C473.421051,341.727051 473.209229,341.166656 473.000610,340.289093 
	C473.460175,339.976196 473.916565,339.980499 474.715637,339.979553 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M482.898621,345.713409 
	C482.865143,346.245239 482.644653,346.538269 482.183350,346.972107 
	C481.942535,347.112885 481.478973,347.068420 481.246460,347.078857 
	C481.000519,346.592590 480.987091,346.095825 480.998840,345.225586 
	C481.586548,345.059601 482.149048,345.267090 482.898621,345.713409 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M428.467224,295.449280 
	C428.524170,295.415283 428.410248,295.483276 428.467224,295.449280 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M441.932129,436.034729 
	C442.166351,436.307770 442.400604,436.580841 442.895569,436.946594 
	C439.486298,448.436646 432.171997,457.132385 422.478973,464.621185 
	C422.076538,463.487061 421.699615,462.424835 421.102936,460.743286 
	C420.229889,462.034912 419.690186,462.833344 419.150513,463.631744 
	C418.826508,463.501404 418.502533,463.371063 418.178558,463.240692 
	C418.581390,462.041779 418.727234,459.989685 419.427643,459.778687 
	C425.118835,458.064392 423.047729,455.118866 421.202393,451.617828 
	C425.348419,452.410095 426.213196,450.130554 425.902100,447.112000 
	C425.655914,444.723389 424.845734,442.392914 424.286041,440.036621 
	C423.988617,440.161713 423.691223,440.286835 423.393829,440.411957 
	C422.908447,439.629669 422.423035,438.847382 422.297668,438.005371 
	C425.442169,437.623688 428.226654,437.301758 430.976685,437.316193 
	C431.016907,438.774719 431.091583,439.896851 431.166290,441.018982 
	C432.137756,440.823425 433.107574,440.484314 434.081146,440.473053 
	C435.035492,440.461975 435.993683,440.784515 436.811462,441.029510 
	C436.794708,441.081726 436.916687,441.065094 437.305939,440.805908 
	C438.185303,438.244476 438.383606,435.527313 441.932129,436.034729 
M435.487915,443.588104 
	C435.487915,443.588104 435.417786,443.471588 435.487915,443.588104 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M421.937653,438.065125 
	C422.423035,438.847382 422.908447,439.629669 423.393829,440.411957 
	C423.691223,440.286835 423.988617,440.161713 424.286041,440.036621 
	C424.845734,442.392914 425.655914,444.723389 425.902100,447.112000 
	C426.213196,450.130554 425.348419,452.410095 421.138458,451.258484 
	C420.778503,451.341888 420.410309,451.100891 420.259857,450.610260 
	C418.066589,449.692352 416.023804,449.265106 413.759033,448.584106 
	C413.357422,445.924988 413.272736,443.507874 412.975159,441.117218 
	C412.565796,437.828705 414.090973,436.626495 417.516541,436.929840 
	C419.220062,437.340515 420.578857,437.702820 421.937653,438.065125 
M419.519958,441.389374 
	C419.813416,441.116730 420.106873,440.844086 420.400360,440.571472 
	C420.294678,440.468811 420.108185,440.267334 420.095245,440.277954 
	C419.800873,440.518799 419.525513,440.782806 419.519958,441.389374 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M413.981018,448.837830 
	C416.023804,449.265106 418.066589,449.692352 420.103241,450.455688 
	C419.205780,452.350281 418.319427,453.911621 417.422241,455.466705 
	C414.864807,459.899536 412.302979,464.329834 409.460114,468.931213 
	C408.411011,469.084900 407.644684,469.068481 406.442535,468.762268 
	C405.738068,467.966949 405.469360,467.461426 405.200684,466.955872 
	C405.228699,466.591949 405.256683,466.228058 405.644531,465.501984 
	C407.040619,463.813293 408.387756,462.625397 409.053345,461.133484 
	C410.850952,457.104095 412.358429,452.945282 413.981018,448.837830 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M449.681396,439.157257 
	C449.867096,439.135925 450.153503,439.481537 450.028442,439.665924 
	C449.767456,439.626404 449.631592,439.402496 449.681396,439.157257 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M436.950195,440.960632 
	C435.993683,440.784515 435.035492,440.461975 434.081146,440.473053 
	C433.107574,440.484314 432.137756,440.823425 431.166290,441.018982 
	C431.091583,439.896851 431.016907,438.774719 431.256195,437.104797 
	C432.672760,434.682495 433.775360,432.807983 434.911621,430.583984 
	C435.169342,429.005402 435.393402,427.776245 435.637451,426.437378 
	C431.177490,426.947998 431.916779,423.278534 431.288147,421.070251 
	C433.427216,421.383698 435.181458,421.683167 436.935730,421.982666 
	C439.868591,421.836487 442.231720,422.298706 442.045349,426.121429 
	C442.003693,426.976440 442.373260,428.226654 442.998596,428.677582 
	C446.744232,431.378174 445.162598,433.435760 442.159180,435.745544 
	C438.383606,435.527313 438.185303,438.244476 437.285309,440.782471 
	C436.997375,441.001556 436.950195,440.960663 436.950195,440.960632 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M436.952637,421.656799 
	C435.181458,421.683167 433.427216,421.383698 431.001526,420.934631 
	C429.544067,422.179657 428.758026,423.574219 427.599060,425.015015 
	C426.477234,425.021637 425.728363,424.982025 424.730652,424.682739 
	C422.727356,423.937531 420.972931,423.452026 418.279144,422.706543 
	C421.012115,420.535187 424.472717,418.936157 424.353333,417.676453 
	C423.897369,412.865326 426.548248,412.862427 430.137482,413.116089 
	C431.682404,412.834900 432.874542,412.427490 434.295898,412.023560 
	C434.525146,412.027039 434.983643,412.022461 435.199280,412.133392 
	C435.691864,412.434967 435.882843,412.690002 435.942078,413.360779 
	C435.218872,415.481750 434.541473,417.251404 434.211365,418.113678 
	C435.670288,419.101532 436.353790,419.564301 437.037231,420.027069 
	C437.014679,420.461700 436.992096,420.896301 436.952637,421.656799 
M432.625916,415.495514 
	C432.625916,415.495514 432.455902,415.391968 432.455902,415.391968 
	C432.455902,415.391968 432.475922,415.596405 432.625916,415.495514 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M442.993683,400.024353 
	C444.770172,400.371216 446.546661,400.718079 448.697113,401.203705 
	C448.518738,405.151550 447.966400,408.960602 447.556396,411.787994 
	C445.823456,412.808472 444.446045,413.619598 442.656494,414.753754 
	C440.838867,414.383575 439.433350,413.690369 438.335358,412.838684 
	C440.554565,408.620209 445.409210,405.571960 442.993683,400.024353 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M438.027863,412.997162 
	C439.433350,413.690369 440.838867,414.383575 442.613281,415.111206 
	C443.198151,416.094543 443.414154,417.043488 443.762146,418.572510 
	C441.782593,419.049561 439.799255,419.527527 437.426575,420.016296 
	C436.353790,419.564301 435.670288,419.101532 434.211365,418.113678 
	C434.541473,417.251404 435.218872,415.481750 436.282043,413.355225 
	C437.121155,412.997925 437.574493,412.997528 438.027863,412.997162 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M448.230469,417.573486 
	C447.990173,418.265167 447.832245,418.686646 447.674316,419.108093 
	C447.420227,419.020264 447.166107,418.932404 446.912018,418.844543 
	C447.303131,418.055817 447.694275,417.267090 448.085388,416.478333 
	C448.177307,416.595001 448.314728,416.699829 448.348999,416.831482 
	C448.386536,416.975830 448.328949,417.144989 448.230469,417.573486 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M438.335358,412.838684 
	C437.574493,412.997528 437.121155,412.997925 436.327820,413.003906 
	C435.882843,412.690002 435.691864,412.434967 435.204895,411.804382 
	C435.980865,410.255432 436.966919,409.146393 438.374847,407.562866 
	C435.395447,407.733490 433.209045,407.858673 431.034973,407.648773 
	C432.375641,406.125031 433.703949,404.936401 435.746002,403.109100 
	C434.599243,402.801697 433.189423,402.026550 431.893860,402.185181 
	C430.524719,402.352783 429.264526,403.410004 427.691803,403.926025 
	C426.607422,403.215149 425.787933,402.656891 424.981659,401.822083 
	C425.000183,401.361084 425.005554,401.176605 425.010895,400.992096 
	C427.461609,400.637878 429.912354,400.283630 432.787537,399.857422 
	C434.222382,399.834290 435.232758,399.883148 436.809875,400.233154 
	C438.257935,400.309723 439.139252,400.085114 440.020569,399.860535 
	C440.764801,399.888367 441.509033,399.916199 442.623474,399.984192 
	C445.409210,405.571960 440.554565,408.620209 438.335358,412.838684 
z"/>
<path fill="#7B5E13" opacity="1.000000" stroke="none" 
	d="
M439.820374,399.630859 
	C439.139252,400.085114 438.257935,400.309723 437.189514,400.279907 
	C437.875031,399.817413 438.747620,399.609314 439.820374,399.630859 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M395.133759,449.341553 
	C395.730347,448.927185 396.099152,448.936401 396.745422,448.973541 
	C397.985199,450.323303 398.947510,451.645172 400.244110,453.426208 
	C399.215851,458.086121 398.066650,463.581421 396.726379,469.029663 
	C396.544495,469.769104 395.405243,470.273010 394.303864,470.586670 
	C393.507233,469.844818 393.116028,469.402252 392.724823,468.959686 
	C393.451874,462.561493 394.178955,456.163330 395.133759,449.341553 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M392.497253,469.092041 
	C393.116028,469.402252 393.507233,469.844818 393.921692,470.598419 
	C393.707092,470.938416 393.469208,470.967346 392.870972,470.983063 
	C392.430359,470.388000 392.350037,469.806213 392.497253,469.092041 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M413.759033,448.584076 
	C412.358429,452.945282 410.850952,457.104095 409.053345,461.133484 
	C408.387756,462.625397 407.040619,463.813293 405.660706,465.228577 
	C405.193787,464.016022 405.472015,462.481964 404.876465,461.454376 
	C402.533997,457.412781 403.007233,455.226532 408.629364,456.029419 
	C406.760468,454.295532 405.389435,453.023560 404.009186,451.375793 
	C404.000000,451.000000 404.004364,450.995270 404.004364,450.995300 
	C405.266541,450.127960 406.528717,449.260651 407.527557,448.574310 
	C406.169739,447.074158 405.142548,445.939270 404.124359,444.814362 
	C402.348785,446.243927 400.483551,447.745667 398.313965,449.121399 
	C399.225098,447.062958 400.440552,445.130554 401.654358,443.200806 
	C400.792450,441.386230 399.908417,439.524994 399.027588,437.288391 
	C400.126617,437.242126 401.222443,437.571228 402.686554,438.320679 
	C405.366638,439.497284 407.678436,440.253540 409.992706,441.308960 
	C410.082123,442.136841 410.169037,442.665527 410.255981,443.194214 
	C410.473907,443.123169 410.691833,443.052094 410.909760,442.981049 
	C410.609741,442.318451 410.309753,441.655884 409.984558,440.650269 
	C410.239471,438.779938 410.519623,437.252655 410.926971,435.032074 
	C411.081726,435.201660 410.541840,434.609985 409.999023,433.649078 
	C410.001038,433.033813 410.005981,432.787781 410.355835,432.667572 
	C412.857758,434.156067 415.014771,435.518768 417.171783,436.881470 
	C414.090973,436.626495 412.565796,437.828705 412.975159,441.117218 
	C413.272736,443.507874 413.357422,445.924988 413.759033,448.584076 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M404.914093,467.129395 
	C405.469360,467.461426 405.738068,467.966949 406.060394,468.767700 
	C404.291107,470.636658 402.468140,472.210449 400.335480,473.911957 
	C400.198425,472.011902 400.371063,469.984070 400.683258,466.317169 
	C403.054718,471.496796 403.802948,469.210724 404.914093,467.129395 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M399.392944,465.715088 
	C399.560638,465.846954 399.585541,466.076080 399.610474,466.305206 
	C399.490356,466.140900 399.370270,465.976624 399.392944,465.715088 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M403.997955,451.001465 
	C403.752686,450.656738 403.506958,450.312134 403.282166,449.954407 
	C403.277039,449.946289 403.508942,449.789185 403.630280,449.701569 
	C403.746399,449.974396 403.862488,450.247253 403.991486,450.757690 
	C404.004364,450.995270 404.000000,451.000000 403.997955,451.001465 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M439.955383,540.234985 
	C439.613190,540.307983 439.270660,540.060120 438.725342,539.564087 
	C439.000031,539.515259 439.477509,539.714661 439.955383,540.234985 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M524.440063,528.522949 
	C524.405090,528.465027 524.474976,528.580933 524.440063,528.522949 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M538.326660,371.939362 
	C538.277039,371.253754 538.500000,370.449951 538.851318,369.356934 
	C542.736023,367.712738 542.640869,370.069580 542.691223,372.998871 
	C541.057434,372.528656 539.828308,372.174896 538.326660,371.939362 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M545.108215,396.553040 
	C545.283386,398.046051 545.426819,399.232300 545.570251,400.418518 
	C545.191772,400.449738 544.813293,400.480957 544.434814,400.512207 
	C544.434814,398.842560 544.434814,397.172943 544.710815,395.258484 
	C545.016724,395.424530 545.046631,395.835419 545.108215,396.553040 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M538.044189,510.968750 
	C538.205505,511.400177 538.320557,511.869324 538.435608,512.338501 
	C538.287781,512.077637 538.139954,511.816772 537.951111,511.321747 
	C537.910095,511.087585 537.997864,511.006470 538.044189,510.968750 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M541.013550,392.986816 
	C541.535950,393.215973 542.056885,393.438690 542.535339,393.730957 
	C542.582153,393.759583 542.367554,394.216248 542.274231,394.474213 
	C541.868591,394.181061 541.463013,393.887909 541.015991,393.310730 
	C540.974609,393.026642 541.000244,392.999786 541.013550,392.986816 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M544.472900,403.446655 
	C544.526184,403.414917 544.419556,403.478424 544.472900,403.446655 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M339.624268,588.089722 
	C339.973480,585.879211 340.645782,583.585388 341.631836,581.127563 
	C343.882904,584.049805 342.520081,586.199768 339.624268,588.089722 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M337.263916,582.936890 
	C337.020691,582.865356 337.006683,582.682007 337.021057,582.223877 
	C337.049500,581.949097 337.463806,581.938293 337.670410,581.925659 
	C337.749054,582.217041 337.621094,582.521057 337.263916,582.936890 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M388.829315,535.130371 
	C389.645813,535.439697 390.462280,535.749023 391.856323,536.199158 
	C392.614563,536.369141 392.795227,536.398315 393.185852,536.583984 
	C393.908264,536.867493 394.420715,536.994568 395.165161,537.410278 
	C398.635925,539.092346 401.874725,540.485718 405.113525,541.879089 
	C405.110992,542.135254 405.108429,542.391479 405.056488,543.033264 
	C393.183807,540.500000 381.360535,537.581238 369.312103,534.371460 
	C374.132904,534.320984 379.178894,534.561523 384.931213,534.752319 
	C386.701477,534.845215 387.765411,534.987793 388.829315,535.130371 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M393.906769,552.656616 
	C392.996338,552.955261 391.978241,552.932739 390.588409,552.839111 
	C386.380951,550.909546 382.545258,549.051086 378.085693,547.124634 
	C377.292358,547.045959 377.122925,547.035339 376.970520,546.655396 
	C376.007416,545.508789 375.027374,544.731445 374.047333,543.954041 
	C375.739532,543.510498 377.946442,542.159912 379.036041,542.778198 
	C384.125824,545.666565 388.907379,549.098022 393.906769,552.656616 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M397.593079,553.161255 
	C397.939056,553.992310 397.885773,554.819031 397.832489,555.645813 
	C396.557159,554.986755 395.281860,554.327637 394.013489,553.323853 
	C395.078217,553.038452 396.135986,553.097717 397.593079,553.161255 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M374.030212,544.220154 
	C375.027374,544.731445 376.007416,545.508789 376.651672,546.634216 
	C375.529022,546.652954 374.742188,546.323608 373.955353,545.994324 
	C373.955353,545.994324 373.977692,545.506348 373.976624,545.262329 
	C373.988068,544.840942 374.000580,544.663574 374.030212,544.220154 
z"/>
<path fill="#E7DA67" opacity="1.000000" stroke="none" 
	d="
M426.019043,545.864075 
	C426.425415,545.947815 426.831757,546.031555 427.619843,546.484009 
	C428.958374,547.563965 429.915192,548.275146 430.904724,549.342407 
	C432.173248,551.799316 433.408997,553.900208 434.693115,556.266113 
	C434.798920,556.701477 434.856293,556.871765 434.837219,557.373657 
	C434.860931,558.144531 434.961090,558.583862 435.038483,559.456482 
	C434.415649,562.415466 433.815613,564.941162 433.215515,567.466797 
	C432.758301,567.298462 432.301086,567.130066 431.479858,566.917480 
	C426.770386,561.220032 422.424896,555.566772 417.780548,549.788879 
	C417.165863,549.194153 416.850037,548.723999 416.541809,547.806885 
	C419.705963,546.861267 422.862518,546.362671 426.019043,545.864075 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M373.679840,546.196411 
	C374.742188,546.323608 375.529022,546.652954 376.634674,547.003479 
	C377.122925,547.035339 377.292358,547.045959 377.705444,547.132019 
	C378.705475,548.413086 379.461884,549.618713 379.915924,551.009827 
	C379.613556,551.195251 379.323212,551.344482 379.323212,551.344482 
	C379.066620,551.404907 378.810059,551.465271 377.985382,551.287598 
	C376.249329,551.069702 375.081390,551.089844 373.913483,551.109985 
	C372.503845,550.776917 371.094208,550.443848 369.333740,550.013916 
	C370.456696,548.744202 371.930511,547.571350 373.679840,546.196411 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M379.462585,551.504700 
	C379.323212,551.344482 379.613556,551.195251 379.688538,551.174316 
	C379.763519,551.153381 379.877838,551.472534 379.943909,551.627563 
	C380.010010,551.782654 379.601990,551.664917 379.462585,551.504700 
z"/>
<path fill="#DABD65" opacity="1.000000" stroke="none" 
	d="
M458.109222,567.985535 
	C458.727631,568.778625 459.346039,569.571716 459.984802,570.679932 
	C457.891174,570.645203 455.777100,570.295227 453.358826,569.894958 
	C453.193939,570.687073 452.941254,571.901062 452.465851,574.184875 
	C451.621918,568.306274 448.261536,571.180420 445.463806,571.018311 
	C444.833282,570.419128 444.581940,569.808594 444.010864,569.062134 
	C443.110809,566.279541 442.530518,563.632874 441.908447,560.247864 
	C442.425049,555.597168 440.615265,553.184326 437.029358,551.481689 
	C437.003601,549.706787 436.989807,548.338684 436.988495,546.628662 
	C437.006378,545.829956 437.011780,545.373169 437.017181,544.916321 
	C439.227783,546.088562 441.438416,547.260742 443.905518,548.671570 
	C446.471558,551.956909 448.781067,555.003723 450.905823,558.594849 
	C450.777435,559.626587 450.833801,560.113892 450.890167,560.601257 
	C451.275085,560.034058 451.660004,559.466858 452.044922,558.899597 
	C452.723419,559.312988 453.401978,559.726318 453.828888,560.633789 
	C452.770355,562.336548 451.078094,563.856750 451.367615,564.679443 
	C451.783630,565.861450 453.563812,566.693115 454.913727,567.372131 
	C455.845886,567.841003 457.034973,567.799133 458.109222,567.985535 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M374.145264,551.378052 
	C375.081390,551.089844 376.249329,551.069702 377.590179,551.277832 
	C376.634430,551.552734 375.505737,551.599426 374.145264,551.378052 
z"/>
<path fill="#211A07" opacity="1.000000" stroke="none" 
	d="
M434.913696,557.041992 
	C434.856293,556.871765 434.798920,556.701477 435.080017,556.283936 
	C435.933502,556.034668 436.448486,556.032776 437.050354,556.307007 
	C437.383362,557.103516 437.629456,557.624023 437.875549,558.144470 
	C438.550079,560.807068 439.543518,563.442627 439.772949,566.143066 
	C439.899506,567.632751 438.743744,569.231445 438.166046,570.781006 
	C436.539795,569.897827 434.913513,569.014648 433.251404,567.799133 
	C433.815613,564.941162 434.415649,562.415466 435.236816,559.283020 
	C435.276520,558.131470 435.095093,557.586731 434.913696,557.041992 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M438.181580,558.225952 
	C437.629456,557.624023 437.383362,557.103516 437.052826,555.979553 
	C436.300598,554.263000 435.632812,553.149902 435.311920,552.026733 
	C436.119690,551.973938 436.580505,551.931213 437.041351,551.888489 
	C440.615265,553.184326 442.425049,555.597168 441.729614,559.924255 
	C440.557556,559.661804 439.522552,558.984619 438.181580,558.225952 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M425.980164,545.522522 
	C422.862518,546.362671 419.705963,546.861267 416.323059,547.625122 
	C412.677429,545.906006 409.258179,543.921631 405.476257,541.908203 
	C401.874725,540.485718 398.635925,539.092346 395.151947,537.077393 
	C394.272766,535.972168 393.638824,535.488525 393.002441,535.002441 
	C393.000000,535.000000 392.995239,534.996704 392.995239,534.996704 
	C398.849823,530.936890 404.519440,533.537903 410.098907,535.632141 
	C415.942596,537.825562 421.939545,539.881470 425.980164,545.522522 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M437.029358,551.481689 
	C436.580505,551.931213 436.119690,551.973938 435.025665,551.843811 
	C433.218994,550.776062 432.045471,549.881226 430.872009,548.986389 
	C429.915192,548.275146 428.958374,547.563965 427.924683,546.547363 
	C429.355225,544.842834 430.862610,543.443604 432.718262,541.998291 
	C433.466614,542.055420 433.866760,542.158630 434.591003,542.691528 
	C435.602112,544.404236 436.289062,545.687378 436.976044,546.970520 
	C436.989807,548.338684 437.003601,549.706787 437.029358,551.481689 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M436.988495,546.628662 
	C436.289062,545.687378 435.602112,544.404236 434.889923,542.774170 
	C435.576355,543.022888 436.287964,543.618591 437.008362,544.565308 
	C437.011780,545.373169 437.006378,545.829956 436.988495,546.628662 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M458.080139,567.608765 
	C457.034973,567.799133 455.845886,567.841003 454.913727,567.372131 
	C453.563812,566.693115 451.783630,565.861450 451.367615,564.679443 
	C451.078094,563.856750 452.770355,562.336548 453.914032,560.945129 
	C454.499664,560.990051 454.748566,561.217773 455.139526,561.658203 
	C456.204773,563.657898 457.127930,565.444946 458.080139,567.608765 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M451.837646,558.866638 
	C451.660004,559.466858 451.275085,560.034058 450.890167,560.601257 
	C450.833801,560.113892 450.777435,559.626587 450.968689,558.952271 
	C451.216278,558.765320 451.630402,558.833679 451.837646,558.866638 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M335.362396,585.049561 
	C335.490173,584.384094 335.942444,583.722534 336.725769,583.046021 
	C336.600189,583.705139 336.143555,584.379272 335.362396,585.049561 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M334.348938,586.930054 
	C334.199646,586.461853 334.359894,585.891907 334.784180,585.170776 
	C334.918304,585.622498 334.788391,586.225342 334.348938,586.930054 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M389.082336,534.920654 
	C387.765411,534.987793 386.701477,534.845215 385.370483,534.611267 
	C386.616364,532.318420 388.038483,532.128296 389.082336,534.920654 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M392.975891,536.427490 
	C392.795227,536.398315 392.614563,536.369141 392.194458,536.205444 
	C392.147797,535.789612 392.340576,535.508423 392.764282,535.111938 
	C392.995239,534.996704 393.000000,535.000000 392.990448,535.357971 
	C392.979218,535.953125 392.977570,536.190308 392.975891,536.427490 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M592.018921,379.997223 
	C591.999878,379.248322 591.980835,378.499451 591.941833,377.376495 
	C593.342590,376.811737 594.763367,376.621033 596.798950,376.347778 
	C596.163391,378.166748 595.619263,379.724152 594.820068,381.748962 
	C594.371094,382.486450 594.177124,382.756500 593.820435,383.108276 
	C593.765808,383.450470 593.873901,383.710999 593.986694,384.352356 
	C593.997620,385.495483 594.003967,386.257751 594.010315,387.020020 
	C594.010315,387.020020 593.997559,387.000732 593.712463,387.117432 
	C591.954041,386.823181 590.480652,386.412201 589.007324,386.001221 
	C587.792664,384.662842 587.980530,383.968750 590.824280,381.265076 
	C591.715942,381.443085 592.118652,381.449432 592.521423,381.455780 
	C592.353943,380.969574 592.186462,380.483398 592.018921,379.997223 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M589.008789,386.392639 
	C590.480652,386.412201 591.954041,386.823181 593.723633,387.119019 
	C594.651123,388.763245 595.282288,390.522614 595.948364,392.637756 
	C595.771545,393.564087 595.559875,394.134644 595.107300,395.354401 
	C592.853699,392.186554 590.932007,389.485321 589.008789,386.392639 
z"/>
<path fill="#ECD078" opacity="1.000000" stroke="none" 
	d="
M594.367676,386.946564 
	C594.003967,386.257751 593.997620,385.495483 593.988037,384.116333 
	C593.984802,383.499451 593.983093,383.026581 593.983093,383.026581 
	C594.177124,382.756500 594.371094,382.486450 594.790039,382.100372 
	C597.648132,382.161407 600.281372,382.338440 602.914673,382.515472 
	C602.765137,382.805389 602.615601,383.095276 602.466125,383.385193 
	C599.885803,384.547821 597.305420,385.710480 594.367676,386.946564 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M603.371643,374.959229 
	C603.740234,374.661774 604.105469,374.620819 604.470764,374.579895 
	C604.467529,374.839966 604.464294,375.100037 604.461060,375.360138 
	C604.096802,375.311981 603.732605,375.263824 603.371643,374.959229 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M600.009521,359.752075 
	C599.898621,359.584381 599.953613,359.414886 600.008545,359.245361 
	C600.064148,359.413666 600.119751,359.581970 600.009521,359.752075 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M608.996338,386.003113 
	C608.556763,385.879547 608.120850,385.752991 607.684937,385.626404 
	C608.045227,385.608276 608.405457,385.590149 608.885315,385.782959 
	C609.004883,385.993896 609.000000,386.000061 608.996338,386.003113 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M601.467407,357.444275 
	C601.530151,357.404358 601.404663,357.484192 601.467407,357.444275 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M608.534729,378.554169 
	C608.478455,378.588379 608.591003,378.519958 608.534729,378.554169 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M565.230103,376.759766 
	C565.537048,377.651154 565.537048,378.602325 565.537048,379.553528 
	C565.130432,379.488251 564.723755,379.423004 564.317139,379.357758 
	C564.519165,378.511688 564.721130,377.665649 565.230103,376.759766 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M591.796143,380.103516 
	C592.186462,380.483398 592.353943,380.969574 592.521423,381.455780 
	C592.118652,381.449432 591.715942,381.443085 591.156616,381.216309 
	C591.104370,380.670563 591.295471,380.408569 591.796143,380.103516 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M597.742554,423.169983 
	C597.822815,423.567749 597.648132,424.131836 597.228577,424.875061 
	C597.151611,424.481537 597.319580,423.908905 597.742554,423.169983 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M594.552612,424.557068 
	C594.481750,424.602173 594.459595,424.417267 594.459595,424.417267 
	C594.459595,424.417267 594.623474,424.511993 594.552612,424.557068 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M564.054321,430.141083 
	C564.123962,430.143799 564.247925,430.287598 564.371826,430.431396 
	C564.284119,430.381653 564.196411,430.331909 564.054321,430.141083 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M518.030212,335.966156 
	C517.957397,335.146271 517.884521,334.326355 518.237854,333.255554 
	C519.105042,332.999115 519.546021,332.993591 519.987000,332.988098 
	C519.987000,332.988098 519.995544,332.995667 519.998169,332.998444 
	C521.321472,334.395935 522.642090,335.790680 523.967896,337.551727 
	C523.421082,338.106628 522.868958,338.295227 522.151733,338.731506 
	C520.866089,338.453003 519.745605,337.926819 518.348206,336.933167 
	C518.057556,336.299164 518.043884,336.132660 518.030212,335.966156 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M526.147400,328.616760 
	C524.713745,327.742218 523.412598,326.560394 522.111389,325.378540 
	C523.157959,324.588562 524.204529,323.798615 525.624390,322.997498 
	C526.091675,324.760712 526.185730,326.535065 526.147400,328.616760 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M529.000366,323.346283 
	C529.154846,323.581207 529.310303,324.150055 529.238037,324.867737 
	C529.007263,324.571136 529.004333,324.125671 529.000366,323.346283 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M528.848633,325.087219 
	C529.019897,325.005524 529.007263,325.502167 528.993896,325.750153 
	C528.811340,325.826569 528.627075,325.666016 528.486511,325.473602 
	C528.460266,325.437653 528.609314,325.273743 528.848633,325.087219 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M531.967163,349.957611 
	C532.114380,349.811035 532.292847,349.699341 532.471313,349.587646 
	C532.430298,349.692902 532.389282,349.798187 532.208008,349.979401 
	C532.067749,350.055328 531.998352,349.992462 531.967163,349.957611 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M585.008057,364.403015 
	C587.650452,362.878967 589.348083,362.869080 588.211182,366.543030 
	C587.736267,368.077545 587.419617,369.660980 587.035645,371.611542 
	C581.689636,370.921906 585.006409,367.384583 585.008057,364.403015 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M583.930115,361.752380 
	C584.061096,362.464264 584.046570,362.901489 584.035767,363.666870 
	C581.858887,365.418121 583.061401,370.590454 578.272827,369.432526 
	C579.739929,366.404694 581.106934,363.583466 582.578613,360.546326 
	C582.626404,360.583252 583.205505,361.030487 583.930115,361.752380 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M531.057007,355.371338 
	C532.403137,355.030731 533.772217,355.033173 535.551270,355.035583 
	C534.673218,357.700714 533.279968,359.608643 531.057007,355.371338 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M523.981384,351.696075 
	C523.097290,352.102570 522.216309,352.168854 521.335388,352.235138 
	C521.577332,351.379517 521.819275,350.523865 522.061218,349.668213 
	C522.702332,350.230774 523.343445,350.793335 523.981384,351.696075 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M509.013519,345.344574 
	C509.442657,344.965912 509.870605,344.918732 510.617340,344.820679 
	C510.960266,345.564636 510.984406,346.359558 511.014221,347.547119 
	C510.350647,347.185211 509.681488,346.430664 509.013519,345.344574 
z"/>
<path fill="#DDC169" opacity="1.000000" stroke="none" 
	d="
M565.088745,331.172119 
	C565.000000,331.001221 565.492676,330.996582 565.738770,330.990662 
	C565.715820,331.104156 565.446655,331.223572 565.088745,331.172119 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M550.653076,317.008636 
	C551.982483,318.447601 552.960876,319.888885 553.961548,321.670044 
	C551.891296,321.783966 549.798889,321.557953 548.945190,321.465759 
	C546.979492,319.238556 545.738098,317.832001 544.496643,316.425415 
	C544.327637,318.433380 544.158691,320.441345 543.997498,322.356140 
	C541.471741,321.378937 540.851624,318.387634 542.721802,313.231934 
	C544.690247,313.179901 546.383301,313.349030 547.657776,313.476318 
	C548.587585,314.719177 549.444824,315.865051 550.653076,317.008636 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M549.043457,329.381226 
	C549.289307,329.782990 549.287720,330.182770 549.257263,330.580292 
	C549.256348,330.591583 548.946045,330.579071 548.780029,330.577637 
	C548.783997,330.178070 548.787964,329.778503 549.043457,329.381226 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M519.940430,332.671326 
	C519.546021,332.993591 519.105042,332.999115 518.333252,333.006958 
	C518.001770,332.570770 518.001160,332.132263 517.999756,331.365814 
	C518.630615,331.476776 519.262207,331.915680 519.940430,332.671326 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M493.019806,333.764069 
	C492.908020,333.600433 492.960968,333.437775 493.013947,333.275116 
	C493.070801,333.438416 493.127686,333.601746 493.019806,333.764069 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M509.396179,329.724792 
	C509.549500,329.848572 509.570679,330.064484 509.591888,330.280365 
	C509.482635,330.125885 509.373352,329.971405 509.396179,329.724792 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M393.996887,419.996918 
	C393.882751,420.434692 393.771606,420.875519 393.660461,421.316315 
	C393.634460,420.957886 393.608429,420.599487 393.793793,420.122253 
	C394.005157,420.003418 393.999878,419.999939 393.996887,419.996918 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M392.017273,417.019958 
	C391.559784,417.105713 391.102295,417.191467 391.066650,417.141724 
	C391.664734,417.010834 391.841003,417.015411 392.017273,417.019958 
z"/>
<path fill="#C6A850" opacity="1.000000" stroke="none" 
	d="
M391.931580,416.839233 
	C391.841003,417.015411 391.664734,417.010834 391.223541,417.001953 
	C391.254364,416.884613 391.550110,416.771545 391.931580,416.839233 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M410.001953,434.018311 
	C410.541840,434.609985 411.081726,435.201660 410.926971,435.032074 
	C410.519623,437.252655 410.239471,438.779938 409.979492,440.653809 
	C409.999634,441.000366 409.990234,441.009766 409.990234,441.009766 
	C407.678436,440.253540 405.366638,439.497284 402.991638,438.357910 
	C402.950958,435.773895 402.935455,433.571838 403.004364,431.372437 
	C403.128632,427.403870 403.303406,423.436890 403.724182,419.246521 
	C404.452362,419.341400 404.913452,419.658966 405.659180,420.346924 
	C405.918823,421.142212 405.893860,421.567047 405.655273,422.239990 
	C406.961792,426.331512 408.481873,430.174896 410.001953,434.018311 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M398.986206,416.995117 
	C399.500153,417.225220 400.040283,417.441528 400.539734,417.728577 
	C400.586731,417.755615 400.377899,418.227783 400.287842,418.493286 
	C399.878174,418.181793 399.468506,417.870300 399.023987,417.264893 
	C398.989105,416.971008 399.001129,417.000793 398.986206,416.995117 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M424.968475,402.098633 
	C425.787933,402.656891 426.607422,403.215149 427.654053,404.285797 
	C427.315552,406.618927 426.749939,408.439667 426.073486,410.617157 
	C427.540161,410.772247 428.620361,410.886505 429.750244,411.330627 
	C429.794861,412.103638 429.789795,412.546753 429.784698,412.989868 
	C426.548248,412.862427 423.897369,412.865326 424.353333,417.676453 
	C424.472717,418.936157 421.012115,420.535187 418.279144,422.706543 
	C420.972931,423.452026 422.727356,423.937531 424.406128,424.728516 
	C423.893524,425.026581 423.456543,425.019165 422.658325,424.993713 
	C421.180115,424.975708 420.063171,424.975708 417.595703,424.975708 
	C419.729553,426.454559 420.844269,427.227112 422.008148,428.388611 
	C419.968445,433.097565 416.829651,430.382111 413.997284,429.585571 
	C411.527222,423.250397 408.849304,417.316071 413.379578,410.859680 
	C415.894897,407.877838 418.123688,405.087646 420.352478,402.297455 
	C421.039215,402.315521 421.725952,402.333557 423.061676,402.249146 
	C424.129944,402.130646 424.549194,402.114624 424.968475,402.098633 
M424.206604,411.644531 
	C424.255005,411.280457 424.303406,410.916351 424.351807,410.552277 
	C424.092010,410.550507 423.832245,410.548767 423.572449,410.546997 
	C423.612610,410.911407 423.652771,411.275818 424.206604,411.644531 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M420.024658,402.109192 
	C418.123688,405.087646 415.894897,407.877838 412.983582,410.836609 
	C410.912354,411.005157 409.523621,411.005157 407.978058,411.005157 
	C407.978058,413.463654 408.238892,415.654327 407.889862,417.743134 
	C407.641327,419.230438 406.571930,420.580566 405.868866,421.991913 
	C405.893860,421.567047 405.918823,421.142212 405.949768,420.398804 
	C405.971405,417.343201 405.987122,414.606140 405.992981,411.412750 
	C406.007935,410.520752 406.032684,410.085083 406.076935,409.322205 
	C407.556030,408.685028 409.015656,408.375153 410.768280,408.003082 
	C410.252533,406.460297 409.642426,404.635101 409.047058,402.434448 
	C410.832153,400.801788 412.602448,399.544586 414.663635,398.135864 
	C416.535248,399.296539 418.116028,400.608734 420.024658,402.109192 
z"/>
<path fill="#857126" opacity="1.000000" stroke="none" 
	d="
M424.981659,401.822083 
	C424.549194,402.114624 424.129944,402.130646 423.397888,402.175110 
	C423.602966,401.647797 424.120758,401.092072 424.824738,400.764221 
	C425.005554,401.176605 425.000183,401.361084 424.981659,401.822083 
z"/>
<path fill="#A98C36" opacity="1.000000" stroke="none" 
	d="
M513.996643,352.995544 
	C513.910217,353.033508 513.827209,353.075989 513.876587,353.062561 
	C514.009033,353.006683 514.000000,353.000000 513.996643,352.995544 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M517.853027,336.065552 
	C518.043884,336.132660 518.057556,336.299164 518.095215,336.714905 
	C517.971375,336.697754 517.823547,336.431366 517.853027,336.065552 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M423.019562,425.011719 
	C423.456543,425.019165 423.893524,425.026581 424.654999,424.988220 
	C425.728363,424.982025 426.477234,425.021637 427.592163,425.381897 
	C426.639282,428.993347 423.140289,432.278442 428.140930,435.661377 
	C430.315094,434.135559 432.596497,432.534485 434.877930,430.933411 
	C433.775360,432.807983 432.672760,434.682495 431.290680,436.768433 
	C428.226654,437.301758 425.442169,437.623688 422.297668,438.005371 
	C420.578857,437.702820 419.220062,437.340515 417.516541,436.929840 
	C415.014771,435.518768 412.857758,434.156067 410.401337,432.335358 
	C411.413055,431.243134 412.724152,430.608917 414.035278,429.974701 
	C416.829651,430.382111 419.968445,433.097565 422.287323,428.200928 
	C422.684723,426.753479 422.852142,425.882599 423.019562,425.011719 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M435.452850,443.529846 
	C435.417786,443.471588 435.487915,443.588104 435.452850,443.529846 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M437.018005,441.024994 
	C436.916687,441.065094 436.794708,441.081726 436.811462,441.029541 
	C436.950195,440.960663 436.997375,441.001556 437.018005,441.024994 
z"/>
<path fill="#CEB258" opacity="1.000000" stroke="none" 
	d="
M419.383270,441.215942 
	C419.525513,440.782806 419.800873,440.518799 420.095245,440.277954 
	C420.108185,440.267334 420.294678,440.468811 420.400360,440.571472 
	C420.106873,440.844086 419.813416,441.116730 419.383270,441.215942 
z"/>
<path fill="#413610" opacity="1.000000" stroke="none" 
	d="
M434.911591,430.583984 
	C432.596497,432.534485 430.315094,434.135559 428.140930,435.661377 
	C423.140289,432.278442 426.639282,428.993347 427.965088,425.335663 
	C428.758026,423.574219 429.544067,422.179657 430.616760,420.920715 
	C431.916779,423.278534 431.177490,426.947998 435.637451,426.437378 
	C435.393402,427.776245 435.169342,429.005402 434.911591,430.583984 
z"/>
<path fill="#9D7F29" opacity="1.000000" stroke="none" 
	d="
M431.022644,407.983887 
	C433.209045,407.858673 435.395447,407.733490 438.374847,407.562866 
	C436.966919,409.146393 435.980865,410.255432 434.989258,411.693481 
	C434.983643,412.022461 434.525146,412.027039 434.012451,411.909241 
	C432.967590,411.500488 432.435394,411.209503 431.903259,410.608734 
	C431.609802,409.527252 431.316223,408.755585 431.022644,407.983887 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M431.903198,410.918549 
	C432.435394,411.209503 432.967590,411.500488 433.783234,411.905762 
	C432.874542,412.427490 431.682404,412.834900 430.137482,413.116089 
	C429.789795,412.546753 429.794861,412.103638 430.121887,411.323792 
	C430.930298,410.964233 431.416748,410.941406 431.903198,410.918549 
z"/>
<path fill="#8C721E" opacity="1.000000" stroke="none" 
	d="
M432.550903,415.545959 
	C432.475922,415.596405 432.455902,415.391968 432.455902,415.391968 
	C432.455902,415.391968 432.625916,415.495514 432.550903,415.545959 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M431.903259,410.608734 
	C431.416748,410.941406 430.930298,410.964233 430.072205,410.993896 
	C428.620361,410.886505 427.540161,410.772247 426.073486,410.617157 
	C426.749939,408.439667 427.315552,406.618927 427.918945,404.438416 
	C429.264526,403.410004 430.524719,402.352783 431.893860,402.185181 
	C433.189423,402.026550 434.599243,402.801697 435.746002,403.109100 
	C433.703949,404.936401 432.375641,406.125031 431.034973,407.648773 
	C431.316223,408.755585 431.609802,409.527252 431.903259,410.608734 
z"/>
<path fill="#92793A" opacity="1.000000" stroke="none" 
	d="
M413.997284,429.585571 
	C412.724152,430.608917 411.413055,431.243134 410.056396,432.209564 
	C410.005981,432.787781 410.001038,433.033813 409.999023,433.649078 
	C408.481873,430.174896 406.961792,426.331512 405.655273,422.239990 
	C406.571930,420.580566 407.641327,419.230438 407.889862,417.743134 
	C408.238892,415.654327 407.978058,413.463654 407.978058,411.005157 
	C409.523621,411.005157 410.912354,411.005157 412.697021,411.028229 
	C408.849304,417.316071 411.527222,423.250397 413.997284,429.585571 
z"/>
<path fill="#E4C870" opacity="1.000000" stroke="none" 
	d="
M410.004700,440.996826 
	C410.309753,441.655884 410.609741,442.318451 410.909760,442.981049 
	C410.691833,443.052094 410.473907,443.123169 410.255981,443.194214 
	C410.169037,442.665527 410.082123,442.136841 409.992706,441.308960 
	C409.990234,441.009766 409.999634,441.000366 410.004700,440.996826 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M393.185852,536.583984 
	C392.977570,536.190308 392.979218,535.953125 392.992889,535.360413 
	C393.638824,535.488525 394.272766,535.972168 394.919983,536.788757 
	C394.420715,536.994568 393.908264,536.867493 393.185852,536.583984 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M430.904724,549.342407 
	C432.045471,549.881226 433.218994,550.776062 434.678741,551.853882 
	C435.632812,553.149902 436.300598,554.263000 436.965942,555.703491 
	C436.448486,556.032776 435.933502,556.034668 435.031616,556.018799 
	C433.408997,553.900208 432.173248,551.799316 430.904724,549.342407 
z"/>
<path fill="#726027" opacity="1.000000" stroke="none" 
	d="
M434.837219,557.373657 
	C435.095093,557.586731 435.276520,558.131470 435.259583,558.849670 
	C434.961090,558.583862 434.860931,558.144531 434.837219,557.373657 
z"/>
<path fill="#D5B95F" opacity="1.000000" stroke="none" 
	d="
M593.820435,383.108276 
	C593.983093,383.026581 593.984802,383.499451 593.983398,383.735474 
	C593.873901,383.710999 593.765808,383.450470 593.820435,383.108276 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M422.658325,424.993713 
	C422.852142,425.882599 422.684723,426.753479 422.238159,427.812012 
	C420.844269,427.227112 419.729553,426.454559 417.595703,424.975708 
	C420.063171,424.975708 421.180115,424.975708 422.658325,424.993713 
z"/>
<path fill="#B89C46" opacity="1.000000" stroke="none" 
	d="
M423.949768,411.642395 
	C423.652771,411.275818 423.612610,410.911407 423.572449,410.546997 
	C423.832245,410.548767 424.092010,410.550507 424.351807,410.552277 
	C424.303406,410.916351 424.255005,411.280457 423.949768,411.642395 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
